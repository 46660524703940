<form class="form" [formGroup]="archiveSubjectForm"
  (ngSubmit)="submitForm(archiveSubjectForm); submitButton.disabled = true">
  <form-group title="{{ 'ARCHIVE_SUBJECT.TITLE' | translate }}" isLast="true">
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label *ngIf="archive">{{ 'ARCHIVE_SUBJECT.ARCHIVE_DATE' | translate }}</mat-label>
        <mat-label *ngIf="!archive">{{ 'UNARCHIVE_SUBJECT.UNARCHIVE_DATE' | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" [min]="minFromDate" [max]="maxFromDate" required
          autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="archiveSubjectForm.get('date').hasError('required')">
          <span *ngIf="archive"> {{ 'ARCHIVE_SUBJECT.ARCHIVE_DATE_ERROR' | translate }} </span>
          <span *ngIf="!archive"> {{ 'UNARCHIVE_SUBJECT.UNARCHIVE_DATE_ERROR' | translate }} </span>
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline" *ngIf="archive">
        <mat-label>{{ 'ARCHIVE_SUBJECT.ARCHIVE_REASON' | translate }}</mat-label>
        <mat-select formControlName="archiveReason" required>
          <mat-option *ngFor="let archiveReason of archivationReasons" [value]=archiveReason.id>
            {{ archiveReason.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="archiveSubjectForm.get('archiveReason').hasError('required')">
          {{ 'ARCHIVE_SUBJECT.ARCHIVE_REASON_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="!archive">
        <mat-label>{{ 'UNARCHIVE_SUBJECT.UNARCHIVE_REASON' | translate }}</mat-label>
        <textarea matInput type="text" formControlName="unarchiveReason" required autocomplete="off"></textarea>
        <mat-error *ngIf="archiveSubjectForm.get('unarchiveReason').hasError('required')">
          {{ 'UNARCHIVE_SUBJECT.UNARCHIVE_REASON_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
      class="form-footer__button form-footer__button--secondary">
      {{ 'ARCHIVE_SUBJECT.CANCEL' | translate }}
    </button>
    <button *ngIf="archive" mat-flat-button color="warn" type="submit"
      class="form-footer__button form-footer__button--primary" [disabled]="!archiveSubjectForm.valid" #submitButton>
      {{ 'ARCHIVE_SUBJECT.ARCHIVE' | translate }}
    </button>
    <button *ngIf="!archive" mat-flat-button color="primary" type="submit"
      class="form-footer__button form-footer__button--primary" [disabled]="!archiveSubjectForm.valid" #submitButton>
      {{ 'UNARCHIVE_SUBJECT.UNARCHIVE' | translate }}
    </button>
  </form-footer>
</form>