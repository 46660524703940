<mat-card class="card">
  <card-header title="{{ 'SUBJECT_HISTORY.TITLE' | translate }}"></card-header>
  <div class="table-responsive-wrapper">
    <table mat-table #myTable [dataSource]="page.content" class="subject-history__table">
      <ng-container matColumnDef="dateOfModification">
        <th mat-header-cell *matHeaderCellDef> {{ 'SUBJECTS.DATEOFMODIFICATION' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.dateOfModification | date:"dd.MM.yyyy."}}
        </td>
      </ng-container>
      <ng-container matColumnDef="modifiedBy">
        <th mat-header-cell *matHeaderCellDef> {{ 'SUBJECTS.MODIFIEDBY' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.modifiedBy }}
        </td>
      </ng-container>
      <ng-container matColumnDef="registrationId">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.REGID' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.registrationId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ 'ID' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element?.historicalSubjectIdentity.subjectId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dateOfCreation">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.DATEOFCREATION' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element?.dateOfCreation | date:"dd.MM.yyyy." }}
        </td>
      </ng-container>
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.LASTNAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.lastName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.FIRSTNAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.firstName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="parentName">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.PARENTNAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.parentName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.DATEOFBIRTH' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.dateOfBirth | date:"dd.MM.yyyy." }}
        </td>
      </ng-container>
      <ng-container matColumnDef="yearOfBirth">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.YEAROFBIRTH' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.yearOfBirth }}
        </td>
      </ng-container>
      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.GENDER' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.gender?.gender }}
        </td>
      </ng-container>
      <ng-container matColumnDef="jmbg">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.JMBG' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.jmbg }}
        </td>
      </ng-container>
      <ng-container matColumnDef="jmbp">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.JMBP' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.jmbp }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.STATUS' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <status-label status="{{element?.status?.id}}">
            {{ element?.status?.title }}
          </status-label>
        </td>
      </ng-container>
      <ng-container matColumnDef="familyDossier">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECTS.DOSSIERSTATUS' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <dossier-status-label status="{{element?.familyDossier}}">
            {{ element?.familyDossierId }}
          </dossier-status-label>
        </td>
      </ng-container>
      <ng-container matColumnDef="placeOfBirth">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.PLACE_OF_BIRTH' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.placeOfBirth }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nationality">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.NATIONALITY' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.nationality?.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="citizenship">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.CITIZENSHIP' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.citizenship?.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL.SUBJECT.CATEGORY_OF_PERSON' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.category?.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nativeLanguage">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.NATIVE_LANGUAGE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.language?.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="disabledPerson">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DISABLED_PERSON' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element?.disabledPerson === null ? '' : element?.disabledPerson ?  'GLOBAL.YES' : 'GLOBAL.NO') | translate  }}
        </td>
      </ng-container>
      <ng-container matColumnDef="disabilityType">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DISABILITY_TYPE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.disabilityType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ableToWork">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.ABLE_TO_WORK' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element?.ableToWork === null ? '' : element?.ableToWork ?  'GLOBAL.YES' : 'GLOBAL.NO') | translate  }}
        </td>
      </ng-container>
      <ng-container matColumnDef="businessAbility">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.BUSINESS_ABILITY' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element?.businessAbility === null ? '' : element?.businessAbility ?  'GLOBAL.YES' : 'GLOBAL.NO') | translate  }}
        </td>
      </ng-container>
      <ng-container matColumnDef="deprivationExtent">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DEPTIVATION_EXTENT' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.deprivationExtent === null ? '' : element?.deprivationExtent?.title  }}
        </td>
      </ng-container>
      <ng-container matColumnDef="occupation">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.OCCUPATION' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.occupation?.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="employment">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.EMPLOYMENT' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.employment?.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="education">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.EDUCATION' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.education?.title }}
        </td>
      </ng-container>
      
      <ng-container matColumnDef="levelOfEducation">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.LEVEL_OF_EDUCATION' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.levelOfEducation?.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="schoolAttending">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.SCHOOL_ATTENDING' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.schoolAttending?.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="maritalStatus">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.MARITAL_STATUS' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.maritalStatus?.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="residence">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.ADDRESS.RESIDENCE_PLACE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.residence?.street }} {{ element?.residence?.number }}{{ element?.residence?.subnumber !==null?("/" + element?.residence?.subnumber): "" }}{{ element?.residence?.town !== null && element?.residence?.town !== ""?("- " + element?.residence?.town) : ""}}{{ element?.residence?.localComunity !== null && element?.residence?.localComunity !== ""?(", " + element?.residence?.town) : "" }}{{ element?.residence?.ptt !== null && element?.residence?.ptt !== ""?(", " + element?.residence?.ptt) : ""}}{{ element?.residence?.populatedPlace?.title !== null && element?.residence?.populatedPlace?.title !== ""? (", " + element?.residence?.populatedPlace?.title) : "" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="permanentResidence">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.ADDRESS.ORIGIN_ADDRESS' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.permanentResidence?.street }} {{ element?.permanentResidence?.number }}{{ element?.permanentResidence?.subnumber !== null?("/" + element?.permanentResidence?.subnumber) : ""}}{{ element?.permanentResidence?.town !== null && element?.permanentResidence?.town !== ""?("-" + element?.permanentResidence?.town ) : "" }}{{ element?.permanentResidence?.localComunity !== null && element?.permanentResidence?.localComunity !== ""?(", " + element?.permanentResidence?.localComunity) : "" }}{{ element?.permanentResidence?.ptt !== null && element?.permanentResidence?.ptt !== ""?(", " +element?.permanentResidence?.ptt) : "" }}{{ element?.permanentResidence?.populatedPlace?.title !== null?(", " + element?.permanentResidence?.populatedPlace?.title) : "" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.ADDRESS.PHONE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.phone }}
        </td>
      </ng-container>
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.ADDRESS.MOBILE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.mobile }}
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.ADDRESS.EMAIL' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.OTHER.NOTE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.note }}
        </td>
      </ng-container>
      <ng-container matColumnDef="note1">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.OTHER.NOTE1' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.note1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="note2">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.OTHER.NOTE2' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.note2 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="note3">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.OTHER.NOTE3' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.note3 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dossierOwner">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.DOSSIER_OWNER' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element?.dossierOwner?.firstName!==null ? element?.dossierOwner?.firstName:'').concat
           (element?.dossierOwner?.lastName!==null ? ' ' :'').concat 
           (element?.dossierOwner?.lastName!==null ? element?.dossierOwner?.lastName : '')
           }}
        </td>
      </ng-container>
      <ng-container matColumnDef="familyType">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.FAMILY_TYPE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.familyType?.title}}
        </td>
      </ng-container>
      <ng-container matColumnDef="familyMembersNames">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'NEW_SUBJECT.FAMILY_MEMBERS_NAMES' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.familyMembersNames }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; let element; columns: displayedColumns" class="subject-history__row"></tr>
    </table>
  </div>
  <div class="pagination">
    <app-custom-pagination [page]="page"  (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()" 
            (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"  (pageSizeEvent)="getPageInNewSize($event)">
    </app-custom-pagination>
  </div>
</mat-card>
