/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProtegePersonService } from 'src/services/protege-person.service';
import { ToastrImplService } from 'src/services/toastr.service';

@Component({
  selector: 'app-new-protege',
  templateUrl: './new-protege.component.html',
  styleUrls: ['./new-protege.component.scss']
})
export class NewProtegeComponent implements OnInit {
  public guardianshipType: 'adult' | 'minor';
  personalInformationStep: FormGroup;
  propertyStep: FormGroup;
  guardianStep: FormGroup;
  terminationOfGuardianshipStep: FormGroup;
  protegePersonId: any;
  subjectId: any;
  year: any;
  isAdult: boolean;
  protegePerson: any;

  constructor(
    private router: Router,
    private protegePersonService: ProtegePersonService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private toastr: ToastrImplService) { this.personalInformationStep = formBuilder.group({
      firstName: ['', [Validators.required]],
         lastName: ['', [Validators.required]],
         parentFirstName: ['', [Validators.required]],
         parentLastName: [''],
         foreigner: [false],
         jmbg: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
         placeOfBirth: [''],
         municipality: [null],
         placeOfOrigin: [null],
         citizenship: [null],
         nationality: [null],
         occupation: [null],
         accomodationInfo: ['', []],
         accomodationType: ['', [Validators.required]],
         decisionNumberDeprivation: ['', []],
         decisionDateDeprivation: ['', []],
         decisionMakerNameDeprivation: ['', []],
         courtDecisionContent: ['', []],
         decisionNumberGuardianship: ['', []],
         decisionDateGuardianship: ['', []],
         decisionMakerNameGuardianship: ['', []],
         dateOfBirth: [null],
    });
    this.propertyStep = formBuilder.group({
      assetValueOnCensusDay: [''],
      assetValueOnTheDayOfTerminationOfCustody: [''],
    });
    this.guardianStep = formBuilder.group({
      guardian: this.formBuilder.group({
        firstName: [''],
        lastName: [''],
        foreigner: [false],
        jmbg: [''],
        residence: [''],
        occupation: [''],
        relationship: [''],
        dateOfBirth: [''],
        yearOfBirth: [''],
        citizenship: [''],
        phone: ['']
    }),
    collectiveGuardian: this.formBuilder.group({
        firstName: [''],
        lastName: [''],
        typeOfJob: [''],
        socialProtectionName: [''],
    }),
    directGuardian: this.formBuilder.group({
        firstName: [''],
        lastName: [''],
        occupation: [''],
    }),
    });
    this.terminationOfGuardianshipStep = formBuilder.group({
      decisionNumber: [''],
      decisionDate: [''],
      decisionName: [''],
      reasonForTermination: ['']
    });

  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.protegePersonId = params.id;
        this.guardianshipType = params.guardianshipType;
        this.subjectId = params.subjectId;
      });

      this.protegePersonService.findById(this.protegePersonId).subscribe((result) => {
           this.protegePerson = result;
           if(result.guardianshipType.mark === 'adult') {
              this.isAdult = true;
           } else {
              this.isAdult = false;
           }

           this.personalInformationStep.patchValue({
              firstName: result.firstName,
              lastName: result.lastName,
              parentFirstName: result.fatherFirstName,
              parentLastName: result.protegePersonCollection?.protegePersonDetails.parentLastName,
              jmbg: result.jmbg,
              dateOfBirth: result.dateOfBirth ?
                           new Date(result.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : null,
              placeOfBirth: result.protegePersonCollection?.protegePersonDetails.placeOfBirth,
              municipality: result.protegePersonCollection?.protegePersonDetails.municipality,
              placeOfOrigin: result.protegePersonCollection?.protegePersonDetails.placeOfOrigin,
              citizenship: result.protegePersonCollection?.protegePersonDetails.citizenship,
              nationality: result.protegePersonCollection?.protegePersonDetails.nationality,
              accomodationInfo: result.protegePersonCollection?.protegePersonDetails.accomodationInfo,
              decisionNumberDeprivation: result.protegePersonCollection?.protegePersonDetails.decisionNumberDeprivation,
              decisionDateDeprivation: result.protegePersonCollection?.protegePersonDetails.decisionDateDeprivation ? 
                                      new Date(result.protegePersonCollection?.protegePersonDetails.decisionDateDeprivation) : null,
              decisionMakerNameDeprivation: result.protegePersonCollection?.protegePersonDetails.decisionMakerNameDeprivation,
              courtDecisionContent: result.protegePersonCollection?.protegePersonDetails.courtDecisionContent,
              decisionNumberGuardianship: result.protegePersonCollection?.protegePersonDetails.decisionNumberGuardianship,
              decisionDateGuardianship: result.protegePersonCollection?.protegePersonDetails.decisionDateGuardianship,
              decisionMakerNameGuardianship: result.protegePersonCollection?.protegePersonDetails.decisionMakerNameGuardianship,
           });

           this.personalInformationStep.controls.dateOfBirth.markAsTouched();


           this.propertyStep.patchValue({
            assetValueOnCensusDay: result.protegePersonCollection?.assetValueOnCensusDay,
            assetValueOnTheDayOfTerminationOfCustody: result.protegePersonCollection?.assetValueOnTheDayOfTerminationOfCustody
           });


           this.guardianStep.patchValue({
            guardian: {
              firstName: result.protegePersonCollection?.guardianFirstName,
              lastName: result.protegePersonCollection?.guardianLastName,
              jmbg: result.protegePersonCollection?.guardianJmbg,
              citizenship: result.protegePersonCollection?.guardianCitizenship,
              residence: result.protegePersonCollection?.guardianAddress,
              occupation: result.protegePersonCollection?.guardianOccupation,
              relationship: result.protegePersonCollection?.guardianRelationship,
              phone: result.protegePersonCollection?.protegePersonDetails.guardianPhone,
            },
            collectiveGuardian: {
              firstName: result.protegePersonCollection?.collectiveGuardianFirstName,
              lastName: result.protegePersonCollection?.collectiveGuardianLastName,
              typeOfJob: result.protegePersonCollection?.collectiveGuardianTypeOfJob,
              socialProtectionName: result.protegePersonCollection?.collectiveGuardianProtectionName,
            },
            directGuardian: {
              firstName: result.protegePersonCollection?.directGuardianFirstName,
              lastName: result.protegePersonCollection?.directGuardianLastName,
              occupation: result.protegePersonCollection?.directGuardianOccupation,
            }
           });


           this.terminationOfGuardianshipStep.patchValue({
            decisionNumber: result.protegePersonCollection?.decisionNumber,
            decisionDate: result.protegePersonCollection?.decisionDate,
            decisionName: result.protegePersonCollection?.decisionName,
            reasonForTermination: result.protegePersonCollection?.reasonForTermination,
           });

        });
  }


  /**
   * Take information from personal step
   */
  updatePersonalInformationStep(step: FormGroup) {
    this.personalInformationStep = step;
  }

  /**
   * Take information from property step
   */
  updatePropertyStep(step: FormGroup) {
    this.propertyStep = step;
  }

  /**
   * Take information from reason for guardian step
   */
  updateGuardianStep(step: FormGroup) {
    this.guardianStep = step;
  }

  /**
   * Take information from reason for termination of guardianship step
   */
  updateTerminationOfGuardianshipStep(step: FormGroup) {
    // This is the final step where, apart from validating the data from this step
    // we are supposed to submit the new record to dedicated service.
    this.terminationOfGuardianshipStep = step;

    this.insertProtegeCollection();
  }

  insertProtegeCollection() {
    const body = {
      protegePersonDetails: {
        firstName: this.personalInformationStep.value.firstName,
        lastName: this.personalInformationStep.value.lastName,
        parentFirstName: this.personalInformationStep.value.parentFirstName,
        parentLastName: this.personalInformationStep.value.parentLastName,
        jmbg: this.personalInformationStep.value.jmbg,
        placeOfBirth: this.personalInformationStep.value.placeOfBirth !== '' ? this.personalInformationStep.value.placeOfBirth : null,
        municipality: this.personalInformationStep.value.municipality !== '' ? this.personalInformationStep.value.municipality : null,
        placeOfOrigin: this.personalInformationStep.value.placeOfOrigin !== '' ? this.personalInformationStep.value.placeOfOrigin : null,
        nationality: this.personalInformationStep.value.nationality !== '' ? this.personalInformationStep.value.nationality : null,
        citizenship: this.personalInformationStep.value.citizenship !== '' ? this.personalInformationStep.value.citizenship : null,
        occupation: this.personalInformationStep.value.occupation !== '' ? this.personalInformationStep.value.occupation : null,
        accomodationInfo: this.personalInformationStep.value.accomodationInfo,
        accomodationType: this.personalInformationStep.value.accomodationType,
        decisionNumberDeprivation: this.personalInformationStep.value.decisionNumberDeprivation,
        decisionDateDeprivation: this.personalInformationStep.value.decisionDateDeprivation !== null ?
           this.datePipe.transform(new Date(this.personalInformationStep.value.decisionDateDeprivation).getTime(), 'yyyy-MM-dd') : null,
        decisionMakerNameDeprivation: this.personalInformationStep.value.decisionMakerNameDeprivation,
        courtDecisionContent: this.personalInformationStep.value.courtDecisionContent,
        decisionNumberGuardianship: this.personalInformationStep.value.decisionNumberGuardianship,
        decisionDateGuardianship: this.personalInformationStep.value.decisionDateGuardianship !== null ?
           this.datePipe.transform(new Date(this.personalInformationStep.value.decisionDateGuardianship).getTime(), 'yyyy-MM-dd') : null,
        decisionMakerNameGuardianship: this.personalInformationStep.value.decisionMakerNameGuardianship,
      },
      assetValueOnCensusDay: this.propertyStep.value.assetValueOnCensusDay,
      assetValueOnTheDayOfTerminationOfCustody: this.propertyStep.value.assetValueOnTheDayOfTerminationOfCustody,
      decisionNumber: this.terminationOfGuardianshipStep.value.decisionNumber,
      decisionName: this.terminationOfGuardianshipStep.value.decisionName,
      decisionDate: this.terminationOfGuardianshipStep.value.decisionDate !== null ?
        this.datePipe.transform(new Date(this.terminationOfGuardianshipStep.value.decisionDate).getTime(), 'yyyy-MM-dd') : null,
      terminationReasonCodebook: this.terminationOfGuardianshipStep.value.terminationReasonCodebook === ''?null:this.terminationOfGuardianshipStep.value.terminationReasonCodebook,
      guardianFirstName: this.guardianStep.value.guardian.firstName !== '' ? this.guardianStep.value.guardian.firstName : null,
      guardianLastName: this.guardianStep.value.guardian.lastName !== '' ? this.guardianStep.value.guardian.lastName : null,
      guardianJmbg: this.guardianStep.value.guardian.jmbg,
      guardianAddress: this.guardianStep.value.guardian.residence,
      guardianOccupation: this.guardianStep.value.guardian.occupation !== '' ? this.guardianStep.value.guardian.occupation : null,
      guardianCitizenship: this.guardianStep.value.guardian.citizenship !== '' ? this.guardianStep.value.guardian.citizenship : null,
      guardianRelationship: this.guardianStep.value.guardian.relationship !== '' ? this.guardianStep.value.guardian.relationship : null,
      guardianPhone: this.guardianStep.value.guardian.phone !== '' ? this.guardianStep.value.guardian.phone : null,
      collectiveGuardianFirstName: this.guardianStep.value.collectiveGuardian.firstName,
      collectiveGuardianLastName: this.guardianStep.value.collectiveGuardian.lastName,
      collectiveGuardianTypeOfJob: this.guardianStep.value.collectiveGuardian.typeOfJob,
      collectiveGuardianProtectionName: this.guardianStep.value.collectiveGuardian.socialProtectionName,
      directGuardianFirstName: this.guardianStep.value.directGuardian.firstName,
      directGuardianLastName: this.guardianStep.value.directGuardian.lastName,
      directGuardianOccupation: this.guardianStep.value.directGuardian.occupation !== '' ?
        this.guardianStep.value.directGuardian.occupation : null,

    };

    this.protegePersonService.insertIntoCollection(this.protegePersonId, body,
      ).subscribe((res) => {
        if (res != null) {
          this.toastr.success('SNACKBAR.ADDED_PROTEGE_PERSON');
          this.router.navigateByUrl('/proteges');
        } else {
          this.toastr.error('SNACKBAR.ADD_PROTEGE_PERSON_ERROR');
          this.router.navigateByUrl('/proteges');
        }
      });

  }
  /**
   * Review if all form steps are valid
   */
  checkDocumentValidation(): boolean {
    const isPersonalInformationStepValid =
      this.personalInformationStep && this.personalInformationStep.valid;
    const isPropertyStepValid =
      this.propertyStep && this.propertyStep.valid;
    const isGuardianStepValid =
      this.guardianStep && this.guardianStep.valid;
    const isTerminationOfGuardianshipStepd =
      this.terminationOfGuardianshipStep && this.terminationOfGuardianshipStep.valid;

    return [
      isPersonalInformationStepValid,
      isPropertyStepValid,
      isGuardianStepValid,
      isTerminationOfGuardianshipStepd
    ].every((val) => val === true);
  }

}
