<container>
  <page-intro-header title="{{ 'GLOBAL_ADMIN.CHANGE_REG_ID.HEADER' | translate }}">
  </page-intro-header>
  <mat-card class="card">
    <card-header title="{{ 'GLOBAL_ADMIN.CHANGE_REG_ID.TITLE' | translate }}">
      <div class="card__aside">
        <form [formGroup]="form">
          <mat-form-field appearance="outline" class="search centar-search">
            <mat-label>
              {{ 'GLOBAL_ADMIN.CHANGE_REG_ID.CENTER' | translate }}
            </mat-label>
            <input required type="text" matInput [formControl]="subcenter" [matAutocomplete]="autoSubcenter" />
            <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete" [displayWith]="displayCustomFormat"
              (optionSelected)="chooseCenter($event.option.value)">
              <mat-option *ngFor="let cent of filteredSubcenters | async" [value]="cent">
                {{ cent.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline" class="search">
            <mat-label>
              {{ 'GLOBAL_ADMIN.CHANGE_REG_ID.JMBG' | translate }}
            </mat-label>
            <input matInput formControlName="jmbg" type="text" (keyup.enter)="submit()" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="search">
            <mat-label>
              {{ 'GLOBAL_ADMIN.CHANGE_REG_ID.REGID' | translate }}
            </mat-label>
            <input matInput formControlName="regId" type="number" (keyup.enter)="submit()" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="search">
            <mat-label>
              {{ 'GLOBAL_ADMIN.CHANGE_REG_ID.ID' | translate }}
            </mat-label>
            <input matInput formControlName="subjectId" type="number" (keyup.enter)="submit()" />
          </mat-form-field>
        </form>
      </div>
    </card-header>
    <mat-chip-list #chipList aria-label="Applied filters" [class]="appliedTextFilters.length > 0 ? 'mb-30' : ''">
      <mat-chip *ngFor="let textFilter of appliedTextFilters">
        {{ textFilter }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <div class="table-responsive-wrapper">
      <table *ngIf="subjects.length > 0" mat-table [dataSource]="subjects" #mytable class="subject-table">
        <ng-container matColumnDef="registrationId">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'SUBJECTS.REGID' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.registrationId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>{{ 'ID' | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element?.subjectIdentity?.subjectId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="dateOfCreation">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'SUBJECTS.DATEOFCREATION' | translate }}
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element?.dateOfCreation | date : 'dd.MM.yyyy.' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'SUBJECTS.LASTNAME' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'SUBJECTS.FIRSTNAME' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="dateOfBirth">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'SUBJECTS.DATEOFBIRTH' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.dateOfBirth | date : 'dd.MM.yyyy.' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="gender">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'SUBJECTS.GENDER' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.gender?.gender }}
          </td>
        </ng-container>
        <ng-container matColumnDef="jmbg">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'SUBJECTS.JMBG' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.jmbg }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="table__cell table__cell--align-right">
            <button mat-icon-button class="button-square table__row-button" color="primary"
              matTooltip="{{ 'SUBJECTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
              #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
              <mat-icon class="button-square__icon">more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="filter-menu">
              <button mat-menu-item (click)="handleOpenDialog(element)">
                <mat-icon class="button-square__icon">edit</mat-icon>
                {{ 'GLOBAL_ADMIN.CHANGE_REG_ID.CHANGE' | translate }}
              </button>
              <button mat-menu-item (click)="checkDetails(element)">
                <mat-icon class="button-square__icon">portrait</mat-icon>
                {{ 'GLOBAL_ADMIN.CHANGE_REG_ID.DETAILS' | translate }}
              </button>
              <button mat-menu-item (click)="openRightsHistoryComponent(element)">
                <mat-icon>list</mat-icon> {{ 'CASE_HISTORY_BY_RIGHT.TITLE' | translate }}
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="subject-table__row"></tr>
      </table>
    </div>
    <div class="pagination" *ngIf="page.content.length > 0">
      <app-custom-pagination [page]="page" (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()"
        (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()" (pageSizeEvent)="getPageInNewSize($event)">
      </app-custom-pagination>
    </div>
    <p *ngIf="subjects.length === 0 && !isLoading" class="subject-empty">
      <mat-icon class="subject-empty__icon subject-empty__icon--empty">info</mat-icon>
      {{ 'SUBJECT_CASES.NO_RESULTS' | translate }}
    </p>
    <mat-progress-spinner *ngIf="isLoading" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>
</container>