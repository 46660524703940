/**
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { Page } from 'src/app/page';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pageable } from 'src/app/pageable';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient, private app: AppService,
    private localStorageService: LocalStorageService) { }

  getFilteredPage(pageable: Pageable, filterValue: string, csrId: string, nextPage?: boolean): Observable<Page<any>> {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    let nextPagePar = false;
    if (nextPage !== null && nextPage !== undefined) {
      nextPagePar = true;
    }

    // return this.httpClient.get(this.app.getUrl() + environment.changeFormNumber + caseId + '/'
    //   + '?formNumber=' + formNumber
    return this.httpClient.post(this.app.getUrl() + environment.findAllWorkers + '?pageNumber=' + pageable.pageNumber
      + '&pageSize=' + pageable.pageSize + '&nextPage=' + nextPagePar + '&csrOuId=' + csrId,
      JSON.parse(JSON.stringify(filterValue)), { headers }) as Observable<any>;

  }

  findAllByRole(csrId, role) {
    return this.httpClient.get(this.app.getUrl() + environment.findAllByRole + '?csrId=' + csrId + '&role=' + role) as Observable<any>;
  }

  isUserLawyer() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.pravnik)) {
        if (this.localStorageService.get('primaryRole') !== null) {
          if (this.localStorageService.get('primaryRole') === ' Правник') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    }

  }

  isUserWorker() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.strucni_radnik)) {
        if (this.localStorageService.get('primaryRole') !== null) {
          if (this.localStorageService.get('primaryRole') === ' Стручни радник') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    }
  }

  isUserManager() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.rukovodilac)) {
        if (this.localStorageService.get('primaryRole') !== null) {
          if (this.localStorageService.get('primaryRole') === ' Руководилац службе') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    }
  }

  isUserEntrancer() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.prijemni_radnik)) {
        if (this.localStorageService.get('primaryRole') !== null) {
          if (this.localStorageService.get('primaryRole') === ' Пријемни радник') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    }
  }

  isUserSupervisor() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.supervizor)) {
        if (this.localStorageService.get('primaryRole') !== null) {
          if (this.localStorageService.get('primaryRole') === ' Супервизор') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    }
  }

  isUserDirector() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.direktor)) {
        if (this.localStorageService.get('primaryRole') !== null) {
          if (this.localStorageService.get('primaryRole') === ' Директор') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    }
  }

  isUserAssigner() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.raspodeljivac)) {
        if (this.localStorageService.get('primaryRole') !== null) {
          if (this.localStorageService.get('primaryRole') === ' Расподељивач') {
            return true;
          } else {
            // rukovodilac je podrazumevano i raspodeljivac
            if (this.isUserManager()) {
              return true;
            }
            return false;
          }
        } else {
          return true;
        }
      } else {
        if (this.isUserManager()) {
          return true;
        }
      }

    }
  }

  isUserDrugostepeni() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.drugostepeni_postupak)) {
        return true;
      }
    }
  }
  isUserMainBelgrade() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).csrId==='1') {
        return true;
      }
    }
    return false;
  }

  isGlobalAdmin() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.global_admin)) {
        return true;
      }
    }
    return false;
  }
  isCenterAdmin() {
    if (this.localStorageService.get('loggedUser') !== null) {
      if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.admin_centra)) {
        return true;
      }
    }
  }

  getUserRoleTitle(roles) {

    let result = [];
    if (roles.some(role => role === environment.pravnik)) {
      result.push(' Правник');
    }
    if (roles.some(role => role === environment.strucni_radnik)) {
      result.push(' Стручни радник');
    }
    if (roles.some(role => role === environment.rukovodilac)) {
      result.push(' Руководилац службе');
    }
    if (roles.some(role => role === environment.prijemni_radnik)) {
      result.push(' Пријемни радник');
    }
    if (roles.some(role => role === environment.supervizor)) {
      result.push(' Супервизор');
    }
    if (roles.some(role => role === environment.drugostepeni_postupak)) {
      result.push('Министарство другостепени поступак');
    }
    if (roles.some(role => role === environment.raspodeljivac)) {
      if (!JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.rukovodilac)) {
        result.push(' Расподељивач');
      }
    }

    if (roles.some(role => role === environment.global_admin)) {
      result.push(' Глобални администратор');
    }

    if (roles.some(role => role === environment.direktor)) {
      result.push(' Директор');
    }
    if (roles.some(role => role === environment.admin_centra)) {
      result.push(' Администратор центра');
    }

    return result;
  }


  getLoggedUser(resourceUrl: string) {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(resourceUrl, { headers });
  }

  getUsersInCenter() {
    return this.httpClient.get(this.app.getUrl() + environment.findAllByCsrIdUrl) as Observable<any>;
  }

  getUsersInSelectedOffice(officeId: any) {
    return this.httpClient.get(this.app.getUrl() + environment.findAllByOfficeIdUrl + officeId) as Observable<any>;
  }

  getUsersInCenterByCsrId(csrId: any) {
    return this.httpClient.get(this.app.getUrl() + environment.findAllByCenterIdUrl + csrId) as Observable<any>;
  }

  findUserInfo(id: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.findUserInfo + '/' + id, { headers }) as Observable<any>;
  }

  findUserData(id: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.findUserData + '/' + id, { headers }) as Observable<any>;
  }

    hasSupervisor(id: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.hasSupervisor + '/' + id, { headers }) as Observable<any>;
  }

  getUserRoles() {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.getUserRoles, { headers }) as Observable<any>;
  }

  getUserRoleDescriptions() {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.getUserRoleDescriptions, { headers }) as Observable<any>;
  }

  getIsUsernameTaken(username: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.getIsUserUsernameTaken, username, { headers }) as Observable<any>;
  }

  createUser(userList) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.createUser, userList, { headers }) as Observable<any>;
  }

  updateUser(user, userId) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.put(this.app.getUrl() + environment.updateUser + '?userId=' + userId, user, { headers }) as Observable<any>;
  }

  resetPassword(password, userId) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.resetPassword + '?userId=' + userId, password, { headers }) as Observable<any>;
  }

  enableUser(userId, enabled) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.enableUser + '?userId=' + userId +
      '&enabled=' + enabled, { headers }) as Observable<any>;
  }

  changePassword(passwords, userId) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.changePassword + '?userId=' + userId, passwords, { headers }) as Observable<any>;
  }


  getInstruction() {
    const headers = new HttpHeaders({
      'Content-type': 'application/json',
    });
    return this.httpClient.get(this.app.getUrl() + environment.getInstruction, {headers, responseType: 'blob'}) as Observable<Blob>;
  }

  changeJmbg(changeRequest: any, userId: string) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json',
    });
    return this.httpClient.post(this.app.getUrl() + environment.changeJmbg + '?userId=' + userId, changeRequest,
        { headers }) as Observable<any>;
  }

  getAssignableUsersInCenter(csrId: any) {
    return this.httpClient.get(this.app.getUrl() + environment.findAssignableUsers  + csrId) as Observable<any>;
  }

  getSignatoriesUsersInCenter(csrId: any) {
    return this.httpClient.get(this.app.getUrl() + environment.findSignatoriesUsers  + csrId) as Observable<any>;
  }
}
