/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/local-storage.service';
import { autoSelect } from 'src/app/utils/autoSelect';
import { CodebookService } from 'src/services/codebook.service';
import { StatisticsService } from 'src/services/statistics.service';
import { UserService } from 'src/services/user.service';

@Component({
   selector: 'document-stats',
   templateUrl: './document-stats.component.html',
   styleUrls: ['./document-stats.component.scss'],
})
export class DocumentStatsComponent implements OnInit {
   documentStatsForm: FormGroup;
   statTypeOptions = [
      { id: 1, name: 'документи' },
      { id: 2, name: 'предмети' },
   ];

   date1: any;
   date2: any;
   user: any = '';
   users: any = [];
   filteredUsers: Observable<any[]>;
   centers = [];
   filteredAllCenters: any = [];
   loggedIn: any;
   isMainCenterBelgrade = false;
   isUserDrugostepeni = false;
   btnDisabled: boolean;
   maxDate: Date = new Date(2999, 11, 31);
   @ViewChild('allSelectedCenters') private allSelectedCenters: MatOption;
   @ViewChild('multiSearch') private multiSearch: ElementRef;
   constructor(
      private formBuilder: FormBuilder,
      private statisticsService: StatisticsService,
      private localStorageService: LocalStorageService,
      private codebookService: CodebookService,
      private translate: TranslateService,
      private userService: UserService
   ) {
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.filteredAllCenters = this.centers;
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.filteredAllCenters = this.centers;
         });
      }
      if (!this.isMainCenterBelgrade && !this.isUserDrugostepeni) {
         this.statisticsService.getUsersInCenterByCsrIds([this.loggedIn.csrId]).subscribe(res => {
            this.users = res;
         });
      }
      this.documentStatsForm = this.formBuilder.group({
         statType: ['', []],
         workerName: ['', []],
         from: ['', []],
         to: ['', []],
         center: [''],
      });
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });
      this.btnDisabled = false;
   }

   ngOnInit(): void {
      this.findWorkers();
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }
   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.documentStatsForm);
   }
   /**
    * Send request and generate Excell report
    */
   sendRequest(form: any) {
      this.date1 = new Date(this.documentStatsForm.value.from);
      this.date1.setDate(this.date1.getDate() + 1);
      this.date2 = new Date(this.documentStatsForm.value.to);
      this.date2.setDate(this.date2.getDate() + 1);

      const newObject = {
         userId: this.documentStatsForm.value.workerName !== undefined && this.documentStatsForm.value.workerName !== '' ? this.documentStatsForm.value.workerName.id : '',
         fullname: this.documentStatsForm.value.workerName !== undefined && this.documentStatsForm.value.workerName !== '' ? this.documentStatsForm.value.workerName.name : '',
         fromDate: this.date1
            .toISOString()
            .split('T')[0]
            .replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1'),
         toDate: this.date2
            .toISOString()
            .split('T')[0]
            .replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1'),
         csrId: this.isMainCenterBelgrade || this.isUserDrugostepeni ? form.value.center : [this.loggedIn.csrId],
      };

      let filename = '';

      if (form.value.statType.id === 1) {
         this.translate.get('STATISTICS.DOCUMENT_STATS.DOCUMENTS_EXPORT').subscribe((res: string) => {
            filename = res;
         });
         this.statisticsService.getDocumentsStats(newObject, filename);
      } else {
         this.translate.get('STATISTICS.DOCUMENT_STATS.SOCIAL_CASES_EXPORT').subscribe((res: string) => {
            filename = res;
         });
         this.statisticsService.getSocialCaseStats(newObject, filename);
      }
   }

   findWorkers() {
      if (this.documentStatsForm.controls.center.value == null || this.documentStatsForm.controls.center.value == '' || this.documentStatsForm.controls.center.value == []) {
         this.users = [];
         return;
      } else if (this.documentStatsForm.controls.center.value.length > 10) {
         this.documentStatsForm.controls.workerName.disable();
         this.documentStatsForm.controls.workerName.setValue('');
         return;
      } else {
         this.documentStatsForm.controls.workerName.enable();
      }
      let centerIds = [this.loggedIn.csrId];
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
         centerIds = this.documentStatsForm.controls.center.value;
      }
      this.statisticsService.getUsersInCenterByCsrIds(centerIds).subscribe(res => {
         this.users = res;
      });
   }

   selectMulti() {
      this.multiSearch.nativeElement.focus();
      this.multiSearch.nativeElement.value = null;
      this.filteredAllCenters = this.centers;
   }

   toggleAllSelectionCenter() {
      if (this.allSelectedCenters.selected) {
         this.documentStatsForm.controls.center.patchValue([...this.centers.map(item => item.id), 0]);
      } else {
         this.documentStatsForm.controls.center.patchValue([]);
      }
      this.findWorkers();
   }
   tosslePerOneCenter() {
      if (this.allSelectedCenters.selected) {
         this.allSelectedCenters.deselect();
         return false;
      }
      if (this.documentStatsForm.controls.center.value.length === this.centers.length) {
         this.allSelectedCenters.select();
      }
      this.findWorkers();
   }
   onInputChange(inputValue: string) {
      const input = inputValue.toLowerCase();
      this.filteredAllCenters = this.centers.filter((center: any) => {
         const selectedCenters = this.documentStatsForm.controls.center.value;
         return center.name.toLowerCase().includes(input) || selectedCenters.includes(center.id);
      });
   }
}
