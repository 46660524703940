/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { CodebookService } from 'src/services/codebook.service';
import { StatisticsService } from 'src/services/statistics.service';
import { UserService } from 'src/services/user.service';

enum AutocompleteFilterEnum {
  CENTERS = 'centers',
}

@Component({
  selector: 'office-stats',
  templateUrl: './office-stats.component.html',
  styleUrls: ['./office-stats.component.scss']
})
export class OfficeStatsComponent implements OnInit {
  officeStatsForm: FormGroup;
  offices = [];
  workers = [];
  filteredWorkers = [];
  centers = [];
  filteredAllCenters: Observable<any>;
  loggedIn: any;
  isMainCenterBelgrade = false;
  btnDisabled: boolean;
  isUserDrugostepeni = false;
  maxDate: Date = new Date(2999, 11, 31);
  
  constructor(
    private formBuilder: FormBuilder,
    private codebookService: CodebookService,
    private localStorage: LocalStorageService,
    private statisticsService: StatisticsService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private userService: UserService) {
    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    this.isMainCenterBelgrade = (this.loggedIn?.csrId === '1') ? true : false;
    this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
    if (this.isMainCenterBelgrade) {
      this.codebookService.getAllSubcenters().subscribe(result => {
        this.centers = result;
        this.filteredAllCenters = this.officeStatsForm.controls.center.valueChanges.pipe(
           startWith(''),
           map((value) => (typeof value === 'string' ? value : value?.name)),
           map((title) =>
              title
                 ? this._filter(title, AutocompleteFilterEnum.CENTERS)
                 : this.centers.slice()
           )
        );      });
    }
    if(this.isUserDrugostepeni){
      this.codebookService.getCsrCodebook().subscribe((result) => {
        this.centers = result;
        this.filteredAllCenters = this.officeStatsForm.controls.center.valueChanges.pipe(
           startWith(''),
           map((value) => (typeof value === 'string' ? value : value?.name)),
           map((title) =>
              title
                 ? this._filter(title, AutocompleteFilterEnum.CENTERS)
                 : this.centers.slice()
           )
        );
     });
    }
    this.officeStatsForm = this.formBuilder.group({
      office: ['', []],
      workerName: ['', []],
      dateFrom: ['', [Validators.required]],
      dateTo: ['', [Validators.required]],
      center: ['']
    });
    this.statisticsService.getBtnStatus().subscribe((status) => {
      this.btnDisabled = status;
    });
    this.btnDisabled = false;
  }
  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.name : objectValue;
  }
  autoSelectCenter(){
    autoSelect(this.centers, 'center', 'name', this.officeStatsForm);
  }
  ngOnInit(): void {
    if (!this.isMainCenterBelgrade && !this.isUserDrugostepeni) {
      this.codebookService.getOfficeCodebookByCsrId(this.loggedIn.csrId).subscribe(result => {
        this.offices = result;
      });
      this.userService.getUsersInCenterByCsrId(this.loggedIn.csrId).subscribe(
        result => {
          this.workers = result;
          this.filteredWorkers = this.workers;
        });
    }
  }

  findWorkers(event: any) {
    this.officeStatsForm.get('workerName').setValue('');
    this.filteredWorkers = event.source.value === undefined ? this.workers : this.workers.filter(
      (option) => option.officeOuId === event.source.value.ouId);
  }

  findOffices(event: any) {    
    if (event.option.value !== undefined) {      
      const id = event.option.value.id;
      this.codebookService.getOfficeCodebookByCsrId(id).subscribe(result => {
        this.offices = result;
        this.userService.getUsersInCenterByCsrId(id).subscribe(
          res => {
            this.workers = res;
            this.filteredWorkers = this.workers;
          });
      });
    }
  }


  /**
   * Send request and generate Excell report
   */
  sendRequest(form: any) {
    const reportingPeriod = {
      from: form.value.dateFrom,
      to: form.value.dateTo
    };

    let filename = '';
    this.translate.get('STATISTICS.OFFICE_STATS.TITLE').subscribe((res: string) => {
      filename = res;
    });

    if (form.value.workerName !== '' && form.value.workerName !== undefined) {
      // trazi za odredjenog radnika
      this.statisticsService.getWorkerStatistics(form.value.workerName.id, reportingPeriod, filename);
    } else {
      if (form.value.office !== '' && form.value.office !== undefined) {
        // trazi za sluzbu
        this.statisticsService.getOfficeStatistics(form.value.office.ouId, reportingPeriod, filename);
      } else {
        // trazi za centar
        if (!this.isMainCenterBelgrade && !this.isUserDrugostepeni) {
          this.statisticsService.getCenterStatistics(reportingPeriod, JSON.parse(this.localStorage.get('loggedUser')).csrId, filename);
        } else {
          // Ako je GCBG ili drugostepeni
          this.statisticsService.getCenterStatistics(reportingPeriod, form.value.center.id, filename);
        }
      }
    }
  }

  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();
    switch (what) {
       case AutocompleteFilterEnum.CENTERS:
          return sortByFirstLetter(this.centers.filter((option) =>
             option.name.toLowerCase().includes(filterValue)), filterValue, 'name');
       default:
          break;
    }
 }
}
