<container>
    <mat-card class="card">
        <card-header title="{{ 'STATISTICS.VIOLENCE_STATS.TITLE' | translate }}">
        </card-header>
        <form [formGroup]="recordControlStatsForm" class="form">
            <field-holder kind="2-cols" *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{'GLOBAL.CENTER' | translate}}
                    </mat-label>
                    <input type="text" matInput [matAutocomplete]="autoSubcenter" formControlName="center"
                        (focusout)="autoSelectCenter()" required />
                    <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete"
                        [displayWith]="displayCustomFormat">
                        <mat-option *ngFor="let cent of filteredAllCenters | async" [value]="cent">
                            {{ cent.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </field-holder>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.VIOLENCE_STATS.RECORD' | translate }}</mat-label>
                    <mat-select formControlName="record" required (selectionChange)="recordChange($event.value)">
                        <mat-option *ngFor="let record of recordOptions" [value]="record">
                            {{ record.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols"
                *ngIf="recordControlStatsForm.controls.record.value!=null&&recordControlStatsForm.controls.record.value.id==1">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
                    <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
                        onlyNumber />
                    <mat-error *ngIf="recordControlStatsForm.controls.selectedYear.hasError('required')">
                        {{ "STATISTICS.DATE_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.VIOLENCE_STATS.TYPE_OF_VIOLENCE' | translate }}</mat-label>
                    <mat-select formControlName="typeOfViolence" required>
                        <mat-option *ngFor="let typeOfViolence of typeOfViolenceOptions" [value]="typeOfViolence">
                            {{ typeOfViolence.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.VIOLENCE_STATS.VIOLENCE_HAPPENED_CONTEXT' | translate }}</mat-label>
                    <mat-select formControlName="violenceHappenedContext" required>
                        <mat-option *ngFor="let violenceHappenedContext of violenceHappenedContextOptions" [value]="violenceHappenedContext">
                            {{ violenceHappenedContext.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols"
                *ngIf="recordControlStatsForm.controls.record.value!=null&&recordControlStatsForm.controls.record.value.id==1">
               
                <mat-form-field appearance="outline">
                    <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
                    <mat-select formControlName="ageGroup" multiple>
                        <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                            {{ record.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
                    <mat-label>{{ 'STATISTICS.FROM' | translate }}</mat-label>
                    <input matInput type="text" formControlName="customAgeGroupFrom" autocomplete="off" maxlength="3"
                      (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
                      placeholder="{{ 'STATISTICS.FROM' | translate }}" />
                    <mat-error *ngIf="recordControlStatsForm.get('customAgeGroupFrom').hasError('outOfRange')">
                      {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
                    <mat-label>{{ 'STATISTICS.TO' | translate }}</mat-label>
                    <input matInput type="text" formControlName="customAgeGroupTo" autocomplete="off" maxlength="3"
                      (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
                      placeholder="{{ 'STATISTICS.TO' | translate }}" />
                    <mat-error *ngIf="recordControlStatsForm.get('customAgeGroupTo').hasError('incorect')">
                      {{ "STATISTICS.NUMBER_OF_YEARS_INCORECT" | translate }}
                    </mat-error>
                    <mat-error *ngIf="recordControlStatsForm.get('customAgeGroupTo').hasError('outOfRange')">
                      {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
                    </mat-error>
                  </mat-form-field>
            </field-holder>
            <form-footer>
                <button [disabled]="!recordControlStatsForm.valid || btnDisabled"
                    class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
                    (click)="sendRequest(recordControlStatsForm)">
                    {{ 'STATISTICS.SUBMIT' | translate }}
                </button>
                <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </form-footer>
        </form>
    </mat-card>
</container>