/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'new-protege-property-step',
  templateUrl: './new-protege-property-step.component.html',
  styleUrls: ['./new-protege-property-step.component.scss']
})

export class NewProtegePropertyStepComponent implements OnInit {
  @Output() propertyStepEmitter = new EventEmitter();
  @Input() propertyStep: FormGroup;
  currentDate = new Date()

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
  }

  /**
   * Send step information to parent component
   */
  updatePropertyStep() {
    console.log('property information step emmiter');
    this.propertyStepEmitter.emit(this.propertyStep);
  }
}
