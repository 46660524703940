/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { DependentPersonService } from 'src/services/dependent-person.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import * as myLetters from './../../../letters';
import { NewDependentDeterminedSupportComponent } from './new-dependent-determined-support/new-dependent-determined-support.component';
import { NewDependentCourtJudgement } from './new-dependent-court-judgement/new-dependent-court-judgement.component';
import { NewDependentTerminationSupport } from './new-dependent-termination-support/new-dependent-termination-support.component';
import { NewDependentCreditorDebtor } from './new-dependent-creditor-debtor/new-dependent-creditor-debtor.component';

type TableCountType = 'courtJudgement' | 'creditor' | 'representativeCreditor' | 'debtor' | 'determinedSupport' | 'terminationSupport';

interface CourtJudgement {
   id: number;
   courtName: string;
   courtNumber: string;
   courtDate: string;
   courtValidityDate: string;
   dateOfVerdictDelivery: string;
   dateOfCommencment: string;
}

interface CreditorDebtor {
   id: number;
   firstName: string;
   lastName: string;
   dateOfBirth: string;
   placeOfBirth: string;
   jmbg: string;
   address: string;
   legalRelation: string;
}

interface DeterminedSupport {
   id: number;
   courtName: string;
   courtNumberAndDate: string;
   dateOfVerdictDelivery: string;
   dateOfCommencment: string;
}

interface TerminationSupport {
   id: number;
   courtName: string;
   courtNumberAndDate: string;
   dateOfVerdictDelivery: string;
   dateOfCommencment: string;
}

@Component({
   selector: 'new-dependent',
   templateUrl: './new-dependent.component.html',
   styleUrls: ['./new-dependent.component.scss'],
})
export class NewDependentComponent implements OnInit {
   currentDate = new Date();
   newRecord: FormGroup;
   displayedColumns: {
      courtJudgement: string[];
      creditor: string[];
      representativeCreditor: string[];
      debtor: string[];
      determinedSupport: string[];
      terminationSupport: string[];
   } = {
      courtJudgement: ['courtName', 'courtNumber', 'courtDate', 'courtValidityDate', 'dateOfVerdictDelivery', 'dateOfCommencment'],
      creditor: ['firstAndLastName', 'dateAndPlaceOfBirth', 'jmbg', 'address', 'legalRelation'],
      representativeCreditor: ['firstAndLastName', 'dateAndPlaceOfBirth', 'jmbg', 'address', 'legalRelation'],
      debtor: ['firstAndLastName', 'dateAndPlaceOfBirth', 'jmbg', 'address', 'legalRelation'],
      determinedSupport: ['supportMannerMoney', 'supportMannerOther', 'supportAmmountFiscal', 'supportAmmountPercentage', 'supportDurationLimited', 'supportDurationUnlimited'],
      terminationSupport: ['courtName', 'courtNumber', 'courtDate', 'dateOfVerdictTermination', 'reasonsForVerdictTermination'],
   };
   table: {
      courtJudgement: CourtJudgement[];
      creditor: CreditorDebtor[];
      representativeCreditor: CreditorDebtor[];
      debtor: CreditorDebtor[];
      determinedSupport: DeterminedSupport[];
      terminationSupport: TerminationSupport[];
   } = {
      courtJudgement: [],
      creditor: [],
      representativeCreditor: [],
      debtor: [],
      determinedSupport: [],
      terminationSupport: [],
   };
   isTableRecordSelected = {
      courtJudgement: false,
      creditor: false,
      representativeCreditor: false,
      debtor: false,
      determinedSupport: false,
      terminationSupport: false,
   };
   selectedTableRecord = {
      courtJudgement: null,
      creditor: null,
      representativeCreditor: null,
      debtor: null,
      determinedSupport: null,
      terminationSupport: null,
   };
   constructor(
      private datePipe: DatePipe,
      private formBuilder: FormBuilder,
      public dialog: MatDialog,
      private dependentPersonService: DependentPersonService,
      private router: Router,
      private translate: TranslateService,
      private toastr: ToastrImplService
   ) {}

   ngOnInit(): void {
      this.newRecord = this.formBuilder.group({
         firstName: ['', [Validators.required, Validators.pattern(myLetters.letters)]],
         lastName: ['', [Validators.required, Validators.pattern(myLetters.letters)]],
         subjectId: ['', [Validators.required, Validators.pattern(myLetters.number)]],
         registrationId: [''],
         dateOfBirth: [null],
         jmbg: [''],
      });
   }

   createRecord(newRecord: any) {
      // Submit new record
      this.createNewDependentPerson();
   }

   createNewDependentPerson() {
      // olja.andjelovski@iten.rs
      // Moraju id-jevi da se postave na null zato sto se kod nas u bazi automatski generisu, ukoliko bi imao
      // kreiran id uzeo bi taj sto bi prouzrokovalo gresku ukoliko kod nas u bazi ima vec neko sa tim id-jem
      this.table.courtJudgement.forEach(x => {
         x.id = null;
      });
      this.table.creditor.forEach(x => {
         x.id = null;
      });
      this.table.representativeCreditor.forEach(x => {
         x.id = null;
      });
      this.table.debtor.forEach(x => {
         x.id = null;
      });
      this.table.determinedSupport.forEach(x => {
         x.id = null;
      });
      this.table.terminationSupport.forEach(x => {
         x.id = null;
      });

      const body = {
         firstName: this.newRecord.value.firstName,
         lastName: this.newRecord.value.lastName,
         jmbg: this.newRecord.value.jmbg,
         dateOfBirth: this.newRecord.value.dateOfBirth !== null ? this.datePipe.transform(new Date(this.newRecord.value.dateOfBirth).getTime(), 'dd/MM/yyyy') : null,
         subjectId: this.newRecord.value.subjectId,
         registrationId: this.newRecord.value.registrationId,
         courtJudgementInfo: this.table.courtJudgement,
         creditorInfo: this.table.creditor,
         representativeCreditorInfo: this.table.representativeCreditor,
         debtorInfo: this.table.debtor,
         determinedSupportInfo: this.table.determinedSupport,
         terminationSupportInfo: this.table.terminationSupport,
      };
      this.dependentPersonService.createDependentPerson(body).subscribe(res => {
         if (res != null) {
            this.toastr.success('SNACKBAR.ADDED_DEPEDENT_PERSON');
            this.router.navigateByUrl('/dependents');
         } else {
            this.toastr.error('SNACKBAR.ADD_DEPEDENT_PERSON_ERROR');
            this.router.navigateByUrl('/dependents');
         }
      });
   }

   addTableRecord(tableCount: TableCountType): void {
      let dialogRef: any;
      if (tableCount === 'courtJudgement') {
         dialogRef = this.dialog.open(NewDependentCourtJudgement, {
            width: ModalSizeEnum.EXTRA_LARGE,
            panelClass: 'overlay-panel',
         });
      }

      if (tableCount === 'creditor' || tableCount === 'representativeCreditor' || tableCount === 'debtor') {
         dialogRef = this.dialog.open(NewDependentCreditorDebtor, {
            width: ModalSizeEnum.EXTRA_LARGE,
            panelClass: 'overlay-panel',
         });
      }

      if (tableCount === 'determinedSupport') {
         dialogRef = this.dialog.open(NewDependentDeterminedSupportComponent, {
            width: ModalSizeEnum.EXTRA_LARGE,
            panelClass: 'overlay-panel',
         });
      }

      if (tableCount === 'terminationSupport') {
         dialogRef = this.dialog.open(NewDependentTerminationSupport, {
            width: ModalSizeEnum.EXTRA_LARGE,
            panelClass: 'overlay-panel',
         });
      }

      dialogRef.afterClosed().subscribe((result: any) => {
         if (result && result.event === ModalEventEnum.SUCCESS) {
            const newRecord = {
               id: this.table[tableCount].length,
               ...result.data,
            };

            this.table[tableCount] = [...this.table[tableCount], newRecord];
            this.toastr.success('SNACKBAR.INPUT_ADDED');
         }
      });
   }

   onRowSelected(tableCount: TableCountType, row: any) {
      if (this.selectedTableRecord[tableCount] !== null) {
         this.selectedTableRecord[tableCount].highlighted = !this.selectedTableRecord[tableCount].highlighted;
      }
      row.highlighted = !row.highlighted;
      this.selectedTableRecord[tableCount] = row;
      this.isTableRecordSelected[tableCount] = true;
   }

   removeTableRecord(tableCount: TableCountType) {
      let updatedState: any[] = [];

      if (tableCount === 'courtJudgement') {
         updatedState = this.table.courtJudgement.filter((record: CourtJudgement) => record.id !== this.selectedTableRecord.courtJudgement?.id);
      } else if (tableCount === 'creditor') {
         updatedState = this.table.creditor.filter((record: CreditorDebtor) => record.id !== this.selectedTableRecord.creditor?.id);
      } else if (tableCount === 'representativeCreditor') {
         updatedState = this.table.representativeCreditor.filter((record: CreditorDebtor) => record.id !== this.selectedTableRecord.representativeCreditor?.id);
      } else if (tableCount === 'debtor') {
         updatedState = this.table.debtor.filter((record: CreditorDebtor) => record.id !== this.selectedTableRecord.debtor?.id);
      } else if (tableCount === 'determinedSupport') {
         updatedState = this.table.determinedSupport.filter((record: DeterminedSupport) => record.id !== this.selectedTableRecord.determinedSupport?.id);
      } else if (tableCount === 'terminationSupport') {
         updatedState = this.table.terminationSupport.filter((record: TerminationSupport) => record.id !== this.selectedTableRecord.terminationSupport?.id);
      }

      this.table[tableCount] = updatedState;
      this.isTableRecordSelected[tableCount] = false;
      this.toastr.success('SNACKBAR.DELETED_ELEMENT');
   }

   importSubjectData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            this.newRecord.patchValue({
               firstName: result.data.firstName,
               lastName: result.data.lastName,
               subjectId: result.data.subjectIdentity.subjectId,
               registrationId: result.data.registrationId,
               jmbg: result.data.jmbg,
               dateOfBirth: result.data.dateOfBirth,
            });
            const obj = JSON.parse(JSON.stringify(result));
         }
      });
   }
}
