/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlfrescoService } from 'src/services/alfresco.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/services/authentication.service';
import { CaseService } from 'src/services/case.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { CodebookService } from 'src/services/codebook.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { patchForDecimalNumber, getNumberFromString } from 'src/app/utils/formatLocalNumber';
import { CaseProblemRecordService } from 'src/services/case-problem-record.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ExpeditionService } from 'src/services/expedition.service';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { ModalSizeEnum } from 'src/types';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { fileCheck } from 'src/app/utils/fileInputUtil';

interface SelectOptions {
   value: any;
   viewValue: string;
}

/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 * Komponenta koja se odnosti na zavrsetak rada na predmetu
 */
@Component({
   selector: 'app-case-conclusion',
   templateUrl: './case-conclusion.component.html',
   styleUrls: ['./case-conclusion.component.scss'],
})
export class CaseConclusionComponent implements OnInit {
   @Input() subjectId: any;
   @Input() caseId: any;
   @Input() templateKind: any; // Na osnovu templateKind ide u alfresco i skida resnja (dokumente)
   @Input() disableFields: any;
   @Input() isNsp: boolean;
   @Input() customData: any;
   @Input() complain: any;
   @Input() hasProblem: boolean;
   newComplain: any = false;
   caseData: any;
   isLawyer: any = false;
   paymentRecipients: any = [];
   caseConclusion: FormGroup;
   fileToUpload: File = null;
   documentOptions: any;
   decisionTypes: [];
   showPreview: boolean;
   notValidDate: any = false;
   @Input() isDenial: boolean;
   decisionMakerOptions: any;
   child: any;
   loggedIn: any;
   parent: any;
   isReopened: boolean;
   loadedData: any;
   yearlyReconsideration: boolean;
   initiatorCodebook: any;
   nspNmValidFrom: any;
   isSubmited = false;
   terminationReasons: any = [];
   minToDate: Date;
   maxDate: Date = new Date(2999, 11, 31);
   @ViewChild('submitButton') submitButton: any;
   @ViewChild('file') file: ElementRef<HTMLInputElement>;

   constructor(
      private alfrescoService: AlfrescoService,
      private router: Router,
      private caseService: CaseService,
      private authentication: AuthenticationService,
      private formBuilder: FormBuilder,
      private localStorageService: LocalStorageService,
      private translate: TranslateService,
      private codebookService: CodebookService,
      private toastr: ToastrImplService,
      private datePipe: DatePipe,
      public dialog: MatDialog,
      private expedition: ExpeditionService,
      private caseProblemRecordService: CaseProblemRecordService
   ) {
      this.codebookService.getDecisionTypeCodebook().subscribe(result => {
         this.decisionTypes = result;
         this.checkIsDenial();
      });

      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
   }

   ngOnInit(): void {
      this.getInitiatorCodebook();
      this.getDocumentsByTemplate();
      this.createCaseConclusionForm();
      this.caseService.getSocialCase(this.caseId).subscribe(res => {
         this.caseData = res;
      });

      this.codebookService.getDecisionMakerCodebook().subscribe(result => {
         this.decisionMakerOptions = result;
         this.caseConclusion.controls.decisionMaker.setValue(result[0]);
         this.caseConclusion.controls.decisionMakerTitle.setValue(result[0].title);

         this.caseService.findNspBySocialCaseId(this.caseId).subscribe(res => {
            if (res) {
               this.isReopened = true;
               this.loadedData = res;
               this.patchValue(res);
            }
         });
      });

      this.caseService.checkIfHaveActiveRight(this.caseId).subscribe(res => {
         if (res === true) {
            this.toastr.warning('NSP_MOCK.CONCLUSION.FAMILY_HAS_ACTIVE_RIGHT');
         }
      });
      this.getPaymentRecipientCodebook();

      this.caseConclusion.get('validFrom').valueChanges.subscribe((fromDate: Date) => {
         this.minToDate = fromDate;
      });
   }

   ngOnChanges(changes: any) {
      if (changes.customData !== undefined) {
         if (Object.keys(changes.customData.currentValue).length !== 0) {
            if (changes.customData && changes.customData.currentValue) {
               if (!this.isReopened) {
                  this.patchValue(changes.customData.currentValue);
               }
            }
         }
      }
      if (changes.isDenial !== undefined) {
         this.checkIsDenial();
      }
      if (changes.complain !== undefined) {
         this.newComplain = changes.complain.currentValue;
      }
   }

   getInitiatorCodebook() {
      this.codebookService.getInitiatorCodebook().subscribe(res => {
         this.initiatorCodebook = res;
      });
   }

   getDocumentsByTemplate() {
      this.alfrescoService.getDocumentTemplates(this.templateKind).subscribe(result => {
         this.documentOptions = result;
      });
   }

   checkIsDenial() {
      if (this.isDenial && Number(this.decisionTypes.length) === 5) {
         this.decisionTypes.splice(0, 1);
      }
   }

   createCaseConclusionForm() {
      this.caseConclusion = this.formBuilder.group({
         lawyerFile: [''],
         fileName: [''],
         lawyerComment: [''],
         subjectId: this.subjectId,
         caseId: this.caseId,
         nspAmount: [''],
         validFrom: [''],
         validTo: [''],
         terminationDate: [null],
         inactive: [null],
         decisionType: [''], // dodati na BE
         decisionMaker: [''], // dodati na BE
         decisionMakerDescription: [], // dodati na BE
         initiator: [''],

         child: [''],
         parentFirstName: new FormControl({ value: '', disabled: true }),
         parentLastName: new FormControl({ value: '', disabled: true }),
         parentResidenceAddress: new FormControl({ value: '', disabled: true }),
         parentAddress: new FormControl({ value: '', disabled: true }),
         parentAddressNum: new FormControl({ value: '', disabled: true }),
         parentRegBr: new FormControl({ value: '', disabled: true }),
         paymentRecipient: null,

         yearlyReconsideration: [false],
         decisionMakerName: [''],
         decisionMakerTitle: [''],
         terminationReason: [''],
      });
   }

   handleNewEvaluation(doc: any) {
      //    window.location.href = 'ms-word:ofe|u|' + url;
      const body = {
         documentId: doc.documentId,
         fileName: doc.name,
         caseId: this.caseId === undefined ? null : this.caseId,
      };
      this.alfrescoService.downloadDocumentByUrl(body);
   }

   handleFileInput(files: FileList) {
      const modifiedFiles = fileCheck(files, this.dialog, this.translate);
      if (modifiedFiles.length > 0) {
         this.fileToUpload = modifiedFiles[0];
         this.caseConclusion.get('lawyerFile').setValue(this.fileToUpload);
         this.caseConclusion.get('fileName').setValue(this.fileToUpload.name);
      }
      this.file.nativeElement.value = null;
   }

   cancelCase() {
      this.router.navigate(['/cases']);
   }

   saveCurrentData() {
      const nspObject = { ...this.createNspObject(), isTempSave: true };

      this.caseService.saveNspCase(nspObject).subscribe(
         nspCaseId => {
            if (nspCaseId) {
               this.loadedData = { ...this.loadedData, id: nspCaseId };
               this.toastr.success('SNACKBAR.SOLUTION_SAVED');
               this.caseConclusion.markAsPristine();
            } else {
               const decisionTypeId = nspObject.decisionTypeCodebook.id;
               if (decisionTypeId === 1) {
                  this.toastr.warning('SNACKBAR.USER_ALREADY_HAVE_RIGHT_NSP');
               } else if (decisionTypeId === 3) {
                  this.toastr.warning('SNACKBAR.USER_NOT_HAVE_RIGHT_NSP');
               }
            }
         },
         error => {
            manageError(this.toastr, ErrorType.SAVE_DATA);
         }
      );
   }
   /* Ako je ulogovani radnik zaduzen predmetom, onda se rijesenje/dokument koji napravi uploaduje u dokumenta korisnika,
      predmet se zatvara, a potom se salje na pisarnicu putem integracije.*/

   // nspObject.decisionTypeCodebook.id ukoliko je 1 onda je priznavanje ukoliko je razlicito onda je odbijanje ili prestanak
   submitConclusion() {
      this.isSubmited = true;

      this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
      const nspObject = { ...this.createNspObject(), isTempSave: false };
      const decisionTypeId = nspObject.decisionTypeCodebook.id;

      this.caseService.saveNspCase(nspObject).subscribe(
         nspCaseId => {
            if (nspCaseId !== null) {
               this.loadedData = { ...nspObject, id: nspCaseId };

               this.handleSaveNspSuccess(decisionTypeId, nspCaseId);
            } else {
               this.handleSaveNspInvalidRequest(decisionTypeId);
            }
         },
         error => {
            manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
            this.enableSubmitButton();
         }
      );
   }

   patchValue(data: any) {
      if (data.nspAmount < 0) {
         this.toastr.info('NSP_MOCK.CONCLUSION.SALARY_NOT_SATISFIED', 'NSP_MOCK.CONCLUSION.CHECK');
      }

      if (this.isReopened) {
         let decisionMaker = null;
         if (data.decisionMaker) {
            decisionMaker = data.decisionMaker.id === 1 ? this.decisionMakerOptions[0] : this.decisionMakerOptions[1];
         }
         this.caseConclusion.patchValue({
            nspAmount: Number(data.amount) < 0 ? 0 : patchForDecimalNumber(data.amount),
            validFrom: data.validFrom !== null ? new Date(data.validFrom.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : data.validFrom,
            validTo: data.validTo !== null ? new Date(data.validTo.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : data.validTo,
            decisionType: data.decisionTypeCodebook,
            decisionMaker: this.decisionMakerOptions === undefined ? null : this.decisionMakerOptions[data.socialCase.decisionMaker.id - 1],
            decisionMakerDescription: data.socialCase.decisionMakerDescription,
            inactive: data.inactive,
            terminationDate: data.terminationDate !== null ? new Date(data.terminationDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : data.terminationDate,
            child: data.socialCase.nsp.child,
            paymentRecipient: data.socialCase.nsp.paymentRecipient,
            initiator: data.initiatorCodebook,
            decisionMakerName: this.loadedData.socialCase.decisionMakerName,
            decisionMakerTitle: this.loadedData.socialCase.decisionMakerTitle,
            terminationReason: this.loadedData.terminationReason,
         });
         this.child = data.socialCase.nsp.child;
         this.setParent(data.socialCase.nsp.parent);
         this.showPreviewButton(data.decisionTypeCodebook);
         this.caseConclusion.controls.yearlyReconsideration.setValue(data.yearlyReconsideration.id === 1);
         this.nspNmValidFrom = new Date(data.socialCase.nsp.validFrom.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
      } else {
         this.caseConclusion.patchValue({
            nspAmount: Number(data.nspAmount) < 0 ? 0 : patchForDecimalNumber(data.nspAmount),
            validFrom: data.validFrom !== null ? new Date(data.validFrom.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : data.validFrom,
            validTo: data.validTo !== null ? new Date(data.validTo.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : data.validTo,
         });
         this.nspNmValidFrom = new Date(data.validFrom.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
      }
   }

   showPreviewButton(selection: any) {
      if (selection != null && selection.id === 1) {
         this.showPreview = true;
         this.caseConclusion.controls.fileName.clearValidators();
         this.caseConclusion.controls.fileName.setErrors(null);
         this.caseConclusion.controls.fileName.disable();
         this.setAmount(this.getDate(this.caseConclusion.value.validFrom));
      } else {
         this.showPreview = false;
         this.caseConclusion.controls.fileName.setValidators(Validators.required);
         this.caseConclusion.controls.fileName.enable();
      }
      if (selection?.id === 3) {
         this.caseConclusion.controls.terminationReason.enable();
         this.caseConclusion.controls.terminationReason.setValidators(Validators.required);
         if (this.terminationReasons.length == 0) {
            this.codebookService.getTerminationReasonByType('NSP').subscribe(res => {
               this.terminationReasons = res;
            });
         }
      } else {
         this.caseConclusion.controls.terminationReason.disable();
         this.caseConclusion.controls.terminationReason.setErrors(null);
         this.caseConclusion.controls.terminationReason.setValue(null);
         this.caseConclusion.controls.terminationReason.clearValidators();
      }
      if (this.caseConclusion.value.validFrom == null) {
         this.caseConclusion.controls.validFrom.setValue(this.nspNmValidFrom);
      }
   }

   createNspObject() {
      const nspObject = {
         id: this.loadedData ? this.loadedData.id : null,
         socialCaseId: this.caseData.id,
         dateOfDecisionCreation: this.datePipe.transform(new Date().getTime(), 'yyyy-MM-dd'),
         decisionTypeCodebook: this.caseConclusion.value.decisionType !== '' ? this.caseConclusion.value.decisionType : null,
         validFrom: this.datePipe.transform(new Date(this.caseConclusion.value.validFrom).getTime(), 'yyyy-MM-dd'),
         validTo: this.caseConclusion.value.validTo !== null ? this.datePipe.transform(new Date(this.caseConclusion.value.validTo).getTime(), 'yyyy-MM-dd') : null,
         amount: getNumberFromString(this.caseConclusion.value.nspAmount),
         inactive: this.caseConclusion.value.inactive,
         terminationDate: this.caseConclusion.value.terminationDate !== null ? this.datePipe.transform(new Date(this.caseConclusion.value.terminationDate).getTime(), 'yyyy-MM-dd') : null,
         child: this.caseConclusion.value.child,
         parentId: this.parent?.subjectIdentity?.subjectId,
         paymentRecipient: this.caseConclusion.value.paymentRecipient,
         decisionMaker: this.caseConclusion.value.decisionMaker ? this.caseConclusion.value.decisionMaker : null,
         decisionMakerDescription: this.caseConclusion.value.decisionMaker?.id === 2 ? this.caseConclusion.value.decisionMakerDescription : '',
         decisionMakerName: this.caseConclusion.value.decisionMakerName ? this.caseConclusion.value.decisionMakerName : '',
         decisionMakerTitle: this.caseConclusion.value.decisionMakerTitle ? this.caseConclusion.value.decisionMakerTitle : '',
         yearlyReconsideration: this.caseConclusion.value.yearlyReconsideration,
         initiatorCodebook: this.caseConclusion.value.initiator !== '' ? this.caseConclusion.value.initiator : null,
         terminationReason: this.caseConclusion.value.terminationReason !== '' ? this.caseConclusion.value.terminationReason : null,
      };
      return nspObject;
   }

   async handleSaveNspSuccess(decisionTypeId: number, nspCaseId: number) {
      // ako nije priznavanje
      const addNewRecordRequered = await this.addNewRecord(this.caseConclusion.controls.decisionType.value.id);
      if (addNewRecordRequered) {
         if (decisionTypeId !== 1) {
            this.caseService.addConclusionFinishCase(this.caseConclusion.get('lawyerFile').value, this.caseConclusion.get('subjectId').value, this.caseConclusion.get('caseId').value).subscribe(
               (documentId: any) => {
                  this.toastr.success('SNACKBAR.SOLUTION_CREATED');
                  if (documentId) {
                     this.expedition.send({ documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'cc');
                  }
               },
               error => {
                  this.enableSubmitButton();
                  this.toastr.error('SNACKBAR.DOCUMENT_ADD_ERROR');
               }
            );
         } else {
            this.caseService.saveNspConclusion(nspCaseId).subscribe(
               (documentId: any) => {
                  this.toastr.success('SNACKBAR.SOLUTION_CREATED');
                  if (documentId) {
                     this.expedition.send({ documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'cc');
                  }
               },
               error => {
                  this.enableSubmitButton();
                  this.toastr.error('SNACKBAR.DOCUMENT_ADD_ERROR');
               }
            );
         }
      } else {
         this.enableSubmitButton();
      }
   }

   handleSaveNspInvalidRequest(decisionTypeId: number) {
      this.enableSubmitButton();
      if (decisionTypeId === 1) {
         this.toastr.warning('SNACKBAR.USER_ALREADY_HAVE_RIGHT_NSP');
      } else if (decisionTypeId === 3) {
         this.toastr.warning('SNACKBAR.USER_NOT_HAVE_RIGHT_NSP');
      }
   }

   preview() {
      const nspObject = this.createNspObject();
      this.caseService.previewNspDecision(this.caseId, nspObject);
   }

   importSubjectData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         this.setParent(result.data);
      });
   }

   setParent(subject: any) {
      if (subject) {
         this.parent = subject;
         this.caseConclusion.controls.parentFirstName.setValue(subject.firstName);
         this.caseConclusion.controls.parentLastName.setValue(subject.lastName);
         this.caseConclusion.controls.parentResidenceAddress.setValue(subject.permanentResidence.town);
         this.caseConclusion.controls.parentAddress.setValue(subject.permanentResidence.street);
         this.caseConclusion.controls.parentAddressNum.setValue(subject.permanentResidence.number);
         this.caseConclusion.controls.parentRegBr.setValue(subject.registrationId);
         this.caseConclusion.controls.subjectId.setValue(subject.subjectIdentity.subjectId);

         this.caseConclusion.controls.parentFirstName.disable();
         this.caseConclusion.controls.parentLastName.disable();
         this.caseConclusion.controls.parentAddress.disable();
         this.caseConclusion.controls.parentResidenceAddress.disable();
         this.caseConclusion.controls.parentAddressNum.disable();
         this.caseConclusion.controls.parentRegBr.disable();
      }
   }

   changeChildCheckbox() {
      this.child = this.caseConclusion.value.child;
   }
   changeYearlyReconsideration() {
      this.yearlyReconsideration = this.caseConclusion.value.yearlyReconsideration;
   }

   compareLoadedObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
   }

   getPaymentRecipientCodebook() {
      this.codebookService.getPaymentRecipientCodebook().subscribe(response => {
         this.paymentRecipients = response;
      });
   }

   decisionMakerSelected(event: any) {
      if (event.value.id === 2) {
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TEXT').subscribe((res: string) => {
            this.caseConclusion.controls.decisionMakerDescription.setValue(res);
         });
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TITLE').subscribe((res: string) => {
            this.caseConclusion.controls.decisionMakerTitle.setValue(res);
         });
      } else {
         this.caseConclusion.controls.decisionMakerDescription.setValue('');
         this.caseConclusion.controls.decisionMakerTitle.setValue(event.value.title);
      }
   }
   changeNspRightCodebook(dateString: any) {
      const date = this.getDate(dateString);
      this.setAmount(date);
      if (date === null || this.nspNmValidFrom.getTime() !== date.getTime()) {
         this.toastr.info('NSP_MOCK.CONCLUSION.DATE_DONT_MATCH_OBSERVATION');
      }
   }
   setAmount(date: any) {
      if (this.caseConclusion.value.decisionType.id === 1) {
         this.caseService.findAmountForNsp(this.caseId, date.getTime()).subscribe((result: number) => {
            this.caseConclusion.controls.nspAmount.setValue(patchForDecimalNumber(result.toFixed(2)));
         });
      } else {
         this.caseConclusion.controls.nspAmount.setValue(0);
      }
   }
   getDate(dateObject: any) {
      if (dateObject instanceof Date) {
         return dateObject;
      } else if (dateObject instanceof String || typeof dateObject === 'string') {
         if (dateObject.trim() !== '' && dateObject.includes('.') && dateObject.split('.').length >= 3) {
            const dateSplited = dateObject.split('.');
            return new Date(Number(dateSplited[2]), Number(dateSplited[1]) - 1, Number(dateSplited[0]));
         } else {
            return null;
         }
      }
   }

   async addNewRecord(decision: any): Promise<boolean> {
      if (decision === 1) {
         try {
            return await this.caseProblemRecordService.addNewRecord(this.subjectId, this.caseId, this.caseData?.caseKindCodebook?.kind, {
               fromDate: this.caseConclusion.controls.validFrom.value,
               toDate: this.caseConclusion.controls.validTo.value,
               amount: this.caseConclusion.controls.nspAmount.value,
            });
         } catch (error) {
            return false;
         }
      }
      return true;
   }
   private enableSubmitButton() {
      this.submitButton.disabled = false;
      this.isSubmited = false;
   }
}
