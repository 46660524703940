import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SendingDocumentsComponent } from 'src/app/components/sending-documents/sending-documents.component';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ModalSizeEnum } from 'src/types';
import { SideNavService } from './side-nav.service';
import { ToastrImplService } from './toastr.service';

@Injectable({
   providedIn: 'root',
})
export class ExpeditionService {
   object = {
      document: {
         documentId: '',
         caseId: '',
         caseName: '',
      },
      action: '',
      subjectId: '',
      caseNumber: '',
   };

   constructor(
      public dialog: MatDialog,
      private toastr: ToastrImplService,
      private router: Router,
      private sideNavService: SideNavService,
      private localStorageService: LocalStorageService
   ) {}

   send(data: any, type: string) {
      this.object.document.documentId = data.documentId;
      this.object.document.caseId = data.caseId;
      this.object.document.caseName = data.caseName;
      this.object.action = 'SEND';
      this.object.subjectId = data.subjectId;
      const dialogRef = this.dialog.open(SendingDocumentsComponent, {
         disableClose: true,
         width: ModalSizeEnum.EXTRA_LARGE,
         data: this.object,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event } = result;
         if (event === 'Create') {
            this.toastr.success('SNACKBAR.DOCUMENT_SENT');
         } else if (event === 'Unsuccessful') {
            this.toastr.error('SNACKBAR.DOCUMENT_SEND_ERROR');
         }
         if (type === 'acc') this.redirectToSubject(data.subjectId);
         else if (type === 'cc') this.caseConclusion(data.subjectId);
         else this.router.navigateByUrl('/subjects/' + data.subjectId);
      });
   }

   redirectToSubject(subjectId: any) {
      this.toastr.success('SNACKBAR.SOLUTION_CREATED');
      this.router.navigateByUrl('/subjects/' + subjectId);
      this.sideNavService.toggle();
   }
   caseConclusion(subjectId: any) {
      this.localStorageService.set('case', true);
      this.sideNavService.toggle();
      this.router.navigateByUrl('/subjects/' + subjectId);
   }
}
