/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { Pageable } from 'src/app/pageable';
import { Observable } from 'rxjs';
import { Page } from 'src/app/page';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppService } from './app.service';
import { Activity } from 'src/models/activity.model';
import * as printJS from 'print-js';

@Injectable({
   providedIn: 'root',
})
export class TrackingListService {
   constructor(private httpClient: HttpClient, private app: AppService) {}

   getSubjectFilteredPage(pageable: Pageable, filterValue: string, subjectId: string): Observable<Page<Activity>> {
      return this.httpClient.post(this.app.getUrl() + environment.getTrackingPaginationUrl + pageable.pageNumber + '/' + pageable.pageSize + '/' + subjectId, filterValue) as Observable<any>;
   }

   getCaseFilteredPage(pageable: Pageable, filterValue: string, caseId: string): Observable<Page<Activity>> {
      return this.httpClient.post(this.app.getUrl() + environment.getTrackingCasePaginationUrl + pageable.pageNumber + '/' + pageable.pageSize + '/' + caseId, filterValue) as Observable<any>;
   }
   getSubjectFilteredPageSecondDegree(pageable: Pageable, filterValue: string, subjectId: string, csrId: any): Observable<Page<Activity>> {
      return this.httpClient.post(
         this.app.getUrl() + environment.getTrackingPaginationSecondDegree + pageable.pageNumber + '/' + pageable.pageSize + '/' + subjectId + '/' + csrId,
         filterValue
      ) as Observable<any>;
   }

   getCaseFilteredPageSecondDegree(pageable: Pageable, filterValue: string, caseId: string): Observable<Page<Activity>> {
      return this.httpClient.post(this.app.getUrl() + environment.getTrackingPaginationSecondDegree + pageable.pageNumber + '/' + pageable.pageSize + '/' + caseId, filterValue) as Observable<any>;
   }

   addItem(item: Object) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.addTrackingListItem, item, { headers }) as Observable<any>;
   }

   removeItem(id: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.delete(this.app.getUrl() + environment.removeTrackingListItem + id, { headers }) as Observable<any>;
   }

   print(body: any, csrIdSecondDegree: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      let params = null;
      if (csrIdSecondDegree != null && csrIdSecondDegree !== undefined) {
         params = new HttpParams().set('csrIdSecondDegree', csrIdSecondDegree);
      }
      this.httpClient.put(this.app.getUrl() + environment.getPrintTrackingListUrl, body, { responseType: 'blob', headers, params }).subscribe(data => {
         const file = new Blob([data], { type: 'application/pdf' });
         const fileURL = URL.createObjectURL(file);
         printJS(fileURL);
      });
   }
}
