/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Page } from 'src/app/page';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { ProtegePersonService } from 'src/services/protege-person.service';
import { SharedCaseService } from 'src/services/shared-case.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalSizeEnum } from 'src/types';
import { PreviewDocumentComponent } from '../../sidebar-widget/preview-document/preview-document.component';
import { NewProtegeSubjectComponent } from './new-protege-subject/new-protege-subject.component';

@Component({
   selector: 'proteges',
   templateUrl: './proteges.component.html',
   styleUrls: ['./proteges.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class ProtegesComponent implements OnInit {
   selectable = true;
   removable = true;
   readonly separatorKeysCodes: number[] = [COMMA, ENTER];
   public searchTerms: string[] = [];
   year: any;
   nowYear: any = new Date().getFullYear();
   closed = false;
   public globalFilter = '';
   public searchItems: string[] = [];
   page: Page<any> = new Page();
   myInput = '';
   object: { origin: string };

   displayedColumns: string[] = ['rBr', 'id', 
      // 'registrationId', 
      'guardianshipId', 'firstName', 'lastName', 'fatherFirstName', 'guardianshipType', 'dateOfBirth', 'jmbg', 'actions'];

   constructor(
      private protegePersonService: ProtegePersonService,
      private sharedCaseService: SharedCaseService,
      public dialog: MatDialog,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private paginationService: CustomPaginationService,
      public router: Router,
      private toastr: ToastrImplService
   ) {
      this.clearSharedCaseService();
      this.object = { ...data };
      this.year = new Date().getFullYear();
      this.checkStatus();
      this.doFilter();
   }

   ngOnInit(): void {}
   checkStatus() {
      this.protegePersonService.checkCollectionStatus(this.year).subscribe(
         result => {
            this.closed = result;
         },
         () => {
            this.closed = false;
         }
      );
   }
   public getNextPage(): void {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilter();
   }

   public getPreviousPage(): void {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilter();
   }

   public getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.doFilter();
   }

   public getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.doFilter();
   }

   public getPageInNewSize(pageSize: any): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.doFilter();
   }

   handleCreateNewSubject() {
      const dialogRef = this.dialog.open(NewProtegeSubjectComponent, {
         width: ModalSizeEnum.MINI,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(result => {
         if (result != null) {
            const { event, data } = result;
            if (event === 'Success') {
               this.toastr.success('SNACKBAR.ADDED_PROTEGE_PERSON');
               this.doFilter();
            } else if (event === 'Unsuccessful') {
               this.toastr.error('SNACKBAR.ADD_PROTEGE_PERSON_ERROR');
            }
         }
      });
   }

   onRowClicked(element: any) {
      if (element?.protegePersonCollectionId) {
         this.router.navigateByUrl('/proteges/' + element.id);
      }
   }

   doFilter() {
      if (this.year === undefined) {
         this.year = new Date().getFullYear();
      }
      const body = {
         text: this.searchItems,
         year: this.year,
      };
      this.protegePersonService.getFilteredPage(this.page.pageable, body).subscribe(page => {
         this.page = page;
         this.page.pageable.pagePerShow = page.number + 1;
         for (const element of page.content) {
            element.dateOfBirth = element.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
         }
      });
   }

   add(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;

      // Add new search term
      if ((value || '').trim()) {
         this.searchItems.push(value.trim());
      }
      // Reset the input value
      if (input) {
         input.value = '';
      }
      this.searchTerms = this.searchItems.map(searchItem => searchItem);
      this.globalFilter = '';
      this.applyFilter(this.searchTerms);
   }

   remove(item: any): void {
      const index = this.searchItems.indexOf(item);
      if (index >= 0) {
         this.searchItems.splice(index, 1);
         this.searchTerms = this.searchItems.map(function (searchItem) {
            return searchItem;
         });
         this.applyFilter(this.searchTerms);
      }
   }

   applyFilter(filterValue: any) {
      this.globalFilter = filterValue;
      this.doFilter();
   }

   clearSharedCaseService() {
      this.sharedCaseService.tabs = [];
      this.sharedCaseService.activeTab = null;
   }

   yearChanged() {
      if (this.year === undefined) {
         this.year = this.nowYear;
      }
      if (this.year !== undefined && this.year !== '') {
         this.checkStatus();
      }
      this.doFilter();
   }

   closeCollection() {
      this.protegePersonService.closeCollection(this.year).subscribe(
         result => {
            this.closed = true;
         },
         () => {
            this.closed = false;
         }
      );
   }

   public exportCsv(): void {
      const body = {
         text: this.searchItems,
         year: this.year,
      };
      this.protegePersonService.exportCSV(body);
   }

   printPDF() {
      const body = {
         text: this.searchItems,
         year: this.year,
      };
      this.protegePersonService.getPdf(body);
   }
}