<form [formGroup]="personalInformationStep" class="form">

  <div class="button-add-holder">
    <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
      <mat-icon>person_add</mat-icon>
      {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
    </button>
  </div>
  <br>
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.FIRST_NAME' | translate
        }}</mat-label>
      <input matInput type="text" formControlName="firstName" required autocomplete="off" maxlength="255" />
      <mat-error *ngIf="personalInformationStep.get('firstName').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.LAST_NAME' | translate
        }}</mat-label>
      <input matInput type="text" formControlName="lastName" required autocomplete="off" maxlength="255" />
      <mat-error *ngIf="personalInformationStep.get('lastName').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.GENDER' | translate
        }}</mat-label>
      <mat-select formControlName="gender" [compareWith]="compareObj" (selectionChange)="updateJMBGOnGenderChange()"
        required>
        <mat-option *ngFor="let gender of genderOptions" [value]="gender">
          {{ gender.gender }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="personalInformationStep.get('gender').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf=" personalInformationStep.get('gender').hasError('incorrect')">
        {{ 'NEW_SUBJECT.PERSONAL_DETAILS.GENDER_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.JMBG' | translate
        }}</mat-label>
      <input matInput type="text" formControlName="jmbg" required autocomplete="off" maxlength="13"
        (input)="updateJMBGOnDateOfBirthChange();updateJMBGOnGenderChange()" name="jmbg" />
      <mat-error *ngIf="personalInformationStep.get('jmbg').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('jmbg').hasError('minLength') ||
                  personalInformationStep.get('jmbg').hasError('pattern')">
        {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.DATE_OF_BIRTH' | translate
        }}</mat-label>

      <input matInput [matDatepicker]="dateOfBirth" formControlName="dateOfBirth"
        (dateChange)="updateJMBGOnDateOfBirthChange()" required autocomplete="off"
        placeholder="{{ 'DATE_FORMAT' | translate }}" [max]="currentDate" maxlength="255" name="dateFormat"
        (input)="checkInputDateFormat('dateOfBirth', $event)" (blur)="onBlur('dateOfBirth')" />
      <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
      <mat-datepicker #dateOfBirth></mat-datepicker>
      <mat-error *ngIf="personalInformationStep.get('dateOfBirth').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('dateOfBirth').hasError('incorrect')">
        {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('dateOfBirth').hasError('maxDateExceeded')">
        {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('dateOfBirth').hasError('invalidFormat')">
        {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.PLACE_OF_BIRTH' | translate
        }}</mat-label>
      <input matInput type="text" formControlName="placeOfBirth" required autocomplete="off" maxlength="255" />
      <mat-error *ngIf="personalInformationStep.get('placeOfBirth').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder kind="3-cols-2-1-1">
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.PLACE_OF_ORIGIN' | translate
        }}</mat-label>
      <input matInput type="text" formControlName="placeOfOrigin" required autocomplete="off" maxlength="255" />
      <mat-error *ngIf="
            personalInformationStep.get('placeOfOrigin').hasError('required')
          ">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NEW_FOSTER_PARENT.FORM.CITIZENSHIP' | translate }}
      </mat-label>
      <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenship"
        (focusout)="checkCitizenship()" />
      <mat-autocomplete #autoCitizenship="matAutocomplete" [displayWith]="displayCustomFormat">
        <mat-option *ngFor="let option of filteredCitizenshipOptions | async" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="personalInformationStep.get('citizenship').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('citizenship').hasError('notValid')">
        {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.NATIONALITY' | translate
        }}</mat-label>
      <input type="text" matInput formControlName="nationality" [matAutocomplete]="autoNationality"
        (focusout)="checkNationality()" />
      <mat-autocomplete #autoNationality="matAutocomplete" [displayWith]="displayCustomFormat">
        <mat-option *ngFor="let option of filteredNationalityOptions | async" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="personalInformationStep.get('nationality').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('nationality').hasError('notValid')">
        {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.EDUCATION' | translate
        }}</mat-label>
      <mat-select formControlName="education" [compareWith]="compareObj" required>
        <mat-option *ngFor="let status of educationOptions" [value]="status">
          {{ status.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="personalInformationStep.get('education').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.OCCUPATION' | translate
        }}</mat-label>
      <input type="text" matInput formControlName="occupation" [matAutocomplete]="autoOccupation"
        (focusout)="checkOccupation()" />
      <mat-autocomplete #autoOccupation="matAutocomplete" [displayWith]="displayCustomFormat">
        <mat-option *ngFor="let option of filteredOccupationOptions | async" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="personalInformationStep.get('occupation').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('occupation').hasError('notValid')">
        {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.WORK_STATUS' | translate
        }}</mat-label>
      <mat-select formControlName="workStatus" [compareWith]="compareObj" required>
        <mat-option *ngFor="let status of employmentOptions" [value]="status">
          {{ status.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="personalInformationStep.get('workStatus').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.MARITAL_STATUS' | translate
        }}</mat-label>
      <mat-select formControlName="maritalStatus" [compareWith]="compareObj" required>
        <mat-option *ngFor="let maritalStatus of maritalStatusOptions" [value]="maritalStatus">
          {{ maritalStatus.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="personalInformationStep.get('maritalStatus').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.HEALTH_STATUS' | translate
        }}</mat-label>

      <input matInput type="text" formControlName="healthStatus" autocomplete="off" maxlength="255" />
      <mat-error *ngIf="personalInformationStep.get('healthStatus').hasError('required')">
        {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <form-footer>
    <button mat-stroked-button matStepperNext color="primary" [disabled]="!personalInformationStep.valid"
      class="form-footer__button" (click)="updatePersonalInformationStep()">
      {{ 'NEW_FOSTER_PARENT.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>
