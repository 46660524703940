<form [formGroup]="terminationOfGuardianshipStep" class="form">
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_PROTEGE.FORM.DECISION_NAME' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="decisionName" autocomplete="off" maxlength="255" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_PROTEGE.FORM.DECISION_NUMBER_GUARDIANSHIP_TERMINATION' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="decisionNumber" autocomplete="off" maxlength="255" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_PROTEGE.FORM.DECISION_DATE_GUARDIANSHIP_TERMINATION' | translate
      }}</mat-label>
      <input matInput [matDatepicker]="decisionDate" [max]="currentDate" formControlName="decisionDate"
        autocomplete="off" name="dateFormat" (input)="checkInputDateFormat('decisionDate', $event)"
        (blur)="onBlur('decisionDate')" />
      <mat-datepicker-toggle matSuffix [for]="decisionDate"></mat-datepicker-toggle>
      <mat-datepicker #decisionDate></mat-datepicker>
      <mat-error *ngIf="terminationOfGuardianshipStep.get('decisionDate').hasError('maxDateExceeded')">
        {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
      </mat-error>
      <mat-error *ngIf="terminationOfGuardianshipStep.get('decisionDate').hasError('invalidFormat')">
        {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_PROTEGE.FORM.REASON_FOR_GUARDIANSHIP_TERMINATION' | translate
      }}</mat-label>
      <mat-select formControlName="terminationReasonCodebook">
        <mat-option *ngFor="let termination of terminationOptions" [value]="termination">
          {{ termination?.title || '' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </field-holder>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_PROTEGE.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-flat-button matStepperNext color="primary" [disabled]="!terminationOfGuardianshipStep.valid"
      class="form-footer__button" (click)="updateTerminationOfGuardianshipStep()">
      {{ 'NEW_PROTEGE.FINISH' | translate }}
    </button>
  </form-footer>
</form>