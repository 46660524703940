<container>
  <mat-card class="card">
    <card-header title="{{ 'STATISTICS.RECORD_CONTROL_STATS.TITLE' | translate }}">
    </card-header>
    <form [formGroup]="recordControlStatsForm" class="form">
      <field-holder  *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
        <mat-form-field appearance="outline" (click)="selectMulti()">
          <mat-label> {{'GLOBAL.CENTER' | translate}}</mat-label>
          <mat-select formControlName="center" multiple required #multiSelect>
            <input type="text" class="multi-input" autocomplete="off" matInput
              placeholder="{{'GLOBAL.SEARCH_CENTERS' | translate}}" #multiSearch (focus)="multiSelect.disabled = true"
              (focusout)="multiSelect.disabled = false" (input)="onInputChange($event.target.value)" />
            <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">
              {{ 'GLOBAL.ALL_CENTERS' | translate }}
            </mat-option>
            <mat-option *ngFor="let value of filteredAllCenters" [value]="value?.id" (click)="tosslePerOneCenter()">
              {{ value.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="recordControlStatsForm.get('center').hasError('required')">
            {{ "GLOBAL.CENTER_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>

      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.RECORD_CONTROL_STATS.RECORD' | translate }}</mat-label>
          <mat-select formControlName="record" required>
            <mat-option *ngFor="let record of recordOptions" [value]="record">
              {{ record.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button [disabled]="!recordControlStatsForm.valid || btnDisabled"
          class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
          (click)="sendRequest(recordControlStatsForm)">
          {{ 'STATISTICS.SUBMIT' | translate }}
        </button>
        <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </form-footer>
    </form>
  </mat-card>
</container>