<container>
   <mat-card class="card">
      <card-header title="{{ 'STATISTICS.VIOLENCE_STATS.TITLE' | translate }}"></card-header>
      <form [formGroup]="violenceStatsForm" class="form">
         <field-holder *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
            <mat-form-field appearance="outline" (click)="selectMulti()">
               <mat-label>{{'GLOBAL.CENTER' | translate}}</mat-label>
               <mat-select formControlName="center" multiple required #multiSelect>
                  <input
                     type="text"
                     class="multi-input"
                     autocomplete="off"
                     matInput
                     placeholder="{{'GLOBAL.SEARCH_CENTERS' | translate}}"
                     #multiSearch
                     (focus)="multiSelect.disabled = true"
                     (focusout)="multiSelect.disabled = false"
                     (input)="onInputChange($event.target.value)"
                  />
                  <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">{{ 'GLOBAL.ALL_CENTERS' | translate }}</mat-option>
                  <mat-option *ngFor="let value of filteredAllCenters" [value]="value?.id" (click)="tosslePerOneCenter()">{{ value.name }}</mat-option>
               </mat-select>
               <mat-error *ngIf="violenceStatsForm.get('center').hasError('required')">{{ "GLOBAL.CENTER_ERROR" | translate }}</mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.VIOLENCE_STATS.RECORD' | translate }}</mat-label>
               <mat-select formControlName="record" required (selectionChange)="recordChange($event.value)">
                  <mat-option *ngFor="let record of recordOptions" [value]="record">{{ record.name }}</mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols" *ngIf="violenceStatsForm.controls.record.value!=null&&violenceStatsForm.controls.record.value.id==1">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
               <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4" onlyNumber />
               <mat-error *ngIf="violenceStatsForm.controls.selectedYear.hasError('required')">{{ "STATISTICS.DATE_ERROR" | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.VIOLENCE_STATS.TYPE_OF_VIOLENCE' | translate }}</mat-label>
               <mat-select formControlName="typeOfViolence" required>
                  <mat-option *ngFor="let typeOfViolence of typeOfViolenceOptions" [value]="typeOfViolence">{{ typeOfViolence.name }}</mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.VIOLENCE_STATS.VIOLENCE_HAPPENED_CONTEXT' | translate }}</mat-label>
               <mat-select formControlName="violenceHappenedContext" required>
                  <mat-option *ngFor="let violenceHappenedContext of violenceHappenedContextOptions" [value]="violenceHappenedContext">{{ violenceHappenedContext.name }}</mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols" *ngIf="violenceStatsForm.controls.record.value!=null&&violenceStatsForm.controls.record.value.id!=1">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
               <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4" onlyNumber />
               <mat-error *ngIf="violenceStatsForm.controls.selectedYear.hasError('required')">{{ "STATISTICS.DATE_ERROR" | translate }}</mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols" *ngIf="violenceStatsForm.controls.record.value!=null&&(violenceStatsForm.controls.record.value.id==1||violenceStatsForm.controls.record.value.id==4)">
            <mat-form-field appearance="outline">
               <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
               <mat-select formControlName="ageGroup" multiple>
                  <mat-option *ngFor="let record of ageGroupOne" [value]="record">{{ record.name }}</mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
               <mat-label>{{ 'STATISTICS.FROM' | translate }}</mat-label>
               <input
                  matInput
                  type="text"
                  formControlName="customAgeGroupFrom"
                  autocomplete="off"
                  maxlength="3"
                  (change)="checkCustomYears()"
                  [required]="shouldShowAgeGroupRange"
                  onlyNumber
                  placeholder="{{ 'STATISTICS.FROM' | translate }}"
               />
               <mat-error *ngIf="violenceStatsForm.get('customAgeGroupFrom').hasError('outOfRange')">{{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
               <mat-label>{{ 'STATISTICS.TO' | translate }}</mat-label>
               <input
                  matInput
                  type="text"
                  formControlName="customAgeGroupTo"
                  autocomplete="off"
                  maxlength="3"
                  (change)="checkCustomYears()"
                  [required]="shouldShowAgeGroupRange"
                  onlyNumber
                  placeholder="{{ 'STATISTICS.TO' | translate }}"
               />
               <mat-error *ngIf="violenceStatsForm.get('customAgeGroupTo').hasError('incorect')">{{ "STATISTICS.NUMBER_OF_YEARS_INCORECT" | translate }}</mat-error>
               <mat-error *ngIf="violenceStatsForm.get('customAgeGroupTo').hasError('outOfRange')">{{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}</mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols"
         *ngIf="(isMainCenterBelgrade || isUserDrugostepeni)&&violenceStatsForm.controls.center.value!=null&&violenceStatsForm.controls.center.value.length>1">
         <mat-checkbox color="primary" class="form__checkbox" formControlName="byCenter">
           {{ 'STATISTICS.GROUP_BY_CSR' | translate }}
         </mat-checkbox>
       </field-holder>
         <form-footer>
            <button
               [disabled]="!violenceStatsForm.valid || btnDisabled"
               class="form-footer__button form-footer__button--primary"
               mat-flat-button
               color="primary"
               (click)="sendRequest(violenceStatsForm)"
            >
               {{ 'STATISTICS.SUBMIT' | translate }}
            </button>
            <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate"></mat-progress-spinner>
         </form-footer>
      </form>
   </mat-card>
</container>
