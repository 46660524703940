<container>
    <mat-card class="card">
        <card-header title="{{ 'STATISTICS.REASON_TERMINATION_STATS.TITLE' | translate }}">
        </card-header>
        <form [formGroup]="reasonStatsForm" class="form">
            <field-holder *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
                <mat-form-field appearance="outline" (click)="selectMulti()">
                    <mat-label> {{'GLOBAL.CENTER' | translate}}</mat-label>
                    <mat-select formControlName="center" multiple required #multiSelect>
                        <input type="text" class="multi-input" autocomplete="off" matInput placeholder="{{'GLOBAL.SEARCH_CENTERS' | translate}}" #multiSearch 
                            (focus)="multiSelect.disabled = true" (focusout)="multiSelect.disabled = false" (input)="onInputChange($event.target.value)"/>
                        <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">
                            {{ 'GLOBAL.ALL_CENTERS' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let value of filteredAllCenters" [value]="value?.id"
                            (click)="tosslePerOneCenter()">
                            {{ value.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="reasonStatsForm.get('center').hasError('required')">
                        {{ "GLOBAL.CENTER_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{ "STATISTICS.REASON_TERMINATION_STATS.REASON_CATEGORY" | translate }}</mat-label>
                    <mat-select formControlName="type" required panelWidth="auto"  (selectionChange)="changeType($event.value)">
                        <mat-option *ngFor="let type of typeOfReason" [value]="type"> {{ type.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ "STATISTICS.REASON_TERMINATION_STATS.REASON" | translate }}</mat-label>
                    <mat-select formControlName="terminationReason" required panelWidth="auto"  >
                        <mat-option *ngFor="let reason of terminationReasonList" [value]="reason"> {{ reason.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ "STATISTICS.REASON_TERMINATION_STATS.CATEGORY" | translate }}</mat-label>
                    <mat-select formControlName="onlyOne" required panelWidth="auto"  [compareWith]="compareTypeStatistics">
                        <mat-option *ngFor="let type of typeOfStatistics" [value]="type"> {{ type.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
                    <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
                        onlyNumber />
                    <mat-error *ngIf="reasonStatsForm.controls.selectedYear.hasError('required')">
                        {{ "STATISTICS.DATE_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{
                        'STATISTICS.AGE_GROUP' | translate
                        }}</mat-label>
                    <mat-select formControlName="ageGroup" multiple>
                        <mat-option *ngFor="let record of ageGroup" [value]="record">
                            {{ record.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{
                        'STATISTICS.GENDER' | translate
                        }}</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let record of genderOptions" [value]="record">
                            {{ record.gender }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols">
                <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
                    <mat-label>{{ 'STATISTICS.FROM' | translate }}</mat-label>
                    <input matInput type="text" formControlName="customAgeGroupFrom" autocomplete="off" maxlength="3"
                        (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
                        placeholder="{{ 'STATISTICS.FROM' | translate }}" [readonly]="!shouldShowAgeGroupRange" />
                    <mat-error *ngIf="reasonStatsForm.controls.customAgeGroupFrom.hasError('outOfRange')">
                        {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
                    <mat-label>{{ 'STATISTICS.TO' | translate }}</mat-label>
                    <input matInput type="text" formControlName="customAgeGroupTo" autocomplete="off" maxlength="3"
                        (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
                        placeholder="{{ 'STATISTICS.TO' | translate }}" [readonly]="!shouldShowAgeGroupRange" />
                    <mat-error *ngIf="reasonStatsForm.controls.customAgeGroupTo.hasError('incorect')">
                        {{ "STATISTICS.NUMBER_OF_YEARS_INCORECT" | translate }}
                    </mat-error>
                    <mat-error *ngIf="reasonStatsForm.controls.customAgeGroupTo.hasError('outOfRange')">
                        {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button [disabled]="!reasonStatsForm.valid || btnDisabled"
                    class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
                    (click)="sendRequest()">
                    {{ 'STATISTICS.SUBMIT' | translate }}
                </button>
                <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </form-footer>
        </form>
    </mat-card>
</container>