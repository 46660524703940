/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   production: true,
   deployed: true,
   translateUrl: '/socialApp/assets/i18n/',
   startUrl: '/socialApp',
   endTime: 60,

   strucni_radnik: 'USR-06-01',
   prijemni_radnik: 'USR-06-02',
   pravnik: 'USR-06-03',
   rukovodilac: 'USR-07-01',
   supervizor: 'USR-07-02',
   direktor: 'USR-07-03',
   raspodeljivac: 'USR-10',
   racunovodstvo: 'USR-11',
   global_admin: 'USR-12',
   drugostepeni_postupak: 'USR-13',
   admin_centra: 'USR-14',

   vsDocumentPP: 'VS-PP',
   vsDocumentPU: 'VS-PU',
   vsDocumentZakljucak: 'VS-ZAKLJUCAK',

   vsDocumentTargetedAssessment: 'DIRECTED_ASSESSMENT',
   vsDocumentTargetedAssessmentPlan: 'DIRECTED_ASSESSMENT_PLAN',
   vsDocumentInitialAssessmentPlan: 'INITIAL_ASSESSMENT_PLAN',

   websocketUrl: '/socialNotification/websocket',

   documentsUrl: '/socialBase/documents/',
   workerDocumentsUrl: '/socialBase/documents/worker/',
   uploadUrl: '/socialBase/documents/upload/',
   viewDocumentUrl: '/socialBase/documents/view/',
   downloadDocumentUrl: '/socialBase/documents/download/',
   documentTemplatesUrl: '/socialBase/documents/templates/',
   downloadTemplateUrl: '/socialBase/documents/templates/download/',
   deleteDocumentUrl: '/socialBase/documents/delete/',
   handleSignatureUrl: '/socialBase/documents/handleSignature',
   uploadEvaluationUrl: '/socialBase/documents/uploadEvaluation',
   sendingToExpedition: '/socialBase/documents/documentSendExpedition',
   fileExistInCase: '/socialBase/documents/fileExistInCase/',
   getSumissionDocuments: '/socialBase/documents/submissionDocuments',
   documentDownloadTemplate: '/docmanager/document/downloadTemplate',
   documentManagerUrl: '/docmanager/document',
   documentManagerPort: '',
   dataImporterPort: '',

   // Subject
   getPaginationUrl: '/socialBase/subject/findAll/',
   getPaginationProblemUrl: '/socialBase/caseProblems/findAll/',
   getPaginationProblemByCaseUrl: '/socialBase/caseProblems/findAllByCaseId/',
   getPaginationRightByCaseUrl: '/socialBase/caseRights/findAllByCaseId/',
   getPaginationRightUrl: '/socialBase/caseRights/findAll/',
   getFilterPaginationUrl: '/socialBase/subject/findAllFilter/',
   findCaseNumbersForSubject: '/socialBase/case/findCaseNumbersForSubject/',

   removeUrl: '/socialBase/subject/remove',
   reportUrl: '/socialBase/subject/report',
   addUrl: '/socialBase/subject/add',
   exportCsvUrl: '/socialBase/subject/exportCSV',
   printSubjectPdfUrl: '/socialBase/subject/print',
   getSubjectUrl: '/socialBase/subject/findById',
   checkJmbgUrl: '/socialBase/subject/checkJmbg/',
   getHistoryUrl: '/socialBase/subject/subjectHistory/',
   getCurrentSubjectUrl: '/socialBase/subject/findCurrentById/',
   archiveSubjectUrl: '/socialBase/subject/archiveSubject/',
   unarchiveSubjectUrl: '/socialBase/subject/unarchiveSubject/',
   addDossierOwnerUrl: '/socialBase/subject/setDossierOwner/',
   getCountRegisteredOnCenter: '/socialBase/subject/registeredCount/',
   getSubjectStatus: '/socialBase/subject/getSubjectStatus/',
   getRegisterId: '/socialBase/subject/getRegisterId/',
   getSubjects: '/socialBase/subject/getSubject/', // {csrId}/{pageId}/{sizeId}
   changeRegId: '/socialBase/subject/changeRegId',

   // family dossier
   addFamilyMemberUrl: '/socialBase/familyDossier/addFamilyMember/',
   getFamilyMembersUrl: '/socialBase/familyDossier/findFamilyMembers/',
   getFamilyMembersCount: '/socialBase/familyDossier/findFamilyMembersCount/',
   setFamilyMemberCarrier: '/socialBase/familyDossier/setNewCarrier/',
   removeFamilyMember: '/socialBase/familyDossier/removeUserFromFamily/',
   getFamilyMembersDossierDtoUrl: '/socialBase/familyDossier/getFamilyMembersDossierDto/',

   // legal subject
   getPaginationLegalUrl: '/socialBase/legal_subject/findAll/',
   getCurrentLegalSubjectUrl: '/socialBase/legal_subject/findCurrentById/',
   addLegalSubjectUrl: '/socialBase/legal_subject/add',
   getLegalHistoryUrl: '/socialBase/legal_subject/subjectHistory/',
   getFilterPaginationLegalUrl: '/socialBase/legal_subject/findAllFilter/',
   getPdfLegalUrl: '/socialBase/legal_subject/exportPdf',
   getLegalSubjectUrl: '/socialBase/legal_subject/findByPib',
   getLegalSubjectById: '/socialBase/legal_subject/findById/',
   getCsvLegalUrl: '/socialBase/legal_subject/exportCSV',
   checkIndetificationNumber: '/socialBase/legal_subject/checkIndetificationNumber/',

   getTrackingPaginationUrl: '/socialBase/tracking/findAll/',
   getTrackingCasePaginationUrl: '/socialBase/tracking/findAllForCase/',
   getTrackingPaginationSecondDegree: '/socialBase/tracking/findAllSecondDegree/',
   getTrackingFilterPaginationUrl: '/socialBase/tracking/findAllFilter/',
   addTrackingListItem: '/socialBase/tracking/add',
   removeTrackingListItem: '/socialBase/tracking/remove/',
   getPrintTrackingListUrl: '/socialBase/tracking/print/',

   // Cases
   getCasesUrl: '/socialBase/case/findAll',
   getUnassignedCasesUrl: '/socialBase/case/findAll/unassigned',
   findCaseByCaseNumber: '/socialBase/case/findCaseByCaseNumber',
   findCaseForSecondDegree: '/socialBase/case/findCaseForSecondDegree',
   getCaseByIdUrl: '/socialBase/case/findById',
   getDetailsByIdUrl: '/socialBase/case/findDetailsById/',
   acceptCaseByIdUrl: '/socialBase/case/accept',
   getDeclineCaseByIdUrl: '/socialBase/case/decline',
   assignCaseUrl: '/socialBase/case/assignCase',
   forwardCaseUrl: '/socialBase/case/forwardCase',
   returnCase: '/socialBase/case/returnCase',
   getCasesBySubjectUrl: '/socialBase/case/findBySubjectId',
   getActiveCasesBySubjectUrl: '/socialBase/case/findActiveBySubjectId',
   getFilteredByUserId: '/socialBase/case/filterSocialCases',
   assignedCasesStatus: '/socialBase/case/assigned/status',
   submitNspUrl: '/socialBase/nspnm/submit/',
   saveDataNspUrl: '/socialBase/nspnm/saveData/',
   findAllFamilyMembers: '/socialBase/familyDossier/getFamilyMembers/',
   closeCaseUrl: '/socialBase/case/close/',
   findByCaseNumberUrl: '/socialBase/case/findByCaseNumber',
   findByCaseProblemUrl: '/socialBase/case/findByCaseProblem',
   printPreviewNspNmCaseUrl: '/socialBase/nspnm/preview/',
   addConclusionFinishCase: '/socialBase/case/finishCase',
   assignTeamUrl: '/socialBase/case/assign/team/',
   returnCaseUrl: '/socialBase/case/return/',
   findDirectedAssessment: '/socialBase/vs/directedAssessment/',
   submitPmnRequestUrl: '/socialBase/pmn/request/',
   submitPmnAcceptanceUrl: '/socialBase/pmn/acceptance/',
   closePmnUrl: '/socialBase/pmn/close',
   isActiveRightUrl: '/socialBase/case/checkIsActiveRight/',
   sendIntoPlanner: '/socialBase/case/sendIntoPlanner',
   teamMemberFinished: '/socialBase/case/teamMemberFinished/',
   saveNspConclusion: '/socialBase/nsp/saveNspConclusion/',
   findAsTeamMemberById: '/socialBase/case/findAsTeamMemberById',
   findCounclersCases: '/socialBase/case/findCounclersCases',
   addCaseComplainResponse: '/socialBase/case/addCaseComplainResponse/',
   getCaseTeamMembers: '/socialBase/case/findTeamMemmbersForCase/',
   closeWithoutChange: '/socialBase/case/saveWithoutChange/',
   getTeamMembersNames: '/socialBase/case/findAllTeamMembers/',
   getCaseManagerNameForCase: '/socialBase/case/findCaseManagerNameForCase/',
   declineTeamRequest: '/socialBase/case/declineTeamRequests/',
   markAsReadCase: '/socialBase/case/markAsRead/',

   // VS
   getForms23ByIdUrl: '/socialBase/vs/findForms23ById',
   submitForms23Url: '/socialBase/vs/submit/forms23/',
   previewForms23: '/socialBase/vs/forms23/preview/',
   previewForm5: '/socialBase/vs/preview/form5/',
   submitForm5Url: '/socialBase/vs/submit/form5/',
   saveForm5Url: '/socialBase/vs/save/form5/',
   findForm5Url: '/socialBase/vs/findForm5/',
   approveInitialAssesmentUrl: '/socialBase/vs/forms23/approve/',
   changeFormNumber: '/socialBase/vs/forms23/change/',
   closeCaseVs: '/socialBase/vs/closeCase',
   supervisorDecisionMake: '/socialBase/vs/supervisorDecisionMake',
   saveLawyerDecision: '/socialBase/vs/saveLawyerDecision',
   updateEvaluationOfPlan: '/socialBase/vs/updateEvaluationOfPlan',
   addAssesment: '/socialBase/assesment/addAssesment',

  // NSP
  getDataForNspFormUrl: '/socialBase/nsp/findNspFormById',
  saveNspCase: '/socialBase/nsp/saveNspCase',
  checkIfHaveActiveRight: '/socialBase/nsp/checkIfFamilyHasNsp',
  findNspBySocialCaseId: '/socialBase/nsp/findNspBySocialCaseId/',
  findAmountForNsp: '/socialBase/nsp/findAmountForNsp/',

  // LocalCase
  saveLocalCase: '/socialBase/localCase/saveLocalCase',
  findLocalCaseBySocialCaseId: '/socialBase/localCase/findLocalCaseBySocialCaseId/',

  // Entrance
  addEntrancePaperUrl: '/socialBase/entrance/addEntrance',
  printPreviewEntranceUrl: '/socialBase/entrance/preview',

  // Users
  findUserInfo: '/socialBase/users/findUserInfo',
  findUserData: '/socialBase/users/findUserData',
  getUsersByRole: '/socialBase/users/findAll/',
  loggedInUserInfo: '/socialBase/users/userinfo',
  loginUrl: '/socialBase/users/login',
  logoutUrl: '/socialBase/users/logout',
  impersonateUrl: '/socialBase/users/impersonateUser',
  refreshTokenUrl: '/socialBase/users/refresh',
  findEmployeesUrl: '/socialBase/users/findAllEmployees',
  findAllByCsrIdUrl: '/socialBase/users/findAll',
  findAllByOfficeIdUrl: '/socialBase/users/office/findAll/',
  findAllByCenterIdUrl: '/socialBase/users/findAllByCenterId/',
  getUserRoles: '/socialBase/role/findAll',
  getUserRoleDescriptions: '/socialBase/role/description/findAll',
  getIsUserUsernameTaken: '/socialBase/users/usernameTaken',
  createUser: '/socialBase/users/create',
  updateUser: '/socialBase/users/update',
  findAllWorkers: '/socialBase/users/findAll',
  findAllByRole: '/socialBase/users/findAllByRole',
  resetPassword: '/socialBase/users/reset-password',
  changePassword: '/socialBase/users/change-password',
  enableUser: '/socialBase/users/enable-user',
  hasSupervisor: '/socialBase/users/hasSupervisor',
  numberOfCasesWorkload: '/socialBase/users/numberOfCasesWorkload',
  numberOfCasesAsTeamMemberWorkload: '/socialBase/users/numberOfCasesAsTeamMemberWorkload',
  getInstruction: '/socialBase/users/getInstruction',
  changeJmbg: '/socialBase/users/changeJmbg',
  findAssignableUsers: '/socialBase/users/findAssignableUsers/',

  // Notifications
  getNotifications: '/socialNotification/notification/findAll/',
  notificationReceived: '/socialNotification/notification/receive/',
  getAllNotifications: '/socialNotification/notification/findAll',

  getNotificationIds: '/socialNotification/notification/getIds/', // {notificationType}
  getAllNotificationsPageable:  '/socialNotification/notification/findAll/', // {pageId}/{sizeId}

  // CaseRights
  getCaseRights: '/socialBase/caseRights/getCaseRights',
  addCaseRights: '/socialBase/caseRights/addCaseRights',
  deleteCaseRights: '/socialBase/caseRights/deleteCaseRight',
  checkExistForCaseId: '/socialBase/caseRights/checkExistForCaseId',


  // CaseProblems:
  addCaseProblems: '/socialBase/caseProblems/addCaseProblems',
  getProblemIdsOfActiveCase: '/socialBase/caseProblems/findIdsWithActiveCase',
  getAllProblemIds: '/socialBase/caseProblems/findAllIds',
  getAllProblemIdsForCase: '/socialBase/caseProblems/findAllByCaseId',
  deleteCaseProblems: '/socialBase/caseProblems/deleteCaseProblems',
  hasProblem: '/socialBase/caseProblems/hasProblem',

  // Codebooks
  getAddictionCodebook: '/socialBase/addictionCodebook/findAll',
  getActivityCodebook: '/socialBase/activityCodebook/findAll',
  getRightsCodebook: '/socialBase/rightsCodebook/findAll',
  getActiveRightsCodebook: '/socialBase/rightsCodebook/findAllActive',
  updateRightsCodebook: '/socialBase/rightsCodebook/update',
  deleteRightsCodebook: '/socialBase/rightsCodebook/delete',
  findRightsById: '/socialBase/rightsCodebook/findById',
  updateServicesCodebook: '/socialBase/servicesCodebook/update',
  deleteServicesCodebook: '/socialBase/servicesCodebook/delete',
  findServicesById: '/socialBase/servicesCodebook/findById',
  getProblemsCodebook: '/socialBase/problemsCodebook/findAll',
  getActiveProblemsCodebook: '/socialBase/problemsCodebook/findAllActive',
  getProblemsIntensityCodebook: '/socialBase/problemsIntensityCodebook/findAll',
  getProblemsDurationCodebook: '/socialBase/problemsDurationCodebook/findAll',
  getCaseKindCodebook: '/socialBase/caseKindCodebook/findAll',
  getCityCodebook: '/socialBase/cityCodebook/findAll',
  getStreetCodebook: '/socialBase/streetCodebook/findAll',
  getLanguageCodebook: '/socialBase/languageCodebook/findAll',
  getNationalityCodebook: '/socialBase/nationalityCodebook/findAll',
  addNewNationalityCodebook: '/socialBase/nationalityCodebook/addNew/',
  updateNationalityCodebook: '/socialBase/nationalityCodebook/updateNationality',
  getCitizenshipCodebook: '/socialBase/citizenshipCodebook/findAll',
  addNewCitizenshipCodebook: '/socialBase/citizenshipCodebook/addNew/',
  updateCitizenshipCodebook: '/socialBase/citizenshipCodebook/updateCitizenship',
  addNewBankCodebook: '/socialBase/bankCodebook/addNew',
  updateBankCodebook: '/socialBase/bankCodebook/updateBank',
  updateBankStatus: '/socialBase/bankCodebook/updateBankStatus',
  getSocialCaseClassificationCodebook: '/socialBase/socialCaseClassificationCodebook/findAll',
  findClassificationCodebookForJnpStats: '/socialBase/socialCaseClassificationCodebook/findClassificationForJnpStats',
  getServiceProviderCodebook: '/socialBase/serviceProviderCodebook/findAll',
  getEducationCodebook: '/socialBase/educationCodebook/findAll',
  getOccupationCodebook: '/socialBase/occupationCodebook/findAll',
  getMaritalStatusCodebook: '/socialBase/maritalStatusCodebook/findAll',
  getEmploymentCodebook: '/socialBase/employmentCodebook/findAll',
  getRelationshipCodebook: '/socialBase/relationshipCodebook/findAll',
  getRelationshipCodebookWithoutUser: '/socialBase/relationshipCodebook/findAllWithoutUser',
  getRelationshipViolenceCodebook: '/socialBase/relationshipViolenceCodebook/findAll',
  getInitiatorCodebook: '/socialBase/initiatorCodebook/findAll',
  getPaymentMethodCodebook: '/socialBase/paymentMethodCodebook/findAll',
  getCaseKindViewCodebookByKind: '/socialBase/caseKindViewCodebook/findByKind',
  getRegionCodebook: '/socialBase/regionCodebook/findAll',
  dpnRightCodebook: '/socialBase/dpnRightCodebook/findByCaseView',
  dpnRightCodebookById: '/socialBase/dpnRightCodebook/findById',
  getDecisionTypeCodebook: '/socialBase/decisionTypeCodebook/findAll',
  getSubmissionTypeCodebook: '/socialBase/submissionTypeCodebook/findAll',
  getEnrollmentEvidentions: '/socialBase/enrollmentEvidention/findAll',
  getEntranceDecisions: '/socialBase/entranceDecision/findAll',
  getAssessmentTypes: '/socialBase/assessmentType/findAll',
  getLegalProceduresCodebook: '/socialBase/legalProceduresCodebook/findAll',
  getActiveLegalProceduresCodebook: '/socialBase/legalProceduresCodebook/findAllActive',
  updateLegalProceduresCodebook: '/socialBase/legalProceduresCodebook/update',
  deleteLegalProceduresCodebook: '/socialBase/legalProceduresCodebook/delete',
  findLegalProceduresById: '/socialBase/legalProceduresCodebook/findById',
  getOtherProceduresAcivitiesCodebook: '/socialBase/otherProceduresAcivitiesCodebook/findAll',
  getServicesCodebook: '/socialBase/servicesCodebook/findAll',
  getActiveServicesCodebook: '/socialBase/servicesCodebook/findAllActive',
  getProfessionalProceduresCodebook: '/socialBase/professionalProceduresCodebook/findAll',
  getActiveProfessionalProceduresCodebook: '/socialBase/professionalProceduresCodebook/findAllActive',
  updateProfessionalProceduresCodebook: '/socialBase/professionalProceduresCodebook/update',
  deleteProfessionalProceduresCodebook: '/socialBase/professionalProceduresCodebook/delete',
  findProfessionalProceduresById: '/socialBase/professionalProceduresCodebook/findById',
  getDevelopmentCodebook: '/socialBase/developmentCodebook/findAll',
  getSubmissionKindCodebook: '/socialBase/submissionKindCodebook/findAll',
  getOfficeCodebook: '/socialBase/officeCodebook/findAll',
  getOfficeCodebookByCsrId: '/socialBase/officeCodebook/findAllByCsrId',
  getCaseTypeCodebook: '/socialBase/caseTypeCodebook/findAll',
  getGenderCodebook: '/socialBase/genderCodebook/findAll',
  getPlanKindCodebook: '/socialBase/planKindCodebook/findAll',
  getHealthStatusCodebook: '/socialBase/healthStatusCodebook/findAll',
  getAgeChildCodebook: '/socialBase/ageChildCodebook/findAll',
  getResidentalStatusCodebook: '/socialBase/residentalStatusCodebook/findAll',
  getFamilyStatusCodebook: '/socialBase/familyStatusCodebook/findAll',
  getSchoolStatusCodebook: '/socialBase/schoolStatusCodebook/findAll',
  getAbsenceCareWithParentsCodebook: '/socialBase/absenceCaseWithParentsCodebook/findAll',
  getAbsenceCareWithoutParentsCodebook: '/socialBase/absenceCaseWithoutParentsCodebook/findAll',
  getGeneralHealthStatusCodebook: '/socialBase/generalHealthStatusCodebook/findAll',
  getPsychophysicalDevelopmentCodebook: '/socialBase/psychophysicalDevelopmentCodebook/findAll',
  getGeneralHealthDeviationsCodebook: '/socialBase/generalHealthDeviationsCodebook/findAll',
  getPsychophysicalDevelopmentDeviationsCodebook: '/socialBase/psychophysicalDevelopmentDeviationsCodebook/findAll',
  getViolenceCodebook: '/socialBase/violenceCodebook/findAll',
  getViolenceCommiterCodebook: '/socialBase/violenceCommiterCodebook/findAll',
  getProtectionMeasureCodebook: '/socialBase/protectionMeasureCodebook/findAll',
  getProtectionRequesterCodebook: '/socialBase/protectionRequesterCodebook/findAll',
  getCommitedViolenceCountCodebook: '/socialBase/commitedViolenceCodebook/findAll',
  getGuardianshipTypeCodebook: '/socialBase/guardianshipTypeCodebook/findAll',
  getPlannerReasonsCodebook: '/socialBase/plannerReasonsCodebook/findAll',
  getReasonsForTerminationOfFosterCareCodebook: '/socialBase/reasonsForTerminationOfFosterCareCodebook/findAll',
  getTypeOfSettlementsCodebook: '/socialBase/typeOfSettlementCodebook/findAll',
  getTypesOfIncomeCodebook: '/socialBase/typesOfIncomeCodebook/findAll',
  getTypesOfPensionCodebook: '/socialBase/typesOfPensionCodebook/findAll',
  getTypesOfAccommodationFeesCodebook: '/socialBase/typesOfAccommodationFeesCodebook/findAll',
  getBranchesPioFundCodebook: '/socialBase/branchesPioFundCodebook/findAll',
  getAccommodationFacilityCodebook: '/socialBase/accommodationFacilityCodebook/findAll',
  getBankCodebook: '/socialBase/bankCodebook/findAll',
  getActiveBankCodebook: '/socialBase/bankCodebook/findAllActive',
  getCsrCodebook: '/socialBase/csrCodebook/findAll',
  checkCsrAddress: '/socialBase/csrCodebook/checkAddress',
  getAccountingRightCodebook: '/socialBase/accountingRightCodebook/findAll',
  getFamilyAccommodationPriceCodebook: '/socialBase/familyAccommodationPriceCodebook/findLatest',
  createFamilyAccommodationPriceCodebook: '/socialBase/familyAccommodationPriceCodebook/create',
  getPocketMoneyAmountCodebook: '/socialBase/pocketMoneyAmountCodebook/findAll',
  getPocketMoneyAmountCodebookValidFrom: '/socialBase/pocketMoneyAmountCodebook/validFromDate/',
  createPocketMoneyAmountCodebook: '/socialBase/pocketMoneyAmountCodebook/create',
  getPocketMoneyPercentageCodebook: '/socialBase/pocketMoneyPercentageCodebook/findAll',
  getCurrentPocketMoneyPercentageCodebook: '/socialBase/pocketMoneyPercentageCodebook/findCurrent',
  addPocketMoneyPercentageCodebook: '/socialBase/pocketMoneyPercentageCodebook/create',
  getTransportationCostCodebook: '/socialBase/transportationCostCodebook/findAll',
  getLatestDpnRightCodebook: '/socialBase/dpnRightCodebook/findLatest',
  getAllDpnRightCodebook: '/socialBase/dpnRightCodebook/findAll',
  getAllByTypeDpnRightCodebook: '/socialBase/dpnRightCodebook/findAllByType', //{type}{pageNum}{size}
  createDpnRightCodebook: '/socialBase/dpnRightCodebook/create',
  getNspRightCodebook: '/socialBase/nspRightCodebook/findLatest',
  getAllNspRightCodebook: '/socialBase/nspRightCodebook/findAll',
  createNspRightCodebook: '/socialBase/nspRightCodebook/create',
  getAllSubcenters: '/socialBase/csrCodebook/getAllSubcenters',
  getCloseCaseCodebook: '/socialBase/closeCaseCodebook/findAll',
  getLocalCommunityForPttNumber: '/socialBase/localCommunityCodebook/findByPttNumber',
  createLegalProcedures: '/socialBase/legalProceduresCodebook/create',
  createProfessionalProcedures: '/socialBase/professionalProceduresCodebook/create',
  createServicesCodebook: '/socialBase/servicesCodebook/create',
  createRightsCodebook: '/socialBase/rightsCodebook/create',
  getTypesOfJnpCodebook: '/socialBase/typesOfJnpCodebook/findAll',
  getNspRightForDate: '/socialBase/nspRightCodebook/getValidForDate',
  getViolenceKindCodebook: '/socialBase/violenceKindCodebook/findAll',
  getAdoptersCodebook: '/socialBase/adoptersCodebook/findAll',
  getFosterCareFormCodebook: '/socialBase/fosterCareFormCodebook/findAll',
  getArchivationReasonsCodebook: '/socialBase/archivationReasonsCodebook/findAll',
  getVictimCharacteristicCodebook: '/socialBase/victimCharacteristicCodebook/findAll',
  getSituationCharacteristicCodebook: '/socialBase/situationCharacteristicCodebook/findAll',
  getPerpetratorCharacteristicCodebook: '/socialBase/perpetratorCharacteristicCodebook/findAll',
  getRiskCharacteristicCodebook: '/socialBase/assessedRiskCodebook/findAll',
  getFormsOfNeglectCodebook: '/socialBase/formsOfNeglectCodebook/findAll',
  getViolenceHappenedContextCodebook: '/socialBase/violenceHappenedContextCodebook/findAll',
  getFormsOfViolenceCodebook: '/socialBase/formsOfViolenceCodebook/findAll',
  getFormsOfSexualViolenceCodebook: '/socialBase/formsOfSexualViolenceCodebook/findAll',
  getHousingStatusCodebook: '/socialBase/housingStatusCodebook/findAll',
  getHouseholdOrFamilyTypeCodebook: '/socialBase/householdOrFamilyTypeCodebook/findAll',
  getFormsOfEmotionalViolenceCodebook: '/socialBase/formsOfEmotionalViolenceCodebook/findAll',
  getEconomicViolenceCodebook: '/socialBase/economicViolenceCodebook/findAll',
  getExploitationChildCodebook: '/socialBase/exploitationChildCodebook/findAll',
  getBullyingCodebook: '/socialBase/bullyingCodebook/findAll',
  getMigrantStatusCodebook: '/socialBase/migrantStatusCodebook/findAll',
  getEngagedCollaborativeNetworkCodebook: '/socialBase/engagedCollaborativeNetworkCodebook/findAll',
  getAvailabilityVictimToPerpetratorCodebook: '/socialBase/availabilityVictimToPerpetratorCodebook/findAll',
  getEmergencyMeasuresCodebook: '/socialBase/emergencyMeasuresCodebook/findAll',
  getPaymentRecipientCodebook: '/socialBase/paymentRecipientCodebook/findAll',
  getFosterParentMaritalStatusCodebook: '/socialBase/fosterParentMaritalStatusCodebook/findAll',
  getLevelOfEducationCodebook: '/socialBase/levelOfEducationCodebook/findAll',
  getPersonCategoryCodebook: '/socialBase/personCategoryCodebook/findAll',
  getFamilyTypeCodebook: '/socialBase/familyTypeCodebook/findAll',
  getAccommodationCategoryCodebook: '/socialBase/accommodationCategoryCodebook/findAll',
  getAccommodationSupportCategoryCodebook:'/socialBase/supportCategoryCodebook/findAll',
  getAccommodationCategoryCodebookNotSupport: '/socialBase/accommodationCategoryCodebook/findAllNotSupport',
  getAccommodationCategoryCodebookSupport: '/socialBase/accommodationCategoryCodebook/findAllSupport',
  getTerminationReasonsCodebookByType: '/socialBase/terminationReasonCodebook/findAllByType/',
  getAccommodationReasonsCodebook: '/socialBase/reasonForAccommodationCodebook/findAll',
  getProtegeAccommodationCodebook: '/socialBase/protegeAccommodationCodebook/findAll',
  getDeprivationExtentCodebook: '/socialBase/deprivationExtentCodebook/findAll',
  getSchoolAttendingCodebook: '/socialBase/schoolAttendingCodebook/findAll',

  // AccommodationFacilityCodebook
  addCategoriesToFacilityCodebook: '/socialBase/accommodationFacilityCodebook/addCategoriesToFacilityCodebook/',
  removeCategoryFromFacilityCodebook: '/socialBase/accommodationFacilityCodebook/removeCategoryFromFacilityCodebook/',
  editCategoryFromFacilityCodebook: '/socialBase/accommodationFacilityCodebook/editCategoryFromFacilityCodebook/',
  getDecisionMakerCodebook: '/socialBase/decisionMakerCodebook/findAll',
  getComplainResponseCodebook: '/socialBase/complainResponseCodebook/findAll',
  getStreetCodebookByPartName: '/socialBase/streetCodebook/findByPartialName/',
  getCsrCodebookById : '/socialBase/csrCodebook/findById',
  addSupportCategoriesToFacilityCodebook: '/socialBase/accommodationFacilityCodebook/addSupportCategoriesToFacilityCodebook/',
  removeSupportCategoryFromFacilityCodebook: '/socialBase/accommodationFacilityCodebook/removeSupportCategoryFromFacilityCodebook/',
  editSupportCategoryFromFacilityCodebook: '/socialBase/accommodationFacilityCodebook/editSupportCategoryFromFacilityCodebook/',
  // Integrations
  sendDocument: '/socialBase/documents/integrations/sendDocument/',

  // Sekcije u novim tabovima
  getNewCaseTab: '/socialApp/cases/details/',
  getFamilyMemberTab: '/socialApp/subjects/',
  getNewSubjectTab: '/socialApp/new-subject/',

  // NspRequest
  addNspRequestUrl: '/socialBase/nspRequest/addNspRequest',
  printPreviewNspRequestUrl: '/socialBase/nspRequest/preview',

  // NspFamilyRequest
  addNspFamilyRequestUrl: '/socialBase/nspFamilyRequest/addNspFamilyRequest',
  printPreviewFamilyRequestUrl: '/socialBase/nspFamilyRequest/preview',

  // DpnRequest
  createDpnRequest: '/socialBase/dpnRequest/create',
  previewDpnRequestUrl: '/socialBase/dpnRequest/preview',
  getDataForDpnFormUrl: '/socialBase/dpnRequest/findDpnDataById',
  saveDpnRequest: '/socialBase/dpnRequest/save',
  checkActiveRightDpn: '/socialBase/dpnRequest/checkIfHaveActiveRight/',

  // PnnCase
  createPnnUrl: '/socialBase/pnnCase/create',
  getDataForPnnFormUrl: '/socialBase/pnnCase/findPnnDataById',
  savePnn: '/socialBase/pnnCase/save',

  // OzrCase
  getDataForOzrFormUrl: '/socialBase/ozrCase/findOzrDataById',
  checkIsActiveRightUrl: '/socialBase/ozrCase/checkIsActiveRight',
  saveOzr: '/socialBase/ozrCase/save',

  // Supervision
  findSubordinates: '/socialBase/supervision/findSubordinates',
  findCasesBySubordinate: '/socialBase/supervision/findCasesBySubordinate',
  findDocumentsByCase: '/socialBase/documents/getCaseDocuments',
  markAsReadSupervisionCase: '/socialBase/supervision/markAsReadSupervision/',

  // Submission
  findById: '/socialBase/submission/findById',
  findSubmissionsByCenter: '/socialBase/submission/findByCenter',
  acceptSubmission: '/socialBase/submission/acceptSubmission',

  // Team requst
  saveTeamRequestUrl: '/socialBase/team/create',
  findAllTeamRequests: '/socialBase/team/findAll',
  caseHaveNotProcessedTeamRequest: '/socialBase/team/checkIfExistActiveForCase',

  // word
  getDocumentTemplateUrl: '/socialBase/ms-word/documentTemplate',
  downloadFileUrl: '/socialBase/ms-word/download/',

  // FosterParent
  addFosterParent: '/socialBase/fosterParent/create',
  findAllFosterParent: '/socialBase/fosterParent/findAll',
  getStatusFosterParentCollection: '/socialBase/fosterParent/checkStatusCollection/',
  closeFosterParentCollection: '/socialBase/fosterParent/closeCollection',
  exportFosterParentCsvUrl: '/socialBase/fosterParent/exportCSV',
  findFosterParentById: '/socialBase/fosterParent/findById',
  getFosterParentReportUrl: '/socialBase/fosterParent/generateReport',

  // FosterChild
  addFosterChild: '/socialBase/fosterChild/create',
  findAllFosterChild: '/socialBase/fosterChild/findAll',
  getStatusFosterChildCollection: '/socialBase/fosterChild/checkStatusCollection/',
  closeFosterChildCollection: '/socialBase/fosterChild/closeCollection',
  exportFosterChildCsvUrl: '/socialBase/fosterChild/exportCSV',
  findFosterChildById: '/socialBase/fosterChild/findById',
  getFosterChildReportUrl: '/socialBase/fosterChild/generateReport',

  // Assurance
  previewAssuranceUrl: '/socialBase/assurance/preview',
  saveAssurance: '/socialBase/assurance/save',
  createAssurance: '/socialBase/assurance/create',

  // AbusedPerson
  addAbusedPerson: '/socialBase/abusedPerson/create',
  findAllAbusedPerson: '/socialBase/abusedPerson/findAll',
  getStatusAbusedPersonCollection: '/socialBase/abusedPerson/checkStatusCollection/',
  closeAbusedPersonCollection: '/socialBase/abusedPerson/closeCollection',
  exportAbusedPersonCsvUrl: '/socialBase/abusedPerson/exportCSV',
  findAbusedPersonById: '/socialBase/abusedPerson/findById',
  getAbusedPersonReportUrl: '/socialBase/abusedPerson/generateReport',

  // ViolenceCommiter
  addViolenceCommiter: '/socialBase/violenceCommiter/create',
  findAllViolenceCommiter: '/socialBase/violenceCommiter/findAll',
  getStatusViolenceCommiterCollection: '/socialBase/violenceCommiter/checkStatusCollection/',
  closeViolenceCommiterCollection: '/socialBase/violenceCommiter/closeCollection',
  exportViolenceCommiterCsvUrl: '/socialBase/violenceCommiter/exportCSV',
  findViolenceCommiterById: '/socialBase/violenceCommiter/findById',
  getViolenceCommiterReportUrl: '/socialBase/violenceCommiter/generateReport',

  // EstablishedFosterCare
  addEstablishedFosterCare: '/socialBase/establishedFosterCare/create',
  findAllEstablishedFosterCare: '/socialBase/establishedFosterCare/findAll',
  getStatusEstablishedFosterCareCollection: '/socialBase/establishedFosterCare/checkStatusCollection/',
  closeEstablishedFosterCareCollection: '/socialBase/establishedFosterCare/closeCollection',
  establishedFosterCareCsvUrl: '/socialBase/establishedFosterCare/exportCSV',
  findEstablishedFosterCareByIdAndCsrId: '/socialBase/establishedFosterCare/findById',
  getEstablishedFosterCareReportUrl: '/socialBase/establishedFosterCare/generateReport',
  terminateFosterCare: '/socialBase/establishedFosterCare/terminateFosterCare',

  // DependentPerson
  addDependentPerson: '/socialBase/dependentPerson/create',
  findAllDependentPerson: '/socialBase/dependentPerson/findAll',
  getStatusDependentPersonCollection: '/socialBase/dependentPerson/checkStatusCollection/',
  closeDependentPersonCollection: '/socialBase/dependentPerson/closeCollection',
  exportDependentPersonCsvUrl: '/socialBase/dependentPerson/exportCSV',
  findDependentPersonById: '/socialBase/dependentPerson/findById',
  getDependentPersonReportUrl: '/socialBase/dependentPerson/generateReport',
  updateTerminationSupportInfo: '/socialBase/dependentPerson/updateTerminationSupportInfo',

  // ProtegePerson
  addProtegePerson: '/socialBase/protegePerson/create',
  findAllProtegePerson: '/socialBase/protegePerson/findAll',
  findProtegePersonByIdAndCsrId: '/socialBase/protegePerson/findById',
  addIntoCollection: '/socialBase/protegePerson/addIntoCollection',
  editProtegePerson: '/socialBase/protegePerson/editProtegeDetails',
  getStatusProtegePersonCollection: '/socialBase/protegePerson/checkStatusCollection/',
  closeProtegePersonCollection: '/socialBase/protegePerson/closeCollection',
  exportProtegePersonCsvUrl: '/socialBase/protegePerson/exportCSV',
  getProtegePersonReportUrl: '/socialBase/protegePerson/generateReport',

  // AdoptChild
  addAdoptedChild: '/socialBase/adoptChild/create',
  findAllAdoptedChild: '/socialBase/adoptChild/findAll',
  getStatusAdoptChildCollection: '/socialBase/adoptChild/checkStatusCollection/',
  closeAdoptChildCollection: '/socialBase/adoptChild/closeCollection',
  exportAdoptedChildCsvUrl: '/socialBase/adoptChild/exportCSV',
  findAdoptChildById: '/socialBase/adoptChild/findById',
  getAdoptChildReportUrl: '/socialBase/adoptChild/generateReport',
  updateAnnulmentOfAdoption: '/socialBase/adoptChild/updateAnnulmentOfAdoption',

  // AccountingProcedures
  getAccountingProceduresExportUrl: '/socialBase/accountingProcedure/export',

  // Statistics
  getWorkerStatistics: '/socialBase/statistics/worker/',
  getOfficeStatistics: '/socialBase/statistics/office/workers/',
  getCenterStatistics: '/socialBase/statistics/workers/',
  getBasicStatistics: '/socialBase/statistics/basic',
  getControlStatistics: '/socialBase/statistics/control',
  getGeneralStatistics: '/socialBase/statistics/general',
  getDocumentStatistics: '/socialBase/statistics/documents',
  getNspStatistics: '/socialBase/statistics/nsp',
  getActivityStatistics: '/socialBase/statistics/activity',
  getSocialCaseStatistics: '/socialBase/statistics/socialCase',
  getJnpStatistics:  '/socialBase/statistics/jnp',
  getJnpStatisticsTxt : '/socialBase/statistics/jnpTxt',
  getCaseOutcomeStatistics: '/socialBase/statistics/decisions',
  getTerminationReasonStatistics: '/socialBase/statistics/terminationReason',
  getTrackingListStatistics: '/socialBase/statistics/trackingList',
  getBasicViolenceStatistics: '/socialBase/statistics/violenceBasic',
  getAllUsersByCenterIdUrl: '/socialBase/statistics/findAllByCenterIds',

  //Socijalna karta
  downloadExternalInfo: '/socialBase/case/getInfoFromExternalInstitutionPdf',
  insertSubjectSK: '/socialBase/case/addInputToSK/',

  previewNspDecision: '/socialBase/nsp/preview/Decision',

  // Accommodation
  accommodationForCase: '/socialBase/accommodation/accommodationForCase/',
  checkIfHaveActiveRightAccommodation: '/socialBase/accommodation/checkIfHaveActiveRight/',
  // S1
  getAccommodationReportS1Url: '/socialBase/accommodation/previewFormS1',
  getAccommodationSaveFromS1Url: '/socialBase/accommodation/saveFormS1',
  // S5
  getAccommodationReportS5Url: '/socialBase/accommodation/previewFormS5',
  getAccommodationSaveFromS5Url: '/socialBase/accommodation/saveFormS5',
  // O
  saveAccommodationOUrl: '/socialBase/accommodation/saveFormO/',
  createReportAccommodationOUrl: '/socialBase/accommodation/createReportFormO/',
  previewAccommodationOUrl: '/socialBase/accommodation/previewReportFormO/',
  // porodicni smestaj
  createFammilyAccommodation: '/socialBase/accommodation/createFammilyAccommodation/',
  previewFammilyAccommodation: '/socialBase/accommodation/previewFammilyAccommodation/',

  // ViolenceForm
  getViolenceFormReportUrl: '/socialBase/violenceForm/preview',
  getViolenceFormSaveUrl: '/socialBase/violenceForm/save',
  findViolenceByCaseId: '/socialBase/violenceForm/findByCaseId/',

  // TeamMemberFinding
  createTeamMemberFinding: '/socialBase/teamMemberFinding/create',

  // import data
  importDataUrl: '/dataImporter/import/registry/',
  importDataStatus: '/dataImporter/import/registry/status/',
  importDataMessages: '/dataImporter/import/registry/messages/',
  stopDataImport: '/dataImporter/import/registry/stopimport/',
  importIntegration: '/dataImporter/import/integration/',
  // subjectImports: '/socialBase/subjectImports/findTop10',
  subjectImports: '/socialBase/importHistory/findTop10Subjects',

  // import collections
  importCollectionsUrl: '/dataImporter/import/collection/',
  importCollectionsStatus: '/dataImporter/import/collection/status/',
  importCollectionsMessages: '/dataImporter/import/collection/messages/',
  stopCollectionsImport: '/dataImporter/import/collection/stopimport/',
  collectionImports: '/socialBase/importHistory/findTop10Collections',

  // import socialCases
  importSocialCasesUrl: '/dataImporter/import/socialCases/',
  importSocialCasesStatus: '/dataImporter/import/socialCases/status/',
  importSocialCasesMessages: '/dataImporter/import/socialCases/messages/',
  stopSocialCasesImport: '/dataImporter/import/socialCases/stopimport/',
  socialCasesImports: '/socialBase/importHistory/findTop10SocialCase',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

