import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ForwardCaseDialogComponent } from 'src/app/forward-case-dialog/forward-case-dialog.component';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { AccommodationService } from 'src/services/accommodation-service';
import { CaseService } from 'src/services/case.service';
import { CodebookService } from 'src/services/codebook.service';
import { SideNavService } from 'src/services/side-nav.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalSizeEnum } from 'src/types';
import { DataSharingService } from 'src/services/data-sharing.service';

// tslint:disable: indent
enum AutocompleteFilterEnum {
   OCCUPATION = 'occupation',
   EDUCATION = 'education',
   ACCOMMODATION = 'accommodation',
}

@Component({
   selector: 'app-forms-s-o',
   templateUrl: './forms-s-o.component.html',
   styleUrls: ['./forms-s-o.component.scss'],
})
export class FormsSOComponent implements OnInit {
   @Input() isCaseClosed: boolean;
   @Input() subjectId: any;
   @Input() caseId: any;
   @Input() caseData: any;
   @Output() handleSubmitData = new EventEmitter();
   @Output() handleSaveChanges = new EventEmitter();
   @Input() accommodation: any;
   idAccomodation = null;
   firstStep: FormGroup;
   secondStep: FormGroup;
   // thirdStep: FormGroup;
   isEditMode = false;
   isSavedS1 = false;
   isSavedS5 = false;
   isSavedAccommodationInfo = false;
   // Zanimanje
   occupationOptions = []; // šifarnik
   filteredOccupationOptions: Observable<any[]>;
   // Skolska sprema
   educationOptions = []; // šifarnik
   accommodationReasons = []; // šifarnik
   filteredEducationOptions: Observable<any[]>;
   object: any = {
      caseId: '',
      userId: '',
      caseStatus: '',
      source: '',
   };
   isSent = false;
   public mask = {
      guide: false,
      showMask: false,
      mask: [
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         '-',
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         '-',
         /[0-9]/,
         /[0-9]/,
      ],
   };

   constructor(
      public dialog: MatDialog,
      private formBuilder: FormBuilder,
      private translate: TranslateService,
      private accommodationService: AccommodationService,
      private toastr: ToastrImplService,
      private caseService: CaseService,
      private router: Router,
      private sideNavService: SideNavService,
      private cdr: ChangeDetectorRef,
      private dataSharingService: DataSharingService,
      private codebookService: CodebookService
   ) {
      this.createForms();
   }

   ngOnInit(): void {
      this.codebookService.getEducationCodebook().subscribe(result => {
         this.educationOptions = result;
         this.filteredEducationOptions = this.secondStep.controls.education.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? value : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.EDUCATION) : this.educationOptions.slice()))
         );
      });

      this.codebookService.getOccupationCodebook().subscribe(result => {
         this.occupationOptions = result;
         this.filteredOccupationOptions = this.secondStep.controls.occupation.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? value : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION) : this.occupationOptions.slice()))
         );
      });
      this.codebookService.getAccommodationReasonsCodebook().subscribe(result => {
         this.accommodationReasons = result;
      });
   }

   ngOnChanges(changes: any): void {
      if (changes.accommodation && changes.accommodation.currentValue) {
         this.idAccomodation = this.accommodation.id;

         if (this.accommodation.accommodationS1 !== undefined && this.accommodation.accommodationS1 !== null) {
            const s1 = this.accommodation.accommodationS1;
            this.firstStep = this.formBuilder.group({
               nicNumber: s1.nicNumber,
               basicHealthInsurance: s1.basicHealthInsurance,
               healtInsuranceCard: s1.healtInsuranceCard,
               // reasonRequest: s1.reasonRequest,
               accommodationReason: s1.accommodationReason,
               requesterFirstName: s1.requesterFirstName,
               requesterLastName: s1.requesterLastName,
               requesterNicNumber: s1.requesterNicNumber,
               requesterAddress: s1.requesterAddress,
               requesterPhone: s1.requesterPhone,
               familyMembersNumber: s1.familyMembersNumber,
               houseStatus: s1.houseStatus,
               houseConcept: s1.houseConcept,
               relativePersonInfo: s1.relativePersonInfo,
               contactPersonInfo: s1.contactPersonInfo,
            });
         }
         if (this.accommodation.accommodationS5 !== undefined && this.accommodation.accommodationS5 !== null) {
            const s5 = this.accommodation.accommodationS5;
            this.secondStep = this.formBuilder.group({
               firstName: s5.firstName,
               lastName: s5.lastName,
               jmbg: s5.jmbg,
               jmbp: s5.jmbp,
               pttNumber: s5.pttNumber,
               municipality: s5.municipality,
               permanentResidence: s5.permanentResidence,
               phone: s5.phone,
               education: s5.education,
               occupation: s5.occupation,
               bankAccount: s5.bankAccount,
               rightToInsurance: s5.rightToInsurance,
               place: s5.place,
               placementRelativeFamily: s5.placementRelativeFamily,
            });
         }
      }
   }
   // Zatvaranje
   closeForm() {
      this.isEditMode = false;
      this.handleSaveChanges.emit({ step: 'STEP_07', isFinished: false, accommodation: this.accommodation });
   }

   createForms() {
      this.firstStep = this.formBuilder.group({
         nicNumber: [null, [Validators.minLength(8)]], // broj licne karte
         basicHealthInsurance: [null], // osnovno zdravstveno osiguranje
         healtInsuranceCard: [null, [Validators.minLength(7)]], // broj zdravstvene knjizice
         // reasonRequest: [null], // razlog podnosenja
         accommodationReason: [null],
         requesterFirstName: [null], // ime podnosioca
         requesterLastName: [null], // prezime podnosioca
         requesterNicNumber: [null, [Validators.minLength(8)]], // broj licne karte podnosioca
         requesterAddress: [null], // adresa podnosioca
         requesterPhone: [null, [Validators.minLength(7), Validators.pattern('^[0-9]*$')]], // telefon podnosioca,
         familyMembersNumber: ['', [Validators.pattern('^[0-9]*$')]], // broj clanova porodice
         houseStatus: [null], // stambeni status
         houseConcept: [null], // struktura stambene jedinice,
         relativePersonInfo: [null], // podaci o srodnicima
         contactPersonInfo: [null], // podaci o licima za kontakt
      });

      this.secondStep = this.formBuilder.group({
         firstName: [null],
         lastName: [null],
         jmbg: [null],
         jmbp: [null],
         pttNumber: [null],
         municipality: [null],
         permanentResidence: [null],
         phone: [null],
         education: [null],
         occupation: [null],
         bankAccount: [null],
         rightToInsurance: [null],
         place: [null],
         placementRelativeFamily: [null],
      });
   }

   /** Import subject data from register and fill automaticaly appropriate fields */
   importSubjectData(): void {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'accommodation',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const obj = JSON.parse(JSON.stringify(result));
            const fosterParentSubjectId = result.data.subjectIdentity.subjectId;
            const {
               data: {
                  firstName,
                  lastName,
                  gender,
                  dateOfBirth,
                  placeOfBirth,
                  permanentResidence,
                  jmbg,
                  phone,
                  residence,
                  mobile,
                  nationality,
                  citizenship,
                  language,
                  education,
                  ableToWork,
                  occupation,
                  employment,
                  usefulData,
                  jmbp,
               },
            } = obj;

            this.secondStep.patchValue({
               firstName,
               lastName,
               gender: gender?.gender,
               dateOfBirth,
               placeOfBirth,
               jmbg,
               permanentResidence:
                  String(permanentResidence.street ? permanentResidence.street : '') +
                  String(permanentResidence.number ? ' ' : '') +
                  String(permanentResidence.number ? permanentResidence.number : ''),
               phone,
               residence:
                  String(residence.street ? residence.street : '') + String(residence.number ? ' ' : '') + String(residence.number ? residence.number : ''),
               mobile,
               pttNumber: permanentResidence.ptt,
               place: permanentResidence.town,
               nationality: nationality?.title,
               citizenship: citizenship?.title,
               nativeLanguage: language?.title,
               education: education?.title,
               ableToWork: ableToWork?.name,
               occupation: occupation?.title,
               employment: employment?.title,
               usefulData,
               subjectId: fosterParentSubjectId,
               jmbp,
            });
         }
      });
   }

   /* ------------------------------------*/
   /*             OBRAZAC S1              */
   /* ------------------------------------*/

   previewS1() {
      const objectS1 = this.createObjectS1(true);
      this.accommodationService.previewS1(objectS1, this.caseId);
   }

   createObjectS1(generateReport: Boolean) {
      const s1 = {
         id: this.idAccomodation,
         nicNumber: this.firstStep.controls.nicNumber.value,
         basicHealthInsurance: this.firstStep.controls.basicHealthInsurance.value,
         healtInsuranceCard: this.firstStep.controls.healtInsuranceCard.value,
         // reasonRequest: this.firstStep.controls.reasonRequest.value,
         accommodationReason: this.firstStep.controls.accommodationReason.value,
         requesterFirstName: this.firstStep.controls.requesterFirstName.value,
         requesterLastName: this.firstStep.controls.requesterLastName.value,
         requesterNicNumber: this.firstStep.controls.requesterNicNumber.value,
         requesterAddress: this.firstStep.controls.requesterAddress.value,
         requesterPhone: this.firstStep.controls.requesterPhone.value,
         familyMembersNumber: this.firstStep.controls.familyMembersNumber.value,
         houseStatus: this.firstStep.controls.houseStatus.value,
         houseConcept: this.firstStep.controls.houseConcept.value,
         relativePersonInfo: this.firstStep.controls.relativePersonInfo.value,
         contactPersonInfo: this.firstStep.controls.contactPersonInfo.value,
         generateReport,
      };
      return s1;
   }

   async saveFormS1(generateReport: boolean): Promise<boolean> {
      const objectS1 = this.createObjectS1(generateReport);
      this.firstStep.disable();
      this.isSavedS1 = true;
      return new Promise((resolve) => {
         let message = '';
         this.accommodationService.saveFormS1(objectS1, this.caseId).subscribe(
            result => {
               if (this.idAccomodation === null) {
                  this.idAccomodation = result.id;
               }
               this.accommodation = result;
               this.translate.get('VS.ACCOMMODATION.' + (generateReport ? 'FORMSO.STEP_01.DATA_SAVED_AND_SENT' : 'DATA_SAVED')).subscribe((resp: string) => {
                  message = resp;
                  this.toastr.showSuccess(message);
               });
               this.firstStep.enable();
               this.isSavedS1 = false;
               resolve(true);
            },
            error => {
               this.firstStep.enable();
               this.isSavedS1 = false;
               this.toastr.error('VS.ACCOMMODATION.FORMSO.STEP_01.ERROR_SAVING');
               resolve(false);
            }
         );
      });
   }

   /* ------------------------------------*/
   /*             OBRAZAC S5              */
   /* ------------------------------------*/

   createObjectS5(generateReport: Boolean) {
      const education = this.secondStep.controls.education.value;
      const occupation = this.secondStep.controls.occupation.value;

      const s5 = {
         id: this.idAccomodation,
         firstName: this.secondStep.controls.firstName.value,
         lastName: this.secondStep.controls.lastName.value,
         jmbg: this.secondStep.controls.jmbg.value,
         jmbp: this.secondStep.controls.jmbp.value,
         pttNumber: this.secondStep.controls.pttNumber.value,
         municipality: this.secondStep.controls.municipality.value,
         permanentResidence: this.secondStep.controls.permanentResidence.value,
         phone: this.secondStep.controls.phone.value,
         education: education?.title ? education.title : education,
         occupation: occupation?.title ? occupation.title : occupation,
         bankAccount: this.secondStep.controls.bankAccount.value,
         rightToInsurance: this.secondStep.controls.rightToInsurance.value,
         place: this.secondStep.controls.place.value,
         generateReport,
         placementRelativeFamily: this.secondStep.controls.placementRelativeFamily.value,
      };
      return s5;
   }

   async saveFormS5(generateReport: boolean): Promise<boolean> {
      const objectS5 = this.createObjectS5(generateReport);
      this.secondStep.disable();
      this.isSavedS5 = true;
      return new Promise((resolve) => {
         this.accommodationService.saveFormS5(objectS5, this.caseId).subscribe(
            result => {
               if (this.idAccomodation === null) {
                  this.idAccomodation = result.id;
               }
               this.accommodation = result;
               let message = '';
               this.translate.get('VS.ACCOMMODATION.' + (generateReport ? 'FORMSO.STEP_02.DATA_SAVED_AND_SENT' : 'DATA_SAVED')).subscribe((resp: string) => {
                  message = resp;
                  this.toastr.showSuccess(message);
               });
               this.secondStep.enable();
               this.isSavedS5 = false;
               resolve(true);
            },
            error => {
               this.secondStep.enable();
               this.isSavedS5 = false;
               this.toastr.error('VS.ACCOMMODATION.FORMSO.STEP_02.ERROR_SAVING');
               resolve(false);
            }
         );
      });
   }

   previewS5() {
      const objectS5 = this.createObjectS5(true);
      this.accommodationService.previewS5(objectS5, this.caseId);
   }

   importRequesterData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'accommodation',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const obj = JSON.parse(JSON.stringify(result));
            const {
               data: { firstName, lastName, permanentResidence, phone },
            } = obj;

            this.firstStep.patchValue({
               requesterFirstName: firstName,
               requesterLastName: lastName,
               requesterAddress:
                  String(permanentResidence.street ? permanentResidence.street : '') +
                  String(permanentResidence.number ? ' ' : '') +
                  String(permanentResidence.number ? permanentResidence.number : '') +
                  String(permanentResidence.town ? ', ' : '') +
                  String(permanentResidence.town ? permanentResidence.town : ''),
               requesterPhone: phone,
            });
         }
      });
   }

   autoSelectItem(arr: string[], input: string) {
      autoSelect(arr, input, 'title', this.secondStep);
   }

   async openDialog() {
      const saved = await this.saveFinal(this.caseData?.socialCaseClassificationCodebook?.oldKindMark === 'PS');
      if (!saved) {
         return;
      }
      this.object.userId = null;
      this.object.caseId = this.caseId;
      this.object.caseStatus = this.caseData.caseStatus;
      this.object.source = 'nsp-nm';

      const dialogRef = this.dialog.open(ForwardCaseDialogComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         data: this.object,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(res => {
         if (res.event === 'SUCCESS') {
            const forwardDto = {
               userId: res.data.user,
               role: res.data.role,
            };
            this.caseService.forwardCase(res.data.caseId, forwardDto).subscribe(
               result => {
                  if (result) {
                     this.cdr.detectChanges();
                     this.isSent = true;
                     this.toastr.success('VS.ACCOMMODATION.SEND_TO_LAWYER');
                     this.dataSharingService.cases.next(true);
                     this.router.navigateByUrl('/subjects/' + this.subjectId);
                     this.sideNavService.toggle();
                  } else {
                     this.toastr.error('PISARNICA.ERROR_ACCEPTED');
                  }
               },
               err => {
                  console.log('Error occured:', err);
               }
            );
         }
      });
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? (objectValue.title !== undefined ? objectValue.title : objectValue) : '';
   }
   displayCustomFormatAccommodation(objectValue: any): string {
      return objectValue ? (objectValue.name !== undefined ? objectValue.name : objectValue) : '';
   }

   compareObjects(object1: any, object2: any) {
      return object1 === object2;
   }
   compareObjectsWithId(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }
   /**
    * _filter
    * @param value input value
    * @param what which filter should be applied (check AutocompleteFilterEnum)
    */
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      if (value !== null) {
         const filterValue = value.toLowerCase();
         switch (what) {
            case AutocompleteFilterEnum.EDUCATION:
               return sortByFirstLetter(
                  this.educationOptions.filter(option => option.title.toLowerCase().includes(filterValue)),
                  filterValue,
                  'title'
               );
            case AutocompleteFilterEnum.OCCUPATION:
               return sortByFirstLetter(
                  this.occupationOptions.filter(option => option.title.toLowerCase().includes(filterValue)),
                  filterValue,
                  'title'
               );
            default:
               break;
         }
      }
   }
   
   async saveFinal(generateBoth: boolean): Promise<boolean> {
      try {
         this.firstStep.disable();
         this.isSavedS1 = true;
         if (generateBoth) {
            this.secondStep.disable();
            this.isSavedS5 = true;
         }
         const resultS1 = await this.saveFormS1(true);
         const resultS5 = generateBoth ? await this.saveFormS5(true) : true;
         this.firstStep.enable();
         this.isSavedS1 = false;
         if (generateBoth) {
            this.secondStep.enable();
            this.isSavedS5 = false;
         }
         return resultS1 && resultS5;
      } catch (error) {
         this.firstStep.enable();
         this.isSavedS1 = false;
         if (generateBoth) {
            this.secondStep.enable();
            this.isSavedS5 = false;
         }
         return false;
      }
   }
}
