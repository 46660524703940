/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 *
 */

export class Activity {
   id: number;
   caseId: string;
   caseName: string;
   author: string;
   date: Date;
   dateOfModification: Date;
   activity: any;
   description: string;

   constructor(caseId: string, caseName: string, author: string, date: Date, id: number, activity: string, description: string, dateOfModification: Date) {
      this.caseId = caseId;
      this.caseName = caseName;
      this.author = author;
      this.date = date;
      this.id = id;
      this.activity = activity;
      this.description = description;
      this.dateOfModification = dateOfModification;
   }
}
