import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import printJS from 'print-js';
import { Observable } from 'rxjs';
import { ToastrImplService } from './toastr.service';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';

@Injectable({
   providedIn: 'root',
})
export class AccommodationService {
   constructor(private httpClient: HttpClient, private app: AppService, private toastr: ToastrImplService) {}

   previewS1(s1Form: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });

      this.httpClient.put(this.app.getUrl() + environment.getAccommodationReportS1Url + '/' + caseId, s1Form, { responseType: 'blob', headers }).subscribe(
         data => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            printJS(fileURL);
         },
         error => {
            manageError(this.toastr, ErrorType.PREVIEW_DOC);
         }
      );
   }

   saveFormS1(formS1: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.getAccommodationSaveFromS1Url + '/' + caseId, formS1, { headers }) as Observable<any>;
   }

   previewS5(s5Form: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });

      this.httpClient.put(this.app.getUrl() + environment.getAccommodationReportS5Url + '/' + caseId, s5Form, { responseType: 'blob', headers }).subscribe(
         data => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            printJS(fileURL);
         },
         error => {
            manageError(this.toastr, ErrorType.PREVIEW_DOC);
         }
      );
   }

   saveFormS5(formS5: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.getAccommodationSaveFromS5Url + '/' + caseId, formS5, { headers }) as Observable<any>;
   }

   accommodationForCase(caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.accommodationForCase + caseId, { headers }) as Observable<any>;
   }

   saveFormO(formO: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.saveAccommodationOUrl + caseId, formO, { headers }) as Observable<any>;
   }

   createAccommodationDecisionO(formO: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.createReportAccommodationOUrl + caseId, formO, { headers }) as Observable<any>;
   }

   createAccommodationFammily(formO: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.createFammilyAccommodation + caseId, formO, { headers }) as Observable<any>;
   }

   previewO(OForm: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });

      this.httpClient.put(this.app.getUrl() + environment.previewAccommodationOUrl + caseId, OForm, { responseType: 'blob', headers }).subscribe(
         data => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            printJS(fileURL);
         },
         error => {
            manageError(this.toastr, ErrorType.PREVIEW_DOC);
         }
      );
   }

   previewFammilyAccommodation(familyForm: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });

      this.httpClient.put(this.app.getUrl() + environment.previewFammilyAccommodation + caseId, familyForm, { responseType: 'blob', headers }).subscribe(
         data => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            printJS(fileURL);
         },
         error => {
            manageError(this.toastr, ErrorType.PREVIEW_DOC);
         }
      );
   }

   finishAccommodationFamily(formO: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.finishFamilyAccommodation + caseId, formO, { headers }) as Observable<any>;
   }

   checkIfHaveActiveRight(caseId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.checkIfHaveActiveRightAccommodation + caseId) as Observable<any>;
   }
}
