<container>
  <page-intro-header title="{{ 'NEW_ADOPTED_CHILDREN.TITLE' | translate }}" back="/adopted-children">
  </page-intro-header>
  <mat-card class="card new-adopted-child">
    <mat-vertical-stepper linear="true" #stepper>
      <mat-step [stepControl]="adopteeStep">
        <ng-template matStepLabel>{{
          'NEW_ADOPTED_CHILDREN.ADOPTEE.TITLE' | translate
        }}</ng-template>
        <new-adopted-child-adoptee-step
          [adopteeStep]="adopteeStep"
          (adopteeStepEmitter)="
            updateAdopteeStep($event)
          "
        ></new-adopted-child-adoptee-step>
      </mat-step>
      <mat-step [stepControl]="biologicalParentsStep">
        <ng-template matStepLabel>{{
          'NEW_ADOPTED_CHILDREN.BIOLOGICAL_PARENTS.TITLE' | translate
        }}</ng-template>
        <new-adopted-child-biological-parents-step
          [biologicalParentsStep]="biologicalParentsStep"
          (biologicalParentsStepEmitter)="updateBiologicalParentsStep($event)"
        ></new-adopted-child-biological-parents-step>
      </mat-step>
      <mat-step [stepControl]="adoptersStep">
        <ng-template matStepLabel>{{
          'NEW_ADOPTED_CHILDREN.ADOPTERS.TITLE' | translate
        }}</ng-template>
        <new-adopted-child-adopters-step
          [adoptersStep]="adoptersStep"
          (adoptersStepEmitter)="updateAdoptersStep($event)"
        ></new-adopted-child-adopters-step>
      </mat-step>
      <mat-step [stepControl]="otherInformationStep">
        <ng-template matStepLabel>{{
          'NEW_ADOPTED_CHILDREN.OTHER_INFORMATION.TITLE' | translate
        }}</ng-template>
        <new-adopted-child-other-information-step
          [otherInformationStep]="otherInformationStep"
          (otherInformationStepEmitter)="updateOtherInformationStep($event)"
        ></new-adopted-child-other-information-step>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card>
</container>
