/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'src/models/subject.model';
import { FamilyMember } from 'src/app/entrance/types';
import { Router, ActivatedRoute } from '@angular/router';
import { CaseService } from 'src/services/case.service';
import { AlfrescoService } from 'src/services/alfresco.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalSizeEnum } from 'src/types';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { NspObservationStepComponent } from './nsp-observation-step/nsp-observation-step.component';
import { NspFamilyStepComponent } from './nsp-family-step/nsp-family-step.component';
import { NspConclusionStepComponent } from './nsp-conclusion-step/nsp-conclusion-step.component';
import { NspPropertyStepComponent } from './nsp-property-step/nsp-property-step.component';
import { MatStepper } from '@angular/material/stepper';
import { SideNavService } from 'src/services/side-nav.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { UserService } from 'src/services/user.service';
import { ForwardCaseDialogComponent } from 'src/app/forward-case-dialog/forward-case-dialog.component';
import { environment } from 'src/environments/environment';
import { ErrorType, manageError, tooManyMembersForBillingWarn } from 'src/app/utils/warningMessage';
import { getNumberFromString } from 'src/app/utils/formatLocalNumber';
import { CaseProblemRecordService } from 'src/services/case-problem-record.service';
import { CaseConclusionComponent } from 'src/app/components/case-conclusion/case-conclusion.component';
import { ToastrImplService } from 'src/services/toastr.service';
import { DataSharingService } from 'src/services/data-sharing.service';

@Component({
   selector: 'nsp-mock',
   templateUrl: './nsp.component.html',
   styleUrls: ['./nsp.component.scss']
})
export class NspComponent implements OnInit {
   subject: Subject;
   subjectDate: string;
   caseData: any;
   observationStep: FormGroup;
   familyMembersStep: FormGroup;
   propertyStep: FormGroup;
   conclusionStep: FormGroup;
   familyMembers: Array<FamilyMember> = [];
   readOnlyDataForConclusionStep: any;
   familyStepFamilyMemberData: any;
   dataFromFamilyForConclusion: any;
   isFormFinished = false;
   panelInfoOpen: any;
   caseName: any;
   caseId: any;
   subjectId: any;
   isValid: boolean;
   isLawyer = false;
   documentOptions = [];
   fileToUpload: File = null;
   uploadEnabled = false;
   solutionRequired = false;
   editable: any;
   object: any = {
      caseId: '',
      userId: '',
      caseStatus: '',
      source: ''
   };
   submitted = false;
   customData: any = {};
   nspRightCodebook: any;
   teamMember = false;
   nspAmountForPropertyStep = 0;
   noJmbp = false;
   loggedIn: any;
   loaded = false;
   oldCaseId = '';
   oldCaseName = '';
   oldSubjectId = '';
   getSocialCase: boolean;
   sendClicked = false;
   title: string;
   familyDossierId: any;
   localStorageCenterId: any;
   sameJmbps = true;
   customDataFamilyStep: any;
   noRegistrationId = false;
   noAbleToWorkId = false;
   isDenial = false;
   noJmbg = false;
   startDate: any;
   hasProblem = false;

   @ViewChild(NspObservationStepComponent) private nspObservationStep: NspObservationStepComponent;
   @ViewChild(NspFamilyStepComponent) private nspFamilyStep: NspFamilyStepComponent;
   @ViewChild(NspPropertyStepComponent) private nspPropertyStep: NspPropertyStepComponent;
   @ViewChild(NspConclusionStepComponent) private nspConclusionStep: NspConclusionStepComponent;
   @ViewChild(CaseConclusionComponent) private caseConclusion: CaseConclusionComponent;
   @ViewChild('stepper') stepper: MatStepper;

   constructor(
      private router: Router,
      private caseService: CaseService,
      private cdr: ChangeDetectorRef,
      private alfrescoService: AlfrescoService,
      formBuilder: FormBuilder,
      public dialog: MatDialog,
      private translate: TranslateService,
      private datePipe: DatePipe,
      private toastr: ToastrImplService,
      private sideNavService: SideNavService,
      private localStorageService: LocalStorageService,
      private route: ActivatedRoute,
      private userService: UserService,
      private dataSharingService: DataSharingService,
      private caseProblemRecordService: CaseProblemRecordService) {
      this.isLawyer = this.userService.isUserLawyer();
      this.route.params.subscribe(params => {
         this.subjectId = params.subjectId;
         this.caseId = params.id;
         this.getSocialCase = false;
         const [, caseType] = this.router.url.split('/cases/');
         if (caseType !== '') {
            this.caseName = caseType.split('/')[0];
         }
         this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
         this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
         this.caseService.getSocialCase(this.caseId).subscribe(
            (result) => {
               this.caseData = result;
               this.title = this.caseData.socialCaseClassificationCodebook.title;
               this.isDenial = this.title.toLowerCase().includes('престанак');
               if (this.loggedIn?.id !== this.caseData.assignedUserId) {
                  this.teamMember = true;
               } else {
                  this.teamMember = false;
               }
               if (this.caseData.caseStatus === 'ACCEPTED') {
                  this.editable = true;
               } else {
                  this.editable = false;

               }
               this.subject = result.subject;
               this.caseService.findNspFormById(this.caseId).subscribe((result1: any) => {
                  this.nspObservationStep?.initializeAll();
                  this.customData = result1;
                  this.loaded = true;
                  this.checkSignacture();

                  if (this.customData?.familyMembers) {
                     this.customData.familyMembers.forEach((member: any) => {
                        const nameParts = member.name.split(' ');
                        member.firstName = nameParts[0];
                        member.lastName = nameParts.slice(1,).join(' ');
                        return member;
                     });
                     this.checkJmbps(this.customData?.familyMembers);
                     this.customDataFamilyStep = this.customData;
                  } else {
                     this.initValues();
                  }

               });
               if ((this.subject.jmbp === null || this.subject.jmbp === '') && this.editable == true) {
                  this.noJmbp = true;
                  this.toastr.info('NSP_MOCK.NO_JBMP');
               }
               if ((this.subject.registrationId === null || this.subject.registrationId === '') && this.editable == true) {
                  this.noRegistrationId = true;
                  this.toastr.info('CASES.NO_REGISTER_NUMBER');
               }
               if ((this.subject.ableToWork === null || this.subject.ableToWork === '') && this.editable == true) {
                  this.noAbleToWorkId = true;
                  this.toastr.info('CASES.NO_ABLE_TO_WORK');
               }
               if ((this.subject.jmbg === null || this.subject.jmbg === '') && this.editable == true) {
                  this.noJmbg = true;
                  this.toastr.info('CASES.NO_JMBG');
               }
               this.getSocialCase = true;
               this.checkSignacture();
               if (this.caseData.nsp === null) {
                  this.startDate = new Date(this.caseData.dateOfCreation.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
               } else {
                  this.startDate = new Date(
                     this.caseData.nsp.validFrom.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
               }
               this.caseHasProblem();
            }
         );
         this.alfrescoService.getDocumentTemplates(this.caseData?.socialCaseClassificationCodebook.oldKindMark).subscribe(
            result => {
               this.documentOptions = result;
            }
         );

         if (this.stepper !== undefined) {
            this.stepper.selectedIndex = 0;
         }
      });
      this.observationStep = formBuilder.group({});
      this.familyMembersStep = formBuilder.group({});
      this.propertyStep = formBuilder.group({});
      this.conclusionStep = formBuilder.group({});
   }

   initValues() {
      this.familyDossierId = this.subject?.familyDossierId;
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.localStorageCenterId = this.localStorageService.get('localStorageCenterId');
      this.getSubjectsFamilyMembers();
   }

   getSubjectsFamilyMembers() {
      const subjectId = this.subject?.subjectIdentity.subjectId;
      let members = null;
      if (this.familyDossierId !== null) {
         members = this.caseService.getFamilyMembersCase(subjectId, this.familyDossierId, this.loggedIn?.csrId);
         members.subscribe((resultMembers: any) => {
            this.checkJmbps(resultMembers);
            this.customDataFamilyStep = { familyMembers: resultMembers };
         });
      }
   }
   ngOnInit() {

   }

   updateStatus(newStatus: string) {
      this.caseData.caseService = newStatus;
      this.editable = true;
      this.caseHasProblem();
      this.cdr.detectChanges();
      if (this.subject.jmbp === null || this.subject.jmbp === '') {
         this.noJmbp = true;
         this.toastr.info('NSP_MOCK.NO_JBMP');
      }
      if (this.subject.registrationId === null || this.subject.registrationId === '') {
         this.noRegistrationId = true;
         this.toastr.info('CASES.NO_REGISTER_NUMBER');
      }
      if (this.subject.jmbg === null || this.subject.jmbg === '') {
         this.noJmbg = true;
         this.toastr.info('CASES.NO_JMBG');
      }
   }

   /**
    * Take information from observation step
    */
   updateObservationStep(step: FormGroup) {
      let familyMembers = 0;
      if (this.customDataFamilyStep !== undefined && this.customDataFamilyStep.familyMembers !== undefined
         && this.customDataFamilyStep.familyMembers.length !== 0) {
         familyMembers = this.customDataFamilyStep.familyMembers.filter((member: any) => member.forBilling === true).length;
      }
      this.observationStep = step;
      tooManyMembersForBillingWarn(familyMembers, this.toastr);
   }

   /**
    * Take information from property step
    */
   updatePropertyStep(step: any) {
      // Propagate these information to final step (readonly fields)
      this.propertyStep = step;
      let {
         value: {
            earningAmountFrom,
            earningAmountTo,
            earningAmount,
            earningWorkArrangementAmount,
            earningRentingAmount,
            missedEarningAmount,
            missedEarningAmountFrom,
            missedEarningAmountTo,
            missedEarningWorkArrangementAmount,
            missedEarningRentingAmount
         }
      } = step;
      if (earningAmount === '' || earningAmount == null) {
         earningAmount = 0;
      }
      if (earningRentingAmount === '' || earningRentingAmount == null) {
         earningRentingAmount = 0;
      }
      if (earningWorkArrangementAmount === '' || earningWorkArrangementAmount == null) {
         earningWorkArrangementAmount = 0;
      }
      if (missedEarningAmount === '' || missedEarningAmount == null) {
         missedEarningAmount = 0;
      }
      if (missedEarningRentingAmount === '' || missedEarningRentingAmount == null) {
         missedEarningRentingAmount = 0;
      }
      if (missedEarningWorkArrangementAmount === '' || missedEarningWorkArrangementAmount == null) {
         missedEarningWorkArrangementAmount = 0;
      }
      earningAmountFrom = this.propertyStep.controls.earningAmountFrom.value;
      earningAmountTo = this.propertyStep.controls.earningAmountTo.value;
      earningAmount = getNumberFromString(earningAmount);
      missedEarningRentingAmount = getNumberFromString(missedEarningRentingAmount);
      missedEarningAmount = getNumberFromString(missedEarningAmount);
      this.readOnlyDataForConclusionStep = {
         earningAmountFrom,
         earningAmountTo,
         earningAmount,
         earningWorkArrangementAmount,
         earningRentingAmount,
         missedEarningAmount,
         missedEarningAmountFrom,
         missedEarningAmountTo,
         missedEarningWorkArrangementAmount,
         missedEarningRentingAmount
      };
   }

   /**
    * Take information from last step, formulate the data and submit it via API
    */
   updateConclusionStep(step: FormGroup) {
      this.sendClicked = true;
      this.conclusionStep = step;

      const submitterData = this.createNspObject();
      if (this.checkDocumentValidation()) {
         this.caseService.submitNspCase(this.caseId, submitterData).subscribe(
            () => {
               if (this.conclusionStep.value.send === 'false') {
                  this.toastr.success('SNACKBAR.DOCUMENT_SENT');
               } else if (this.conclusionStep.value.send === 'true') {
                  this.openDialog(this.caseId);
               }
            },
            error => {
               manageError(this.toastr, ErrorType.SAVE_DATA);
               this.sendClicked = false;
            }
         );
      }
   }

   createNspObject() {
      const circumstances = [];
      if (this.propertyStep.value.circumstances !== '' && this.propertyStep.value.circumstances !== undefined) {
         for (const pr of this.propertyStep.value.circumstances) {
            circumstances.push(pr.value);
         }
      }
      let ableToLease = null;
      let isRegisteredCsr = null;
      let lease = null;
      let ownsRealEstate = null;
      let surplusHousing = null;

      if (this.propertyStep.value.ableToLease !== '' && this.propertyStep.value.ableToLease !== undefined) {
         ableToLease = JSON.parse(this.propertyStep.value.ableToLease);
      }
      if (this.propertyStep.value.isRegisteredCSR !== '' && this.propertyStep.value.isRegisteredCSR !== undefined) {
         isRegisteredCsr = JSON.parse(this.propertyStep.value.isRegisteredCSR);
      }
      if (this.propertyStep.value.lease !== '' && this.propertyStep.value.lease !== undefined) {
         lease = JSON.parse(this.propertyStep.value.lease);
      }
      if (this.propertyStep.value.ownsRealestate !== '' && this.propertyStep.value.ownsRealestate !== undefined) {
         ownsRealEstate = JSON.parse(this.propertyStep.value.ownsRealestate);
      }
      if (this.propertyStep.value.surplusHousing !== '' && this.propertyStep.value.surplusHousing !== undefined) {
         surplusHousing = JSON.parse(this.propertyStep.value.surplusHousing);
      }
      if (this.familyMembers.length <= 0 && this.subject.ableToWork !== null && this.subject.ableToWork === false) {
         this.familyMembersStep.controls.increasedNsp.setValue(true);
      } else if (this.familyMembers.length <= 0 && this.subject.ableToWork !== null && this.subject.ableToWork === true) {
         this.familyMembersStep.controls.increasedNsp.setValue(false);
      }

      const submitterData = {
         fieldVisits: this.observationStep.value.fieldVisitObservations,
         onSightInvestigation: this.observationStep.value.onSightInvestigationObservations,
         statements: this.observationStep.value.statementObservations,
         witnessHearing: this.observationStep.value.witnessHearingObservations,
         otherEvidence: this.observationStep.value.otherEvidenceObservations,
         note: this.familyMembersStep.value.note,
         familyMembers: [...this.familyMembers],
         ableToLease,
         otherCircumstances: this.propertyStep.value.otherCircumstances,
         ownsMovableProperty: this.propertyStep.value.ownsMovableProperty,
         regIDNumber: this.propertyStep.value.regIDNumber,
         circumstances,
         movableProperty: this.propertyStep.value.movableProperty,
         earningAmountFrom: this.propertyStep.controls.earningAmountFrom.value,
         earningAmountTo: this.propertyStep.controls.earningAmountTo.value,
         earningRenting: this.propertyStep.value.earningRenting,
         earningRentingAmount: getNumberFromString(this.propertyStep.value.earningRentingAmount),
         earningRentingWhat: this.propertyStep.value.earningRentingWhat,
         earningWorkArrangement: this.propertyStep.value.earningWorkArrangement,
         earningWorkArrangementAmount: getNumberFromString(this.propertyStep.value.earningWorkArrangementAmount),
         isRegisteredCsr,
         lease,
         canProvide: this.propertyStep.value.canProvide === '' ? false : this.propertyStep.value.canProvide,
         missedEarning: this.propertyStep.value.missedEarning,
         missedEarningAmountFrom: this.propertyStep.value.missedEarningAmountFrom,
         missedEarningAmountTo: this.propertyStep.value.missedEarningAmountTo,
         missedEarningRenting: this.propertyStep.value.missedEarningRenting,
         missedEarningRentingWhat: this.propertyStep.value.missedEarningRentingWhat,
         missedEarningRentingAmount: getNumberFromString(this.propertyStep.value.missedEarningRentingAmount),
         missedEarningWorkArrangement: this.propertyStep.value.missedEarningWorkArrangement,
         missedEarningWorkArrangementAmount: getNumberFromString(this.propertyStep.value.missedEarningWorkArrangementAmount),
         // dodatni prihodi
         earningFromEmployment: this.propertyStep.value.earningFromEmployment,
         earningFromEmploymentAmount: getNumberFromString(this.propertyStep.value.earningFromEmploymentAmount),
         earningFromTemporaryJobs: this.propertyStep.value.earningFromTemporaryJobs,
         earningFromTemporaryJobsAmount: getNumberFromString(this.propertyStep.value.earningFromTemporaryJobsAmount),
         earningFromRetirements: this.propertyStep.value.earningFromRetirements,
         earningFromRetirementsAmount: getNumberFromString(this.propertyStep.value.earningFromRetirementsAmount),
         earningFromAgriculture: this.propertyStep.value.earningFromAgriculture,
         earningFromAgricultureAmount: getNumberFromString(this.propertyStep.value.earningFromAgricultureAmount),
         earningFromIndipendentActivity: this.propertyStep.value.earningFromIndipendentActivity,
         earningFromIndipendentActivityAmount: getNumberFromString(this.propertyStep.value.earningFromIndipendentActivityAmount),
         compensationDuringUnemployment: this.propertyStep.value.compensationDuringUnemployment,
         compensationDuringUnemploymentAmount: getNumberFromString(this.propertyStep.value.compensationDuringUnemploymentAmount),
         severancePayAndCompensation: this.propertyStep.value.severancePayAndCompensation,
         severancePayAndCompensationAmount: getNumberFromString(this.propertyStep.value.severancePayAndCompensationAmount),
         earningFromGoods: this.propertyStep.value.earningFromGoods,
         earningFromGoodsAmount: getNumberFromString(this.propertyStep.value.earningFromGoodsAmount),
         earningFromSavingAndCash: this.propertyStep.value.earningFromSavingAndCash,
         earningFromSavingAndCashAmount: getNumberFromString(this.propertyStep.value.earningFromSavingAndCashAmount),
         earningFromMaintenanceOfRelativies: this.propertyStep.value.earningFromMaintenanceOfRelativies,
         earningFromMaintenanceOfRelativiesAmount: getNumberFromString(this.propertyStep.value.earningFromMaintenanceOfRelativiesAmount),

         ownsRealEstate,
         realestateSize: getNumberFromString(this.propertyStep.value.realestateSize),
         roomsNumber: this.propertyStep.value.roomsNumber,
         surplusHousing,
         missedEarningAmount: getNumberFromString(this.propertyStep.value.missedEarningAmount),
         earningAmount: getNumberFromString(this.propertyStep.value.earningAmount),
         familyMembersCount: this.conclusionStep.value.familyMembersCount,
         familyMembersAbleToWorkCount: this.conclusionStep.value.familyMembersAbleToWorkCount,
         familyMembersUnableToWorkCount: this.conclusionStep.value.familyMembersUnableToWorkCount,
         ableToLearnJobSkillsCount: this.conclusionStep.value.ableToLearnJobSkillsCount,
         readyToLearnJobSkillsCount: this.conclusionStep.value.readyToLearnJobSkillsCount,
         requiresAdditionalServices: this.conclusionStep.value.requiresAdditionalServices,
         conclusion: this.conclusionStep.value.conclusion,
         advice: this.conclusionStep.value.advice,
         increasedNsp: this.familyMembersStep.value.increasedNsp === null || this.familyMembersStep.value.increasedNsp === '' ?
            false : this.familyMembersStep.value.increasedNsp,
         increasedNspReason: this.familyMembersStep.value.increasedNspReason,
         validFrom: this.conclusionStep.value.dateStart,
      };
      return this.formatDatesInSubmitterData(submitterData);
   }
   /**
    * Sign the form before submitting the data
    */
   handleSignature() {
      this.isFormFinished = true;
      this.toastr.success('SNACKBAR.DOCUMENT_FINISHED');
   }

   /**
    * Review if all form steps are valid
    */
   checkDocumentValidation(): boolean {
      const isObservationStepValid = this.observationStep && this.observationStep.valid;
      const isFamilyMembersStepValid = this.familyMembersStep && this.familyMembersStep.valid;
      const isPropertyStepValid = this.propertyStep && this.propertyStep.valid;
      const isConclusionStepValid = this.conclusionStep && this.conclusionStep.valid;

      return [isObservationStepValid, isFamilyMembersStepValid, isPropertyStepValid, isConclusionStepValid].every(val => val === true);
   }

   enableButton() {
      this.uploadEnabled = true;
   }

   openDialog(caseId: number) {

      this.object.userId = null;
      this.object.caseId = caseId;
      this.object.caseStatus = this.caseData.caseStatus;

      const dialogRef = this.dialog.open(ForwardCaseDialogComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         data: this.object,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(res => {
         this.submitted = true;
         if (res.event === 'SUCCESS') {
            const forwardDto = {
               userId: res.data.user,
               role: res.data.role
            };
            this.caseService.forwardCase(res.data.caseId, forwardDto).subscribe(
               (result) => {
                  if (result) {
                     this.toastr.success('NSP_MOCK.CONCLUSION.SEND_TO_LAWYER');
                     this.router.navigateByUrl('/subjects/' + this.subjectId);
                     this.sideNavService.toggle();
                     this.sendClicked = false;
                     this.dataSharingService.cases.next(true);
                  } else {
                     this.toastr.error('PISARNICA.ERROR_ACCEPTED');
                  }
               },
               err => {
                  console.log('Error occured:', err);
                  this.sendClicked = false;
               }
            );
         } else if (res.event === 'CLOSE') {
            this.caseService.closeCase(res.data.caseId).subscribe(
               () => {
                  this.cdr.detectChanges();
                  this.router.navigateByUrl('/subjects/' + this.subjectId);
                  this.sendClicked = false;
                  this.dataSharingService.cases.next(true);

               },
               err => {
                  console.log('Error occured:', err);
                  this.sendClicked = false;
               }
            );
         } else {
            this.sendClicked = false;
         }
      });

   }

   printPreview(step: FormGroup) {
      this.conclusionStep = step;
      const submitterData = this.createNspObject();
      this.caseService.nspNmPreview(this.caseId, submitterData);
   }

   saveData() {
      if (this.isLawyer && this.teamMember === false && this.customData !== null) {
        this.caseConclusion.saveCurrentData();
      } else {
        this.saveNspNm();
      }
   }

   saveNspNm() {
      this.nspObservationStep.updateObservationStep();
      this.nspFamilyStep.updateFamilyMembersStep();
      this.nspPropertyStep.updatePropertyStep();
      this.nspConclusionStep.saveDataForConclusion();
      const submitterData = this.createNspObject();
      this.caseService.saveDataNspCase(this.caseId, submitterData).subscribe((result) => {
         this.checkJmbps(result.familyMembers);
         this.toastr.success('NSP_MOCK.SAVED_DATA');
         this.nspObservationStep.patchValues(result, true);
         this.nspFamilyStep.patchValue(result, true);
         this.nspPropertyStep.patchValue(result, true);
         this.nspConclusionStep.patchValues(result);
         this.nspObservationStep.observationStep.markAsPristine();
         this.nspFamilyStep.familyMembersStep.markAsPristine(); 
         this.nspPropertyStep.propertyStep.markAsPristine();
      }, error => {
         this.toastr.error('NSP_MOCK.SAVED_DATA_ERROR');
      });
   }

   saveConclusionStepData(step: FormGroup) {
      this.conclusionStep = step;
   }
   returnToAllocation() {
      let message = '';
      let title = '';
      this.translate.get('WARNING_DIALOG.NSP_NM_MISSING.TITLE').subscribe((res: string) => {
         title = res;
      });
      if (this.customData == null && this.editable !== true) {
         this.translate.get('WARNING_DIALOG.NSP_NM_MISSING.MESSAGE_ALLOCATION').subscribe((res: string) => {
            message = res;
         });
      } else {
         this.translate.get('WARNING_DIALOG.NSP_NM_MISSING.MESSAGE_TO_WORKER').subscribe((res: string) => {
            message = res;
         });
      }
      const object = {
         document: null,
         action: 'allocationError',
         subjectId: this.subjectId,
         message,
         title
      };
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         data: object,
         panelClass: 'overlay-panel',
      });
      // trebalo bi ovde proveriti da li je nspnm sacuvan ako jeste vratiti onda strucnom radniku ako nije onda na raspodelu
      // trenutno resenje vraca sve na raspodelu
      dialogRef.afterClosed().subscribe(() => {
         if (this.customData === null && this.editable !== true) {
            const x = this.caseService.declineCase(this.caseId);
            x.subscribe(
               () => {
                  this.dataSharingService.cases.next(true);
                  this.router.navigateByUrl('/');
               },
               () => {
                  console.log('Error occured');
               }
            );
         } else {
            const assignedDto = {
               role: environment.strucni_radnik,
               taskComment: null
            };
            this.caseService.returnCase(this.caseId, assignedDto).subscribe(
               () => {
                  this.dataSharingService.cases.next(true);
                  this.router.navigateByUrl('/');
               },
               err => {
                  console.log('Error occured:', err);
               }
            );
         }

      });
   }
   checkSignacture() {
      if (this.loaded === true && this.getSocialCase === true) {
         if (this.isLawyer && this.teamMember === false) {
            if (this.customData === null || this.caseData.signature === null) {
               this.returnToAllocation();
            }
         }
      }
   }

   checkJmbps(members: any) {
      const familyMembers = [];
      members.forEach((member: any) => {
         if (this.subject.jmbp !== member.jmbp) {
            familyMembers.push(member);
         }
      });
      if (familyMembers.length > 0) {
         this.sameJmbps = false;

         const subjectName = this.subject.firstName + ' ' + this.subject.lastName;
         let messageUser = '';
         let messageJmbp = '';
         this.translate.get('SNACKBAR.PARTIAL.USER').subscribe((res: string) => {
            messageUser = res;
         });
         this.translate.get('SNACKBAR.PARTIAL.NOT_EQUAL_JMBP_NSP').subscribe((res: string) => {
            messageJmbp = res;
         });

         familyMembers.forEach(member => {
            const message = messageUser + member.name + messageJmbp + subjectName;
            member.tooltipText = message;
            member.jmbpWarning = true;
            this.toastr.showWarning(message, 30000 );
         });

      }
   }

   transformSubject(subject: any): any {
      return {
         name: `${subject.firstName} ${subject.lastName}`,
         regNumber: subject.registrationId,
         yearOfBirth: subject.yearOfBirth,
         jmbg: subject.jmbg,
         jmbp: subject.jmbp,
         qualificationEmployment: subject.occupation !== null ? subject.occupation.title : 'Непознато',
         ableToWork: subject.ableToWork,
         disabledPerson: subject.disabledPerson,
         relationship: subject.relationship ? subject.relationship : null,
         dateOfBirth: subject.dateOfBirth,
         forBilling: subject.forBilling,
         editable: subject.editable
      };
   }
   acceptedComplain() {
      this.caseData.newComplain = false;
   }

   updateFamilyMembersStep(
      { step, familyMembers, membersForBillingCounter }:
         {
            step: FormGroup, familyMembers?: Array<FamilyMember>,
            membersForBillingCounter: number
         }) {
      this.familyMembers = familyMembers;
      this.familyMembersStep = step;
      // prosledjivanje podataka o porodici u naredne korake za validaciju NSP-a
      // this.familyStepFamilyMemberData = this.familyMembers;

      const familyMembersCount = this.familyMembers.length + 1;
      let familyMembersAbleToWorkCount = 0;
      let familyMembersUnableToWorkCount = 0;
      for (const member of familyMembers) {
         if (member.ableToWork !== null) {
            member.ableToWork === true ? familyMembersAbleToWorkCount++ : familyMembersUnableToWorkCount++;
         }
      }
      if (this.subject.ableToWork !== null) {
         this.subject.ableToWork === true ? familyMembersAbleToWorkCount++ : familyMembersUnableToWorkCount++;
      }
      this.dataFromFamilyForConclusion = {
         familyMembersCount,
         familyMembersAbleToWorkCount,
         familyMembersUnableToWorkCount,
         membersForBillingCounter,
         familyMembers: this.familyMembers
      };

      this.nspPropertyStep.refreshRegNumberSet();
   }

   private formatDatesInSubmitterData(submitterData: any) {
      if (submitterData.statements !== null && submitterData.statements !== undefined) {
         for (const element of submitterData.statements) {
            const str = new Date(element.date).getTime();
            element.date = this.datePipe.transform(str, 'yyyy-MM-dd');
         }
      }
      if (submitterData.onSightInvestigation !== null && submitterData.onSightInvestigation !== undefined) {
         for (const element of submitterData.onSightInvestigation) {
            const str = new Date(element.date).getTime();
            element.date = this.datePipe.transform(str, 'yyyy-MM-dd');
         }
      }
      if (submitterData.fieldVisits !== null && submitterData.fieldVisits !== undefined) {
         for (const element of submitterData.fieldVisits) {
            const str = new Date(element.date).getTime();
            element.date = this.datePipe.transform(str, 'yyyy-MM-dd');
         }
      }
      if (submitterData.witnessHearing !== null && submitterData.witnessHearing !== undefined) {
         for (const element of submitterData.witnessHearing) {
            const str = new Date(element.date).getTime();
            element.date = this.datePipe.transform(str, 'yyyy-MM-dd');
         }
      }
      return submitterData;
   }

   problemAdded(added: boolean) {
      if (added) {
         this.hasProblem = true;
         this.cdr.detectChanges();
      }
   }
   caseHasProblem() {
      this.caseProblemRecordService.caseHasProblem(this.caseData?.caseStatus, this.caseId, this.caseData?.caseKindCodebook?.kind).
         subscribe((hasProblem) => { this.hasProblem = hasProblem; });
   }
   //Check if there any unsaved data etc. If yes then as for confirmation 
   async saveChanges() : Promise<boolean> {
       
      //this.caseConclusion?.caseConclusion.pristine
      const pristine = this.isLawyer && this.teamMember === false && this.customData !== null ? 
         this.caseConclusion.caseConclusion.pristine :
         (
            this.nspObservationStep?.observationStep.pristine && 
            this.nspFamilyStep?.familyMembersStep.pristine && 
            this.nspPropertyStep?.propertyStep.pristine
         );
      if(
         this.teamMember ||
         this.submitted || 
         this.caseConclusion?.isSubmited || 
         pristine
      ){
         return true;
      }
      let message = '';
      let title = '';
      this.translate.get('WARNING_DIALOG.SAVE_DATA.TITLE').subscribe((res: string) => {
         title = res;
      });
      this.translate.get('WARNING_DIALOG.SAVE_DATA.MESSAGE').subscribe((res: string) => {
         message = res;
      });
      const object = {
         document: null,
         message: message,
         title: title,
         saveData: true,
      };   

      const dialogRef = this.dialog.open(WarningMessageComponent, {
         data: object,
         width: ModalSizeEnum.MINI,
         panelClass: 'overlay-panel',
      });
      await dialogRef.afterClosed().toPromise().then(         
         res =>{
            if(res?.event !== 'cancel' && res !== undefined){
               return this.saveData();
            }
         }
      );
      return true;
   }
}
