/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';
import { Subject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { WebsocketService } from './websocket.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';
import { ToastrImplService } from './toastr.service';

export interface LogData {
  email: string;
  role: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // tslint:disable-next-line: variable-name
  _userActionOccured: Subject<void> = new Subject();
  _userLoginActionOccured: Subject<void> = new Subject();
  logData: LogData;

  unsubscribeLogin$: Subject<void> = new Subject();
  loginTimerSubscription: Subscription;
  timerSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private router: Router,
    private websocketService: WebsocketService,
    private localStorageService: LocalStorageService,
    private toastr: ToastrImplService,
    private translate: TranslateService,
    private userService: UserService
  ) {
  }

  login(username: string, password: string) {
    this.http.post(this.appService.getUrl() + environment.loginUrl, { userId: username, password })
      .subscribe(result => {
        const obj = JSON.parse(JSON.stringify(result));
        if (obj.status !== 404) {
          this.saveToken(JSON.parse(obj.entity.token));
          this.localStorageService.set('loggedUser', obj.entity.loggedIn);
          if (JSON.parse(this.localStorageService.get('loggedUser')).role.length > 1) {
            const firstRole = JSON.parse(this.localStorageService.get('loggedUser')).role[0];

            // ako ima i raspodeljivac i rukovodilac, ali prva na spisku je raspodeljivac
            if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.rukovodilac)) {
              if (firstRole === environment.raspodeljivac) {
                this.localStorageService.set('primaryRole', ' Руководилац службе');
              } else {
                this.localStorageService.set('primaryRole', this.userService.getUserRoleTitle([firstRole]));
              }
            } else {
              this.localStorageService.set('primaryRole',
                this.userService.getUserRoleTitle(JSON.parse(this.localStorageService.get('loggedUser')).role.slice(0, 1)));
            }
          }
          this.router.navigateByUrl('/').then(
            () => {
              window.location.reload();
            }
          );
        } else {
          this.toastr.error('INVALID_CREDENTIALS');
        }
      },
        () => {
          this.toastr.error('INVALID_CREDENTIALS');
        });
  }

  impersonate(userId: string) {
    this.http.post(this.appService.getUrl() + environment.impersonateUrl, {userId:userId,refreshToken:this.getRefreshToken()})
      .subscribe(result => {
        const obj = JSON.parse(JSON.stringify(result));
        if (obj.status !== 404) {
          this.saveToken(JSON.parse(obj.entity.token));
          this.localStorageService.set('loggedUser', obj.entity.loggedIn);
          if (JSON.parse(this.localStorageService.get('loggedUser')).role.length > 1) {
            const firstRole = JSON.parse(this.localStorageService.get('loggedUser')).role[0];

            // ako ima i raspodeljivac i rukovodilac, ali prva na spisku je raspodeljivac
            if (JSON.parse(this.localStorageService.get('loggedUser')).role.some((role: string) => role === environment.rukovodilac)) {
              if (firstRole === environment.raspodeljivac) {
                this.localStorageService.set('primaryRole', ' Руководилац службе');
              } else {
                this.localStorageService.set('primaryRole', this.userService.getUserRoleTitle([firstRole]));
              }
            } else {
              this.localStorageService.set('primaryRole',
                this.userService.getUserRoleTitle(JSON.parse(this.localStorageService.get('loggedUser')).role.slice(0, 1)));
            }
          }
          this.router.navigateByUrl('/').then(
            () => {
              window.location.reload();
            }
          );
        } else {
          this.toastr.error('INVALID_CREDENTIALS');
        }
      },
        () => {
          this.toastr.error('INVALID_CREDENTIALS');
        });
  }

  saveToken(token: { access_token: any; refresh_token: any; refresh_expires_in: number; }) {
    this.localStorageService.set('access_token', token.access_token);
    this.localStorageService.set('refresh_token', token.refresh_token);
    this.localStorageService.set('refresh_token', token.refresh_token);
    const d = new Date();
    d.setSeconds(d.getSeconds() + (token.refresh_expires_in / 2));
    this.localStorageService.set('timeout', d);
  }

  checkCredentials() {
    return this.localStorageService.check('access_token');
  }

  logout() {
    this.http.post(this.appService.getUrl() + environment.logoutUrl,
      { refresh_token: this.localStorageService.get('refresh_token') })
      .subscribe(
        (result) => {
          this.cleanStorage();
          this.unsubscribeLogin$.next();
          this.unsubscribeLogin$.complete();
          this.logoutNavigate();
        },
        () => {
          // alert('Timeout');
        }
      );
  }

  cleanStorage() {
    this.localStorageService.remove('access_token');
    this.localStorageService.remove('refresh_token');
    this.localStorageService.remove('loggedUser');
    this.localStorageService.remove('entranceIdentity');
    this.localStorageService.remove('primaryRole');
    this.localStorageService.remove('nspRequestIdentity');
    this.localStorageService.remove('nspFamilyRequestIdentity');
    this.localStorageService.remove('timeout');
    this.localStorageService.remove('localStorageCenterId');
    this.localStorageService.remove('localStorageSubcenter');
    this.localStorageService.remove('filterCsrOuId');
    this.localStorageService.remove('filter');
    this.localStorageService.remove('filterPage');
    this.localStorageService.remove('case');
    this.localStorageService.remove('milisecondsUntilTimeout');
    this.localStorageService.remove('impersonate');
  }

  logoutNavigate() {
    this.router.navigateByUrl('/').then(
      () => {
        window.location.reload();
      }
    );
  }

  refreshToken() {
    return this.http.post<any>(this.appService.getUrl() + environment.refreshTokenUrl,
      { refreshToken: this.getRefreshToken() },
    ).pipe(tap((token) => {
      if (token !== null) {
        this.saveToken(token);
      } else {
        this.cleanStorage();
        this.unsubscribeLogin$.next();
        this.unsubscribeLogin$.complete();
        this.logoutNavigate();
      }
    }));
  }

  refreshTokenBeforeTimeout() {
    this.http.post<any>(this.appService.getUrl() + environment.refreshTokenUrl, { refreshToken: this.getRefreshToken() }).subscribe(
      result => {
        if (result !== null) {
          this.saveToken(result);
          this.resetLoginTimer();
        } else {
          this.cleanStorage();
          this.unsubscribeLogin$.next();
          this.unsubscribeLogin$.complete();
          this.logoutNavigate();
        }
      }
    );
  }

  connectWebsocket() {
    const log = JSON.parse(this.localStorageService.get('loggedUser'));
    this.websocketService.initializeWebSocketConnection(log.email);
  }

  getJwtToken() {
    return this.localStorageService.get('access_token');
  }

  private getRefreshToken() {
    return this.localStorageService.get('refresh_token');

  }

  get userActionOccured(): Observable<void> {
    return this._userActionOccured.asObservable();
  }

  notifyUserAction() {
    this._userActionOccured.next();
  }

  notifyLoginAction() {
    this._userLoginActionOccured.next();
  }

  resetLoginTimer() {
    this.notifyLoginAction();
  }

}
