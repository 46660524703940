<div class="wrapper">
   <div class="header-wrapper">
      <span [ngClass]="{
        category: true,
        'category--red': caseData?.caseTypeCodebook.mark === 'C',
        'category--yellow': caseData?.caseTypeCodebook.mark === 'Ž',
        'category--white': caseData?.caseTypeCodebook.mark === 'O',
        'category--blue': caseData?.caseTypeCodebook.mark === 'P',
        'category--purple': caseData?.caseTypeCodebook.mark === 'LJ',
        'category--dashedred': caseData?.caseTypeCodebook.mark === 'IC',
        'category--dashedblue': caseData?.caseTypeCodebook.mark === 'IP'
      }">
      </span>
      <page-intro-header title="{{ caseTitle }}">
         <span matTooltip="{{'CASES.NOT_ACCEPTED' | translate }}"
            [matTooltipDisabled]="!(teamMember && caseData.caseStatus === 'ASSIGNED')">
            <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" class="dropdown-button"
               [disabled]="teamMember && caseData.caseStatus === 'ASSIGNED'">
               {{ 'GLOBAL.MANAGE_CASE' | translate }} <mat-icon>arrow_drop_down</mat-icon>
            </button>
         </span>
         <mat-menu #menu="matMenu" xPosition="before">
            <span>
               <button
                  *ngIf="caseData?.caseStatus == 'ACCEPTED' && !isLawyer && !teamMember && forwardingLawyerWorker===false&&!caseData?.newComplain"
                  mat-menu-item (click)="forwardCase(caseData.id)">
                  <mat-icon class="green-icon">arrow_forward</mat-icon>
                  {{ 'CASES.SEND_LAWYER' | translate }}
               </button>
            </span>
            <span>
               <button
                  *ngIf="caseData?.caseStatus == 'ACCEPTED' && isLawyer && forwardingLawyerWorker===false && caseData?.caseManagerId !==null&&!caseData?.newComplain"
                  mat-menu-item (click)="returnCase(caseData.id)">
                  <mat-icon>arrow_forward</mat-icon>
                  {{ "CASES.RETURN_CASE" | translate }}
               </button>
            </span>
            <span>
               <button *ngIf="caseData?.caseStatus == 'ASSIGNED' && caseData?.assignedUserId === loggedIn.id"
                  mat-menu-item (click)="acceptCase(caseData.id)">
                  <mat-icon>check</mat-icon>
                  {{ 'VS.ACCEPT' | translate }}
               </button>
            </span>
            <span matTooltip="{{'CASES.SENDED_INTO_PLANNER' | translate }}"
               [matTooltipDisabled]="caseData?.intoPlanner === false">
               <button [disabled]="caseData?.intoPlanner === true"
                  *ngIf="caseData?.caseStatus == 'ACCEPTED' && caseData?.assignedUserId === loggedIn.id" mat-menu-item
                  (click)="sendIntoPlanner(caseData.id)">
                  <mat-icon>arrow_forward</mat-icon>
                  {{ 'VS.PLANNER' | translate }}
               </button>
            </span>
            <span>
               <button *ngIf="caseData?.caseStatus == 'ASSIGNED' && caseData?.assignedUserId === loggedIn.id"
                  mat-menu-item (click)="declineCase(caseData.id)">
                  <mat-icon>clear</mat-icon>
                  {{ 'VS.DECLINE' | translate }}
               </button>
            </span>
            <span>
               <span matTooltip="{{'CASES.COMPLAIN_RESPOND_NEEDED' | translate }}"
                  [matTooltipDisabled]="!caseData?.newComplain">
                  <button
                     *ngIf="caseData?.caseStatus == 'ACCEPTED' && caseData?.assignedUserId === loggedIn.id && 
             caseData?.socialCaseClassificationCodebook?.caseKindViewCodebook?.base === 'VS' && !localHasTeamRequest && !isLawyer"
                     mat-menu-item (click)="openTeamRequest(caseData.id)" [disabled]="caseData?.newComplain">
                     <mat-icon>arrow_forward</mat-icon>
                     {{ "VS.CREATE_TEAM" | translate }}
                  </button>
               </span>
            </span>

            <span>
               <span matTooltip="{{'CASES.COMPLAIN_RESPOND_NEEDED' | translate }}"
                  [matTooltipDisabled]="!caseData?.newComplain">
                  <button *ngIf="
             caseData?.caseStatus == 'ACCEPTED' && caseData?.assignedUserId === loggedIn.id && !isLawyer &&
             (caseData?.socialCaseClassificationCodebook?.caseKindViewCodebook?.base === 'VS'||caseName==='L')"
                     mat-menu-item (click)="handleCloseCase()" [disabled]="caseData?.newComplain">
                     <mat-icon>arrow_forward</mat-icon>
                     {{ "VS.CLOSE_CASE.QUESTION_BUTTON" | translate }}
                  </button>
               </span>
            </span>

            <span>
               <button *ngIf="caseData?.caseStatus == 'ACCEPTED' && caseData?.assignedUserId === loggedIn.id &&
          caseData?.socialCaseClassificationCodebook?.caseKindViewCodebook?.base === 'PMN'" mat-menu-item
                  (click)="handleClosePmnCase()">
                  <mat-icon>arrow_forward</mat-icon>
                  {{ "VS.CLOSE_CASE.QUESTION_BUTTON" | translate }}
               </button>
            </span>
            <span>
               <button *ngIf="saveDataCondition()" mat-menu-item (click)="saveData()">
                  <mat-icon>arrow_forward</mat-icon>
                  {{ "GLOBAL.SAVE" | translate }}
               </button>
            </span>
            <span>
               <button *ngIf="caseData?.caseStatus == 'ACCEPTED' && caseData?.assignedUserId === loggedIn.id"
                  mat-menu-item (click)="addProblem()">
                  <mat-icon>playlist_add</mat-icon>
                  {{ "GLOBAL.NEW_SUBJECT_PROBLEM" | translate }}
               </button>
            </span>
            <span>
               <button *ngIf="caseData?.caseStatus == 'ACCEPTED' && caseData?.assignedUserId === loggedIn.id"
                  mat-menu-item (click)="addRecord()">
                  <mat-icon>playlist_add</mat-icon>
                  {{ "NEW_SUBJECT_RECORD.SUBMIT" | translate }}
               </button>
            </span>
            <span>
               <button *ngIf="teamMember && caseData?.caseStatus === 'ACCEPTED'" mat-menu-item
                  (click)="resolveAsTeamMember()">
                  <mat-icon>clear</mat-icon>
                  {{ "CASES.TEAM_MEMBER_FINISH" | translate }}
               </button>
            </span>
            <span>
               <button *ngIf="caseData?.caseStatus === 'ACCEPTED' && caseData?.newComplain" mat-menu-item
                  (click)="addResponseToComplain()">
                  <mat-icon>arrow_forward</mat-icon>
                  {{ "CASES.RESPOND_TO_COMPLAIN" | translate }}
               </button>
            </span>
            <span>
               <button [disabled]="!sendToSK||disableInsertSubject" *ngIf="caseData?.caseStatus === 'ACCEPTED' && caseData?.assignedUserId === loggedIn.id"
                  mat-menu-item (click)="addSubjectInSK()">
                  <mat-icon>arrow_forward</mat-icon>
                  {{ "SUBJECT_SK.INPUT_SUBJECT_TITLE" | translate }}
               </button>
            </span>
            <span>
               <button *ngIf="caseData?.caseStatus === 'ACCEPTED' && caseData?.reopen ===true && !caseData?.newComplain"
                  mat-menu-item (click)="closeWithoutChange()">
                  <mat-icon>arrow_forward</mat-icon>
                  {{ "CASES.CLOSE_WITHOUT_CHANGE" | translate }}
               </button>
            </span>
            <span>
               <button *ngIf="caseData?.caseStatus === 'ACCEPTED' && rightType!==null" mat-menu-item
                  (click)="openRightsHistoryComponent()">
                  <mat-icon>list</mat-icon> {{ 'CASE_HISTORY_BY_RIGHT.TITLE' | translate }}
               </button>
            </span>
         </mat-menu>
      </page-intro-header>
   </div>
   <ng-container *ngIf="caseData">
      <div class="case-status-indicator">
         <app-timeout-indicator [startOrEnd]="'END'" [date]="remainingTime"></app-timeout-indicator>
      </div>
   </ng-container>
   <mat-accordion>
      <mat-expansion-panel (opened)="panelInfoOpen = true" (closed)="panelInfoOpen = false"
         class="subject-details__expansion-panel">
         <mat-expansion-panel-header>
            <mat-panel-title class="panel">
               <mat-icon class="panel__icon">info</mat-icon>{{ 'VS.SUBJECT_DETAILS.PANEL_TITLE' | translate }}
            </mat-panel-title>
            <mat-panel-description class="panel">
               {{ !panelInfoOpen && subject ? title : '' }}
            </mat-panel-description>
         </mat-expansion-panel-header>
         <div class="subject-details">
            <div class="subject-details__column">
               <h3 class="subject-details__header">
                  {{ 'VS.SUBJECT_DETAILS.BASE_TITLE' | translate }}
               </h3>
               <definition-list>
                  <definition-item *ngIf="subject?.registrationId" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.REGISTRATION_ID' | translate }}">
                     {{ subject.registrationId }}
                  </definition-item>
                  <definition-item *ngIf="subject?.firstName" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.FIRST_AND_LAST_NAME' | translate }}">
                     {{ subject.firstName }} {{ subject.lastName }}</definition-item>
                  <definition-item *ngIf="subject?.parentName" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.PARENT_NAME' | translate }}">
                     {{ subject.parentName }}</definition-item>
                  <definition-item *ngIf="subject?.gender" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.GENDER' | translate }}">
                     {{ subject.gender.gender }}
                  </definition-item>
                  <definition-item *ngIf="subjectDate" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.DATE_OF_BIRTH' | translate }}">
                     {{ subjectDate }}
                  </definition-item>
                  <definition-item *ngIf="subject?.jmbg" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.JMBG' | translate }}">
                     {{ subject.jmbg }}</definition-item>
                  <definition-item *ngIf="subject?.dateOfBirth" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.DATE_OF_BIRTH' | translate }}">
                     {{ subject.dateOfBirth | date:"dd.MM.yyyy." }}</definition-item>
                  <definition-item *ngIf="subject?.placeOfBirth" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.PLACE_OF_BIRTH' | translate }}">
                     {{ subject.placeOfBirth }}</definition-item>
                  <definition-item *ngIf="subject?.nationality" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.NATIONALITY' | translate }}">
                     {{ subject.nationality.title }}
                  </definition-item>
                  <definition-item *ngIf="subject?.citizenship" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.CITIZENSHIP' | translate }}">
                     {{ subject.citizenship.title }}
                  </definition-item>
                  <definition-item *ngIf="subject?.nativeLanguage" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.NATIVE_LANGUAGE' | translate }}">
                     {{ subject.nativeLanguage.title }}
                  </definition-item>
               </definition-list>
            </div>
            <div class="subject-details__column">
               <h3 class="subject-details__header">
                  {{ 'VS.SUBJECT_DETAILS.OTHER_TITLE' | translate }}
               </h3>
               <definition-list>
                  <definition-item *ngIf="subject?.language" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.NATIVE_LANGUAGE' | translate }}">
                     {{ subject.language.title }}</definition-item>
                  <definition-item *ngIf="subject?.education" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.EDUCATION' | translate }}">
                     {{ subject.education.title }}</definition-item>
                  <definition-item *ngIf="subject?.ableToWork !== null" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.ABLE_TO_WORK' | translate }}">
                     {{ (subject?.ableToWork ? 'GLOBAL.YES' : 'GLOBAL.NO') | translate }}
                  </definition-item>
                  <definition-item *ngIf="subject?.businessAbility !== null" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.BUSINESS_ABILITY' | translate }}">
                     {{ (subject?.businessAbility ? 'GLOBAL.YES' : 'GLOBAL.NO') | translate }}
                  </definition-item>
                  <definition-item *ngIf="subject?.occupation" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.OCCUPATION' | translate }}">
                     {{ subject.occupation.title }}
                  </definition-item>
                  <definition-item *ngIf="subject?.workStatus" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.WORK_STATUS' | translate }}">
                     {{ subject.workStatus }}
                  </definition-item>
                  <definition-item *ngIf="subject?.phone" [valueOffset]="labelpadding"
                     key="{{ 'NEW_SUBJECT.ADDRESS.PHONE' | translate }}">
                     {{ subject.phone }}</definition-item>
                  <definition-item *ngIf="subject?.mobile" [valueOffset]="labelpadding"
                     key="{{ 'NEW_SUBJECT.ADDRESS.MOBILE' | translate }}">
                     {{ subject.mobile }}</definition-item>
                  <definition-item [valueOffset]="labelpadding" *ngIf="subject?.permanentResidence"
                     key="{{ 'GLOBAL.SUBJECT.ORIGIN_ADDRESS_FULL' | translate }}">
                     {{ subject.permanentResidence.street}}
                     {{ subject.permanentResidence.number }}/
                     {{ subject.permanentResidence.subnumber }}
                     <br />{{ subject.permanentResidence.ptt }}
                     {{ subject.permanentResidence.town }}
                  </definition-item>
                  <definition-item *ngIf="subject?.residence" [valueOffset]="labelpadding"
                     key="{{ 'GLOBAL.SUBJECT.RESIDENCE_ADDRESS_FULL' | translate }}">
                     {{ subject.residence.street }}
                     {{ subject.residence.number }}/
                     {{ subject.residence.subnumber }}
                     <br />{{ subject.residence.ptt }}
                     {{ subject.residence.town }}
                  </definition-item>
               </definition-list>
            </div>
         </div>
      </mat-expansion-panel>
   </mat-accordion>
   <container *ngIf="teamMembersNames.length!==0" kind="default">
      <mat-card class="card">
         <definition-list>
            <definition-item *ngIf="teamMembersNames.length!==0&&workerOnCase!==null&&workerOnCase!==''"
               key="{{ 'CASE_DETAILS.WORKER' | translate }}">
               {{workerOnCase}}</definition-item>
            <definition-item *ngIf="teamMembersNames.length!==0" key="{{ 'CASE_DETAILS.TEAM_MEMBERS' | translate }}">
               {{teamMembersNames}}</definition-item>
         </definition-list>
      </mat-card>
   </container>
   <ng-container *ngIf="caseData">
      <span class="comment" *ngIf="caseData?.taskComment!==null">
         {{ "CASES.NOTE" | translate }}: {{caseData.taskComment}}
      </span>
   </ng-container>
</div>