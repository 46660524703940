/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as myLetters from '../../../../letters';
import { MAT_DATEPICKER_VALIDATORS } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

@Component({
   selector: 'new-foster-parent-suitability-step',
   templateUrl: './new-foster-parent-suitability-step.component.html',
   styleUrls: ['./new-foster-parent-suitability-step.component.scss'],
})
export class NewFosterParentSuitabilityStepComponent implements OnInit {
   @Output() suitabilityStepEmitter = new EventEmitter();
   @Input() suitabilityStep: FormGroup;
   currentDate = new Date();
   regexStrDate = '^[0-9.]+$';

   constructor(private datePipe: DatePipe, private formBuilder: FormBuilder) {}

   ngOnInit(): void {
      this.suitabilityStep = this.formBuilder.group({
         reportDates: this.formBuilder.group({
            psychologist: ['', [Validators.required]],
            pedagogue: ['', [Validators.required]],
            socialWorker: ['', [Validators.required]],
            lawyer: ['', [Validators.required]],
            doctor: ['', [Validators.required]],
            otherInstitutions: ['', [Validators.required]],
         }),
         decisionNumber: ['', [Validators.required]],
         decisionDate: ['', [Validators.required]],
      });
   }

   /**
    * Send step information to parent component
    */
   updateSuitabilityStep() {
      this.suitabilityStep
         .get('reportDates')
         .get('psychologist')
         .setValue(this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('psychologist').value).getTime(), 'yyyy-MM-dd'));
      this.suitabilityStep
         .get('reportDates')
         .get('pedagogue')
         .setValue(this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('pedagogue').value).getTime(), 'yyyy-MM-dd'));
      this.suitabilityStep
         .get('reportDates')
         .get('socialWorker')
         .setValue(this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('socialWorker').value).getTime(), 'yyyy-MM-dd'));
      this.suitabilityStep
         .get('reportDates')
         .get('lawyer')
         .setValue(this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('lawyer').value).getTime(), 'yyyy-MM-dd'));
      this.suitabilityStep
         .get('reportDates')
         .get('doctor')
         .setValue(this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('doctor').value).getTime(), 'yyyy-MM-dd'));
      this.suitabilityStep
         .get('reportDates')
         .get('otherInstitutions')
         .setValue(this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('otherInstitutions').value).getTime(), 'yyyy-MM-dd'));
      console.log('suitability information step emmiter');
      this.suitabilityStepEmitter.emit(this.suitabilityStep);
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const controlStep = fieldName === 'decisionDate' ? this.suitabilityStep : this.suitabilityStep.get('reportDates');
      const control = controlStep.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);

      if (inputDate > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const controlStep = controlName === 'decisionDate' ? this.suitabilityStep : this.suitabilityStep.get('reportDates');

      const control = controlStep?.get(controlName);
      if (!control) return;

      const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement;
      const inputValue = inputElement?.value || '';
      const dateValue = control.value;

      if (!inputValue) {
         control.setErrors({ required: true });
         return;
      }

      if (!dateValue) {
         control.setErrors({ invalidFormat: true });
         return;
      }

      if (dateValue > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      }
   }

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      }
   }
}
