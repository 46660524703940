/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Pageable } from 'src/app/pageable';
import * as printJS from 'print-js';

/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 *
 */
@Injectable({
   providedIn: 'root',
})
export class ProtegePersonService {
   constructor(private httpClient: HttpClient, private app: AppService) {}

   createProtegePerson(fosterChild: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json;',
      });
      return this.httpClient.post(this.app.getUrl() + environment.addProtegePerson, fosterChild, { headers }) as Observable<any>;
   }

   getFilteredPage(pageable: Pageable, filterValue: any): Observable<any> {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.findAllProtegePerson + '/' + pageable.pageNumber + '/' + pageable.pageSize, filterValue, { headers }) as Observable<any>;
   }

   findByIdAndCsrId(id: any, year: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.findProtegePersonByIdAndCsrId + '/' + id, { headers }) as Observable<any>;
   }

   insertIntoCollection(id: any, personDetails: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json;',
      });
      return this.httpClient.post(this.app.getUrl() + environment.addIntoCollection + '/' + id, personDetails, { headers }) as Observable<any>;
   }

   editProtegeDetails(protegeId: any, property: any, details: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json;',
      });
      return this.httpClient.put(this.app.getUrl() + environment.editProtegePerson + '/' + protegeId + '/' + property, details, { headers }) as Observable<any>;
   }

   closeCollection(year: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.closeProtegePersonCollection + '/' + year, { headers }) as Observable<any>;
   }

   exportCSV(filterValue: any) {
      const filterJSON = JSON.parse(JSON.stringify(filterValue));
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });

      this.httpClient.put(this.app.getUrl() + environment.exportProtegePersonCsvUrl, filterJSON, { responseType: 'blob', headers }).subscribe(data => {
         const parts = [];
         parts.push('\uFEFF');
         parts.push(data);
         const file = new Blob(parts, { type: 'text/csv' });
         const fileURL = URL.createObjectURL(file);
         const a = document.createElement('a');
         document.body.appendChild(a);
         a.setAttribute('style', 'display: none');
         a.href = fileURL;
         a.download = 'sticenici.csv';
         a.click();
         window.URL.revokeObjectURL(fileURL);
         a.remove();
      });
   }

   getPdf(filterValue: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });

      this.httpClient.put(this.app.getUrl() + environment.getProtegePersonReportUrl, filterValue, { responseType: 'blob', headers }).subscribe(data => {
         const file = new Blob([data], { type: 'application/pdf' });
         const fileURL = URL.createObjectURL(file);
         printJS(fileURL);
      });
   }

   terminateGuardianship(protegeId: any, terminationDetails: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json;',
      });
      return this.httpClient.put(this.app.getUrl() + environment.editProtegePerson + '/' + protegeId, terminationDetails, { headers }) as Observable<any>;
   }
   checkCollectionStatus(year: any): Observable<any> {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getStatusProtegePersonCollection + year, { headers }) as Observable<any>;
   }
}
