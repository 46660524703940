/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/local-storage.service';
import { StatisticsService } from 'src/services/statistics.service';
import { CodebookService } from 'src/services/codebook.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/services/user.service';
import { map, startWith } from 'rxjs/operators';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { ageGroupData } from 'src/app/utils/ageGroupData';

enum AutocompleteFilterEnum {
   CENTERS = 'centers',
}
@Component({
   selector: 'app-violence-stats',
   templateUrl: './violence-stats.component.html',
   styleUrls: ['./violence-stats.component.scss'],
})
export class ViolenceStatsComponent implements OnInit {
   recordControlStatsForm: FormGroup;
   centers = [];
   filteredAllCenters: Observable<any>;
   loggedIn: any;
   isMainCenterBelgrade = false;
   btnDisabled: boolean;
   isUserDrugostepeni = false;
   ageGroupOne = ageGroupData;
   shouldShowAgeGroupRange = false;

   recordOptions = [
      { id: 1, name: 'Број пријава породичног и партнерског насиља у току године према доминантној врсти насиља' },
      // { id: 2, name: 'Активни корисници којима недостаје потреба/проблем' },
   ];
   typeOfViolenceOptions = [
      { id: 1, name: 'Занемаривање' },
      { id: 2, name: 'Злостављање' },
      { id: 3, name: 'Сексуалног насиље' },
      { id: 4, name: 'Емоционално насиље' },
      { id: 5, name: 'Експлоатација детета' },
      { id: 6, name: 'Економско насиље' },
      { id: 7, name: 'Булинг - малтретирање' },
   ];
   violenceHappenedContextOptions = [
      { id: 1, name: 'Породично насиље' },
      { id: 2, name: 'Породично или партнерско насиља' },
   ];
   constructor(
      private formBuilder: FormBuilder,
      private localStorage: LocalStorageService,
      private statisticsService: StatisticsService,
      private codebookService: CodebookService,
      private translate: TranslateService,
      private userService: UserService
   ) {
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });
      this.btnDisabled = false;
      this.loggedIn = JSON.parse(this.localStorage.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.centers = result;
            this.filteredAllCenters = this.recordControlStatsForm.controls.center.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.CENTERS) : this.centers.slice()))
            );
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.centers = result;
            this.filteredAllCenters = this.recordControlStatsForm.controls.center.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.CENTERS) : this.centers.slice()))
            );
         });
      }
   }

   ngOnInit(): void {
      this.recordControlStatsForm = this.formBuilder.group({
         record: ['', [Validators.required]],
         center: [''],
         selectedYear: ['', []],
         ageGroup: ['', []],
         typeOfViolence: ['', []],
         violenceHappenedContext: ['', []],
         customAgeGroupTo: [''],
         customAgeGroupFrom: [''],
      });
      this.dynamicAgeFormUpdate();
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }
   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.recordControlStatsForm);
   }
   /**
    * Send request and generate Excell report
    */
   sendRequest(form: any) {
      if (form.value.record.id == 1) {
         let ageGroupDto = null;
         let year = null;
         ageGroupDto = form.value.ageGroup === null || form.value.ageGroup === '' ? null : form.value.ageGroup;
         year = form.value.selectedYear === '' || form.value.selectedYear === null ? null : form.value.selectedYear;
         if (ageGroupDto != null && ageGroupDto.some(e => e.id === 6)) {
            const indexToUpdate = ageGroupDto.findIndex(group => group.id === 6);
            const newAgeGroup = {
               id: 6,
               name: 'Слободан унос опсега година (' + form.value.customAgeGroupFrom + ' - ' + form.value.customAgeGroupTo + ')',
               from: form.value.customAgeGroupFrom,
               to: form.value.customAgeGroupTo,
            };
            ageGroupDto[indexToUpdate] = newAgeGroup;
         }
         const dto = {
            csrId: this.isMainCenterBelgrade || this.isUserDrugostepeni ? form.value.center.id : this.loggedIn.csrId,
            ageGroup: ageGroupDto,
            year,
            typeOfViolence: form.value.typeOfViolence.id,
            violenceHappenedContext: form.value.violenceHappenedContext.id,
         };
         let filename = '';
         this.translate.get('STATISTICS.VIOLENCE_STATS.TITLE').subscribe((res: string) => {
            filename = res;
         });
         this.statisticsService.getBasicViolenceStatistics(dto, filename);
      }
   }
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.CENTERS:
            return sortByFirstLetter(
               this.centers.filter(option => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         default:
            break;
      }
   }
   checkCustomYears() {
      if (
         this.recordControlStatsForm.value.customAgeGroupTo !== null &&
         this.recordControlStatsForm.value.customAgeGroupTo !== '' &&
         this.recordControlStatsForm.value.customAgeGroupFrom !== null &&
         this.recordControlStatsForm.value.customAgeGroupFrom !== ''
      ) {
         if (Number(this.recordControlStatsForm.value.customAgeGroupTo) < Number(this.recordControlStatsForm.value.customAgeGroupFrom)) {
            this.recordControlStatsForm.controls.customAgeGroupTo.setErrors({
               incorect: true,
            });
         } else {
            this.recordControlStatsForm.controls.customAgeGroupTo.setErrors(null);
         }
      }

      if (this.recordControlStatsForm.value.customAgeGroupTo !== null && this.recordControlStatsForm.value.customAgeGroupTo !== '') {
         if (!this.recordControlStatsForm.controls.customAgeGroupTo.hasError('incorect') && Number(this.recordControlStatsForm.value.customAgeGroupTo) > 150) {
            this.recordControlStatsForm.controls.customAgeGroupTo.setErrors({
               outOfRange: true,
            });
         } else if (!this.recordControlStatsForm.controls.customAgeGroupTo.hasError('incorect')) {
            this.recordControlStatsForm.controls.customAgeGroupTo.setErrors(null);
         }
      }
      if (this.recordControlStatsForm.value.customAgeGroupFrom !== null && this.recordControlStatsForm.value.customAgeGroupFrom !== '') {
         if (Number(this.recordControlStatsForm.value.customAgeGroupFrom) > 150) {
            this.recordControlStatsForm.controls.customAgeGroupFrom.setErrors({
               outOfRange: true,
            });
         } else {
            this.recordControlStatsForm.controls.customAgeGroupFrom.setErrors(null);
         }
      }
   }
   dynamicAgeFormUpdate() {
      this.recordControlStatsForm.controls.ageGroup.valueChanges.subscribe(ageArray => {
         if (ageArray !== null && ageArray.some(age => age.id === 6)) {
            this.shouldShowAgeGroupRange = true;
            this.recordControlStatsForm.controls.customAgeGroupFrom.setValidators(Validators.required);
            this.recordControlStatsForm.controls.customAgeGroupTo.setValidators(Validators.required);
         } else {
            if (this.shouldShowAgeGroupRange === true) {
               this.recordControlStatsForm.controls.customAgeGroupFrom.clearValidators();
               this.recordControlStatsForm.controls.customAgeGroupFrom.setValue(null);
               this.recordControlStatsForm.controls.customAgeGroupTo.clearValidators();
               this.recordControlStatsForm.controls.customAgeGroupTo.setValue(null);
               this.shouldShowAgeGroupRange = false;
            }
         }
      });
   }

   recordChange(record) {
      if (record.id == 1) {
         this.recordControlStatsForm.controls.ageGroup.setValue(null);
         this.recordControlStatsForm.controls.typeOfViolence.setValue('');
         this.recordControlStatsForm.controls.violenceHappenedContext.setValue('');
         this.recordControlStatsForm.controls.selectedYear.setValue('');
         this.recordControlStatsForm.controls.typeOfViolence.setErrors(null);
         this.recordControlStatsForm.controls.violenceHappenedContext.setErrors(null);
         this.recordControlStatsForm.controls.selectedYear.setErrors(null);
      }
      this.recordControlStatsForm.markAsUntouched();
   }
}
