/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';

@Component({
  selector: 'new-protege-termination-of-guardianship-step',
  templateUrl: './new-protege-termination-of-guardianship-step.component.html',
  styleUrls: ['./new-protege-termination-of-guardianship-step.component.scss']
})

export class NewProtegeTerminationOfGuardianshipStepComponent implements OnInit {
  @Output() terminationOfGuardianshipStepEmitter = new EventEmitter();
  @Input() terminationOfGuardianshipStep: FormGroup;
  currentDate = new Date();
  terminationOptions: any = [];
  regexStrDate = '^[0-9.]+$';

  constructor(private formBuilder: FormBuilder,
    private codebookService: CodebookService) { }

  ngOnInit(): void {
    this.terminationOfGuardianshipStep = this.formBuilder.group({
      decisionNumber: [''],
      decisionDate: [''],
      decisionName: [''],
      reasonForTermination: [''],
      terminationReasonCodebook: ['']
    });

    this.getTerminationReasonCodebook();
  }

  getTerminationReasonCodebook() {
    this.codebookService.getTerminationReasonByType('STAR').subscribe(res => {
      this.terminationOptions = [null, ...res];
    });
  }

  /**
   * Send step information to parent component
   */
  updateTerminationOfGuardianshipStep() {
    console.log('termination of guardianship information step emmiter');
    this.terminationOfGuardianshipStepEmitter.emit(this.terminationOfGuardianshipStep);
  }

  checkInputDateFormat(fieldName: string, event: any): void {
    const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

    const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

    const control = this.terminationOfGuardianshipStep.get(fieldName);
    const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
    const inputDate = new Date(year, month - 1, day);

    if (inputDate > this.currentDate) {
       control.setErrors({ maxDateExceeded: true });
    } else {
       control.setErrors(null);
    }
 }

 onBlur(controlName: string) {
  const control = this.terminationOfGuardianshipStep.get(controlName);
  if (!control) return;

  const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement;
  const inputValue = inputElement?.value || '';
  const dateValue = control.value;

  if (inputValue && !dateValue) {
    control.setErrors({ invalidFormat: true });
    return;
  }

  if (dateValue && dateValue > this.currentDate) {
    control.setErrors({ maxDateExceeded: true });
  } else {
    control.setErrors(null);
  }
}


  @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      }
   }
}
