<container>
  <page-intro-header *ngIf="guardianshipType === 'minor'" title="{{ 'NEW_PROTEGE.TITLE_MINOR' | translate }}"
    back="/proteges">
  </page-intro-header>
  <page-intro-header *ngIf="guardianshipType === 'adult'" title="{{ 'NEW_PROTEGE.TITLE_ADULT' | translate }}"
    back="/proteges">
  </page-intro-header>
  <mat-card class="card new-protege">
    <mat-vertical-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="personalInformationStep">
        <ng-template matStepLabel>{{ 'NEW_PROTEGE.PERSONAL_INFORMATION.TITLE' | translate }}</ng-template>
        <new-protege-personal-information-step [protegePersonId]="protegePersonId" [subjectId]="subjectId"
          [personalInformationStep]="personalInformationStep" (personalInformationStepEmitter)="
            updatePersonalInformationStep($event)
          "></new-protege-personal-information-step>
      </mat-step>
      <mat-step [stepControl]="propertyStep">
        <ng-template matStepLabel> {{ 'NEW_PROTEGE.PROPERTY.TITLE' | translate }}</ng-template>
        <new-protege-property-step [propertyStep]="propertyStep" (propertyStepEmitter)="updatePropertyStep($event)">
        </new-protege-property-step>
      </mat-step>
      <mat-step [stepControl]="guardianStep">
        <ng-template matStepLabel>{{ 'NEW_PROTEGE.GUARDIAN.TITLE' | translate }}</ng-template>
        <new-protege-guardian-step [guardianStep]="guardianStep" [protege]="personalInformationStep.value"
          [guardianshipType]="guardianshipType" (guardianStepEmitter)="updateGuardianStep($event)">
        </new-protege-guardian-step>
      </mat-step>
      <mat-step [stepControl]="guardianStep">
        <ng-template matStepLabel>{{ 'NEW_PROTEGE.TERMINATION_OF_GUARDIANSHIP.TITLE' | translate }}</ng-template>
        <new-protege-termination-of-guardianship-step [terminationOfGuardianshipStep]="terminationOfGuardianshipStep"
          (terminationOfGuardianshipStepEmitter)="updateTerminationOfGuardianshipStep($event)"></new-protege-termination-of-guardianship-step>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card>
</container>