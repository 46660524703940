import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CaseService } from 'src/services/case.service';
import { DatePipe } from '@angular/common';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { TranslateService } from '@ngx-translate/core';
import { CaseStatus, ModalSizeEnum } from 'src/types';
import { Page } from 'src/app/page';
import { CodebookService } from 'src/services/codebook.service';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/local-storage.service';
import { RightsHistoryDialogComponent } from 'src/app/rights-history-dialog/rights-history-dialog.component';
import { MatDialog } from '@angular/material/dialog';

enum AutocompleteFilterEnum {
  SUBCENTER = 'subcenter',
}

@Component({
  selector: 'app-admin-cases',
  templateUrl: './admin-cases.component.html',
  styleUrls: ['./admin-cases.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminCasesComponent implements OnInit {
  page: Page<any> = new Page();
  filter: FormGroup;
  text: string[] = [];
  centerId: any;
  appliedTextFilters: string[] = [];
  subcenters = [];
  subcenter = new FormControl();
  filteredSubcenters: Observable<any>;
  areLoaded: boolean = true;
  displayedColumns: string[] = [
    'case',
    'centerName',
    'subjectId',
    'subjectInfo',
    'status',
    'userInfo',
    'username',
    'officeName',
    'centerOfficeName',
    'actions',
  ];

  constructor(
    private paginationService: CustomPaginationService,
    private caseService: CaseService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private translateService: TranslateService,
    private codebookService: CodebookService,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog
  ) {
    this.codebookService.getCsrCodebook().subscribe((result) => {
      this.subcenters = result;
      this.filteredSubcenters = this.subcenter.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((title) =>
          title
            ? this._filter(title, AutocompleteFilterEnum.SUBCENTER)
            : this.subcenters.slice()
        )
      );
      if (this.text.length > 0) {
        this.getCases();
      }
    });
  }
  translateStatus(status: any) {
    if (status === CaseStatus.NOT_ASSIGNED) {
      this.translateService
        .get('CASES.STATUSES.NOT_ASSIGNED')
        .subscribe((res: string) => {
          status = res;
        });
    } else if (status === CaseStatus.ASSIGNED) {
      this.translateService
        .get('CASES.STATUSES.ASSIGNED')
        .subscribe((res: string) => {
          status = res;
        });
    } else if (status === CaseStatus.ACCEPTED) {
      this.translateService
        .get('CASES.STATUSES.ACCEPTED')
        .subscribe((res: string) => {
          status = res;
        });
    } else if (status === CaseStatus.CLOSED) {
      this.translateService
        .get('CASES.STATUSES.CLOSED')
        .subscribe((res: string) => {
          status = res;
        });
    } else if (status === CaseStatus.FINISHED_PROCESSING) {
      this.translateService
        .get('CASES.STATUSES.FINISHED_PROCESSING')
        .subscribe((res: string) => {
          status = res;
        });
    } else if (status === CaseStatus.COMPLAIN) {
      this.translateService
        .get('CASES.STATUSES.COMPLAIN')
        .subscribe((res: string) => {
          status = res;
        });
    } else if (status === CaseStatus.SECOND_DEGREE) {
      this.translateService
        .get('CASES.STATUSES.SECOND_DEGREE')
        .subscribe((res: string) => {
          status = res;
        });
    }
    return status;
  }
  ngOnInit(): void {
    this.filter = this.formBuilder.group({
      searchValue: ['', []],
    });
  }

  public getNextPage(): void {
    this.page.pageable = this.paginationService.getNextPage(this.page);
    this.getCases();
  }

  public getPreviousPage(): void {
    this.page.pageable = this.paginationService.getPreviousPage(this.page);
    this.getCases();
  }
  public getFirstPage(): void {
    this.page.pageable = this.paginationService.getFirstPage(this.page);
    this.getCases();
  }

  public getLastPage(): void {
    this.page.pageable = this.paginationService.getLastPage(this.page);
    this.getCases();
  }

  public getPageInNewSize(pageSize: number): void {
    this.page.pageable = this.paginationService.getPageInNewSize(
      this.page,
      pageSize
    );
    this.getCases();
  }

  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.name : objectValue;
  }

  onEnterClickAddFilter() {
    this.text.push(this.filter.get('searchValue').value);
    this.appliedTextFilters.push(this.filter.get('searchValue').value);
    this.getCases();
    this.filter.reset();
  }

  getCases() {
    this.areLoaded = false;
    this.caseService
      .findCaseByCaseNumber(
        this.page.pageable,
        this.text.map((text) => text.trim()),
        this.centerId ? this.centerId : ''
      )
      .subscribe((page) => {
        this.page = page;
        this.page.pageable.pagePerShow = page.number + 1;
        this.areLoaded = true;
      });
  }

  removeTextFilter(textFilter: any) {
    this.text = this.text.filter((item) => item !== textFilter);
    this.appliedTextFilters = this.appliedTextFilters.filter(
      (item) => item !== textFilter
    );
    this.getCases();
  }

  reloadCases(event: any) {
    this.centerId = event?.id;
    this.getCases();
  }

  checkInput() {
    if (this.subcenter.value === '') {
      this.centerId = null;
      this.getCases();
    }
  }
  checkSubject(item: any) {
    this.localStorageService.set('localStorageCenterId', item.centerId);
    window.open(environment.getFamilyMemberTab + item.subjectId, '_blank');
  }
  checkCase(item: any) {
    this.localStorageService.set('localStorageCenterId', item.centerId);
    window.open(
      environment.getNewCaseTab +
        item.id +
        '/' +
        item.subjectId +
        '/' +
        item.centerId,
      '_blank'
    );
  }
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();
    switch (what) {
      case AutocompleteFilterEnum.SUBCENTER:
        return this.subcenters.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
      default:
        break;
    }
  }

  openRightsHistoryComponent(item: any) {
    const dataObject = {
       subjectId:  item.subjectId,
       csrId: item.centerId,
       type: 'NSP'
    };

    this.dialog.open(RightsHistoryDialogComponent, {
       panelClass: 'overlay-panel',
       disableClose: false,
       width: ModalSizeEnum.EXTRA_LARGE,
       data: dataObject,
    });
 }
}
