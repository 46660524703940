<mat-card class="card">
    <field-holder kind="3-cols" *ngIf="isAdmin">
        <mat-form-field appearance="outline">
            <mat-label>
                {{'CASE_HISTORY_BY_RIGHT.RIGHT' | translate}}
            </mat-label>
                <mat-select [(ngModel)]="type" (selectionChange)="getFirstPage()"  [compareWith]="compareRights">
                <mat-option *ngFor="let right of rightsTypes" [value]="right.type">
                    {{ right.name}}
                </mat-option>
            </mat-select>
        </mat-form-field></field-holder>
    <div class="table-responsive-wrapper">
        <table mat-table id="myTable" class="history__table" #myTable [dataSource]="page.content">
            <ng-container matColumnDef="caseNumber">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CASE_HISTORY_BY_RIGHT.CASE_NUMBER' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.caseNumber }}
                </td>
            </ng-container>
            <ng-container matColumnDef="decisionType">
                <th mat-header-cell *matHeaderCellDef>{{ 'CASE_HISTORY_BY_RIGHT.DECISION' | translate }}</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    {{element?.decisionType!=null? decisionOptions[element?.decisionType-1].name:""}}
                </td>
            </ng-container>
            <ng-container matColumnDef="previousCaseNumber">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CASE_HISTORY_BY_RIGHT.CASE_NUMBER_PREVIOUS' | translate }}</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    {{ element?.previousCaseNumber }}
                </td>
            </ng-container>
            <ng-container matColumnDef="previousDecisionType">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CASE_HISTORY_BY_RIGHT.DECISION_PREVIOUS' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element?.previousDecisionType!=null? decisionOptions[element?.previousDecisionType-1].name :""}}
                </td>
            </ng-container>
            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CASE_HISTORY_BY_RIGHT.ACTIVE' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">{{ element?.active? "Да":"Не" }}</td>
            </ng-container>
            <ng-container matColumnDef="validFrom">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CASE_HISTORY_BY_RIGHT.VALID_FROM' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.validFrom | date:"dd.MM.yyyy."}}
                </td>
            </ng-container>
            <ng-container matColumnDef="validTo">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CASE_HISTORY_BY_RIGHT.VALID_TO' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.validTo | date:"dd.MM.yyyy."}}
                </td>
            </ng-container>
            <ng-container matColumnDef="terminationDate">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CASE_HISTORY_BY_RIGHT.TERMINATION_DATE' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.terminationDate | date:"dd.MM.yyyy." }}
                </td>
            </ng-container>
            <ng-container matColumnDef="imported">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CASE_HISTORY_BY_RIGHT.IMPORT' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.imported ? "Да":"Не"}}
                </td>
            </ng-container>
            <ng-container matColumnDef="caseStatus">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CASE_HISTORY_BY_RIGHT.CASE_STATUS' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="status-column">
                    <div class="status-column-content">
                        <div>
                            {{translateStatus(element.caseStatus)}} </div>
                    </div>
                </td>
            </ng-container>
            <!-- <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="table__cell table__cell--align-right">
                <button mat-icon-button class="button-square table__row-button" color="primary"
                    matTooltip="{{ 'SUBJECTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
                    #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
                    <mat-icon class="button-square__icon">more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="filter-menu">
                 
                </mat-menu>
            </td>
        </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="subjects__row--header"></tr>
            <!-- <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" (click)="onRowClicked(element)"
            class="subjects__row">
        </tr> -->
            <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" class="subjects__row">
            </tr>
        </table>
    </div>
    <div class="pagination" *ngIf="page.content.length > 0">
        <app-custom-pagination [page]="page" (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()"
            (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()" (pageSizeEvent)="getPageInNewSize($event)">
        </app-custom-pagination>
    </div>

    <p class="subject-case-empty" *ngIf="page.content.length === 0">
        <mat-icon class="subject-case-empty__icon subject-case-empty__icon--empty">info</mat-icon>
        {{ 'SUBJECT_CASES.NO_RESULTS' | translate }}
    </p>
</mat-card>