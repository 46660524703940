<container kind="midi">
   <case-page-header (newCaseStatus)="updateStatus($event)" (handleComplainAccepted)="acceptedComplain()"
      [isLawyer]="isLawyer" *ngIf="caseData" [caseData]="caseData" [caseId]="caseId" [subjectId]="subjectId"
      [teamMember]="teamMember" [caseName]="caseName" (saveEvent)="saveCurrentData()"
      (newProblem)="problemAdded($event)" [rightType]="'DPN'">
   </case-page-header>
   <page-intro-header title="{{title}}"></page-intro-header>

   <mat-card class="card" *ngIf="isLawyer && teamMember===false">
      <form [formGroup]="dpnRequestForm" class="form">
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.DECISION_TYPE' | translate}}
               </mat-label>
               <mat-select formControlName="decisionType" [compareWith]="compareLoadedObjects" required
                  (selectionChange)="ShowPreviewButton($event.value)">
                  <mat-option *ngFor="let dpnType of decisionTypes" [value]="dpnType">
                     {{ dpnType.title}}
                  </mat-option>
               </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.DATE_CASE_CREATION' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerDateOfCaseCreation" formControlName="dateOfCaseCreation"
                  [max]="maxDate" autocomplete="off" />
               <mat-datepicker-toggle matSuffix [for]="pickerDateOfCaseCreation"></mat-datepicker-toggle>
               <mat-datepicker #pickerDateOfCaseCreation></mat-datepicker>
            </mat-form-field>

            <mat-radio-group aria-label="Select an option" color="primary" formControlName="decisionMaker"
               class="form__radio-group form__radio-group--2-cols">
               <span class="form__radio-group-label">
                  {{ 'LAWYER.DECISION_MAKER' | translate }}
               </span><br>
               <mat-radio-button *ngFor="let option of decisionMakerOptions" [value]="option"
                  (change)="decisionMakerSelected($event)">
                  {{ option.title }}
               </mat-radio-button>
            </mat-radio-group>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline" *ngIf="dpnRequestForm.value.decisionMaker?.id === 2">
               <mat-label>{{ 'LAWYER.DECISION_MAKER_DESCRIPTION' | translate }}</mat-label>
               <textarea matInput type="text" formControlName="decisionMakerDescription" autocomplete="off"
                  matTooltip="{{'LAWYER.DECISION_MAKER_DESCRIPTION_TOOLTIP'| translate}}">
               </textarea>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'LAWYER.DECISION_MAKER_TITLE' | translate }}</mat-label>
               <input matInput type="text" formControlName="decisionMakerTitle" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'LAWYER.DECISION_MAKER_NAME' | translate }}
               </mat-label>
               <mat-select formControlName="decisionMakerName" [compareWith]="compareDecisionMakerNameObjects" required>
                  <mat-option *ngFor="let signature of signatures" [value]="signature">
                     {{ signature }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-checkbox color="primary" class="form__checkbox" formControlName="inAccordanceWithDecision"
               *ngIf="dpnRequestForm.get('decisionType').value?.id === 1">
               {{ 'DPN.IN_ACCORDANCE_WITH_DECISION' | translate }}
            </mat-checkbox>
         </field-holder>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.INITIATOR' | translate}}
               </mat-label>
               <mat-select formControlName="initiator" [compareWith]="compareLoadedObjects">
                  <mat-option *ngFor="let init of initiatorCodebook" [value]="init">
                     {{ init.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="dpnRequestForm.get('decisionType')?.value?.id === 1">
               <mat-label>
                  {{'DPN.PAYMENT_METHOD' | translate}}
               </mat-label>
               <mat-select formControlName="paymentMethod" [compareWith]="compareLoadedObjects"
                  (selectionChange)="paymentMethodChanged()">
                  <mat-option *ngFor="let paymentMethod of paymentMethodCodebook" [value]="paymentMethod">
                     {{ paymentMethod.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline"
               *ngIf="dpnRequestForm.get('decisionType')?.value?.id === 1 &&
                 (dpnRequestForm.get('paymentMethod').value !== null && dpnRequestForm.get('paymentMethod').value?.id !==1 && dpnRequestForm.get('paymentMethod').value?.id !==6)">
               <mat-label>
                  {{'CASES.ACCOUNT_NUMBER' | translate}}
               </mat-label>
               <input matInput type="text" formControlName="account" autocomplete="off"
                  [required]="dpnRequestForm.get('decisionType')?.value?.id === 1 && 
                 (dpnRequestForm.get('paymentMethod').value !== null && dpnRequestForm.get('paymentMethod').value !== '' && dpnRequestForm.get('paymentMethod').value?.id !==1&& dpnRequestForm.get('paymentMethod').value?.id !==2 && dpnRequestForm.get('paymentMethod').value?.id !==6)"
                  placeholder="{{ 'ACCOUNT_NUMBER_FORMAT' | translate }}" [textMask]="mask" />
            </mat-form-field>
         </field-holder>

         <field-holder kind="2-cols-1-2">
            <mat-form-field appearance="outline" *ngIf="dpnRequestForm.get('decisionType').value?.id === 1">
               <mat-label>
                  {{'DPN.SUB_KIND' | translate}}
               </mat-label>
               <mat-select formControlName="subKind" [required]="showPreview"
                  (selectionChange)="changeSubKindCodebook()">
                  <mat-option *ngFor="let subCase of subCaseKind" [value]="subCase.id">
                     {{ subCase.code }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="dpnRequestForm.get('decisionType').value?.id === 1">
               <mat-label>
                  {{'DPN.SUB_KIND_RIGHT' | translate}}
               </mat-label>
               <mat-select formControlName="subKindRight" [required]="showPreview"
                  (selectionChange)="changeSubKindRightCodebook()">
                  <mat-option *ngFor="let dpnRight of dpnRights" [value]="dpnRight">
                     {{ dpnRight.description }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>

         <ng-container *ngIf="dpnRequestForm.get('decisionType').value?.id === 1">

            <mat-checkbox (change)="changeChildCheckbox()" color="primary" class="form__checkbox"
               formControlName="child" *ngIf="dpnRequestForm.get('decisionType').value?.id === 1">
               {{ 'DPN.CHILD' | translate }}
            </mat-checkbox>
            <br>
            <div class="button-add-holder" *ngIf="child==true && dpnRequestForm.get('decisionType').value?.id === 1">
               <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
                  <mat-icon>person_add</mat-icon>
                  {{ "GLOBAL.IMPORT_SUBJECT_REIMBURSEMENT" | translate }}
               </button>
            </div>
            <br>
            <field-holder kind="4-cols" *ngIf="child==true && dpnRequestForm.get('decisionType').value?.id === 1">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'CASES.PAYMENT_RECIPIENT' | translate}}
                  </mat-label>
                  <mat-select formControlName="paymentRecipient" [compareWith]="compareLoadedObjects">
                     <mat-option></mat-option>
                     <mat-option *ngFor="let paymentRecipient of paymentRecipients" [value]="paymentRecipient">
                        {{ paymentRecipient.title}}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.PARENT.FIRST_NAME' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentFirstName" autocomplete="off" />
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.PARENT.LAST_NAME' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentLastName" autocomplete="off" />
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.REG_BR' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentRegBr" autocomplete="off" />
               </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols" *ngIf="child==true && dpnRequestForm.get('decisionType').value?.id === 1">
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.PARENT.RESIDENCE_ADDRESS' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentResidenceAddress" autocomplete="off" />
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.PARENT.ADDRESS' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentAddress" autocomplete="off" />
               </mat-form-field>

               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.PARENT.ADDRESS_BR' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentAddressNum" autocomplete="off" />
               </mat-form-field>
            </field-holder>
         </ng-container>
         <form-subgroup-divider></form-subgroup-divider>
         <div *ngIf="kind!==null && kind!==undefined&& kind !== 1 && kind !== 2">
            <span class="form__radio-group-label">
               {{'DPN.PIO_CONCLUSION_TITLE' | translate}}
            </span>

            <field-holder kind="3-cols">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{ 'DPN.PIO_DECISION' | translate }}
                  </mat-label>
                  <input type="text" matInput formControlName="pioDecision" [matAutocomplete]="autoPioDecision"
                     (focusout)="CheckConclusions(true)" [required]="pioDecisionRequred" />
                  <mat-autocomplete #autoPioDecision="matAutocomplete" [displayWith]="displayCustomFormat">
                     <mat-option *ngFor="let option of pioDecisionsFiltered | async" [value]="option">
                        {{option.branchTypeCodebook.title}} {{ option.name }}
                     </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="dpnRequestForm.get('pioDecision').hasError('randomInput')">
                     {{ 'GLOBAL.AUTOCOMPLETE_ERROR' | translate }}
                  </mat-error>
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{ 'DPN.PIO_NUM_DECISION' | translate }}
                  </mat-label>
                  <input matInput type="text" maxlength="250" formControlName="pioNumDecision" autocomplete="off"
                     (focusout)="CheckConclusions(false)" [required]="pioDecisionRequred" />
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'DPN.PIO_DATE_DECISION' | translate}}
                  </mat-label>
                  <input matInput [matDatepicker]="pickerPioDateDecision" formControlName="pioDateDecision"
                     [max]="maxDate" autocomplete="off" (focusout)="CheckConclusions(false)"
                     [required]="pioDecisionRequred" />
                  <mat-datepicker-toggle matSuffix [for]="pickerPioDateDecision" (focusout)="CheckConclusions(false)">
                  </mat-datepicker-toggle>
                  <mat-datepicker #pickerPioDateDecision></mat-datepicker>
               </mat-form-field>
            </field-holder>
         </div>
         <span class="form__radio-group-label">
            {{'DPN.PIO_FINDING_TITLE' | translate}}
         </span>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'DPN.PIO_DECISION' | translate }}</mat-label>
               <input type="text" matInput formControlName="pioFinding" [matAutocomplete]="autoPioFinding"
                  (focusout)="CheckFindings(true)" [required]="pioFindingRequred" />
               <mat-autocomplete #autoPioFinding="matAutocomplete" [displayWith]="displayCustomFormat">
                  <mat-option *ngFor="let option of pioFindingsFiltered | async" [value]="option">
                     {{option.branchTypeCodebook.title}} {{ option.name }}
                  </mat-option>
               </mat-autocomplete>
               <mat-error *ngIf="dpnRequestForm.get('pioFinding').hasError('randomInput')">
                  {{ 'GLOBAL.AUTOCOMPLETE_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'DPN.PIO_NUM_FINDING' | translate }}</mat-label>
               <input matInput type="text" maxlength="250" formControlName="pioNumFinding" autocomplete="off"
                  (focusout)="CheckFindings(false)" [required]="pioFindingRequred" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.PIO_DATE_FINDING' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerPioDateFinding" [required]="pioFindingRequred" [max]="maxDate"
                  formControlName="pioDateFinding" autocomplete="off" (focusout)="CheckFindings(false)" />
               <mat-datepicker-toggle matSuffix [for]="pickerPioDateFinding" (focusout)="CheckFindings(false)">
               </mat-datepicker-toggle>
               <mat-datepicker #pickerPioDateFinding></mat-datepicker>
            </mat-form-field>
         </field-holder>
         <form-subgroup-divider></form-subgroup-divider>
         <ng-container>
            <field-holder>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{ 'DPN.DISPOZITIV_ADDITION' | translate }}
                  </mat-label>
                  <input type="text" matInput formControlName="dispozitivAddition" />
               </mat-form-field>
            </field-holder>
         </ng-container>

         <form-subgroup-divider></form-subgroup-divider>
         <field-holder kind="4-cols" *ngIf="dpnRequestForm.get('decisionType').value?.id === 1">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.FROM_DATE' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerFromDate" formControlName="fromDate" [max]="maxDate"
                  autocomplete="off" />
               <mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
               <mat-datepicker #pickerFromDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.TO_DATE' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerToDate" formControlName="toDate" [max]="maxDate"
                  autocomplete="off" />
               <mat-datepicker-toggle matSuffix [for]="pickerToDate"></mat-datepicker-toggle>
               <mat-datepicker #pickerToDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'DPN.AMOUNT' | translate }}</mat-label>
               <input matInput type="text" formControlName="amount" autocomplete="off" id="amount" />
            </mat-form-field>
         </field-holder>
         <ng-container *ngIf="dpnRequestForm.get('decisionType').value !== null">
            <field-holder>
               <mat-form-field appearance="outline"
                  *ngIf="dpnRequestForm.get('decisionType').value?.id !== undefined && dpnRequestForm.get('decisionType').value?.id !== 1
                  && dpnRequestForm.get('decisionType').value?.id !== 6 && dpnRequestForm.get('decisionType').value?.id !== 3">
                  <mat-label>
                     {{ 'GLOBAL.DECISION_FOR_CASE_'+dpnRequestForm.get('decisionType').value?.id | translate }}
                  </mat-label>
                  <input matInput type="text" formControlName="terminationOfRights" autocomplete="off" maxlength="255"
                     id="terminationOfRights" />
               </mat-form-field>
            </field-holder>
            <field-holder>
               <mat-form-field appearance="outline"
                  *ngIf="dpnRequestForm.get('decisionType').value?.id !== undefined && dpnRequestForm.get('decisionType').value?.id === 3">
                  <mat-label>
                     {{ 'GLOBAL.DECISION_FOR_CASE_'+dpnRequestForm.get('decisionType').value?.id | translate }}
                  </mat-label>
                  <mat-select formControlName="terminationReason" [compareWith]="compareLoadedObjects" required>
                     <mat-option *ngFor="let reason of terminationReasons" [value]="reason">
                        {{ reason.title}}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
            </field-holder>
         </ng-container>
         <ng-container class="aside" *ngIf="showPreview === false">
            <button mat-flat-button color="primary" type="button" [matMenuTriggerFor]="menu"
               #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
               {{ 'LAWYER.DOCUMENT' | translate }}<mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="filter-menu">
               <ng-container *ngFor="let docOptions of documentOptions">
                  <button mat-menu-item (click)="handleNewEvaluation(docOptions)">{{ docOptions.name }}</button>
               </ng-container>
            </mat-menu>

            <input type="file" #file accept=".docx, .pdf" (change)="handleFileInput($event.target.files)" hidden>
            <button class="header__add-document" style="margin-left: 15px;" mat-flat-button color="primary"
               type="submit" (click)="file.click()">
               {{ 'LAWYER.CREATE' | translate }}
            </button>
            <br>
            <field-holder>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'LAWYER.SOLUTION' | translate }}</mat-label>
                  <input matInput type="text" formControlName="fileName" readonly required autocomplete="off" />
               </mat-form-field>
            </field-holder>
            <field-holder *ngIf="dpnRequestForm.get('decisionType').value?.id === 3" kind="4-cols">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'DPN.FROM_DATE' | translate}}
                  </mat-label>
                  <input matInput [matDatepicker]="pickerFromDate" formControlName="fromDate" autocomplete="off"
                     [max]="maxDate" />
                  <mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFromDate></mat-datepicker>
               </mat-form-field>
            </field-holder>
         </ng-container>

         <form-footer class="footer">
            <span matTooltip="{{checkIfSendIsDisabled().msg}}" [matTooltipDisabled]="checkIfSendIsDisabled().disabled">
               <span matTooltip="{{'CASES.NO_REGISTER_NUMBER' | translate }}"
                  [matTooltipDisabled]="(checkIfSendIsDisabled().disabled === false && noRegistrationId === true) || noRegistrationId === false">
                  <span matTooltip="{{'CASES.COMPLAIN_RESPOND_NEEDED' | translate }}"
                     [matTooltipDisabled]="!caseData?.newComplain">
                     <button mat-flat-button color="primary" type="button"
                        (click)="sendRequest(); submitButton.disabled = true" #submitButton
                        class="form-footer__button form-footer__button--secondary"
                        [disabled]="(!dpnRequestForm?.valid || enableConclusion!==true)|| caseData?.newComplain || !hasProblem">
                        {{ 'DPN.SEND' | translate }}
                     </button>
                  </span>
               </span>
            </span>
            <span class="btn-footer">
               <button *ngIf="showPreview" mat-flat-button color="primary" type="button"
                  [disabled]="!dpnRequestForm.valid" (click)="printPreview()">
                  <mat-icon>visibility</mat-icon>
                  {{ "PRINT.PREVIEW" | translate }}
               </button>
            </span>
            <mat-progress-spinner [diameter]="45" *ngIf="isSubmited" color="primary" mode="indeterminate">
            </mat-progress-spinner>
         </form-footer>
      </form>
   </mat-card>
   <mat-card *ngIf="teamMember">
      <team-member [editable]="enableConclusion" [caseId]="caseId" [subjectId]="subjectId"></team-member>
   </mat-card>
</container>