import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CaseService } from 'src/services/case.service';
import { SubjectsService } from 'src/services/subjects.service';
import { MatDialog } from '@angular/material/dialog';
import { CodebookService } from 'src/services/codebook.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { OzrCaseService } from 'src/services/ozr-case.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { environment } from 'src/environments/environment';
import { AlfrescoService } from 'src/services/alfresco.service';
import { UserService } from 'src/services/user.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { CaseProblemRecordService } from 'src/services/case-problem-record.service';
import { ExpeditionService } from 'src/services/expedition.service';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { ModalSizeEnum } from 'src/types';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { fileCheck } from 'src/app/utils/fileInputUtil';

@Component({
   selector: 'app-ozr',
   templateUrl: './ozr.component.html',
   styleUrls: ['./ozr.component.scss'],
})
export class OzrComponent implements OnInit {
   title: string;

   dateOfCaseCreation: Date;
   caseName: any;
   caseId: any;
   subjectId: any;
   isValid: any;
   caseData: any;
   subject: any;
   ozrForm: FormGroup;
   paymentMethodCodebook: any;
   initiatorCodebook: any;
   kind: any;
   subCaseKind: any;
   right: any;
   decisionTypes: [];
   isLawyer: any = false;
   enableConclusion = false;
   dateOfDecisionCreation: any;
   loadedData: any;
   isTrainingCosts = false;
   isAccommodationCosts = false;
   isTransportCosts = false;
   noRegistrationId = false;
   loggedIn: any;
   teamMember = false;
   fileToUpload: File = null;
   documentOptions: any;
   isSubmited = false;
   @ViewChild('file') file: ElementRef<HTMLInputElement>;
   @ViewChild('submitButton') submitButton: any;
   terminationReasons: any = [];

   public mask = {
      guide: false,
      showMask: false,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/],
   };
   decisionMakerOptions: any;
   hasProblem = false;
   maxDate: Date = new Date(2999, 11, 31);

   constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private caseService: CaseService,
      private cdr: ChangeDetectorRef,
      private subjectService: SubjectsService,
      public dialog: MatDialog,
      private codebookService: CodebookService,
      private ozrCaseService: OzrCaseService,
      private authentication: AuthenticationService,
      private translate: TranslateService,
      private localStorageService: LocalStorageService,
      private alfrescoService: AlfrescoService,
      private toastr: ToastrImplService,
      private route: ActivatedRoute,
      private userService: UserService,
      private expedition: ExpeditionService,
      private caseProblemRecordService: CaseProblemRecordService
   ) {
      this.isLawyer = this.userService.isUserLawyer();
      this.route.params.subscribe((params: any) => {
         this.caseId = params.id;
         this.subjectId = params.subjectId;
         const [_, caseType] = this.router.url.split('/cases/');
         if (caseType !== '') {
            this.caseName = caseType.split('/')[0];
         }
         this.isValid = this.subjectId !== undefined || this.subjectId !== null;
         if (this.isValid) {
            this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
            this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
            this.initializationOfForm();
            this.caseService.getSocialCase(this.caseId).subscribe(result => {
               this.caseData = result;
               this.subject = this.caseData.subject;
               if (this.subject.registrationId === null || this.subject.registrationId === '') {
                  this.noRegistrationId = true;
                  this.enableConclusion = false;
                  this.toastr.info('CASES.NO_REGISTER_NUMBER');
               }
               this.getDocumentsByTemplate();

               if (this.loggedIn?.id !== this.caseData.assignedUserId) {
                  this.teamMember = true;
               } else {
                  this.teamMember = false;
               }
               if (this.caseData.caseStatus === 'ACCEPTED') {
                  this.enableConclusion = true;
               } else {
                  this.enableConclusion = false;
               }

               this.getCaseKindViewCodebook(this.caseData.socialCaseClassificationCodebook.caseKindViewCodebook.kind, this.caseData.dateOfCreation);
               this.title = this.caseData.socialCaseClassificationCodebook.title;
               this.codebookService.getDecisionTypeCodebook().subscribe(result1 => {
                  this.decisionTypes = result1;
                  if (this.title.toLowerCase().includes('престанак')) {
                     this.decisionTypes.splice(0, 1);
                  }
               });
               this.caseHasProblem();
               this.showConditionalFields();
            });
            this.ozrCaseService.findOzrCaseById(this.caseId).subscribe(result => {
               this.loadedData = result;
               if (this.loadedData !== undefined && this.loadedData !== null) {
                  this.patchValues();
               }
            });
         }
      });
   }

   ngOnInit(): void {
      this.getInitiatorCodebook();
      this.getPaymentMethodCodebook();
      this.initializationOfForm();
      this.getDecisionMakerCodebook();
   }

   initializationOfForm() {
      this.ozrForm = this.formBuilder.group({
         lawyerFile: [''],
         fileName: ['', [Validators.required]],
         lawyerComment: [''],
         caseId: this.caseId,
         dateOfCaseCreation: [''],
         dateOfDecisionCreation: new FormControl({ value: new Date(), disabled: true }),
         validFrom: ['', []],
         decisionType: ['', [Validators.required]],
         paymentMethod: ['', []],
         initiator: [''],
         subjectId: this.subjectId,
         terminationOfRights: [''],
         transportRoute: [''],
         refusalReason: [''],
         institutionName: [''],
         account: [''],
         decisionMaker: [''],
         decisionMakerDescription: [],
         decisionMakerName: [''],
         decisionMakerTitle: [''],
         terminationReason: [''],
      });
   }
   checkIfSendIsDisabled() {
      return this.caseProblemRecordService.checkIfSendIsDisabled(this.caseData, !this.ozrForm.valid, this.hasProblem);
   }
   showConditionalFields() {
      if (this.caseData.socialCaseClassificationCodebook.caseKindViewCodebook.subkind === 1) {
         this.isTrainingCosts = true;
      } else if (this.caseData.socialCaseClassificationCodebook.caseKindViewCodebook.subkind === 2) {
         this.isAccommodationCosts = true;
      } else if (this.caseData.socialCaseClassificationCodebook.caseKindViewCodebook.subkind === 3) {
         this.isTransportCosts = true;
      }
   }

   updateStatus(newStatus: string) {
      this.caseData.caseStatus = newStatus;
      this.enableConclusion = true;
      this.caseHasProblem();
      this.cdr.detectChanges();
      if (this.subject.registrationId === null || this.subject.registrationId === '') {
         this.noRegistrationId = true;
         this.enableConclusion = false;
         this.toastr.info('CASES.NO_REGISTER_NUMBER');
      }
   }

   getDocumentsByTemplate() {
      this.alfrescoService.getDocumentTemplates(this.caseData.socialCaseClassificationCodebook.oldKindMark).subscribe(result => {
         this.documentOptions = result;
      });
   }

   getPaymentMethodCodebook() {
      this.codebookService.getPaymentMethodCodebook().subscribe(res => {
         this.paymentMethodCodebook = res;
      });
   }

   getInitiatorCodebook() {
      this.codebookService.getInitiatorCodebook().subscribe(res => {
         this.initiatorCodebook = res;
      });
   }

   getCaseKindViewCodebook(kind: any, dateOfCreation: any) {
      this.ozrForm.controls.dateOfCaseCreation.setValue(new Date(dateOfCreation));
      this.ozrForm.controls.dateOfCaseCreation.disable();
      this.codebookService.getCaseKindViewCodebookByKind(kind).subscribe(res => {
         this.subCaseKind = res;
      });
   }

   getDecisionMakerCodebook() {
      this.codebookService.getDecisionMakerCodebook().subscribe(result => {
         this.decisionMakerOptions = result;
         if (!this.loadedData?.socialCase?.decisionMaker) {
            this.ozrForm.controls.decisionMaker.setValue(result[0]);
            this.ozrForm.controls.decisionMakerTitle.setValue(result[0].title);
         } else {
            this.ozrForm.controls.decisionMaker.setValue(result[this.loadedData?.socialCase?.decisionMaker.id - 1]);
            this.ozrForm.controls.decisionMakerTitle.setValue(this.loadedData?.socialCase?.decisionMakerTitle);
         }
      });
   }

   createOzrObject() {
      const ozrCase = {
         id: this.loadedData ? this.loadedData.id : null,
         socialCaseId: this.caseData.id,
         dateOfDecisionCreation: new Date(),
         decisionTypeCodebook: this.ozrForm.value.decisionType !== '' ? this.ozrForm.value.decisionType : null,
         initiatorCodebook: this.ozrForm.value.initiator !== '' ? this.ozrForm.value.initiator : null,
         paymentMethodCodebook: this.ozrForm.value.paymentMethod !== '' ? this.ozrForm.value.paymentMethod : null,
         validFrom: this.ozrForm.value.validFrom !== '' ? this.ozrForm.value.validFrom : null,
         terminationOfRights: this.ozrForm.value.terminationOfRights,
         transportRoute: this.ozrForm.value.transportRoute,
         refusalReason: this.ozrForm.value.refusalReason,
         institutionName: this.ozrForm.value.institutionName,
         accountNumber: this.ozrForm.value.account,
         decisionMaker: this.ozrForm.value.decisionMaker ? this.ozrForm.value.decisionMaker : null,
         decisionMakerDescription: this.ozrForm.value.decisionMaker?.id === 2 ? this.ozrForm.value.decisionMakerDescription : '',
         decisionMakerName: this.ozrForm.value.decisionMakerName ? this.ozrForm.value.decisionMakerName : '',
         decisionMakerTitle: this.ozrForm.value.decisionMakerTitle ? this.ozrForm.value.decisionMakerTitle : '',
         terminationReason: this.ozrForm.value.terminationReason !== '' ? this.ozrForm.value.terminationReason : null,
      };
      return ozrCase;
   }

   patchValues() {
      this.ozrForm = this.formBuilder.group({
         dateOfDecisionCreation: new FormControl({ value: new Date(), disabled: true }),
         dateOfCaseCreation: new FormControl({ value: new Date(this.loadedData.socialCase.dateOfCreation), disabled: true }),
         validFrom: this.loadedData.validFrom !== null ? new Date(this.loadedData.validFrom.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : this.loadedData.validFrom,
         decisionType: this.loadedData.decisionTypeCodebook,
         paymentMethod: this.loadedData.paymentMethodCodebook,
         initiator: this.loadedData.initiatorCodebook,
         subjectId: [''],
         terminationOfRights: this.loadedData.terminationOfRights,
         transportRoute: this.loadedData.transportRoute,
         refusalReason: this.loadedData.refusalReason,
         institutionName: this.loadedData.institutionName,
         account: this.loadedData.socialCase.accountNumber,
         lawyerFile: [''],
         fileName: ['', [Validators.required]],
         lawyerComment: [''],
         caseId: this.caseId,
         decisionMaker: this.decisionMakerOptions === undefined ? null : this.decisionMakerOptions[this.loadedData.socialCase.decisionMaker.id - 1],
         decisionMakerName: this.loadedData.socialCase.decisionMakerName,
         decisionMakerTitle: this.loadedData.socialCase.decisionMakerTitle,
         decisionMakerDescription: this.loadedData.socialCase.decisionMakerDescription,
         terminationReason: this.loadedData.terminationReason,
      });
      this.checkValidation(this.loadedData.decisionTypeCodebook);
   }

   compareLoadedObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
   }

   handleNewEvaluation(doc: any) {
      //    window.location.href = 'ms-word:ofe|u|' + url;

      const paymentTypeData = this.ozrForm.controls.paymentMethod.value !== '' && this.ozrForm.controls.paymentMethod.value !== undefined ? this.ozrForm.controls.paymentMethod.value.title : null;
      const body = {
         documentId: doc.documentId,
         fileName: doc.name,
         caseId: this.caseId === undefined ? null : this.caseId,
         paymentType: paymentTypeData,
         accountNumber: this.ozrForm.value.account,
      };
      this.alfrescoService.downloadDocumentByUrl(body);
   }

   handleFileInput(files: FileList) {
      const modifiedFiles = fileCheck(files, this.dialog, this.translate);
      if (modifiedFiles.length > 0) {
         this.fileToUpload = modifiedFiles[0];
         this.ozrForm.get('lawyerFile').setValue(this.fileToUpload);
         this.ozrForm.get('fileName').setValue(this.fileToUpload.name);
      }
      this.file.nativeElement.value = null;
   }

   async submitConclusion() {
      this.isSubmited = true;
      const decisionTypeId = this.ozrForm.value.decisionType.id;
      const ozrCase = { ...this.createOzrObject(), isTempSave: false };
      const addNewRecordRequered = await this.addNewRecord(decisionTypeId);
      if (addNewRecordRequered) {
         this.ozrCaseService.saveOzrCase(ozrCase).subscribe(
            (ozrCaseId: number) => {
               if (ozrCaseId) {
                  this.caseService.addConclusionFinishCase(this.ozrForm.get('lawyerFile').value, this.subjectId, this.caseData.id).subscribe(
                     (documentId: string) => {
                        this.toastr.success('SNACKBAR.DOCUMENT_ADDED');
                        this.localStorageService.set('case', true);
                        this.expedition.send({ documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'ozr');
                     },
                     error => {
                        manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
                        this.enableSubmitButton();
                     }
                  );
               } else {
                  if (decisionTypeId === 1) {
                     this.toastr.warning('SNACKBAR.USER_ALREADY_HAVE_RIGHT_OZR');
                  } else if (decisionTypeId === 3) {
                     this.toastr.warning('SNACKBAR.USER_NOT_HAVE_RIGHT_OZR');
                  }
                  this.enableSubmitButton();
               }
            },
            error => {
               manageError(this.toastr, ErrorType.SAVE_DATA);
               this.enableSubmitButton();
            }
         );
      } else {
         this.enableSubmitButton();
      }
   }

   saveCurrentData() {
      const dpnRequest = { ...this.createOzrObject(), isTempSave: true };
      this.ozrCaseService.saveOzrCase(dpnRequest).subscribe(
         (ozrCaseId: number) => {
            if (ozrCaseId) {
               this.loadedData = { ...this.loadedData, id: ozrCaseId };
               this.toastr.success('SNACKBAR.SOLUTION_SAVED');
               this.ozrForm.markAsPristine();
            } else {
               const decisionTypeId = this.ozrForm.value.decisionType.id;
               if (decisionTypeId === 1) {
                  this.toastr.warning('SNACKBAR.USER_ALREADY_HAVE_RIGHT_OZR');
               } else if (decisionTypeId === 3) {
                  this.toastr.warning('SNACKBAR.USER_NOT_HAVE_RIGHT_OZR');
               }
            }
         },
         _ => {
            manageError(this.toastr, ErrorType.SAVE_DATA);
         }
      );
   }

   checkValidation(decision: any) {
      this.paymentMethodChanged();
      if (decision.id === 3) {
         this.ozrForm.controls.terminationReason.enable();
         this.ozrForm.controls.terminationReason.setValidators(Validators.required);
         if (this.terminationReasons.length == 0) {
            this.codebookService.getTerminationReasonByType('OPSTI').subscribe(res => {
               this.terminationReasons = res;
            });
         }
      } else {
         this.ozrForm.controls.terminationReason.disable();
         this.ozrForm.controls.terminationReason.setErrors(null);
         this.ozrForm.controls.terminationReason.setValue(null);
         this.ozrForm.controls.terminationReason.clearValidators();
      }
   }
   paymentMethodChanged() {
      if (this.ozrForm.value.paymentMethod === null || this.ozrForm.value.paymentMethod.id === 1 || this.ozrForm.value.paymentMethod.id === 6) {
         this.ozrForm.controls.account.setValue('');
         this.ozrForm.controls.account.clearValidators();
         this.ozrForm.controls.account.setErrors(null);
         this.ozrForm.controls.account.disable();
      } else {
         this.ozrForm.controls.account.enable();
         if (this.ozrForm.value.paymentMethod.id !== 2) {
            this.ozrForm.controls.account.setValidators(Validators.required);
         } else {
            this.ozrForm.controls.account.clearValidators();
            this.ozrForm.controls.account.setErrors(null);
         }
      }
   }

   acceptedComplain() {
      this.caseData.newComplain = false;
   }

   decisionMakerSelected(event: any) {
      if (event.value.id === 2) {
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TEXT').subscribe((res: string) => {
            this.ozrForm.controls.decisionMakerDescription.setValue(res);
         });
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TITLE').subscribe((res: string) => {
            this.ozrForm.controls.decisionMakerTitle.setValue(res);
         });
      } else {
         this.ozrForm.controls.decisionMakerDescription.setValue('');
         this.ozrForm.controls.decisionMakerTitle.setValue(event.value.title);
      }
   }

   private enableSubmitButton() {
      this.submitButton.disabled = false;
      this.isSubmited = false;
   }

   problemAdded(added: boolean) {
      if (added) {
         this.hasProblem = true;
         this.cdr.detectChanges();
      }
   }
   caseHasProblem() {
      this.caseProblemRecordService.caseHasProblem(this.caseData?.caseStatus, this.caseId, this.caseData?.caseKindCodebook?.kind).subscribe(hasProblem => {
         this.hasProblem = hasProblem;
      });
   }

   async addNewRecord(decision: any): Promise<boolean> {
      if (decision === 1) {
         try {
            return await this.caseProblemRecordService.addNewRecord(this.subjectId, this.caseId, this.caseData?.caseKindCodebook?.kind, {
               fromDate: this.ozrForm.controls.validFrom.value,
               amount: '',
            });
         } catch (error) {
            return false;
         }
      }
      return true;
   }
   async saveChanges(): Promise<boolean> {
      if (this.isSubmited || this.ozrForm.pristine) {
         return true;
      }
      let message = '';
      let title = '';
      this.translate.get('WARNING_DIALOG.SAVE_DATA.TITLE').subscribe((res: string) => {
         title = res;
      });
      this.translate.get('WARNING_DIALOG.SAVE_DATA.MESSAGE').subscribe((res: string) => {
         message = res;
      });
      const object = {
         document: null,
         message: message,
         title: title,
         saveData: true,
      };

      const dialogRef = this.dialog.open(WarningMessageComponent, {
         data: object,
         width: ModalSizeEnum.MINI,
         panelClass: 'overlay-panel',
      });
      await dialogRef
         .afterClosed()
         .toPromise()
         .then(res => {
            if (res?.event !== 'cancel' && res !== undefined) {
               return this.saveCurrentData();
            }
         });
      return true;
   }
}
