/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/services/authentication.service';
import { Subject, Subscription, interval, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'src/services/app.service';
import { environment } from 'src/environments/environment';
import { SystemInfo } from './utils/systemInfo';
import { VersionCheckService } from 'src/services/version-check.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
   shortcuts: ShortcutInput[] = [];
   isLoggedIn = false;
   unsubscribe$: Subject<void> = new Subject();
   unsubscribeLogin$: Subject<void> = new Subject();
   timerSubscription: Subscription;
   loginTimerSubscription: Subscription;
   public loggedUser: any;
   isSpecificUser = false;
   isGlobalAdmin = false;
   endTime = environment.endTime;
   refreshTime = null;
   moduleOpen = false;
   // inicijalno fiksno vreme
   initialTime = 59;
   // vreme u sekundama za prikaz upozorenja kod isteka sesije, menjati po potrebi
   time: number = this.initialTime;
   intervalSubscription: Subscription;
   timer: any;
   milisecondsUntilTimeout: any;
   constructor(
      titleService: Title,
      private http: HttpClient,
      private appService: AppService,
      translate: TranslateService,
      private authentication: AuthenticationService,
      private router: Router,
      private localStorageService: LocalStorageService,
      private versionCheckService: VersionCheckService
   ) {
      translate.setDefaultLang('sr-Cyrl');
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/sr-Latn|sr-Cyrl/) ? browserLang : 'sr-Cyrl');
      translate.onLangChange.subscribe(() => {
         translate.get('APP').subscribe((res: string) => {
            titleService.setTitle(res);
         });
      });

      if (window.addEventListener) {
         window.addEventListener('storage', this.listener1, false);
      }
   }

   private listener1 = (event: StorageEvent) => {
      if (event.key === 'milisecondsUntilTimeout') {
         this.startTimer();
         return;
      } else {
         this.router.navigateByUrl('/').then(() => {
            window.location.reload();
         });
      }
   };

   ngOnInit(): void {
      this.versionCheckService.initVersionCheck('assets/version/version.json', 1800000); // checks every 30 minutes

      this.isLoggedIn = this.authentication.checkCredentials();
      this.loggedUser = this.localStorageService.get('loggedUser');
      const loggedUserAsObject = JSON.parse(this.loggedUser);
      if (loggedUserAsObject?.role.includes('USR-11')) {
         this.isSpecificUser = true;
      } else if (loggedUserAsObject?.role.includes('USR-12')) {
         this.isGlobalAdmin = true;
      }
      if (this.isLoggedIn) {
         this.setRefreshTimeout();
         this.authentication.connectWebsocket();
         Notification.requestPermission().then(result => {
            console.log(result);
         });
      }
   }
   closeWarning() {
      this.moduleOpen = false;
      this.refreshTokenBeforeTimeout();
   }

   calculateMilisecondsUntilTimeout() {
      const tokenStr = this.authentication.getJwtToken();
      const token = JSON.parse(atob(tokenStr.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
      const expDate = new Date(token.exp * 1000);
      const date = new Date();
      const untilTimeout = expDate.getTime() - date.getTime();
      // 1minute = 60000, 12minutes = 720000, 14minutes = 840000, 14.5minutes = 870000
      const milisecondsUntilTimeout = untilTimeout - (this.initialTime + 1) * 1000;
      return milisecondsUntilTimeout;
   }

   setRefreshTimeout() {
      this.milisecondsUntilTimeout = this.calculateMilisecondsUntilTimeout();
      this.localStorageService.set('milisecondsUntilTimeout', this.milisecondsUntilTimeout.toString());
      this.startTimer();
   }
   startTimer() {
      const milisecondsUntilTimeout: any = localStorage.getItem('milisecondsUntilTimeout');
      const newTime = parseInt(milisecondsUntilTimeout, 10);
      clearTimeout(this.timer);
      this.intervalSubscription?.unsubscribe();
      this.time = this.initialTime;
      this.timer = setTimeout(() => {
         this.moduleOpen = true;
         this.startCountdown(this.initialTime);
      }, newTime);

      if (!this.isLoggedIn) {
         clearTimeout(this.timer);
      }
   }
   startCountdown(initialTime: number) {
      this.intervalSubscription = interval(1000)
         .pipe(
            take(initialTime),
            map(count => initialTime - count)
         )
         .subscribe(seconds => {
            this.time = seconds - 1;
            if (this.time < 1) {
               this.authentication.logout();
            }
         });
   }
   refreshTokenBeforeTimeout() {
      const refreshToken = this.localStorageService.get('refresh_token');
      this.http.post<any>(this.appService.getUrl() + environment.refreshTokenUrl, { refreshToken }).subscribe(result => {
         if (result !== null) {
            this.authentication.saveToken(result);
            this.authentication.resetLoginTimer();
            this.setRefreshTimeout();
         } else {
            this.authentication.cleanStorage();
            this.unsubscribeLogin$.next();
            this.unsubscribeLogin$.complete();
            this.authentication.logoutNavigate();
         }
      });
   }

   // tslint:disable-next-line: use-lifecycle-interface
   ngOnDestroy() {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.intervalSubscription.unsubscribe();
      window.removeEventListener('storage', this.listener1, false);
   }

   // Stop timer
   stopTimer() {
      this.unsubscribeLogin$.next();
      this.unsubscribeLogin$.complete();
   }

   @HostListener('document:visibilitychange', [])
   checkTimer() {
      if (this.moduleOpen) {
         const milisecondsUntilTimeout = this.calculateMilisecondsUntilTimeout();
         const timeForCheck = Math.floor(+milisecondsUntilTimeout / 1000) + this.initialTime + 1;
         if (timeForCheck < 0) {
            clearTimeout(this.timer);
            this.intervalSubscription?.unsubscribe();
            this.time = 0;
            this.authentication.refreshTokenBeforeTimeout();
         } else if (timeForCheck < this.time) {
            clearTimeout(this.timer);
            this.intervalSubscription?.unsubscribe();
            this.intervalSubscription = undefined;
            this.time = timeForCheck;
            this.startCountdown(timeForCheck);
         }
      }
   }
   @HostListener('document:keyup', [])
   @HostListener('document:click', [])
   @HostListener('document:wheel', [])
   resetTimer() {
      this.authentication.notifyUserAction();
      const timeoutString = this.localStorageService.get('timeout');
      const timeout = new Date(timeoutString);
      if (timeoutString !== null) {
         const currentDate = new Date();
         if (currentDate > timeout && !this.moduleOpen) {
            this.authentication.refreshTokenBeforeTimeout();
         }
      }
   }
}
