/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as printJS from 'print-js';
import { Observable } from 'rxjs';
import { Page } from 'src/app/page';
import { Pageable } from 'src/app/pageable';
import { Problem } from 'src/models/problem.model';
import { RightService } from 'src/models/rightService.model';
import { Subject } from 'src/models/subject.model';
import { environment } from './../environments/environment';
import { AppService } from './app.service';
import { ToastrImplService } from './toastr.service';

@Injectable({
   providedIn: 'root',
})
export class SubjectsService {
   constructor(private httpClient: HttpClient, private app: AppService, private toastr: ToastrImplService) {}

   getFilteredPage(pageable: Pageable, filterValue: string, csrId: string, nextPage?: boolean): Observable<Page<Subject>> {
      if (filterValue === '') {
         const headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
         });
         return this.httpClient.get(this.app.getUrl() + environment.getPaginationUrl + pageable.pageNumber + '/' + pageable.pageSize + '/' + csrId, {
            headers,
         }) as Observable<any>;
      } else {
         const headers = new HttpHeaders({
            'Content-type': 'application/json',
         });
         const filterJSON = JSON.parse(JSON.stringify(filterValue));
         let nextPagePar = false;
         if (nextPage !== null && nextPage !== undefined) {
            nextPagePar = true;
         }
         return this.httpClient.post(this.app.getUrl() + environment.getFilterPaginationUrl + pageable.pageNumber + '/' + pageable.pageSize + '/' + csrId + '/' + nextPagePar, filterJSON, {
            headers,
         }) as Observable<any>;
      }
   }

   getFilteredProblemPage(pageable: Pageable, subjectId: any, filterValue: string, csrId: any): Observable<Page<Problem>> {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPaginationProblemUrl + subjectId + '/' + pageable.pageNumber + '/' + pageable.pageSize + '/' + csrId, {
         headers,
      }) as Observable<any>;
   }

   getFilteredProblemByCaseIdPage(pageable: Pageable, caseId: any): Observable<Page<Problem>> {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPaginationProblemByCaseUrl + caseId + '/' + pageable.pageNumber + '/' + pageable.pageSize, {
         headers,
      }) as Observable<any>;
   }

   getFilteredRightByCaseIdPage(pageable: Pageable, caseId: any): Observable<Page<Problem>> {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPaginationRightByCaseUrl + caseId + '/' + pageable.pageNumber + '/' + pageable.pageSize, {
         headers,
      }) as Observable<any>;
   }

   getFilteredRightPage(pageable: Pageable, subjectId: any, filterValue: string, csrId: any): Observable<Page<RightService>> {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPaginationRightUrl + subjectId + '/' + pageable.pageNumber + '/' + pageable.pageSize + '/' + csrId, { headers }) as Observable<any>;
   }

   findCaseNumbersForSubject(subjectId: any): Observable<Page<Problem>> {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.findCaseNumbersForSubject + subjectId, { headers }) as Observable<any>;
   }

   getHistoryPage(pageable: Pageable, subjectId: any, csrId: any): Observable<Page<Object>> {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getHistoryUrl + subjectId + '/' + pageable.pageNumber + '/' + pageable.pageSize + '/' + csrId, { headers }) as Observable<any>;
   }

   removeSubject(id: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.delete(this.app.getUrl() + environment.removeUrl + '/' + id, { headers }) as Observable<any>;
   }

   getSubject(id: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getSubjectUrl + '/' + id, { headers }) as Observable<any>;
   }

   getSubjects(data: any, pageable: Pageable) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      let params = new HttpParams().set('subjectId', data?.subjectId).set('regId', data?.regId).set('jmbg', String(data?.jmbg));
      params.keys().forEach(key => {
         const value = params.get(key);
         if (value === null || value === undefined || value === '') {
            params['map'].delete(key);
         }
      });

      return this.httpClient.get(this.app.getUrl() + environment.getSubjects + data.csrId + '/' + pageable.pageNumber + '/' + pageable.pageSize, {
         headers,
         params,
      }) as Observable<any>;
   }

   changeRegId(csrId: any, subjectId: any, newRegId: any, oldRegId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      const body = { oldRegId, newRegId, csrId, subjectId };
      return this.httpClient.put(this.app.getUrl() + environment.changeRegId, body, { headers }) as Observable<any>;
   }
   checkJmbg(jmbg: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.checkJmbgUrl + jmbg, { headers }) as Observable<any>;
   }

   getCurrentSubject(id: any, csrId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCurrentSubjectUrl + id + '/' + csrId, { headers }) as Observable<any>;
   }

   getFamilyMembers(id: any, csrId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFamilyMembersUrl + id + '/' + csrId, { headers }) as Observable<any>;
   }

   addFamilyMember(familyMember: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.addFamilyMemberUrl, familyMember, { headers }) as Observable<any>;
   }

   addSubject(subject: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.addUrl, subject, { headers }) as Observable<any>;
   }

   getRegisterId(csrId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getRegisterId + csrId, { headers }) as Observable<any>;
   }

   archiveSubject(id: string | number, archiveData: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.archiveSubjectUrl + id, archiveData, { headers }) as Observable<any>;
   }

   unarchiveSubject(id: string | number, unarchiveData: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.unarchiveSubjectUrl + id, unarchiveData, { headers }) as Observable<any>;
   }

   exportCSV(filterValue: any, csrId: any, filename) {
      const filterJSON = JSON.parse(JSON.stringify(filterValue));
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });

      this.httpClient.put(this.app.getUrl() + environment.exportCsvUrl + '/' + csrId, filterJSON, { responseType: 'blob', headers }).subscribe(data => {
         const parts = [];
         parts.push('\uFEFF');
         parts.push(data);
         const file = new Blob(parts, { type: 'text/csv' });
         const fileURL = URL.createObjectURL(file);
         const a = document.createElement('a');
         document.body.appendChild(a);
         a.setAttribute('style', 'display: none');
         a.href = fileURL;
         a.download = filename + '.csv';
         a.click();
         window.URL.revokeObjectURL(fileURL);
         a.remove();
      });
   }

   addDossierOwner(subjectId: any, ownerId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.addDossierOwnerUrl + subjectId + '/' + ownerId, { headers }) as Observable<any>;
   }

   async downloadExternalInfo(data1: any): Promise<boolean> {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      try {
         const data = await this.httpClient.post(this.app.getUrl() + environment.downloadExternalInfo, data1, { responseType: 'blob', headers }).toPromise();
         const file = new Blob([data], { type: 'application/pdf' });
         const fileURL = URL.createObjectURL(file);
         printJS(fileURL);
      } catch (error) {
         if (error.status == 409) {
            const message = JSON.parse(await error.error.text()).message;
            this.toastr.showInfo(message);
         } else {
            this.toastr.error('SIDEBAR_WIDGET.OTHER_INSTITUTIONS.ERROR_RESPONSE');
         }
      } finally {
         return false;
      }
   }

   getFamilyMembersCount(id: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFamilyMembersCount + id, { headers }) as Observable<any>;
   }

   setFamilyMemberCarrier(id: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.setFamilyMemberCarrier + id, { headers }) as Observable<any>;
   }

   removeFamilyMember(id: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.removeFamilyMember + id, { headers }) as Observable<any>;
   }

   printPdf(printDto: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });

      this.httpClient.put(this.app.getUrl() + environment.printSubjectPdfUrl, printDto, { responseType: 'blob', headers }).subscribe(data => {
         const file = new Blob([data], { type: 'application/pdf' });
         const fileURL = URL.createObjectURL(file);
         printJS(fileURL);
      });
   }

   getCountOfRegisteredSubjects(center: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.getCountRegisteredOnCenter + center, { headers }) as Observable<any>;
   }

   getFamilyMembersDossierDto(subjectId: any, memberId: any, csrId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFamilyMembersDossierDtoUrl + subjectId + '/' + memberId + '/' + csrId, {
         headers,
      }) as Observable<any>;
   }

   insertSubjectInSK(data: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.insertSubjectSK + caseId, data, { headers }) as Observable<any>;
   }

   getSubjectStatus(subjectId: any, csrId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getSubjectStatus + subjectId + '/' + csrId, { headers }) as Observable<any>;
   }
}
