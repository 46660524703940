/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { CodebookService } from 'src/services/codebook.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalSizeEnum, Subject } from 'src/types';
import * as myLetters from './../../letters';

interface YesNoType {
   value: boolean;
   viewValue: string;
}

interface FamilyDossier {
   value: string;
   viewValue: string;
}

enum AutocompleteFilterEnum {
   RESIDENCE_PLACE = 'residencePlace',
   RESIDENCE_AREA = 'residenceArea',
   RESIDENCE_PTT_NUMBER = 'residencePttNumber',
   RESIDENCE_ADDRESS = 'residenceAddress',
   ORIGIN_PLACE = 'originPlace',
   ORIGIN_AREA = 'originArea',
   ORIGIN_PTT_NUMBER = 'originPttNumber',
   ORIGIN_ADDRESS = 'originAddress',
   NATIONALITY = 'nationality',
   CATEGORY = 'category', // kategorija lica (drzavljanstvo)
   CITIZENSHIP = 'citizenship',
   NATIVE_LANGUAGE = 'nativeLanguage',
   OCCUPATION = 'occupation',
   EDUCATION = 'education',
   EMPLOYMENT = 'employment',
   MARITAL_STATUS = 'maritalStatus',
}
@Component({
   selector: 'subject-form',
   templateUrl: './subject-form.component.html',
   styleUrls: ['./subject-form.component.scss'],
   providers: [DatePipe],
})
export class SubjectFormComponent implements OnInit, OnChanges {
   @ViewChild('submitButton') submitButton: any;
   newSubjectForm: FormGroup;
   residenceMatchOriginAddress = true;
   requiredCopyAddress = false;
   subjectId: any;
   subject: Subject;
   familyDossierId: any;
   checkDiffAddress = false;
   currentDate = new Date();
   @Input() errors: ValidationErrors;
   @Input() registrationId: any;
   @Input() importedSubjectData: any;
   fammilyMembers = 0;
   isSavePossible = true;

   registeredOnCenter = false;
   possibleToRegisterOnCenter = true;
   tooltipText: string = '';

   regNumber: any;
   loggedIn: any;
   oldJmbg: string;
   // provera da li je clan ili nosilac porodice
   isCarrierOrMember: boolean;
   public keyword = 'title';

   object: { origin: string };

   cities = [];
   maxDate: Date = new Date(2999, 11, 31);

   // Pol korisnika (? dobavljanje iz šifarnika)
   genderOptions = [];
   // Oblik lisenja
   deprivationExtents = [];
   // Nacionalnost
   nationalityOptions = []; // šifarnik
   filteredNationalityOptions: Observable<any[]>;

   // Državljanstvo
   citizenshipOptions = []; // šifarnik
   filteredCitizenshipOptions: Observable<any[]>;

   // Maternji jezik
   nativeLanguageOptions = []; // šifarnik
   filteredNativeLanguageOptions: Observable<any[]>;

   // Skolska sprema
   educationOptions = []; // šifarnik
   filteredEducationOptions: Observable<any[]>;

   // Radni status
   employmentOptions = []; // šifarnik
   filteredEmploymentOptions: Observable<any[]>;

   // Nivo obrazovanja
   levelOfEducation = []; // šifarnik
   schoolAttendingCodebook = []; // šifarnik

   // Kategorija lica - drzavljanstvo
   categoryOptions = [];
   filteredCategoryOptions: Observable<any[]>;

   yesNoOptions = [
      { value: true, viewValue: 'GLOBAL.YES' },
      { value: false, viewValue: 'GLOBAL.NO' },
   ];

   // Zanimanje
   occupationOptions = []; // šifarnik
   filteredOccupationOptions: Observable<any[]>;

   // Status dosijea (da li je korisnik nosilac ili ĝlan porodiĝnog dosijea)
   familyDossier: FamilyDossier[] = [{ value: 'CARRIER', viewValue: 'Носилац' }];
   familyDossierStatus: any;

   familyType = [];

   // Bracni status
   maritalStatusOptions = []; // šifarnik

   // Mesto stanovanja/boravišta
   placeOptions: string[] = []; // šifarnik
   filteredOriginPlaceOptions: Observable<string[]>;
   filteredResidencePlaceOptions: Observable<string[]>;

   // Mesna zajednica stanovanja/boravišta
   areaOptions: string[] = []; // šifarnik
   areaOptionsOrigin: string[] = [];
   filteredAreaOptions: Observable<string[]>;
   filteredAreaOptionsOrigin: Observable<string[]>;

   // PTT broj
   pttNumberOptions = []; // šifarnik
   filteredOriginPttNumberOptions: Observable<string[]>;
   filteredResidencePttNumberOptions: Observable<string[]>;

   // Tip naselja
   AreaType = [];

   // Adresa stanovanja/boravišta
   addressOptions: string[] = []; // ? šifarnik
   originAddressOptions: string[] = [];
   residenceAddressOptions: string[] = [];
   filteredOriginAddressOptions: Observable<string[]>;
   filteredResidenceAddressOptions: Observable<string[]>;
   typeOfInputRegNum = false;

   // work status out of range for work
   workstatus = false;
   /*
     Olja Andjelovski olja.andjelovski@iten.rs
     Polje koje nam oznacava da li je disable-ovano polje Datum upisa u registar na formi.
     Ovo polje je false, sve dok se ne ucita neki postojeci korisnik.
     U bloku koda this.subjectId !== undefined se setuje na true, jer tada znamo da je to korisnik
     koji je postojeci i da radimo izmenu podataka.
   */

   isDisabledRegistrationDate = false;
   @Input() isEditMode = false;
   @Output() emitUpdatedSubject = new EventEmitter();
   recommendedRegNum: number;
   constructor(
      private formBuilder: FormBuilder,
      public subjectService: SubjectsService,
      private router: Router,
      private route: ActivatedRoute,
      public dialog: MatDialog,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private localStorageService: LocalStorageService,
      private codebookService: CodebookService,
      private datePipe: DatePipe,
      private translate: TranslateService,
      private toastr: ToastrImplService,
      private cdr: ChangeDetectorRef
   ) {
      this.object = { ...data };
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));

      this.route.params.subscribe(params => {
         this.subjectId = params.id;
      });

      this.getCodebooks();
   }

   getCodebooks() {
      this.codebookService.getGenderCodebook().subscribe(result => {
         this.genderOptions = result;
      });

      this.codebookService.getCityCodebook().subscribe(result => {
         this.cities = result;
         for (const city of result) {
            this.placeOptions.push(city.name);
            this.pttNumberOptions.push(city['ptt-number']);
         }

         this.filteredOriginPlaceOptions = this.newSubjectForm.controls.originPlace.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, AutocompleteFilterEnum.ORIGIN_PLACE))
         );

         this.filteredResidencePlaceOptions = this.newSubjectForm.controls.residencePlace.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, AutocompleteFilterEnum.RESIDENCE_PLACE))
         );
      });

      this.codebookService.getTypeOfSettlementsCodebook().subscribe(res => (this.AreaType = res));

      this.codebookService.getLevelOfEducationCodebook().subscribe(result => {
         this.levelOfEducation = result;
      });
      this.codebookService.getSchoolAttendingCodebook().subscribe(result => {
         this.schoolAttendingCodebook = result;
      });
      this.codebookService.getNationalityCodebook().subscribe(result => {
         this.nationalityOptions = result;
         this.filteredNationalityOptions = this.newSubjectForm.controls.nationality.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? value : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.NATIONALITY) : this.nationalityOptions.slice()))
         );
      });

      this.codebookService.getPersonCategoryCodebook().subscribe(result => {
         this.categoryOptions = result;
         this.filteredCategoryOptions = this.newSubjectForm.controls.category.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? value : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CATEGORY) : this.categoryOptions.slice()))
         );
      });

      this.codebookService.getFamilyTypeCodebook().subscribe(result => {
         this.familyType = result;
      });

      this.codebookService.getCitizenshipCodebook().subscribe(result => {
         this.citizenshipOptions = result;
         this.filteredCitizenshipOptions = this.newSubjectForm.controls.citizenship.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? value : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice()))
         );
      });

      this.codebookService.getLanguageCodebook().subscribe(result => {
         this.nativeLanguageOptions = result;
         this.filteredNativeLanguageOptions = this.newSubjectForm.controls.nativeLanguage.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? value : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.NATIVE_LANGUAGE) : this.nativeLanguageOptions.slice()))
         );
      });

      this.codebookService.getEducationCodebook().subscribe(result => {
         this.educationOptions = result;
         this.filteredEducationOptions = this.newSubjectForm.controls.education.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? value : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.EDUCATION) : this.educationOptions.slice()))
         );
      });

      this.codebookService.getOccupationCodebook().subscribe(result => {
         this.occupationOptions = result;
         this.filteredOccupationOptions = this.newSubjectForm.controls.occupation.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? value : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION) : this.occupationOptions.slice()))
         );
      });

      this.codebookService.getEmploymentCodebook().subscribe(result => {
         this.employmentOptions = result;
         this.filteredEmploymentOptions = this.newSubjectForm.controls.employment.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? value : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.EMPLOYMENT) : this.employmentOptions.slice()))
         );
      });
      this.codebookService.getMaritalStatusCodebook().subscribe(result => {
         this.maritalStatusOptions = result;
      });

      this.codebookService.getDeprivationExtentCodebook().subscribe(result => {
         this.deprivationExtents = result;
      });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes.importedSubjectData) {
         if (this.newSubjectForm !== undefined) {
            this.newSubjectForm.patchValue({
               firstName: this.importedSubjectData.externalIntegrationsSubjectInfo.ime,
               lastName: this.importedSubjectData.externalIntegrationsSubjectInfo.prezime,
               parentName: this.importedSubjectData.externalIntegrationsSubjectInfo.imeRoditelja1,
               jmbg: this.importedSubjectData?.externalIntegrationsSubjectInfo.jmbg,
               gender:
                  this.importedSubjectData.externalIntegrationsSubjectInfo.polSifra !== null
                     ? this.importedSubjectData.externalIntegrationsSubjectInfo.polSifra === 'M'
                        ? this.genderOptions[0]
                        : this.genderOptions[1]
                     : null,
               dateOfBirth: this.importedSubjectData.externalIntegrationsSubjectInfo.datumRodjenja !== null ? new Date(this.importedSubjectData.externalIntegrationsSubjectInfo.datumRodjenja) : null,
               yearOfBirth:
                  this.importedSubjectData?.externalIntegrationsSubjectInfo?.datumRodjenja !== null ? this.importedSubjectData?.externalIntegrationsSubjectInfo?.datumRodjenja.split('-')[0] : null,
            });
            if (this.newSubjectForm.value.firstName !== null) {
               this.newSubjectForm.controls.firstName.markAsTouched();
            }
            if (this.newSubjectForm.value.lastName !== null) {
               this.newSubjectForm.controls.lastName.markAsTouched();
            }
            if (this.newSubjectForm.value.parentName !== null) {
               this.newSubjectForm.controls.parentName.markAsTouched();
            }
            if (this.newSubjectForm.value.gender !== null) {
               this.newSubjectForm.controls.gender.markAsTouched();
            }

            if (this.newSubjectForm.value.dateOfBirth !== null) {
               this.newSubjectForm.controls.dateOfBirth.markAsTouched();
            }

            if (this.newSubjectForm.value.yearOfBirth !== null) {
               this.newSubjectForm.controls.yearOfBirth.markAsTouched();
            }
         }
      }
   }
   // Init
   ngOnInit(): void {
      // Form builder
      this.newSubjectForm = this.formBuilder.group({
         firstName: [this.subject && this.subject.jmbg, [Validators.pattern(myLetters.letters), Validators.required]],
         lastName: [null, [Validators.pattern(myLetters.letters), Validators.required]],
         parentName: [null, [Validators.pattern(myLetters.letters)]],
         gender: [null, []],
         dateOfBirth: [null, []],
         yearOfBirth: [null, [Validators.minLength(4), Validators.maxLength(4), Validators.max(this.currentDate.getFullYear()), Validators.pattern(myLetters.dateBirthValidator)]],
         jmbg: [null, Validators.compose([Validators.maxLength(13), Validators.pattern('^[0-9]*$')])],
         familyType: [null],
         placeOfBirth: [null, [Validators.pattern(myLetters.letters)]],
         nationality: [null, []],
         category: [null, []],
         citizenship: [
            null,
            [
               /*, this.valueSelected(this.citizenshipOptions)*/
            ],
         ],
         nativeLanguage: [
            null,
            [
               /*, this.valueSelected(this.nativeLanguageOptions)*/
            ],
         ],
         disabledPerson: [null, []],
         disabilityType: [{ value: null, disabled: true }, []],
         ableToWork: [null, []],
         occupation: [
            null,
            [
               /*, this.valueSelected(this.occupationOptions)*/
            ],
         ],
         employment: [
            null,
            [
               /*, this.valueSelected(this.educationOptions)*/
            ],
         ],
         education: [
            null,
            [
               /*, this.valueSelected(this.employmentOptions)*/
            ],
         ],
         levelOfEducation: [null],
         schoolAttending: [null],
         businessAbility: [null, []],
         familyDossier: [null, []],
         jmbp: [null, Validators.compose([Validators.minLength(13), Validators.maxLength(13), Validators.pattern('^[0-9]*$')])],
         residenceId: [null],
         residencePlace: [null, !this.residenceMatchOriginAddress ? [this.valueSelected(this.placeOptions)] : []],
         residenceArea: [null, []],
         residencePttNumber: [{ value: null, disabled: true }, !this.residenceMatchOriginAddress ? [this.valueSelected(this.pttNumberOptions)] : []],
         residenceAreaType: [{ value: null, disabled: true }, !this.residenceMatchOriginAddress ? [] : []],
         residenceAddress: [{ value: null, disabled: true }],
         residenceAddressNum: [null, !this.residenceMatchOriginAddress ? [Validators.pattern(myLetters.num_letters)] : []],
         residenceAddressSubNum: [null, [Validators.pattern(myLetters.addressSubnumber)]],
         originPlace: [null, [this.valueSelected(this.placeOptions)]],
         originId: [null],
         originArea: [{ value: null, disabled: true }, []],
         originPttNumber: [{ value: null, disabled: true }, [this.valueSelected(this.pttNumberOptions)]],
         originAreaType: [{ value: null, disabled: true }, []],
         originAddress: [{ value: null, disabled: true }],
         originAddressNum: [null, [Validators.pattern(myLetters.num_letters)]],
         originAddressSubNum: [null, [Validators.pattern(myLetters.addressSubnumber)]],
         phone: [null, [Validators.minLength(9), Validators.maxLength(13), Validators.pattern('^[0-9]*$')]],
         mobile: [null, [Validators.minLength(9), Validators.maxLength(13), Validators.pattern('^[0-9]*$')]],
         email: [null, Validators.email],
         note: [null, []],
         note1: [null, []],
         note2: [null, []],
         note3: [null, []],
         dateOfCreation: [null, []],
         regNumberAutomatic: [],
         regNumberInput: [],
         newRegNumber: [null, Validators.required],
         maritalStatus: [null],
         foreigner: [false],
         deprivationExtent: [{ value: null, disabled: true }, []],
      });

      this.newSubjectForm.controls.newRegNumber.disable();
      this.dynamicValidation();
      this.filterOptions();

      if (this.subjectId !== undefined) {
         this.subjectService.getSubject(this.subjectId).subscribe(res => {
            /*
              Olja Andjelovski olja.andjelovski@iten.rs
              Pronasli smo korisnika - radi se izmena podataka.
              Polje treba da setujemo na true, kako ne bi bila moguca izmena polja Datum unosa u registar
            */
            this.isDisabledRegistrationDate = true;
            this.familyDossierId = res.familyDossierId;

            // provera da li nosilac sa clanovima ili clan radi blokiranja unosa JMBP-a
            if ((res.familyDossier === 'CARRIER' && res.familyMembersNames.trim() && res.jmbp) || res.familyDossier === 'MEMBER') {
               this.isCarrierOrMember = true;
            }

            if (res.permanentResidence !== null) {
               if (!this.checkAddresses(res.permanentResidence, res.residence)) {
                  this.checkDiffAddress = true;
                  this.residenceMatchOriginAddress = false;
               }
            }
            let familyDossierStatus: string;
            if (res.familyDossier === 'NONE') {
               familyDossierStatus = '';
            } else {
               familyDossierStatus = res.familyDossier;
            }

            this.newSubjectForm.patchValue({
               firstName: res.firstName,
               lastName: res.lastName,
               parentName: res.parentName,
               gender: res.gender,
               dateOfBirth: res.dateOfBirth === null ? null : new Date(res.dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')),
               yearOfBirth: res.yearOfBirth,
               foreigner: res.foreigner,
               jmbg: res.jmbg,
               placeOfBirth: res.placeOfBirth,
               nationality: res.nationality !== null ? res.nationality : null,
               category: res.category !== null ? res.category : null,
               familyType: res.familyType !== null ? res.familyType : null,
               citizenship: res.citizenship,
               nativeLanguage: res.language,
               disabledPerson: res.disabledPerson === null ? null : res.disabledPerson ? this.yesNoOptions[0] : this.yesNoOptions[1],
               disabilityType: res.disabilityType,
               ableToWork: res.ableToWork === null ? null : res.ableToWork ? this.yesNoOptions[0] : this.yesNoOptions[1],
               occupation: res.occupation,
               employment: res.employment,
               education: res.education,
               levelOfEducation: res.levelOfEducation,
               schoolAttending: res.schoolAttending,
               // privremeno za business
               businessAbility: res.businessAbility === null ? null : res.businessAbility ? this.yesNoOptions[0] : this.yesNoOptions[1],
               familyDossier: familyDossierStatus,
               jmbp: res.jmbp,
               residenceId: res.residence === null ? null : res.residence.id === null ? null : res.residence.id,
               residencePlace: res.residence === null ? '' : res.residence.town === null ? '' : res.residence.town,
               residenceArea: res.residence === null ? '' : res.residence.localComunity === null ? '' : res.residence.localComunity,
               residencePttNumber: res.residence === null ? '' : res.residence.ptt === null ? '' : res.residence.ptt,
               residenceAreaType: res.residence === null ? '' : res.residence.populatedPlace === null ? '' : res.residence.populatedPlace,
               residenceAddress: res.residence === null ? '' : res.residence.street === null ? '' : res.residence.street,
               residenceAddressNum: res.residence === null ? '' : res.residence.number,
               residenceAddressSubNum: res.residence === null ? '' : res.residence.subnumber,
               originId: res.permanentResidence === null ? null : res.permanentResidence.id === null ? null : res.permanentResidence.id,
               originPlace: res.permanentResidence === null ? '' : res.permanentResidence.town === null ? '' : res.permanentResidence.town,
               originArea: res.permanentResidence === null ? '' : res.permanentResidence.localComunity === null ? '' : res.permanentResidence.localComunity,
               originPttNumber: res.permanentResidence === null ? '' : res.permanentResidence.ptt === null ? '' : res.permanentResidence.ptt,
               originAreaType: res.permanentResidence === null ? '' : res.permanentResidence.populatedPlace === null ? '' : res.permanentResidence.populatedPlace,
               originAddress: res.permanentResidence === null ? '' : res.permanentResidence.street === null ? '' : res.permanentResidence.street,
               originAddressNum: res.permanentResidence === null ? '' : res.permanentResidence.number,
               originAddressSubNum: res.permanentResidence === null ? '' : res.permanentResidence.subnumber,
               phone: res.phone,
               mobile: res.mobile,
               email: res.email,
               note: res.note,
               note1: res.note1,
               note2: res.note2,
               note3: res.note3,
               dateOfCreation: new Date(res.dateOfCreation.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')),
               registrationId: res.registrationId,
               maritalStatus: res.maritalStatus,
               deprivationExtent: res.deprivationExtent,
            });
            if (this.newSubjectForm.controls.residencePttNumber.value !== '') {
               this.getAreaOptions(this.newSubjectForm.controls.residencePttNumber.value, 'residence');
            }
            if (this.newSubjectForm.controls.originPttNumber.value !== '') {
               this.getAreaOptions(this.newSubjectForm.controls.originPttNumber.value, 'origin');
            }
            this.subjectService.getFamilyMembersCount(this.subjectId).subscribe(resp => {
               this.fammilyMembers = resp;
               if (res.familyDossier !== 'NONE' && this.fammilyMembers !== 0) {
                  this.familyDossier = [
                     { value: 'CARRIER', viewValue: 'Носилац' },
                     { value: 'MEMBER', viewValue: 'Члан' },
                  ];
               }
            });

            this.newSubjectForm.controls.originAddress.value === '' ? '' : this.getStreetCodebook((this.newSubjectForm.controls.originAddress.value as string).substring(0, 3), 'origin');
            this.newSubjectForm.controls.residenceAddress.value === '' ? '' : this.getStreetCodebook((this.newSubjectForm.controls.residenceAddress.value as string).substring(0, 3), 'residencePlace');
            this.familyDossierStatus = res.familyDossier;
            this.newSubjectForm.markAllAsTouched();
            this.oldJmbg = res.jmbg;
            this.workStatusCheckFromJmbg();
            if (res.registeredOnCenter) {
               this.possibleToRegisterOnCenter = true;
               this.registeredOnCenter = true;
            }
         });
      } else {
         this.newSubjectForm.patchValue({
            dateOfCreation: new Date(),
         });
      }
   }

   autoSelectItem(arr: any[], input: string) {
      return autoSelect(arr, input, 'title', this.newSubjectForm);
   }

   filterOptions() {
      this.filteredOriginPlaceOptions = this.newSubjectForm.controls.originPlace.valueChanges.pipe(
         startWith(''),
         map(value => this._filter(value, AutocompleteFilterEnum.ORIGIN_PLACE))
      );

      this.filteredOriginPttNumberOptions = this.newSubjectForm.controls.originPttNumber.valueChanges.pipe(
         startWith(''),
         map(value => this._filter(value, AutocompleteFilterEnum.ORIGIN_PTT_NUMBER))
      );

      this.filteredAreaOptionsOrigin = this.newSubjectForm.controls.originArea.valueChanges.pipe(
         startWith(''),
         map(value => this._filter(value, AutocompleteFilterEnum.ORIGIN_AREA))
      );

      this.filteredResidencePlaceOptions = this.newSubjectForm.controls.residencePlace.valueChanges.pipe(
         startWith(''),
         map(value => this._filter(value, AutocompleteFilterEnum.RESIDENCE_PLACE))
      );

      this.filteredResidencePttNumberOptions = this.newSubjectForm.controls.residencePttNumber.valueChanges.pipe(
         startWith(''),
         map(value => this._filter(value, AutocompleteFilterEnum.RESIDENCE_PTT_NUMBER))
      );

      this.filteredAreaOptions = this.newSubjectForm.controls.residenceArea.valueChanges.pipe(
         startWith(''),
         map(value => this._filter(value, AutocompleteFilterEnum.RESIDENCE_AREA))
      );

      this.filteredResidencePttNumberOptions = this.newSubjectForm.controls.residencePttNumber.valueChanges.pipe(
         startWith(''),
         map(value => this._filter(value, AutocompleteFilterEnum.RESIDENCE_PTT_NUMBER))
      );
   }

   compareObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
   }

   compareYesNoObjects(object1: any, object2: any) {
      return object1 && object2 && object1.value === object2.value && object1.viewValue === object2.viewValue;
   }

   compareGenderObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.gender === object2.gender && object1.code === object2.code;
   }

   dynamicValidation() {
      this.newSubjectForm.get('disabledPerson').valueChanges.subscribe(disabledPerson => {
         if (disabledPerson === undefined || disabledPerson === null) {
            this.newSubjectForm.get('disabilityType').disable();
            this.newSubjectForm.controls.disabilityType.setValue(null);
         } else {
            if (disabledPerson.value === true) {
               this.newSubjectForm.controls.disabilityType.enable();
               this.newSubjectForm.controls.disabilityType.setValidators([Validators.required]);
            } else {
               this.newSubjectForm.controls.disabilityType.disable();
               this.newSubjectForm.controls.disabilityType.setValue(null);
            }
         }
      });
      this.newSubjectForm.controls.businessAbility.valueChanges.subscribe(businessAbility => {
         if (businessAbility === undefined || businessAbility === null) {
            this.newSubjectForm.controls.deprivationExtent.disable();
            this.newSubjectForm.controls.deprivationExtent.setValue(null);
         } else {
            if (businessAbility.value === false) {
               this.newSubjectForm.controls.deprivationExtent.enable();
            } else {
               this.newSubjectForm.controls.deprivationExtent.disable();
               this.newSubjectForm.controls.deprivationExtent.setValue(null);
            }
         }
      });

      this.newSubjectForm.get('originPlace').valueChanges.subscribe(originPlace => {
         if (originPlace !== null && originPlace !== undefined) {
            this.newSubjectForm.get('originArea').enable();
            this.newSubjectForm.get('originAddress').enable();
         } else {
            this.newSubjectForm.get('originArea').disable();
            this.newSubjectForm.get('originAddress').disable();
         }
      });

      this.newSubjectForm.get('originArea').valueChanges.subscribe(originArea => {
         if (originArea !== null) {
            this.newSubjectForm.get('originPttNumber').enable();
         } else {
            this.newSubjectForm.get('originPttNumber').disable();
         }
      });

      this.newSubjectForm.get('originPttNumber').valueChanges.subscribe(originPttNumber => {
         if (originPttNumber !== null) {
            this.newSubjectForm.get('originAreaType').enable();
         } else {
            this.newSubjectForm.get('originAreaType').disable();
         }
      });

      this.newSubjectForm.get('residencePlace').valueChanges.subscribe(residencePlace => {
         if (residencePlace !== null && residencePlace !== undefined) {
            this.newSubjectForm.get('residenceArea').enable();
            this.newSubjectForm.get('residenceAddress').enable();
         } else {
            this.newSubjectForm.get('residenceArea').disable();
            this.newSubjectForm.get('residenceAddress').disable();
         }
      });

      this.newSubjectForm.get('residenceArea').valueChanges.subscribe(residenceArea => {
         if (residenceArea !== null) {
            this.newSubjectForm.get('residencePttNumber').enable();
         } else {
            this.newSubjectForm.get('residencePttNumber').disable();
         }
      });

      this.newSubjectForm.get('residencePttNumber').valueChanges.subscribe(residencePttNumber => {
         if (residencePttNumber !== null) {
            this.newSubjectForm.get('residenceAreaType').enable();
         } else {
            this.newSubjectForm.get('residenceAreaType').disable();
         }
      });

      this.newSubjectForm.get('foreigner').valueChanges.subscribe(foreigner => {
         if (foreigner) {
            this.newSubjectForm.controls.jmbg.setValue(null);
            this.newSubjectForm.controls.jmbg.setValidators([Validators.maxLength(250)]);
         } else {
            this.newSubjectForm.controls.jmbg.setValue(null);

            this.newSubjectForm.controls.jmbg.setValidators([Validators.maxLength(13), Validators.pattern('^[0-9]*$')]);
         }
      });
   }

   registerOnCenterAddress(event: any) {
      if (event.checked === true) {
         this.registeredOnCenter = true;
         this.codebookService.checkCsrAddress().subscribe(result => {
            if (!result) {
               this.possibleToRegisterOnCenter = false;
               this.toastr.error('SNACKBAR.REGISTER_SUBJECT_ON_CENTER_ERROR');
            } else {
               this.possibleToRegisterOnCenter = true;
               this.toastr.success('SNACKBAR.REGISTER_SUBJECT_ON_CENTER_SUCCESS');
            }
         });
      } else {
         this.registeredOnCenter = false;
      }
   }

   handleCancel() {
      if (!this.isEditMode) {
         this.router.navigate(['/subjects']);
      }
      this.emitUpdatedSubject.emit();
   }

   // Submit
   submitSubject(submittedForm: FormGroup) {
      this.submitButton.disabled = true;
      const regNumValue = this.newSubjectForm.get('newRegNumber').value;
      //kondicional za registarski broj
      if (regNumValue > this.recommendedRegNum) {
         let message = '';
         let title = '';
         this.translate.get('NEW_SUBJECT.OTHER.REG_NUM_ERR_TITLE1').subscribe((res: string) => {
            title = res + regNumValue;
         });
         const secondTitle = regNumValue - this.recommendedRegNum > 100 ? 'NEW_SUBJECT.OTHER.REG_NUM_ERR_TITLE2_2' : 'NEW_SUBJECT.OTHER.REG_NUM_ERR_TITLE2_1';
         this.translate.get(secondTitle).subscribe((res: string) => {
            title += res + this.recommendedRegNum + '.';
         });
         this.translate.get('NEW_SUBJECT.OTHER.REG_NUM_ERR_MSG').subscribe((res: string) => {
            message = res + regNumValue + '?';
         });
         const object = {
            document: null,
            message,
            title,
         };
         const dialogRef = this.dialog.open(WarningMessageComponent, {
            data: object,
            width: ModalSizeEnum.MINI,
            panelClass: 'overlay-panel',
         });
         dialogRef.afterClosed().subscribe(res => {
            if (res.event === 'cancel') {
               this.submitButton.disabled = false;
               return;
            } else {
               this.createSubject(submittedForm);
            }
         });
      } else {
         this.createSubject(submittedForm);
      }
   }

   createSubject(submittedForm: FormGroup) {
      if (submittedForm.value.jmbg !== '') {
         this.checkJMBGRule('');
         this.updateJMBGOnGenderChange('');
         this.updateJMBGOnDateOfBirthChange('');
      }
      if (!this.newSubjectForm.valid) {
         return;
      }

      const originStreet = submittedForm.get('originAddress').value;
      let residenceStreet = null;
      if (submittedForm.get('residenceAddress').value !== undefined && submittedForm.get('residenceAddress').value !== null) {
         residenceStreet = submittedForm.get('residenceAddress').value;
      }

      let residence = null;
      // if (submittedForm.get('originPlace').value !== null && submittedForm.get('originPlace').value !== '') {
      residence = {
         id: submittedForm.get('residenceId').value,
         town: submittedForm.get('residencePlace').value,
         localComunity: submittedForm.get('residenceArea').value !== null ? (submittedForm.get('residenceArea').value === '' ? null : submittedForm.get('residenceArea').value) : null,
         ptt: submittedForm.get('residencePttNumber').value,
         populatedPlace: submittedForm.get('residenceAreaType').value !== null ? (submittedForm.get('residenceAreaType').value !== '' ? submittedForm.get('residenceAreaType').value : null) : null,
         street: residenceStreet,
         number: submittedForm.get('residenceAddressNum').value,
         subnumber: submittedForm.get('residenceAddressSubNum').value,
      };
      // }

      let permanentResidence = null;
      permanentResidence = {
         id: submittedForm.get('originId').value,
         town: submittedForm.get('originPlace').value,
         localComunity: submittedForm.get('originArea').value !== null ? (submittedForm.get('originArea').value === '' ? null : submittedForm.get('originArea').value) : null,
         ptt: submittedForm.get('originPttNumber').value,
         populatedPlace: submittedForm.get('originAreaType').value !== null ? (submittedForm.get('originAreaType').value !== '' ? submittedForm.get('originAreaType').value : null) : null,
         street: originStreet,
         number: submittedForm.get('originAddressNum').value,
         subnumber: submittedForm.get('originAddressSubNum').value,
      };
      // }

      let familyDossierStatus = '';
      if (submittedForm.get('familyDossier').value === undefined || submittedForm.get('familyDossier').value === null || submittedForm.get('familyDossier').value === '') {
         familyDossierStatus = 'NONE';
      } else {
         familyDossierStatus = submittedForm.get('familyDossier').value;
      }
      if (this.residenceMatchOriginAddress === true) {
         residence.town = permanentResidence.town;
         residence.localComunity = permanentResidence.localComunity;
         residence.ptt = permanentResidence.ptt;
         residence.populatedPlace = permanentResidence.populatedPlace;
         residence.street = permanentResidence.street;
         residence.number = permanentResidence.number;
         residence.subnumber = permanentResidence.subnumber;
      }
      const firstNameTrimed = (submittedForm.get('firstName').value as string).trim();
      const lastNameTrimed = (submittedForm.get('lastName').value as string).trim();
      const parentNameTrimed = submittedForm.get('parentName').value === null ? null : (submittedForm.get('parentName').value as string).trim();

      const newSubject = {
         subjectIdentity: {
            csrId: JSON.parse(this.localStorageService.get('loggedUser')).csrId,
            subjectId: this.subjectId,
         },
         firstName: firstNameTrimed,
         lastName: lastNameTrimed,
         parentName: parentNameTrimed,
         gender: this.isOptionSelected('gender') ? submittedForm.get('gender').value : null,
         dateOfBirth:
            submittedForm.get('dateOfBirth').value === null
               ? null
               : new Date(submittedForm.get('dateOfBirth').value).setHours(
                    new Date(submittedForm.get('dateOfBirth').value).getHours() - new Date(submittedForm.get('dateOfBirth').value).getTimezoneOffset() / 60
                 ),
         yearOfBirth: submittedForm.get('yearOfBirth').value,
         jmbg: submittedForm.get('jmbg').value,
         placeOfBirth: submittedForm.get('placeOfBirth').value,
         nationality: this.isOptionSelected('nationality') ? submittedForm.get('nationality').value : null,
         category: this.isOptionSelected('category') ? submittedForm.get('category').value : null,
         familyType: this.isOptionSelected('familyType') ? submittedForm.get('familyType').value : null,
         citizenship: this.isOptionSelected('citizenship') ? submittedForm.get('citizenship').value : null,
         language: this.isOptionSelected('nativeLanguage') ? submittedForm.get('nativeLanguage').value : null,
         disabledPerson: this.isOptionSelected('disabledPerson') ? submittedForm.get('disabledPerson').value?.value : null,
         disabilityType: submittedForm.get('disabilityType').value,
         ableToWork: submittedForm.get('ableToWork').value !== null ? submittedForm.get('ableToWork').value?.value : null,
         occupation: this.isOptionSelected('occupation') ? submittedForm.get('occupation').value : null,
         employment: this.isOptionSelected('employment') ? submittedForm.get('employment').value : null,
         education: this.isOptionSelected('education') ? submittedForm.get('education').value : null,
         businessAbility: submittedForm.get('businessAbility').value !== null ? submittedForm.get('businessAbility').value?.value : null,
         familyDossier: familyDossierStatus,
         familyDossierId: this.familyDossierId,
         jmbp: submittedForm.get('jmbp').value,
         residence,
         permanentResidence,
         phone: submittedForm.get('phone').value,
         mobile: submittedForm.get('mobile').value,
         email: submittedForm.get('email').value,
         note: submittedForm.get('note').value,
         note1: submittedForm.get('note1').value,
         note2: submittedForm.get('note2').value,
         note3: submittedForm.get('note3').value,
         dateOfCreation: new Date(submittedForm.get('dateOfCreation').value).setHours(
            new Date(submittedForm.get('dateOfCreation').value).getHours() - new Date(submittedForm.get('dateOfCreation').value).getTimezoneOffset() / 60
         ),
         registrationId: this.regNumber ? 0 : null,
         registeredOnCenter: this.registeredOnCenter,
         newRegNum: this.typeOfInputRegNum ? submittedForm.controls.newRegNumber.value : null,
         levelOfEducation: submittedForm.controls.levelOfEducation.value,
         schoolAttending: submittedForm.controls.schoolAttending.value,
         maritalStatus: this.isOptionSelected('maritalStatus') ? submittedForm.get('maritalStatus').value : null,
         foreigner: submittedForm.get('foreigner').value,
         deprivationExtent: this.isOptionSelected('deprivationExtent') ? submittedForm.controls.deprivationExtent.value : null,
      };
      const x = this.subjectService.addSubject(newSubject);
      x.subscribe(
         result => {
            if (!this.isEditMode) {
               this.router.navigateByUrl('/subjects');
               let message = '';
               this.translate.get('SNACKBAR.USER_ADDED_IN_REGISTER').subscribe((res: string) => {
                  message = res;
               });
               let response = '';
               this.translate.get('SNACKBAR.BUTTON_OK').subscribe((res: string) => {
                  response = res;
               });
               this.toastr.showSuccess(message + `: ${submittedForm.get('firstName').value} ${submittedForm.get('lastName').value}`);
            } else {
               this.emitUpdatedSubject.emit(result);
            }
         },
         error => {
            let message = '';
            if (error.error === 'registrstrationId') {
               this.translate.get('NEW_SUBJECT.OTHER.REG_NUM_ERROR').subscribe((res: string) => {
                  message = res;
               });
               this.newSubjectForm.controls.newRegNumber.setErrors({ invalid: true });
            } else {
               this.translate.get('NEW_SUBJECT.ERROR').subscribe((res: string) => {
                  message = res;
               });
            }
            this.toastr.showError(message);
            this.submitButton.disabled = false;
         }
      );
   }

   handleChange(event: any) {
      this.requiredCopyAddress = event.checked;
      this.residenceMatchOriginAddress = !this.residenceMatchOriginAddress;
      if (this.requiredCopyAddress) {
         this.newSubjectForm.controls.residencePlace.enable();
         this.newSubjectForm.controls.residenceAddressNum.enable();
         this.newSubjectForm.controls.residenceArea.disable();
      } else {
         this.newSubjectForm.controls.residencePlace.disable();
         this.newSubjectForm.controls.residencePttNumber.disable();
         this.newSubjectForm.controls.residenceAddressNum.disable();
      }
      if (this.residenceMatchOriginAddress) {
         this.newSubjectForm.patchValue({
            residencePlace: this.newSubjectForm.get('originPlace').value,
            residenceArea: this.newSubjectForm.get('originArea').value,
            residencePttNumber: this.newSubjectForm.get('originPttNumber').value,
            residenceAreaType: this.newSubjectForm.get('originAreaType').value,
            residenceAddress: this.newSubjectForm.get('originAddress').value,
            residenceAddressNum: this.newSubjectForm.get('originAddressNum').value,
            residenceAddressSubNum: this.newSubjectForm.get('originAddressSubNum').value,
         });
      } else {
         this.newSubjectForm.patchValue({
            residencePlace: null,
            residenceArea: null,
            residencePttNumber: null,
            residenceAreaType: null,
            residenceAddress: null,
            residenceAddressNum: null,
            residenceAddressSubNum: null,
         });
      }
   }

   checkJMBG(event: any) {
      if (this.newSubjectForm.get('foreigner').value === true) {
         return;
      }
      this.updateJMBGOnDateOfBirthChange(event);
      if (event.target.value.length === 13) {
         if (this.oldJmbg !== event.target.value) {
            this.isSavePossible = false;
            const x = this.subjectService.checkJmbg(event.target.value);
            this.translate.get('NEW_SUBJECT.TOOLTIP').subscribe((result: string) => {
               this.tooltipText = result;
            });
            x.subscribe(
               res => {
                  if (res !== null && res !== '' && res.length !== 0 && res !== undefined) {
                     if (!res.some((r: any) => r.center === this.loggedIn.center)) {
                        let response = '';
                        let center = '';
                        let status = '';
                        this.translate.get('NEW_SUBJECT.CENTER').subscribe((resp: string) => {
                           center = resp;
                        });
                        this.translate.get('NEW_SUBJECT.STATUS').subscribe((resp: string) => {
                           status = resp;
                        });
                        for (const r of res) {
                           response += center + r.center + ' ' + status + r.status.title + `\n`;
                        }
                        this.toastr.showSuccess(response);
                        this.isSavePossible = true;
                        this.tooltipText = '';
                     } else {
                        this.newSubjectForm.controls.jmbg.setErrors({ incorrect: true });
                        this.toastr.error('NEW_SUBJECT.EXISTS');
                        this.isSavePossible = false;
                        this.tooltipText = '';
                     }
                  } else {
                     this.toastr.info('NEW_SUBJECT.DOESNTEXIST');
                     this.isSavePossible = true;
                     this.tooltipText = '';
                  }
               },
               () => {
                  this.toastr.error('NEW_SUBJECT.ERROR');
                  this.isSavePossible = false;
               }
            );
         } else {
            this.isSavePossible = true;
         }
      }

      this.updateJMBGOnGenderChange(event);
   }

   /**
    * Check if selected option from autocomplete is not altered
    * (e.g. if the correct value is Novi Sad, but the user added New Sad)
    */
   valueSelected(myArray: any[]) {
      return (control: any): { [key: string]: boolean } | null => {
         const selectboxValue = control.value;
         if (selectboxValue !== '' && myArray.length !== 0) {
            const itemExist = myArray.find(item => item === selectboxValue);

            if (itemExist) {
               // everything's fine. return no error. therefore it's null.
               return null;
            } else {
               if (control.value === '' || control.value === undefined) {
                  return null;
               } else {
                  // there's no matching select box value selected. so return match error.
                  return { match: true };
               }
            }
         }
      };
   }

   /**
    * _filter
    * @param value input value
    * @param what which filter should be applied (check AutocompleteFilterEnum)
    */
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      if (value !== null && value !== undefined) {
         const filterValue = value.toLowerCase();
         let res;
         switch (what) {
            case AutocompleteFilterEnum.NATIONALITY:
               res = sortByFirstLetter(
                  this.nationalityOptions.filter(option => option.title.toLowerCase().includes(filterValue)),
                  filterValue,
                  'title'
               );
               if (res.length === 0) {
                  this.newSubjectForm.get('nationality').setErrors({ match: true });
               }
               return res;
            case AutocompleteFilterEnum.CATEGORY:
               res = sortByFirstLetter(
                  this.categoryOptions.filter(option => option.title.toLowerCase().includes(filterValue)),
                  filterValue,
                  'title'
               );
               if (res.length === 0) {
                  this.newSubjectForm.get('category').setErrors({ match: true });
               }
               return res;
            case AutocompleteFilterEnum.CITIZENSHIP:
               res = sortByFirstLetter(
                  this.citizenshipOptions.filter(option => option.title.toLowerCase().includes(filterValue)),
                  filterValue,
                  'title'
               );
               if (res.length === 0) {
                  this.newSubjectForm.get('citizenship').setErrors({ match: true });
               }
               return res;
            case AutocompleteFilterEnum.NATIVE_LANGUAGE:
               res = sortByFirstLetter(
                  this.nativeLanguageOptions.filter(option => option.title.toLowerCase().includes(filterValue)),
                  filterValue,
                  'title'
               );
               if (res.length === 0) {
                  this.newSubjectForm.get('nativeLanguage').setErrors({ match: true });
               }
               return res;
            case AutocompleteFilterEnum.EDUCATION:
               res = sortByFirstLetter(
                  this.educationOptions.filter(option => option.title.toLowerCase().includes(filterValue)),
                  filterValue,
                  'title'
               );
               if (res.length === 0) {
                  this.newSubjectForm.get('education').setErrors({ match: true });
               }
               return res;
            case AutocompleteFilterEnum.EMPLOYMENT:
               res = sortByFirstLetter(
                  this.employmentOptions.filter(option => option.title.toLowerCase().includes(filterValue)),
                  filterValue,
                  'title'
               );
               if (res.length === 0) {
                  this.newSubjectForm.get('employment').setErrors({ match: true });
               }
               return res;
            case AutocompleteFilterEnum.OCCUPATION:
               res = sortByFirstLetter(
                  this.occupationOptions.filter(option => option.title.toLowerCase().includes(filterValue)),
                  filterValue,
                  'title'
               );
               if (res.length === 0) {
                  this.newSubjectForm.get('occupation').setErrors({ match: true });
               }
               return res;
            case AutocompleteFilterEnum.RESIDENCE_PLACE:
               return this.placeOptions.filter(option => option.toLowerCase().includes(filterValue));
            case AutocompleteFilterEnum.RESIDENCE_AREA:
               return this.areaOptions.filter(option => option.toLowerCase().includes(filterValue));
            case AutocompleteFilterEnum.RESIDENCE_PTT_NUMBER:
               return this.pttNumberOptions.filter(option => option.toLowerCase().includes(filterValue));
            case AutocompleteFilterEnum.RESIDENCE_ADDRESS:
               return this.addressOptions.filter(option => option.toLowerCase().includes(filterValue));
            case AutocompleteFilterEnum.ORIGIN_PLACE:
               return this.placeOptions.filter(option => option.toLowerCase().includes(filterValue));
            case AutocompleteFilterEnum.ORIGIN_AREA:
               return this.areaOptionsOrigin.filter(option => option.toLowerCase().includes(filterValue));
            case AutocompleteFilterEnum.ORIGIN_PTT_NUMBER:
               return this.pttNumberOptions.filter(option => option.toLowerCase().includes(filterValue));
            case AutocompleteFilterEnum.ORIGIN_ADDRESS:
               return this.originAddressOptions.filter(option => option.toLowerCase().includes(filterValue));
            case AutocompleteFilterEnum.CATEGORY:
               return this.categoryOptions.filter(option => option.toLowerCase().includes(filterValue));
            default:
               break;
         }
      }
   }

   filterOriginArea(value: string) {
      this.filteredAreaOptionsOrigin = of(this._filter(value, AutocompleteFilterEnum.ORIGIN_AREA));
   }

   filterOriginPtt(city: any) {
      for (const value of this.cities) {
         if (value.name === city) {
            this.newSubjectForm.get('originPttNumber').setValue(value['ptt-number']);
            this.getAreaOptions(this.newSubjectForm.controls.originPttNumber.value, 'origin');
            this.filteredOriginPttNumberOptions = this.newSubjectForm.controls.originPttNumber.valueChanges.pipe(
               startWith(''),
               map(valuePtt => this._filter(valuePtt, AutocompleteFilterEnum.ORIGIN_PTT_NUMBER))
            );
         }
      }
   }

   filterResidencePtt(city: any) {
      for (const value of this.cities) {
         if (value.name === city) {
            this.newSubjectForm.get('residencePttNumber').setValue(value['ptt-number']);
            this.getAreaOptions(this.newSubjectForm.controls.residencePttNumber.value, 'residence');
            this.filteredResidencePttNumberOptions = this.newSubjectForm.controls.residencePttNumber.valueChanges.pipe(
               startWith(''),
               map(valuePtt => this._filter(valuePtt, AutocompleteFilterEnum.RESIDENCE_PTT_NUMBER))
            );
         }
      }
   }

   updateDisabilityType(event: any) {
      if (!event.value) {
         this.newSubjectForm.get('disabilityType').setValue(null);
      }
   }
   updateBusinessAbility(event: any) {
      if (!event.value) {
         this.newSubjectForm.get('disabilityType').setValue(null);
      }
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.title : '';
   }

   setRegistrationNumber(type: any) {
      if (type === 'input') {
         this.regNumber = this.newSubjectForm.value.regNumberInput;
         if (this.regNumber === true) {
            this.newSubjectForm.controls.regNumberAutomatic.disable();
            this.typeOfInputRegNum = true;
            this.newSubjectForm.controls.newRegNumber.enable();
         } else {
            this.newSubjectForm.controls.newRegNumber.setValue(null);
            this.newSubjectForm.controls.newRegNumber.setErrors(null);
            this.newSubjectForm.controls.newRegNumber.disable();

            this.newSubjectForm.controls.regNumberAutomatic.enable();
            this.typeOfInputRegNum = false;
         }
         if (this.recommendedRegNum == null || this.recommendedRegNum == undefined) {
            this.subjectService.getRegisterId(this.loggedIn.csrId).subscribe(res => {
               this.recommendedRegNum = res;
            });
         }
      } else {
         this.regNumber = this.newSubjectForm.value.regNumberAutomatic;
         if (this.regNumber === true) {
            this.newSubjectForm.controls.regNumberInput.disable();
         } else {
            this.newSubjectForm.controls.regNumberInput.enable();
         }
      }
   }

   updateJMBGOnDateOfBirthChange(event: any) {
      if (event != null && this.newSubjectForm.value.foreigner !== true) {
         if (this.newSubjectForm.value.dateOfBirth !== null && this.newSubjectForm.value.jmbg !== null) {
            const birthDate = new Date(this.newSubjectForm.value.dateOfBirth).getTime();
            const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
            const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
            if (this.newSubjectForm.value.jmbg.length === 13) {
               const jmbg = this.newSubjectForm.value.jmbg.substring(0, 7);
               if (newBirthDate !== jmbg) {
                  this.newSubjectForm.get('dateOfBirth').setErrors({ incorrect: true });
               } else {
                  this.newSubjectForm.get('dateOfBirth').setErrors(null);
               }
            }
         }
      }
   }

   updateYearOfBirthOnDateOfBirthChange(event: any) {
      if (event.target.value != null) {
         const birthDate = this.newSubjectForm.value.dateOfBirth.getFullYear();
         this.newSubjectForm.get('yearOfBirth').setValue(birthDate);
      }
   }

   updateDateOfBirthOnYearOfBirthChange(event: any) {
      if (event.target.value !== null) {
         if (this.newSubjectForm.value.dateOfBirth !== null) {
            const year = new Date(this.newSubjectForm.value.dateOfBirth).getTime();
            const newDate = this.datePipe.transform(year, 'ddMMyyyy');
            const fullYear = newDate.substring(4, 9);
            const birthDate = this.newSubjectForm.get('yearOfBirth').value;

            if (birthDate !== fullYear) {
               this.newSubjectForm.get('yearOfBirth').setErrors({ incorrect: true });
            } else {
               this.newSubjectForm.get('yearOfBirth').setErrors(null);
            }
         }
      }
   }

   updateJMBGOnGenderChange(event: any) {
      if (this.newSubjectForm.value.foreigner === true) {
         return;
      }
      if (this.newSubjectForm.value.jmbg !== null && this.newSubjectForm.value.jmbg.length === 13) {
         const jmbg = this.newSubjectForm.value.jmbg.substring(9, 12);
         if (this.newSubjectForm.get('gender').value !== null && this.newSubjectForm.get('gender').value !== undefined) {
            if (this.newSubjectForm.value.jmbg.length === 13 && jmbg >= '000' && jmbg <= '499') {
               if (this.newSubjectForm.value.gender.code === 'F') {
                  this.newSubjectForm.get('gender').setErrors({ incorrect: true });
               } else {
                  this.newSubjectForm.get('gender').setErrors(null);
               }
            } else if (jmbg >= '500' && jmbg <= '999') {
               if (this.newSubjectForm.value.gender.code === 'M') {
                  this.newSubjectForm.get('gender').setErrors({ incorrect: true });
               } else {
                  this.newSubjectForm.get('gender').setErrors(null);
               }
            }
         }
      }
   }
   checkAddresses(permanentResidence: any, residence: any) {
      if (permanentResidence.localComunity != residence.localComunity) {
         return false;
      }
      if (permanentResidence.number != residence.number) {
         return false;
      }
      if (permanentResidence.ptt != residence.ptt) {
         return false;
      }
      if (permanentResidence.street != residence.street) {
         return false;
      }
      if (permanentResidence.subnumber != residence.subnumber) {
         return false;
      }
      if (permanentResidence.town != residence.town) {
         return false;
      }
      if (permanentResidence.town != residence.town) {
         return false;
      }
      const permanentResidencePopulatetPlace = permanentResidence.populatedPlace === null ? '' : permanentResidence.populatedPlace.id;
      const residencePopulatedPlace = residence.populatedPlace === null ? '' : residence.populatedPlace.id;
      if (permanentResidencePopulatetPlace !== residencePopulatedPlace) {
         return false;
      }
      return true;
   }
   checkJMBGRule(event: any) {
      if (this.newSubjectForm.get('foreigner').value === true) {
         return;
      }
      if (this.newSubjectForm.value.jmbg !== null && this.newSubjectForm.value.jmbg !== '') {
         if (this.newSubjectForm.value.jmbg.length < 13) {
            this.newSubjectForm.get('jmbg').setErrors({ pattern: true });
         } else {
            const jmbg = this.newSubjectForm.value.jmbg;
            const digits = jmbg.split('').map(Number);
            const delimiter = 11;
            let sum = 0;
            let start = 7;
            for (let index = 0; index < digits.length - 1; index++, start--) {
               if (start === 1) {
                  start = 7;
               }
               sum += start * digits[index];
            }
            let controlNumber = sum % delimiter;
            if (controlNumber > 1) {
               controlNumber = delimiter - controlNumber;
            }
            if (controlNumber !== digits[digits.length - 1]) {
               this.newSubjectForm.get('jmbg').setErrors({ controlNumber: true });
               setTimeout(() => {
                  this.newSubjectForm.get('jmbg').setErrors({ controlNumber: true });
                  this.newSubjectForm.controls.jmbg.markAsTouched();
               });
            }
         }
      } else {
         if (this.newSubjectForm.get('dateOfBirth').hasError('incorrect')) {
            this.newSubjectForm.controls.dateOfBirth.setErrors(null);
         }
         if (this.newSubjectForm.get('gender').hasError('incorrect')) {
            this.newSubjectForm.controls.gender.setErrors(null);
         }
      }
   }

   workStatusUpdateFromJmbg() {
      if (this.newSubjectForm.get('foreigner').value === true) {
         return;
      }
      this.newSubjectForm.controls.employment.setValue('');
      const pensionerOrStudent = this.workStatusCheckFromJmbg() === 'PENSION' ? 4 : 6;
      if (this.workstatus) {
         const status = this.employmentOptions.find(element => element.id === pensionerOrStudent);
         if (this.newSubjectForm.controls.employment.value === null || this.newSubjectForm.controls.employment.value === '') {
            this.newSubjectForm.controls.employment.setValue(status);
         }
      }
   }

   workStatusCheckFromJmbg() {
      if (this.newSubjectForm.value.jmbg !== null && this.newSubjectForm.value.jmbg.length >= 7) {
         const jmbgDate = this.newSubjectForm.value.jmbg.substring(0, 7);
         const year = jmbgDate.substring(4, 7) <= 200 ? '2' + jmbgDate.substring(4, 7) : '1' + jmbgDate.substring(4, 7);
         const newBirthDate = this.datePipe.transform(new Date(Number(year), Number(jmbgDate.substring(2, 4)) - 1, Number(jmbgDate.substring(0, 2))).getTime(), 'yyyy-MM-dd');
         const timeDiff = Math.abs(Date.now() - new Date(newBirthDate).getTime());
         const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
         if (age <= 15) {
            this.workstatus = true;
            return;
         } else {
            if (age >= 65) {
               this.workstatus = true;
               return 'PENSION';
            }
            if (this.newSubjectForm.value.jmbg.length >= 12) {
               const gender = this.newSubjectForm.value.jmbg.substring(9, 12);
               if (gender >= '500' && gender <= '999' && age >= 63) {
                  this.workstatus = true;
                  return 'PENSION';
               }
            } else {
               this.workstatus = false;
               return;
            }
         }
      } else if (this.newSubjectForm.value.dateOfBirth !== null) {
         const timeDiff = Math.abs(Date.now() - new Date(this.newSubjectForm.value.dateOfBirth).getTime());
         const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
         if (age <= 15) {
            this.workstatus = true;
            return;
         } else {
            if (age >= 65) {
               this.workstatus = true;
               return 'PENSION';
            }
            const gender = this.newSubjectForm.value.gender;
            if (gender !== null && gender !== undefined && gender !== '') {
               if (gender.code === 'F' && age >= 63) {
                  this.workstatus = true;
                  return 'PENSION';
               }
            } else {
               this.workstatus = false;
               return;
            }
         }
      } else {
         this.workstatus = false;
         return;
      }
   }
   getAreaOptions(ptt: any, option: any) {
      if ((this.residenceMatchOriginAddress == true && option === 'origin') || this.residenceMatchOriginAddress != true) {
         this.codebookService.getLocalCommunityForPttNumber(ptt).subscribe(result => {
            if (option === 'origin') {
               this.areaOptionsOrigin = result.map(function (a) {
                  return a.communityName;
               });
               this.filteredAreaOptionsOrigin = of(this.areaOptionsOrigin);
            } else {
               this.areaOptions = result.map(function (a) {
                  return a.communityName;
               });
               this.filteredAreaOptions = of(this.areaOptions);
            }
         });
      }
   }

   getStreetCodebook(street: any, type: any) {
      if ((this.residenceMatchOriginAddress == true && type === 'origin') || this.residenceMatchOriginAddress != true) {
         this.codebookService.getStreetCodebookByStreetName(street).subscribe(result => {
            this.addressOptions = result.map(function (a) {
               return a.name;
            });
            if (type === 'origin') {
               this.originAddressOptions = this.addressOptions;
               this.filteredOriginAddressOptions = this.newSubjectForm.controls.originAddress.valueChanges.pipe(
                  startWith(''),
                  map(value => this._filter(value === null ? '' : value, AutocompleteFilterEnum.ORIGIN_ADDRESS))
               );
            } else {
               this.residenceAddressOptions = this.addressOptions;
               this.filteredResidenceAddressOptions = this.newSubjectForm.controls.residenceAddress.valueChanges.pipe(
                  startWith(''),
                  map(value => this._filter(value === null ? '' : value, AutocompleteFilterEnum.RESIDENCE_ADDRESS))
               );
            }
         });
      }
   }

   checkLengthStreetName(type: any) {
      if (type === 'origin') {
         if ((this.newSubjectForm.controls.originAddress.value as string).length === 3) {
            this.getStreetCodebook(this.newSubjectForm.controls.originAddress.value, type);
         }
      } else {
         if ((this.newSubjectForm.controls.residenceAddress.value as string).length === 3) {
            this.getStreetCodebook(this.newSubjectForm.controls.residenceAddress.value, type);
         }
      }
   }

   private isOptionSelected(field: string) {
      return this.newSubjectForm.get(field).value !== null && this.newSubjectForm.get(field).value !== '';
   }
   updateDateOfBirthFromJMBG() {
      if (this.newSubjectForm.value.jmbg !== null && this.newSubjectForm.value.jmbg.length >= 7) {
         const jmbgDate = this.newSubjectForm.value.jmbg.substring(0, 7);
         const jmbgDateOfBirth = jmbgDate.substring(0, 2);
         const jmbgMonthOfBirth = jmbgDate.substring(2, 4);
         const jmbgYearOfBirth = jmbgDate.substring(4, 7);
         this.newSubjectForm.controls.yearOfBirth.setValue(Number(jmbgYearOfBirth) > 800 ? '1' + jmbgYearOfBirth : '2' + jmbgYearOfBirth);

         this.newSubjectForm.controls.dateOfBirth.setValue(new Date(jmbgMonthOfBirth + '/' + jmbgDateOfBirth + '/' + this.newSubjectForm.value.yearOfBirth));
         if (this.newSubjectForm.value.jmbg.length >= 12) {
            const gender = this.newSubjectForm.value.jmbg.substring(9, 12);
            if (Number(gender) >= 500 && Number(gender) <= 999) {
               this.newSubjectForm.controls.gender.setValue(this.genderOptions[1]);
            } else {
               this.newSubjectForm.controls.gender.setValue(this.genderOptions[0]);
            }
         }
      }
   }
}
