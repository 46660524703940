<form [formGroup]="closeMembersStep" class="form">
    <form-group title="{{
      'NEW_FOSTER_CHILD.CLOSE_MEMBERS.BROTHERS_AND_SISTERS' | translate
    }}">

        <button mat-flat-button color="primary" type="button" (click)="addBrothersAndSisters()">
            {{ 'FOSTER_CHILD.FAMILY_DATA.ADD_BROTHERS_AND_SISTERS' | translate }}
        </button>
        <br>
        <br>

        <div class="table-responsive-wrapper" *ngIf="brothersAndSisters.length>0">
            <table mat-table [dataSource]="brothersAndSisters" #mytable>
                <ng-container matColumnDef="information">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'FOSTER_CHILD.FAMILY_DATA.INFORMATION_BROTHER' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.information }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> {{ 'VS.ACTION_OPTION' | translate }} </th>
                    <td mat-cell *matCellDef="let element; let i = index" class="action-link">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="deleteBrothersAndSisters(i)">
                                <mat-icon>delete</mat-icon>
                                <span>{{ 'VS.FORM5.DELETE' | translate }}</span>
                            </button>

                        </mat-menu>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsBrothersAndSisters"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsBrothersAndSisters"
                    class="problems-table__row"></tr>
            </table>
        </div>
    </form-group>

    <form-group title="{{
      'NEW_FOSTER_CHILD.CLOSE_MEMBERS.ADDITIONAL_INFORMATION' | translate
    }}" isLast="true">

        <button mat-flat-button color="primary" type="button" (click)="addGuardian()">
            {{ 'FOSTER_CHILD.FAMILY_DATA.ADD_OTHERS' | translate }}
        </button>
        <br>
        <br>

        <div class="table-responsive-wrapper" *ngIf="guardians.length>0">
            <table mat-table [dataSource]="guardians" #mytable>
                <ng-container matColumnDef="information">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'FOSTER_CHILD.FAMILY_DATA.INFORMATION' | translate }}

                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.information }}
                    </td>
                </ng-container>

                   <ng-container matColumnDef="relationshipCodebook">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'FOSTER_CHILD.FAMILY_DATA.RELATIONSHIP' | translate }}

                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.relationshipCodebook?.title }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> {{ 'VS.ACTION_OPTION' | translate }} </th>
                    <td mat-cell *matCellDef="let element; let i = index" class="action-link">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="deleteGuardians(i)">
                                <mat-icon>delete</mat-icon>
                                <span>{{ 'VS.FORM5.DELETE' | translate }}</span>
                            </button>

                        </mat-menu>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsGuardians"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsGuardians" class="problems-table__row"></tr>
            </table>
        </div>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'NEW_FOSTER_CHILD.FORM.ACQUAINTANCES_INFO' | translate }}
                </mat-label>
                <textarea matInput type="text" formControlName="acquaintancesInfo" autocomplete="off"
                    required maxlength="255"></textarea>
                <mat-error *ngIf="closeMembersStep.get('acquaintancesInfo').hasError('required')">
                    {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
                </mat-error>
            </mat-form-field>
        </field-holder>
    </form-group>
    <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
            class="form-footer__button form-footer__button--secondary">
            {{ 'NEW_FOSTER_CHILD.PREVIOUS_STEP' | translate }}
        </button>
        <button mat-stroked-button matStepperNext color="primary" [disabled]="!closeMembersStep.valid"
            class="form-footer__button" (click)="updateCloseMembersStep()">
            {{ 'NEW_FOSTER_CHILD.NEXT_STEP' | translate }}
        </button>
    </form-footer>
</form>