<form [formGroup]="adopteeStep" class="form">
  <div class="button-add-holder">
    <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
      <mat-icon>person_add</mat-icon>
      {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
    </button>
  </div>
  <br>
  <form-group title="{{ 'NEW_ADOPTED_CHILDREN.ADOPTEE.BEFORE_ADOPTION_TITLE' | translate }}">
    <section formGroupName="beforeAdoption">
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="firstName" required autocomplete="off" maxlength="255" />
          <mat-error *ngIf="adopteeStep.get('beforeAdoption.firstName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="lastName" required autocomplete="off" maxlength="255" />
          <mat-error *ngIf="adopteeStep.get('beforeAdoption.lastName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="jmbg" maxlength="13"
            (input)="updateJMBGOnDateOfBirthChange($event,'beforeAdoption'); copyJmbg()" required autocomplete="off"
            name="jmbg" />
          <mat-error *ngIf="adopteeStep.get('beforeAdoption.jmbg').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="adopteeStep.get('beforeAdoption.jmbg').hasError('minlength') ||
                  adopteeStep.get('beforeAdoption.jmbg').hasError('pattern')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate
            }}</mat-label>
          <input matInput [matDatepicker]="beforeAdoptionDateOfBirth" formControlName="dateOfBirth"
            (dateChange)="updateJMBGOnDateOfBirthChange($event,'beforeAdoption')" required autocomplete="off"
            [max]="currentDate" maxlength="255" name="dateFormat"
            (input)="checkInputDateFormat('beforeAdoption.dateOfBirth', $event)"
            (blur)="onBlur('beforeAdoption.dateOfBirth')" id="beforeAdoption-dateOfBirth" />
          <mat-datepicker-toggle matSuffix [for]="beforeAdoptionDateOfBirth"></mat-datepicker-toggle>
          <mat-datepicker #beforeAdoptionDateOfBirth></mat-datepicker>
          <mat-error *ngIf="adopteeStep.get('beforeAdoption.dateOfBirth').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="adopteeStep.get('beforeAdoption.dateOfBirth').hasError('incorrect')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="adopteeStep.get('beforeAdoption.dateOfBirth').hasError('maxDateExceeded')">
            {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
          </mat-error>
          <mat-error *ngIf="adopteeStep.get('beforeAdoption.dateOfBirth').hasError('invalidFormat')">
            {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.PLACE_OF_BIRTH' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="placeOfBirth" required autocomplete="off" maxlength="255" />
          <mat-error *ngIf="adopteeStep.get('beforeAdoption.placeOfBirth').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>

      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate
            }}</mat-label>
          <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenshipBefore"
            (focusout)="checkCitizenship('beforeAdoption')" />
          <mat-autocomplete #autoCitizenshipBefore="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredCitizenshipOptionsBefore | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="adopteeStep.get('beforeAdoption').get('citizenship').hasError('notValid')">
            {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="permanentResidence" autocomplete="off" maxlength="255" />
        </mat-form-field>
      </field-holder>
    </section>
  </form-group>
  <form-group title="{{ 'NEW_ADOPTED_CHILDREN.ADOPTEE.AFTER_ADOPTION_TITLE' | translate }}" isLast="true">
    <section formGroupName="afterAdoption">
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="firstName" required autocomplete="off" maxlength="255" />
          <mat-error *ngIf="adopteeStep.get('afterAdoption.firstName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="lastName" required autocomplete="off" maxlength="255" />
          <mat-error *ngIf="adopteeStep.get('afterAdoption.lastName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="jmbg" maxlength="13" required autocomplete="off"
            (input)="updateJMBGOnDateOfBirthChange($event,'afterAdoption')" name="jmbg" />
          <mat-error *ngIf="adopteeStep.get('afterAdoption.jmbg').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="adopteeStep.get('afterAdoption.jmbg').hasError('minlength') ||
                  adopteeStep.get('afterAdoption.jmbg').hasError('pattern')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate
            }}</mat-label>
          <input matInput [matDatepicker]="afterAdoptionDateOfBirth" formControlName="dateOfBirth"
            (dateChange)="updateJMBGOnDateOfBirthChange($event,'afterAdoption')" required autocomplete="off"
            placeholder="{{ 'DATE_FORMAT' | translate }}" [max]="currentDate" maxlength="255" name="dateFormat"
            (input)="checkInputDateFormat('afterAdoption.dateOfBirth', $event)"
            (blur)="onBlur('afterAdoption.dateOfBirth')" id="afterAdoption-dateOfBirth" />
          <mat-datepicker-toggle matSuffix [for]="afterAdoptionDateOfBirth"></mat-datepicker-toggle>
          <mat-datepicker #afterAdoptionDateOfBirth></mat-datepicker>
          <mat-error *ngIf="adopteeStep.get('afterAdoption.dateOfBirth').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="adopteeStep.get('afterAdoption.dateOfBirth').hasError('incorrect')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="adopteeStep.get('afterAdoption.dateOfBirth').hasError('maxDateExceeded')">
            {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
          </mat-error>
          <mat-error *ngIf="adopteeStep.get('afterAdoption.dateOfBirth').hasError('invalidFormat')">
            {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.PLACE_OF_BIRTH' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="placeOfBirth" required autocomplete="off" maxlength="255" />
          <mat-error *ngIf="adopteeStep.get('afterAdoption.placeOfBirth').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate
            }}</mat-label>
          <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenshipAfter"
            (focusout)="checkCitizenship('afterAdoption')" />
          <mat-autocomplete #autoCitizenshipAfter="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredCitizenshipOptionsAfter | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="adopteeStep.get('afterAdoption').get('citizenship').hasError('notValid')">
            {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
          </mat-error>

        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="permanentResidence" autocomplete="off" maxlength="255" />
        </mat-form-field>
      </field-holder>
    </section>
  </form-group>
  <form-footer>

    <button mat-flat-button matStepperNext color="primary" [disabled]="!adopteeStep.valid" class="form-footer__button"
      (click)="updateAdopteeStep()">
      {{ 'NEW_ADOPTED_CHILDREN.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>