/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SubjectsService } from 'src/services/subjects.service';
import { CodebookService } from 'src/services/codebook.service';
import { Subject } from 'src/types';
import { DialogActions } from 'src/app/utils/DialogActionsArchive';

interface ArhiveData {
   subject: Subject;
   action: DialogActions;
}

@Component({
   selector: 'archive-subject',
   templateUrl: './archive-subject.component.html',
   styleUrls: ['./archive-subject.component.scss'],
})
export class ArchiveSubjectComponent implements OnInit {
   minFromDate = new Date();
   maxFromDate = new Date();
   action: string;
   subject: Subject;
   archiveSubjectForm: FormGroup;
   archivationReasons: any[];
   archive = true;
   @ViewChild('submitButton') submitButton;

   constructor(
      public codebookService: CodebookService,
      public subjectsService: SubjectsService,
      public dialogRef: MatDialogRef<ArchiveSubjectComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: ArhiveData,
      private formBuilder: FormBuilder
   ) {
      this.subject = data.subject;
      this.action = data.action;
      this.archive = this.action === DialogActions.ARCHIVE;
      this.populateArchivationReasons();
   }

   closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
   }

   ngOnInit() {
      if (this.archive) {
         this.archiveSubjectForm = this.formBuilder.group({
            date: ['', [Validators.required]],
            archiveReason: ['', [Validators.required]],
         });
         this.populateArchivationReasons();
      } else {
         this.archiveSubjectForm = this.formBuilder.group({
            date: ['', [Validators.required]],
            unarchiveReason: ['', [Validators.required]],
         });
      }
      this.maxFromDate.setFullYear(this.maxFromDate.getFullYear() + 1);
   }

   populateArchivationReasons() {
      this.codebookService.getArchivationReasonsCodebook().subscribe(reasons => {
         this.archivationReasons = reasons;
      });
   }

   archiveSubject(submittedForm: FormGroup) {
      const archiveData = {
         date: submittedForm.get('date').value,
         archiveReason: submittedForm.get('archiveReason').value,
      };
      this.subjectsService.archiveSubject(this.subject.historicalSubjectIdentity.subjectId, archiveData).subscribe(
         res => {
            this.dialogRef.close({ event: this.action, data: res });
         },
         () => {
            this.submitButton.disabled = false;
            this.dialogRef.close({ event: DialogActions.ERROR });
         }
      );
   }
   unarchiveSubject(submittedForm: FormGroup) {
      const unarchiveData = {
         date: submittedForm.get('date').value,
         unarchiveReason: submittedForm.get('unarchiveReason').value,
      };

      const x = this.subjectsService.unarchiveSubject(this.subject.historicalSubjectIdentity.subjectId, unarchiveData);
      x.subscribe(
         res => {
            this.dialogRef.close({ event: this.action, data: res });
         },
         () => {
            this.submitButton.disabled = false;
            this.dialogRef.close({ event: DialogActions.ERROR });
         }
      );
   }

   submitForm(submittedForm: FormGroup) {
      if (this.archive) {
         this.archiveSubject(submittedForm);
      } else {
         this.unarchiveSubject(submittedForm);
      }
   }
}
