<container kind="midi">
   <case-page-header (handleComplainAccepted)="acceptedComplain()" (newCaseStatus)="updateStatus($event)"
      *ngIf="caseData" [caseData]="caseData" [caseId]="caseId" [subjectId]="subjectId" [teamMember]="teamMember"
      [caseName]="caseName">
   </case-page-header>
   <page-intro-header title="{{title}}"></page-intro-header>

   <mat-card class="card" *ngIf="teamMember===false">
      <form [formGroup]="assuranceForm" class="form">
         <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'ASSURANCE.CREATION_DATE' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerCreationDate" formControlName="dateOfCreation" [max]="maxDate"
                  autocomplete="off" />
               <mat-datepicker-toggle matSuffix [for]="pickerCreationDate"></mat-datepicker-toggle>
               <mat-datepicker #pickerCreationDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'ASSURANCE.PURPOSE' | translate}}
               </mat-label>
               <input matInput type="text" formControlName="purpose" autocomplete="off" />
               <mat-error *ngIf="assuranceForm.get('purpose').hasError('required')">
                  {{ "ASSURANCE.PURPOSE_ERROR" | translate }}
               </mat-error>
            </mat-form-field>
         </field-holder>

         <ng-container class="aside">
            <button mat-flat-button color="primary" type="button" [matMenuTriggerFor]="menu"
               #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
               {{ 'ASSURANCE.DOCUMENT' | translate }}
               <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="filter-menu">
               <ng-container *ngFor="let docOptions of documentOptions">
                  <button mat-menu-item (click)="handleNewEvaluation(docOptions)">{{ docOptions.name }}</button>
               </ng-container>
            </mat-menu>

            <input type="file" #file accept=".docx, .pdf" (change)="handleFileInput($event.target.files)" hidden>
            <button class="header__add-document" style="margin-left: 15px;" mat-flat-button color="primary"
               type="submit" *ngIf="assuranceForm.get('uploadedFile').value === '' " (click)="file.click()">
               {{ 'ASSURANCE.CREATE' | translate }}
            </button>
            <button class="header__add-document" style="margin-left: 15px;" mat-flat-button color="primary"
               *ngIf="assuranceForm.get('uploadedFile').value !== '' " (click)="removeFile()">
               {{ 'ASSURANCE.REMOVE' | translate }}
            </button>
            <br>
            <field-holder>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'ASSURANCE.SOLUTION' | translate }}</mat-label>
                  <input matInput type="text" formControlName="fileName" readonly autocomplete="off" />
               </mat-form-field>
            </field-holder>
         </ng-container>

         <form-footer class="footer">
            <span *ngIf="assuranceForm.get('uploadedFile').value === ''&& caseOnlyUpload===false" [matTooltip]="caseData?.caseStatus !== 'ACCEPTED'
                    ? 'Предмет није прихваћен'
                    : 'Преглед уверења'">
               <span matTooltip="{{'CASES.COMPLAIN_RESPOND_NEEDED' | translate }}"
                  [matTooltipDisabled]="!caseData?.newComplain">
                  <button mat-stroked-button color="primary" (click)="preview()"
                     [disabled]="caseData?.caseStatus !== 'ACCEPTED'||caseData?.newComplain"
                     class="form-footer__button form-footer__button--secondary">
                     {{ "ASSURANCE.PREVIEW" | translate }}
                  </button>
               </span>
            </span>

            <span *ngIf="assuranceForm.get('uploadedFile').value === '' && caseOnlyUpload===false" [matTooltip]="caseData?.caseStatus !== 'ACCEPTED'
                    ? 'Предмет није прихваћен'
                    : 'Генерисање уверења и затварање предмета'">
               <button mat-flat-button color="primary" type="button"
                  (click)="saveAssurance(); submitButton.disabled = true;"
                  [disabled]="caseData?.caseStatus !== 'ACCEPTED' || !assuranceForm.valid"
                  class="form-footer__button form-footer__button--secondary" #submitButton>
                  {{ 'ASSURANCE.SUBMIT' | translate }}
               </button>
            </span>
            <span *ngIf="assuranceForm.get('uploadedFile').value !== ''|| caseOnlyUpload===true " [matTooltip]="caseData?.caseStatus !== 'ACCEPTED'
                    ? 'Предмет није прихваћен'
                    : 'Генерисање уверења и затварање предмета'">
               <button mat-flat-button color="primary" type="button" (click)="saveAssurance()"
                  [disabled]="caseData?.caseStatus !== 'ACCEPTED' || !assuranceForm.valid"
                  class="form-footer__button form-footer__button--secondary">
                  {{ 'ASSURANCE.SEND' | translate }}
               </button>
            </span>
            <mat-progress-spinner [diameter]="45" *ngIf="isSubmited" color="primary" mode="indeterminate">
            </mat-progress-spinner>
         </form-footer>

      </form>
   </mat-card>
   <mat-card *ngIf="teamMember">
      <team-member [editable]="caseData?.caseStatus === 'ACCEPTED'" [caseId]="caseId" [subjectId]="subjectId">
      </team-member>
   </mat-card>
</container>