/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from 'src/services/data-sharing.service';
import { NotificationService } from 'src/services/notification.service';
import { IntegrationsService } from 'src/services/integrations.service';

@Component({
   selector: 'notifications-dropdown',
   templateUrl: './notifications-dropdown.component.html',
   styleUrls: ['./notifications-dropdown.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class NotificationsDropdownComponent implements OnInit {
   notifications = [];
   constructor(public router: Router, private notificationsService: NotificationService, private cdr: ChangeDetectorRef) {
      this.notificationsService.getAllNotificationsPageable(0, 10).subscribe(res => {
         this.notifications = res.content;
         this.cdr.detectChanges();
      });
   }

   /*   @Input() notifications: any[] = []; */
   @Output() toggleDropdownHandler = new EventEmitter();

   ngOnInit(): void {}

   toggleDropdown(): void {
      this.toggleDropdownHandler.next();
   }
}
