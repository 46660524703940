<form class="form" [formGroup]="newProtegeSubject">
  <form-group title="{{ 'NEW_PROTEGE_SUBJECT.TITLE' | translate }}" isLast="true">
    <div class="button-add-holder">
      <button mat-flat-button color="primary" (click)="importSubjectData()" type="button">
        <mat-icon>person_add</mat-icon>
        {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
      </button>
    </div>
    <br>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_PROTEGE_SUBJECT.FIRST_NAME' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="firstName" required autocomplete="off" maxlength="255" />
        <mat-error *ngIf="newProtegeSubject.get('firstName').hasError('required')">
          {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_PROTEGE_SUBJECT.LAST_NAME' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="lastName" required autocomplete="off" maxlength="255" />
        <mat-error *ngIf="newProtegeSubject.get('lastName').hasError('required')">
          {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_PROTEGE_SUBJECT.FATHER_FIRST_NAME' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="fatherFirstName" autocomplete="off" maxlength="255" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_PROTEGE_SUBJECT.ID_NUMBER' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="subjectId" autocomplete="off" maxlength="255" />
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_PROTEGE_SUBJECT.JMBG' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="jmbg" maxlength="13" name="jmbg"
          (input)="updateJMBGOnDateOfBirthChange($event);selectGuardianshipTypeOptions()" autocomplete="off" />
        <mat-error *ngIf="newProtegeSubject.get('jmbg').hasError('pattern')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_FOSTER_CHILD.FORM.DATE_OF_BIRTH' | translate
          }}</mat-label>
        <input matInput [matDatepicker]="picker"
          (dateChange)="updateJMBGOnDateOfBirthChange($event);selectGuardianshipTypeOptions()"
          formControlName="dateOfBirth" autocomplete="off" placeholder="{{ 'DATE_FORMAT' | translate }}" [max]="currentDate"
          maxlength="255" name="dateFormat" (input)="checkInputDateFormat('dateOfBirth', $event)" (blur)="onBlur('dateOfBirth')" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="newProtegeSubject.get('dateOfBirth').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="newProtegeSubject.get('dateOfBirth').hasError('incorrect')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="newProtegeSubject.get('dateOfBirth').hasError('maxDateExceeded')">
          {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
        </mat-error>
        <mat-error *ngIf="newProtegeSubject.get('dateOfBirth').hasError('invalidFormat')">
          {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>

    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_PROTEGE_SUBJECT.GUARDIANSHIP_TYPE' | translate }}
        </mat-label>
        <mat-select formControlName="guardianshipType" required>
          <mat-option *ngFor="let option of guardianshipTypeOptions" [value]="option">
            {{ option.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="newProtegeSubject.get('guardianshipType').hasError('required')">
          {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_PROTEGE_SUBJECT.GUARDIANSHIP_ID' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="guardianshipId" autocomplete="off" maxlength="255" />
      </mat-form-field>
    </field-holder>
    <form-footer>
      <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
        class="form-footer__button form-footer__button--secondary">
        {{ 'NEW_PROTEGE_SUBJECT.CANCEL' | translate }}
      </button>
      <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
        (click)="closeRecord()" [disabled]="newProtegeSubject.valid === false">
        {{ 'NEW_PROTEGE_SUBJECT.SAVE' | translate }}
      </button>
    </form-footer>
  </form-group>
</form>