<container>
   <mat-card class="card">
      <card-header title="{{ 'STATISTICS.JNP_STATS.TITLE' | translate }}">
      </card-header>
      <form [formGroup]="jnpStatsForm" class="form">
         <field-holder kind="2-cols" *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
            <mat-form-field appearance="outline">
               <mat-label>
                 {{'GLOBAL.CENTER' | translate}}
               </mat-label>
               <input type="text" matInput [matAutocomplete]="autoSubcenter" formControlName="center"
                (focusout)="autoSelectCenter()" required/>
               <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete"
                 [displayWith]="displayCustomFormat" (optionSelected)="onOptionSelected($event)">
                 <mat-option *ngFor="let cent of filteredAllCenters | async" [value]="cent">
                   {{ cent.name }}
                 </mat-option>
               </mat-autocomplete>
             </mat-form-field>
             <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.JNP_STATS.FILE_TYPE' | translate }}</mat-label>
               <mat-select formControlName="fileType" required>
                  <mat-option> </mat-option>
                  <mat-option *ngFor="let option of fileTypes" [value]="option">
                     {{ option }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder kind="2-cols" *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.JNP_STATS.CASE_STATUS' | translate }}</mat-label>
               <mat-select formControlName="onlyClosed" required>
                  <mat-option> </mat-option>
                  <mat-option *ngFor="let option of statusOptions" [value]="option.value">
                     {{ option.viewValue }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'STATISTICS.JNP_STATS.CLASSIFICATION' | translate }}
               </mat-label>
               <mat-select formControlName="classificationsCodebook" multiple>
                  <mat-option #allSelectedRights (click)="toggleAllSelectionRights()" [value]="0">
                     {{ 'STATISTICS.JNP_STATS.ALL_CLASSIFICATION' | translate }}
                  </mat-option>
                  <mat-option *ngFor="let element of classificationsOptions" [value]="element?.code"
                     (click)="tosslePerOneRight()">
                     {{ element.code  +" "+  element.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder kind="4-cols" *ngIf="!isMainCenterBelgrade && !isUserDrugostepeni">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.JNP_STATS.CASE_STATUS' | translate }}</mat-label>
               <mat-select formControlName="onlyClosed" required>
                  <mat-option> </mat-option>
                  <mat-option *ngFor="let option of statusOptions" [value]="option.value">
                     {{ option.viewValue }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.JNP_STATS.FILE_TYPE' | translate }}</mat-label>
               <mat-select formControlName="fileType" required>
                  <mat-option> </mat-option>
                  <mat-option *ngFor="let option of fileTypes" [value]="option">
                     {{ option }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.JNP_STATS.DATE' | translate }}</mat-label>
               <mat-select formControlName="dateOfSignifance" required>
                  <mat-option value="1">Датум креирања</mat-option>
                  <mat-option value="2">Датум важења</mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'STATISTICS.JNP_STATS.CLASSIFICATION' | translate }}
               </mat-label>
               <mat-select formControlName="classificationsCodebook" multiple>
                  <mat-option #allSelectedRights (click)="toggleAllSelectionRights()" [value]="0">
                     {{ 'STATISTICS.JNP_STATS.ALL_CLASSIFICATION' | translate }}
                  </mat-option>
                  <mat-option *ngFor="let element of classificationsOptions" [value]="element?.code"
                     (click)="tosslePerOneRight()">
                     {{ element.code  +" "+  element.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.REPORTING_PERIOD_FROM' | translate }}</mat-label>
               <input matInput type="text" [matDatepicker]="fromDate" formControlName="fromDate" autocomplete="off"
                  required placeholder="{{ 'STATISTICS.REPORTING_PERIOD_FROM' | translate }}" [max]="maxDate" />
               <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
               <mat-datepicker #fromDate></mat-datepicker>
               <mat-error *ngIf="jnpStatsForm.get('fromDate').hasError('required')">
                  {{ "STATISTICS.DATE_ERROR" | translate }}
               </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.REPORTING_PERIOD_TO' | translate }}</mat-label>
               <input matInput type="text" [matDatepicker]="toDate" formControlName="toDate" autocomplete="off" required
                  placeholder="{{ 'STATISTICS.REPORTING_PERIOD_TO' | translate }}"[max]="maxDate"  />
               <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
               <mat-datepicker #toDate></mat-datepicker>
               <mat-error *ngIf="jnpStatsForm.get('toDate').hasError('required')">
                  {{ "STATISTICS.DATE_ERROR" | translate }}
               </mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder>
            <mat-hint style="font-size: 12px;">{{ 'STATISTICS.JNP_STATS.ALL_CASE_STATUS_NOTE' | translate }}</mat-hint>
         </field-holder>
         <form-footer>
            <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
               matTooltip="{{ 'STATISTICS.JNP_STATS.CONTRACT' | translate }}"
               [matTooltipDisabled]="!(csr?.accountNumber == null || csr?.payerNumber == null) || jnpStatsForm.value.fileType!=='Txt'"
               (click)="sendRequest()"
               [disabled]="!jnpStatsForm.valid || ((csr?.accountNumber == null || csr?.payerNumber == null) && jnpStatsForm.value.fileType==='Txt') || btnDisabled">
               {{ 'STATISTICS.SUBMIT' | translate  }}
            </button>
            <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
            </mat-progress-spinner>
         </form-footer>
      </form>

   </mat-card>
</container>