import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/local-storage.service';
import { CodebookService } from 'src/services/codebook.service';
import { UserService } from 'src/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsService } from 'src/services/statistics.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';

enum AutocompleteFilterEnum {
  CENTERS = 'centers',
}
@Component({
  selector: 'activity-stats',
  templateUrl: './activity-stats.component.html',
  styleUrls: ['./activity-stats.component.scss']
})
export class ActivityStatsComponent implements OnInit {

  activityStatsForm: FormGroup;
  offices = [];
  workers = [];
  filteredWorkers = [];
  centers = [];
  filteredAllCenters: Observable<any>;
  loggedIn: any;
  isMainCenterBelgrade = false;
  dateFrom: any;
  dateTo: any;
  btnDisabled: boolean;
  isUserDrugostepeni = false;
  maxDate: Date = new Date(2999, 11, 31);

  constructor(
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private codebookService: CodebookService,
    private userService: UserService,
    private translate: TranslateService,
    private statisticsService: StatisticsService
    ){
    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    this.isMainCenterBelgrade = (this.loggedIn?.csrId === '1') ? true : false;
    this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
    if (this.isMainCenterBelgrade) {
      this.codebookService.getAllSubcenters().subscribe(result => {
        this.centers = result;
        this.filteredAllCenters = this.activityStatsForm.controls.center.valueChanges.pipe(
           startWith(''),
           map((value) => (typeof value === 'string' ? value : value?.name)),
           map((title) =>
              title
                 ? this._filter(title, AutocompleteFilterEnum.CENTERS)
                 : this.centers.slice()
           )
        );
      });
    }
    if(this.isUserDrugostepeni){
      this.codebookService.getCsrCodebook().subscribe((result) => {
        this.centers = result;
        this.filteredAllCenters = this.activityStatsForm.controls.center.valueChanges.pipe(
           startWith(''),
           map((value) => (typeof value === 'string' ? value : value?.name)),
           map((title) =>
              title
                 ? this._filter(title, AutocompleteFilterEnum.CENTERS)
                 : this.centers.slice()
           )
        );
     });
    }
    this.activityStatsForm = this.formBuilder.group({
      office: ['', []],
      workerName: ['', []],
      dateFrom: ['', [Validators.required]],
      dateTo: ['', [Validators.required]],
      center: ['']
    });
    this.statisticsService.getBtnStatus().subscribe((status) => {
      this.btnDisabled = status;
    });
    this.btnDisabled = false;
    }

  ngOnInit(): void {
     if (!this.isMainCenterBelgrade && !this.isUserDrugostepeni) {
      this.codebookService.getOfficeCodebookByCsrId(this.loggedIn.csrId).subscribe(result => {
        this.offices = result;
      });
      this.userService.getUsersInCenterByCsrId(this.loggedIn.csrId).subscribe(
        result => {
          this.workers = result;
          this.filteredWorkers = this.workers;
        });
    }
  }
  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.name : objectValue;
  }
  autoSelectCenter(){
    autoSelect(this.centers, 'center', 'name', this.activityStatsForm);
  }
   findOffices(event: any) {
    if (event.option.value !== undefined) {
      const id = event.option.value.id;
      this.codebookService.getOfficeCodebookByCsrId(id).subscribe(result => {
        this.offices = result;
        this.userService.getUsersInCenterByCsrId(id).subscribe(
          res => {
            this.workers = res;
            this.filteredWorkers = this.workers;
          });
      });
    }
  }

  findWorkers(event: any) {
    this.activityStatsForm.get('workerName').setValue('');
    this.filteredWorkers = event.source.value === undefined ? this.workers : this.workers.filter(
      (option) => option.officeOuId === event.source.value.ouId);
  }

  sendRequest(form: any) {
    this.dateFrom = new Date(this.activityStatsForm.value.from);
    this.dateFrom.setDate(this.dateFrom.getDate() + 1);
    this.dateTo = new Date(this.activityStatsForm.value.to);
    this.dateTo.setDate(this.dateTo.getDate() + 1);
    let filename = '';
    this.translate.get('STATISTICS.ACTIVITY_STATS.TITLE').subscribe((res: string) => {
      filename = res;
    });

    let officeId = '';
    if (this.activityStatsForm.value.office !== '' && this.activityStatsForm.value.office !== undefined) {
        officeId = form.value.office.ouId;
    }
    let userId = '';
    if (this.activityStatsForm.value.workerName !== '' && this.activityStatsForm.value.workerName !== undefined) {
        userId = form.value.workerName.id;
    }
    const dto = {
      csrId: this.isMainCenterBelgrade || this.isUserDrugostepeni ? form.value.center.id : this.loggedIn.csrId,
      officeId: officeId,
      userId: userId,
      fromDate: form.value.dateFrom,
      toDate: form.value.dateTo
    };

    this.statisticsService.getActivityStatistics(dto, filename);
  }
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();
    switch (what) {
       case AutocompleteFilterEnum.CENTERS:
          return sortByFirstLetter(this.centers.filter((option) =>
             option.name.toLowerCase().includes(filterValue)), filterValue, 'name');
       default:
          break;
    }
 }
}
