<container kind="midi">
  <page-intro-header back="/users"
    title="{{ 'USER_DETAILS.TITLE' | translate }} - {{ user?.firstName }} {{ user?.lastName }}">
    <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" (click)="checkMainAdmin()" class="dropdown-button">
      {{ 'GLOBAL.MANAGE_USER' | translate }} <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <span>
        <button mat-menu-item (click)="resetPassword(user)">
          <mat-icon>loop</mat-icon>{{ 'USER_DETAILS.PASSWORD_FORGOTTEN' | translate }}
        </button>
      </span>
      <span *ngIf="user?.enabled">
        <button mat-menu-item (click)="enableUser(user)">
          <mat-icon>lock</mat-icon>{{ 'USER_DETAILS.DISABLE_USER' | translate }}
        </button>
      </span>
      <span *ngIf="!user?.enabled">
        <button mat-menu-item (click)="enableUser(user)">
          <mat-icon>lock_open</mat-icon>{{ 'USER_DETAILS.ENABLE_USER' | translate }}
        </button>
      </span>
      <span *ngIf="user?.enabled&&impersonate">
        <button mat-menu-item (click)="impersonateUser(user)">
          <mat-icon>supervised_user_circle</mat-icon>{{ 'GLOBAL_ADMIN.IMPERSONATE' | translate }}
        </button>
      </span>
    </mat-menu>
  </page-intro-header>
  <mat-card class="card card--edit" *ngIf="isEditMode">
    <user-form isEditMode="true" (emitUpdatedUser)="patchUserAfterEdit($event)" [userId]="userId"></user-form>
  </mat-card>
  <mat-card class="card" *ngIf="!isEditMode">
    <card-header>
      <button mat-flat-button color="primary" (click)="editUser()">
        {{ 'GLOBAL.EDIT' | translate }}
      </button>
    </card-header>
    <definition-list>
      <definition-item *ngIf="user?.enabled" key="{{ 'WORKERS.STATUS.TITLE' | translate }}">
        <status-label status="1">
          {{ 'WORKERS.STATUS.ACTIVE' | translate }}
        </status-label>
      </definition-item>
      <definition-item *ngIf="!user?.enabled" key="{{ 'WORKERS.STATUS.TITLE' | translate }}">
        <status-label status="2">
          {{ 'WORKERS.STATUS.INACTIVE' | translate }}
        </status-label>
      </definition-item>
      <definition-item *ngIf="user?.firstName && user.lastName" key="{{ 'USER_DETAILS.NAME' | translate }}">
        {{ user?.firstName }} {{ user?.lastName }}</definition-item>
      <definition-item *ngIf="user?.username" key="{{ 'USER_DETAILS.USERNAME' | translate }}">
        {{ user?.username }}</definition-item>
      <definition-item key="{{ 'USER_DETAILS.JMBG' | translate }}">
        <span *ngIf="user?.jmbg" >{{ user?.jmbg }}</span>
        <span *ngIf="!user?.jmbg" >{{ 'USER_DETAILS.NO_JMBG' | translate }}</span>
      </definition-item>
      <definition-item *ngIf="user?.csrCodebook" key="{{ 'WORKERS.CENTER' | translate }}">
        {{ user?.csrCodebook?.name }}
      </definition-item>
      <definition-item *ngIf="user?.officeCodebook" key="{{ 'USER_DETAILS.OFFICE' | translate }}">
        {{ user?.officeCodebook?.name }}
      </definition-item>
      <definition-item *ngIf="user?.officeCodebook" key="{{ 'WORKERS.ROLE' | translate }}">
        {{ roles }}
      </definition-item>
    </definition-list>
  </mat-card>

</container>
