import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { getNumberForShow, getNumberFromString, patchForDecimalNumber } from 'src/app/utils/formatLocalNumber';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { AlfrescoService } from 'src/services/alfresco.service';
import { CaseProblemRecordService } from 'src/services/case-problem-record.service';
import { CaseService } from 'src/services/case.service';
import { CodebookService } from 'src/services/codebook.service';
import { ExpeditionService } from 'src/services/expedition.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalSizeEnum } from 'src/types';
import { fileCheck } from 'src/app/utils/fileInputUtil';
import { SubjectDocumentsDialogComponent } from 'src/app/sidebar-widget/subject-documents/subject-documents-dialog/subject-documents-dialog.component';

interface SelectOptions {
   value: any;
   viewValue: string;
}
enum PaymentTypesEnum {
   NATURI = 'naturi',
   JNP = 'jnp',
}

@Component({
   selector: 'app-local',
   templateUrl: './local.component.html',
   styleUrls: ['./local.component.scss'],
})
export class LocalComponent implements OnInit {
   fileToUpload: File = null;
   localCaseForm: FormGroup;
   dateOfCaseCreation: any;
   caseName: any;
   caseId: any;
   subjectId: any;
   isValid: any;
   caseData: any;
   subject: any;
   subCaseKind: any;
   documentsKind: any;
   title: string;
   documentOptions: any;
   decisionTypes = [];
   jnpTypes = [];
   filteredJnpTypes = [];
   paymentMethodCodebook = [];
   loggedIn: any;
   rejectedCase = true;
   teamMember = false;
   isLawyer: any = false;
   hasProblem = false;
   button = false;
   record = null;
   localCaseCreateDto: any;
   decisionMakerOptions: any;
   noRegistrationId = false;
   loadedData: any;
   maxDate: Date = new Date(2999, 11, 31);
   paymentTypes: SelectOptions[] = [
      { value: PaymentTypesEnum.JNP, viewValue: 'LOCAL_CASE.JNP_TYPE' },
      { value: PaymentTypesEnum.NATURI, viewValue: 'LOCAL_CASE.NATURI' },
   ];
   isSubmited = false;
   minToDate: Date;
   @ViewChild('file') file: ElementRef<HTMLInputElement>;

   public mask = {
      guide: false,
      showMask: false,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/],
   };
   constructor(
      private alfrescoService: AlfrescoService,
      private formBuilder: FormBuilder,
      private router: Router,
      private caseService: CaseService,
      private codebookService: CodebookService,
      private cdr: ChangeDetectorRef,
      public dialog: MatDialog,
      private translate: TranslateService,
      private datePipe: DatePipe,
      private localStorageService: LocalStorageService,
      private route: ActivatedRoute,
      private userService: UserService,
      private expedition: ExpeditionService,
      private toastr: ToastrImplService,
      private caseProblemRecordService: CaseProblemRecordService
   ) {
      this.createLocalCaseForm();

      this.codebookService.getTypesOfJnpCodebook().subscribe(result => {
         this.jnpTypes = result;
         this.filteredJnpTypes = this.jnpTypes.filter(o => o.inNature === false);
      });
      this.codebookService.getPaymentMethodCodebook().subscribe(result => {
         this.paymentMethodCodebook = result;
      });
      this.isLawyer = this.userService.isUserLawyer();
      this.route.params.subscribe(params => {
         this.subjectId = params.subjectId;
         this.caseId = params.id;
         const [_, caseType] = this.router.url.split('/cases/');
         if (caseType !== '') {
            this.caseName = caseType.split('/')[0];
         }
         this.isValid = this.subjectId !== undefined || this.subjectId !== null;
         this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
         if (this.isValid) {
            this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
            this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
            this.caseService.getSocialCase(this.caseId).subscribe(result => {
               this.caseData = result;
               if (this.loggedIn?.id !== this.caseData.assignedUserId) {
                  this.teamMember = true;
               } else {
                  this.teamMember = false;
               }
               this.getDocumentsByTemplate();

               this.subject = this.caseData.subject;
               if (this.subject.registrationId === null || this.subject.registrationId === '') {
                  this.noRegistrationId = true;
                  this.toastr.info('CASES.NO_REGISTER_NUMBER');
               }
               this.title = this.caseData.socialCaseClassificationCodebook.title;
               this.codebookService.getDecisionTypeCodebook().subscribe(result1 => {
                  this.decisionTypes = result1;
                  if (this.title.toLowerCase().includes('престанак')) {
                     this.decisionTypes.splice(0, 1);
                  }
               });
               this.caseHasProblem();
            });
            this.caseService.findLocalBySocialCaseId(this.caseId).subscribe(result => {
               this.loadedData = result;
               if (this.loadedData !== undefined && this.loadedData !== null) {
                  this.patchValues();
               }
            });
         }
      });
   }

   ngOnInit(): void {
      this.getDecisionMakerCodebook();

      this.localCaseForm.get('validFrom').valueChanges.subscribe((fromDate: Date) => {
         this.minToDate = fromDate;
      });
   }
   checkAmount() {
      this.localCaseForm.controls.amount.setValue(getNumberForShow(this.localCaseForm.controls.amount.value));
   }
   getDecisionMakerCodebook() {
      this.codebookService.getDecisionMakerCodebook().subscribe(result => {
         this.decisionMakerOptions = result;
         if (!this.loadedData?.socialCase?.decisionMaker) {
            this.localCaseForm.controls.decisionMaker.setValue(result[0]);
            this.localCaseForm.controls.decisionMakerTitle.setValue(result[0].title);
         } else {
            this.localCaseForm.controls.decisionMaker.setValue(result[this.loadedData?.socialCase?.decisionMaker.id - 1]);
            this.localCaseForm.controls.decisionMakerTitle.setValue(this.loadedData?.socialCase?.decisionMakerTitle);
         }
      });
   }
   updateStatus(newStatus: string) {
      this.caseData.caseStatus = newStatus;
      this.caseHasProblem();
      this.cdr.detectChanges();
      this.noRegistrationId = false;
      this.subject = this.caseData.subject;
      if (this.subject.registrationId === null || this.subject.registrationId === '') {
         this.noRegistrationId = true;
         this.toastr.info('CASES.NO_REGISTER_NUMBER');
      }
   }

   checkIfSendIsDisabled() {
      return this.caseProblemRecordService.checkIfSendIsDisabled(this.caseData, !this.localCaseForm.valid, this.hasProblem);
   }

   handleNewEvaluation(doc: any) {
      //    window.location.href = 'ms-word:ofe|u|' + url;
      const amountData =
         this.localCaseForm.controls.amount.value !== '' && this.localCaseForm.controls.amount.value !== undefined ? getNumberFromString(this.localCaseForm.controls.amount.value) : null;
      const paymentTypeData =
         this.localCaseForm.controls.paymentType.value !== '' && this.localCaseForm.controls.paymentType.value !== undefined && this.localCaseForm.controls.paymentType.value != null
            ? this.localCaseForm.controls.paymentType.value.title
            : null;
      const jnpReason =
         this.localCaseForm.controls.jnpReason != undefined &&
         this.localCaseForm.controls.jnpReason.value !== '' &&
         this.localCaseForm.controls.jnpReason.value !== undefined &&
         this.localCaseForm.controls.jnpReason.value != null
            ? this.localCaseForm.controls.jnpReason.value.title
            : null;

      const body = {
         documentId: doc.documentId,
         fileName: doc.name,
         caseId: this.caseId === undefined ? null : this.caseId,
         amount: amountData,
         paymentType: paymentTypeData,
         accountNumber: this.localCaseForm.value.account,
         jnpReason: jnpReason,
      };
      this.alfrescoService.downloadDocumentByUrl(body);
   }

   handleFileInput(files: FileList) {
      const modifiedFiles = fileCheck(files, this.dialog, this.translate);
      if (modifiedFiles.length > 0) {
         this.fileToUpload = modifiedFiles[0];
         this.localCaseForm.get('file').setValue(this.fileToUpload);
         this.localCaseForm.get('fileName').setValue(this.fileToUpload.name);
      }
      this.file.nativeElement.value = null;
   }

   createLocalCaseForm() {
      this.localCaseForm = this.formBuilder.group({
         file: ['', [Validators.required]],
         fileName: ['', [Validators.required]],
         validFrom: ['', [Validators.required]],
         validTo: new FormControl({ value: '', disabled: true }),
         amount: new FormControl({ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]),
         paymentType: new FormControl({ value: '', disabled: false }, [Validators.required]),
         decisionType: ['', [Validators.required]],
         jnpReason: ['', [Validators.required]],
         account: [''],
         typeOfLocalCase: [PaymentTypesEnum.JNP],
         naturi: new FormControl({ value: '', disabled: true }),
         decisionMaker: [''],
         decisionMakerDescription: [],
         decisionMakerName: [''],
         decisionMakerTitle: [''],
      });
   }

   patchValues() {
      const type = this.loadedData.typeOfJpn?.inNature ? this.paymentTypes[1] : this.paymentTypes[0];
      this.localCaseForm = this.formBuilder.group({
         validFrom: this.loadedData.validFrom !== null ? new Date(this.loadedData.validFrom.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : this.loadedData.validFrom,
         validTo: this.loadedData.validTo !== null ? new Date(this.loadedData.validTo.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : this.loadedData.validTo,
         amount: this.loadedData.amount === null ? '' : patchForDecimalNumber(this.loadedData.amount),
         paymentType: new FormControl({ value: this.loadedData.paymentMethod, disabled: false }, []),
         decisionType: this.loadedData.decisionType,
         jnpReason: this.loadedData.typeOfJpn,
         account: this.loadedData.socialCase.accountNumber,
         naturi: this.loadedData.naturi,
         typeOfLocalCase: [type.value],
         file: ['', [Validators.required]],
         fileName: ['', [Validators.required]],
         decisionMaker: this.decisionMakerOptions === undefined ? null : this.decisionMakerOptions[this.loadedData.socialCase.decisionMaker.id - 1],
         decisionMakerName: this.loadedData.socialCase.decisionMakerName,
         decisionMakerTitle: this.loadedData.socialCase.decisionMakerTitle,
         decisionMakerDescription: this.loadedData.socialCase.decisionMakerDescription,
      });
      this.changeTypeOfLocalRight(type.value);
      this.decisionChanged(this.loadedData.decisionType);
      this.paymentMethodChanged();
      this.localCaseForm.controls.jnpReason.setValue(this.loadedData.typeOfJpn);
      this.localCaseForm.controls.paymentType.enable();
   }

   getDocumentsByTemplate() {
      this.alfrescoService.getDocumentTemplates(this.caseData.socialCaseClassificationCodebook.oldKindMark).subscribe(result => {
         this.documentOptions = result;
      });
   }

   async submitLocalCase() {
      this.isSubmited = true;
      const localCase = { ...this.createLocalCase(), isTempSave: false };
      const addNewRecordRequered = await this.addNewRecord(this.localCaseForm.controls.decisionType.value.id);
      if (addNewRecordRequered) {
         this.saveAndUploadLocalCase(localCase);
      } else {
         this.enableSubmitButton();
      }
   }

   saveAndUploadLocalCase(localCase: any) {
      this.caseService.saveLocalCase(localCase).subscribe(
         (res: any) => {
            this.caseService.addConclusionFinishCase(this.localCaseForm.get('file').value, this.subjectId, this.caseId).subscribe(
               documentId => {
                  this.toastr.success('SNACKBAR.DOCUMENT_ADDED');
                  this.localStorageService.set('case', true);
                  this.expedition.send({ documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'local');
               },
               error => {
                  manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
                  this.enableSubmitButton();
               }
            );
         },
         (error: any) => {
            manageError(this.toastr, ErrorType.SAVE_DATA);
            this.enableSubmitButton();
         }
      );
   }

   saveCurrentData() {
      const localCase = { ...this.createLocalCase(), isTempSave: true };
      this.caseService.saveLocalCase(localCase).subscribe(
         (localCaseId: number) => {
            if (localCaseId) {
               this.loadedData = { ...this.loadedData, id: localCaseId };
               this.toastr.success('SNACKBAR.SOLUTION_SAVED');
               this.localCaseForm.markAsPristine();
            }
         },
         error => {
            this.enableSubmitButton();
            manageError(this.toastr, ErrorType.SAVE_DATA);
         }
      );
   }

   compareLoadedObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
   }
   createLocalCase() {
      const validFrom =
         this.localCaseForm.value.validFrom === undefined || this.localCaseForm.value.validFrom === null
            ? this.datePipe.transform(new Date().getTime(), 'yyyy-MM-dd')
            : this.datePipe.transform(new Date(this.localCaseForm.value.validFrom).getTime(), 'yyyy-MM-dd');
      const validTo =
         this.localCaseForm.value.validTo === undefined || this.localCaseForm.value.validTo === null
            ? this.datePipe.transform(new Date().getTime(), 'yyyy-MM-dd')
            : this.datePipe.transform(new Date(this.localCaseForm.value.validTo).getTime(), 'yyyy-MM-dd');
      const localCase = {
         id: this.loadedData ? this.loadedData.id : null,
         socialCaseId: this.caseId,
         created: new Date(),
         decisionTypeCodebook: this.localCaseForm.value.decisionType !== '' ? this.localCaseForm.value.decisionType : null,
         validFrom: validFrom,
         validTo: validTo,
         amount: getNumberFromString(this.localCaseForm.value.amount),
         typeOfJpn: this.localCaseForm.value.jnpReason !== '' ? this.localCaseForm.value.jnpReason : null,
         paymentMethodCodebook: this.localCaseForm.value.paymentType ? this.localCaseForm.value.paymentType : null,
         accountNumber: this.localCaseForm.value.account,
         naturi: this.localCaseForm.value.naturi,
         decisionMaker: this.localCaseForm.value.decisionMaker ? this.localCaseForm.value.decisionMaker : null,
         decisionMakerDescription: this.localCaseForm.value.decisionMaker?.id === 2 ? this.localCaseForm.value.decisionMakerDescription : '',
         decisionMakerName: this.localCaseForm.value.decisionMakerName ? this.localCaseForm.value.decisionMakerName : '',
         decisionMakerTitle: this.localCaseForm.value.decisionMakerTitle ? this.localCaseForm.value.decisionMakerTitle : '',
      };
      return localCase;
   }
   decisionChanged(value: any) {
      if (value?.id === 1) {
         this.localCaseForm.controls.validTo.enable();
         this.localCaseForm.controls.amount.enable();
         this.localCaseForm.controls.paymentType.enable();
         this.localCaseForm.controls.account.enable();
         this.localCaseForm.controls.naturi.enable();
         this.localCaseForm.controls.account.setValidators(Validators.required);
         this.localCaseForm.controls.account.setValidators(Validators.required);
      } else {
         this.localCaseForm.controls.validTo.disable();
         this.localCaseForm.controls.naturi.disable();
         this.localCaseForm.controls.amount.disable();
         this.localCaseForm.controls.paymentType.disable();
         this.localCaseForm.controls.validTo.setValue('');
         this.localCaseForm.controls.amount.setValue('');
         this.localCaseForm.controls.paymentType.setValue('');
         this.localCaseForm.controls.account.setValue('');
         this.localCaseForm.controls.account.clearValidators();
         this.localCaseForm.controls.paymentType.clearValidators();
         this.localCaseForm.controls.account.setErrors(null);
         this.localCaseForm.controls.account.disable();
      }
   }
   paymentMethodChanged() {
      if (!this.localCaseForm.controls.paymentType.value || this.localCaseForm.value.paymentType.id === 1 || this.localCaseForm.value.paymentType.id === 6) {
         this.localCaseForm.controls.account.setValue('');
         this.localCaseForm.controls.account.clearValidators();
         this.localCaseForm.controls.account.setErrors(null);
         this.localCaseForm.controls.account.disable();
      } else {
         this.localCaseForm.controls.account.enable();
         if (this.localCaseForm.value.paymentType.id !== 2) {
            this.localCaseForm.controls.account.setValidators(Validators.required);
         } else {
            this.localCaseForm.controls.account.clearValidators();
            this.localCaseForm.controls.account.setErrors(null);
         }
      }
   }

   changeTypeOfLocalRight(event: any) {
      if (event === PaymentTypesEnum.JNP) {
         this.localCaseForm.controls.account.setValidators(Validators.required);
         this.localCaseForm.controls.paymentType.setValidators(Validators.required);
         this.filteredJnpTypes = this.jnpTypes.filter(o => o.inNature === false);
         this.localCaseForm.controls.jnpReason.setValue('');
      } else {
         this.localCaseForm.controls.account.clearValidators();
         this.localCaseForm.controls.paymentType.clearValidators();
         this.filteredJnpTypes = this.jnpTypes.filter(o => o.inNature === true);
         this.localCaseForm.controls.jnpReason.setValue('');
      }
   }

   acceptedComplain() {
      this.caseData.newComplain = false;
   }
   decisionMakerSelected(event: any) {
      if (event.value.id === 2) {
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TEXT').subscribe((res: string) => {
            this.localCaseForm.controls.decisionMakerDescription.setValue(res);
         });
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TITLE').subscribe((res: string) => {
            this.localCaseForm.controls.decisionMakerTitle.setValue(res);
         });
      } else {
         this.localCaseForm.controls.decisionMakerDescription.setValue('');
         this.localCaseForm.controls.decisionMakerTitle.setValue(event.value.title);
      }
   }

   private enableSubmitButton() {
      this.button = false;
      this.isSubmited = false;
   }

   problemAdded(added: boolean) {
      if (added) {
         this.hasProblem = true;
         this.cdr.detectChanges();
      }
   }
   caseHasProblem() {
      this.caseProblemRecordService.caseHasProblem(this.caseData?.caseStatus, this.caseId, this.caseData?.caseKindCodebook?.kind).subscribe(hasProblem => {
         this.hasProblem = hasProblem;
      });
   }

   async addNewRecord(decision: any): Promise<boolean> {
      if (decision === 1) {
         try {
            return await this.caseProblemRecordService.addNewRecord(this.subjectId, this.caseId, this.caseData?.caseKindCodebook?.kind, {
               fromDate: this.localCaseForm.controls.validFrom.value,
               toDate: this.localCaseForm.controls.validTo.value,
               amount: this.localCaseForm.controls.amount.value,
            });
         } catch (error) {
            return false;
         }
      }
      return true;
   }
   async saveChanges(): Promise<boolean> {
      if (this.isSubmited || this.localCaseForm.pristine) {
         return true;
      }
      let message = '';
      let title = '';
      this.translate.get('WARNING_DIALOG.SAVE_DATA.TITLE').subscribe((res: string) => {
         title = res;
      });
      this.translate.get('WARNING_DIALOG.SAVE_DATA.MESSAGE').subscribe((res: string) => {
         message = res;
      });
      const object = {
         document: null,
         message: message,
         title: title,
         saveData: true,
      };

      const dialogRef = this.dialog.open(WarningMessageComponent, {
         data: object,
         width: ModalSizeEnum.MINI,
         panelClass: 'overlay-panel',
      });
      await dialogRef
         .afterClosed()
         .toPromise()
         .then(res => {
            if (res?.event !== 'cancel' && res !== undefined) {
               return this.saveCurrentData();
            }
         });
      return true;
   }

   async handleCloseCase({ status, res }) {
      const addNewRecordRequered = await this.addNewRecord(null);
      if (addNewRecordRequered) {
         let body = null;
         const dialogRef = this.dialog.open(SubjectDocumentsDialogComponent, {
            width: ModalSizeEnum.DEFAULT,
            panelClass: 'overlay-panel',
         });
         dialogRef.afterClosed().subscribe(({ event, ...rest }) => {
            this.caseService.addConclusionFinishCase(res.fileToUpload, this.subjectId, this.caseData.id).subscribe(
               (documentId: string) => {
                  this.toastr.success('SNACKBAR.DOCUMENT_ADDED');
                  this.expedition.send({ documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'vs');
                  this.localStorageService.set('case', true);
               },
               error => {
                  manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
               }
            );
         });
      }
   }
}
