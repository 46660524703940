// src/app/version-check.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { LocalStorageService } from 'src/app/local-storage.service';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalSizeEnum } from 'src/types';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
   providedIn: 'root',
})
export class VersionCheckService {
   constructor(private http: HttpClient, private localStorageService: LocalStorageService, private dialog: MatDialog, private translate: TranslateService) {}
   dialogOpen = false;
   public initVersionCheck(url: string, intervalMs: number = 60000): void {
      // Check version every X ms
      this.checkVersion(url);
      interval(intervalMs).subscribe(() => {
         this.checkVersion(url);
      });
   }

   private checkVersion(url: string): void {
      if (this.dialogOpen) {
         return;
      }
      this.http.get(url + '?t=' + new Date().getTime()).subscribe((res: any) => {
         const latestVersion = res.version;
         const storedVersion = this.localStorageService.get('appVersion');
         if (storedVersion && storedVersion !== latestVersion) {
            this.promptUser(latestVersion);
         } else if (!storedVersion) {
            this.localStorageService.set('appVersion', latestVersion);
         }
      });
   }

   private promptUser(latestVersion: any): void {
      this.dialogOpen = true;
      let message = '';
      let title = '';
      this.translate.get('WARNING_DIALOG.NEW_VERSION.TITLE').subscribe((res: string) => {
         title = res;
      });
      this.translate.get('WARNING_DIALOG.NEW_VERSION.MESSAGE').subscribe((res: string) => {
         message = res;
      });
      const object = {
         message,
         title,
         singleButtonMode: true,
      };
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         data: object,
         panelClass: 'overlay-panel',
      });
      dialogRef.afterClosed().subscribe(() => {
         setTimeout(() => {
            this.localStorageService.set('appVersion', latestVersion);
            window.location.reload(true);
         }, 50000);
      });
   }
}
