<form class="form" [formGroup]="newRecord" (ngSubmit)="addNewRecord(newRecord)">
  <form-group
    title="{{ 'NEW_DEPENDENT.NEW_RECORD' | translate }}"
    isLast="true"
  >
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.COURT_NAME' | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="courtName"
          required
          autocomplete="off"
          maxlength="255"
        />
        <mat-error *ngIf="newRecord.get('courtName').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.COURT_NUMBER' | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="courtNumber"
          required
          autocomplete="off"
          maxlength="255"
        />
        <mat-error
          *ngIf="newRecord.get('courtNumber').hasError('required')"
        >
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.COURT_DATE' | translate
          }}</mat-label>
        <input
          matInput
          [matDatepicker]="courtDate"
          [max]="currentDate"
          formControlName="courtDate"
          required
          autocomplete="off"
          maxlength="255"
          name="dateFormat"
          (input)="checkInputDateFormat('courtDate', $event)" (blur)="onBlur('courtDate')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="courtDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #courtDate></mat-datepicker>
        <mat-error *ngIf="newRecord.get('courtDate').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="newRecord.get('courtDate').hasError('maxDateExceeded')">
          {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
        </mat-error>
        <mat-error *ngIf="newRecord.get('courtDate').hasError('invalidFormat')">
          {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.DATE_OF_VERDICT_TERMINATION' | translate
        }}</mat-label>
        <input
          matInput
          [matDatepicker]="dateOfVerdictTermination"
          [max]="currentDate"
          formControlName="dateOfVerdictTermination"
          required
          autocomplete="off"
          maxlength="255"
          name="dateFormat"
          (input)="checkInputDateFormat('dateOfVerdictTermination', $event)" (blur)="onBlur('dateOfVerdictTermination')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="dateOfVerdictTermination"
        ></mat-datepicker-toggle>
        <mat-datepicker #dateOfVerdictTermination></mat-datepicker>
        <mat-error
          *ngIf="newRecord.get('dateOfVerdictTermination').hasError('required')"
        >
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="newRecord.get('dateOfVerdictTermination').hasError('maxDateExceeded')">
          {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
        </mat-error>
        <mat-error *ngIf="newRecord.get('dateOfVerdictTermination').hasError('invalidFormat')">
          {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.REASONS_FOR_VERDICT_TERMINATON' | translate
        }}</mat-label>
        <textarea
          matInput
          type="text"
          formControlName="reasonsForVerdictTermination"
          required
          autocomplete="off"
          maxlength="255"
        ></textarea>
        <mat-error
          *ngIf="newRecord.get('reasonsForVerdictTermination').hasError('required')"
        >
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button
      mat-stroked-button
      color="primary"
      class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()"
      type="button"
    >
      {{ 'NEW_DEPENDENT.CANCEL' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="modal-footer__button modal-footer__button--primary"
      type="submit"
      [disabled]="!newRecord.valid"
    >
      {{ 'NEW_DEPENDENT.SAVE' | translate }}
    </button>
  </form-footer>
</form>
