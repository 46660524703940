/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { MatDialog } from '@angular/material/dialog';
import { CodebookService } from 'src/services/codebook.service';
import { ProtegePersonService } from 'src/services/protege-person.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SubjectsService } from 'src/services/subjects.service';
import { DatePipe } from '@angular/common';

enum AutocompleteFilterEnum {
   CITIZENSHIP = 'citizenship',
   NATIONALITY = 'nationality',
   OCCUPATION = 'occupation',
}

@Component({
   selector: 'new-protege-personal-information-step',
   templateUrl: './new-protege-personal-information-step.component.html',
   styleUrls: ['./new-protege-personal-information-step.component.scss'],
})
export class NewProtegePersonalInformationStepComponent implements OnInit {
   @Output() personalInformationStepEmitter = new EventEmitter();
   @Input() personalInformationStep: FormGroup;
   @Input() protegePersonId: any;
   @Input() subjectId: any;
   @Input() year: any;
   isAdult: boolean;
   protegePerson: any;
   currentDate = new Date();
   occupationOptions: any = [];
   accommodations: any = [];
   nationalityOptions: any = [];
   citizenshipOptions: any = [];
   filteredCitizenshipOptions: Observable<any[]>;
   filteredOccupationOptions: Observable<any[]>;
   filteredNationalityOptions: Observable<any[]>;
   regexStrDate = '^[0-9.]+$';
   regexStrJmbg = '^[0-9]+$';

   constructor(
      private datePipe: DatePipe,
      private subjectService: SubjectsService,
      private protegePersonService: ProtegePersonService,
      private codebookService: CodebookService,
      private dialog: MatDialog,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute
   ) {
      this.getCodebooks();
   }

   ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
         if (params.guardianshipType === 'adult') {
            this.isAdult = true;
         } else {
            this.isAdult = false;
         }
      });

      this.personalInformationStep = this.formBuilder.group({
         firstName: ['', [Validators.required]],
         lastName: ['', [Validators.required]],
         parentFirstName: ['', [Validators.required]],
         parentLastName: [''],
         foreigner: [false],
         jmbg: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(13), Validators.maxLength(13)]],
         placeOfBirth: [''],
         municipality: [null],
         placeOfOrigin: [null],
         citizenship: [null],
         nationality: [null],
         occupation: [null],
         accomodationInfo: [''], //, [Validators.required]],
         accomodationType: ['', [Validators.required]],
         decisionNumberDeprivation: ['', []],
         decisionDateDeprivation: ['', []],
         decisionMakerNameDeprivation: ['', []],
         courtDecisionContent: ['', []],
         decisionNumberGuardianship: ['', []],
         decisionDateGuardianship: ['', []],
         decisionMakerNameGuardianship: ['', []],
         dateOfBirth: [null],
      });

      if (this.subjectId !== null && this.subjectId !== undefined) {
         this.subjectService.getSubject(this.subjectId).subscribe(result => {
            const address =
               String(result.permanentResidence.street ? result.permanentResidence.street : '') +
               String(result.permanentResidence.number ? ' ' + result.permanentResidence.number : '') +
               String(result.permanentResidence.subnumber ? ' ' + result.permanentResidence.subnumber : '') +
               String(result.permanentResidence.town ? ', ' + result.permanentResidence.town : '') +
               String(result.permanentResidence.street && result.residence.street ? '/' : '') +
               String(result.residence.street ? result.residence.street : '') +
               String(result.residence.number ? ' ' + result.residence.number : '') +
               String(result.residence.subnumber ? ' ' + result.residence.subnumber : '') +
               String(result.residence.town ? ', ' + result.residence.town : '');
            this.personalInformationStep.patchValue({
               placeOfBirth: result.placeOfBirth,
               placeOfOrigin: address,
               citizenship: result.citizenship,
               nationality: result.nationality,
               occupation: result.occupation,
               municipality: result.permanentResidence.town,
               foreigner: result.foreigner,
            });
         });
      }
      this.protegePersonService.findByIdAndCsrId(this.protegePersonId, this.year != null && this.year != undefined ? this.year : new Date().getFullYear()).subscribe(result => {
         this.protegePerson = result;
         this.personalInformationStep.patchValue({
            firstName: result.firstName,
            lastName: result.lastName,
            parentFirstName: result.fatherFirstName,
            jmbg: result.jmbg,
            dateOfBirth: result.dateOfBirth ? new Date(result.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : null,
         });
         this.personalInformationStep.controls.dateOfBirth.markAsTouched();
      });
   }

   /**
    * Send step information to parent component
    */
   updatePersonalInformationStep() {
      this.personalInformationStepEmitter.emit(this.personalInformationStep);
   }

   importSubjectData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const address =
               String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
               String(result.data.permanentResidence.number ? ' ' + result.data.permanentResidence.number : '') +
               String(result.data.permanentResidence.subnumber ? ' ' + result.data.permanentResidence.subnumber : '') +
               String(result.data.permanentResidence.town ? ', ' + result.data.permanentResidence.town : '') +
               String(result.data.permanentResidence.street && result.data.residence.street ? '/' : '') +
               String(result.data.residence.street ? result.data.residence.street : '') +
               String(result.data.residence.number ? ' ' + result.data.residence.number : '') +
               String(result.data.residence.subnumber ? ' ' + result.data.residence.subnumber : '') +
               String(result.data.residence.town ? ', ' + result.data.residence.town : '');

            this.personalInformationStep.patchValue({
               firstName: result.data.firstName,
               lastName: result.data.lastName,
               parentFirstName: result.data.parentName,
               foreigner: result.data.foreigner,
               jmbg: result.data.jmbg,
               dateOfBirth: result.data.dateOfBirth ? new Date(result.data.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')) : null,
               placeOfBirth: result.data.placeOfBirth,
               placeOfOrigin: address,
               citizenship: result.data.citizenship,
               nationality: result.data.nationality,
               occupation: result.data.occupation,
               municipality: result.data.permanentResidence.town,
            });
            this.personalInformationStep.controls.dateOfBirth.markAsTouched();
         }
      });
   }

   getCodebooks() {
      this.codebookService.getOccupationCodebook().subscribe(res => {
         this.occupationOptions = res;
         this.filteredOccupationOptions = this.personalInformationStep.controls.occupation.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION) : this.occupationOptions.slice()))
         );
      });
      this.codebookService.getNationalityCodebook().subscribe(res => {
         this.nationalityOptions = res;
         this.filteredNationalityOptions = this.personalInformationStep.controls.nationality.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.NATIONALITY) : this.nationalityOptions.slice()))
         );
      });
      this.codebookService.getCitizenshipCodebook().subscribe(res => {
         this.citizenshipOptions = res;
         this.filteredCitizenshipOptions = this.personalInformationStep.controls.citizenship.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice()))
         );
      });

      this.codebookService.getProtegeAccommodationCodebook().subscribe(res => {
         this.accommodations = res;
      });
   }

   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();

      switch (what) {
         case AutocompleteFilterEnum.CITIZENSHIP:
            return this.citizenshipOptions.filter((option: any) => option.title.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.NATIONALITY:
            return this.nationalityOptions.filter((option: any) => option.title.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.OCCUPATION:
            return this.occupationOptions.filter((option: any) => option.title.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.title : '';
   }

   updateJMBGOnDateOfBirthChange() {
      if (this.personalInformationStep.value.foreigner) {
         return;
      }
      if (this.personalInformationStep.value.dateOfBirth !== null && this.personalInformationStep.value.jmbg !== null) {
         const birthDate = new Date(this.personalInformationStep.value.dateOfBirth).getTime();
         const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
         const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);

         if (this.personalInformationStep.value.jmbg.length === 13) {
            const jmbg = this.personalInformationStep.value.jmbg.substring(0, 7);
            if (newBirthDate !== jmbg) {
               this.personalInformationStep.get('dateOfBirth').setErrors({ incorrect: true });
            } else {
               this.personalInformationStep.get('dateOfBirth').setErrors(null);
            }
         }
      }
   }

   checkNationality() {
      if (this.personalInformationStep.value.nationality !== undefined && this.personalInformationStep.value.nationality !== null && this.personalInformationStep.value.nationality !== '') {
         const choosedValue = this.personalInformationStep.value.nationality;

         let result = this.nationalityOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            this.personalInformationStep.controls.nationality.setErrors({ notValid: true });
         } else {
            this.personalInformationStep.controls.nationality.setValue(result);
            this.personalInformationStep.controls.nationality.setErrors(null);
         }
      }
   }

   checkCitizenship() {
      if (this.personalInformationStep.value.citizenship !== undefined && this.personalInformationStep.value.citizenship !== null && this.personalInformationStep.value.citizenship !== '') {
         const choosedValue = this.personalInformationStep.value.citizenship;

         let result = this.citizenshipOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            this.personalInformationStep.controls.citizenship.setErrors({ notValid: true });
         } else {
            this.personalInformationStep.controls.citizenship.setValue(result);
            this.personalInformationStep.controls.citizenship.setErrors(null);
         }
      }
   }

   checkOccupation() {
      if (this.personalInformationStep.value.occupation !== undefined && this.personalInformationStep.value.occupation !== null && this.personalInformationStep.value.occupation !== '') {
         const choosedValue = this.personalInformationStep.value.occupation;

         let result = this.occupationOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            this.personalInformationStep.controls.occupation.setErrors({ notValid: true });
         } else {
            this.personalInformationStep.controls.occupation.setValue(result);
            this.personalInformationStep.controls.occupation.setErrors(null);
         }
      }
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.personalInformationStep.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);

      if (inputDate > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.personalInformationStep.get(controlName);
      if (!control) return;
    
      const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement;
      const inputValue = inputElement?.value || '';
      const dateValue = control.value;
    
      if (inputValue && !dateValue) {
        control.setErrors({ invalidFormat: true });
        return;
      }
    
      if (dateValue && dateValue > this.currentDate) {
        control.setErrors({ maxDateExceeded: true });
      }
    }

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      } else if (fieldName === 'jmbg') {
         return new RegExp(this.regexStrJmbg).test(event.key);
      }
   }
}
