<container>
  <mat-card class="card">
    <card-header title="{{ 'STATISTICS.NSP_STATS.TITLE' | translate }}">
    </card-header>
    <form [formGroup]="nspStatsForm" class="form">
      <field-holder *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
        <mat-form-field appearance="outline" (click)="selectMulti()">
          <mat-label> {{'GLOBAL.CENTER' | translate}}</mat-label>
          <mat-select formControlName="center" multiple required #multiSelect>
            <input type="text" class="multi-input" autocomplete="off" matInput
              placeholder="{{'GLOBAL.SEARCH_CENTERS' | translate}}" #multiSearch (focus)="multiSelect.disabled = true"
              (focusout)="multiSelect.disabled = false" (input)="onInputChange($event.target.value)" />
            <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">
              {{ 'GLOBAL.ALL_CENTERS' | translate }}
            </mat-option>
            <mat-option *ngFor="let value of filteredAllCenters" [value]="value?.id" (click)="tosslePerOneCenter()">
              {{ value.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="nspStatsForm.get('center').hasError('required')">
            {{ "GLOBAL.CENTER_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'STATISTICS.NSP_STATS.RECORD' | translate }}</mat-label>
          <mat-select formControlName="record" required>
            <mat-option *ngFor="let record of recordOptions" [value]="record">
              {{ record.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.REPORTING_PERIOD_FROM' | translate }}</mat-label>
          <input matInput type="text" [matDatepicker]="dateFrom" formControlName="dateFrom" autocomplete="off"
            placeholder="{{ 'STATISTICS.REPORTING_PERIOD_FROM' | translate }}" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
          <mat-datepicker #dateFrom></mat-datepicker>
          <mat-error *ngIf="nspStatsForm.get('dateFrom').hasError('required')">
            {{ "STATISTICS.DATE_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.REPORTING_PERIOD_TO' | translate }}</mat-label>
          <input matInput type="text" [matDatepicker]="dateTo" formControlName="dateTo" autocomplete="off"
            placeholder="{{ 'STATISTICS.REPORTING_PERIOD_TO' | translate }}" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
          <mat-datepicker #dateTo></mat-datepicker>
          <mat-error *ngIf="nspStatsForm.get('dateTo').hasError('required')">
            {{ "STATISTICS.DATE_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>

      <form-footer>
        <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
          [disabled]="!this.nspStatsForm.valid || btnDisabled" (click)="sendRequest(nspStatsForm)">
          {{ 'STATISTICS.SUBMIT' | translate }}
        </button>
        <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </form-footer>
    </form>
  </mat-card>
</container>