/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { User } from 'src/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { CaseService } from 'src/services/case.service';
import { MatDialog } from '@angular/material/dialog';
import { DataSharingService } from 'src/services/data-sharing.service';
import { LocalStorageService } from '../local-storage.service';
import { CodebookService } from 'src/services/codebook.service';
import { UserService } from 'src/services/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { Observable } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';

const groupCasesByCaseKindCodebookId = (cases, codebook) =>
   codebook.reduce((acc, value) => {
      acc[value.name] = cases.filter(item => item.caseKindCodebook.id === value.id);
      return acc;
   }, {});
const groupCasesByAssigneStatus = (cases, status) =>
   status.reduce((acc, value) => {
      const nameOfGroup =
         value === 'ASSIGNED' ? 'Нови додељени' : value === 'FOWARDED' ? 'Нови прослеђени' : value === 'RETURNED' ? 'Нови враћени' : 'Поново у обради';
      acc[nameOfGroup] = cases.filter(item => item.assignStatus === value);
      return acc;
   }, {});

@Component({
   selector: 'app-cases',
   templateUrl: './cases.component.html',
   styleUrls: ['./cases.component.scss'],
})
export class CasesComponent implements OnInit {
   @ViewChild('allCategoriesSelected') private allCategoriesSelected: MatOption;
   @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
   @Input() events: Observable<void>;
   searchForm: FormGroup;
   filterForm: FormGroup;
   errorMessage: boolean;
   cases = [];
   loggedIn: User;
   isUserEntrancer = false;
   caseKindCodebook: any;
   filteredCases = {};
   filteredCategories = [];
   filteredCasesList = [];

   newly = {};
   assignStatuses = ['ASSIGNED', 'FOWARDED', 'RETURNED', 'RETURNED_PISARNICA'];

   object: any = {
      caseId: '',
      userId: '',
   };
   submissions: any = [];
   primaryRole = 0;
   badgeCount: number = 0;

   objectKeys(obj) {
      return Object.keys(obj);
   }

   constructor(
      public translate: TranslateService,
      private casesService: CaseService,
      private formBuilder: FormBuilder,
      public dialog: MatDialog,
      private cdr: ChangeDetectorRef,
      private dataSharingService: DataSharingService,
      private localStorageService: LocalStorageService,
      private codebookService: CodebookService,
      private userService: UserService
   ) {
      const isUserWorker = this.userService.isUserWorker();
      const isUserLawyer = this.userService.isUserLawyer();
      const primaryRole = this.localStorageService.get('primaryRole');

      if (primaryRole !== null) {
         if (primaryRole === ' Правник') {
            this.primaryRole = 2;
         } else if (primaryRole === ' Стручни радник') {
            this.primaryRole = 1;
         } else {
            this.primaryRole = 0;
         }
      }

      this.dataSharingService.cases.subscribe(value => {
         if (isUserWorker || isUserLawyer) {
            this.casesService.getCases(this.primaryRole).subscribe(res => {
               this.cases = res;
               this.searchCases(this.searchForm.value.casesTerm);
            });
         }
      });
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isUserEntrancer = this.userService.isUserEntrancer();
   }

   ngOnInit(): void {
      this.searchForm = this.formBuilder.group({
         casesTerm: [''],
      });

      this.filterForm = this.formBuilder.group({
         selectedFilter: ['all'],
         categories: [],
      });
      this.events.subscribe(() => {
         this.resetFilter();
      });
   }

   toggleAllCategories() {
      if (this.allCategoriesSelected.selected) {
         this.filterForm.controls.categories.patchValue([...this.filteredCategories.map(item => item), 0]);
      } else {
         this.filterForm.controls.categories.patchValue([]);
      }
   }

   togglePerOneCategory() {
      if (this.allCategoriesSelected.selected) {
         this.allCategoriesSelected.deselect();
         return false;
      }
      if (this.filterForm.controls.categories.value.length === this.filteredCategories.length) {
         this.allCategoriesSelected.select();
      }
   }

   searchCases(term: string) {
      this.filteredCasesList = this.cases;
      term = term.trim().toLowerCase();
      this.filteredCasesList = this.filteredCasesList.filter((caseItem: any) => {
         const name = (caseItem.firstName + ' ' + caseItem.lastName).toLowerCase();
         return caseItem.caseNumber.includes(term) || name.includes(term);
      });
      this.applyFilter();
   }

   clear() {
      this.searchForm.controls.casesTerm.setValue('');
      this.searchCases('');
   }
   isUserAdmin() {
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      if (this.loggedIn !== null) {
         if (this.userService.isUserManager()) {
            return true;
         }
      }
   }

   applyFilter() {
      let newCases = this.filteredCasesList;
      this.badgeCount = 0;
      const filter = this.filterForm.controls.selectedFilter.value;
      const isAssigned = filter === 'assigned';
      const isAccepted = filter === 'accepted';
      const isTeamMember = filter === 'member';
      if (isAssigned) {
         this.badgeCount++;
         newCases = newCases.filter((caseItem: any) => {
            return caseItem.caseStatus === 'ASSIGNED';
         });
      }
      if (isAccepted) {
         this.badgeCount++;
         newCases = newCases.filter((caseItem: any) => {
            return caseItem.caseStatus === 'ACCEPTED';
         });
      }
      if (isTeamMember) {
         this.badgeCount++;
         newCases = newCases.filter((caseItem: any) => {
            return caseItem.assignedUserId !== this.loggedIn.id;
         });
      } else if (isAccepted || isAssigned) {
         newCases = newCases.filter((caseItem: any) => {
            return caseItem.assignedUserId === this.loggedIn.id;
         });
      }
      const categories = this.filterForm.get('categories').value;
      if (categories == null || categories.length === 0 || categories.includes(0)) {
         this.groupCases(newCases);
      } else {
         this.groupCases(newCases.filter(caseItem => categories.includes(caseItem.caseKindCodebook.name)));
         this.badgeCount++;
      }
      this.trigger.closeMenu();
   }

   resetFilter() {
      this.filteredCasesList = this.cases;
      this.badgeCount = 0;
      this.filterForm.reset({
         selectedFilter: 'all',
         categories: [],
      });
      this.trigger.closeMenu();
      this.searchCases(this.searchForm.value.casesTerm);
   }

   groupCases(cases: any) {
      this.filteredCasesList = cases;
      if (this.caseKindCodebook === null || this.caseKindCodebook === undefined) {
         this.codebookService.getCaseKindCodebook().subscribe(res => {
            this.caseKindCodebook = res.sort((a, b) => {
               return a.name.localeCompare(b.name);
            });
            this.filteredCases = groupCasesByCaseKindCodebookId(cases, this.caseKindCodebook);
            this.formatNewlyAssigned(cases);
            const groupCasesTypes = this.objectKeys(this.filteredCases);
            this.filteredCategories = groupCasesTypes.filter(key => this.filteredCases[key].length > 0);
            this.cdr.detectChanges();
         });
      } else {
         this.filteredCases = groupCasesByCaseKindCodebookId(cases, this.caseKindCodebook);
         this.formatNewlyAssigned(cases);
         const groupCasesTypes = this.objectKeys(this.filteredCases);
         this.filteredCategories = groupCasesTypes.filter(key => this.filteredCases[key].length > 0);
         this.cdr.detectChanges();
      }
   }

   formatNewlyAssigned(cases: any) {
      this.newly = groupCasesByAssigneStatus(cases, this.assignStatuses);
      this.filteredCases = { ...this.newly, ...this.filteredCases };
   }

   markAsRead(caseData: any) {
      if (caseData.assignStatus !== 'READ') {
         this.casesService.markAsReadCase(caseData.id).subscribe(res => {
            for (let item of this.cases) {
               if (caseData.id === item.id) {
                  item.assignStatus = 'READ';
               }
            }
            this.groupCases(this.cases);
         });
      }
   }
}
