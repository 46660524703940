/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/internal/operators/finalize';
import { LocalStorageService } from 'src/app/local-storage.service';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { Form5AddUserComment } from 'src/models/form5AddUserComment.model';
import { Form5ParticipationUser } from 'src/models/form5ParticipationUser.model';
import { Form5Task } from 'src/models/form5Task.model';
import { CodebookService } from 'src/services/codebook.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { VsService } from 'src/services/vs.service';
import { ModalSizeEnum } from 'src/types';
import { AddUserCommentComponent } from './add-user-comment/add-user-comment.component';
import { AddUsersComponent } from './add-users/add-users.component';
import { TasksComponent } from './tasks/tasks.component';
/**
 *
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 */
@Component({
   selector: 'app-form5',
   templateUrl: './form5.component.html',
   styleUrls: ['./form5.component.scss'],
})
export class Form5Component implements OnInit {
   @Input() isCaseClosed: boolean;
   @Input() subjectId: any;
   @Input() caseId: any;
   @Output() handleUpdatePlan = new EventEmitter();
   @Output() handleSaveChanges = new EventEmitter();
   @ViewChild('submitButton') submitButton;
   @ViewChild('saveButton') saveButton;

   servicePlan: FormGroup;
   participationPlan: FormGroup;
   userComment: FormGroup;
   evaluationPlan: FormGroup;
   form5Data: any;
   form5Saved: any;
   editable: any;
   subject: any;
   caseData: any;
   caseName: any;
   // Service Plan
   templatedTasks: any = [];
   servicePlanList: any;
   displayedColumnsServicePlan: string[] = ['outcome', 'task', 'responsiblePerson', 'fromDate', 'toDate', 'action'];

   // Participation Plan
   templatedUsers = [];
   displayedColumnsParticipationPlan: string[] = ['firstAndLastName', 'relationship', 'phone', 'action'];

   // User Comment
   templatedComments = [];
   minToDate = new Date();
   displayedColumnsComments: string[] = ['comment', 'commentDate', 'relationship', 'action'];

   // Evaluation Plan
   yesNoOptions = [
      { value: true, viewValue: 'Да' },
      { value: false, viewValue: 'Не' },
   ];

   loggedIn: any;
   hasSupervisor = false;
   maxDate: Date = new Date(2999, 11, 31);

   constructor(
      private subjectService: SubjectsService,
      public dialog: MatDialog,
      private vsService: VsService,
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private translate: TranslateService,
      private localStorageService: LocalStorageService,
      private datePipe: DatePipe,
      private toastr: ToastrImplService,
      private userService: UserService
   ) {
      this.editable = true;

      this.servicePlan = this.formBuilder.group({
         id: [''],
         plan: [
            /*, Validators.required*/
         ],
         areasOfWork: ['' /*, Validators.required*/],
         strength: ['' /*, Validators.required*/],
         goal: ['' /*, Validators.required*/],
         notes: ['' /*, Validators.required*/],
         firstAndLastName: [''],
         regBr: [''],
         plannedTasks: [
            /*, Validators.required*/
         ],
      });

      // Participation Plan
      this.participationPlan = this.formBuilder.group({
         participationUsers: [
            /*, Validators.required*/
         ],
      });

      // User Comment
      this.userComment = this.formBuilder.group({
         comment: ['' /*, Validators.required*/],
         commentDate: ['' /*, Validators.required*/],
         participationUsersComments: [
            /*, Validators.required*/
         ],
      });

      // Evaluation Plan

      this.evaluationPlan = this.formBuilder.group({
         toDate: ['' /*[Validators.required]*/],
         evaluationMethod: [''],
         note: [''],
         copyPlan: [''],
         needsCannotBeMet: [''],
         steps: [''],
      });

      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));

      this.userService.hasSupervisor(JSON.parse(this.localStorageService.get('loggedUser')).id).subscribe(result => {
         if (result) {
            this.hasSupervisor = true;
         } else {
            this.hasSupervisor = false;
         }
      });
   }

   ngOnInit(): void {
      this.subjectService.getCurrentSubject(this.subjectId, this.loggedIn?.csrId).subscribe(res => {
         this.subject = res;
         this.servicePlan.controls.firstAndLastName.setValue(this.subject.firstName + ' ' + this.subject.lastName);
         this.servicePlan.controls.regBr.setValue(this.subject.registrationId);
         this.servicePlan.controls.regBr.disable();
         this.servicePlan.controls.firstAndLastName.disable();
      });

      // Service Plan
      this.getPlanKindCodebook();

      this.vsService.getForm5(this.caseId).subscribe(res => {
         if (res !== null && res !== undefined) {
            this.form5Saved = res;
            this.servicePlan.patchValue({
               id: this.form5Saved.id,
               plan: this.form5Saved.planKindCodebook,
               areasOfWork: this.form5Saved.areasOfWork,
               strength: this.form5Saved.strength,
               goal: this.form5Saved.goal,
               notes: this.form5Saved.notes,
               plannedTasks: this.form5Saved.plannedTasks,
            });
            if (this.form5Saved.plannedTasks !== null) {
               this.templatedTasks = this.form5Saved.plannedTasks;
            }
            this.participationPlan.patchValue({
               participationUsers: this.form5Saved.participationUsers,
            });
            if (this.form5Saved.participationUsers !== null) {
               this.templatedUsers = this.form5Saved.participationUsers;
            }
            this.userComment.patchValue({
               comment: this.form5Saved.userComment,
               commentDate: this.form5Saved.userCommentDate,
               participationUsersComments: this.form5Saved.participationUsersComments,
            });
            if (this.form5Saved.participationUsersComments != null) {
               this.templatedComments = this.form5Saved.participationUsersComments;
            }
            this.evaluationPlan.patchValue({
               toDate: this.form5Saved.evaluationPlanDate,
               evaluationMethod: this.form5Saved.evaluationMethod,
               note: this.form5Saved.evaluationNote,
               copyPlan: this.form5Saved.evaluationCopyPlan,
               needsCannotBeMet: this.form5Saved.evaluationNoteNeedsCannotBeMet,
               steps: this.form5Saved.evaluationNoteSteps,
            });
         }
      });
   }

   ///////////////////////////////// ServicePlan

   comparePlan(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }

   getPlanKindCodebook() {
      this.codebookService.getPlanKindCodebook().subscribe(res => {
         this.servicePlanList = res;
      });
   }

   addTask() {
      const dialogRef = this.dialog.open(TasksComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: null,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.templatedTasks.push(new Form5Task(data.outcome, data.task, data.responsiblePerson, data.fromDate, data.toDate));
            this.templatedTasks = [...this.templatedTasks];
            this.servicePlan.get('plannedTasks').setValue(this.templatedTasks);
         }
      });
   }

   deleteTask(i: any) {
      this.templatedTasks.splice(i, 1);
      this.templatedTasks = [...this.templatedTasks];
   }

   importSubjectData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            this.servicePlan.controls.firstAndLastName.setValue(result.data.firstName + ' ' + result.data.lastName);
            this.servicePlan.controls.regBr.setValue(result.data.registrationId);
         }
      });
   }

   form5RequestBody() {
      if (this.servicePlan !== undefined && this.servicePlan.value.plannedTasks !== null) {
         this.servicePlan.value.plannedTasks.forEach((element: any) => {
            element.fromDate =
               element.fromDate === '' || element.fromDate === null ? '' : this.datePipe.transform(new Date(element.fromDate).getTime(), 'yyyy-MM-dd');
            element.toDate = element.toDate === '' || element.toDate === null ? '' : this.datePipe.transform(new Date(element.toDate).getTime(), 'yyyy-MM-dd');
         });
      }
      if (this.userComment !== undefined && this.userComment.value.participationUsersComments !== null) {
         this.userComment.value.participationUsersComments.forEach((element: any) => {
            element.commentDate =
               element.commentDate === '' || element.commentDate === null ? '' : this.datePipe.transform(new Date(element.commentDate).getTime(), 'yyyy-MM-dd');
            element.relationship = element.relationship === '' ? null : element.relationship;
         });
      }
      if (this.participationPlan !== undefined && this.participationPlan.value.participationUsers !== null) {
         this.participationPlan.value.participationUsers.forEach((element: any) => {
            element.relationship = element.relationship === '' ? null : element.relationship;
         });
      }

      this.form5Data = {
         id: this.servicePlan !== undefined ? this.servicePlan.value.id : null,
         planKindCodebook: this.servicePlan !== undefined ? this.servicePlan.value.plan : null,
         areasOfWork: this.servicePlan !== undefined ? this.servicePlan.value.areasOfWork : null,
         strength: this.servicePlan !== undefined ? this.servicePlan.value.strength : null,
         goal: this.servicePlan !== undefined ? this.servicePlan.value.goal : null,
         notes: this.servicePlan !== undefined ? this.servicePlan.value.notes : null,
         plannedTasks: this.servicePlan !== undefined ? this.servicePlan.value.plannedTasks : null,
         participationUsers: this.participationPlan !== undefined ? this.participationPlan.value.participationUsers : null,
         userComment: this.userComment !== undefined ? this.userComment.value.comment : null,
         userCommentDate:
            this.userComment !== undefined
               ? this.userComment.value.commentDate !== '' && this.userComment.value.commentDate !== null
                  ? this.datePipe.transform(new Date(this.userComment.value.commentDate).getTime(), 'yyyy-MM-dd')
                  : ''
               : '',
         participationUsersComments: this.userComment !== undefined ? this.userComment.value.participationUsersComments : null,
         evaluationPlanDate:
            this.evaluationPlan !== undefined
               ? this.evaluationPlan.value.toDate !== '' && this.evaluationPlan.value.toDate !== null
                  ? this.datePipe.transform(new Date(this.evaluationPlan.value.toDate).getTime(), 'yyyy-MM-dd')
                  : ''
               : '',
         evaluationMethod: this.evaluationPlan !== undefined ? this.evaluationPlan.value.evaluationMethod : null,
         evaluationNote: this.evaluationPlan !== undefined ? this.evaluationPlan.value.note : null,
         evaluationCopyPlan: this.evaluationPlan !== undefined ? this.evaluationPlan.value.copyPlan : null,
         evaluationNoteNeedsCannotBeMet: this.evaluationPlan !== undefined ? this.evaluationPlan.value.needsCannotBeMet : null,
         evaluationNoteSteps: this.evaluationPlan !== undefined ? this.evaluationPlan.value.steps : null,
      };
   }
   // Cuvanje trenutno uradjenog posla. Resenje nece biti izgenerisano.
   saveForm5() {
      this.form5RequestBody();
      this.vsService
         .saveForm5(this.caseId, this.form5Data)
         .pipe(
            finalize(() => {
               // this.handleUpdatePlan.emit();
            })
         )
         .subscribe(
            result => {
               this.toastr.success('SNACKBAR.FORM_SAVED');
               this.handleUpdatePlan.emit();
               this.handleSaveChanges.emit(false);
            },
            error => {
               this.toastr.error('SNACKBAR.ERROR_FORM_SAVING');
               this.submitButton.disabled = false;
               this.saveButton.disabled = false;
            }
         );
   }

   preview() {
      this.form5RequestBody();
      this.vsService.previewForm5(this.form5Data, this.caseId);
   }
   ///////////////////////////////// Participation plan
   addUser() {
      const dialogRef = this.dialog.open(AddUsersComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: {},
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.templatedUsers.push(new Form5ParticipationUser(data.firstAndLastName, data.relationship, data.phone));
            this.templatedUsers = [...this.templatedUsers];
         }
         this.participationPlan.get('participationUsers').setValue(this.templatedUsers);
      });
   }

   deleteUser(i: any) {
      this.templatedUsers.splice(i, 1);
      this.templatedUsers = [...this.templatedUsers];
   }

   ///////////////////////////// User Comment
   addUserComment() {
      const dialogRef = this.dialog.open(AddUserCommentComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: null,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.templatedComments.push(new Form5AddUserComment(data.comment, data.commentDate, data.relationship));
            this.templatedComments = [...this.templatedComments];
            this.userComment.get('participationUsersComments').setValue(this.templatedComments);
         }
      });
   }

   deleteComment(i: any) {
      this.templatedComments.splice(i, 1);
      this.templatedComments = [...this.templatedComments];
   }

   ///////////////////////////// Evaluation Plan
   // Zavrsetak rada na formi - generisanje resenja
   submitForm5() {
      this.form5RequestBody();
      this.vsService
         .submitForm5(this.caseId, this.form5Data)
         .subscribe(
            result => {
               this.submitButton.disabled = false
               this.saveButton.disabled = false;
               this.toastr.success('SNACKBAR.DOCUMENT_SENT_SUPERVISOR');
               this.handleSaveChanges.emit(true);
            },
            error => {
               this.toastr.error('SNACKBAR.ERROR_RECORDING_AND_GENERETING_DECISION');
               this.submitButton.disabled = false
               this.saveButton.disabled = false;
            }
         );
   }
   //////////////////////////////

   closeForm() {
      this.handleUpdatePlan.emit();
   }

   checkDate(control: string, event: any) {
      if (event.value === null) {
         if (control === 'commentDate') {
            this.userComment.controls.commentDate.reset();
         } else {
            this.evaluationPlan.controls.toDate.reset();
         }
      }
   }

   editTask(i: any) {
      const dialogRef = this.dialog.open(TasksComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: this.templatedTasks[i],
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Edit') {
            this.templatedTasks[i] = new Form5Task(data.outcome, data.task, data.responsiblePerson, data.fromDate, data.toDate);
            this.templatedTasks = [...this.templatedTasks];
         }
      });
   }
   editUserComment(i: any) {
      const dialogRef = this.dialog.open(AddUserCommentComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: this.templatedComments[i],
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Edit') {
            this.templatedComments[i] = new Form5AddUserComment(data.comment, data.commentDate, data.relationship);
            this.templatedComments = [...this.templatedComments];
         }
      });
   }
}
