/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
   selector: 'new-foster-parent-center-step',
   templateUrl: './new-foster-parent-center-step.component.html',
   styleUrls: ['./new-foster-parent-center-step.component.scss'],
})
export class NewFosterParentCenterStepComponent implements OnInit {
   @Output() centerStepEmitter = new EventEmitter();
   @Input() centerStep: FormGroup;
   @Input() familyInformationStep: FormGroup;
   @Input() personalInformationStep: FormGroup;
   @Input() reasonsParentalAbsenceStep: FormGroup;
   @Input() closeMembers: FormGroup;
   @Input() costCarriersStep: FormGroup;
   @Input() suitabilityStep: FormGroup;
   currentDate = new Date();
   regexStrDate = '^[0-9.]+$';

   constructor(private datePipe: DatePipe, private formBuilder: FormBuilder) {}

   ngOnInit(): void {
      this.centerStep = this.formBuilder.group({
         guardianshipName: ['', [Validators.required]],
         address: [''],
         phone: ['', [Validators.minLength(9), Validators.maxLength(13), Validators.pattern('^[0-9]*$')]],
         emailAddress: ['', [Validators.email]],
         questionnaireNumber: [''],
         questionnaireDate: [''],
      });
   }

   /**
    * Send step information to parent component
    */
   updateCenterStep() {
      this.centerStepEmitter.emit(this.centerStep);
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.centerStep.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);

      if (inputDate > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.centerStep.get(controlName);
      if (!control) return;
    
      const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement;
      const inputValue = inputElement?.value || '';
      const dateValue = control.value;
    
      if (inputValue && !dateValue) {
        control.setErrors({ invalidFormat: true });
        return;
      }
    
      if (dateValue && dateValue > this.currentDate) {
        control.setErrors({ maxDateExceeded: true });
      } else {
        control.setErrors(null);
      }
    }
    

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      } 
   }
}
