/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { MatDialog } from '@angular/material/dialog';
import { CodebookService } from 'src/services/codebook.service';
import { ChangeDetectionStrategy } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

enum AutocompleteFilterEnum {
   CITIZENSHIP = 'citizenship',
}
@Component({
   changeDetection: ChangeDetectionStrategy.OnPush,
   selector: 'new-adopted-child-adopters-step',
   templateUrl: './new-adopted-child-adopters-step.component.html',
   styleUrls: ['./new-adopted-child-adopters-step.component.scss'],
})
export class NewAdoptedChildAdoptersStepComponent implements OnInit {
   @Output() adoptersStepEmitter = new EventEmitter();
   @Input() adoptersStep: FormGroup;
   currentDate = new Date();
   citizenshipOptions = [];
   filteredCitizenshipOptionsAdoptiveMother: Observable<any[]>;
   filteredCitizenshipOptionsAdoptiveFather: Observable<any[]>;
   adoptersOptions = [];
   adopters = ['усвојитељка', 'усвојитељ'];

   isAdoptiveMother = false;
   isAdoptiveFather = false;
   regexStrDate = '^[0-9.]+$';

   constructor(private codebookService: CodebookService, private dialog: MatDialog, private formBuilder: FormBuilder) {
      this.getCitizenship();
      this.getAdopters();
   }

   ngOnInit(): void {
      this.adoptersStep = this.formBuilder.group({
         adoptiveMother: this.formBuilder.group({
            firstName: ['', []],
            lastName: ['', []],
            dateOfBirth: ['', []],
            residence: ['', []],
            citizenship: [''],
         }),
         adoptiveFather: this.formBuilder.group({
            firstName: ['', []],
            lastName: ['', []],
            dateOfBirth: ['', []],
            residence: ['', []],
            citizenship: [''],
         }),
         adoptsInfo: this.formBuilder.group({
            adoptersCodebook: ['', [Validators.required]],
         }),
         adopter: ['', [Validators.required]],
      });
   }

   /**
    * Send step information to parent component
    */
   updateAdoptersStep() {
      this.adoptersStepEmitter.emit(this.adoptersStep);
   }

   importSubjectData(mother: any, father: any) {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            if (mother) {
               this.adoptersStep.patchValue({
                  adoptiveMother: {
                     firstName: result.data.firstName,
                     lastName: result.data.lastName,
                     dateOfBirth: result.data.dateOfBirth ? new Date(result.data.dateOfBirth.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')) : null,
                     residence:
                        String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
                        String(result.data.permanentResidence.number ? ' ' : '') +
                        String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
                        String(result.data.permanentResidence.subnumber ? '/' : '') +
                        String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : ''),
                     citizenship: result.data.citizenship,
                  },
               });
            } else {
               this.adoptersStep.patchValue({
                  adoptiveFather: {
                     firstName: result.data.firstName,
                     lastName: result.data.lastName,
                     dateOfBirth: result.data.dateOfBirth ? new Date(result.data.dateOfBirth.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')) : null,
                     residence:
                        String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
                        String(result.data.permanentResidence.number ? ' ' : '') +
                        String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
                        String(result.data.permanentResidence.subnumber ? '/' : '') +
                        String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : ''),
                     citizenship: result.data.citizenship,
                  },
               });
            }
         }
      });
   }

   getCitizenship() {
      this.codebookService.getCitizenshipCodebook().subscribe(res => {
         this.citizenshipOptions = res;
         this.filteredCitizenshipOptionsAdoptiveMother = (this.adoptersStep.get('adoptiveMother') as FormGroup).controls.citizenship.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? null : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice()))
         );
         this.filteredCitizenshipOptionsAdoptiveFather = (this.adoptersStep.get('adoptiveFather') as FormGroup).controls.citizenship.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? null : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice()))
         );
      });
   }

   getAdopters() {
      this.codebookService.getAdoptersCodebook().subscribe(res => {
         this.adoptersOptions = res;
      });
   }

   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }

   adoptersChange() {
      const selectedAdopters = this.adoptersStep.value.adopter;
      if (selectedAdopters.includes('усвојитељка')) {
         this.isAdoptiveMother = true;
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.firstName.setValidators([Validators.required]);
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.lastName.setValidators([Validators.required]);
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.dateOfBirth.setValidators([Validators.required]);
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.residence.setValidators([Validators.required]);
      } else {
         this.isAdoptiveMother = false;
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.firstName.clearValidators();
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.lastName.clearValidators();
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.dateOfBirth.clearValidators();
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.residence.clearValidators();

         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.firstName.setValue(null);
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.lastName.setValue(null);
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.dateOfBirth.setValue(null);
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.residence.setValue(null);
         (this.adoptersStep.controls.adoptiveMother as FormGroup).controls.citizenship.setValue(null);
      }
      if (selectedAdopters.includes('усвојитељ')) {
         this.isAdoptiveFather = true;
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.firstName.setValidators([Validators.required]);
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.lastName.setValidators([Validators.required]);
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.dateOfBirth.setValidators([Validators.required]);
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.residence.setValidators([Validators.required]);
      } else {
         this.isAdoptiveFather = false;
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.firstName.clearValidators();
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.lastName.clearValidators();
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.dateOfBirth.clearValidators();
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.residence.clearValidators();

         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.firstName.setValue(null);
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.lastName.setValue(null);
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.dateOfBirth.setValue(null);
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.residence.setValue(null);
         (this.adoptersStep.controls.adoptiveFather as FormGroup).controls.citizenship.setValue(null);
      }
   }

   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.CITIZENSHIP:
            return this.citizenshipOptions.filter((option: any) => option.title.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.title : '';
   }

   checkCitizenship(type: string) {
      let step;

      if (type === 'adoptiveMother') {
         step = this.adoptersStep.value.adoptiveMother;
      } else {
         step = this.adoptersStep.value.adoptiveFather;
      }
      if (step.citizenship !== undefined && step.citizenship !== null && step.citizenship !== '') {
         const choosedValue = step.citizenship;

         var result = this.citizenshipOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            (this.adoptersStep.get(type) as FormGroup).controls.citizenship.setErrors({ notValid: true });
         } else {
            (this.adoptersStep.get(type) as FormGroup).controls.citizenship.setValue(result);
            (this.adoptersStep.get(type) as FormGroup).controls.citizenship.setErrors(null);
         }
      }
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.adoptersStep.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);

      if (inputDate > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.adoptersStep.get(controlName);
      if (!control) return;

      const inputElement = document.querySelector(`[id="${controlName.replace('.', '-')}"]`) as HTMLInputElement;
      const inputValue = inputElement?.value || '';
      const dateValue = control.value;

      if (!inputValue) {
         control.setErrors({ required: true });
         return;
      }

      if (inputValue && !dateValue) {
         control.setErrors({ invalidFormat: true });
         return;
      }

      if (dateValue && dateValue > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      }
   }

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      }
   }
}
