import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DependentPersonService } from 'src/services/dependent-person.service';

@Component({
  selector: 'app-dependents-details',
  templateUrl: './dependents-details.component.html',
  styleUrls: ['./dependents-details.component.scss']
})
export class DependentsDetailsComponent implements OnInit {
  dependentPersonId: any;
  dependentPerson: any;
  year: any;

  courtJudgement: string[] = [
    'courtName',
    'courtNumber',
    'courtDate',
    'courtValidityDate',
    'dateOfVerdictDelivery',
    'dateOfCommencment',
  ];

  creditor: string[] = [
    'firstAndLastName',
    'dateAndPlaceOfBirth',
    'jmbg',
    'address',
    'legalRelation',
  ];


  representativeCreditor: string[] = [
    'firstAndLastName',
    'dateAndPlaceOfBirth',
    'jmbg',
    'address',
    'legalRelation',
  ];

  debtor: string[] = [
    'firstAndLastName',
    'dateAndPlaceOfBirth',
    'jmbg',
    'address',
    'legalRelation',
  ];

  determinedSupport: string[] = [
    'supportMannerMoney',
    'supportMannerOther',
    'supportAmmountFiscal',
    'supportAmmountPercentage',
    'supportDurationLimited',
    'supportDurationUnlimited',
  ];

  terminationSupport: string[] = [
    'courtName',
    'courtNumber',
    'courtDate',
    'dateOfVerdictTermination',
    'reasonsForVerdictTermination',
  ];


  constructor(
    private route: ActivatedRoute,
    private dependentPersonService: DependentPersonService) {
    this.route.params.subscribe((params) => {
      this.dependentPersonId = params.id;
      this.year = params.year;
    });
    this.getDependentPerson();
  }

  ngOnInit(): void {
  }

  getDependentPerson() {
    this.dependentPersonService.findById(this.dependentPersonId).subscribe((res) => {
      this.dependentPerson = res;
      for (const element of this.dependentPerson.courtJudgementInfo) {
        element.dateOfVerdictDelivery = element.dateOfVerdictDelivery?.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1.');
        element.dateOfCommencment = element.dateOfCommencment?.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1.');
        element.courtDate = element.courtDate?.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1.');
        element.courtValidityDate = element.courtValidityDate?.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1.');
      }
      for (const element of this.dependentPerson.creditorInfo) {
        if (element.dateOfBirth !== null) {
          element.dateOfBirth = element.dateOfBirth?.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1.');
        } else {
          element.dateOfBirth = ''
        }
        if (element.placeOfBirth === null) {
          element.placeOfBirth = '';
        }
      }
      for (const element of this.dependentPerson.representativeCreditorInfo) {
        if (element.dateOfBirth !== null) {
          element.dateOfBirth = element.dateOfBirth?.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1.');
        } else {
          element.dateOfBirth = '';
        }
        if (element.placeOfBirth === null) {
          element.placeOfBirth = '';
        }
      }
      for (const element of this.dependentPerson.debtorInfo) {
        if (element.dateOfBirth !== null) {
          element.dateOfBirth = element.dateOfBirth?.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1.');
        }
        else {
          element.dateOfBirth = '';
        }
        if (element.placeOfBirth === null) {
          element.placeOfBirth = '';
        }
      }
      for (const element of this.dependentPerson.terminationSupportInfo) {
        element.courtDate = element.courtDate?.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1.');
        element.dateOfVerdictTermination = element.dateOfVerdictTermination?.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1.');
      }
    });
  }
}
