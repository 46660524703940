<nav class="nav" *ngIf="isAccountingProcedures">
    <container>
        <div class="nav__content">
            <h1 class="nav__logo">{{ "ACCOUNTING_PROCEDURES.LOGO_TEXT" | translate }}</h1>
            <button class="button-square" mat-flat-button color="primary" (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                {{ "ACCOUNTING_PROCEDURES.LOGOUT" | translate }}
            </button>
        </div>
    </container>
</nav>
<div class="wrapper" *ngIf="isAccountingProcedures">
    <div class="content">
        <div class="media"></div>
        <main class="main">
            <card-header title="{{ 'ACCOUNTING_PROCEDURES.TITLE' | translate }}"> </card-header>
            <form [formGroup]="accountingProceduresForm" class="form">
                <field-holder kind="2-cols">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'ACCOUNTING_PROCEDURES.FROM' | translate }}</mat-label>
                        <input matInput type="text" [matDatepicker]="periodFrom" formControlName="periodFrom"
                            autocomplete="off" placeholder="{{ 'ACCOUNTING_PROCEDURES.FROM' | translate }}" 
                            [max]="maxDate" required />
                        <mat-datepicker-toggle matSuffix [for]="periodFrom"></mat-datepicker-toggle>
                        <mat-datepicker #periodFrom></mat-datepicker>
                        <mat-error *ngIf="accountingProceduresForm.get('periodFrom').hasError('required')">
                            {{ "ACCOUNTING_PROCEDURES.FROM_ERROR" | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'ACCOUNTING_PROCEDURES.TO' | translate }}</mat-label>
                        <input matInput type="text" [matDatepicker]="periodTo" formControlName="periodTo"
                            autocomplete="off" placeholder="{{ 'ACCOUNTING_PROCEDURES.TO' | translate }}" 
                            [max]="maxDate" required />
                        <mat-datepicker-toggle matSuffix [for]="periodTo"></mat-datepicker-toggle>
                        <mat-datepicker #periodTo></mat-datepicker>
                        <mat-error *ngIf="accountingProceduresForm.get('periodTo').hasError('required')">
                            {{ "ACCOUNTING_PROCEDURES.TO_ERROR" | translate }}
                        </mat-error>
                    </mat-form-field>
                </field-holder>
                <field-holder>
                    <mat-form-field appearance="outline" (click)="selectMulti()">
                        <mat-label>{{ 'ACCOUNTING_PROCEDURES.CENTER' | translate }}</mat-label>
                        <mat-select formControlName="centers" multiple required #multiSelect>
                            <input type="text" class="multi-input" autocomplete="off" matInput placeholder="{{'ACCOUNTING_PROCEDURES.SEARCH_CENTERS' | translate}}" #multiSearch 
                                (focus)="multiSelect.disabled = true" (focusout)="multiSelect.disabled = false" (input)="onInputChange($event.target.value)"/>
                            <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">
                                {{ 'ACCOUNTING_PROCEDURES.ALL_CENTERS' | translate }}
                            </mat-option>
                            <mat-option *ngFor="let value of filteredCenterOptions" [value]="value?.id"
                                (click)="tosslePerOneCenter()">
                                {{ value.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="accountingProceduresForm.get('centers').hasError('required')">
                            {{ "ACCOUNTING_PROCEDURES.CENTER_ERROR" | translate }}
                        </mat-error>
                    </mat-form-field>
                </field-holder>
                <field-holder>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'ACCOUNTING_PROCEDURES.RIGHT' | translate }}</mat-label>
                        <mat-select formControlName="rights" multiple required>
                            <mat-option #allSelectedRights (click)="toggleAllSelectionRight()" [value]="0">
                                {{ 'ACCOUNTING_PROCEDURES.ALL_RIGHTS' | translate }}
                            </mat-option>
                            <mat-option *ngFor="let value of rightOptions" [value]="value.id"
                                (click)="tosslePerOneRight()">
                                {{ value.title }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="accountingProceduresForm.get('rights').hasError('required')">
                            {{ "ACCOUNTING_PROCEDURES.RIGHT_ERROR" | translate }}
                        </mat-error>
                    </mat-form-field>
                </field-holder>
                <form-footer>
                    <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
                        (click)="submitForm(accountingProceduresForm)" [disabled]="!accountingProceduresForm.valid">
                        {{ 'ACCOUNTING_PROCEDURES.SUBMIT' | translate }}
                    </button>
                </form-footer>
            </form>
        </main>
    </div>
</div>
<container *ngIf="!isAccountingProcedures">
    <mat-card class="card">
        <card-header title="{{ 'ACCOUNTING_PROCEDURES.TITLE' | translate }}"> </card-header>
        <form [formGroup]="accountingProceduresForm" class="form">
            <field-holder kind="2-cols" *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
                <mat-form-field appearance="outline" (click)="selectMulti()">
                    <mat-label>{{ 'ACCOUNTING_PROCEDURES.CENTER' | translate }}</mat-label>
                    <mat-select formControlName="centers" multiple required #multiSelect>
                        <input type="text" class="multi-input" autocomplete="off" matInput placeholder="{{'GLOBAL.SEARCH_CENTERS' | translate}}" #multiSearch 
                            (focus)="multiSelect.disabled = true" (focusout)="multiSelect.disabled = false" (input)="onInputChange($event.target.value)"/>
                        <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">
                            {{ 'GLOBAL.ALL_CENTERS' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let value of filteredCenterOptions" [value]="value?.id"
                            (click)="tosslePerOneCenter()">
                            {{ value.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="accountingProceduresForm.get('centers').hasError('required')">
                        {{ "GLOBAL.CENTER_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'ACCOUNTING_PROCEDURES.RIGHT' | translate }}</mat-label>
                    <mat-select formControlName="rights" multiple required>
                        <mat-option #allSelectedRights (click)="toggleAllSelectionRight()" [value]="0">
                            {{ 'ACCOUNTING_PROCEDURES.ALL_RIGHTS' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let value of rightOptions" [value]="value.id"
                            (click)="tosslePerOneRight()">
                            {{ value.title }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="accountingProceduresForm.get('rights').hasError('required')">
                        {{ "ACCOUNTING_PROCEDURES.RIGHT_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <field-holder *ngIf="!isMainCenterBelgrade && !isUserDrugostepeni">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'ACCOUNTING_PROCEDURES.RIGHT' | translate }}</mat-label>
                    <mat-select formControlName="rights" multiple required>
                        <mat-option #allSelectedRights (click)="toggleAllSelectionRight()" [value]="0">
                            {{ 'ACCOUNTING_PROCEDURES.ALL_RIGHTS' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let value of rightOptions" [value]="value.id"
                            (click)="tosslePerOneRight()">
                            {{ value.title }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="accountingProceduresForm.get('rights').hasError('required')">
                        {{ "ACCOUNTING_PROCEDURES.RIGHT_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <field-holder kind="2-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'ACCOUNTING_PROCEDURES.FROM' | translate }}</mat-label>
                    <input matInput type="text" [matDatepicker]="periodFrom" formControlName="periodFrom"
                        autocomplete="off" placeholder="{{ 'ACCOUNTING_PROCEDURES.FROM' | translate }}" 
                        [max]="maxDate" required />
                    <mat-datepicker-toggle matSuffix [for]="periodFrom"></mat-datepicker-toggle>
                    <mat-datepicker #periodFrom></mat-datepicker>
                    <mat-error *ngIf="accountingProceduresForm.get('periodFrom').hasError('required')">
                        {{ "ACCOUNTING_PROCEDURES.FROM_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'ACCOUNTING_PROCEDURES.TO' | translate }}</mat-label>
                    <input matInput type="text" [matDatepicker]="periodTo" formControlName="periodTo"
                        autocomplete="off" placeholder="{{ 'ACCOUNTING_PROCEDURES.TO' | translate }}" 
                        [max]="maxDate" required />
                    <mat-datepicker-toggle matSuffix [for]="periodTo"></mat-datepicker-toggle>
                    <mat-datepicker #periodTo></mat-datepicker>
                    <mat-error *ngIf="accountingProceduresForm.get('periodTo').hasError('required')">
                        {{ "ACCOUNTING_PROCEDURES.TO_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
                    (click)="submitForm(accountingProceduresForm)" [disabled]="!accountingProceduresForm.valid || btnDisabled">
                    {{ 'STATISTICS.SUBMIT' | translate }}
                </button>
                <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </form-footer>
        </form>
    </mat-card>
</container>