<container>
  <mat-card class="card">
    <card-header title="{{ 'STATISTICS.DOCUMENT_STATS.TITLE' | translate }}">
    </card-header>
    <form [formGroup]="documentStatsForm" class="form">
      <field-holder *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
        <mat-form-field appearance="outline" (click)="selectMulti()">
          <mat-label> {{'GLOBAL.CENTER' | translate}}</mat-label>
          <mat-select formControlName="center" multiple required #multiSelect>
              <input type="text" class="multi-input" autocomplete="off" matInput placeholder="{{'GLOBAL.SEARCH_CENTERS' | translate}}" #multiSearch 
                  (focus)="multiSelect.disabled = true" (focusout)="multiSelect.disabled = false" (input)="onInputChange($event.target.value)"/>
              <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">
                  {{ 'GLOBAL.ALL_CENTERS' | translate }}
              </mat-option>
              <mat-option *ngFor="let value of filteredAllCenters" [value]="value?.id"
                  (click)="tosslePerOneCenter()">
                  {{ value.name }}
              </mat-option>
          </mat-select>
          <mat-error *ngIf="documentStatsForm.get('center').hasError('required')">
              {{ "GLOBAL.CENTER_ERROR" | translate }}
          </mat-error>
      </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'STATISTICS.DOCUMENT_STATS.STAT_TYPE' | translate }}
          </mat-label>
          <mat-select formControlName="statType" required>
            <mat-option *ngFor="let value of statTypeOptions" [value]="value"> {{ value.name }} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{'STATISTICS.DOCUMENT_STATS.WORKER' | translate}}
          </mat-label>

          <mat-select formControlName="workerName">
            <mat-option></mat-option>
            <mat-option *ngFor="let user of users" [value]="user">
              {{ user.name }} ({{user.roles}})
            </mat-option>
          </mat-select>
          <mat-hint>{{ 'STATISTICS.DOCUMENT_STATS.WORKER_HINT' | translate }}</mat-hint>
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'STATISTICS.REPORTING_PERIOD_FROM' | translate }}
          </mat-label>
          <input matInput type="text" [matDatepicker]="from" formControlName="from" autocomplete="off" required
            placeholder="{{ 'STATISTICS.REPORTING_PERIOD_FROM' | translate }}" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
          <mat-datepicker #from></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'STATISTICS.REPORTING_PERIOD_TO' | translate }}
          </mat-label>
          <input matInput type="text" [matDatepicker]="to" formControlName="to" autocomplete="off" required
            placeholder="{{ 'STATISTICS.REPORTING_PERIOD_TO' | translate }}" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
          <mat-datepicker #to></mat-datepicker>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
          [disabled]="!documentStatsForm.valid || btnDisabled" (click)="sendRequest(documentStatsForm)">
          {{ 'STATISTICS.SUBMIT' | translate }}
        </button>
        <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </form-footer>
    </form>
  </mat-card>
</container>