import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ageGroupData } from 'src/app/utils/ageGroupData';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { CodebookService } from 'src/services/codebook.service';
import { StatisticsService } from 'src/services/statistics.service';
import { UserService } from 'src/services/user.service';

enum AutocompleteFilterEnum {
   CENTERS = 'centers',
   CASE_CLASSIFICATION = 'caseClassification',
}
@Component({
   selector: 'app-decision-stats',
   templateUrl: './decision-stats.component.html',
   styleUrls: ['./decision-stats.component.scss'],
})
export class DecisionStatsComponent implements OnInit {
   classificationsOptions = [];
   filteredOptions: Observable<any[]>;

   genderOptions = [];
   shouldShowAgeGroupRange = false;
   ageGroup = ageGroupData;
   centers = [];
   filteredAllCenters: any = [];
   loggedIn: any;
   isMainCenterBelgrade = false;
   btnDisabled: boolean;
   isUserDrugostepeni = false;
   decisionStatsForm: FormGroup;
   @ViewChild('allSelectedCenters') private allSelectedCenters: MatOption;
   @ViewChild('multiSearch') private multiSearch: ElementRef;
   constructor(
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private statisticsService: StatisticsService,
      private localStorage: LocalStorageService,
      private userService: UserService
   ) {
      this.loggedIn = JSON.parse(this.localStorage.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.filteredAllCenters = this.centers;
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.filteredAllCenters = this.centers;
         });
      }
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });
      this.btnDisabled = false;
   }

   ngOnInit(): void {
      this.codebookService.getGenderCodebook().subscribe(result => {
         this.genderOptions = result;
      });

      this.decisionStatsForm = this.formBuilder.group({
         caseClassification: [null, [Validators.required]],
         center: [''],
         ageGroup: ['', []],
         gender: ['', []],
         selectedYear: ['', [Validators.required]],
         customAgeGroupTo: [''],
         customAgeGroupFrom: [''],
         byCenter: [false],
      });
      this.dynamicFormUpdate();

      this.codebookService.getSocialCaseClassificationCodebook().subscribe(result => {
         this.classificationsOptions = result;
         this.filteredOptions = this.decisionStatsForm.controls.caseClassification.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? null : typeof value === 'string' ? value : value.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CASE_CLASSIFICATION) : this.classificationsOptions.slice()))
         );
      });
   }
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.CENTERS:
            return sortByFirstLetter(
               this.centers.filter(option => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         case AutocompleteFilterEnum.CASE_CLASSIFICATION:
            return this.classificationsOptions.filter(option => option.title.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }

   sendRequest() {
      if (!this.decisionStatsForm.valid) {
         return;
      }
      let ageGroupDto = null;
      let gender = null;
      let year = null;
      let classification = null;
      ageGroupDto = this.decisionStatsForm.value.ageGroup === null || this.decisionStatsForm.value.ageGroup === '' ? null : this.decisionStatsForm.value.ageGroup;
      year = this.decisionStatsForm.value.selectedYear === '' || this.decisionStatsForm.value.selectedYear === null ? null : this.decisionStatsForm.value.selectedYear;
      gender = this.decisionStatsForm.value.gender === '' || this.decisionStatsForm.value.gender === null ? null : this.decisionStatsForm.value.gender;
      if (ageGroupDto != null && ageGroupDto.some(e => e.id === 6)) {
         const indexToUpdate = ageGroupDto.findIndex(group => group.id === 6);
         const newAgeGroup = {
            id: 6,
            name: 'Слободан унос опсега година (' + this.decisionStatsForm.value.customAgeGroupFrom + ' - ' + this.decisionStatsForm.value.customAgeGroupTo + ')',
            from: this.decisionStatsForm.value.customAgeGroupFrom,
            to: this.decisionStatsForm.value.customAgeGroupTo,
         };
         ageGroupDto[indexToUpdate] = newAgeGroup;
      }
      classification = this.decisionStatsForm.value.caseClassification.code;
      const dto = {
         csrId: this.isMainCenterBelgrade || this.isUserDrugostepeni ? this.decisionStatsForm.value.center : [this.loggedIn.csrId],
         ageGroup: ageGroupDto,
         gender,
         year,
         classification,
         byCenter: this.decisionStatsForm.value.byCenter,
      };

      var filename = 'Статистика ' + this.decisionStatsForm.value.caseClassification.code;
      this.statisticsService.getCaseOutcomeStatistics(dto, filename);
   }

   displayCustomFormat(caseClass: any): string {
      return caseClass ? caseClass.code + ' | ' + caseClass.title : '';
   }

   displayCustomFormatCenter(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }

   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.decisionStatsForm);
   }

   checkClasificationCase(fast: boolean) {
      const temp = this.decisionStatsForm;
      const caseClassifications = this.classificationsOptions;
      if (temp.value.caseClassification === null) {
         temp.controls.caseClassification.setErrors({ notValid: true });
         return;
      }
      if (fast === false) {
         setTimeout(function () {
            if (temp.value.caseClassification.code === undefined || temp.value.caseClassification.code === null) {
               const founded = caseClassifications.filter(
                  caseClass => caseClass.title.toLowerCase().includes(temp.value.caseClassification) || caseClass.code.toLowerCase().includes(temp.value.caseClassification)
               );
               if (founded.length === 0) {
                  temp.controls.caseClassification.setErrors({ notValid: true });
                  return;
               } else {
                  temp.controls.caseClassification.setValue(founded[0]);
                  temp.controls.caseClassification.setErrors(null);
               }
            }
         }, 300);
      } else {
         if (temp.value.caseClassification.code === undefined || temp.value.caseClassification.code === null) {
            const founded = caseClassifications.filter(
               caseClass => caseClass.title.toLowerCase().includes(temp.value.caseClassification) || caseClass.code.toLowerCase().includes(temp.value.caseClassification)
            );
            if (founded.length === 0) {
               temp.controls.caseClassification.setErrors({ notValid: true });
               return;
            } else {
               temp.controls.caseClassification.setValue(founded[0]);
               temp.controls.caseClassification.setErrors(null);
            }
         }
      }
   }
   dynamicFormUpdate() {
      this.decisionStatsForm.get('ageGroup').valueChanges.subscribe(ageArray => {
         if (ageArray.some(age => age.id === 6)) {
            this.shouldShowAgeGroupRange = true;
            this.decisionStatsForm.get('customAgeGroupFrom').setValidators(Validators.required);
            this.decisionStatsForm.get('customAgeGroupTo').setValidators(Validators.required);
         } else {
            this.shouldShowAgeGroupRange = false;
            this.decisionStatsForm.get('customAgeGroupFrom').clearValidators();
            this.decisionStatsForm.get('customAgeGroupFrom').setValue(null);
            this.decisionStatsForm.get('customAgeGroupTo').clearValidators();
            this.decisionStatsForm.get('customAgeGroupTo').setValue(null);
         }
      });
   }
   checkCustomYears() {
      if (this.decisionStatsForm.controls.customAgeGroupTo.touched && this.decisionStatsForm.controls.customAgeGroupFrom.touched) {
         if (
            this.decisionStatsForm.value.customAgeGroupTo !== null &&
            this.decisionStatsForm.value.customAgeGroupTo !== '' &&
            this.decisionStatsForm.value.customAgeGroupFrom !== null &&
            this.decisionStatsForm.value.customAgeGroupFrom !== ''
         ) {
            if (this.decisionStatsForm.value.customAgeGroupTo < this.decisionStatsForm.value.customAgeGroupFrom) {
               this.decisionStatsForm.controls.customAgeGroupTo.setErrors({
                  incorect: true,
               });
            } else {
               this.decisionStatsForm.controls.customAgeGroupTo.setErrors(null);
            }
         }
      }
      if (this.decisionStatsForm.value.customAgeGroupTo !== null && this.decisionStatsForm.value.customAgeGroupTo !== '') {
         if (!this.decisionStatsForm.controls.customAgeGroupTo.hasError('incorect') && Number(this.decisionStatsForm.value.customAgeGroupTo) > 150) {
            this.decisionStatsForm.controls.customAgeGroupTo.setErrors({
               outOfRange: true,
            });
         } else if (!this.decisionStatsForm.controls.customAgeGroupTo.hasError('incorect')) {
            this.decisionStatsForm.controls.customAgeGroupTo.setErrors(null);
         }
      }
      if (this.decisionStatsForm.value.customAgeGroupFrom !== null && this.decisionStatsForm.value.customAgeGroupFrom !== '') {
         if (Number(this.decisionStatsForm.value.customAgeGroupFrom) > 150) {
            this.decisionStatsForm.controls.customAgeGroupFrom.setErrors({
               outOfRange: true,
            });
         } else {
            this.decisionStatsForm.controls.customAgeGroupFrom.setErrors(null);
         }
      }
   }

   selectMulti() {
      this.multiSearch.nativeElement.focus();
      this.multiSearch.nativeElement.value = null;
      this.filteredAllCenters = this.centers;
   }

   toggleAllSelectionCenter() {
      if (this.allSelectedCenters.selected) {
         this.decisionStatsForm.controls.center.patchValue([...this.centers.map(item => item.id), 0]);
      } else {
         this.decisionStatsForm.controls.center.patchValue([]);
      }
      if (this.decisionStatsForm.controls.center.value == null || this.decisionStatsForm.controls.center.value.length <= 1) {
         this.decisionStatsForm.controls.byCenter.setValue(false);
      }
   }
   tosslePerOneCenter() {
      if (this.allSelectedCenters.selected) {
         this.allSelectedCenters.deselect();
         return false;
      }
      if (this.decisionStatsForm.controls.center.value.length === this.centers.length) {
         this.allSelectedCenters.select();
      }
      if (this.decisionStatsForm.controls.center.value == null || this.decisionStatsForm.controls.center.value.length <= 1) {
         this.decisionStatsForm.controls.byCenter.setValue(false);
      }
   }
   onInputChange(inputValue: string) {
      const input = inputValue.toLowerCase();
      this.filteredAllCenters = this.centers.filter((center: any) => {
         const selectedCenters = this.decisionStatsForm.controls.center.value;
         return center.name.toLowerCase().includes(input) || selectedCenters.includes(center.id);
      });
   }
}
