/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

enum AutocompleteFilterEnum {
   CITIZENSHIP = 'citizenship',
}

@Component({
   selector: 'new-adopted-child-adoptee-step',
   templateUrl: './new-adopted-child-adoptee-step.component.html',
   styleUrls: ['./new-adopted-child-adoptee-step.component.scss'],
})
export class NewAdoptedChildAdopteeStepComponent implements OnInit {
   @Output() adopteeStepEmitter = new EventEmitter();
   @Input() adopteeStep: FormGroup;
   currentDate = new Date();
   citizenshipOptions = [];
   filteredCitizenshipOptionsBefore: Observable<any[]>;
   filteredCitizenshipOptionsAfter: Observable<any[]>;
   regexStrDate = '^[0-9.]+$';
   regexStrJmbg = '^[0-9]+$';

   constructor(private dialog: MatDialog, private codebookService: CodebookService, private formBuilder: FormBuilder, private datePipe: DatePipe) {
      this.getCitizenship();
   }

   ngOnInit(): void {
      this.adopteeStep = this.formBuilder.group({
         beforeAdoption: this.formBuilder.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            dateOfBirth: ['', [Validators.required]],
            placeOfBirth: ['', [Validators.required]],
            jmbg: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(13), Validators.minLength(13)]],
            citizenship: [
               '',
               [
                  /*Validators.required*/
               ],
            ],
            subjectId: [''],
            registrationId: [''],
            permanentResidence: [''],
            foreigner: [false],
         }),
         afterAdoption: this.formBuilder.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            dateOfBirth: ['', [Validators.required]],
            placeOfBirth: ['', [Validators.required]],
            jmbg: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(13), Validators.minLength(13)]],
            citizenship: [
               '',
               [
                  /*Validators.required*/
               ],
            ],
            permanentResidence: [''],
         }),
      });
   }

   /**
    * Send step information to parent component
    */
   updateAdopteeStep() {
      this.adopteeStepEmitter.emit(this.adopteeStep);
   }

   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }

   getCitizenship() {
      this.codebookService.getCitizenshipCodebook().subscribe(res => {
         this.citizenshipOptions = res;
         this.filteredCitizenshipOptionsBefore = (this.adopteeStep.get('beforeAdoption') as FormGroup).controls.citizenship.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? null : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice()))
         );
         this.filteredCitizenshipOptionsAfter = (this.adopteeStep.get('afterAdoption') as FormGroup).controls.citizenship.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? null : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice()))
         );
      });
   }

   importSubjectData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         // width: ModalSizeEnum.DEFAULT,
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const permanentAdress =
               String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
               String(result.data.permanentResidence.number ? ' ' : '') +
               String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
               String(result.data.permanentResidence.subnumber ? '/' : '') +
               String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : '');

            this.adopteeStep.patchValue({
               beforeAdoption: {
                  subjectId: result.data.subjectIdentity.subjectId,
                  registrationId: result.data.registrationId,
                  firstName: result.data.firstName,
                  lastName: result.data.lastName,
                  dateOfBirth: new Date(result.data.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')),
                  placeOfBirth: result.data.placeOfBirth,
                  foreigner: result.data.foreigner,
                  jmbg: result.data.jmbg,
                  citizenship: result.data.citizenship,
                  permanentResidence: permanentAdress,
               },
               afterAdoption: {
                  dateOfBirth: new Date(result.data.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')),
                  placeOfBirth: result.data.placeOfBirth,
                  jmbg: result.data.jmbg,
               },
            });
            this.adopteeStep.get('beforeAdoption.jmbg').markAsTouched();
            this.adopteeStep.get('beforeAdoption.dateOfBirth').markAsTouched();
            this.adopteeStep.controls.beforeAdoption.disable();
            this.adopteeStep.get('afterAdoption.jmbg').disable();
            this.adopteeStep.get('afterAdoption.dateOfBirth').disable();
            this.adopteeStep.get('afterAdoption.placeOfBirth').disable();
         }
      });
   }

   updateJMBGOnDateOfBirthChange(event: any, control: string) {
    const group = this.adopteeStep.get(control) as FormGroup;
  
    if (control === 'beforeAdoption' && group.get('foreigner').value) {
      return;
    }
  
    const dateOfBirth = group.get('dateOfBirth')?.value;
    const jmbg = group.get('jmbg')?.value;
  
    if (dateOfBirth && jmbg) {
      const birthDate = new Date(dateOfBirth).getTime();
      const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
      const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
  
      if (jmbg.length === 13) {
        const jmbgPrefix = jmbg.substring(0, 7);
        if (newBirthDate !== jmbgPrefix) {
          group.get('dateOfBirth')?.setErrors({ incorrect: true });
        } else {
          group.get('dateOfBirth')?.setErrors(null);
        }
      }
    }
  }
  

   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.CITIZENSHIP:
            return this.citizenshipOptions.filter((option: any) => option.title.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.title : '';
   }

   checkCitizenship(type: string) {
      let step;

      if (type === 'beforeAdoption') {
         step = this.adopteeStep.value.beforeAdoption;
      } else {
         step = this.adopteeStep.value.afterAdoption;
      }
      if (step.citizenship !== undefined && step.citizenship !== null && step.citizenship !== '') {
         const choosedValue = step.citizenship;

         var result = this.citizenshipOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            (this.adopteeStep.get(type) as FormGroup).controls.citizenship.setErrors({ notValid: true });
         } else {
            (this.adopteeStep.get(type) as FormGroup).controls.citizenship.setValue(result);
            (this.adopteeStep.get(type) as FormGroup).controls.citizenship.setErrors(null);
         }
      }
   }

   copyJmbg() {
      (this.adopteeStep.controls.afterAdoption as FormGroup).controls.jmbg.setValue((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.jmbg.value);
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.adopteeStep.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);

      if (inputDate > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.adopteeStep.get(controlName);
      if (!control) return;
    
      const inputElement = document.querySelector(`[id="${controlName.replace('.', '-')}"]`) as HTMLInputElement;
      const inputValue = inputElement?.value || '';
      const dateValue = control.value;
    
      if (!inputValue) {
        control.setErrors({ required: true });
        return;
      }
    
      if (inputValue && !dateValue) {
        control.setErrors({ invalidFormat: true });
        return;
      }
    
      if (dateValue && dateValue > this.currentDate) {
        control.setErrors({ maxDateExceeded: true });
      }
    }
    

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      } else if (fieldName === 'jmbg') {
         return new RegExp(this.regexStrJmbg).test(event.key);
      }
   }
}
