import { DatePipe } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodebookService } from 'src/services/codebook.service';
import { ModalEventEnum } from 'src/types';

@Component({
   selector: 'edit-termination-of-guardianship',
   templateUrl: './edit-termination-of-guardianship.component.html',
   styleUrls: ['./edit-termination-of-guardianship.component.scss'],
})
export class EditTerminationOfGuardianshipComponent implements OnInit {
   currentDate = new Date();
   terminationOfGuardianship: FormGroup;
   terminationOptions: any = [];
   regexStrDate = '^[0-9.]+$';

   constructor(
      private formBuilder: FormBuilder,
      public dialogRef: MatDialogRef<any>,
      private datePipe: DatePipe,
      private codebookService: CodebookService,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any
   ) {}

   ngOnInit(): void {
      this.getTerminationReasonCodebook();

      this.terminationOfGuardianship = this.formBuilder.group({
         decisionNumber: [this.data.protege.protegePersonCollection.decisionNumber],
         decisionDate: [this.data.protege.protegePersonCollection.decisionDate],
         decisionName: [this.data.protege.protegePersonCollection.decisionName],
         reasonForTermination: [this.data.protege.protegePersonCollection.reasonForTermination],
         terminationReasonCodebook: [this.data.protege.protegePersonCollection.terminationReasonCodebook],
      });
   }

   getTerminationReasonCodebook() {
      this.codebookService.getTerminationReasonByType('STAR').subscribe(res => {
         this.terminationOptions = [null, ...res];
      });
   }

   terminateGuardianship() {
      const body = {
         decisionNumber: this.terminationOfGuardianship.value.decisionNumber,
         decisionDate: this.terminationOfGuardianship.value.decisionDate !== null ? this.datePipe.transform(new Date(this.terminationOfGuardianship.value.decisionDate).getTime(), 'yyyy-MM-dd') : null,
         decisionName: this.terminationOfGuardianship.value.decisionName,
         reasonForTermination: this.terminationOfGuardianship.value.reasonForTermination,
         terminationReasonCodebook: this.terminationOfGuardianship.value.terminationReasonCodebook,
      };

      this.dialogRef.close({
         event: ModalEventEnum.SUCCESS,
         data: body,
      });
   }

   compareObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title && object1.type === object2.type;
   }

   closeDialog() {
      this.dialogRef.close({ event: ModalEventEnum.CANCEL });
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.terminationOfGuardianship.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);

      if (inputDate > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.terminationOfGuardianship.get(controlName);
      const dateValue = control.value;
      const inputValue = (document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement).value;

      if (dateValue === null && inputValue !== '') {
         control.setErrors({ invalidFormat: true });
      } else {
         if (dateValue > this.currentDate) {
            control.setErrors({ maxDateExceeded: true });
         } else {
            control.setErrors(null);
         }
      }
   }

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      }
   }
}
