/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { SubjectsService } from 'src/services/subjects.service';
import { Page } from 'src/app/page';
import { LocalStorageService } from 'src/app/local-storage.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'subject-history',
  templateUrl: './subject-history.component.html',
  styleUrls: ['./subject-history.component.scss'],
})
export class SubjectHistoryComponent {
  page: Page<Object> = new Page();
  selectedRow: { id: number };
  subjectId: string;

  displayedColumns: string[] = [
    'dateOfModification',
    'modifiedBy',
    'registrationId',
    'id',
    'dateOfCreation',
    'lastName',
    'firstName',
    'parentName',
    'dateOfBirth',
    'yearOfBirth',
    'gender',
    'jmbg',
    'jmbp',
    'status',
    'familyDossier',
    'familyType',
    'placeOfBirth',
    'nationality',
    'citizenship',
    'category',
    'nativeLanguage',
    'disabledPerson',
    'disabilityType',
    'ableToWork',
    'occupation',
    'employment',
    'education',
    'levelOfEducation',
    'schoolAttending',
    'businessAbility',
    'deprivationExtent',
    'maritalStatus',
    'residence',
    'permanentResidence',
    'phone',
    'mobile',
    'email',
    'note',
    'note1',
    'note2',
    'note3',
    'dossierOwner',
    'familyMembersNames'
  ];
  loggedIn: any;
  isMainCenterBelgrade = false;
  isUserDrugostepeni = false;
  localStorageCenterId: any;
  isAdmin: boolean;
  constructor(
    private route: ActivatedRoute,
    private subjectsService: SubjectsService,
    private paginationService: CustomPaginationService,
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private userService: UserService) {

    this.localStorageCenterId = this.localStorageService.get('localStorageCenterId');
    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    this.isMainCenterBelgrade = (this.loggedIn?.csrId === '1') ? true : false;
    this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
    this.isAdmin = this.userService.isGlobalAdmin();
    this.route.params.subscribe((params) => {
      this.subjectId = params.id;
    });
    this.getData();
  }

  getData() {
    let x = null;
    if (this.isMainCenterBelgrade || this.isUserDrugostepeni || this.isAdmin) {
      x = this.subjectsService.getHistoryPage(this.page.pageable, this.subjectId, this.localStorageCenterId);
    } else {
      x = this.subjectsService.getHistoryPage(this.page.pageable, this.subjectId, this.loggedIn?.csrId);
    }
    x.subscribe((page) => {
      this.page = page;
      this.page.pageable.pagePerShow = page.number + 1;
      for (const element of this.page.content) {
        (element as any).dateOfModification = (element as any).dateOfModification.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
        (element as any).dateOfCreation = (element as any).dateOfCreation.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
        if ((element as any).dateOfBirth !== null) {
          (element as any).dateOfBirth = (element as any).dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
        }
      }
      this.cdr.detectChanges();
    });

  }

  public getNextPage(): void {
    this.page.pageable = this.paginationService.getNextPage(this.page);
    this.getData();
  }

  public getPreviousPage(): void {
    this.page.pageable = this.paginationService.getPreviousPage(this.page);
    this.getData();
  }

  public getFirstPage(): void {
    this.page.pageable = this.paginationService.getFirstPage(this.page);
    this.getData();
  }

  public getLastPage(): void {
    this.page.pageable = this.paginationService.getLastPage(this.page);
    this.getData();
  }

  public getPageInNewSize(pageSize: number): void {
    this.page.pageable = this.paginationService.getPageInNewSize(
      this.page,
      pageSize
    );
    this.getData();
  }
}
