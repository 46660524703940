<container kind="midi">
   <case-page-header (handleComplainAccepted)="acceptedComplain()" (newCaseStatus)="updateStatus($event)"
      (newProblem)="problemAdded($event)" *ngIf="caseData" [caseData]="caseData" [caseId]="caseId" [isLawyer]="isLawyer"
      [subjectId]="subjectId" [teamMember]="teamMember" [caseName]="caseName" (saveEvent)="saveCurrentData()"
      (handleCloseCaseData)="handleCloseCase($event)" [rightType]="'L'">
   </case-page-header>
   <page-intro-header title="{{title}}"></page-intro-header>

   <mat-card class="card" *ngIf="isLawyer && teamMember===false">
      <form [formGroup]="localCaseForm" class="form">
         <field-holder>
            <mat-radio-group aria-label="Select an option" color="primary" formControlName="typeOfLocalCase"
               class="form__radio-group">
               <mat-radio-button *ngFor="let option of paymentTypes" (change)="changeTypeOfLocalRight($event.value)"
                  [value]="option.value">
                  {{ option.viewValue | translate }}
               </mat-radio-button>
            </mat-radio-group>
         </field-holder>
         <br>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'LOCAL_CASE.DECISION_TYPE' | translate}}
               </mat-label>
               <mat-select formControlName="decisionType" [compareWith]="compareLoadedObjects"
                  (selectionChange)="decisionChanged($event.value)" required>
                  <mat-option *ngFor="let dType of decisionTypes" [value]="dType">
                     {{ dType.title}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-radio-group aria-label="Select an option" color="primary" formControlName="decisionMaker"
               class="form__radio-group form__radio-group--2-cols">
               <span class="form__radio-group-label">
                  {{ 'LAWYER.DECISION_MAKER' | translate }}
               </span><br>
               <mat-radio-button *ngFor="let option of decisionMakerOptions" [value]="option"
                  (change)="decisionMakerSelected($event)">
                  {{ option.title }}
               </mat-radio-button>
            </mat-radio-group>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline" *ngIf="localCaseForm.value.decisionMaker?.id === 2">
               <mat-label>{{ 'LAWYER.DECISION_MAKER_DESCRIPTION' | translate }}</mat-label>
               <textarea matInput type="text" formControlName="decisionMakerDescription" autocomplete="off"
                  matTooltip="{{'LAWYER.DECISION_MAKER_DESCRIPTION_TOOLTIP'| translate}}">
               </textarea>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'LAWYER.DECISION_MAKER_TITLE' | translate }}</mat-label>
               <input matInput type="text" formControlName="decisionMakerTitle" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'LAWYER.DECISION_MAKER_NAME' | translate }}
               </mat-label>
               <mat-select formControlName="decisionMakerName" [compareWith]="compareDecisionMakerNameObjects" required>
                  <mat-option *ngFor="let signature of signatures" [value]="signature">
                     {{ signature }}
                  </mat-option>
               </mat-select>
            </mat-form-field>

         </field-holder>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'LOCAL_CASE.VALID_FROM' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerValidFrom" formControlName="validFrom" required [max]="maxDate"
                  autocomplete="off" />
               <mat-datepicker-toggle matSuffix [for]="pickerValidFrom">
               </mat-datepicker-toggle>
               <mat-datepicker #pickerValidFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'LOCAL_CASE.VALID_TO' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerValidTo" formControlName="validTo" autocomplete="off"
                  [min]="minToDate" [max]="maxDate" />
               <mat-datepicker-toggle matSuffix [for]="pickerValidTo">
               </mat-datepicker-toggle>
               <mat-datepicker #pickerValidTo></mat-datepicker>
            </mat-form-field>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'LOCAL_CASE.JNP_REASON' | translate}}
               </mat-label>
               <mat-select formControlName="jnpReason" [compareWith]="compareLoadedObjects" required>
                  <mat-option *ngFor="let jnpType of filteredJnpTypes" [value]="jnpType">
                     {{ jnpType.title}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline" *ngIf="localCaseForm.get('decisionType')?.value?.id === 1">
               <mat-label>
                  {{'LOCAL_CASE.PAYMENT_METHOD' | translate}}
               </mat-label>
               <mat-select formControlName="paymentType" [compareWith]="compareLoadedObjects"
                  (selectionChange)="paymentMethodChanged()"
                  [required]="localCaseForm.get('typeOfLocalCase').value===paymentTypes[0].value">
                  <mat-option *ngFor="let paymentMethod of paymentMethodCodebook" [value]="paymentMethod">
                     {{ paymentMethod.title}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="localCaseForm.get('decisionType')?.value?.id === 1">
               <mat-label>
                  {{ 'LOCAL_CASE.AMOUNT' | translate }}
               </mat-label>
               <input type="number" step="0.01" matTooltip="{{ 'LOCAL_CASE.AMOUNT_TOOLTIP' | translate }}"
                  matInput formControlName="amount" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline"
               *ngIf="localCaseForm.get('decisionType')?.value?.id === 1 && 
                 (localCaseForm.get('paymentType').value !== null && localCaseForm.get('paymentType').value !== '' && localCaseForm.get('paymentType').value.id !==1 && localCaseForm.get('paymentType').value.id !==6)">
               <mat-label>
                  {{'CASES.ACCOUNT_NUMBER' | translate}}
               </mat-label>
               <input matInput type="text" formControlName="account" autocomplete="off"
                  [required]="localCaseForm.get('decisionType')?.value?.id === 1 && 
                 (localCaseForm.get('paymentType').value !== null && localCaseForm.get('paymentType').value !== '' && localCaseForm.get('paymentType').value.id !==1&& localCaseForm.get('paymentType').value.id !==2 && localCaseForm.get('paymentType').value.id !==6)"
                  placeholder="{{ 'ACCOUNT_NUMBER_FORMAT' | translate }}" [textMask]="mask" />
            </mat-form-field>
         </field-holder>
         <field-holder *ngIf="localCaseForm.get('typeOfLocalCase').value===paymentTypes[1].value">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'LOCAL_CASE.NATURI' | translate }}
               </mat-label>
               <input type="text" matInput formControlName="naturi" autocomplete="off" />
            </mat-form-field>
         </field-holder>
         <div class="aside">
            <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
               (click)="$event.stopPropagation()">
               {{ 'LAWYER.DOCUMENT' | translate }}<mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="filter-menu">
               <ng-container *ngFor="let docOptions of documentOptions">
                  <button mat-menu-item (click)="handleNewEvaluation(docOptions)">{{ docOptions.name }}</button>
               </ng-container>
            </mat-menu>

            <input type="file" #file accept=".docx, .pdf" (change)="handleFileInput($event.target.files)" hidden>
            <button class="header__add-document" style="margin-left: 15px;" mat-flat-button color="primary"
               type="submit" (click)="file.click()">
               {{ 'LAWYER.CREATE' | translate }}
            </button>
         </div>
         <br>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'LAWYER.SOLUTION' | translate }}</mat-label>
               <input matInput type="text" formControlName="fileName" readonly required autocomplete="off" />
            </mat-form-field>
         </field-holder>
         <form-footer class="footer">
            <span matTooltip="{{checkIfSendIsDisabled().msg}}" [matTooltipDisabled]="checkIfSendIsDisabled().disabled">
               <span matTooltip="{{'CASES.NO_REGISTER_NUMBER' | translate }}"
                  [matTooltipDisabled]="(checkIfSendIsDisabled().disabled)||noRegistrationId===false">
                  <span matTooltip="{{'CASES.COMPLAIN_RESPOND_NEEDED' | translate }}"
                     [matTooltipDisabled]="!caseData?.newComplain">
                     <button mat-flat-button color="primary" (click)="submitLocalCase();button = true"
                        class="form-footer__button form-footer__button--secondary" #submitButton
                        [disabled]="fileToUpload === null || caseData.caseStatus !== 'ACCEPTED' || !localCaseForm.valid || !hasProblem||button||noRegistrationId||caseData?.newComplain">
                        {{ 'LAWYER.SUBMIT_AND_SEND' | translate }}
                     </button>
                  </span>
               </span> </span>
            <mat-progress-spinner [diameter]="45" *ngIf="isSubmited" color="primary" mode="indeterminate">
            </mat-progress-spinner>
         </form-footer>
      </form>
   </mat-card>
   <mat-card *ngIf="teamMember">
      <team-member [editable]="caseData?.caseStatus === 'ACCEPTED'" [caseId]="caseId" [subjectId]="subjectId">
      </team-member>
   </mat-card>
</container>