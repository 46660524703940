/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { fileCheck } from 'src/app/utils/fileInputUtil';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { AlfrescoService } from 'src/services/alfresco.service';
import { CaseProblemRecordService } from 'src/services/case-problem-record.service';
import { CaseService } from 'src/services/case.service';
import { CodebookService } from 'src/services/codebook.service';
import { DpnRequestService } from 'src/services/dpn-request.service';
import { ExpeditionService } from 'src/services/expedition.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalSizeEnum } from 'src/types';
import { SubjectsComponent } from '../../subjects/subjects.component';
import { DataSharingService } from 'src/services/data-sharing.service';

enum AutocompleteFilterEnum {
   PIODECISION = 'pioDecision',
   PIOFINDING = 'pioFinding',
}

@Component({
   selector: 'app-dpn',
   templateUrl: './dpn.component.html',
   styleUrls: ['./dpn.component.scss'],
})
export class DpnComponent implements OnInit {
   title: string;
   pioDecisions: any = [];
   pioDecisionsFiltered: Observable<any[]>;
   pioFindingsFiltered: Observable<any[]>;
   dateOfCaseCreation: any;
   caseName: any;
   caseId: any;
   subjectId: any;
   isValid: any;
   caseData: any;
   subject: any;
   dpnRequestForm: FormGroup;
   child: any;
   paymentMethodCodebook: any;
   initiatorCodebook: any;
   kind: any;
   count: any = 0;
   subCaseKind: any = [];
   patch: any = false;
   parent: any;
   dpnRights: any = [];
   dpnRight: any;
   right: any;
   decisionTypes: [];
   isLawyer: any = false;
   enableConclusion = false;
   loadedData: any;
   loggedIn: any;
   showPreview: boolean;
   pioDecisionRequred = false;
   pioFindingRequred = false;
   teamMember = false;
   paymentRecipients: any = [];
   subkindRightRequred = false;
   fileToUpload: File = null;
   documentOptions: any;
   noRegistrationId = false;
   changeDate = null;
   terminationReasons: any = [];
   minToDate: Date;
   maxDate = new Date(2999, 11, 31);
   @ViewChild('submitButton') submitButton: any;
   @ViewChild('file') file: ElementRef<HTMLInputElement>;
   public mask = {
      guide: false,
      showMask: false,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/],
   };
   decisionMakerOptions: any;
   isSubmited = false;
   hasProblem = false;

   constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private caseService: CaseService,
      private cdr: ChangeDetectorRef,
      public dialog: MatDialog,
      private codebookService: CodebookService,
      private dpnRequestService: DpnRequestService,
      private translate: TranslateService,
      private localStorageService: LocalStorageService,
      private toastr: ToastrImplService,
      private alfrescoService: AlfrescoService,
      private datePipe: DatePipe,
      private route: ActivatedRoute,
      private userService: UserService,
      private expedition: ExpeditionService,
      private dataSharingService: DataSharingService,
      private caseProblemRecordService: CaseProblemRecordService
   ) {
      this.getPioDesisions();

      this.isLawyer = this.userService.isUserLawyer();

      this.route.params.subscribe(params => {
         this.subjectId = params.subjectId;
         this.caseId = params.id;
         const [_, caseType] = this.router.url.split('/cases/');
         if (caseType !== '') {
            this.caseName = caseType.split('/')[0];
         }
         this.initializeDpnForm();
         this.isValid = this.subjectId !== undefined || this.subjectId !== null;
         if (this.isValid) {
            this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
            this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
            this.caseService.getSocialCase(this.caseId).subscribe(result => {
               this.caseData = result;
               this.subject = this.caseData.subject;
               if (this.subject.registrationId === null || this.subject.registrationId === '') {
                  this.noRegistrationId = true;
                  this.enableConclusion = false;
                  this.toastr.info('CASES.NO_REGISTER_NUMBER');
               }
               if (this.loggedIn?.id !== this.caseData.assignedUserId) {
                  this.teamMember = true;
               } else {
                  this.teamMember = false;
               }
               if (this.caseData.caseStatus === 'ACCEPTED') {
                  this.enableConclusion = true;
               } else {
                  this.enableConclusion = false;
               }
               if (this.isLawyer) {
                  this.getDocumentsByTemplate();
               }
               this.getCaseKindViewCodebook(this.caseData.socialCaseClassificationCodebook.caseKindViewCodebook.kind, this.caseData.dateOfCreation);
               this.title = this.caseData.socialCaseClassificationCodebook.title;
               this.codebookService.getDecisionTypeCodebook().subscribe(result1 => {
                  this.decisionTypes = result1;
                  if (this.title.toLowerCase().includes('престанак')) {
                     this.decisionTypes.splice(0, 1);
                  }
               });
               this.caseHasProblem();
            });
         }
         this.checkActiveRight();
      });
   }
   initializeDpnForm() {
      this.dpnRequestForm = this.formBuilder.group({
         dateOfCaseCreation: [''],
         requestDate: new FormControl({ value: new Date(), disabled: true }),
         amount: new FormControl({ value: '0', disabled: true }),
         fromDate: ['', [Validators.required]],
         toDate: [''],
         pioFinding: [''],
         pioNumFinding: new FormControl({ value: '', disabled: true }),
         pioDateFinding: new FormControl({ value: '', disabled: true }),
         pioDecision: [''],
         pioNumDecision: new FormControl({ value: '', disabled: true }),
         pioDateDecision: new FormControl({ value: '', disabled: true }),
         child: [''],
         parentFirstName: new FormControl({ value: '', disabled: true }),
         parentLastName: new FormControl({ value: '', disabled: true }),
         parentResidenceAddress: new FormControl({ value: '', disabled: true }),
         parentAddress: new FormControl({ value: '', disabled: true }),
         parentAddressNum: new FormControl({ value: '', disabled: true }),
         parentRegBr: new FormControl({ value: '', disabled: true }),
         decisionType: [''],
         paymentMethod: [null],
         initiator: [''],
         subjectId: [''],
         subKind: [''],
         subKindRight: [''],
         toDateCheck: [''],
         refusalReason: [''],
         terminationOfRights: [''],
         lawyerFile: [''],
         fileName: [''],
         account: [''],
         paymentRecipient: null,
         decisionMaker: [''],
         decisionMakerDescription: [],
         decisionMakerName: [''],
         decisionMakerTitle: [''],
         inAccordanceWithDecision: [false],
         dispozitivAddition: [''],
         terminationReason: [''],
      });
      this.subCaseKind = [];
      this.caseData = null;
      this.count = 0;
      this.setValueChangesEvents();
   }

   setValueChangesEvents() {
      this.dpnRequestForm.controls.pioFinding.valueChanges.subscribe(originAreaType => {
         if (originAreaType !== '') {
            this.dpnRequestForm.controls.pioNumFinding.enable();
            if (this.dpnRequestForm.controls.pioNumFinding.value !== '') {
               this.dpnRequestForm.controls.pioDateFinding.enable();
            }
            this.pioFindingRequred = true;
         } else {
            this.dpnRequestForm.controls.pioNumFinding.setValue('');
            this.dpnRequestForm.controls.pioNumFinding.disable();
            this.pioFindingRequred = false;
         }
      });
      this.dpnRequestForm.controls.pioNumFinding.valueChanges.subscribe(originAreaType => {
         if (originAreaType !== '') {
            if (this.dpnRequestForm.controls.pioNumFinding.disabled) {
               this.dpnRequestForm.controls.pioDateFinding.setValue('');
               this.dpnRequestForm.controls.pioDateFinding.disable();
            } else {
               this.dpnRequestForm.controls.pioDateFinding.enable();
            }
         } else {
            this.dpnRequestForm.controls.pioDateFinding.setValue('');
            this.dpnRequestForm.controls.pioDateFinding.disable();
         }
      });
      this.dpnRequestForm.controls.pioDecision.valueChanges.subscribe(originAreaType => {
         if (originAreaType !== '') {
            this.dpnRequestForm.controls.pioNumDecision.enable();
            if (this.dpnRequestForm.controls.pioNumDecision.value !== '') {
               this.dpnRequestForm.controls.pioDateDecision.enable();
            }

            this.pioDecisionRequred = true;
         } else {
            this.dpnRequestForm.controls.pioNumDecision.setValue('');
            this.dpnRequestForm.controls.pioNumDecision.disable();
            this.pioDecisionRequred = false;
         }
      });
      this.dpnRequestForm.controls.pioNumDecision.valueChanges.subscribe(originAreaType => {
         if (originAreaType !== '') {
            if (this.dpnRequestForm.controls.pioNumDecision.disabled) {
               this.dpnRequestForm.controls.pioDateDecision.setValue('');
               this.dpnRequestForm.controls.pioDateDecision.disable();
            } else {
               this.dpnRequestForm.controls.pioDateDecision.enable();
            }
         } else {
            this.dpnRequestForm.controls.pioDateDecision.setValue('');
            this.dpnRequestForm.controls.pioDateDecision.disable();
         }
      });

      this.dpnRequestForm.controls.fromDate.valueChanges.subscribe(value => {
         if (this.kind !== undefined && value != null) {
            this.changeDate = this.dpnRequestForm.controls.subKindRight.value;
            this.getDpnRightCodebook(this.kind, value);
         }
      });
   }

   ngOnInit(): void {
      this.getInitiatorCodebook();
      this.getPaymentMethodCodebook();
      this.getPaymentRecipientCodebook();
      this.getDecisionMakerCodebook();

      this.dpnRequestForm.get('fromDate').valueChanges.subscribe((fromDate: Date) => {
         this.minToDate = fromDate;
      });
   }

   checkActiveRight() {
      this.dpnRequestService.checkActiveRight(this.caseId).subscribe(res => {
         if (res === true) {
            this.toastr.warning('SNACKBAR.USER_ALREADY_HAVE_RIGHT_DPN');
         }
      });
   }
   checkIfSendIsDisabled() {
      return this.caseProblemRecordService.checkIfSendIsDisabled(this.caseData, !this.dpnRequestForm.valid, this.hasProblem);
   }
   ngDoCheck() {
      if (this.subCaseKind.length !== 0 && this.count !== this.subCaseKind.length) {
         if (this.caseData !== null && this.caseData !== undefined) {
            this.count = this.subCaseKind.length;
            this.dpnRequestService.findDpnRequestById(this.caseId).subscribe(result => {
               this.loadedData = result;
               if (this.loadedData !== undefined && this.loadedData !== null) {
                  this.patch = true;
                  this.patchValues();
               }
            });
         }
      }
      if (this.patch === true) {
         if (this.dpnRights.length !== 0) {
            this.patch = false;
            this.dpnRequestForm.controls.subKindRight.setValue(this.dpnRight);
         }
      }
   }
   updateStatus(newStatus: string) {
      this.caseData.caseStatus = newStatus;
      this.enableConclusion = true;
      this.caseHasProblem();
      this.cdr.detectChanges();
      if (this.subject.registrationId === null || this.subject.registrationId === '') {
         this.noRegistrationId = true;
         this.enableConclusion = false;
         this.toastr.info('CASES.NO_REGISTER_NUMBER');
      }
   }

   async sendRequest() {
      this.isSubmited = true;
      const addNewRecordRequered = await this.addNewRecord(this.dpnRequestForm.controls.decisionType.value.id);
      if (addNewRecordRequered) {
         const dpnRequest = { ...this.createDpnRequestObject(), isTempSave: false };
         if (this.showPreview === true) {
            this.dpnRequestService.createDpnRequest(dpnRequest).subscribe(
               (documentId: string) => {
                  if (documentId) {
                     this.toastr.success('SNACKBAR.SOLUTION_CREATED');
                     this.localStorageService.set('case', true);
                     this.expedition.send({ documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'dpn');
                     this.dataSharingService.cases.next(true);
                  } else {
                     this.enableSubmitButton();
                     this.toastr.warning('SNACKBAR.USER_ALREADY_HAVE_RIGHT_DPN');
                  }
               },
               _ => {
                  this.enableSubmitButton();
                  manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
               }
            );
         } else {
            this.dpnRequestService.saveDpnRequest(dpnRequest).subscribe(
               (dpnRequestId: number) => {
                  if (dpnRequestId) {
                     this.caseService.addConclusionFinishCase(this.dpnRequestForm.get('lawyerFile').value, this.subjectId, this.caseData.id).subscribe(
                        (documentId: string) => {
                           this.toastr.success('SNACKBAR.SOLUTION_CREATED');
                           this.localStorageService.set('case', true);
                           this.expedition.send({ documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'dpn');
                        },
                        _ => {
                           manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
                           this.enableSubmitButton();
                        }
                     );
                  } else {
                     this.toastr.warning('SNACKBAR.USER_NOT_HAVE_RIGHT_DPN');
                     this.enableSubmitButton();
                  }
               },
               _ => {
                  manageError(this.toastr, ErrorType.SAVE_DATA);
                  this.enableSubmitButton();
               }
            );
         }
      } else {
         this.enableSubmitButton();
      }
   }

   saveCurrentData() {
      const dpnRequest = { ...this.createDpnRequestObject(), isTempSave: true };
      this.dpnRequestService.saveDpnRequest(dpnRequest).subscribe(
         (dpnRequestId: number) => {
            if (dpnRequestId) {
               this.loadedData = { ...this.loadedData, id: dpnRequestId };
               this.toastr.success('SNACKBAR.SOLUTION_SAVED');
               this.dpnRequestForm.markAsPristine();
            } else {
               this.toastr.warning('SNACKBAR.USER_NOT_HAVE_RIGHT_DPN');
            }
         },
         _ => {
            manageError(this.toastr, ErrorType.SAVE_DATA);
         }
      );
   }

   changeChildCheckbox() {
      this.child = this.dpnRequestForm.value.child;
   }

   getPaymentRecipientCodebook() {
      this.codebookService.getPaymentRecipientCodebook().subscribe(response => {
         this.paymentRecipients = response;
      });
   }

   getDecisionMakerCodebook() {
      this.codebookService.getDecisionMakerCodebook().subscribe(result => {
         this.decisionMakerOptions = result;
         if (!this.loadedData?.socialCase?.decisionMaker) {
            this.dpnRequestForm.controls.decisionMaker.setValue(result[0]);
            this.dpnRequestForm.controls.decisionMakerTitle.setValue(result[0].title);
         } else {
            this.dpnRequestForm.controls.decisionMaker.setValue(result[this.loadedData?.socialCase?.decisionMaker.id - 1]);
            this.dpnRequestForm.controls.decisionMakerTitle.setValue(this.loadedData?.socialCase?.decisionMakerTitle);
         }
      });
   }

   changeSubKindCodebook() {
      this.kind = this.dpnRequestForm.value.subKind;
      this.dpnRequestForm.controls.subKindRight.setValue('');
      this.getDpnRightCodebook(this.kind, this.dpnRequestForm.value.fromDate);
   }

   importSubjectData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         panelClass: 'overlay-panel',
         width: '1200px',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         this.setParent(result.data);
      });
   }

   setParent(parent: any) {
      if (parent) {
         this.parent = parent;
         // const obj = JSON.parse(JSON.stringify(result));
         this.dpnRequestForm.controls.parentFirstName.setValue(parent.firstName);
         this.dpnRequestForm.controls.parentLastName.setValue(parent.lastName);
         this.dpnRequestForm.controls.parentResidenceAddress.setValue(parent.permanentResidence.town);
         this.dpnRequestForm.controls.parentAddress.setValue(parent.permanentResidence.street);
         this.dpnRequestForm.controls.parentAddressNum.setValue(parent.permanentResidence.number);
         this.dpnRequestForm.controls.parentRegBr.setValue(parent.registrationId);
         this.dpnRequestForm.controls.subjectId.setValue(parent.subjectIdentity.subjectId);

         this.dpnRequestForm.controls.parentFirstName.disable();
         this.dpnRequestForm.controls.parentLastName.disable();
         this.dpnRequestForm.controls.parentResidenceAddress.disable();
         this.dpnRequestForm.controls.parentAddress.disable();
         this.dpnRequestForm.controls.parentAddressNum.disable();
         this.dpnRequestForm.controls.parentRegBr.disable();
      }
   }

   getPaymentMethodCodebook() {
      this.codebookService.getPaymentMethodCodebook().subscribe(res => {
         this.paymentMethodCodebook = res;
      });
   }
   getPioDesisions() {
      this.codebookService.getBranchesPioFundCodebook().subscribe(res => {
         this.pioDecisions = res;
         this.pioDecisionsFiltered = this.dpnRequestForm.controls.pioDecision.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(name => (name ? this._filter(name, AutocompleteFilterEnum.PIODECISION) : this.pioDecisions.slice()))
         );
         this.pioFindingsFiltered = this.dpnRequestForm.controls.pioFinding.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(name => (name ? this._filter(name, AutocompleteFilterEnum.PIOFINDING) : this.pioDecisions.slice()))
         );
      });
   }

   getInitiatorCodebook() {
      this.codebookService.getInitiatorCodebook().subscribe(res => {
         this.initiatorCodebook = res;
      });
   }

   getCaseKindViewCodebook(kind: any, dateOfCreation: any) {
      this.dpnRequestForm.controls.dateOfCaseCreation.setValue(new Date(dateOfCreation));
      this.dpnRequestForm.controls.dateOfCaseCreation.disable();
      this.dpnRequestForm.controls.fromDate.setValue(new Date(dateOfCreation));
      this.codebookService.getCaseKindViewCodebookByKind(kind).subscribe(res => {
         this.subCaseKind = res;
         this.subCaseKind = this.subCaseKind.filter((x: any) => x.code !== null && x.code !== 'VS');
      });
   }

   getDpnRightCodebook(caseView: any, date: any) {
      this.codebookService.getDpnRightCodebook(caseView, new Date(date).getTime()).subscribe(res => {
         if (res != null && res.length != 0) {
            this.dpnRights = res;
            if (this.changeDate != null) {
               this.right = this.dpnRights.find((i: any) => i.type === this.changeDate.type);
               this.dpnRequestForm.controls.subKindRight.setValue(this.right);

               this.dpnRequestForm.controls.amount.setValue(this.right.price);
            }
            this.changeDate = null;
         }
      });
   }

   getDpnRightCodebookById(subKindRight: any) {
      this.codebookService.getDpnRightCodebookById(subKindRight).subscribe(res => {
         this.right = res;
         this.dpnRequestForm.controls.amount.setValue(this.right.price);
      });
   }

   changeSubKindRightCodebook() {
      //      this.getDpnRightCodebookById(this.dpnRequestForm.value.subKindRight.id);
      this.right = this.dpnRequestForm.controls.subKindRight.value;
      this.dpnRequestForm.controls.amount.setValue(this.right.price);
   }

   createDpnRequestObject() {
      const dpnRequest = {
         id: this.loadedData ? this.loadedData.id : null,
         socialCaseId: this.caseData.id,
         requestDate: this.datePipe.transform(new Date().getTime(), 'yyyy-MM-dd'),
         subKindId: this.dpnRequestForm.value.subKind,
         subKindRightId: this.dpnRequestForm.value.subKindRight?.id,
         child: this.dpnRequestForm.value.child,
         parentId: this.dpnRequestForm.value.subjectId,
         pioFinding: this.dpnRequestForm.value.pioFinding !== '' ? this.dpnRequestForm.value.pioFinding : null,
         pioNumFinding: this.dpnRequestForm.value.pioNumFinding,
         pioDateFinding:
            this.dpnRequestForm.value.pioDateFinding !== undefined && this.dpnRequestForm.value.pioDateFinding !== null
               ? this.datePipe.transform(new Date(this.dpnRequestForm.value.pioDateFinding).getTime(), 'yyyy-MM-dd')
               : null,
         pioDecision: this.dpnRequestForm.value.pioDecision !== '' ? this.dpnRequestForm.value.pioDecision : null,
         pioNumDecision: this.dpnRequestForm.value.pioNumDecision,
         pioDateDecision:
            this.dpnRequestForm.value.pioDateDecision !== undefined && this.dpnRequestForm.value.pioDateDecision !== null
               ? this.datePipe.transform(new Date(this.dpnRequestForm.value.pioDateDecision).getTime(), 'yyyy-MM-dd')
               : null,
         decisionTypeCodebook: this.dpnRequestForm.value.decisionType !== '' ? this.dpnRequestForm.value.decisionType : null,
         initiatorCodebook: this.dpnRequestForm.value.initiator !== '' ? this.dpnRequestForm.value.initiator : null,
         // treba proveriti na BE je obavezno initiator
         paymentMethodCodebook: this.dpnRequestForm.value.paymentMethod !== '' ? this.dpnRequestForm.value.paymentMethod : null,
         fromDate: this.dpnRequestForm.value.fromDate !== null ? this.datePipe.transform(new Date(this.dpnRequestForm.value.fromDate).getTime(), 'yyyy-MM-dd') : null,
         toDate:
            this.dpnRequestForm.value.toDate !== undefined && this.dpnRequestForm.value.toDate !== null
               ? this.datePipe.transform(new Date(this.dpnRequestForm.value.toDate).getTime(), 'yyyy-MM-dd')
               : null,
         toDateCheck: this.dpnRequestForm.value.toDateCheck,
         amount: this.dpnRequestForm.controls.amount.value,
         terminationOfRights: this.dpnRequestForm.value.terminationOfRights,
         accountNumber: this.dpnRequestForm.value.account,
         paymentRecipient: this.dpnRequestForm.value.paymentRecipient,
         decisionMaker: this.dpnRequestForm.value.decisionMaker ? this.dpnRequestForm.value.decisionMaker : null,
         decisionMakerDescription: this.dpnRequestForm.value.decisionMaker?.id === 2 ? this.dpnRequestForm.value.decisionMakerDescription : '',
         decisionMakerName: this.dpnRequestForm.value.decisionMakerName ? this.dpnRequestForm.value.decisionMakerName : '',
         decisionMakerTitle: this.dpnRequestForm.value.decisionMakerTitle ? this.dpnRequestForm.value.decisionMakerTitle : '',
         inAccordanceWithDecision: this.dpnRequestForm.value.inAccordanceWithDecision,
         dispozitivAddition: this.dpnRequestForm.value.dispozitivAddition,
         terminationReason: this.dpnRequestForm.value.terminationReason !== '' ? this.dpnRequestForm.value.terminationReason : null,
      };

      return dpnRequest;
   }

   // Popunjavanje rjesenja vrijednostima sa forme u PrintPrivew opciji, bez cuvanja podataka u bazi.
   printPreview() {
      const dpnRequest = this.createDpnRequestObject();
      this.dpnRequestService.dpnPreview(dpnRequest);
   }

   patchValues() {
      this.dpnRequestForm = this.formBuilder.group({
         dateOfCaseCreation: new FormControl({ value: new Date(this.caseData.dateOfCreation), disabled: true }),
         requestDate: new FormControl({ value: new Date(), disabled: true }),
         subKindRight: '',
         subKindId: this.loadedData.subKind,
         subKind: this.loadedData.caseKindViewCodebook?.id,
         subKindRightId: this.loadedData.subKindRightId,
         child: this.loadedData.child,
         parentId: this.loadedData.subjectId,
         pioFinding: this.loadedData.pioFinding !== null ? this.loadedData.pioFinding : '',
         pioNumFinding: this.loadedData.pioNumFinding,
         pioDateFinding: this.loadedData.pioDateFinding !== null ? new Date(this.loadedData.pioDateFinding.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : this.loadedData.pioDateFinding,
         pioDecision: this.loadedData.pioDecision !== null ? this.loadedData.pioDecision : '',
         pioNumDecision: this.loadedData.pioNumDecision,
         pioDateDecision: this.loadedData.pioDateDecision !== null ? new Date(this.loadedData.pioDateDecision.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : this.loadedData.pioDateDecision,
         decisionType: this.loadedData.decisionTypeCodebook,
         initiator: this.loadedData.initiatorCodebook,
         paymentMethod: this.loadedData.paymentMethodCodebook,
         fromDate: this.loadedData.fromDate !== null ? new Date(this.loadedData.fromDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : null,
         toDate: this.loadedData.toDate !== null ? new Date(this.loadedData.toDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) : this.loadedData.toDate,
         toDateCheck: this.loadedData.toDateCheck,
         amount: this.loadedData.amount,
         terminationOfRights: this.loadedData.terminationOfRights,
         paymentRecipient: this.loadedData.paymentRecipient,
         account: this.loadedData.socialCase.accountNumber,
         parentFirstName: new FormControl({ value: '', disabled: true }),
         parentLastName: new FormControl({ value: '', disabled: true }),
         parentResidenceAddress: new FormControl({ value: '', disabled: true }),
         parentAddress: new FormControl({ value: '', disabled: true }),
         parentAddressNum: new FormControl({ value: '', disabled: true }),
         parentRegBr: new FormControl({ value: '', disabled: true }),
         subjectId: [''],
         refusalReason: [''],
         lawyerFile: [''],
         fileName: [''],
         decisionMaker: this.decisionMakerOptions === undefined ? null : this.decisionMakerOptions[this.loadedData.socialCase.decisionMaker.id - 1],
         decisionMakerName: this.loadedData.socialCase.decisionMakerName,
         decisionMakerTitle: this.loadedData.socialCase.decisionMakerTitle,
         decisionMakerDescription: this.loadedData.socialCase.decisionMakerDescription,
         inAccordanceWithDecision: this.loadedData.inAccordanceWithDecision,
         dispozitivAddition: this.loadedData.dispozitivAddition,
         terminationReason: this.loadedData.terminationReason,
      });
      this.dpnRequestForm.controls.amount.disable();
      this.dpnRight = this.loadedData.subKindRightId;
      if (this.loadedData.caseKindViewCodebook?.id) {
         this.getDpnRightCodebook(this.loadedData.caseKindViewCodebook?.id, this.caseData.dateOfCreation);
         this.patchSubkindRigthCodebook(this.loadedData.caseKindViewCodebook?.id, this.loadedData.subKindRightId);
      }
      //      this.getDpnRightCodebookById(this.loadedData.subKindRightId);
      this.kind = this.dpnRequestForm.value.subKind;
      this.ShowPreviewButton(this.loadedData.decisionTypeCodebook);
      if (this.loadedData.child) {
         this.child = true;
         this.setParent(this.loadedData.parent);
      }

      this.setValueChangesEvents();
   }

   // Poredjenje ucitanih vrijednosti za sifrarnike prilikom mapiranja podataka (patch vrijednosti)
   compareLoadedObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
   }

   /**
    * _filter
    * @param value input value
    * @param what which filter should be applied (check AutocompleteFilterEnum)
    */
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.PIODECISION:
            return sortByFirstLetter(
               this.pioDecisions.filter((option: any) => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         case AutocompleteFilterEnum.PIOFINDING:
            return sortByFirstLetter(
               this.pioDecisions.filter((option: any) => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         default:
            break;
      }
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.branchTypeCodebook.title + ' ' + objectValue.name : '';
   }

   CheckConclusions(checkPio: boolean) {
      if (checkPio) {
         return autoSelect(this.pioDecisions, 'pioDecision', 'name', this.dpnRequestForm);
      }
      if (
         (this.dpnRequestForm.controls.pioDecision.value.branchTypeCodebook === null || this.dpnRequestForm.controls.pioDecision.value.branchTypeCodebook === undefined) &&
         this.dpnRequestForm.controls.pioDecision.value !== ''
      ) {
         setTimeout(() => {
            this.dpnRequestForm.controls.pioDecision.setErrors({ randomInput: true });
         });
      } else {
         setTimeout(() => {
            this.dpnRequestForm.controls.pioDecision.setErrors(null);
         });
      }
   }

   CheckFindings(checkPio: boolean) {
      if (checkPio) {
         return autoSelect(this.pioDecisions, 'pioFinding', 'name', this.dpnRequestForm);
      }
      if (
         (this.dpnRequestForm.controls.pioFinding.value.branchTypeCodebook === null || this.dpnRequestForm.controls.pioFinding.value.branchTypeCodebook === undefined) &&
         this.dpnRequestForm.controls.pioFinding.value !== ''
      ) {
         setTimeout(() => {
            this.dpnRequestForm.controls.pioFinding.setErrors({ randomInput: true });
         });
      } else {
         setTimeout(() => {
            this.dpnRequestForm.controls.pioFinding.setErrors(null);
         });
      }
   }
   ShowPreviewButton(selection: any) {
      if (selection?.id === 1) {
         this.showPreview = true;
         this.dpnRequestForm.controls.subKind.enable();
         this.dpnRequestForm.controls.subKindRight.enable();
         this.dpnRequestForm.controls.fileName.clearValidators();
         this.dpnRequestForm.controls.fileName.setErrors(null);
         this.dpnRequestForm.controls.fileName.setValue(null);
         this.dpnRequestForm.controls.fileName.disable();
         this.dpnRequestForm.controls.account.enable();
         // this.dpnRequestForm.controls.account.setValidators(Validators.required);
      } else {
         this.showPreview = false;
         this.dpnRequestForm.controls.subKind.disable();
         this.dpnRequestForm.controls.subKindRight.disable();
         this.dpnRequestForm.controls.subKind.setErrors(null);
         this.dpnRequestForm.controls.subKindRight.setErrors(null);
         this.dpnRequestForm.controls.fileName.enable();
         this.dpnRequestForm.controls.fileName.setValidators(Validators.required);

         this.dpnRequestForm.controls.account.setValue('');
         this.dpnRequestForm.controls.account.clearValidators();
         this.dpnRequestForm.controls.account.setErrors(null);
         this.dpnRequestForm.controls.account.disable();
      }
      if (selection?.id === 3) {
         this.dpnRequestForm.controls.terminationReason.enable();
         this.dpnRequestForm.controls.terminationReason.setValidators(Validators.required);
         if (this.terminationReasons.length == 0) {
            this.codebookService.getTerminationReasonByType('OPSTI').subscribe(res => {
               this.terminationReasons = res;
            });
         }
      } else {
         this.dpnRequestForm.controls.terminationReason.disable();
         this.dpnRequestForm.controls.terminationReason.setErrors(null);
         this.dpnRequestForm.controls.terminationReason.setValue(null);
         this.dpnRequestForm.controls.terminationReason.clearValidators();
      }
   }

   handleFileInput(files: FileList) {
      const modifiedFiles = fileCheck(files, this.dialog, this.translate);
      if (modifiedFiles.length > 0) {
         this.fileToUpload = modifiedFiles[0];
         this.dpnRequestForm.get('lawyerFile').setValue(this.fileToUpload);
         this.dpnRequestForm.get('fileName').setValue(this.fileToUpload.name);
      }
      this.file.nativeElement.value = null;
   }

   handleNewEvaluation(doc: any) {
      //    window.location.href = 'ms-word:ofe|u|' + url;
      const body = {
         documentId: doc.documentId,
         fileName: doc.name,
         caseId: this.caseId === undefined ? null : this.caseId,
      };
      this.alfrescoService.downloadDocumentByUrl(body);
   }

   getDocumentsByTemplate() {
      this.alfrescoService.getDocumentTemplates(this.caseData.socialCaseClassificationCodebook.oldKindMark).subscribe(result => {
         this.documentOptions = result;
      });
   }
   paymentMethodChanged() {
      if (this.dpnRequestForm.value.paymentMethod === null || this.dpnRequestForm.value.paymentMethod.id === 1 || this.dpnRequestForm.value.paymentMethod.id === 6) {
         this.dpnRequestForm.controls.account.setValue('');
         this.dpnRequestForm.controls.account.clearValidators();
         this.dpnRequestForm.controls.account.setErrors(null);
         this.dpnRequestForm.controls.account.disable();
      } else if (this.dpnRequestForm.value.paymentMethod !== null) {
         this.dpnRequestForm.controls.account.enable();
         if (this.dpnRequestForm.value.paymentMethod.id !== 2) {
            this.dpnRequestForm.controls.account.setValidators(Validators.required);
         } else {
            this.dpnRequestForm.controls.account.clearValidators();
            this.dpnRequestForm.controls.account.setErrors(null);
         }
      }
   }

   acceptedComplain() {
      this.caseData.newComplain = false;
   }

   patchSubkindRigthCodebook(caseView: any, subKindRightId: any) {
      this.codebookService.getDpnRightCodebook(caseView, new Date(this.caseData.dateOfCreation).getTime()).subscribe(res => {
         this.dpnRights = res;
         for (const dpn of this.dpnRights) {
            if (dpn.type === subKindRightId) {
               this.dpnRequestForm.controls.subKindRight.setValue(dpn);
               break;
            }
         }
      });
   }

   decisionMakerSelected(event: any) {
      if (event.value.id === 2) {
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TEXT').subscribe((res: string) => {
            this.dpnRequestForm.controls.decisionMakerDescription.setValue(res);
         });
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TITLE').subscribe((res: string) => {
            this.dpnRequestForm.controls.decisionMakerTitle.setValue(res);
         });
      } else {
         this.dpnRequestForm.controls.decisionMakerDescription.setValue('');
         this.dpnRequestForm.controls.decisionMakerTitle.setValue(event.value.title);
      }
   }

   private enableSubmitButton() {
      this.submitButton.disabled = false;
      this.isSubmited = false;
   }

   problemAdded(added: boolean) {
      if (added) {
         this.hasProblem = true;
         this.cdr.detectChanges();
      }
   }
   caseHasProblem() {
      this.caseProblemRecordService.caseHasProblem(this.caseData?.caseStatus, this.caseId, this.caseData?.caseKindCodebook?.kind).subscribe(hasProblem => {
         this.hasProblem = hasProblem;
      });
   }

   async addNewRecord(decision: any): Promise<boolean> {
      if (decision === 1) {
         try {
            return await this.caseProblemRecordService.addNewRecord(this.subjectId, this.caseId, this.caseData?.caseKindCodebook?.kind, {
               fromDate: this.dpnRequestForm.controls.fromDate.value,
               toDate: this.dpnRequestForm.controls.toDate.value,
               amount: this.dpnRequestForm.controls.amount.value,
            });
         } catch (error) {
            return false;
         }
      }
      return true;
   }
   async saveChanges(): Promise<boolean> {
      if (this.isSubmited || this.dpnRequestForm.pristine) {
         return true;
      }
      let message = '';
      let title = '';
      this.translate.get('WARNING_DIALOG.SAVE_DATA.TITLE').subscribe((res: string) => {
         title = res;
      });
      this.translate.get('WARNING_DIALOG.SAVE_DATA.MESSAGE').subscribe((res: string) => {
         message = res;
      });
      const object = {
         document: null,
         message: message,
         title: title,
         saveData: true,
      };

      const dialogRef = this.dialog.open(WarningMessageComponent, {
         data: object,
         width: ModalSizeEnum.MINI,
         panelClass: 'overlay-panel',
      });
      await dialogRef
         .afterClosed()
         .toPromise()
         .then(res => {
            if (res?.event !== 'cancel' && res !== undefined) {
               return this.saveCurrentData();
            }
         });
      return true;
   }
}
