import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EstablishedFosterCareService } from 'src/services/established-foster-care.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { TerminationOfFosterCareComponent } from './termination-of-foster-care/termination-of-foster-care.component';

@Component({
  selector: 'app-established-foster-care-details',
  templateUrl: './established-foster-care-details.component.html',
  styleUrls: ['./established-foster-care-details.component.scss']
})
export class EstablishedFosterCareDetailsComponent implements OnInit {

  displayedColumns: string[] = [
    'firstName',
    'dateOfBirth',
    'kinship',
    'formOfFosterCare',
    'dateOfPlacementInAFosterFamily',
    'childSupportBearers',
    'dateOfCustodyTermination',
    'reasonsForCustodyTermination',
    'courtNumber',
    'courtName',
    'competentCenter',
    'actions'
  ];

  establishedFosterCare: any;
  establishedFosterCareId: any;
  year: any;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrImplService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private establishedFosterCareService: EstablishedFosterCareService) {
    this.route.params.subscribe((params) => {
      this.establishedFosterCareId = params.id;
      this.year = params.year;
    });
    this.getEstablishedFosterCare();
  }

  ngOnInit(): void {
  }
  getEstablishedFosterCare() {
    this.establishedFosterCareService.findByIdAndCsrId(this.establishedFosterCareId).subscribe((res) => {
      this.establishedFosterCare = res;
      console
      this.establishedFosterCare.fosterParentDateOfBirth =
      this.establishedFosterCare.fosterParentDateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      for (const element of this.establishedFosterCare.children) {
        element.dateOfBirth = element.dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      }
    });

  }

  openNewTermination(element: any) {
    let dialogRef: any;
    dialogRef = this.dialog.open(TerminationOfFosterCareComponent, {
        width: ModalSizeEnum.EXTRA_LARGE,
        panelClass:'overlay-panel',
        data: {child: element}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
    if (result && result.event === ModalEventEnum.SUCCESS) {
        const identity = this.establishedFosterCareId;
        this.establishedFosterCareService.terminateFosterCare(element.id, {...result.data, identity}).subscribe((result) => {
              this.toastr.success('SNACKBAR.INPUT_ADDED');
              this.updateRow(element, result);
            });
    }
    });
  }

  updateRow(childToChange: any, result: any) {
     for (const child of this.establishedFosterCare.children) {
        if (child.id === childToChange.id) {
            child.dateOfCustodyTermination = result.dateOfCustodyTermination;
            child.reasonsForCustodyTermination = result.reasonsForCustodyTermination;
            child.courtNumber = result.courtNumber;
            child.courtName = result.courtName;
        }
      }
  }
}
