/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { CaseService } from 'src/services/case.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { TranslatorService } from 'src/services/translator.service';
import { UserService } from 'src/services/user.service';
import { ModalSizeEnum, Subject } from 'src/types';
import { ArchiveSubjectComponent } from './archive-subject/archive-subject.component';
import { DialogActions } from 'src/app/utils/DialogActionsArchive';
import { formatDate } from 'src/app/utils/formatDate';

@Component({
   selector: 'app-subject-details',
   templateUrl: './subject-details.component.html',
   styleUrls: ['./subject-details.component.scss'],
})
export class SubjectDetailsComponent implements OnInit {
   showDivider = false;
   minFromDate = new Date();
   loggedIn: any;
   isSubjectHistoryRendered: Boolean = false;
   isMainCenterBelgrade = false;
   isUserDrugostepeni = false;
   editUserDetailsText = '';
   editUsertDetailTextError = '';
   localStorageCenterId: any;
   isAdmin: boolean = false;
   constructor(
      private route: ActivatedRoute,
      private translate: TranslateService,
      private subjectService: SubjectsService,
      private translatorService: TranslatorService,
      public dialog: MatDialog,
      private dataSharingService: DataSharingService,
      private localStorageService: LocalStorageService,
      private router: Router,
      private caseService: CaseService,
      private toastr: ToastrImplService,
      private userService: UserService
   ) {
      // this.tabs = this.sharedCaseService.tabs;
      this.getNationalities();
      this.localStorageCenterId = this.localStorageService.get('localStorageCenterId');
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      this.isAdmin = this.userService.isGlobalAdmin();

      this.route.params.subscribe(params => {
         this.subjectId = params.id;
         if (!this.isMainCenterBelgrade && !this.isUserDrugostepeni && !this.isAdmin) {
            this.getCurrentSubject(this.subjectId, this.loggedIn?.csrId);
         } else {
            if (this.localStorageCenterId !== undefined && this.localStorageCenterId !== 0) {
               this.getCurrentSubject(this.subjectId, this.localStorageCenterId);
            } else {
               this.router.navigate(['/subjects/']);
            }
         }
      });

      this.translate.get('SUBJECT_DETAILS.DOSSIER_CHANGE_TOOLTIP').subscribe((resp: string) => {
         this.editUserDetailsText = resp;
      });

      this.translate.get('SUBJECT_DETAILS.ARHIVED_DOSSIER_CHANGE_TOOLTIP').subscribe((resp: string) => {
         this.editUsertDetailTextError = resp;
      });
   }
   selectTab(tab: any) {
      if (tab.index === 2) {
         this.isSubjectHistoryRendered = true;
      }
   }
   isDossierAssignedToViewer: boolean;
   isArchived: boolean;
   isEditMode = false;
   subjectId: any;
   subject: Subject;
   nationality = [];
   businessAbility: string;
   category = []; // category of person for nationality
   subjectNationality: string;
   subjectCategory: string;
   familyDossier: any;
   familyType: any;
   date = ['', '', ''];
   gender: boolean;
   jmbg: any;
   object: any = {
      subject: '',
      action: '',
   };
   registrationId = null;

   ngOnInit(): void {}

   public patchSubjectAfterEdit(subject: Subject): void {
      if (typeof subject !== 'undefined') {
         // this.subject = subject;
         this.getCurrentSubject(subject.subjectIdentity.subjectId, subject.subjectIdentity.csrId);
         this.isArchived = subject.status.id === 2;
         this.toastr.success('SNACKBAR.DATA_CHANGED');
         this.isSubjectHistoryRendered = false;
      }
      this.isEditMode = false;
   }

   getCurrentSubject(jmbg: any, centerId: any) {
      this.subjectService.getCurrentSubject(jmbg, centerId).subscribe(res => {
         this.subject = res;
         if (this.subject.dateOfBirth !== null) {
            this.subject.dateOfBirth = this.subject.dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
         }
         this.subject.dateOfModification = this.subject.dateOfModification.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
         this.subject.dateOfCreation = this.subject.dateOfCreation.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
         this.registrationId = res.registrationId;
         this.subjectNationality = res?.nationality?.title;
         this.subjectCategory = res?.category?.title;

         if (this.subject?.disabledPerson || this.subject?.disabilityType || this.subject?.occupation || this.subject?.employment || this.subject?.education || this.subject?.maritalStatus) {
            this.showDivider = true;
         }

         if (res.familyDossier === 'NONE') {
            res.familyDossier = null;
         } else {
            this.translate.get('SUBJECTS.FAMILY_DOSSIER.' + res.familyDossier).subscribe((result: string) => {
               this.familyDossier = result;
            });
         }

         if (res.familyType === 'NONE') {
            res.familyType = null;
         } else {
            this.translate.get('SUBJECTS.FAMILY_TYPE.' + res.familyType).subscribe((result: string) => {
               this.familyType = result;
            });
         }

         // u bazi pasivno je 2
         this.isArchived = res.status.id === 2;
      });
   }

   formatDate(date: string) {
      const [y, m, d] = date.split('-');

      return [d, m, y].join('.');
   }

   editSubject() {
      this.isEditMode = true;
   }

   getNationalities() {
      this.nationality = this.translatorService.translateNationalities();
      this.translate.onLangChange.subscribe(() => {
         this.nationality = [];
         this.nationality = this.translatorService.translateNationalities();
      });
   }

   openDialog(action: 'archive' | 'unarchive', obj: any) {
      this.object.subject = obj;
      this.object.action = action;

      if (action === DialogActions.ARCHIVE) {
         this.caseService.getActiveCasesBySubjectId(this.subjectId).subscribe(res => {
            let assignedCount = 0;
            let unassignedCount = 0;
            let acceptedCount = 0;
            [assignedCount, acceptedCount, unassignedCount] = this.countByStatusCases(res);
            if (assignedCount === 0 && unassignedCount === 0 && acceptedCount === 0) {
               this.openArchiveComponent();
            } else {
               if (assignedCount > 0 || acceptedCount > 0) {
                  this.toastr.error('NEW_SUBJECT.SUBJECT_HAS_ACTIVE_CASE', 'NEW_SUBJECT.ARCHIVE_IMPOSIBLE');
               } else if (unassignedCount > 0) {
                  this.toastr.error('NEW_SUBJECT.SUBJECT_HAS_UNASSIGNED_CASES', 'NEW_SUBJECT.ARCHIVE_IMPOSIBLE');
               }
            }
         });
      }
      if (action === DialogActions.UNARCHIVE) {
         this.openArchiveComponent();
      }
   }

   reloadUser() {
      this.isAdmin ? this.getCurrentSubject(this.subjectId, this.localStorageCenterId) : this.getCurrentSubject(this.subjectId, this.loggedIn?.csrId);
      this.isSubjectHistoryRendered = false;
   }

   openArchiveComponent() {
      const dialogRef = this.dialog.open(ArchiveSubjectComponent, {
         panelClass: 'overlay-panel',
         disableClose: true,
         width: ModalSizeEnum.MINI,
         data: this.object,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === DialogActions.ARCHIVE || event === DialogActions.UNARCHIVE) {
            this.subject.dateOfModification = formatDate(new Date());
            this.isSubjectHistoryRendered = false;
            this.subject.status = data;
            if (event === DialogActions.ARCHIVE) {
               this.isArchived = true;
               this.toastr.success('SNACKBAR.SUBJECT_ARCHIVED');
               this.dataSharingService.subjectStatusChange.next(true);
            } else {
               this.isArchived = false;
               this.toastr.success('SNACKBAR.SUBJECT_RETURNED_FROM_ARCHIVE');
               this.dataSharingService.subjectStatusChange.next(false);
            }
         } else if (event !== 'Cancel') {
            this.toastr.error('SNACKBAR.SUBJECT_NOT_ARCHIVED');
         }
      });
   }

   countByStatusCases(cases: any) {
      let unassignedCount = 0;
      let assignedCount = 0;
      let acceptedCount = 0;
      for (const element of cases) {
         if (element.caseStatus === 'ASSIGNED') {
            assignedCount++;
         } else if (element.caseStatus === 'ACCEPTED') {
            acceptedCount++;
         } else {
            unassignedCount++;
         }
      }
      return [assignedCount, acceptedCount, unassignedCount];
   }
}
