/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CaseService } from 'src/services/case.service';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlfrescoService } from 'src/services/alfresco.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { VsService } from 'src/services/vs.service';
import { ModalSizeEnum } from 'src/types';
import { environment } from 'src/environments/environment';
import { DataSharingService } from 'src/services/data-sharing.service';
import { documentSigningNotification } from 'src/models/documentsigningnotification';
import { UserService } from 'src/services/user.service';
import { TeamRequestService } from 'src/services/team-request.service';
import { AccommodationService } from 'src/services/accommodation-service';
import { AccommodationLawyerComponent } from './accommodation-lawyer/accommodation-lawyer.component';
import { CaseProblemRecordService } from 'src/services/case-problem-record.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { VsLawyerComponent } from './vs-lawyer/vs-lawyer.component';
import { ExpeditionService } from 'src/services/expedition.service';
import { SubjectDocumentsDialogComponent } from 'src/app/sidebar-widget/subject-documents/subject-documents-dialog/subject-documents-dialog.component';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { TranslateService } from '@ngx-translate/core';
import { InitialAssessmentPlanComponent } from './initial-assessment-plan/initial-assessment-plan.component';
import { InitialAssessmentComponent } from './initial-assessment/initial-assessment.component';
import { TargetedAssessmentPlanComponent } from './targeted-assessment-plan/targeted-assessment-plan.component';
import { TargetedAssessmentComponent } from './targeted-assessment/targeted-assessment.component';
import { MeasuresAndServicesComponent } from './measures-and-services/measures-and-services.component';
import { EvaluationAndReassessmentComponent } from './evaluation-and-reassessment/evaluation-and-reassessment.component';
import { AccommodationComponent } from './accommodation/accommodation.component';
import { ViolenceComponent } from './violence/violence.component';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
interface TypeListItem {
   documentId: string;
   name: string;
}
@Component({
   selector: 'app-vs',
   templateUrl: './vs.component.html',
   styleUrls: ['./vs.component.scss'],
})
export class VsComponent implements OnInit {
   object: any = {
      document: '',
      action: '',
      subjectId: '',
      message: '',
      title: '',
   };
   step01 = {
      id: 0,
      stepStatus: 'INITIAL', // 'INITIAL' | 'STARTED' | 'FINISHED'
      title: 'Hello There',
   };
   step02 = {
      id: 0,
      stepStatus: 'INITIAL', // 'INITIAL' | 'STARTED' | 'FINISHED'
      title: 'Hello There',
   };
   step03 = {
      id: 0,
      stepStatus: 'INITIAL', // 'INITIAL' | 'STARTED' | 'FINISHED'
      title: 'Hello There',
   };
   step04 = {
      id: 0,
      stepStatus: 'INITIAL', // 'INITIAL' | 'STARTED' | 'FINISHED'
      title: 'Hello There',
   };
   step05 = {
      id: 0,
      stepStatus: 'INITIAL', // 'INITIAL' | 'STARTED' | 'FINISHED'
      title: 'Hello There',
   };
   step06 = {
      id: 0,
      stepStatus: 'INITIAL', // 'INITIAL' | 'STARTED' | 'FINISHED'
      title: 'Hello There',
   };
   step07 = {
      id: 0,
      stepStatus: 'INITIAL', // 'INITIAL' | 'STARTED' | 'FINISHED'
      title: 'Hello There',
   };
   isCaseClosed = false;
   remainingTime: any;
   currentDate: any;
   caseName: any;
   caseId: any;
   subjectId: any;
   caseData: any;
   isValid: boolean;
   isDisabled = [true, true, true, true, true, true];
   activeTabs: number[];
   waitingForApproval = false;
   approved = false;
   firstStep: FormGroup;
   secondStep: FormGroup;
   thirdStep: FormGroup;
   fourthStep: FormGroup;
   fifthStep: FormGroup;
   seventhStep: FormGroup; // smjestaj
   eighthStep: FormGroup; // nasilje
   openFirstStep = false;
   formNumber: any;
   subject: any;
   isLawyer: any = false;
   enableConclusion = false;
   step01data: any;
   loggedIn: any;
   documentSigned: boolean;
   teamMember = false;
   fileToUpload: File = null;
   closingCaseData = null;
   accomodation = null;
   hasTeamRequest = false;
   targetedAssesmentPlanDocuments: any;
   targetedAssesmentDocuments: any;
   initialAssesmentPlanDocuments: any;
   title: string;
   noRegistrationId = false;
   isDenial = false;
   @ViewChild('submitButton') submitButton: any;
   isSubmited = false;
   hasProblem = true;
   @ViewChild(AccommodationLawyerComponent) private accommodationLawyer: AccommodationLawyerComponent;
   @ViewChild(VsLawyerComponent) private vsLawyer: VsLawyerComponent;
   @ViewChild(InitialAssessmentPlanComponent) private initialAssessmentPlanComponent: InitialAssessmentPlanComponent;
   @ViewChild(InitialAssessmentComponent) private initialAssessmentComponent: InitialAssessmentComponent;
   @ViewChild(TargetedAssessmentPlanComponent) private targetedAssessmentPlanComponent: TargetedAssessmentPlanComponent;
   @ViewChild(TargetedAssessmentComponent) private targetedAssessmentComponent: TargetedAssessmentComponent;
   @ViewChild(MeasuresAndServicesComponent) private measuresAndServicesComponent: MeasuresAndServicesComponent;
   @ViewChild(EvaluationAndReassessmentComponent) private evaluationAndReassessmentComponent: EvaluationAndReassessmentComponent;
   @ViewChild(AccommodationComponent) private accommodationComponent: AccommodationComponent;
   @ViewChild(ViolenceComponent) private violenceComponent: ViolenceComponent;

   constructor(
      private vsService: VsService,
      private router: Router,
      private caseService: CaseService,
      private cdr: ChangeDetectorRef,
      public dialog: MatDialog,
      private alfresco: AlfrescoService,
      private localStorageService: LocalStorageService,
      private toastr: ToastrImplService,
      private userService: UserService,
      private accommodationService: AccommodationService,
      private teamRequestService: TeamRequestService,
      private dataSharingService: DataSharingService,
      private expedition: ExpeditionService,
      private translate: TranslateService,
      private caseProblemRecordService: CaseProblemRecordService
   ) {
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
      this.isLawyer = this.userService.isUserLawyer();

      this.router.events.subscribe(ev => {
         if (ev instanceof NavigationEnd) {
            if (ev.url.includes('/cases/')) {
               const [, caseType] = ev.url.split('/cases/');
               if (caseType !== '') {
                  this.caseData = null;
                  [this.caseName, this.caseId, this.subjectId] = caseType.split('/');
                  this.isValid = this.subjectId !== undefined || this.subjectId !== null;
                  this.caseId = Number(this.caseId);
                  this.subjectId = Number(this.subjectId);
                  if (this.isValid && !this.caseData) {
                     this.caseService.getSocialCase(this.caseId).subscribe(result => {
                        this.caseData = result;
                        this.subject = result.subject;
                        if (this.subject.registrationId === null || this.subject.registrationId === '') {
                           this.noRegistrationId = true;
                           this.toastr.info('CASES.NO_REGISTER_NUMBER');
                        }
                        this.title = this.caseData.socialCaseClassificationCodebook.title;
                        this.isDenial = this.title.toLowerCase().includes('престанак');
                        if (this.caseData.closingVs !== null) {
                           this.waitingForApproval = true;
                           this.closingCaseData = this.caseData.closingVs;
                           if (this.caseData.closingVs.reviewed !== false) {
                              if (this.caseData.closingVs.decision === true) {
                                 this.approved = true;
                              } else {
                                 this.waitingForApproval = false;
                              }
                           }
                        }
                        if (this.loggedIn?.id !== this.caseData.assignedUserId) {
                           this.teamMember = true;
                        } else {
                           this.teamMember = false;
                           this.teamRequestService.caseHaveNotProcessedTeamRequest(this.caseId).subscribe(response => {
                              this.hasTeamRequest = response;
                           });
                        }
                        if (this.caseData.forms23.length !== 0) {
                           this.documentSigned = this.caseData.forms23[this.caseData.forms23.length - 1].approved;
                        }
                        const difference = this.calculateDiff(this.caseData.dateOfCreation);
                        if (this.caseData.priority === 1) {
                           this.remainingTime = 0;
                        } else if (this.caseData.priority === 2) {
                           this.remainingTime = this.calculateRemaining(difference, 72);
                        } else {
                           this.remainingTime = this.calcWorkHours(this.caseData.dateOfCreation, new Date(new Date(this.caseData.dateOfCreation).getTime() + 1000 * 60 * 60 * 24 * 5));
                           this.calculateRemaining(difference, 120);
                        }
                        this.step01data = {
                           caseId: this.caseData.id,
                           subjectId: this.caseData.subject.subjectIdentity.subjectId,
                        };
                        this.formNumber = result.socialCaseClassificationCodebook.caseKindViewCodebook.formType;
                        if (this.caseData !== undefined && this.caseData !== null) {
                           this.subject = this.caseData.subject;
                        }
                        if (this.caseData?.socialCaseClassificationCodebook.caseKindViewCodebook.type === 1) {
                           this.accommodationService.accommodationForCase(this.caseId).subscribe(res => {
                              if (res !== null) {
                                 this.accomodation = res;
                              }
                           });
                        }
                        this.caseHasProblem();
                     });
                  }
               }
            }
         }
      });

      this.dataSharingService.documentSigned.subscribe((data: documentSigningNotification) => {
         if (this.caseId !== undefined && data !== null) {
            if (data.caseId === this.caseId && data.document === environment.vsDocumentZakljucak) {
               this.caseService.getSocialCase(this.caseId).subscribe(result => {
                  this.caseData.closingVs = result.closingVs;
                  this.closingCaseData = this.caseData.closingVs;
                  if (this.caseData.closingVs.reviewed !== false) {
                     if (this.caseData.closingVs.decision === true) {
                        this.approved = true;
                     } else {
                        this.waitingForApproval = false;
                     }
                  }
                  this.cdr.detectChanges();
               });
            }
         }
      });

      if (!this.isLawyer) {
         this.checkSupervisor();
      }
   }

   ngOnInit(): void {
      this.findDocumentsForVs();
      this.dataSharingService.newDocument.subscribe(result => {
         if (result === true) {
            this.findDocumentsForVs();
         }
      });
   }

   calculateDiff(dateSent: any) {
      const currentDate = new Date();
      dateSent = new Date(dateSent);
      return Math.floor(
         (Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 24)
      );
   }

   calculateRemaining(started: any, defined: any) {
      const remaining = defined - started;
      if (remaining < 0) {
         return 0;
      } else {
         return remaining;
      }
   }

   calcWorkHours(start: any, end: any) {
      const total = this.calculateDiff(start) / 60; // compute difference in days
      const totalDays = parseInt(total.toString(), 10); // trim fractional portion
      const remainder = (total - totalDays) * 24; // save fractional portion in hours
      if (remainder !== 0) {
         const startingDay = new Date(start).getDay(); // starting weekday
         const endingDay = new Date(end).getDay(); // ending weekday
         const possibleWorkingDays = totalDays * 5;
         const actualOccurringWeekends = (startingDay - endingDay) * 2;
         let workDays = (possibleWorkingDays - actualOccurringWeekends) / 7;
         if (endingDay === 6) {
            workDays -= 1; // ends on a weekend, subtract a day
         }
         if (startingDay === 0) {
            workDays -= 1; // starts on a weekend, subtract a day
         }
         return workDays * 24 + remainder; // convert to hours and add back fractional portion
      } else {
         return remainder;
      }
   }

   updateStatus(newStatus: string) {
      this.caseData.caseStatus = newStatus;
      this.enableConclusion = true;
      this.caseHasProblem();
      this.cdr.detectChanges();
      this.noRegistrationId = false;
      this.subject = this.caseData.subject;
      if (this.subject.registrationId === null || this.subject.registrationId === '') {
         this.noRegistrationId = true;
         this.toastr.info('CASES.NO_REGISTER_NUMBER');
      }
   }

   updateFirstStep(step: FormGroup) {
      this.firstStep = step;
   }

   updateSecondStep(step: FormGroup) {
      this.secondStep = step;
   }

   updateThirdStep(step: FormGroup) {
      this.thirdStep = step;
   }

   updateFourthStep(step: FormGroup) {
      this.fourthStep = step;
   }

   updateFifthStep(step: FormGroup) {
      this.fifthStep = step;
   }

   updateSeventhStep(step: FormGroup) {
      this.seventhStep = step;
   }

   updateEighthStep(step: FormGroup) {
      this.eighthStep = step;
   }

   handleStepSubmit({ step, data }) {
      if (step === 'STEP_01' || step === 'STEP_03' || step === 'STEP_04') {
         const body = {
            socialCaseId: this.caseId,
            assesmentText: data.assesmentText,
            type: data.type,
         };
         this.vsService.addAssesment(body).subscribe(
            response => {
               this.findDocumentsForVs();
               this.cdr.detectChanges();
            },
            error => {}
         );
      } else if (step === 'STEP_02') {
         this.caseService.approveInitialAssesment(this.caseId).subscribe(
            result => {},
            error => {
               console.log(error);
            }
         );
      } else if (step === 'STEP_06') {
         this.vsService.updateEvaluationOfPlan(data, this.caseId).subscribe(
            res => {
               this.caseData.evaluationOfPlan.splice(0, 0, res);
            },
            error => {
               console.log(error);
            }
         );
      }
   }

   async handleCloseCase({ status, res }) {
      const addNewRecordRequered = await this.addNewRecord(null);
      if (addNewRecordRequered) {
         let body = null;
         if (res.sendDirectly) {
            const dialogRef = this.dialog.open(SubjectDocumentsDialogComponent, {
               width: ModalSizeEnum.DEFAULT,
               panelClass: 'overlay-panel',
            });
            dialogRef.afterClosed().subscribe(({ event, ...rest }) => {
               this.caseService.addConclusionFinishCase(res.fileToUpload, this.subjectId, this.caseData.id).subscribe(
                  (documentId: string) => {
                     this.toastr.success('SNACKBAR.DOCUMENT_ADDED');
                     this.expedition.send({ documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'vs');
                     this.localStorageService.set('case', true);
                  },
                  error => {
                     manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
                  }
               );
            });
         } else {
            body = {
               closeCaseCodebook: res.closeCaseCodebook,
               caseId: this.caseId,
               explanation: res.explanation,
               comment: res.comment,
            };
            this.vsService.closeCaseVs(body).subscribe(
               () => {
                  this.waitingForApproval = true;
                  this.caseData.closingVs = res;
                  if (this.approved === true) {
                     this.toastr.success('SNACKBAR.SUBJECT_CLOSED');
                     this.cdr.detectChanges();
                     this.localStorageService.set('case', true);
                     this.dataSharingService.cases.next(true);
                     this.router.navigateByUrl('/subjects/' + this.subjectId);
                  }
               },
               error => {
                  manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
               }
            );
         }
      }
   }

   uploadFile(type: any) {
      this.alfresco.postFileForSubjectsCase(this.fileToUpload, this.subjectId, this.caseId, type).subscribe(() => {});
   }
   handleNewTeamRequest() {
      this.hasTeamRequest = true;
   }

   updateAccommodation(accommodation: any) {
      this.accomodation = accommodation;
   }

   public findDocumentsForVs() {
      this.alfresco.findTargetedAssesment(this.caseId).subscribe(result => {
         const documentForCase = result;
         this.targetedAssesmentDocuments = documentForCase.filter(o => o.typeOfVs === environment.vsDocumentTargetedAssessment);
         this.targetedAssesmentDocuments = this.targetedAssesmentDocuments.sort(
            (y, x) =>
               new Date(x.dateOfModification.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$2/$1/$3')).getTime() - new Date(y.dateOfModification.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$2/$1/$3')).getTime()
         );

         this.targetedAssesmentPlanDocuments = documentForCase.filter(o => o.typeOfVs === environment.vsDocumentTargetedAssessmentPlan);
         this.targetedAssesmentPlanDocuments = this.targetedAssesmentPlanDocuments.sort(
            (y, x) =>
               new Date(x.dateOfModification.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$2/$1/$3')).getTime() - new Date(y.dateOfModification.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$2/$1/$3')).getTime()
         );
         this.initialAssesmentPlanDocuments = documentForCase.filter(o => o.typeOfVs === environment.vsDocumentInitialAssessmentPlan);
         this.initialAssesmentPlanDocuments = this.initialAssesmentPlanDocuments.sort(
            (y, x) =>
               new Date(x.dateOfModification.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$2/$1/$3')).getTime() - new Date(y.dateOfModification.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$2/$1/$3')).getTime()
         );
      });
   }

   checkSupervisor() {
      this.userService.hasSupervisor(JSON.parse(this.localStorageService.get('loggedUser')).id).subscribe(result => {
         if (!result) {
            this.toastr.warning('VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.NO_SUPERVISOR');
         }
      });
   }
   acceptedComplain() {
      this.caseData.newComplain = false;
   }

   saveCurrentData() {
      const isValidLawyer = this.isLawyer && this.caseData?.assignedUserId === this.loggedIn.id;
      const isAccommodation = this.caseData?.socialCaseClassificationCodebook.caseKindViewCodebook.type === 1;

      if (isValidLawyer) {
         if (isAccommodation) {
            this.accommodationLawyer.saveFormSO();
         } else {
            this.vsLawyer.saveCurrentData();
         }
      }
   }

   problemAdded(added: boolean) {
      if (added) {
         this.hasProblem = true;
         this.cdr.detectChanges();
      }
   }
   caseHasProblem() {
      this.caseProblemRecordService.caseHasProblem(this.caseData?.caseStatus, this.caseId, this.caseData?.caseKindCodebook?.kind).subscribe(hasProblem => {
         this.hasProblem = hasProblem;
      });
   }
   async addNewRecord(decision: any): Promise<boolean> {
      if (decision == null || decision === 1 || decision === 3) {
         try {
            return await this.caseProblemRecordService.addNewRecord(this.subjectId, this.caseId, this.caseData?.caseKindCodebook?.kind, {
               amount: '',
            });
         } catch (error) {
            return false;
         }
      }
      return true;
   }
   async saveChanges(): Promise<boolean> {
      const isTeamMember = this.caseData?.assignedUserId !== this.loggedIn.id;
      const isValidLawyer = this.isLawyer && this.caseData?.assignedUserId === this.loggedIn.id;
      const isAccommodation = this.caseData?.socialCaseClassificationCodebook.caseKindViewCodebook.type === 1;

      const lawyerPristine = isAccommodation
         ? this.accommodationLawyer?.firstStep.pristine && this.accommodationLawyer?.secondStep.pristine && this.accommodationLawyer?.thirdStep.pristine
         : this.vsLawyer?.lawyerFormVS.pristine;
      const workerPristine =
         this.initialAssessmentPlanComponent?.initialAssesmentPlan.pristine &&
         (this.initialAssessmentComponent?.from2_3 === undefined
            ? true
            : this.initialAssessmentComponent?.from2_3?.firstStep.pristine &&
              this.initialAssessmentComponent?.from2_3?.secondStep.pristine &&
              this.initialAssessmentComponent?.from2_3?.thirdStep.pristine &&
              this.initialAssessmentComponent?.from2_3?.fourthStep.pristine &&
              this.initialAssessmentComponent?.from2_3?.fifthStep.pristine &&
              this.initialAssessmentComponent?.from2_3?.secondStep.pristine &&
              this.initialAssessmentComponent?.from2_3?.seventhStep.pristine &&
              this.initialAssessmentComponent?.from2_3?.eighthStep.pristine) &&
         this.targetedAssessmentComponent?.targetedAssesment.pristine &&
         (this.measuresAndServicesComponent?.form5Component === undefined
            ? true
            : this.measuresAndServicesComponent?.form5Component?.servicePlan.pristine &&
              this.measuresAndServicesComponent?.form5Component?.participationPlan.pristine &&
              this.measuresAndServicesComponent?.form5Component?.userComment.pristine &&
              this.measuresAndServicesComponent?.form5Component?.evaluationPlan.pristine) &&
         this.evaluationAndReassessmentComponent?.newEvaluationAndAssessment.pristine &&
         (this.accommodationComponent?.formsSOComponent === undefined
            ? true
            : this.accommodationComponent?.formsSOComponent?.firstStep.pristine && this.accommodationComponent?.formsSOComponent?.secondStep.pristine) &&
         (this.violenceComponent?.newViolence === undefined ? true : this.violenceComponent?.newViolence.pristine);

      const pristine = isValidLawyer ? lawyerPristine : workerPristine;

      if (
         isTeamMember ||
         this.accommodationLawyer?.isSubmited ||
         this.vsLawyer?.isSubmited ||
         this.caseData?.caseStatus !== 'ACCEPTED' ||
         this.accommodationComponent?.formsSOComponent?.isSent ||
         pristine
      ) {
         return true;
      }
      let message = '';
      let title = '';
      this.translate.get('WARNING_DIALOG.SAVE_DATA.TITLE').subscribe((res: string) => {
         title = res;
      });
      this.translate.get('WARNING_DIALOG.SAVE_DATA.MESSAGE').subscribe((res: string) => {
         message = res;
      });
      const object = {
         document: null,
         message: message,
         title: title,
         saveData: true,
      };
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         data: object,
         width: ModalSizeEnum.MINI,
         panelClass: 'overlay-panel',
      });
      await dialogRef
         .afterClosed()
         .toPromise()
         .then(res => {
            if (res?.event !== 'cancel' && res !== undefined) {
               if (isValidLawyer) {
                  return this.saveCurrentData();
               } else {
                  this.initialAssessmentPlanComponent?.handleSaveChanges();
                  this.initialAssessmentPlanComponent.initialAssesmentPlan.markAsPristine();

                  this.initialAssessmentComponent?.from2_3?.firstStep.markAsPristine();
                  this.initialAssessmentComponent?.from2_3?.secondStep.markAsPristine();
                  this.initialAssessmentComponent?.from2_3?.thirdStep.markAsPristine();
                  this.initialAssessmentComponent?.from2_3?.fourthStep.markAsPristine();
                  this.initialAssessmentComponent?.from2_3?.fifthStep.markAsPristine();
                  this.initialAssessmentComponent?.from2_3?.secondStep.markAsPristine();
                  this.initialAssessmentComponent?.from2_3?.seventhStep.markAsPristine();
                  this.initialAssessmentComponent?.from2_3?.eighthStep.markAsPristine();
                  this.initialAssessmentComponent.from2_3?.save(false, false);

                  this.targetedAssessmentPlanComponent?.handleSaveChanges();
                  this.targetedAssessmentComponent?.handleSaveChanges();
                  this.targetedAssesmentDocuments.targetedAssesment.markAsPristine();

                  this.measuresAndServicesComponent?.form5Component?.servicePlan.markAsPristine();
                  this.measuresAndServicesComponent?.form5Component?.participationPlan.markAsPristine();
                  this.measuresAndServicesComponent?.form5Component?.userComment.markAsPristine();
                  this.measuresAndServicesComponent?.form5Component?.evaluationPlan.markAsPristine();
                  this.measuresAndServicesComponent?.form5Component?.saveForm5();
                  this.measuresAndServicesComponent?.handleSaveChanges(false);

                  this.evaluationAndReassessmentComponent.handleSaveChanges();
                  this.evaluationAndReassessmentComponent.newEvaluationAndAssessment.markAsPristine();

                  if (this.caseData?.socialCaseClassificationCodebook?.oldKindMark === 'PS') {
                     this.accommodationComponent?.formsSOComponent?.saveFormS1(false);
                  } else {
                     this.accommodationComponent?.formsSOComponent?.saveFormS1(false);
                  }
                  this.accommodationComponent?.formsSOComponent?.firstStep.markAsPristine();
                  this.accommodationComponent?.formsSOComponent?.secondStep.markAsPristine();
                  if (this.caseData?.socialCaseClassificationCodebook.caseKindViewCodebook.type === 2) {
                     this.violenceComponent?.saveCurrentData();
                  }
               }
            }
         });
      return true;
   }
}
