<div class="item__wraper" (click)="handleItemClick()">
  <div class="item">
    <icon-file-tracking></icon-file-tracking>
    <main class="item__main" [matTooltip]="description" [matTooltipDisabled]="activityId!=='1'">
      <h4 class="item__title">
        {{ activity }}
      </h4>
      <footer class="item__meta">
        <span class="item__meta-item">
          {{ date }}
        </span>
        <span class="item__meta-item" *ngIf="caseName">
          {{ caseName }}
        </span>
        <span class="item__meta-item">{{ author }}</span>
      </footer>
    </main>
    <div class="item__description">
      <span >{{description}}</span>
    </div>
  </div>
  <button mat-icon-button class="button-square item__button" color="primary"
    matTooltip="{{ 'TRACKING_LIST_ITEM.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
    <mat-icon class="button-square__icon">more_horiz</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="filter-menu">
    <button mat-menu-item (click)="handleItemClick()">
      <mat-icon>visibility</mat-icon>
      {{ 'TRACKING_LIST_ITEM.VIEW_TRACKING_ITEM' | translate }}
    </button>
    <button [disabled]="isEditable" mat-menu-item (click)="editCallback()">
      <mat-icon>edit</mat-icon>
      {{ 'TRACKING_LIST_ITEM.EDIT_TRACKING_ITEM' | translate }}
    </button>
    <button [disabled]="isEditable" mat-menu-item (click)="deleteCallback()">
      <mat-icon>delete</mat-icon>
      {{ 'TRACKING_LIST_ITEM.DELETE_TRACKING_ITEM' | translate }}
    </button>
  </mat-menu>
</div>