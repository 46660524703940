import { Component, OnInit, Inject, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CaseStatus } from 'src/types';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { CaseService } from 'src/services/case.service';
import { Page } from '../page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/services/user.service';

@Component({
   selector: 'app-rights-history-dialog',
   templateUrl: './rights-history-dialog.component.html',
   styleUrls: ['./rights-history-dialog.component.scss'],
})
export class RightsHistoryDialogComponent implements OnInit {
   displayedColumns: string[] = ['caseNumber', 'decisionType', 'previousCaseNumber', 'previousDecisionType', 'active', 'validFrom', 'validTo', 'terminationDate', 'imported', 'caseStatus'];
   isAdmin: boolean = false;

   decisionOptions = [
      { id: 1, name: 'Признавање' },
      { id: 2, name: 'Одбијање' },
      { id: 3, name: 'Престанак' },
      { id: 4, name: 'Обустава' },
      { id: 5, name: 'Одбацивање' },
      { id: 6, name: 'Без промене' },
   ];

   rightsTypes = [
      { type: 'DPN', name: 'DPN - Додатак за помоћ и негу' },
      { type: 'PNN', name: 'PNN - Посебна новчана накнада' },
      { type: 'L', name: 'L - Локална' },
      { type: 'OZR', name: 'OZR - Оспособљавање за рад' },
      { type: 'NSP', name: 'NSP - Новчана социјална помоћ' },
      { type: 'ACC', name: 'Смештај у породицу/дом' },
   ];
   page: Page<any> = new Page();
   type: any = null;
   constructor(
      private translateService: TranslateService,
      private paginationService: CustomPaginationService,
      private caseService: CaseService,
      public dialogRef: MatDialogRef<any>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private userService: UserService
   ) {
      this.isAdmin = this.userService.isGlobalAdmin();
      this.type = this.data.type;
   }

   ngOnInit(): void {
      this.doFilter();
   }
   translateStatus(status: any) {
      if (status === CaseStatus.NOT_ASSIGNED) {
         this.translateService.get('CASES.STATUSES.NOT_ASSIGNED').subscribe((res: string) => {
            status = res;
         });
      } else if (status === CaseStatus.ASSIGNED) {
         this.translateService.get('CASES.STATUSES.ASSIGNED').subscribe((res: string) => {
            status = res;
         });
      } else if (status === CaseStatus.ACCEPTED) {
         this.translateService.get('CASES.STATUSES.ACCEPTED').subscribe((res: string) => {
            status = res;
         });
      } else if (status === CaseStatus.CLOSED) {
         this.translateService.get('CASES.STATUSES.CLOSED').subscribe((res: string) => {
            status = res;
         });
      } else if (status === CaseStatus.FINISHED_PROCESSING) {
         this.translateService.get('CASES.STATUSES.FINISHED_PROCESSING').subscribe((res: string) => {
            status = res;
         });
      }
      return status;
   }
   getNextPage() {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilter();
   }

   getPreviousPage() {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilter();
   }
   getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.doFilter();
   }

   getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.doFilter();
   }

   getPageInNewSize(pageSize: number): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.doFilter();
   }

   doFilter() {
      this.caseService.getRightsHistoryForSubject(this.page.pageable, this.data.subjectId, this.data.csrId, this.type).subscribe(page => {
         this.page = page;
         this.page.pageable.pagePerShow = page.number + 1;
      });
   }
   compareRights(object1: any, object2: any) {
      return object1 && object2 && object1 === object2;
   }
}
