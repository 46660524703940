/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, Optional, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { SharedCaseService } from 'src/services/shared-case.service';
import { PreviewDocumentComponent } from '../../sidebar-widget/preview-document/preview-document.component';
import { Page } from 'src/app/page';
import { AbusedPersonService } from 'src/services/abused-person.service';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { ModalSizeEnum } from 'src/types';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'domestic-violence-victim',
   templateUrl: './domestic-violence-victim.component.html',
   styleUrls: ['./domestic-violence-victim.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class DomesticViolenceVictimComponent implements OnInit {
   selectable = true;
   removable = true;
   readonly separatorKeysCodes: number[] = [COMMA, ENTER];
   public searchTerms: string[] = [];
   public globalFilter = '';
   public searchItems: any = [];
   page: Page<any> = new Page();
   myInput = '';
   year: any;
   nowYear: any = new Date().getFullYear();
   closed = false;

   object: { origin: string };

   displayedColumns: string[] = ['rBr', 'id', 'registrationId', 'firstName', 'lastName', 'dateOfBirth', 'jmbg'];

   constructor(
      private abusedPersonService: AbusedPersonService,
      private sharedCaseService: SharedCaseService,
      public dialog: MatDialog,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private paginationService: CustomPaginationService,
      public router: Router,
      private translate: TranslateService
   ) {
      this.clearSharedCaseService();
      this.object = { ...data };
      this.year = new Date().getFullYear();
      this.checkStatus();
      this.doFilter();
   }

   ngOnInit(): void {}
   checkStatus() {
      this.abusedPersonService.checkCollectionStatus(this.year).subscribe(
         result => {
            this.closed = result;
         },
         () => {
            this.closed = false;
         }
      );
   }
   public getNextPage(): void {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilter();
   }

   public getPreviousPage(): void {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilter();
   }
   public getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.doFilter();
   }

   public getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.doFilter();
   }

   public getPageInNewSize(pageSize: any): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.doFilter();
   }

   handleOpenDialog(element: any) {
      this.dialog.open(PreviewDocumentComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         data: { document: { name: `${element.firstName} ${element.lastName}` } },
      });
   }

   onRowClicked(element: any) {
      this.router.navigateByUrl('/domestic-violence-victim/' + element.id);
   }

   doFilter() {
      if (this.year === undefined) {
         this.year = new Date().getFullYear();
      }
      const body = {
         text: this.searchItems,
         year: this.year,
      };
      this.abusedPersonService.getFilteredPage(this.page.pageable, body).subscribe(page => {
         this.page = page;
         for (const element of page.content) {
            if (element.abusedPersonDetails.dateOfBirth !== null) {
               element.abusedPersonDetails.dateOfBirth = element.abusedPersonDetails.dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
            }
         }
         this.page.pageable.pagePerShow = page.number + 1;
      });
   }

   add(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;

      // Add new search term
      if ((value || '').trim()) {
         this.searchItems.push(value.trim());
      }
      // Reset the input value
      if (input) {
         input.value = '';
      }
      this.searchTerms = this.searchItems.map((searchItem: any) => searchItem.name);
      this.globalFilter = '';
      this.applyFilter(this.searchTerms);
   }

   remove(item: any): void {
      const index = this.searchItems.indexOf(item);
      if (index >= 0) {
         this.searchItems.splice(index, 1);
         this.searchTerms = this.searchItems.map(function (searchItem) {
            return searchItem.name;
         });
         this.applyFilter(this.searchTerms);
      }
   }

   applyFilter(filterValue: any) {
      this.globalFilter = filterValue;
      this.doFilter();
   }

   clearSharedCaseService() {
      this.sharedCaseService.tabs = [];
      this.sharedCaseService.activeTab = null;
   }

   yearChanged() {
      if (this.year === undefined) {
         this.year = this.nowYear;
      }
      if (this.year !== undefined && this.year !== '') {
         this.checkStatus();
      }
      this.doFilter();
   }

   closeCollection() {
      const object = {
         message: null,
         title: null,
      };

      this.translate.get('WARNING_DIALOG.CLOSE_COLLECTION.MESSAGE').subscribe((res: string) => {
         object.message = res;
      });
      this.translate.get('WARNING_DIALOG.CLOSE_COLLECTION.TITLE').subscribe((res: string) => {
         object.title = res;
      });
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.MINI,
         data: object,
      });

      dialogRef.afterClosed().subscribe(res => {
         if (res.data !== undefined) {
            if (res.data.status) {
               this.abusedPersonService.closeCollection(this.year).subscribe(
                  result => {
                     this.closed = true;
                  },
                  () => {
                     this.closed = false;
                  }
               );
            }
         }
      });
   }

   public exportCsv(): void {
      const body = {
         text: this.searchItems,
         year: this.year,
      };
      this.abusedPersonService.exportCSV(body);
   }

   printPDF() {
      const body = {
         text: this.searchItems,
         year: this.year,
      };
      this.abusedPersonService.getPdf(body);
   }
}
