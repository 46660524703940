<container>
  <page-intro-header back="/collections" title="{{ 'FOSTER_CHILD.TITLE' | translate }}">
    <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" class="dropdown-button"
      [disabled]="closed || !(year==nowYear)">
      {{ 'FOSTER_CHILD.MANAGE' | translate }} <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item routerLink="/foster-child/new">
        <mat-icon>person_add</mat-icon> {{ 'FOSTER_CHILD.NEW_SUBJECT' | translate }}
      </button>
      <button mat-menu-item (click)="closeCollection()" [disabled]="closed">
        <mat-icon>archive</mat-icon> {{ 'FOSTER_CHILD.CLOSE_COLLECTION' | translate }}
      </button>
    </mat-menu>
  </page-intro-header>
  <mat-card class="card">
    <card-header title="{{ 'FOSTER_CHILD.CARD_TITLE' | translate }}">
      <div class="card__aside">
        <mat-form-field appearance="outline" class="search">
          <mat-label>{{ 'GLOBAL.COLLECTIONS_YEAR' | translate }}</mat-label>
          <input matInput type="text" matInput (change)="yearChanged()" [(ngModel)]="year" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="search">
          <mat-label>{{ 'GLOBAL.SEARCH' | translate }}</mat-label>
          <input matInput type="text" matInput [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)"
            placeholder="{{ 'GLOBAL.SEARCH' | translate }}" />
        </mat-form-field>
        <button mat-button color="primary" (click)="exportCsv()"
          class="button-square button-square--large button-export">
          <icon-file-csv matTooltip="{{ 'CSV' | translate }}" class="button-square__icon" size="24"></icon-file-csv>
        </button>
        <button mat-button color="primary" (click)="printPDF()" [disabled]="page.content==null||page.content.length==0"
          class="button-square button-square--large button-export">
          <icon-file-pdf matTooltip="{{ 'PDF' | translate }}" class="button-square__icon" size="24"></icon-file-pdf>
        </button>
      </div>
    </card-header>
    <mat-chip-list #chipList class="chip-list" [ngStyle]="{ display: searchItems.length > 0 ? 'block' : 'none' }">
      <mat-chip *ngFor="let item of searchItems" [selectable]="selectable" [removable]="removable"
        (removed)="remove(item)">
        {{ item }}
        <mat-icon matChipRemove *ngIf="removable"> cancel </mat-icon>
      </mat-chip>
    </mat-chip-list>
    <table mat-table class="table" #myTable [dataSource]="page.content">
      <ng-container matColumnDef="rBr">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'FOSTER_CHILD.R_BR' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.ordinalNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL.SUBJECT.ID' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.subjectId }}</td>
      </ng-container>

      <ng-container matColumnDef="registrationId">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL.SUBJECT.REGISTRATION_ID' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.registrationId }}</td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL.SUBJECT.FIRST_NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL.SUBJECT.LAST_NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL.SUBJECT.DATE_OF_BIRTH' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.dateOfBirth }}</td>
      </ng-container>

      <ng-container matColumnDef="jmbg">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL.SUBJECT.JMBG' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.jmbg }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table__row--header"></tr>
      <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" (click)="onRowClicked(element)"
        class="table__row"></tr>
    </table>
    <div class="paginator-wrapper">
      <app-custom-pagination [page]="page"  (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()" 
            (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"  (pageSizeEvent)="getPageInNewSize($event)">
      </app-custom-pagination>
    </div>
  </mat-card>
</container>