/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { CodebookService } from 'src/services/codebook.service';
import { EstablishedFosterCareService } from 'src/services/established-foster-care.service';
import { FosterParentService } from 'src/services/foster-parent.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import * as myLetters from './../../../letters';
import { AddFosterChildComponent } from './add-foster-child/add-foster-child.component';

interface FosterChild {
   id: number;
   firstName: string;
   lastName: string;
   dateOfBirth: string;
   placeOfBirth: string;
   kinship: string;
   formOfFosterCare: any;
   dateOfPlacementInAFosterFamily: string;
   childSupportBearers: string;
   dateOfCustodyTermination: string;
   reasonsForCustodyTermination: any;
   competentCenter: string;
   highlighted?: boolean;
}

@Component({
   selector: 'new-established-foster-care',
   templateUrl: './new-established-foster-care.component.html',
   styleUrls: ['./new-established-foster-care.component.scss'],
})
export class NewEstablishedFosterCareComponent implements OnInit {
   currentDate = new Date();
   newRecord: FormGroup;
   displayedColumns: string[] = [
      'firstName',
      'dateOfBirth',
      'kinship',
      'formOfFosterCare',
      'dateOfPlacementInAFosterFamily',
      'childSupportBearers',
      'dateOfCustodyTermination',
      'reasonsForCustodyTermination',
      'competentCenter',
   ];
   fosterChildren: FosterChild[] = [];
   genderOptions: any = [];
   isFosterChildSelected = false;
   selectedFosterChild = null;
   regexStrDate = '^[0-9.]+$';
   regexStrJmbg = '^[0-9]+$';

   constructor(
      private router: Router,
      private establishedFosterCareService: EstablishedFosterCareService,
      private fosterParentsService: FosterParentService,
      private codebookService: CodebookService,
      private formBuilder: FormBuilder,
      public dialog: MatDialog,
      private translate: TranslateService,
      private datePipe: DatePipe,
      private toastr: ToastrImplService
   ) {
      this.getGender();
   }

   ngOnInit(): void {
      this.newRecord = this.formBuilder.group({
         subjectId: [''],
         registrationId: [''],
         firstName: ['', [Validators.required]],
         lastName: ['', [Validators.required]],
         gender: ['', [Validators.required]],
         foreigner: [false],
         dateOfBirth: ['', [Validators.required]],
         placeOfBirth: ['', [Validators.required]],
         jmbg: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(13), Validators.maxLength(13)]],
         placeOfOrigin: ['', [Validators.required]],
         childrenInFamilyCount: ['', [Validators.pattern(myLetters.number)]],
         childrenInFosterCareCount: ['', [Validators.pattern(myLetters.number)]],
         competentCenterForSocialWork: ['', [Validators.required]],
      });
   }

   createRecord(newRecord: any) {
      // Submit new record
      this.createNewEstablishedFosterCare();
   }

   createNewEstablishedFosterCare() {
      if (this.newRecord.value.jmbg !== '') {
         this.updateJMBGOnGenderChange();
         this.updateJMBGOnDateOfBirthChange();
      }
      if (!this.newRecord.valid) {
         return;
      }
      for (const child of this.fosterChildren) {
         child.dateOfBirth = child.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$1/$2/$3');
      }

      // olja.andjelovski@iten.rs
      // Moraju id-jevi da se postave na null zato sto se kod nas u bazi automatski generisu,
      // ukoliko bi imao kreiran id uzeo bi taj sto bi prouzrokovalo gresku ukoliko kod nas u bazi ima vec neko sa tim id-jem
      this.fosterChildren.forEach(x => {
         x.id = null;
      });

      const body = {
         fosterParentSubjectId: this.newRecord.value.subjectId,
         fosterParentRegistrationId: this.newRecord.value.registrationId,
         fosterParentFirstName: this.newRecord.value.firstName,
         fosterParentLastName: this.newRecord.value.lastName,
         fosterParentGender: this.newRecord.value.gender,
         fosterParentDateOfBirth: this.datePipe.transform(new Date(this.newRecord.value.dateOfBirth).getTime(), 'dd/MM/yyyy'),
         fosterParentPlaceOfBirth: this.newRecord.value.placeOfBirth,
         fosterParentJmbg: this.newRecord.value.jmbg,
         fosterParentPermanentResidence: this.newRecord.value.placeOfOrigin,
         childrenInFamilyCount: this.newRecord.value.childrenInFamilyCount,
         childrenInFosterCareCount: this.newRecord.value.childrenInFosterCareCount,
         competentCenterForSocialWork: this.newRecord.value.competentCenterForSocialWork,
         children: this.fosterChildren,
      };

      this.establishedFosterCareService.createEstablishedFosterCare(body).subscribe(res => {
         if (res != null) {
            this.toastr.success('SNACKBAR.ADDED_FOSTER_CARE');
            this.router.navigateByUrl('/established-foster-care');
         } else {
            this.toastr.error('SNACKBAR.ADD_FOSTER_CARE_ERROR');
            this.router.navigateByUrl('/established-foster-care');
         }
      });
   }

   addFosterChildRecord(): void {
      const dialogRef = this.dialog.open(AddFosterChildComponent, {
         width: ModalSizeEnum.EXTRA_LARGE,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(result => {
         if (result && result.event === ModalEventEnum.SUCCESS) {
            const newChild = {
               id: this.fosterChildren.length,
               ...result.data,
            };
            newChild.dateOfBirth = this.datePipe.transform(new Date(newChild.dateOfBirth).getTime(), 'dd.MM.yyyy.');
            this.fosterChildren = [...this.fosterChildren, newChild];
            this.toastr.success('SNACKBAR.INPUT_ADDED');
         }
      });
   }

   onRowSelected(row: any) {
      if (this.selectedFosterChild !== null) {
         this.selectedFosterChild.highlighted = !this.selectedFosterChild.highlighted;
      }
      row.highlighted = !row.highlighted;
      this.selectedFosterChild = row;
      this.isFosterChildSelected = true;
   }

   removeFosterChildRecord() {
      const updatedState = this.fosterChildren.filter(child => child.id !== this.selectedFosterChild.id);
      this.fosterChildren = updatedState;
      this.isFosterChildSelected = false;
      this.toastr.success('SNACKBAR.DELETED_ELEMENT');
   }

   importSubjectData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         // width: ModalSizeEnum.DEFAULT,
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            this.fosterParentsService.findFosterParentById(result.data.jmbg).subscribe(res => {
               if (res != null) {
                  this.newRecord.patchValue({
                     childrenInFamilyCount: res.numberOfFamilyChildren,
                  });
               }
            });
            const address =
               String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
               String(result.data.permanentResidence.number ? ' ' + result.data.permanentResidence.number : '') +
               String(result.data.permanentResidence.subnumber ? ' ' + result.data.permanentResidence.subnumber : '') +
               String(result.data.permanentResidence.town ? ', ' + result.data.permanentResidence.town : '') +
               String(result.data.permanentResidence.street && result.data.residence.street ? '/' : '') +
               String(result.data.residence.street ? result.data.residence.street : '') +
               String(result.data.residence.number ? ' ' + result.data.residence.number : '') +
               String(result.data.residence.subnumber ? ' ' + result.data.residence.subnumber : '') +
               String(result.data.residence.town ? ', ' + result.data.residence.town : '');

            this.newRecord.patchValue({
               subjectId: result.data.subjectIdentity.subjectId,
               registrationId: result.data.registrationId,
               firstName: result.data.firstName,
               lastName: result.data.lastName,
               gender: result.data.gender,
               foreigner: result.data.foreigner,
               dateOfBirth: new Date(result.data.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')),
               placeOfBirth: result.data.placeOfBirth,
               jmbg: result.data.jmbg,
               placeOfOrigin: address,
            });
            this.newRecord.controls.gender.markAsTouched();
            this.newRecord.controls.dateOfBirth.markAsTouched();
            this.newRecord.controls.jmbg.markAsTouched();
         }
      });
   }

   getGender() {
      this.codebookService.getGenderCodebook().subscribe(res => {
         this.genderOptions = res;
      });
   }

   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }

   updateJMBGOnGenderChange() {
      if (this.newRecord.value.foreigner === true) {
         return;
      }
      if (this.newRecord.value.jmbg !== null && this.newRecord.value.jmbg.length === 13) {
         const jmbg = this.newRecord.value.jmbg.substring(9, 12);
         if (this.newRecord.get('gender').value !== null && this.newRecord.get('gender').value !== undefined) {
            if (this.newRecord.value.jmbg.length === 13 && jmbg >= '000' && jmbg <= '499') {
               if (this.newRecord.value.gender.code === 'F') {
                  this.newRecord.get('gender').setErrors({ incorrect: true });
               } else {
                  this.newRecord.get('gender').setErrors(null);
               }
            } else if (jmbg >= '500' && jmbg <= '999') {
               if (this.newRecord.value.gender.code === 'M') {
                  this.newRecord.get('gender').setErrors({ incorrect: true });
               } else {
                  this.newRecord.get('gender').setErrors(null);
               }
            }
         }
      }
   }

   updateJMBGOnDateOfBirthChange() {
      if (this.newRecord.value.foreigner) {
         return;
      }
      if (this.newRecord.value.dateOfBirth && this.newRecord.value.jmbg) {
         const birthDate = new Date(this.newRecord.value.dateOfBirth).getTime();
         const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
         const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);

         if (this.newRecord.value.jmbg.length === 13) {
            const jmbg = this.newRecord.value.jmbg.substring(0, 7);
            if (newBirthDate !== jmbg) {
               this.newRecord.get('dateOfBirth').setErrors({ incorrect: true });
            } else {
               this.newRecord.get('dateOfBirth').setErrors(null);
            }
         }
      }
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.newRecord.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);

      if (inputDate > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.newRecord.get(controlName);
      if (!control) return;

      const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement;
      const inputValue = inputElement?.value || '';
      const dateValue = control.value;

      if (!inputValue) {
         control.setErrors({ required: true });
         return;
      }

      if (!dateValue) {
         control.setErrors({ invalidFormat: true });
         return;
      }

      if (dateValue > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      }
   }

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      } else if (fieldName === 'jmbg') {
         return new RegExp(this.regexStrJmbg).test(event.key);
      }
   }
}
