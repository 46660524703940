<container>
    <mat-card class="card">
        <card-header title="{{ 'STATISTICS.TRACKING_LIST_STATS.TITLE' | translate }}">
        </card-header>
        <form [formGroup]="trackingListStatsForm" class="form">
            <field-holder *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
                <mat-form-field appearance="outline" (click)="selectMulti()">
                    <mat-label> {{'GLOBAL.CENTER' | translate}}</mat-label>
                    <mat-select formControlName="center" multiple required #multiSelect>
                        <input type="text" class="multi-input" autocomplete="off" matInput
                            placeholder="{{'GLOBAL.SEARCH_CENTERS' | translate}}" #multiSearch
                            (focus)="multiSelect.disabled = true" (focusout)="multiSelect.disabled = false"
                            (input)="onInputChange($event.target.value)" />
                        <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">
                            {{ 'GLOBAL.ALL_CENTERS' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let value of filteredAllCenters" [value]="value?.id"
                            (click)="tosslePerOneCenter()">
                            {{ value.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="trackingListStatsForm.get('center').hasError('required')">
                        {{ "GLOBAL.CENTER_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{ 'STATISTICS.TRACKING_LIST_STATS.ACTIVITY' | translate }}
                    </mat-label>
                    <input type="text" matInput formControlName="activity"
                        (focusout)="checkActivityAutocomplete(false) " [matAutocomplete]="auto" required>
                    <mat-autocomplete #auto="matAutocomplete" for="activity" [displayWith]="displayCustomFormat">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.title}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error
                        *ngIf="trackingListStatsForm.get('activity').hasError('required')||trackingListStatsForm.get('activity').hasError('notValid')">
                        {{ 'STATISTICS.TRACKING_LIST_STATS.ACTIVITY_ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
                    <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
                        onlyNumber />
                    <mat-error *ngIf="trackingListStatsForm.controls.selectedYear.hasError('required')">
                        {{ "STATISTICS.DATE_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.AGE_GROUP' | translate }}</mat-label>
                    <mat-select formControlName="ageGroup" multiple>
                        <mat-option *ngFor="let record of ageGroup" [value]="record">
                            {{ record.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols">
                <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
                    <mat-label>{{ 'STATISTICS.FROM' | translate }}</mat-label>
                    <input matInput type="text" formControlName="customAgeGroupFrom" autocomplete="off" maxlength="3"
                        (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
                        placeholder="{{ 'STATISTICS.FROM' | translate }}" [readonly]="!shouldShowAgeGroupRange" />
                    <mat-error *ngIf="trackingListStatsForm.controls.customAgeGroupFrom.hasError('outOfRange')">
                        {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
                    <mat-label>{{ 'STATISTICS.TO' | translate }}</mat-label>
                    <input matInput type="text" formControlName="customAgeGroupTo" autocomplete="off" maxlength="3"
                        (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
                        placeholder="{{ 'STATISTICS.TO' | translate }}" [readonly]="!shouldShowAgeGroupRange" />
                    <mat-error *ngIf="trackingListStatsForm.controls.customAgeGroupTo.hasError('incorect')">
                        {{ "STATISTICS.NUMBER_OF_YEARS_INCORECT" | translate }}
                    </mat-error>
                    <mat-error *ngIf="trackingListStatsForm.controls.customAgeGroupTo.hasError('outOfRange')">
                        {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols"
                *ngIf="(isMainCenterBelgrade || isUserDrugostepeni)&&trackingListStatsForm.controls.center.value!=null&&trackingListStatsForm.controls.center.value.length>1">
                <mat-checkbox color="primary" class="form__checkbox" formControlName="byCenter">
                    {{ 'STATISTICS.GROUP_BY_CSR' | translate }}
                </mat-checkbox>
            </field-holder>
            <form-footer>
                <button [disabled]="!trackingListStatsForm.valid || btnDisabled"
                    class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
                    (click)="sendRequest()">
                    {{ 'STATISTICS.SUBMIT' | translate }}
                </button>
                <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </form-footer>
        </form>
    </mat-card>
</container>