/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { autoSelect } from 'src/app/utils/autoSelect';
import { CodebookService } from 'src/services/codebook.service';
import { StatisticsService } from 'src/services/statistics.service';
import { UserService } from 'src/services/user.service';

@Component({
   selector: 'record-control-stats',
   templateUrl: './record-control-stats.component.html',
   styleUrls: ['./record-control-stats.component.scss'],
})
export class RecordControlStatsComponent implements OnInit {
   recordControlStatsForm: FormGroup;
   centers = [];
   filteredAllCenters: any = [];
   loggedIn: any;
   isMainCenterBelgrade = false;
   btnDisabled: boolean;
   isUserDrugostepeni = false;

   recordOptions = [
      { id: 1, name: 'Активни корисници без евидентираних права, мера и услуга' },
      { id: 2, name: 'Активни корисници којима недостаје потреба/проблем' },
      { id: 3, name: 'Пасивизирани корисници без евидентираних права, мера и услуга' },
      { id: 4, name: 'Пасивизирани корисници којима недостаје потреба/проблем' },
   ];

   @ViewChild('allSelectedCenters') private allSelectedCenters: MatOption;
   @ViewChild('multiSearch') private multiSearch: ElementRef;
   constructor(
      private formBuilder: FormBuilder,
      private localStorage: LocalStorageService,
      private statisticsService: StatisticsService,
      private codebookService: CodebookService,
      private translate: TranslateService,
      private userService: UserService
   ) {
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });
      this.btnDisabled = false;
      this.loggedIn = JSON.parse(this.localStorage.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
          this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
          this.filteredAllCenters = this.centers;
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
          this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
          this.filteredAllCenters = this.centers;
         });
      }
   }

   ngOnInit(): void {
      this.recordControlStatsForm = this.formBuilder.group({
         record: ['', [Validators.required]],
         center: [''],
      });
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }
   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.recordControlStatsForm);
   }
   /**
    * Send request and generate Excell report
    */
   sendRequest(form: any) {
      const dto = {
         csrId: this.isMainCenterBelgrade || this.isUserDrugostepeni ? form.value.center : [this.loggedIn.csrId],
         id: form.value.record.id,
      };
      let filename = '';
      this.translate.get('STATISTICS.RECORD_CONTROL_STATS.TITLE').subscribe((res: string) => {
         filename = res;
      });
      this.statisticsService.getControlStats(dto, filename);
   }

   selectMulti() {
      this.multiSearch.nativeElement.focus();
      this.multiSearch.nativeElement.value = null;
      this.filteredAllCenters = this.centers;
   }

   toggleAllSelectionCenter() {
      if (this.allSelectedCenters.selected) {
         this.recordControlStatsForm.controls.center.patchValue([...this.centers.map(item => item.id), 0]);
      } else {
         this.recordControlStatsForm.controls.center.patchValue([]);
      }
   }
   tosslePerOneCenter() {
      if (this.allSelectedCenters.selected) {
         this.allSelectedCenters.deselect();
         return false;
      }
      if (this.recordControlStatsForm.controls.center.value.length === this.centers.length) {
         this.allSelectedCenters.select();
      }
   }
   onInputChange(inputValue: string) {
      const input = inputValue.toLowerCase();
      this.filteredAllCenters = this.centers.filter((center: any) => {
         const selectedCenters = this.recordControlStatsForm.controls.center.value;
         return center.name.toLowerCase().includes(input) || selectedCenters.includes(center.id);
      });
   }
}
