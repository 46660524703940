import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ageGroupData } from 'src/app/utils/ageGroupData';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { CodebookService } from 'src/services/codebook.service';
import { StatisticsService } from 'src/services/statistics.service';
import { UserService } from 'src/services/user.service';

enum AutocompleteFilterEnum {
   CENTERS = 'centers',
   ACTIVITY = 'activity',
}
@Component({
   selector: 'app-tracking-list-stats',
   templateUrl: './tracking-list-stats.component.html',
   styleUrls: ['./tracking-list-stats.component.scss'],
})
export class TrackingListStatsComponent implements OnInit {
   activities = [];
   filteredOptions: Observable<any[]>;

   shouldShowAgeGroupRange = false;
   ageGroup = ageGroupData;
   centers = [];
   filteredAllCenters: Observable<any>;
   loggedIn: any;
   isMainCenterBelgrade = false;
   btnDisabled: boolean;
   isUserDrugostepeni = false;
   trackingListStatsForm: FormGroup;

   constructor(
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private statisticsService: StatisticsService,
      private localStorage: LocalStorageService,
      private userService: UserService
   ) {
      this.loggedIn = JSON.parse(this.localStorage.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.centers = result;
            this.filteredAllCenters = this.trackingListStatsForm.controls.center.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.CENTERS) : this.centers.slice()))
            );
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.centers = result;
            this.filteredAllCenters = this.trackingListStatsForm.controls.center.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.CENTERS) : this.centers.slice()))
            );
         });
      }
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });
      this.btnDisabled = false;
   }

   ngOnInit(): void {
      this.trackingListStatsForm = this.formBuilder.group({
         activity: [null, [Validators.required]],
         center: [''],
         ageGroup: ['', []],
         selectedYear: ['', [Validators.required]],
         customAgeGroupTo: [''],
         customAgeGroupFrom: [''],
      });
      this.dynamicFormUpdate();
      this.codebookService.getActivityCodebook().subscribe(result => {
         this.activities = result;
         this.filteredOptions = this.trackingListStatsForm.controls.activity.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.ACTIVITY) : this.activities.slice()))
         );
      });
   }
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.CENTERS:
            return sortByFirstLetter(
               this.centers.filter(option => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         case AutocompleteFilterEnum.ACTIVITY:
            return sortByFirstLetter(
               this.activities.filter(option => option.title.toLowerCase().includes(filterValue)),
               filterValue,
               'title'
            );
         default:
            break;
      }
   }

   sendRequest() {
      if (!this.trackingListStatsForm.valid) {
         return;
      }
      let ageGroupDto = null;
      let year = null;
      ageGroupDto = this.trackingListStatsForm.value.ageGroup === null || this.trackingListStatsForm.value.ageGroup === '' ? null : this.trackingListStatsForm.value.ageGroup;
      year = this.trackingListStatsForm.value.selectedYear === '' || this.trackingListStatsForm.value.selectedYear === null ? null : this.trackingListStatsForm.value.selectedYear;
      if (ageGroupDto != null && ageGroupDto.some(e => e.id === 6)) {
         const indexToUpdate = ageGroupDto.findIndex(group => group.id === 6);
         const newAgeGroup = {
            id: 6,
            name: 'Слободан унос опсега година (' + this.trackingListStatsForm.value.customAgeGroupFrom + ' - ' + this.trackingListStatsForm.value.customAgeGroupTo + ')',
            from: this.trackingListStatsForm.value.customAgeGroupFrom,
            to: this.trackingListStatsForm.value.customAgeGroupTo,
         };
         ageGroupDto[indexToUpdate] = newAgeGroup;
      }
      let activity = this.trackingListStatsForm.value.activity.id;
      const dto = {
         csrId: this.isMainCenterBelgrade || this.isUserDrugostepeni ? this.trackingListStatsForm.value.center.id : this.loggedIn.csrId,
         ageGroup: ageGroupDto,
         year,
         activity,
      };

      var filename = 'Статистика лист праћења - ' + this.trackingListStatsForm.value.activity.title;
      this.statisticsService.getTrackingListStatistics(dto, filename);
   }

   displayCustomFormat(activity: any): string {
      return activity ? activity.title : '';
   }

   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.trackingListStatsForm);
   }

   checkActivityAutocomplete(fast: boolean) {
      const temp = this.trackingListStatsForm;
      const activities = this.activities;
      if (temp.value.activity === null) {
         temp.controls.activity.setErrors({ notValid: true });
         return;
      }
      if (fast === false) {
         setTimeout(function () {
            if (temp.value.activity.code === undefined || temp.value.activity.code === null) {
               const founded = activities.filter(activity => activity.title.toLowerCase().includes(temp.value.activity));
               if (founded.length === 0) {
                  temp.controls.activity.setErrors({ notValid: true });
                  return;
               } else {
                  temp.controls.activity.setValue(founded[0]);
                  temp.controls.activity.setErrors(null);
               }
            }
         }, 300);
      } else {
         if (temp.value.activity.code === undefined || temp.value.activity.code === null) {
            const founded = activities.filter(activity => activity.title.toLowerCase().includes(temp.value.activity));
            if (founded.length === 0) {
               temp.controls.activity.setErrors({ notValid: true });
               return;
            } else {
               temp.controls.activity.setValue(founded[0]);
               temp.controls.activity.setErrors(null);
            }
         }
      }
   }
   dynamicFormUpdate() {
      this.trackingListStatsForm.get('ageGroup').valueChanges.subscribe(ageArray => {
         if (ageArray.some(age => age.id === 6)) {
            this.shouldShowAgeGroupRange = true;
            this.trackingListStatsForm.get('customAgeGroupFrom').setValidators(Validators.required);
            this.trackingListStatsForm.get('customAgeGroupTo').setValidators(Validators.required);
         } else {
            this.shouldShowAgeGroupRange = false;
            this.trackingListStatsForm.get('customAgeGroupFrom').clearValidators();
            this.trackingListStatsForm.get('customAgeGroupFrom').setValue(null);
            this.trackingListStatsForm.get('customAgeGroupTo').clearValidators();
            this.trackingListStatsForm.get('customAgeGroupTo').setValue(null);
         }
      });
   }
   checkCustomYears() {
      if (this.trackingListStatsForm.controls.customAgeGroupTo.touched && this.trackingListStatsForm.controls.customAgeGroupFrom.touched) {
         if (
            this.trackingListStatsForm.value.customAgeGroupTo !== null &&
            this.trackingListStatsForm.value.customAgeGroupTo !== '' &&
            this.trackingListStatsForm.value.customAgeGroupFrom !== null &&
            this.trackingListStatsForm.value.customAgeGroupFrom !== ''
         ) {
            if (this.trackingListStatsForm.value.customAgeGroupTo < this.trackingListStatsForm.value.customAgeGroupFrom) {
               this.trackingListStatsForm.controls.customAgeGroupTo.setErrors({
                  incorect: true,
               });
            } else {
               this.trackingListStatsForm.controls.customAgeGroupTo.setErrors(null);
            }
         }
      }
      if (this.trackingListStatsForm.value.customAgeGroupTo !== null && this.trackingListStatsForm.value.customAgeGroupTo !== '') {
         if (!this.trackingListStatsForm.controls.customAgeGroupTo.hasError('incorect') && Number(this.trackingListStatsForm.value.customAgeGroupTo) > 150) {
            this.trackingListStatsForm.controls.customAgeGroupTo.setErrors({
               outOfRange: true,
            });
         } else if (!this.trackingListStatsForm.controls.customAgeGroupTo.hasError('incorect')) {
            this.trackingListStatsForm.controls.customAgeGroupTo.setErrors(null);
         }
      }
      if (this.trackingListStatsForm.value.customAgeGroupFrom !== null && this.trackingListStatsForm.value.customAgeGroupFrom !== '') {
         if (Number(this.trackingListStatsForm.value.customAgeGroupFrom) > 150) {
            this.trackingListStatsForm.controls.customAgeGroupFrom.setErrors({
               outOfRange: true,
            });
         } else {
            this.trackingListStatsForm.controls.customAgeGroupFrom.setErrors(null);
         }
      }
   }
}
