<container>
   <mat-card class="card">
      <card-header title="{{ 'STATISTICS.BASIC_STATS.TITLE' | translate }}"></card-header>
      <form [formGroup]="basicStatsForm" class="form">
         <field-holder *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
            <mat-form-field appearance="outline" (click)="selectMulti()">
               <mat-label>{{'GLOBAL.CENTER' | translate}}</mat-label>
               <mat-select formControlName="center" multiple required #multiSelect>
                  <input
                     type="text"
                     class="multi-input"
                     autocomplete="off"
                     matInput
                     placeholder="{{'GLOBAL.SEARCH_CENTERS' | translate}}"
                     #multiSearch
                     (focus)="multiSelect.disabled = true"
                     (focusout)="multiSelect.disabled = false"
                     (input)="onInputChange($event.target.value,'centers')"
                  />
                  <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">{{ 'GLOBAL.ALL_CENTERS' | translate }}</mat-option>
                  <mat-option *ngFor="let value of filteredAllCenters" [value]="value?.id" (click)="tosslePerOneCenter()">{{ value.name }}</mat-option>
               </mat-select>
               <mat-error *ngIf="basicStatsForm.get('center').hasError('required')">{{ "GLOBAL.CENTER_ERROR" | translate }}</mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.REPORTING_PERIOD' | translate }}</mat-label>
               <mat-select formControlName="period">
                  <mat-option *ngFor="let p of period" [value]="p">{{ p.name }}</mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="basicStatsForm.controls.period.value?.id != 2">
               <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
               <input matInput type="text" formControlName="selectedYear" autocomplete="off" [required]="basicStatsForm.controls.period.value?.id != 2" maxlength="4" onlyNumber />
               <mat-error *ngIf="basicStatsForm.get('selectedYear').hasError('required') || basicStatsForm.get('selectedYear').hasError('minlength')">
                  {{ "STATISTICS.YEAR_ERROR" | translate }}
               </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="basicStatsForm.controls.period.value?.id == 2">
               <mat-label>{{ 'STATISTICS.REPORTING_PERIOD_FROM' | translate }}</mat-label>
               <input
                  matInput
                  type="text"
                  [matDatepicker]="dateFrom"
                  formControlName="dateFrom"
                  autocomplete="off"
                  [required]="basicStatsForm.controls.period.value?.id == 2"
                  placeholder="{{ 'STATISTICS.REPORTING_PERIOD_FROM' | translate }}"
                  [max]="maxDate"
               />
               <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
               <mat-datepicker #dateFrom></mat-datepicker>
               <mat-error *ngIf="basicStatsForm.get('dateFrom').hasError('required')">{{ "STATISTICS.DATE_ERROR" | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="basicStatsForm.controls.period.value?.id == 2">
               <mat-label>{{ 'STATISTICS.REPORTING_PERIOD_TO' | translate }}</mat-label>
               <input
                  matInput
                  type="text"
                  [matDatepicker]="dateTo"
                  formControlName="dateTo"
                  autocomplete="off"
                  [required]="basicStatsForm.controls.period.value?.id == 2"
                  [max]="maxDate"
                  placeholder="{{ 'STATISTICS.REPORTING_PERIOD_TO' | translate }}"
               />
               <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
               <mat-datepicker #dateTo></mat-datepicker>
               <mat-error *ngIf="basicStatsForm.get('dateTo').hasError('required')">{{ "STATISTICS.DATE_ERROR" | translate }}</mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.AGE_GROUP' | translate }}</mat-label>
               <mat-select formControlName="ageGroup" multiple>
                  <mat-option *ngFor="let group of ageGroup" [value]="group">{{ group.name }}</mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
               <mat-label>{{ 'STATISTICS.FROM' | translate }}</mat-label>
               <input
                  matInput
                  type="text"
                  formControlName="customAgeGroupFrom"
                  autocomplete="off"
                  maxlength="3"
                  (change)="checkCustomYears()"
                  [required]="shouldShowAgeGroupRange"
                  onlyNumber
                  placeholder="{{ 'STATISTICS.FROM' | translate }}"
                  [readonly]="!shouldShowAgeGroupRange"
               />
               <mat-error *ngIf="basicStatsForm.get('customAgeGroupFrom').hasError('outOfRange')">{{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
               <mat-label>{{ 'STATISTICS.TO' | translate }}</mat-label>
               <input
                  matInput
                  type="text"
                  formControlName="customAgeGroupTo"
                  autocomplete="off"
                  maxlength="3"
                  (change)="checkCustomYears()"
                  [required]="shouldShowAgeGroupRange"
                  onlyNumber
                  placeholder="{{ 'STATISTICS.TO' | translate }}"
                  [readonly]="!shouldShowAgeGroupRange"
               />
               <mat-error *ngIf="basicStatsForm.get('customAgeGroupTo').hasError('incorect')">{{ "STATISTICS.NUMBER_OF_YEARS_INCORECT" | translate }}</mat-error>
               <mat-error *ngIf="basicStatsForm.get('customAgeGroupTo').hasError('outOfRange')">{{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}</mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.GENDER' | translate }}</mat-label>
               <mat-select formControlName="gender">
                  <mat-option></mat-option>
                  <mat-option *ngFor="let value of gender" [value]="value">{{ value.gender }}</mat-option>
               </mat-select>
            </mat-form-field>
            <mat-checkbox color="primary" class="form__checkbox" formControlName="crossData">{{ 'STATISTICS.BASIC_STATS.CROSS_DATA' | translate }}</mat-checkbox>
         </field-holder>
         <form-subgroup-divider></form-subgroup-divider>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.BASIC_STATS.REQUESTS_AND_PROBLEMS' | translate }}</mat-label>
               <mat-select formControlName="requestsAndProblems" multiple [compareWith]="compareObjectsId" #multiSelectProblems>
                  <input
                     type="text"
                     class="multi-input"
                     (focus)="multiSelectProblems.disabled = true"
                     (focusout)="multiSelectProblems.disabled = false"
                     autocomplete="off"
                     matInput
                     placeholder="{{'STATISTICS.BASIC_STATS.SEARCH.PROBLEMS' | translate}}"
                     #multiSearch
                     (input)="onInputChange($event.target.value,'requestsAndProblems')"
                  />
                  <mat-option *ngFor="let value of requestsAndProblemsFiltered" [value]="value" #matOption (click)="tosslePerOne('requestsAndProblems', value.id, matOption.selected)">
                     {{ value.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.BASIC_STATS.SERVICES' | translate }}</mat-label>
               <mat-select formControlName="services" multiple [compareWith]="compareObjectsId" #multiSelectServices>
                  <input
                     type="text"
                     class="multi-input"
                     (focus)="multiSelectServices.disabled = true"
                     (focusout)="multiSelectServices.disabled = false"
                     autocomplete="off"
                     matInput
                     placeholder="{{'STATISTICS.BASIC_STATS.SEARCH.SERVICES' | translate}}"
                     #multiSearch
                     (input)="onInputChange($event.target.value,'services')"
                  />
                  <mat-option *ngFor="let value of servicesFiltered" [value]="value" #matOption (click)="tosslePerOne('services', value.id, matOption.selected)">{{ value.title }}</mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.BASIC_STATS.LEGAL_PROCEDURES' | translate }}</mat-label>
               <mat-select formControlName="legalProcedures" multiple [compareWith]="compareObjectsId" #multiSelectLegalProcedures>
                  <input
                     type="text"
                     class="multi-input"
                     (focus)="multiSelectLegalProcedures.disabled = true"
                     (focusout)="multiSelectLegalProcedures.disabled = false"
                     autocomplete="off"
                     matInput
                     placeholder="{{'STATISTICS.BASIC_STATS.SEARCH.LEGAL_PROCEDURES' | translate}}"
                     #multiSearch
                     (input)="onInputChange($event.target.value,'legalProcedures')"
                  />
                  <mat-option *ngFor="let value of legalProceduresFiltered" [value]="value" #matOption (click)="tosslePerOne('legalProcedures', value.id, matOption.selected)">
                     {{ value.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.BASIC_STATS.PROFESSIONAL_PROCEDURES' | translate }}</mat-label>
               <mat-select formControlName="professionalProcedures" multiple [compareWith]="compareObjectsId" #multiSelectProfessionalProcedures>
                  <input
                     type="text"
                     class="multi-input"
                     (focus)="multiSelectProfessionalProcedures.disabled = true"
                     (focusout)="multiSelectProfessionalProcedures.disabled = false"
                     autocomplete="off"
                     matInput
                     placeholder="{{'STATISTICS.BASIC_STATS.SEARCH.PROFESIONAL_PROCEDURES' | translate}}"
                     #multiSearch
                     (input)="onInputChange($event.target.value,'professionalProcedures')"
                  />
                  <mat-option *ngFor="let value of professionalProceduresFiltered" #matOption [value]="value" (click)="tosslePerOne('professionalProcedures', value.id, matOption.selected)">
                     {{ value.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'STATISTICS.BASIC_STATS.RIGHTS' | translate }}</mat-label>
               <mat-select formControlName="rights" multiple [compareWith]="compareObjectsId" #multiSelectRights>
                  <input
                     type="text"
                     class="multi-input"
                     (focus)="multiSelectRights.disabled = true"
                     (focusout)="multiSelectRights.disabled = false"
                     autocomplete="off"
                     matInput
                     placeholder="{{'STATISTICS.BASIC_STATS.SEARCH.RIGHTS' | translate}}"
                     #multiSearch
                     (input)="onInputChange($event.target.value,'rights')"
                  />
                  <mat-option *ngFor="let value of rightsFiltered" [value]="value" #matOption (click)="tosslePerOne('rights', value.id, matOption.selected)">{{ value.title }}</mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols" *ngIf="(isMainCenterBelgrade || isUserDrugostepeni) && basicStatsForm.controls.center.value != null && basicStatsForm.controls.center.value.length > 1">
            <mat-checkbox color="primary" class="form__checkbox" formControlName="byCenter">{{ 'STATISTICS.GROUP_BY_CSR' | translate }}</mat-checkbox>
         </field-holder>
         <form-footer>
            <button
               class="form-footer__button form-footer__button--primary"
               mat-flat-button
               color="primary"
               (click)="sendRequest(basicStatsForm)"
               [disabled]="!basicStatsForm.valid || btnDisabled || numSelected==0"
            >
               {{ 'STATISTICS.SUBMIT' | translate }}
            </button>
            <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate"></mat-progress-spinner>
         </form-footer>
      </form>
   </mat-card>
</container>
