/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { MatDialog } from '@angular/material/dialog';
import { CodebookService } from 'src/services/codebook.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

enum AutocompleteFilterEnum {
  CITIZENSHIP = 'citizenship'
}

@Component({
  selector: 'new-adopted-child-biological-parents-step',
  templateUrl: './new-adopted-child-biological-parents-step.component.html',
  styleUrls: ['./new-adopted-child-biological-parents-step.component.scss']
})
export class NewAdoptedChildBiologicalParentsStepComponent implements OnInit {
  @Output() biologicalParentsStepEmitter = new EventEmitter();
  @Input() biologicalParentsStep: FormGroup;
  currentDate = new Date();
  citizenshipOptions = [];
  filteredCitizenshipOptionsMother: Observable<any[]>;
  filteredCitizenshipOptionsFather: Observable<any[]>;
  regexStrDate = '^[0-9.]+$';

  constructor(
    private codebookService: CodebookService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder) {
    this.getCitizenship();
  }

  ngOnInit(): void {
    this.biologicalParentsStep = this.formBuilder.group({
      mother: this.formBuilder.group({
        firstName: ['', []],
        lastName: ['', []],
        dateOfBirth: ['', []],
        residence: ['', []],
        citizenship: ['', [/*Validators.required*/]],
      }),
      father: this.formBuilder.group({
        firstName: ['', []],
        lastName: ['', []],
        dateOfBirth: ['', []],
        residence: ['', []],
        citizenship: ['', [/*Validators.required*/]],
      })
    });
  }

  /**
   * Send step information to parent component
   */
  updateBiologicalParentsStep() {
    this.biologicalParentsStepEmitter.emit(this.biologicalParentsStep);
  }

  importSubjectData(mother: any, father: any) {
    const dialogRef = this.dialog.open(SubjectsComponent, {
      width: '1200px',
      panelClass:'overlay-panel',
      data: {
        origin: 'entrance',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        if (mother) {

          this.biologicalParentsStep.patchValue({
            mother: {
              firstName: result.data.firstName,
              lastName: result.data.lastName,
              dateOfBirth: result.data.dateOfBirth ?
                new Date(result.data.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')) : null,
              residence: String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
                String(result.data.permanentResidence.number ? ' ' : '') +
                String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
                String(result.data.permanentResidence.subnumber ? '/' : '') +
                String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : ''),
              citizenship: result.data.citizenship,
            }
          });
        } else {
          this.biologicalParentsStep.patchValue({
            father: {
              firstName: result.data.firstName,
              lastName: result.data.lastName,
              dateOfBirth: result.data.dateOfBirth ?
                new Date(result.data.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')) : null,
              residence: String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
                String(result.data.permanentResidence.number ? ' ' : '') +
                String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
                String(result.data.permanentResidence.subnumber ? '/' : '') +
                String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : ''),
              citizenship: result.data.citizenship,
            }
          });
        }
      }
    });
  }

  getCitizenship() {
    this.codebookService.getCitizenshipCodebook().subscribe((res) => {
      this.citizenshipOptions = res;
      this.filteredCitizenshipOptionsMother = (this.biologicalParentsStep.get('mother') as FormGroup).controls.citizenship.valueChanges
        .pipe(
          startWith(''),
          map(value => value === null ? null : typeof value === 'string' ? value : value?.title),
          map(title => title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice())
        );
      this.filteredCitizenshipOptionsFather = (this.biologicalParentsStep.get('father') as FormGroup).controls.citizenship.valueChanges
        .pipe(
          startWith(''),
          map(value => value === null ? null : typeof value === 'string' ? value : value?.title),
          map(title => title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice())
        );
    });
  }

  compareObj(object1: any, object2: any) {
    return object1 && object2 && object1.id === object2.id;
  }

  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();
    switch (what) {
      case AutocompleteFilterEnum.CITIZENSHIP:
        return this.citizenshipOptions.filter((option: any) =>
          option.title.toLowerCase().includes(filterValue)
        );
      default:
        break;
    }

  }

  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.title : '';
  }

  checkCitizenship(type: string) {
    let step;

    if (type === 'mother') {
      step = this.biologicalParentsStep.value.mother;
    } else {
      step = this.biologicalParentsStep.value.father;
    }
    if (step.citizenship !== undefined && step.citizenship !== null
      && step.citizenship !== '') {
      const choosedValue = step.citizenship;

      var result = this.citizenshipOptions.find(value => {
        if (choosedValue.title === undefined) {
          if (value.title === choosedValue) {
            return value;
          }
        } else {
          if (value.title === choosedValue.title) {
            return value;
          }
        }
      });

      if (result === undefined) {
        (this.biologicalParentsStep.get(type) as FormGroup).controls.citizenship.setErrors({ notValid: true });
      } else {
        (this.biologicalParentsStep.get(type) as FormGroup).controls.citizenship.setValue(result);
        (this.biologicalParentsStep.get(type) as FormGroup).controls.citizenship.setErrors(null);
      }
    }
  }

  checkInputDateFormat(fieldName: string, event: any): void {
    const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

    const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

    const control = this.biologicalParentsStep.get(fieldName);
    const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
    const inputDate = new Date(year, month - 1, day);

    if (inputDate > this.currentDate) {
       control.setErrors({ maxDateExceeded: true });
    } else {
       control.setErrors(null);
    }
 }

 onBlur(controlName: string) {
    const control = this.biologicalParentsStep.get(controlName);
    const dateValue = control.value;
    const inputValue = (document.querySelector(`[id="${controlName.replace('.', '-')}"]`) as HTMLInputElement).value;

    if (dateValue === null && inputValue !== '') {
       control.setErrors({ invalidFormat: true });
    } else {
       if (dateValue > this.currentDate) {
          control.setErrors({ maxDateExceeded: true });
       }
    }
 }

 @HostListener('keypress', ['$event'])
 onKeyPress(event: any) {
    const fieldName = event.target.name;
    if (fieldName === 'dateFormat') {
       return new RegExp(this.regexStrDate).test(event.key);
    }
 }
}
