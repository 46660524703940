<container>
  <page-intro-header title="{{ 'NEW_ESTABLISHED_FOSTER_CARE.TITLE' | translate }}" back="/established-foster-care">
  </page-intro-header>
  <mat-card class="card new-established-foster-care">
    <form [formGroup]="newRecord" class="form">

      <form-group title="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FOSTER_PARENT' | translate }}">

        <div class="button-add-holder">
          <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
            <mat-icon>person_add</mat-icon>
            {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
          </button>
        </div>
        <br>
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_ESTABLISHED_FOSTER_CARE.FORM.FIRST_NAME' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="firstName" required autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('firstName').hasError('required')">
              {{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_ESTABLISHED_FOSTER_CARE.FORM.LAST_NAME' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="lastName" required autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('lastName').hasError('required')">
              {{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_ESTABLISHED_FOSTER_CARE.FORM.GENDER' | translate
              }}</mat-label>
            <mat-select formControlName="gender" [compareWith]="compareObj"
              (selectionChange)="updateJMBGOnGenderChange()" required>
              <mat-option *ngFor="let gender of genderOptions" [value]="gender">
                {{ gender.gender }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="newRecord.get('gender').hasError('required')">
              {{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf=" newRecord.get('gender').hasError('incorrect')">
              {{ 'NEW_SUBJECT.PERSONAL_DETAILS.GENDER_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_ESTABLISHED_FOSTER_CARE.FORM.JMBG' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="jmbg" required autocomplete="off" maxlength="13"
              (input)="updateJMBGOnDateOfBirthChange();updateJMBGOnGenderChange()" name="jmbg" />
            <mat-error *ngIf="newRecord.get('jmbg').hasError('required')">
              {{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('jmbg').hasError('minlength') ||
                  newRecord.get('jmbg').hasError('pattern')">
              {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_ESTABLISHED_FOSTER_CARE.FORM.DATE_OF_BIRTH' | translate
              }}</mat-label>
            <input matInput [matDatepicker]="father" formControlName="dateOfBirth" maxlength="255"
              (dateChange)="updateJMBGOnDateOfBirthChange()" required autocomplete="off" name="dateFormat"
              placeholder="{{ 'DATE_FORMAT' | translate }}" [max]="currentDate"
              (input)="checkInputDateFormat('dateOfBirth', $event)" (blur)="onBlur('dateOfBirth')" />
            <mat-datepicker-toggle matSuffix [for]="father"></mat-datepicker-toggle>
            <mat-datepicker #father></mat-datepicker>
            <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('required')">
              {{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('incorrect')">
              {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('maxDateExceeded')">
              {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('invalidFormat')">
              {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_ESTABLISHED_FOSTER_CARE.FORM.PLACE_OF_BIRTH' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="placeOfBirth" required autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('placeOfBirth').hasError('required')">
              {{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder kind="2-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_ESTABLISHED_FOSTER_CARE.FORM.PLACE_OF_ORIGIN' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="placeOfOrigin" required autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('placeOfOrigin').hasError('required')">
              {{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_ESTABLISHED_FOSTER_CARE.FORM.CHILDREN_IN_FAMILY_COUNT'
              | translate
              }}</mat-label>
            <input matInput type="text" formControlName="childrenInFamilyCount" required autocomplete="off"
              maxlength="255" />
            <mat-error *ngIf="
                newRecord.get('childrenInFamilyCount').hasError('pattern')
              ">
              {{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.NUMBER_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_ESTABLISHED_FOSTER_CARE.FORM.CHILDREN_IN_FOSTER_CARE_COUNT'
              | translate
              }}</mat-label>
            <input matInput type="text" formControlName="childrenInFosterCareCount" required autocomplete="off"
              maxlength="255" />
            <mat-error *ngIf="
                newRecord.get('childrenInFosterCareCount').hasError('pattern')
              ">
              {{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.NUMBER_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_ESTABLISHED_FOSTER_CARE.FORM.COMPETENT_CENTER' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="competentCenterForSocialWork" required autocomplete="off"
              maxlength="255" />
            <mat-error *ngIf="
                newRecord
                  .get('competentCenterForSocialWork')
                  .hasError('required')
              ">
              {{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
      </form-group>
      <form-group title="{{
          'NEW_ESTABLISHED_FOSTER_CARE.CHILDREN_IN_FOSTER_CARE' | translate
        }}" isLast="true">
        <div class="members__table-wrapper table-responsive-wrapper" *ngIf="fosterChildren.length > 0">
          <table mat-table [dataSource]="fosterChildren" #mytable class="members__table">
            <ng-container matColumnDef="firstName">
              <th mat-header-cell *matHeaderCellDef>
                {{
                'NEW_ESTABLISHED_FOSTER_CARE.TABLE.FIRST_AND_LAST_NAME'
                | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.firstName + ' ' + element.lastName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dateOfBirth">
              <th mat-header-cell *matHeaderCellDef>
                {{
                'NEW_ESTABLISHED_FOSTER_CARE.TABLE.DATE_AND_PLACE_OF_BIRTH'
                | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dateOfBirth + ' ' + element.placeOfBirth }}
              </td>
            </ng-container>
            <ng-container matColumnDef="kinship">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_ESTABLISHED_FOSTER_CARE.TABLE.KINSHIP' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.kinship }}
              </td>
            </ng-container>
            <ng-container matColumnDef="formOfFosterCare">
              <th mat-header-cell *matHeaderCellDef>
                {{
                'NEW_ESTABLISHED_FOSTER_CARE.TABLE.FORM_OF_FOSTER_CARE'
                | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.formOfFosterCare?.title }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dateOfPlacementInAFosterFamily">
              <th mat-header-cell *matHeaderCellDef>
                {{
                'NEW_ESTABLISHED_FOSTER_CARE.TABLE.DATE_OF_PLACEMENT_IN_A_FOSTER_FAMILY'
                | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dateOfPlacementInAFosterFamily | date: 'dd.MM.yyyy.' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="childSupportBearers">
              <th mat-header-cell *matHeaderCellDef>
                {{
                'NEW_ESTABLISHED_FOSTER_CARE.TABLE.CHILD_SUPPORT_BEARERS'
                | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.childSupportBearers }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dateOfCustodyTermination">
              <th mat-header-cell *matHeaderCellDef>
                {{
                'NEW_ESTABLISHED_FOSTER_CARE.TABLE.DATE_OF_CUSTODY_TERMINATION'
                | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dateOfCustodyTermination | date: 'dd.MM.yyyy.' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="reasonsForCustodyTermination">
              <th mat-header-cell *matHeaderCellDef>
                {{
                'NEW_ESTABLISHED_FOSTER_CARE.TABLE.REASONS_FOR_CUSTODY_TERMINATION'
                | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.reasonsForCustodyTermination?.title }}
              </td>
            </ng-container>
            <ng-container matColumnDef="competentCenter">
              <th mat-header-cell *matHeaderCellDef>
                {{
                'NEW_ESTABLISHED_FOSTER_CARE.TABLE.COMPETENT_CENTER' | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.competentCenter }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowSelected(row)"
              [ngClass]="{ hovered: row.hovered, highlighted: row.highlighted }" (mouseover)="row.hovered = true"
              (mouseout)="row.hovered = false"></tr>
          </table>
        </div>
        <div class="button-holder">
          <button mat-flat-button color="primary" type="button" (click)="addFosterChildRecord()"
            class="form-footer__button form-footer__button--secondary">
            <mat-icon>person_add</mat-icon>
            {{ 'NEW_ESTABLISHED_FOSTER_CARE.ADD_MEMBER' | translate }}
          </button>
          <button mat-flat-button color="warn" (click)="removeFosterChildRecord()" [disabled]="!isFosterChildSelected"
            type="button">
            <mat-icon>person_remove</mat-icon>
            {{ 'NEW_ESTABLISHED_FOSTER_CARE.REMOVE_MEMBER' | translate }}
          </button>
        </div>
      </form-group>
      <form-footer>
        <button class="form-footer__button form-footer__button--primary" (click)="createRecord(newRecord)"
          mat-flat-button color="primary" type="submit"
          [disabled]="!newRecord.valid || this.fosterChildren.length === 0">
          {{ 'NEW_ESTABLISHED_FOSTER_CARE.CREATE' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-card>
</container>