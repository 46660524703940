/**
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { Page } from 'src/app/page';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalSizeEnum } from 'src/types';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { AuthenticationService } from 'src/services/authentication.service';

@Component({
   selector: 'app-user-details',
   templateUrl: './user-details.component.html',
   styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
   user;
   isEditMode = false;
   userId;
   roles = '';
   page: Page<any>;
   impersonate = false;
   constructor(
      private userService: UserService,
      private route: ActivatedRoute,
      public dialog: MatDialog,
      private toastr: ToastrImplService,
      public translate: TranslateService,
      private localStorageService: LocalStorageService,
      private authService: AuthenticationService
   ) {
      this.route.params.subscribe(params => {
         this.userId = params.id;
      });
      this.userService.findUserData(this.userId).subscribe(result => {
         this.user = result;
         this.user.roles.forEach(element => {
            this.roles += element.roleName + ' ';
         });
      });

      this.page = JSON.parse(localStorage.getItem('filterPage'));
      this.checkMainAdmin();
   }

   ngOnInit(): void {}

   enableUser(user) {
      let message = '';
      let title = '';
      if (user.enabled) {
         this.translate.get('WARNING_DIALOG.DISABLE_USER.TITLE').subscribe((res: string) => {
            title = res;
         });
         this.translate.get('WARNING_DIALOG.DISABLE_USER.MESSAGE').subscribe((res: string) => {
            message = res;
         });
      } else {
         this.translate.get('WARNING_DIALOG.ENABLE_USER.TITLE').subscribe((res: string) => {
            title = res;
         });
         this.translate.get('WARNING_DIALOG.ENABLE_USER.MESSAGE').subscribe((res: string) => {
            message = res;
         });
      }

      const object = {
         document: null,
         message: message,
         title: title,
         saveData: false,
      };

      const dialogRef = this.dialog.open(WarningMessageComponent, {
         data: object,
         width: ModalSizeEnum.MINI,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(res => {
         if (res?.event !== 'cancel' && res !== undefined) {
            this.userService.enableUser(user.userId, !user.enabled).subscribe(result => {
               let message = '';
               if (user.enabled) {
                  this.translate.get('SNACKBAR.USER_DISABLED').subscribe((res: string) => {
                     message = res;
                  });
               } else {
                  this.translate.get('SNACKBAR.USER_ENABLED').subscribe((res: string) => {
                     message = res;
                  });
               }
               this.toastr.showSuccess(message);
               this.user.enabled = !user.enabled;
               this.page.content = this.page.content.map(u => (u.userId !== result.userId ? u : result));
               this.localStorageService.set('filterPage', JSON.stringify(this.page));
            });
         }
      });
   }

   impersonateUser(user) {
      this.authService.impersonate(user.userId);
   }

   resetPassword(user) {
      const dialogRef = this.dialog.open(ResetPasswordComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: user.userId,
      });

      dialogRef.afterClosed().subscribe(result => {
         if (result.event === 'SUCCESS') {
            this.toastr.success('SNACKBAR.PASSWORD_UPDATED');
         }
      });
   }

   patchUserAfterEdit(event) {
      this.userService.findUserData(this.userId).subscribe(result => {
         this.isEditMode = false;
         this.user = result;
      });
   }

   checkMainAdmin() {
      const isGlobalAdmin = this.userService.isGlobalAdmin();
      if (!isGlobalAdmin) {
         return;
      }
      this.impersonate = this.localStorageService.get('impersonate') === '1';
   }

   editUser() {
      this.isEditMode = true;
   }
}
