<ng-container>
   <form class="form" [formGroup]="caseConclusion">
      <mat-card>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'LAWYER.DECISION_TYPE' | translate}}
               </mat-label>
               <mat-select formControlName="decisionType" (selectionChange)="showPreviewButton($event.value)" required
                  [compareWith]="compareLoadedObjects">
                  <mat-option *ngFor="let decType of decisionTypes" [value]="decType">
                     {{ decType.title}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.INITIATOR' | translate}}
               </mat-label>
               <mat-select formControlName="initiator" [compareWith]="compareLoadedObjects">
                  <mat-option *ngFor="let init of initiatorCodebook" [value]="init">
                     {{ init.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-radio-group aria-label="Select an option" color="primary" formControlName="decisionMaker"
               class="form__radio-group form__radio-group--2-cols">
               <span class="form__radio-group-label">
                  {{ 'LAWYER.DECISION_MAKER' | translate }}
               </span><br>
               <mat-radio-button *ngFor="let option of decisionMakerOptions" [value]="option"
                  (change)="decisionMakerSelected($event)">
                  {{ option.title }}
               </mat-radio-button>
            </mat-radio-group>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline" *ngIf="caseConclusion.value.decisionMaker?.id === 2">
               <mat-label>{{ 'LAWYER.DECISION_MAKER_DESCRIPTION' | translate }}</mat-label>
               <textarea matInput type="text" formControlName="decisionMakerDescription" autocomplete="off"
                  matTooltip="{{'LAWYER.DECISION_MAKER_DESCRIPTION_TOOLTIP'| translate}}">
               </textarea>
            </mat-form-field>
         </field-holder>

         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'LAWYER.DECISION_MAKER_TITLE' | translate }}</mat-label>
               <input matInput type="text" formControlName="decisionMakerTitle" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'LAWYER.DECISION_MAKER_NAME' | translate }}
               </mat-label>
               <mat-select formControlName="decisionMakerName" [compareWith]="compareDecisionMakerNameObjects" required>
                  <mat-option *ngFor="let signature of signatures" [value]="signature">
                     {{ signature }}
                  </mat-option>
               </mat-select>
            </mat-form-field>

         </field-holder>

         <field-holder kind="3-cols" *ngIf="showPreview===true">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'LAWYER.AMOUNT' | translate }}</mat-label>
               <input matInput type="text" formControlName="nspAmount" autocomplete="off" readonly />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ "LAWYER.START_DATE" | translate }}</mat-label>
               <input matInput [matDatepicker]="picker" required formControlName="validFrom" #ref
                  (dateChange)=changeNspRightCodebook(ref.value) autocomplete="off" [max]="maxDate"/>
               <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
               <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ "LAWYER.END_DATE" | translate }}</mat-label>
               <input matInput [matDatepicker]="picker2" formControlName="validTo" autocomplete="off" 
               [min]="minToDate" [max]="maxDate"/>
               <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
               <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
         </field-holder>
         <div>
            <mat-checkbox color="primary" class="form__checkbox" formControlName="yearlyReconsideration">
               {{ 'LAWYER.YEARLY_RECONSIDERATION' | translate }}
            </mat-checkbox>
         </div>
         <ng-container *ngIf="showPreview">

            <mat-checkbox (change)="changeChildCheckbox()" color="primary" class="form__checkbox"
               formControlName="child" *ngIf="showPreview">
               {{ 'DPN.CHILD' | translate }}
            </mat-checkbox>
            <br>
            <div class="button-add-holder" *ngIf="child==true && showPreview">
               <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
                  <mat-icon>person_add</mat-icon>
                  {{ "GLOBAL.IMPORT_SUBJECT_REIMBURSEMENT" | translate }}
               </button>
            </div>
            <br>
            <field-holder kind="4-cols" *ngIf="child==true && showPreview">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'CASES.PAYMENT_RECIPIENT' | translate}}
                  </mat-label>
                  <mat-select formControlName="paymentRecipient" [compareWith]="compareLoadedObjects">
                     <mat-option></mat-option>
                     <mat-option *ngFor="let paymentRecipient of paymentRecipients" [value]="paymentRecipient">
                        {{ paymentRecipient.title}}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.PARENT.FIRST_NAME' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentFirstName" autocomplete="off" />
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.PARENT.LAST_NAME' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentLastName" autocomplete="off" />
               </mat-form-field>


               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.REG_BR' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentRegBr" autocomplete="off" />
               </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols" *ngIf="child==true && showPreview">
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.PARENT.RESIDENCE_ADDRESS' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentResidenceAddress" autocomplete="off" />
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.PARENT.ADDRESS' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentAddress" autocomplete="off" />
               </mat-form-field>

               <mat-form-field appearance="outline">
                  <mat-label>{{ 'DPN.PARENT.ADDRESS_BR' | translate }}</mat-label>
                  <input matInput type="text" formControlName="parentAddressNum" autocomplete="off" />
               </mat-form-field>
            </field-holder>
         </ng-container>



         <field-holder kind="3-cols" *ngIf="caseConclusion?.get('decisionType')?.value?.id===3">
            <mat-form-field appearance="outline">
               <mat-label>{{ "LAWYER.TERMINATION_DATE" | translate }}</mat-label>
               <input matInput [matDatepicker]="picker" required formControlName="terminationDate" [max]="maxDate" autocomplete="off" />
               <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
               <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-checkbox color="primary" class="form__checkbox" formControlName="inactive">
               {{ 'LAWYER.INACTION' | translate }}
            </mat-checkbox>
         </field-holder>
         <br>
         <field-holder>
            <mat-form-field appearance="outline" *ngIf="caseConclusion?.get('decisionType')?.value?.id===3">
               <mat-label>
                  {{ 'GLOBAL.DECISION_FOR_CASE_3'| translate }}
               </mat-label>
               <mat-select formControlName="terminationReason" [compareWith]="compareLoadedObjects" required>
                  <mat-option *ngFor="let reason of terminationReasons" [value]="reason">
                     {{ reason.title}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>

         <div class="aside" *ngIf="showPreview===false">
            <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
               (click)="$event.stopPropagation()">
               {{ 'LAWYER.DOCUMENT' | translate }}
               <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="filter-menu">
               <ng-container *ngFor="let docOptions of documentOptions">
                  <button mat-menu-item (click)="handleNewEvaluation(docOptions)">{{ docOptions.name }}</button>
               </ng-container>
            </mat-menu>

            <input type="file" #file accept=".docx, .pdf" (change)="handleFileInput($event.target.files)" hidden>
            <button class="header__add-document" style="margin-left: 15px;" mat-flat-button color="primary"
               type="submit" (click)="file.click()">
               {{ 'LAWYER.CREATE' | translate }}
            </button>
            <br>
            <field-holder>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'LAWYER.SOLUTION' | translate }}</mat-label>
                  <input matInput type="text" formControlName="fileName" readonly required autocomplete="off" />
               </mat-form-field>
            </field-holder>
         </div>
         <form-footer class="footer">
            <span matTooltip="{{'CASES.COMPLAIN_RESPOND_NEEDED' | translate }}" [matTooltipDisabled]="!newComplain">
               <button mat-flat-button color="primary" (click)="submitConclusion(); submitButton.disabled = true;"
                  class="form-footer__button form-footer__button--secondary"
                  [disabled]="caseData?.caseStatus !== 'ACCEPTED'||!caseConclusion.valid||newComplain||notValidDate|| !hasProblem"
                  #submitButton>
                  {{ 'LAWYER.SUBMIT_AND_SEND' | translate }}
               </button></span>
            <span>
               <button mat-flat-button color="primary" type="button" *ngIf="showPreview" (click)="preview()"
                  class="form-footer__button form-footer__button--primary" [disabled]="notValidDate">
                  {{ "PRINT.PREVIEW" | translate }}
               </button>
            </span>
            <mat-progress-spinner [diameter]="45" *ngIf="isSubmited" color="primary" mode="indeterminate">
            </mat-progress-spinner>
         </form-footer>
      </mat-card>
   </form>
</ng-container>
