<form class="form" [formGroup]="newRecord" (ngSubmit)="addNewRecord(newRecord)">
  <form-group title="{{ 'NEW_DEPENDENT.NEW_RECORD' | translate }}" isLast="true">
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.SUPPORT_MANNER_MONEY' | translate }}</mat-label>
        <input matInput type="text" formControlName="supportMannerMoney" autocomplete="off" maxlength="10"  />
        <mat-error *ngIf="newRecord.get('supportMannerMoney').hasError('pattern')">
          {{ 'NEW_DEPENDENT.FORM.MONEY_ERROR' | translate }}
        </mat-error>
        <mat-hint>
          {{ 'NEW_DEPENDENT.FORM.SUPPORT_MANNER_HINT' | translate }}
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.SUPPORT_MANNER_OTHER' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="supportMannerOther" autocomplete="off" maxlength="255" />
        <mat-error *ngIf="newRecord.get('supportMannerOther').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-hint>
          {{ 'NEW_DEPENDENT.FORM.SUPPORT_MANNER_HINT' | translate }}
        </mat-hint>
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.SUPPORT_AMMOUNT_FISCAL' | translate }}</mat-label>
        <input matInput type="text" formControlName="supportAmmountFiscal" autocomplete="off" maxlength="10" />
        <mat-error *ngIf="newRecord.get('supportAmmountFiscal').hasError('pattern')">
          {{ 'NEW_DEPENDENT.FORM.MONEY_ERROR' | translate }}
        </mat-error>
        <mat-hint>
          {{ 'NEW_DEPENDENT.FORM.SUPPORT_AMMOUNT_HINT' | translate }}
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.SUPPORT_AMMOUNT_PERCENTAGE' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="supportAmmountPercentage" autocomplete="off"
          (input)="checkPercent()" />
        <mat-error *ngIf="newRecord.get('supportAmmountPercentage').hasError('pattern')">
          {{ 'NEW_DEPENDENT.FORM.SUPPORT_AMMOUNT_PERCENTAGE_ERROR' | translate }}
        </mat-error>
        <mat-hint>
          {{ 'NEW_DEPENDENT.FORM.SUPPORT_AMMOUNT_HINT' | translate }}
        </mat-hint>
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.SUPPORT_DURATION_LIMITED' | translate }}</mat-label>
        <input matInput type="text" formControlName="supportDurationLimited" autocomplete="off" maxlength="255" />
        <mat-hint>
          {{ 'NEW_DEPENDENT.FORM.SUPPORT_DURATION_HINT' | translate }}
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.SUPPORT_DURATION_UNLIMITED' | translate }}</mat-label>
        <input matInput type="text" formControlName="supportDurationUnlimited" autocomplete="off" maxlength="255" />
        <mat-hint>
          {{ 'NEW_DEPENDENT.FORM.SUPPORT_DURATION_HINT' | translate }}
        </mat-hint>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button mat-stroked-button color="primary" class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()" type="button">
      {{ 'NEW_DEPENDENT.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" class="modal-footer__button modal-footer__button--primary" type="submit"
      [disabled]="!(newRecord.valid && isEnabled())">
      {{ 'NEW_DEPENDENT.SAVE' | translate }}
    </button>
  </form-footer>
</form>