/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { autoSelect } from 'src/app/utils/autoSelect';
import { CodebookService } from 'src/services/codebook.service';
import { StatisticsService } from 'src/services/statistics.service';
import { UserService } from 'src/services/user.service';

@Component({
   selector: 'nsp-stats',
   templateUrl: './nsp-stats.component.html',
   styleUrls: ['./nsp-stats.component.scss'],
})
export class NspStatsComponent implements OnInit {
   nspStatsForm: FormGroup;
   recordOptions = [
      { id: 1, name: 'Сви корисници који су носиоци НСП' },
      { id: 2, name: 'Сви корисници који су носиоци НСП са њиховим члановима домаћинства' },
      { id: 3, name: 'Сви корисници који су радно способни' },
      { id: 4, name: 'Сви корисници који нису радно способни' },
   ];

   centers = [];
   filteredAllCenters: any = [];
   loggedIn: any;
   isMainCenterBelgrade = false;
   btnDisabled = false;
   genderOptions = [];
   isUserDrugostepeni = false;
   maxDate: Date = new Date(2999, 11, 31);

   @ViewChild('allSelectedCenters') private allSelectedCenters: MatOption;
   @ViewChild('multiSearch') private multiSearch: ElementRef;
   constructor(
      private formBuilder: FormBuilder,
      private statisticsService: StatisticsService,
      private codebookService: CodebookService,
      private localStorageService: LocalStorageService,
      private translate: TranslateService,
      private userService: UserService
   ) {
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.filteredAllCenters = this.centers;
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.centers = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.filteredAllCenters = this.centers;
         });
      }
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });
      this.btnDisabled = false;
   }

   ngOnInit(): void {
      this.resetForm();
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }
   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.nspStatsForm);
   }
   /**
    * Send request and generate Excell report
    */
   sendRequest(form: any) {
      let filename = '';
      this.translate.get('STATISTICS.NSP_STATS.TITLE').subscribe((res: string) => {
         filename = res;
      });
      const dto = this.createDto();
      this.statisticsService.getNspStatistics(dto, filename);
   }

   createDto() {
      const form = this.nspStatsForm;
      const csrId = this.isMainCenterBelgrade || this.isUserDrugostepeni ? form.value.center : [this.loggedIn.csrId];
      const queryId = form.value.record.id;
      const dateFrom = form.value.dateFrom;
      const dateTo = form.value.dateTo;
      return {
         csrId,
         queryId,
         dateFrom,
         dateTo,
      };
   }

   resetForm() {
      this.nspStatsForm = this.formBuilder.group({
         record: ['', [Validators.required]],
         center: [''],
         dateFrom: [''],
         dateTo: [''],
         gender: [null, []],
         ageGroup: [null, []],
      });
   }
   selectMulti() {
      this.multiSearch.nativeElement.focus();
      this.multiSearch.nativeElement.value = null;
      this.filteredAllCenters = this.centers;
   }

   toggleAllSelectionCenter() {
      if (this.allSelectedCenters.selected) {
         this.nspStatsForm.controls.center.patchValue([...this.centers.map(item => item.id), 0]);
      } else {
         this.nspStatsForm.controls.center.patchValue([]);
      }
   }
   tosslePerOneCenter() {
      if (this.allSelectedCenters.selected) {
         this.allSelectedCenters.deselect();
         return false;
      }
      if (this.nspStatsForm.controls.center.value.length === this.centers.length) {
         this.allSelectedCenters.select();
      }
   }
   onInputChange(inputValue: string) {
      const input = inputValue.toLowerCase();
      this.filteredAllCenters = this.centers.filter((center: any) => {
         const selectedCenters = this.nspStatsForm.controls.center.value;
         return center.name.toLowerCase().includes(input) || selectedCenters.includes(center.id);
      });
   }
}
