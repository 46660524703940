import { DatePipe } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodebookService } from 'src/services/codebook.service';
import { ModalEventEnum } from 'src/types';

@Component({
   selector: 'termination-of-foster-care',
   templateUrl: './termination-of-foster-care.component.html',
   styleUrls: ['./termination-of-foster-care.component.scss'],
})
export class TerminationOfFosterCareComponent implements OnInit {
   terminationForm: FormGroup;
   currentDate = new Date();
   reasonsForCustodyTerminations = [];
   constructor(
      private datePipe: DatePipe,
      private formBuilder: FormBuilder,
      public dialogRef: MatDialogRef<any>,
      private codebookService: CodebookService,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any
   ) {
      this.getReasonsForTerminationOfFosterCare();
   }
   regexStrDate = '^[0-9.]+$';

   ngOnInit(): void {
      this.terminationForm = this.formBuilder.group({
         courtName: [this.data.child.courtName],
         courtNumber: [this.data.child.courtNumber],
         dateOfCustodyTermination: [this.data.child.dateOfCustodyTermination, [Validators.required]],
         reasonsForCustodyTermination: [this.data.child.reasonsForCustodyTermination, [Validators.required]],
      });
   }

   getReasonsForTerminationOfFosterCare() {
      this.codebookService.getReasonsForTerminationOfFosterCareCodebook().subscribe(result => {
         this.reasonsForCustodyTerminations = result;
      });
   }

   addNewRecord(trackingDocumentForm: FormGroup) {
      trackingDocumentForm.get('dateOfCustodyTermination').setValue(this.datePipe.transform(new Date(trackingDocumentForm.value.dateOfCustodyTermination).getTime(), 'yyyy-MM-dd'));
      this.dialogRef.close({
         event: ModalEventEnum.SUCCESS,
         data: trackingDocumentForm.value,
      });
   }

   compareObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
   }

   closeDialog() {
      this.dialogRef.close({ event: ModalEventEnum.CANCEL });
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.terminationForm.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);

      if (inputDate > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.terminationForm.get(controlName);
      const dateValue = control.value;
      const inputValue = (document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement).value;

      if (dateValue === null && inputValue !== '') {
         control.setErrors({ invalidFormat: true });
      } else {

         if (dateValue > this.currentDate) {
            control.setErrors({ maxDateExceeded: true });
         } else {
            control.setErrors(null);
         }
      }
   }

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      }
   }
}
