<container kind="midi">
    <page-intro-header back="/subjects" [isAdmin]="isAdmin"
        title="{{ 'SUBJECT_DETAILS.TITLE' | translate }} - {{subject?.firstName}} {{subject?.lastName}}, {{'SUBJECT_DETAILS.REG_BR' | translate }} {{subject?.registrationId === null? '/' : subject?.registrationId }}">
        <span matTooltip="{{'SNACKBAR.ARCHIVE_UNARCHIVE_IMPOSSIBLE' | translate }}"
            [matTooltipDisabled]="subject?.status?.id !== 3">
            <button [disabled]="subject?.status?.id === 3" mat-flat-button color="primary" [matMenuTriggerFor]="menu"
                class="dropdown-button" *ngIf="!isMainCenterBelgrade && !isUserDrugostepeni && !isAdmin">
                {{ 'GLOBAL.MANAGE' | translate }}
                <mat-icon>arrow_drop_down</mat-icon>
            </button>
        </span>
        <mat-menu #menu="matMenu" xPosition="before">
            <span>
                <button *ngIf="!isArchived" mat-menu-item (click)="openDialog('archive', subject)">
                    <mat-icon>archive</mat-icon> {{ 'SUBJECT_DETAILS.ARCHIVE' | translate }}
                </button>
            </span>
            <span>
                <button *ngIf="isArchived" mat-menu-item (click)="openDialog('unarchive', subject)">
                    <mat-icon>unarchive</mat-icon> {{ 'SUBJECT_DETAILS.UNARCHIVE' | translate }}
                </button>
            </span>
        </mat-menu>

    </page-intro-header>
    <div *ngIf="subject?.status?.id!==2">
        <h4 class="assigned_dossier__title" *ngIf="subject?.dossierOwner!==null">
            {{ 'SUBJECT_DETAILS.DOSSIER_OWNER' | translate }}
            {{subject.dossierOwner.firstName + " " + subject.dossierOwner.lastName}}</h4>
    </div>
    <div *ngIf="subject?.status?.id===2">
        <status-label status="{{subject.status.id}}">
            {{ 'SUBJECT_DETAILS.DOSSIER_ARCHIVED' | translate }}
        </status-label>
    </div>

    <mat-tab-group (selectedTabChange)="selectTab($event)" animationDuration="0">
        <mat-tab label="{{ 'SUBJECT_DETAILS.TAB_ONE' | translate }}" class="tab-content">
            <subject-cases></subject-cases>
            <subject-problems [status]="subject?.status?.id===2 || subject?.registrationId === null"></subject-problems>
            <subject-record [status]="subject?.status?.id===2 || subject?.registrationId === null"></subject-record>
        </mat-tab>
        <mat-tab label="{{ 'SUBJECT_DETAILS.TAB_TWO' | translate }}" class="tab-content">
            <mat-card class="card card--edit" *ngIf="isEditMode">
                <subject-form isEditMode="true" (emitUpdatedSubject)="patchSubjectAfterEdit($event)"
                    [registrationId]="registrationId"></subject-form>
            </mat-card>
            <mat-card class="card" *ngIf="!isEditMode">
                <card-header title="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.TITLE' | translate }}">
                    <span [matTooltip]="
              subject?.status?.id !== 2
                ? editUserDetailsText
                : editUsertDetailTextError
            ">
                        <button mat-flat-button color="primary" (click)="editSubject()"
                            [disabled]="subject?.status.id == 2"
                            *ngIf="(!isMainCenterBelgrade && !isUserDrugostepeni) && !isAdmin">
                            {{ 'GLOBAL.EDIT' | translate }}
                        </button>
                    </span>
                </card-header>
                <definition-list>
                    <definition-item *ngIf="subject?.historicalSubjectIdentity?.subjectId"
                        key="{{ 'GLOBAL.SUBJECT.ID' | translate }}">
                        {{ subject?.historicalSubjectIdentity?.subjectId }}</definition-item>
                    <definition-item *ngIf="subject?.registrationId"
                        key="{{ 'GLOBAL.SUBJECT.REGISTRATION_ID' | translate }}">
                        {{ subject?.registrationId }}</definition-item>
                    <definition-item *ngIf="subject?.status?.title && subject?.status.id !== 3"
                        key="{{ 'GLOBAL.SUBJECT.STATUS' | translate }}">
                        <status-label *ngIf="subject?.status.id !== 3" status="{{ subject.status.id }}">
                            {{subject?.status?.title}}</status-label>
                    </definition-item>
                    <definition-item *ngIf="subject?.firstName"
                        key="{{ 'GLOBAL.SUBJECT.FIRST_AND_LAST_NAME' | translate }}">
                        {{ subject?.firstName }} {{ subject?.lastName }}</definition-item>
                    <definition-item *ngIf="subject?.parentName" key="{{ 'SUBJECTS.PARENTNAME' | translate }}">
                        {{ subject?.parentName }}</definition-item>
                    <definition-item *ngIf="subject?.gender"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.GENDER' | translate }}">
                        {{ subject?.gender?.gender }}
                    </definition-item>
                    <definition-item *ngIf="subject?.dateOfBirth"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH' | translate }}">
                        {{ subject?.dateOfBirth }}
                    </definition-item>
                    <definition-item *ngIf="subject?.yearOfBirth"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.YEAR_OF_BIRTH' | translate }}">
                        {{ subject?.yearOfBirth }}
                    </definition-item>
                    <definition-item *ngIf="subject?.jmbg && subject?.foreigner!==true"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG' | translate }}">
                        {{ subject?.jmbg }}</definition-item>
                    <definition-item *ngIf="subject?.jmbg && subject?.foreigner===true"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.EBS' | translate }}">
                        {{ subject?.jmbg }}</definition-item>
                    <definition-item *ngIf="subject?.placeOfBirth"
                        key="{{'NEW_SUBJECT.PERSONAL_DETAILS.PLACE_OF_BIRTH' | translate}}">{{ subject?.placeOfBirth
                        }}</definition-item>
                    <definition-item *ngIf="subjectNationality"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.NATIONALITY' | translate }}">
                        {{ subject?.nationality?.title }}
                    </definition-item>
                    <definition-item *ngIf="subject?.citizenship"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.CITIZENSHIP' | translate }}">
                        {{ subject?.citizenship?.title }}
                    </definition-item>
                    <definition-item *ngIf="subject?.category"
                        key="{{ 'GLOBAL.SUBJECT.CATEGORY_OF_PERSON' | translate }}">
                        {{ subject?.category?.title }}
                    </definition-item>
                    <definition-item *ngIf="subject?.nativeLanguage"
                        key="{{'NEW_SUBJECT.PERSONAL_DETAILS.NATIVE_LANGUAGE' | translate}}">{{ subject?.nativeLanguage
                        }}</definition-item>
                    <definition-item-divider *ngIf="showDivider"></definition-item-divider>
                    <definition-item *ngIf="subject?.disabledPerson !== null"
                        key="{{'NEW_SUBJECT.PERSONAL_DETAILS.DISABLED_PERSON' | translate}}">
                        {{ (subject?.disabledPerson ? 'GLOBAL.YES' : 'GLOBAL.NO') | translate }} </definition-item>
                    <definition-item *ngIf="subject?.disabilityType"
                        key="{{'NEW_SUBJECT.PERSONAL_DETAILS.DISABILITY_TYPE' | translate}}">{{ subject?.disabilityType
                        }}</definition-item>
                    <definition-item *ngIf="subject?.ableToWork !== null"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.ABLE_TO_WORK' | translate }}">
                        {{ (subject?.ableToWork ? 'GLOBAL.YES' : 'GLOBAL.NO') | translate }}
                    </definition-item>
                    <definition-item *ngIf="subject?.occupation"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.OCCUPATION' | translate }}">
                        {{ subject?.occupation?.title }}</definition-item>
                    <definition-item *ngIf="subject?.employment"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.EMPLOYMENT' | translate }}">
                        {{ subject?.employment?.title }}</definition-item>
                    <definition-item *ngIf="subject?.education"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.EDUCATION' | translate }}">
                        {{ subject?.education?.title }}
                    </definition-item>
                    <definition-item *ngIf="subject?.levelOfEducation"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.LEVEL_OF_EDUCATION' | translate }}">
                        {{ subject?.levelOfEducation?.title }}
                    </definition-item>
                    <definition-item *ngIf="subject?.schoolAttending"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.SCHOOL_ATTENDING' | translate }}">
                        {{ subject?.schoolAttending?.title }}
                    </definition-item>
                    <definition-item *ngIf="subject?.businessAbility !== null"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.BUSINESS_ABILITY' | translate }}">
                        {{ (subject?.businessAbility ? 'GLOBAL.YES' : 'GLOBAL.NO') | translate }}
                    </definition-item>
                    <definition-item *ngIf="subject?.deprivationExtent"
                        key="{{'NEW_SUBJECT.PERSONAL_DETAILS.DEPTIVATION_EXTENT' | translate}}">{{
                        subject?.deprivationExtent.title }}</definition-item>
                    <definition-item *ngIf="subject?.maritalStatus"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.MARITAL_STATUS' | translate }}">
                        {{ subject?.maritalStatus?.title }}
                    </definition-item>
                </definition-list>
                <card-subgroup-divider></card-subgroup-divider>
                <card-header title="{{ 'NEW_SUBJECT.ADDRESS.TITLE' | translate }}"></card-header>
                <definition-list>
                    <definition-item
                        *ngIf="subject?.permanentResidence?.street || subject?.permanentResidence?.number ||subject?.permanentResidence?.subnumber ||subject?.permanentResidence?.ptt "
                        key="{{ 'GLOBAL.SUBJECT.ORIGIN_ADDRESS_FULL' | translate }}">
                        {{ subject?.permanentResidence?.street }}
                        {{ subject?.permanentResidence?.number }}
                        <ng-container
                            *ngIf="subject?.permanentResidence?.subnumber !== '' && subject?.permanentResidence?.subnumber !== null||subject?.permanentResidence?.town||subject?.permanentResidence?.localComunity||subject?.permanentResidence?.populatedPlace?.title ">
                            / {{ subject?.permanentResidence?.subnumber }}
                        </ng-container>
                        <br />
                        {{ subject?.permanentResidence?.ptt }}
                        {{ subject?.permanentResidence?.town }}
                        <ng-container
                            *ngIf="subject?.permanentResidence?.localComunity !== null || subject?.permanentResidence?.populatedPlace?.title !== null">
                            (
                        </ng-container>
                        {{ subject?.permanentResidence?.localComunity }}
                        <ng-container *ngIf="subject?.permanentResidence?.populatedPlace?.title !== null">,
                        </ng-container>
                        {{ subject?.permanentResidence?.populatedPlace?.title }}
                        <ng-container
                            *ngIf="subject?.permanentResidence?.localComunity !== null || subject?.permanentResidence?.populatedPlace?.title !== null">
                            )
                        </ng-container>
                    </definition-item>
                    <definition-item
                        *ngIf=" subject?.residence?.street||subject?.residence?.number||subject?.residence?.subnumber||subject?.residence?.ptt||subject?.residence?.town||subject?.residence?.localComunity||subject?.residence?.populatedPlace?.title"
                        key="{{ 'GLOBAL.SUBJECT.RESIDENCE_ADDRESS_FULL' | translate }}">
                        {{ subject?.residence?.street }} {{ subject?.residence?.number }}
                        <ng-container
                            *ngIf="subject?.residence?.subnumber !== '' && subject?.residence?.subnumber !== null">
                            / {{ subject?.residence?.subnumber }}
                        </ng-container>
                        <br />
                        {{ subject?.residence?.ptt }} {{ subject?.residence?.town }}
                        <ng-container
                            *ngIf="subject?.residence?.localComunity !== null || subject?.residence?.populatedPlace?.title !== null">
                            (
                        </ng-container>
                        {{ subject?.residence?.localComunity }}
                        <ng-container *ngIf="subject?.residence?.populatedPlace?.title !== null">,</ng-container>
                        {{ subject?.residence?.populatedPlace?.title }}
                        <ng-container
                            *ngIf="subject?.residence?.localComunity !== null || subject?.residence?.populatedPlace?.title !== null">
                            )
                        </ng-container>
                    </definition-item>
                    <definition-item *ngIf="subject?.phone" key="{{ 'NEW_SUBJECT.ADDRESS.PHONE' | translate }}">
                        {{ subject?.phone }}</definition-item>
                    <definition-item *ngIf="subject?.mobile" key="{{ 'NEW_SUBJECT.ADDRESS.MOBILE' | translate }}">
                        {{ subject?.mobile }}</definition-item>
                    <definition-item *ngIf="subject?.email" key="{{ 'NEW_SUBJECT.ADDRESS.EMAIL' | translate }}">
                        {{ subject?.email }}
                    </definition-item>
                </definition-list>
                <card-subgroup-divider></card-subgroup-divider>

                <card-header title="{{ 'NEW_SUBJECT.FAMILY.TITLE' | translate }}"></card-header>
                <definition-list>
                    <definition-item *ngIf="subject?.familyDossier"
                        key="{{ 'NEW_SUBJECT.FAMILY.FAMILY_DOSSIER' | translate }}">
                        {{ familyDossier }}
                    </definition-item>
                    <definition-item *ngIf="subject?.jmbp" key="{{ 'NEW_SUBJECT.FAMILY.JMBP' | translate }}">
                        {{ subject?.jmbp }}
                    </definition-item>
                    <definition-item *ngIf="subject?.familyType?.title"
                        key="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.FAMILY_TYPE' | translate }}">
                        {{ subject?.familyType?.title }}
                    </definition-item>
                </definition-list>
                <card-subgroup-divider></card-subgroup-divider>

                <card-header title="{{ 'NEW_SUBJECT.OTHER.TITLE' | translate }}">
                </card-header>
                <definition-list>
                    <definition-item *ngIf="subject?.note" key="{{ 'NEW_SUBJECT.OTHER.NOTE' | translate }}">
                        {{ subject?.note }}
                    </definition-item>
                    <definition-item *ngIf="subject?.note1" key="{{ 'NEW_SUBJECT.OTHER.NOTE1' | translate }}">
                        {{ subject?.note1 }}
                    </definition-item>
                    <definition-item *ngIf="subject?.note2" key="{{ 'NEW_SUBJECT.OTHER.NOTE2' | translate }}">
                        {{ subject?.note2 }}
                    </definition-item>
                    <definition-item *ngIf="subject?.note3" key="{{ 'NEW_SUBJECT.OTHER.NOTE3' | translate }}">
                        {{ subject?.note3 }}
                    </definition-item>
                    <definition-item *ngIf="subject?.dateOfCreation"
                        key="{{ 'NEW_SUBJECT.OTHER.DATE_OF_CREATION' | translate }}">
                        {{ subject?.dateOfCreation }}
                    </definition-item>
                    <definition-item *ngIf="subject?.modifiedBy" key="{{ 'SUBJECT_DETAILS.MODIFIED_BY' | translate }}">
                        {{ subject?.modifiedBy }}
                    </definition-item>
                    <definition-item *ngIf="subject?.dateOfModification"
                        key="{{ 'SUBJECTS.DATEOFMODIFICATION' | translate }}">
                        {{ subject?.dateOfModification }}
                    </definition-item>
                </definition-list>
            </mat-card>
            <subject-family [subjectId]="subjectId" [jmbp]="subject?.jmbp" (emitEditUser)="reloadUser()"
                [subject]="subject" [isModificationDisabled]="subject?.familyDossier !== 'CARRIER'"
                [familyDossierId]="subject?.familyDossierId">
            </subject-family>
        </mat-tab>
        <mat-tab label="{{ 'SUBJECT_DETAILS.TAB_THREE' | translate }}" class="tab-content">
            <subject-history *ngIf="isSubjectHistoryRendered"></subject-history>
        </mat-tab>
    </mat-tab-group>
</container>