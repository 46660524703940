/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Pageable } from 'src/app/pageable';

@Injectable({
   providedIn: 'root',
})
export class CodebookService {
   constructor(private httpClient: HttpClient, private app: AppService) {}

   getActivityCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getActivityCodebook, { headers }) as Observable<any>;
   }

   getProblemsForComponentCodebook(component: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getProblemsCodebook + '/' + component, { headers }) as Observable<any>;
   }

   getProblemsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getProblemsCodebook, { headers }) as Observable<any>;
   }

   getActiveProblemsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getActiveProblemsCodebook, { headers }) as Observable<any>;
   }

   getProblemsIntensityCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getProblemsIntensityCodebook, { headers }) as Observable<any>;
   }

   getCaseKindCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCaseKindCodebook, { headers }) as Observable<any>;
   }

   getProblemsDurationCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getProblemsDurationCodebook, { headers }) as Observable<any>;
   }

   getCityCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCityCodebook, { headers }) as Observable<any>;
   }

   getStreetCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getStreetCodebook, { headers }) as Observable<any>;
   }

   getLanguageCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getLanguageCodebook, { headers }) as Observable<any>;
   }

   getNationalityCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getNationalityCodebook, { headers }) as Observable<any>;
   }

   getPersonCategoryCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPersonCategoryCodebook, { headers }) as Observable<any>;
   }

   getFamilyTypeCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFamilyTypeCodebook, { headers }) as Observable<any>;
   }

   getCitizenshipCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCitizenshipCodebook, { headers }) as Observable<any>;
   }

   getSocialCaseClassificationCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getSocialCaseClassificationCodebook, { headers }) as Observable<any>;
   }

   getServiceProviderCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getServiceProviderCodebook, { headers }) as Observable<any>;
   }

   getEducationCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getEducationCodebook, { headers }) as Observable<any>;
   }

   getOccupationCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getOccupationCodebook, { headers }) as Observable<any>;
   }

   getMaritalStatusCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getMaritalStatusCodebook, { headers }) as Observable<any>;
   }

   getEmploymentCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getEmploymentCodebook, { headers }) as Observable<any>;
   }

   getRelationshipCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getRelationshipCodebook, { headers }) as Observable<any>;
   }

   getRelationshipCodebookWithoutUser() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getRelationshipCodebookWithoutUser, { headers }) as Observable<any>;
   }

   getInitiatorCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getInitiatorCodebook, { headers }) as Observable<any>;
   }

   getPaymentMethodCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPaymentMethodCodebook, { headers }) as Observable<any>;
   }

   getCaseKindViewCodebookByKind(kind: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCaseKindViewCodebookByKind + '/' + kind, { headers }) as Observable<any>;
   }

   getRegionCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getRegionCodebook, { headers }) as Observable<any>;
   }

   getDpnRightCodebook(caseView: any, dateOfCreation: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.dpnRightCodebook + '/' + caseView + '/' + dateOfCreation, { headers }) as Observable<any>;
   }

   getDpnRightCodebookById(caseKindRight: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.dpnRightCodebookById + '/' + caseKindRight, { headers }) as Observable<any>;
   }

   getDecisionTypeCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getDecisionTypeCodebook, { headers }) as Observable<any>;
   }

   getSubmissionTypeCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getSubmissionTypeCodebook, { headers }) as Observable<any>;
   }

   getEnrollmentEvidentionCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getEnrollmentEvidentions, { headers }) as Observable<any>;
   }

   getEntranceDecisionCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getEntranceDecisions, { headers }) as Observable<any>;
   }

   getAssessmentTypeCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAssessmentTypes, { headers }) as Observable<any>;
   }

   getOtherProceduresAcivitiesCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getOtherProceduresAcivitiesCodebook, { headers }) as Observable<any>;
   }

   getProfessionalProceduresCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getProfessionalProceduresCodebook, { headers }) as Observable<any>;
   }
   getActiveProfessionalProceduresCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getActiveProfessionalProceduresCodebook, { headers }) as Observable<any>;
   }
   getDevelopmentCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getDevelopmentCodebook, { headers }) as Observable<any>;
   }

   getSubmissionKindCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getSubmissionKindCodebook, { headers }) as Observable<any>;
   }

   getCaseTypeCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCaseTypeCodebook, { headers }) as Observable<any>;
   }

   getOfficeCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getOfficeCodebook, { headers }) as Observable<any>;
   }

   getOfficeCodebookByCsrId(csrId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getOfficeCodebookByCsrId + '/' + csrId, { headers }) as Observable<any>;
   }

   getGenderCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getGenderCodebook, { headers }) as Observable<any>;
   }

   getPlanKindCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPlanKindCodebook, { headers }) as Observable<any>;
   }

   getHealtStatusCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getHealthStatusCodebook, { headers }) as Observable<any>;
   }

   getAgeChildCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAgeChildCodebook, { headers }) as Observable<any>;
   }

   getResidentalStatusCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getResidentalStatusCodebook, { headers }) as Observable<any>;
   }

   getFamilyStatusCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFamilyStatusCodebook, { headers }) as Observable<any>;
   }

   getSchoolStatusCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getSchoolStatusCodebook, { headers }) as Observable<any>;
   }

   getAbsenceCareWithParentsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAbsenceCareWithParentsCodebook, { headers }) as Observable<any>;
   }

   getAbsenceCareWithoutParentsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAbsenceCareWithoutParentsCodebook, { headers }) as Observable<any>;
   }

   getGeneralHealthStatusCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getGeneralHealthStatusCodebook, { headers }) as Observable<any>;
   }

   getPsychophysicalDevelopmentCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPsychophysicalDevelopmentCodebook, { headers }) as Observable<any>;
   }

   getGeneralHealthDeviationsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getGeneralHealthDeviationsCodebook, { headers }) as Observable<any>;
   }

   getPsychophysicalDevelopmentDeviationsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPsychophysicalDevelopmentDeviationsCodebook, { headers }) as Observable<any>;
   }

   getViolenceCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getViolenceCodebook, { headers }) as Observable<any>;
   }

   getViolenceCommiterCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getViolenceCommiterCodebook, { headers }) as Observable<any>;
   }

   getProtectionMeasureCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getProtectionMeasureCodebook, { headers }) as Observable<any>;
   }

   getProtectionRequesterCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getProtectionRequesterCodebook, { headers }) as Observable<any>;
   }

   getCommitedViolenceCountCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCommitedViolenceCountCodebook, { headers }) as Observable<any>;
   }

   getGuardianshipTypeCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getGuardianshipTypeCodebook, { headers }) as Observable<any>;
   }

   getPlannerReasonsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPlannerReasonsCodebook, { headers }) as Observable<any>;
   }

   getReasonsForTerminationOfFosterCareCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getReasonsForTerminationOfFosterCareCodebook, { headers }) as Observable<any>;
   }

   getTypeOfSettlementsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getTypeOfSettlementsCodebook, { headers }) as Observable<any>;
   }

   getTypesOfIncomeCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getTypesOfIncomeCodebook, { headers }) as Observable<any>;
   }

   getTypesOfPensionCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getTypesOfPensionCodebook, { headers }) as Observable<any>;
   }

   getTypesOfAccommodationFeesCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getTypesOfAccommodationFeesCodebook, { headers }) as Observable<any>;
   }

   getBranchesPioFundCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getBranchesPioFundCodebook, { headers }) as Observable<any>;
   }

   getAccommodationFacilityCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAccommodationFacilityCodebook, { headers }) as Observable<any>;
   }

   getBankCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getBankCodebook, { headers }) as Observable<any>;
   }

   getActiveBankCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getActiveBankCodebook, { headers }) as Observable<any>;
   }

   getCsrCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCsrCodebook, { headers }) as Observable<any>;
   }

   checkCsrAddress() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.checkCsrAddress, { headers }) as Observable<any>;
   }

   getAccountingRightCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAccountingRightCodebook, { headers }) as Observable<any>;
   }

   getFamilyAccommodationPriceCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFamilyAccommodationPriceCodebook, { headers }) as Observable<any>;
   }

   getPocketMoneyAmountCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPocketMoneyAmountCodebook, { headers }) as Observable<any>;
   }
   getPocketMoneyAmountCodebookValidFrom(date) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPocketMoneyAmountCodebookValidFrom+date, { headers }) as Observable<any>;
   }

   getPocketMoneyPercentageCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPocketMoneyPercentageCodebook, { headers }) as Observable<any>;
   }

   getTransportationCostCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getTransportationCostCodebook, { headers }) as Observable<any>;
   }

   getLatestDpnRightCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getLatestDpnRightCodebook, { headers }) as Observable<any>;
   }

   getAllDpnRightCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAllDpnRightCodebook, { headers }) as Observable<any>;
   }

   getAllByTypeDpnRightCodebook(type: String, pageable: Pageable) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(
         this.app.getUrl() + environment.getAllByTypeDpnRightCodebook + '/' + type + '/' + pageable.pageNumber + '/' + pageable.pageSize,
         { headers }
      ) as Observable<any>;
   }

   getNspRightCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getNspRightCodebook, { headers }) as Observable<any>;
   }

   getAllNspRightCodebook(pageable: Pageable) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAllNspRightCodebook + '/' + pageable.pageNumber + '/' + pageable.pageSize, {
         headers,
      }) as Observable<any>;
   }

   getAllSubcenters() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAllSubcenters, { headers }) as Observable<any>;
   }

   getCloseCaseCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCloseCaseCodebook, { headers }) as Observable<any>;
   }

   getNspRightCodebookForDate(date: Date) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getNspRightForDate + '/' + date.getTime(), { headers }) as Observable<any>;
   }

   getLocalCommunityForPttNumber(pttNumber: number) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getLocalCommunityForPttNumber + '/' + pttNumber, { headers }) as Observable<any>;
   }

   getTypesOfJnpCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getTypesOfJnpCodebook, { headers }) as Observable<any>;
   }

   getViolenceKindCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getViolenceKindCodebook, { headers }) as Observable<any>;
   }

   getAdoptersCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAdoptersCodebook, { headers }) as Observable<any>;
   }

   getFosterCareFormCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFosterCareFormCodebook, { headers }) as Observable<any>;
   }

   getFormsOfNeglectCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFormsOfNeglectCodebook, { headers }) as Observable<any>;
   }

   getViolenceHappenedContextCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getViolenceHappenedContextCodebook, { headers }) as Observable<any>;
   }

   getFormsOfViolenceCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFormsOfViolenceCodebook, { headers }) as Observable<any>;
   }

   getFormsOfSexualViolenceCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFormsOfSexualViolenceCodebook, { headers }) as Observable<any>;
   }

   getHousingStatusCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getHousingStatusCodebook, { headers }) as Observable<any>;
   }

   getHouseholdOrFamilyTypeCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getHouseholdOrFamilyTypeCodebook, { headers }) as Observable<any>;
   }

   getFormsOfEmotionalViolenceCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFormsOfEmotionalViolenceCodebook, { headers }) as Observable<any>;
   }

   getExploitationChildCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getExploitationChildCodebook, { headers }) as Observable<any>;
   }

   getBullyingCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getBullyingCodebook, { headers }) as Observable<any>;
   }

   getMigrantStatusCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getMigrantStatusCodebook, { headers }) as Observable<any>;
   }

   getEngagedCollaborativeNetworkCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getEngagedCollaborativeNetworkCodebook, { headers }) as Observable<any>;
   }

   getAvailabilityVictimToPerpetratorCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAvailabilityVictimToPerpetratorCodebook, { headers }) as Observable<any>;
   }

   getEconomicViolenceCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getEconomicViolenceCodebook, { headers }) as Observable<any>;
   }

   getEmergencyMeasuresCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getEmergencyMeasuresCodebook, { headers }) as Observable<any>;
   }

   getVictimCharacteristicCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getVictimCharacteristicCodebook, { headers }) as Observable<any>;
   }

   getSituationCharacteristicCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getSituationCharacteristicCodebook, { headers }) as Observable<any>;
   }

   getPerpetratorCharacteristicCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPerpetratorCharacteristicCodebook, { headers }) as Observable<any>;
   }

   getRiskCharacteristicCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getRiskCharacteristicCodebook, { headers }) as Observable<any>;
   }

   getArchivationReasonsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getArchivationReasonsCodebook, { headers }) as Observable<any>;
   }

   getAddictionCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAddictionCodebook, { headers }) as Observable<any>;
   }

   getPaymentRecipientCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPaymentRecipientCodebook, { headers }) as Observable<any>;
   }

   getFosterParentMaritalStatusCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getFosterParentMaritalStatusCodebook, { headers }) as Observable<any>;
   }

   getRightsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getRightsCodebook, { headers }) as Observable<any>;
   }

   getActiveRightsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getActiveRightsCodebook, { headers }) as Observable<any>;
   }

   findRightsById(id: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.findRightsById + '?id=' + id, { headers }) as Observable<any>;
   }

   getServicesCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getServicesCodebook, { headers }) as Observable<any>;
   }

   getActiveServicesCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getActiveServicesCodebook, { headers }) as Observable<any>;
   }

   findServicesById(id: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.findServicesById + '?id=' + id, { headers }) as Observable<any>;
   }

   getLegalProceduresCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getLegalProceduresCodebook, { headers }) as Observable<any>;
   }

   getActiveLegalProceduresCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getActiveLegalProceduresCodebook, { headers }) as Observable<any>;
   }

   findLegalProcedureById(id: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.findLegalProceduresById + '?id=' + id, { headers }) as Observable<any>;
   }

   findProfessionalProcedureById(id: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.findProfessionalProceduresById + '?id=' + id, { headers }) as Observable<any>;
   }

   getLevelOfEducationCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getLevelOfEducationCodebook, { headers }) as Observable<any>;
   }

   getDecisionMakerCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getDecisionMakerCodebook, { headers }) as Observable<any>;
   }

   getComplainResponseCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getComplainResponseCodebook, { headers }) as Observable<any>;
   }

   getAccommodationCategoryCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAccommodationCategoryCodebook, { headers }) as Observable<any>;
   }

   getStreetCodebookByStreetName(streetName: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getStreetCodebookByPartName + streetName, { headers }) as Observable<any>;
   }

   getCsrCodebookById() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCsrCodebookById, { headers }) as Observable<any>;
   }

   getClassificationForJnpStats() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.findClassificationCodebookForJnpStats, { headers }) as Observable<any>;
   }

   getAccommodationSupportCategoryCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAccommodationSupportCategoryCodebook, { headers }) as Observable<any>;
   }

   getAccommodationCategoryCodebookNotSupport() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAccommodationCategoryCodebookNotSupport, { headers }) as Observable<any>;
   }

   getAccommodationCategoryCodebookSupport() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAccommodationCategoryCodebookSupport, { headers }) as Observable<any>;
   }

   getTerminationReasonByType(type: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getTerminationReasonsCodebookByType + type, { headers }) as Observable<any>;
   }
   
   getRelationshipViolenceCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getRelationshipViolenceCodebook, { headers }) as Observable<any>;
   }

   getAccommodationReasonsCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAccommodationReasonsCodebook, { headers }) as Observable<any>;
   }

   getProtegeAccommodationCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getProtegeAccommodationCodebook, { headers }) as Observable<any>;
   }

   getDeprivationExtentCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getDeprivationExtentCodebook, { headers }) as Observable<any>;
   }
   getSchoolAttendingCodebook() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getSchoolAttendingCodebook, { headers }) as Observable<any>;
   }

}
