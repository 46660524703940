/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModalEventEnum } from 'src/types';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { CodebookService } from 'src/services/codebook.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'add-foster-child',
  templateUrl: './add-foster-child.component.html',
  styleUrls: ['./add-foster-child.component.scss'],
})
export class AddFosterChildComponent implements OnInit {
  newRecord: FormGroup;
  currentDate = new Date();
  reasonsForCustodyTerminations = [];
  fosterCareFormOptions = [];
  regexStrDate = '^[0-9.]+$';

  constructor(
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    private codebookService: CodebookService
  ) {
    this.getFosterCareForm();
    this.getReasonsForTerminationOfFosterCare();

  }

  ngOnInit(): void {
    this.newRecord = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      placeOfBirth: ['', [Validators.required]],
      kinship: ['', [Validators.required]],
      formOfFosterCare: ['', [Validators.required]],
      dateOfPlacementInAFosterFamily: ['', [Validators.required]],
      childSupportBearers: ['', [Validators.required]],
      dateOfCustodyTermination: [''],
      reasonsForCustodyTermination: [''],
      competentCenter: ['', [Validators.required]],
    });
  }

  addNewRecord(trackingDocumentForm: FormGroup) {
    trackingDocumentForm.get('dateOfPlacementInAFosterFamily').setValue(
      this.datePipe.transform(new Date(trackingDocumentForm.value.dateOfPlacementInAFosterFamily).getTime(), 'yyyy-MM-dd'));
    trackingDocumentForm.get('dateOfCustodyTermination').setValue(
      this.datePipe.transform(new Date(trackingDocumentForm.value.dateOfCustodyTermination).getTime(), 'yyyy-MM-dd'));
    trackingDocumentForm.value.reasonsForCustodyTermination =
      trackingDocumentForm.value.reasonsForCustodyTermination !== '' ? trackingDocumentForm.value.reasonsForCustodyTermination : null;
    this.dialogRef.close({
      event: ModalEventEnum.SUCCESS,
      data: trackingDocumentForm.value,
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: ModalEventEnum.CANCEL });
  }

  importSubjectData() {
    const dialogRef = this.dialog.open(SubjectsComponent, {
      // width: ModalSizeEnum.DEFAULT,
      width: '1200px',
      panelClass:'overlay-panel',
      data: {
        origin: 'entrance',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.newRecord.patchValue({
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          gender: result.data.gender,
          dateOfBirth: new Date(result.data.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')),
          placeOfBirth: result.data.placeOfBirth
        });
      }

    });
  }

  getFosterCareForm() {
    this.codebookService.getFosterCareFormCodebook().subscribe(
      result => {
        this.fosterCareFormOptions = result;
      });
  }

  getReasonsForTerminationOfFosterCare() {
    this.codebookService.getReasonsForTerminationOfFosterCareCodebook().subscribe(
      result => {
        this.reasonsForCustodyTerminations = result;
      });
  }

  checkInputDateFormat(fieldName: string, event: any): void {
    const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

    const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

    const control = this.newRecord.get(fieldName);
    const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
    const inputDate = new Date(year, month - 1, day);

    if (inputDate > this.currentDate) {
       control.setErrors({ maxDateExceeded: true });
    } else {
       control.setErrors(null);
    }
 }

 onBlur(controlName: string) {
  const control = this.newRecord.get(controlName);
  if (!control) return;

  const inputElement = document.querySelector(`[id="${controlName}"]`) as HTMLInputElement;
  const inputValue = inputElement?.value || '';
  const dateValue = control.value;

  if (controlName !== 'dateOfCustodyTermination' && !inputValue) {
    control.setErrors({ required: true });
    return;
  }

  if (!dateValue && inputValue) {
    control.setErrors({ invalidFormat: true });
    return;
  }

  if (dateValue && dateValue > this.currentDate) {
    control.setErrors({ maxDateExceeded: true });
  } else {
    control.setErrors(null);
  }
}

 @HostListener('keypress', ['$event'])
 onKeyPress(event: any) {
    const fieldName = event.target.name;
    if (fieldName === 'dateFormat') {
       return new RegExp(this.regexStrDate).test(event.key);
    }
 }
}
