<container>
  <page-intro-header title="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.TITLE' | translate }}" back="/domestic-violence-victim">
  </page-intro-header>
  <mat-card class="card new-domestic-violence-victim">
    <form [formGroup]="newRecord" class="form">


      <form-group title="{{
          'NEW_DOMESTIC_VIOLENCE_VICTIM.GENERAL_INFORMATION' | translate
        }}">

        <div class="button-add-holder">
          <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
            <mat-icon>person_add</mat-icon>
            {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
          </button>
        </div>
        <br>
        <field-holder kind="4-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.FIRST_NAME' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="firstName" required autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('firstName').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.LAST_NAME' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="lastName" required autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('lastName').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.MAIDEN_NAME' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="maidenName" autocomplete="off" maxlength="255" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.NICKNAME' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="nickname" autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('nickname').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <form-subgroup-divider></form-subgroup-divider>
        <field-holder kind="2-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.PARENT_FULL_NAME' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="parentFullName" autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('parentFullName').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.MOTHER_MAIDEN_NAME' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="motherMaidenName" autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('motherMaidenName').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <form-subgroup-divider></form-subgroup-divider>
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.GENDER' | translate
              }}</mat-label>
            <mat-select formControlName="gender" [compareWith]="compareObj"
              (selectionChange)="updateJMBGOnGenderChange($event)">
              <mat-option *ngFor="let gender of genderOptions" [value]="gender">
                {{ gender.gender }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="newRecord.get('gender').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf=" newRecord.get('gender').hasError('incorrect')">
              {{ 'NEW_SUBJECT.PERSONAL_DETAILS.GENDER_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.JMBG' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="jmbg" autocomplete="off" maxlength="13"
              (input)="updateJMBGOnDateOfBirthChange($event);updateJMBGOnGenderChange($event)" name="jmbg" />
            <mat-error *ngIf="newRecord.get('jmbg').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('jmbg').hasError('minlength') ||
                  newRecord.get('jmbg').hasError('pattern')">
              {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DATE_OF_BIRTH' | translate
              }}</mat-label>
            <input matInput [matDatepicker]="dateOfBirth" formControlName="dateOfBirth"
              (dateChange)="updateJMBGOnDateOfBirthChange($event)" autocomplete="off"
              (input)="checkInputDateFormat('dateOfBirth', $event)" (blur)="onBlur('dateOfBirth')"
              placeholder="{{ 'DATE_FORMAT' | translate }}" [max]="currentDate" name="dateFormat" maxlength="255" />
            <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
            <mat-datepicker #dateOfBirth></mat-datepicker>
            <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('incorrect')">
              {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('maxDateExceeded')">
              {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('invalidFormat')">
              {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder kind="4-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.PLACE_OF_BIRTH' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="placeOfBirth" autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('placeOfBirth').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.COUNTRY_OF_BIRTH' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="countryOfBirth" autocomplete="off" maxlength="255" />
            <mat-hint>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.COUNTRY_OF_BIRTH_HINT'
              | translate
              }}</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.CITIZENSHIP' | translate
              }}</mat-label>
            <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenship"
              (focusout)="checkCitizenship()" />
            <mat-autocomplete #autoCitizenship="matAutocomplete" [displayWith]="displayCustomFormat">
              <mat-option *ngFor="let option of filteredCitizenshipOptions | async" [value]="option">
                {{ option.title }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="newRecord.get('citizenship').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('citizenship').hasError('notValid')">
              {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.NATIONALITY' | translate
              }}</mat-label>
            <input type="text" matInput formControlName="nationality" [matAutocomplete]="autoNationality"
              (focusout)="checkNationality()" />
            <mat-autocomplete #autoNationality="matAutocomplete" [displayWith]="displayCustomFormat">
              <mat-option *ngFor="let option of filteredNationalityOptions | async" [value]="option">
                {{ option.title }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="newRecord.get('nationality').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('nationality').hasError('notValid')">
              {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder kind="2-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.OCCUPATION' | translate
              }}</mat-label>
            <input type="text" matInput formControlName="occupation" [matAutocomplete]="autoOccupation"
              (focusout)="checkOccupation()" />
            <mat-autocomplete #autoOccupation="matAutocomplete" [displayWith]="displayCustomFormat">
              <mat-option *ngFor="let option of filteredOccupationOptions | async" [value]="option">
                {{ option.title }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="newRecord.get('occupation').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('occupation').hasError('notValid')">
              {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.RESIDENCE_ADDRESS' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="residenceAddress" autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('residenceAddress').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
      </form-group>
      <form-group isLast="true" title="{{
          'NEW_DOMESTIC_VIOLENCE_VICTIM.VIOLENCE_INFORMATION' | translate
        }}">
        <field-holder kind="2-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.COMMITED_VIOLENCE_COUNT'
              | translate
              }}</mat-label>
            <mat-select formControlName="commitedViolenceCount" required>
              <mat-option *ngFor="let option of commitedViolenceCountOptions" [value]="option">
                {{ option.title }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                newRecord.get('commitedViolenceCount').hasError('required')
              ">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="shouldShowCommitedViolenceMeta">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.COMMITED_VIOLENCE_COUNT_META'
              | translate
              }}</mat-label>
            <input matInput type="text" formControlName="commitedViolenceMeta" maxlength="40" required
              autocomplete="off" />
            <mat-error *ngIf="newRecord.get('commitedViolenceMeta').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder kind="2-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.PROTECTION_FROM_VIOLENCE_REQUESTER'
              | translate
              }}</mat-label>
            <mat-select formControlName="protectionFromViolenceRequester" required>
              <mat-option *ngFor="let option of protectionFromViolenceRequesterOptions" [value]="option">
                {{ option.title }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                newRecord
                  .get('protectionFromViolenceRequester')
                  .hasError('required')
              ">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="shouldShowProtectionFromViolenceRequesterMeta">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.PROTECTION_FROM_VIOLENCE_REQUESTER_META'
              | translate
              }}</mat-label>
            <input matInput type="text" maxlength="150" formControlName="protectionFromViolenceRequesterMeta" required
              autocomplete="off" />
            <mat-error *ngIf="
                newRecord
                  .get('protectionFromViolenceRequesterMeta')
                  .hasError('required')
              ">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder kind="4-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.COURT_NAME' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="courtName" required autocomplete="off" maxlength="255" />
            <mat-error *ngIf="newRecord.get('courtName').hasError('required')">
              {{
              'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.INPUT_ERROR' | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.VERDICT_NUMBER' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="verdictNumber" maxlength="40" required autocomplete="off" />
            <mat-error *ngIf="newRecord.get('verdictNumber').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DATE_OF_VERDICT' | translate
              }}</mat-label>
            <input matInput [matDatepicker]="dateOfVerdict" [max]="currentDate" formControlName="dateOfVerdict" required
              autocomplete="off" name="dateFormat" (input)="checkInputDateFormat('dateOfVerdict', $event)"
              (blur)="onBlur('dateOfVerdict')" maxlength="255" />
            <mat-datepicker-toggle matSuffix [for]="dateOfVerdict"></mat-datepicker-toggle>
            <mat-datepicker #dateOfVerdict></mat-datepicker>
            <mat-error *ngIf="newRecord.get('dateOfVerdict').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('dateOfVerdict').hasError('maxDateExceeded')">
              {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('dateOfVerdict').hasError('invalidFormat')">
              {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DATE_OF_VERDICT_ENTRY_INTO_FORCE'
              | translate
              }}</mat-label>
            <input matInput [matDatepicker]="dateOfVerdictEntryIntoForce" [max]="currentDate"
              (input)="checkInputDateFormat('dateOfVerdictEntryIntoForce', $event)" (blur)="onBlur('dateOfVerdictEntryIntoForce')"
              formControlName="dateOfVerdictEntryIntoForce" required autocomplete="off" name="dateFormat" maxlength="255" />
            <mat-datepicker-toggle matSuffix [for]="dateOfVerdictEntryIntoForce"></mat-datepicker-toggle>
            <mat-datepicker #dateOfVerdictEntryIntoForce></mat-datepicker>
            <mat-error *ngIf="
                newRecord
                  .get('dateOfVerdictEntryIntoForce')
                  .hasError('required')
              ">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('dateOfVerdictEntryIntoForce').hasError('maxDateExceeded')">
              {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('dateOfVerdictEntryIntoForce').hasError('invalidFormat')">
              {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.COMMITTED_VIOLENCE_TYPE'
              | translate
              }}</mat-label>
            <mat-select formControlName="commitedVioleceType" multiple required>
              <mat-option *ngFor="let option of commitedVioleceTypeOptions" [value]="option">
                {{ option.title }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="newRecord.get('commitedVioleceType').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder kind="2-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.VIOLENCE_FAMILY_MEMBER_COMMITER'
              | translate
              }}</mat-label>
            <mat-select formControlName="violenceFamilyMemberCommiter" required>
              <mat-option *ngFor="let option of violenceFamilyMemberCommiterOptions" [value]="option">
                {{ option.title }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                newRecord
                  .get('violenceFamilyMemberCommiter')
                  .hasError('required')
              ">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="shouldShowViolenceFamilyMemberCommiterMeta">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.VIOLENCE_FAMILY_MEMBER_COMMITER_META'
              | translate
              }}</mat-label>
            <input matInput type="text" formControlName="violenceFamilyMemberCommiterMeta" autocomplete="off" maxlength="255" />
            <mat-error *ngIf="
                newRecord
                  .get('violenceFamilyMemberCommiterMeta')
                  .hasError('required')
              ">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DEFINED_PROTECTION_MEASURE'
              | translate
              }}</mat-label>
            <mat-select formControlName="definedProtectionMeasure" multiple required>
              <mat-option *ngFor="let option of definedProtectionMeasureOptions" [value]="option">
                {{ option.title }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                newRecord
                  .get('definedProtectionMeasure')
                  .hasError('required')
              ">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>


        <field-holder kind="2-cols">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DURATION_OF_PROTECTION_MEASURE_FROM' | translate }}
            </mat-label>
            <input matInput type="text" [matDatepicker]="from" formControlName="from" autocomplete="off" required
              (input)="checkInputDateFormat('from', $event)" (blur)="onBlur('from')" maxlength="255"
              placeholder="{{ 'STATISTICS.DOCUMENT_STATS.FROM' | translate }}" [max]="maxDate" name="dateFormat" />
            <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
            <mat-datepicker #from></mat-datepicker>
            <mat-error *ngIf="newRecord.get('from').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('from').hasError('maxDateExceeded')">
              {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('from').hasError('invalidFormat')">
              {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DURATION_OF_PROTECTION_MEASURE_TO' | translate }}
            </mat-label>
            <input matInput type="text" [matDatepicker]="to" formControlName="to" autocomplete="off" required
              (input)="checkInputDateFormat('to', $event)" (blur)="onBlur('to')" maxlength="255"
              placeholder="{{ 'STATISTICS.DOCUMENT_STATS.TO' | translate }}" [max]="maxDate" name="dateFormat" />
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to></mat-datepicker>
            <mat-error *ngIf="newRecord.get('to').hasError('required')">
              {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.INPUT_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('to').hasError('maxDateExceeded')">
              {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
            </mat-error>
            <mat-error *ngIf="newRecord.get('to').hasError('invalidFormat')">
              {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
      </form-group>
      <form-footer>
        <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
          (click)="createRecord(newRecord)" [disabled]="!newRecord.valid">
          {{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.CREATE' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-card>
</container>