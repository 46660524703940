import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { FamilyMembersComponent } from 'src/app/entrance/family-members/family-members.component';
import { EventEnum } from 'src/app/entrance/types';
import { LocalStorageService } from 'src/app/local-storage.service';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { Institution } from 'src/models/institution';
import { Subject } from 'src/models/subject.model';
import { CodebookService } from 'src/services/codebook.service';
import { TeamRequestService } from 'src/services/team-request.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { VsService } from 'src/services/vs.service';
import { ModalSizeEnum } from 'src/types';

enum StepEnum {
    FORM2_STEP06 = 'FORM2_STEP06',
    FORM3_STEP07 = 'FORM3_STEP07',
    FORM23_STEP08 = 'FORM23_STEP08'
}

@Component({
    selector: 'app-forms2-3',
    templateUrl: './forms2_3.component.html',
    styleUrls: ['./forms2_3.component.scss']
})
export class Forms2_3Component implements OnInit, OnChanges {

    isLoading = false;
    firstStep: FormGroup;
    secondStep: FormGroup;
    thirdStep: FormGroup;
    fourthStep: FormGroup;
    fifthStep: FormGroup;
    sixthStep: FormGroup;
    seventhStep: FormGroup;
    eighthStep: FormGroup;
    teamRequestOnWait = false;
    isEditMode = false;
    isFormDigitallySigned = false;
    isTargetedEvaluationNeeded = true;
    checkTargetedEvaluation = false;
    isOtherProblem = false;
    hasSupervisor = false;
    maxDate: Date = new Date(2999, 11, 31);

    @Input() isCaseClosed: boolean;
    @Input() formNumber: any;
    @Input() caseId: any;
    @Input() subject: any;
    @Input() approved: boolean;
    @Output() handleSubmitData = new EventEmitter();
    @Output() handleSaveChanges = new EventEmitter();
    @Output() handleFormNumberChange = new EventEmitter<number>();
    @ViewChild(MatTable, { static: true }) table: MatTable<any>;
    @ViewChild('submitButton1') submitButton1;
    @ViewChild('submitButton2') submitButton2;
    @Input() hasTeamRequest: boolean;
    @Output() handleNewTeamRequest = new EventEmitter<any>();
    @Input() familyMembers: any = [];
    displayedColumns: string[] = [
        'name',
        'relationship',
        'yearOfBirth',
        'qualificationEmployment',
    ];

    assessmentTypes = [];
    center: any;
    showDate: any;
    currentDate = new Date();
    isMemberSelected = false;
    selectedMember = null;
    members = [];

    relationshipOptions = [];

    /**
     * Institucije za formu 2 imaju jedan element vi�e (prvi) u odnosu na formu 3.
     * Uvek se snimaju sve institucije, samo se za formu3 sakriva prvi element.
     */
    defaultInstitutions = [
        new Institution('школ./предшкол.', null, null, null, null, null, 1),
        new Institution('здравствене', null, null, null, null, null, 2),
        new Institution('соц. заштита', null, null, null, null, null, 3),
        new Institution('суд', null, null, null, null, null, 4),
        new Institution('полиција', null, null, null, null, null, 5),
        new Institution('НВО', null, null, null, null, null, 6),
        new Institution('друго', null, null, null, null, null, 7)
    ];


    form2_step06 = [];
    selectedform2_step06 = [];
    form2_step08 = [];
    form3_step08 = [];
    selectedform23_step08 = [];
    form3_step07 = [];
    selectedform3_step07 = [];


    dataSource: MatTableDataSource<any>;

    displayedColumnsInstitutions: string[] = [
        'kind',
        'name',
        'address',
        'phone',
        'contactPerson',
        'participation',
    ];
    isInstitutionSelected = false;
    legalMeasuresProceduresData = [];
    selectedLegalMeasuresProcedures: any;

    servicesData = [];
    selectedServices: any;
    rightsData = [];
    selectedRights: any;
    professionalProceduresData = [];
    selectedProfessionalProcedures: any;
    developmentOptions = [];
    problemsDuration = [];
    problemsIntensity = [];

    forms23: any = {};

    strengthTooltipChild = '';
    strengthTooltipAdult = '';

    constructor(
        private formBuilder: FormBuilder,
        private localStorageService: LocalStorageService,
        private datePipe: DatePipe,
        public dialog: MatDialog,
        private codebook: CodebookService,
        private vsService: VsService,
        private teamRequestService: TeamRequestService,
        private translate: TranslateService,
        private toastr: ToastrImplService,
        private userService: UserService) {
        this.center = (JSON.parse(this.localStorageService.get('loggedUser'))).center;
        this.showDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
        this.codebook.getAssessmentTypeCodebook().subscribe(
            result => {
                this.assessmentTypes = result;
            }
        );
        this.codebook.getRelationshipCodebookWithoutUser().subscribe(
            result => {
                this.relationshipOptions = result;
            }
        );
        this.codebook.getActiveLegalProceduresCodebook().subscribe(
            result => {
                this.legalMeasuresProceduresData = result;
            }
        );

        this.codebook.getActiveServicesCodebook().subscribe(
            result => {
                this.servicesData = result;
            }
        );

        this.codebook.getActiveRightsCodebook().subscribe(
            result => {
                this.rightsData = result;
            }
        );

        this.codebook.getActiveProfessionalProceduresCodebook().subscribe(
            result => {
                this.professionalProceduresData = result;
            }
        );

        this.codebook.getDevelopmentCodebook().subscribe(
            result => {
                this.developmentOptions = result;
            }
        );

        this.codebook.getProblemsForComponentCodebook('FORM2_STEP06').subscribe(
            result => {
                this.form2_step06 = result;
            }
        );

        this.codebook.getProblemsForComponentCodebook('FORM2_STEP08').subscribe(
            result => {
                this.form2_step08 = result;
            }
        );

        this.codebook.getProblemsForComponentCodebook('FORM3_STEP07').subscribe(
            result => {
                this.form3_step07 = result;
            }
        );

        this.codebook.getProblemsForComponentCodebook('FORM3_STEP08').subscribe(
            result => {
                this.form3_step08 = result;
            }
        );

        this.codebook.getProblemsDurationCodebook().subscribe(
            result => {
                this.problemsDuration = result;
            }
        );

        this.codebook.getProblemsIntensityCodebook().subscribe(
            result => {
                this.problemsIntensity = result;
            }
        );

        this.translate.get('VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.STRENGHTS_TOOLTIP_CHILD').subscribe((res: string) => {
            this.strengthTooltipChild = res;
        });

        this.translate.get('VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.STRENGHTS_TOOLTIP_ADULT').subscribe((res: string) => {
            this.strengthTooltipAdult = res;
        });
        // this.formNumber = this.formNumber === null || this.formNumber === undefined ? 3 : this.formNumber;

        this.userService.hasSupervisor(JSON.parse(this.localStorageService.get('loggedUser')).id).subscribe(
            result => {
                if (result) {
                    this.hasSupervisor = true;
                } else {
                    this.hasSupervisor = false;
                }
            }
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.caseId && changes.caseId.currentValue) {
            this.vsService.getForms23(this.caseId).subscribe(result => this.loadFormData(result));
            this.members = this.familyMembers;
        }
        if (changes.formNumber && changes.formNumber.currentValue) {
            this.formNumber = changes.formNumber.currentValue;
            if (this.thirdStep) {
                this.thirdStep.setControl('institutions', this.formBuilder.array(
                    // this.forms23.institutions.slice(this.formNumber === 2 ? 0 : 1).map((r: any) => this.formBuilder.group(r)))
                    this.defaultInstitutions.slice(this.formNumber === 2 ? 0 : 1).map((r: any) => this.formBuilder.group(r)))
                );
                this.patchInstitutions();
                this.dataSource = new MatTableDataSource((this.thirdStep.get('institutions') as FormArray).controls);
            }
        }
    }

    compareAssessmentTypeObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id && object1.name === object2.name;
    }

    compareRelationshipObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
    }

    compareDurationObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id && object1.duration === object2.duration;
    }

    compareIntensityObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id && object1.intensity === object2.intensity;
    }

    isOtherProblemChecked(event: any) {
        if (event.value.some((problem: any) => problem.id === 53)) {
            this.isOtherProblem = true;
            this.eighthStep.get('otherProblem').setValidators([Validators.required]);
            this.eighthStep.get('otherProblem').markAsTouched();
        }
        if(event.value.length === 0){
            this.eighthStep.get('otherProblem').setErrors(null);
            this.isOtherProblem = false;
        }
    }

    patchValues() {
        if (this.formNumber !== this.forms23.formNumber) {
            this.formNumber = this.forms23.formNumber;
            this.handleFormNumberChange.emit(this.formNumber);
        }

        if (this.forms23.otherProblem !== null && this.forms23.otherProblem !== undefined && this.forms23.otherProblem !== '') {
            this.isOtherProblem = true;
        }
        const date = this.approved ? this.showDate : this.forms23.dateOfSubmission;
        this.firstStep.patchValue({
            dateOfSubmission: date, 
            assessmentType: this.forms23.assessmentType,
            center: this.center,
            notesEducation: this.forms23.notesEducation,
            notesOcupationEmployment: this.forms23.notesOcupationEmployment,
            notesMaritalStatus: this.forms23.notesMaritalStatus,
            schoolPreschool: this.forms23.schoolPreschool,
            class: this.forms23.schoolClass,
            notesSchoolPreschool: this.forms23.notesSchoolPreschool,
            contactPerson1Name: this.forms23.contactPerson1Name,
            contactPerson1Kinship: this.forms23.contactPerson1Kinship,
            contactPerson1Address: this.forms23.contactPerson1Address,
            contactPerson1Phone: this.forms23.contactPerson1Phone,
            contactPerson1Note: this.forms23.contactPerson1Note,
            contactPerson2Name: this.forms23.contactPerson2Name,
            contactPerson2Kinship: this.forms23.contactPerson2Kinship,
            contactPerson2Address: this.forms23.contactPerson2Address,
            contactPerson2Phone: this.forms23.contactPerson2Phone,
            contactPerson2Note: this.forms23.contactPerson2Note,
        });

        this.secondStep.patchValue({
            otherData: this.forms23.otherData
        });
        this.members = this.forms23.familyMembers;

        this.thirdStep.patchValue({
            note: this.forms23.note
        });
        this.thirdStep.setControl('institutions', this.formBuilder.array(
            // this.forms23.institutions.slice(this.formNumber === 2 ? 0 : 1).map((r: any) => this.formBuilder.group(r)))
            this.defaultInstitutions.slice(this.formNumber === 2 ? 0 : 1).map((r: any) => this.formBuilder.group(r)))
        );
        this.patchInstitutions();
        this.dataSource = new MatTableDataSource((this.thirdStep.get('institutions') as FormArray).controls);

        this.fourthStep.patchValue({
            evaluationReason: this.forms23.evaluationReason
        });

        this.fifthStep.patchValue({
            childNeeds: this.forms23.childNeeds,
            childNeedsEvaluation: this.forms23.childNeedsEvaluation,
            stateNeeds: this.forms23.stateNeeds,
            stateNeedsEvaluation: this.forms23.stateNeedsEvaluation
        });

        this.sixthStep.patchValue({
            parentAbility: this.forms23.parentAbility,
            influenceFactors: this.forms23.influencesList,
            assessment: this.forms23.assessment,
            influences: this.forms23.influences,
            developments: this.forms23.developments,
            disability: this.forms23.disability,
            talent: this.forms23.talent,
            importantEvents: this.forms23.importantEvents,
            userProblems: this.forms23.importantEvents,
            parentProblems: this.forms23.parentProblems,
            developmentAssessment: this.forms23.developmentAssessment
        });

        this.seventhStep.patchValue({
            familyInfluence: this.forms23.familyInfluence,
            familyInfluenceAssessment: this.forms23.familyInfluenceAssessment,
            householdProblems: this.forms23.householdProblemsList,
            householdProblemsArray: this.forms23.householdProblems,
            socialAssistance: this.forms23.socialAssistance
        });

        this.eighthStep.patchValue({
            problems: this.forms23.problemsList,
            problemsArray: this.forms23.problems,
            otherProblem: this.forms23.otherProblem,
            strenghts: this.forms23.strenghts,
            summaryEvaluation: this.forms23.summaryEvaluation,
            targetedAssessment: this.forms23.targetedAssessment,
            servicesProceduresInterventions: this.forms23.servicesProceduresInterventions,
            legalProcedures: this.forms23.legalProcedures,
            services: this.forms23.services,
            rights: this.forms23.rights,
            professionalProcedures: this.forms23.professionalProcedures
        });
    }

    loadFormData(data: any) {
        if(data !== null && data.dateOfSubmission && !this.approved){
            this.firstStep.get("dateOfSubmission").setValue(data.dateOfSubmission);
        }
        if (data !== null && data.institutions !== null) {
            this.forms23 = data;
            if (data.institutions.length === 0) {
                this.forms23.institutions = this.defaultInstitutions;
            } else {
                for (const instution of data.institutions) {
                    instution.id = null;
                }
                this.forms23.institutions = data.institutions;
            }
            this.patchValues();
        } else {
            this.forms23.institutions = this.defaultInstitutions;
            this.thirdStep = this.formBuilder.group({
                note: ['', []],
                institutions: this.formBuilder.array(
                    // this.forms23.institutions.slice(this.formNumber === 2 ? 0 : 1).map((r: any) => this.formBuilder.group(r))
                    this.defaultInstitutions.slice(this.formNumber === 2 ? 0 : 1).map((r: any) => this.formBuilder.group(r))
                )
            });
            this.dataSource = new MatTableDataSource((this.thirdStep.get('institutions') as FormArray).controls);
        }
    }

    createForms() {
        this.vsService.getForms23(this.caseId).subscribe(result => this.loadFormData(result));

        this.firstStep = this.formBuilder.group({
            center: [
                this.center,
                [Validators.required],
            ],
            dateOfSubmission: [this.showDate, [Validators.required]],
            assessmentType: ['', [Validators.required]],
            schoolPreschool: [],
            class: [],
            notesSchoolPreschool: [],
            contactPerson1Name: [],
            contactPerson1Kinship: [],
            contactPerson1Address: [],
            contactPerson1Phone: [],
            contactPerson1Note: [],
            contactPerson2Name: [],
            contactPerson2Kinship: [],
            contactPerson2Address: [],
            contactPerson2Phone: [],
            contactPerson2Note: [],
            notesEducation: [],
            notesOcupationEmployment: [],
            notesMaritalStatus: []
        });

        this.secondStep = this.formBuilder.group({
            otherData: ['', []],
        });

        this.thirdStep = this.formBuilder.group({
            note: ['', []],
            institutions: this.formBuilder.array(this.defaultInstitutions.map(r => this.formBuilder.group(r)))
        });

        this.fourthStep = this.formBuilder.group({
            evaluationReason: ['', []],
        });
        this.fifthStep = this.formBuilder.group({
            childNeeds: ['', []],
            childNeedsEvaluation: ['', []],
            stateNeeds: [],
            stateNeedsEvaluation: []
        });
        this.sixthStep = this.formBuilder.group({
            parentAbility: ['', []],
            influenceFactors: [],
            assessment: [],
            influences: new FormArray([]),
            developments: [],
            disability: [],
            talent: [],
            importantEvents: [],
            userProblems: [],
            parentProblems: [],
            developmentAssessment: []
        });

        this.setInfluenceFactorsConditionalFields();

        this.seventhStep = this.formBuilder.group({
            familyInfluence: [],
            familyInfluenceAssessment: [],
            householdProblems: [],
            householdProblemsArray: new FormArray([]),
            socialAssistance: []
        });

        this.setHouseholdProblemsConditionalFields();

        this.eighthStep = this.formBuilder.group({
            problems: [],
            problemsArray: new FormArray([]),
            otherProblem: [],
            strenghts: [],
            summaryEvaluation: [],
            targetedAssessment: [],
            servicesProceduresInterventions: [],
            legalProcedures: [],
            services: [],
            rights: [],
            professionalProcedures: []
        });

        this.setProblemsConditionalFields();
    }

    ngOnInit(): void {
        this.createForms();
    }

    preview(){        
        const submittedData = this.createObject(false);
        this.vsService.previewForm23(submittedData, this.caseId);
    }

    setHouseholdProblemsConditionalFields() {
        this.seventhStep
            .get('householdProblems')
            .valueChanges.subscribe((reasons) => {
                // 1. Establish which options are selected
                // 2. Compare the values with previously selected options and distinguish removed and new items
                let removedReasonIds: any;
                if (reasons.length === 0) {
                    removedReasonIds = this.selectedform3_step07;
                } else {
                    removedReasonIds = this.selectedform3_step07.filter(
                        (item) => !reasons.includes(item));
                }
                const newReasonIds = reasons.filter((id: any) => !this.selectedform3_step07.includes(id));

                // If there are removed options from the current state:
                // 1. Reset controls count in internal state
                // 2. Update selected observations in internal state
                // 3. Reset form control
                if (removedReasonIds.length > 0) {
                    removedReasonIds.forEach((id: any) => {
                        this.selectedform3_step07 = this.selectedform3_step07.filter(reason => reason !== id);

                        this.removeFormControl(StepEnum.FORM3_STEP07, id);
                    });
                }

                // If there are new selected options:
                // 1. Push selected observations to internal state
                // 2. Add new form control for respective observation
                if (newReasonIds.length > 0) {
                    newReasonIds.forEach((id: any) => {
                        this.selectedform3_step07 = [...new Set([...this.selectedform3_step07, id])]; // push unique array (removed duplicates)

                        this.addFormControl(StepEnum.FORM3_STEP07);
                    });
                }
            });
    }

    setInfluenceFactorsConditionalFields() {
        this.sixthStep
            .get('influenceFactors')
            .valueChanges.subscribe((reasons) => {
                // 1. Establish which options are selected
                // 2. Compare the values with previously selected options and distinguish removed and new items
                let removedReasonIds: any;
                if (reasons.length === 0) {
                    removedReasonIds = this.selectedform2_step06;
                } else {
                    removedReasonIds = this.selectedform2_step06.filter(
                        (item) => !reasons.includes(item));
                }
                const newReasonIds = reasons.filter((id: any) => !this.selectedform2_step06.includes(id));

                // If there are removed options from the current state:
                // 1. Reset controls count in internal state
                // 2. Update selected observations in internal state
                // 3. Reset form control
                if (removedReasonIds.length > 0) {
                    removedReasonIds.forEach((id: any) => {
                        this.selectedform2_step06 = this.selectedform2_step06.filter(reason => reason !== id);

                        this.removeFormControl(StepEnum.FORM2_STEP06, id);
                    });
                }

                // If there are new selected options:
                // 1. Push selected observations to internal state
                // 2. Add new form control for respective observation
                if (newReasonIds.length > 0) {
                    newReasonIds.forEach((id: any) => {
                        this.selectedform2_step06 = [...new Set([...this.selectedform2_step06, id])]; // push unique array (removed duplicates)

                        this.addFormControl(StepEnum.FORM2_STEP06);
                    });
                }
            });
    }

    setProblemsConditionalFields() {
        this.eighthStep
            .get('problems')
            .valueChanges.subscribe((reasons) => {
                // 1. Establish which options are selected
                // 2. Compare the values with previously selected options and distinguish removed and new items
                let removedReasonIds: any;
                if (reasons.length === 0) {
                    removedReasonIds = this.selectedform23_step08;
                } else {
                    removedReasonIds = this.selectedform23_step08.filter(
                        (item) => !reasons.includes(item));
                }
                const newReasonIds = reasons.filter((id: any) => !this.selectedform23_step08.includes(id));

                // If there are removed options from the current state:
                // 1. Reset controls count in internal state
                // 2. Update selected observations in internal state
                // 3. Reset form control
                if (removedReasonIds.length > 0) {
                    removedReasonIds.forEach((id: any) => {
                        this.selectedform23_step08 = this.selectedform23_step08.filter(reason => reason !== id);

                        this.removeFormControl(StepEnum.FORM23_STEP08, id);
                    });
                }

                // If there are new selected options:
                // 1. Push selected observations to internal state
                // 2. Add new form control for respective observation
                if (newReasonIds.length > 0) {
                    newReasonIds.forEach((id: any) => {
                        this.selectedform23_step08 = [...new Set([...this.selectedform23_step08, id])]; // push unique array (removed duplicates)

                        this.addFormControl(StepEnum.FORM23_STEP08);
                    });
                }
            });

    }
    /**
     * Remove form control from the form builder
     */
    removeFormControl(kind: any, element: any) {
        // Reduce control count per observation kind

        switch (kind) {
            case StepEnum.FORM2_STEP06:
                this.influenceFactorsGetter.removeAt(this.influenceFactorsGetter.value.
                    findIndex((item: any) => item.factor === element.title));
                break;
            case StepEnum.FORM3_STEP07:
                this.householdProblemsGetter.removeAt(this.householdProblemsGetter.value.
                    findIndex((item: any) => item.houseHoldProblem === element.title));
                break;
            case StepEnum.FORM23_STEP08:
                this.problemsGetter.removeAt(this.problemsGetter.value.findIndex((item: any) => item.difficulty === element.title));
                break;
            default:
                break;
        }
    }

    addFormControl(kind: any) {
        switch (kind) {
            case StepEnum.FORM2_STEP06:
                for (let i = this.influenceFactorsGetter.length; i < this.selectedform2_step06.length; i++) {
                    this.influenceFactorsGetter.push(this.formBuilder.group({
                        factor: [this.selectedform2_step06[i].title, [Validators.required]],
                        description: ['', []],
                        manifestingPerson: ['', []]
                    }));
                }
                break;
            case StepEnum.FORM3_STEP07:
                for (let i = this.householdProblemsGetter.length; i < this.selectedform3_step07.length; i++) {
                    this.householdProblemsGetter.push(this.formBuilder.group({
                        factor: [this.selectedform3_step07[i].title, [Validators.required]],
                        manifestingPerson: ['', [Validators.required]]
                    }));
                }
                break;
            case StepEnum.FORM23_STEP08:
                for (let i = this.problemsGetter.length; i < this.selectedform23_step08.length; i++) {
                    let duration: any;

                    const form = this.formBuilder.group({
                        difficulty: [this.selectedform23_step08[i].title, [Validators.required]],
                        duration: [duration, [Validators.required]],
                        intensity: [null, [Validators.required]]
                    });
                    form.markAllAsTouched();
                    this.problemsGetter.push(form);
                    if (this.selectedform23_step08[i].duration !== undefined) {

                    }
                }
                break;
            default:
                break;
        }
    }

    /** Get selected row from table, selected family member */
    onRowSelected(row: any) {
        if (this.selectedMember !== null) {
            this.selectedMember.highlighted = !this.selectedMember.highlighted;
        }
        row.highlighted = !row.highlighted;
        this.selectedMember = row;
        this.isMemberSelected = true;
    }

    /** Select family member and then propagate the data to family member dialog */
    importFamilyMember(): void {
        const dialogRef = this.dialog.open(SubjectsComponent, {
            width: '1200px',
            panelClass:'overlay-panel',
            data: {
                origin: 'forms23',
                members: this.members
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                const obj = JSON.parse(JSON.stringify(result));
                const {
                    data: {
                        subjectIdentity,
                        firstName,
                        lastName,
                        registrationId,
                        yearOfBirth,
                        occupation,
                        qualification,
                        dateOfBirth,
                        jmbg,
                        foreigner
                    }
                } = obj;

                const memberData = {
                    subjectIdentity,
                    firstName,
                    lastName,
                    registrationId,
                    yearOfBirth,
                    occupation: occupation !== null ? occupation.title : 'Непознато',
                    qualification,
                    dateOfBirth,
                    jmbg,
                    foreigner
                };


                this.openFamilyMembersDialog(memberData);
            }
        });
    }

    openFamilyMembersDialog(data: Partial<Subject>) {
        const tempSubject = { ...this.subject };
        const familyData = {
            origin: 'VS.INITIAL_ASSESSMENT.FORMS23',
            data,
            subject: tempSubject
        };
        familyData.subject.dateOfBirth = familyData.subject.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
        const dialogRef = this.dialog.open(FamilyMembersComponent, {
            disableClose: true,
            width: '480px',
            data: familyData,
            panelClass:'overlay-panel',
        });

        dialogRef.afterClosed().subscribe((result) => {
            const { event, data } = result;
            if (event === EventEnum.SUBMIT) {
                const memberCount = data.length;
                this.members = [...this.members, ...data];
            }
        });
    }

    /** Remove selected family member from table and refresh table content */
    removeFamilyMember() {
        const index = this.members.findIndex(x => x.name === this.selectedMember.name);
        this.members.splice(index, 1);
        this.members = this.members.slice();
        this.isMemberSelected = false;
    }

    updateSelectedLegalMeasuresProcedures(result: any) {
        this.selectedLegalMeasuresProcedures = result;
    }

    // @jninkovic - input ako je drugo u pitanju
    updateSelectedRights(result: any) {
        this.selectedRights = result;
    }

    updateSelectedServices(result: any) {
        this.selectedServices = result;
    }

    // @jninkovic - input ako je drugo u pitanju
    updateSelectedprofessionalProcedures(result: any) {
        this.selectedProfessionalProcedures = result;
    }
    createObject(isFinished: boolean){
        const submittedData = {
            id: (this.forms23?.id !== null && this.forms23?.id !== undefined) ? this.forms23?.id : null,
            formNumber: this.formNumber,
            dateOfSubmission: this.firstStep.value.dateOfSubmission,
            assessmentType: this.firstStep.value.assessmentType==''?null:this.firstStep.value.assessmentType,
            center: this.center,
            schoolPreschool: this.firstStep.value.schoolPreschool,
            schoolClass: this.firstStep.value.class,
            notesSchoolPreschool: this.firstStep.value.notesSchoolPreschool,
            contactPerson1Name: this.firstStep.value.contactPerson1Name,
            contactPerson1Kinship: this.firstStep.value.contactPerson1Kinship,
            contactPerson1Address: this.firstStep.value.contactPerson1Address,
            contactPerson1Phone: this.firstStep.value.contactPerson1Phone,
            contactPerson1Note: this.firstStep.value.contactPerson1Note,
            contactPerson2Name: this.firstStep.value.contactPerson2Name,
            contactPerson2Kinship: this.firstStep.value.contactPerson2Kinship,
            contactPerson2Address: this.firstStep.value.contactPerson2Address,
            contactPerson2Phone: this.firstStep.value.contactPerson2Phone,
            contactPerson2Note: this.firstStep.value.contactPerson2Note,
            notesEducation: this.firstStep.value.notesEducation,
            notesOcupationEmployment: this.firstStep.value.notesOcupationEmployment,
            notesMaritalStatus: this.firstStep.value.notesMaritalStatus,
            familyMembers: this.members,
            otherData: this.secondStep.value.otherData,
            note: this.thirdStep.value.note,
            institutions: this.thirdStep.value.institutions,
            evaluationReason: this.fourthStep.value.evaluationReason,
            childNeeds: this.fifthStep.value.childNeeds,
            childNeedsEvaluation: this.fifthStep.value.childNeedsEvaluation,
            stateNeeds: this.fifthStep.value.stateNeeds,
            stateNeedsEvaluation: this.fifthStep.value.stateNeedsEvaluation,
            parentAbility: this.sixthStep.value.parentAbility,
            assessment: this.sixthStep.value.assessment,
            influences: this.sixthStep.value.influences,
            influencesList: this.sixthStep.value.influenceFactors,
            developments: this.sixthStep.value.developments,
            disability: this.sixthStep.value.disability,
            talent: this.sixthStep.value.talent,
            importantEvents: this.sixthStep.value.importantEvents,
            userProblems: this.sixthStep.value.userProblems,
            parentProblems: this.sixthStep.value.parentProblems,
            developmentAssessment: this.sixthStep.value.developmentAssessment,
            familyInfluence: this.seventhStep.value.familyInfluence,
            familyInfluenceAssessment: this.seventhStep.value.familyInfluenceAssessment,
            householdProblems: this.seventhStep.value.householdProblemsArray,
            householdProblemsList: this.seventhStep.value.householdProblems,
            socialAssistance: this.seventhStep.value.socialAssistance,
            problems: this.eighthStep.value.problemsArray,
            problemsList: this.eighthStep.value.problems,
            otherProblem: this.eighthStep.value.otherProblem,
            strenghts: this.eighthStep.value.strenghts,
            summaryEvaluation: this.eighthStep.value.summaryEvaluation,
            targetedAssessment: this.eighthStep.value.targetedAssessment,
            legalProcedures: this.eighthStep.value.legalProcedures,
            services: this.eighthStep.value.services,
            rights: this.eighthStep.value.rights,
            professionalProcedures: this.eighthStep.value.professionalProcedures,
            servicesProceduresInterventions: this.eighthStep.value.servicesProceduresInterventions,
            finished: isFinished,
            approved: false
        };
        return submittedData;
    }
    save(isTeamRequest: any, isFinished: any) {
        const submittedData = this.createObject(isFinished);
        this.vsService.submitForms23(this.caseId, submittedData, isTeamRequest).subscribe(
            (result) => {
                if (!isTeamRequest) {
                    this.isEditMode = false;
                    if (isFinished) {
                        this.toastr.success('SNACKBAR.DOCUMENT_SENT_SUPERVISOR');
                        this.handleSaveChanges.emit({ step: 'STEP_02', isFinished: true, saved: true });
                    } else {
                        this.toastr.success('VS.INITIAL_ASSESSMENT.FORMS23.SAVED');
                        this.handleSaveChanges.emit({ step: 'STEP_02', isFinished: false, saved: true });
                    }
                }
            }, () => {
                this.submitButton1.disabled = false;
                this.submitButton2.disabled = false;
                this.toastr.error('SNACKBAR.ERROR_OCCURRED');
            }
        );

    }

    handleSubmit() {
        this.save(false, true);
        // this.handleSubmitData.emit({ step: 'STEP_02', finished: false });
    }

    handleSignature() {
        // this.isFormDigitallySigned = true;
    }

    handleChange() {
        this.isTargetedEvaluationNeeded = !this.isTargetedEvaluationNeeded;
        if (!this.isTargetedEvaluationNeeded) {
            this.eighthStep.controls.targetedAssessment.reset();
        }
    }

    closeForm() {
        this.isEditMode = false;
        this.handleSaveChanges.emit({ step: 'STEP_02', isFinished: false, saved: false });
    }

    sendRequest() {
        this.teamRequestOnWait = true;
        const request = {
            request: this.eighthStep.controls.targetedAssessment.value,
            caseId: this.caseId
        };
        this.teamRequestService.createTeamRequest(request).subscribe(
            result => {
                this.toastr.success('TEAM_REQUEST.REQUEST_SENT');
                this.handleNewTeamRequest.emit();
                this.save(true, false);
                this.teamRequestOnWait = false;
            }
        );
    }
    patchInstitutions() {

        const institutions = this.forms23.institutions.sort((a, b) => (a.ordinal > b.ordinal) ? 1 : ((b.ordinal > a.ordinal) ? -1 : 0));

        for (const iterator of (this.thirdStep.controls.institutions as FormArray).controls) {
            if (institutions.find(obj => parseInt(obj.ordinal) === iterator.value.ordinal) !== undefined) {
                (iterator as FormGroup).controls.address
                    .setValue(institutions.find(obj => parseInt(obj.ordinal) === iterator.value.ordinal).address);
                (iterator as FormGroup).controls.name
                    .setValue(institutions.find(obj => parseInt(obj.ordinal) === iterator.value.ordinal).name);
                (iterator as FormGroup).controls.contactPerson
                    .setValue(institutions.find(obj => parseInt(obj.ordinal) === iterator.value.ordinal).contactPerson);
                (iterator as FormGroup).controls.participation
                    .setValue(institutions.find(obj => parseInt(obj.ordinal) === iterator.value.ordinal).participation);
                (iterator as FormGroup).controls.phone
                    .setValue(institutions.find(obj => parseInt(obj.ordinal) === iterator.value.ordinal).phone);
            }
        }
    }

    get influenceFactorsGetter() { return this.sixthStep.controls.influences as FormArray; }
    get householdProblemsGetter() { return this.seventhStep.controls.householdProblemsArray as FormArray; }
    get problemsGetter() { return this.eighthStep.controls.problemsArray as FormArray; }

    async saveChanges() : Promise<boolean> {  

        let message = '';
        let title = '';
        this.translate.get('WARNING_DIALOG.SAVE_DATA.TITLE').subscribe((res: string) => {
           title = res;
        });
        this.translate.get('WARNING_DIALOG.SAVE_DATA.MESSAGE').subscribe((res: string) => {
           message = res;
        });
        const object = {
           document: null,
           message: message,
           title: title,
           saveData: true,
        };
        const dialogRef = this.dialog.open(WarningMessageComponent, {
           data: object,
           width: ModalSizeEnum.MINI,
           panelClass: 'overlay-panel',
        });
        await dialogRef.afterClosed().toPromise().then(         
           res =>{
              if(res?.event !== 'cancel' && res !== undefined){
                 return this.save(false,false);
              }
           }
        );
        return true;
     }
}
