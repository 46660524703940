/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { MatDialog } from '@angular/material/dialog';
import { CodebookService } from 'src/services/codebook.service';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';

enum AutocompleteFilterEnum {
   NATIONALITY = 'nationality',
   CITIZENSHIP = 'citizenship',
   OCCUPATION = 'occupation',
}

@Component({
   selector: 'new-foster-parent-personal-information-step',
   templateUrl: './new-foster-parent-personal-information-step.component.html',
   styleUrls: ['./new-foster-parent-personal-information-step.component.scss'],
})
export class NewFosterParentPersonalInformationStepComponent implements OnInit {
   @Output() personalInformationStepEmitter = new EventEmitter();
   @Input() personalInformationStep: FormGroup;
   currentDate = new Date();
   //healthStatusOptions: any = [];
   maritalStatusOptions: any = [];
   nationalityOptions: any = [];
   genderOptions: any = [];
   citizenshipOptions: any = [];
   educationOptions: any = [];
   employmentOptions: any = [];
   occupationOptions: any = [];
   regexStrDate = '^[0-9.]+$';
   regexStrJmbg = '^[0-9]+$';

   constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private codebookService: CodebookService, private datePipe: DatePipe) {
      //this.getHealthStatus();
      this.getMaritalStatus();
      this.getNationality();
      this.getGender();
      this.getCitizenship();
      this.getOccupation();
      this.getEducation();
      this.getEmployment();
   }

   filteredCitizenshipOptions: Observable<any[]>;
   filteredNationalityOptions: Observable<any[]>;
   filteredOccupationOptions: Observable<any[]>;
   ngOnInit(): void {
      this.personalInformationStep = this.formBuilder.group({
         subjectId: [''],
         registrationId: [''],
         firstName: ['', [Validators.required]],
         lastName: ['', [Validators.required]],
         gender: ['', [Validators.required]],
         foreigner: [false],
         dateOfBirth: ['', [Validators.required]],
         placeOfBirth: ['', [Validators.required]],
         jmbg: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(13), Validators.minLength(13)]],
         citizenship: [null],
         placeOfOrigin: ['', [Validators.required]],
         nationality: [null],
         education: ['', [Validators.required]],
         occupation: [null],
         workStatus: ['', [Validators.required]],
         maritalStatus: ['', [Validators.required]],
         healthStatus: ['' /*[Validators.required]*/],
      });
   }

   /**
    * Send step information to parent component
    */
   updatePersonalInformationStep() {
      this.personalInformationStepEmitter.emit(this.personalInformationStep);
   }

   importSubjectData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const address =
               String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
               String(result.data.permanentResidence.number ? ' ' + result.data.permanentResidence.number : '') +
               String(result.data.permanentResidence.subnumber ? ' ' + result.data.permanentResidence.subnumber : '') +
               String(result.data.permanentResidence.town ? ', ' + result.data.permanentResidence.town : '') +
               String(result.data.permanentResidence.street && result.data.residence.street ? '/' : '') +
               String(result.data.residence.street ? result.data.residence.street : '') +
               String(result.data.residence.number ? ' ' + result.data.residence.number : '') +
               String(result.data.residence.subnumber ? ' ' + result.data.residence.subnumber : '') +
               String(result.data.residence.town ? ', ' + result.data.residence.town : '');

            this.personalInformationStep.patchValue({
               subjectId: result.data.subjectIdentity.subjectId,
               registrationId: result.data.registrationId,
               firstName: result.data.firstName,
               lastName: result.data.lastName,
               gender: result.data.gender,
               foreigner: result.data.foreigner,
               dateOfBirth: new Date(result.data.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')),
               placeOfBirth: result.data.placeOfBirth,
               jmbg: result.data.jmbg,
               citizenship: result.data.citizenship,
               placeOfOrigin: address,
               nationality: result.data.nationality,
               education: result.data.education,
               occupation: result.data.occupation,
               workStatus: result.data.employment,
               maritalStatus: result.data.maritalStatus,
               healthStatus: result.data.healthStatus,
            });
            this.personalInformationStep.controls.gender.markAsTouched();
            this.personalInformationStep.controls.dateOfBirth.markAsTouched();
            this.personalInformationStep.controls.jmbg.markAsTouched();
         }
      });
   }

   getMaritalStatus() {
      this.codebookService.getFosterParentMaritalStatusCodebook().subscribe(res => {
         this.maritalStatusOptions = res;
      });
   }

   getNationality() {
      this.codebookService.getNationalityCodebook().subscribe(res => {
         this.nationalityOptions = res;
         this.filteredNationalityOptions = this.personalInformationStep.controls.nationality.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.NATIONALITY) : this.nationalityOptions.slice()))
         );
      });
   }

   getGender() {
      this.codebookService.getGenderCodebook().subscribe(res => {
         this.genderOptions = res;
      });
   }

   getCitizenship() {
      this.codebookService.getCitizenshipCodebook().subscribe(res => {
         this.citizenshipOptions = res;
         this.filteredCitizenshipOptions = this.personalInformationStep.controls.citizenship.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice()))
         );
      });
   }

   getEmployment() {
      this.codebookService.getEmploymentCodebook().subscribe(res => {
         if (res !== null) {
            res.forEach(element => {
               if ([1, 3, 4, 8, 9].includes(element.id)) {
                  this.employmentOptions.push(element);
               }
            });
         }
      });
   }

   getEducation() {
      this.codebookService.getEducationCodebook().subscribe(res => {
         this.educationOptions = res;
      });
   }

   getOccupation() {
      this.codebookService.getOccupationCodebook().subscribe(res => {
         this.occupationOptions = res;
         this.filteredOccupationOptions = this.personalInformationStep.controls.occupation.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION) : this.occupationOptions.slice()))
         );
      });
   }

   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();

      switch (what) {
         case AutocompleteFilterEnum.NATIONALITY:
            return this.nationalityOptions.filter((option: any) => option.title.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.CITIZENSHIP:
            return this.citizenshipOptions.filter((option: any) => option.title.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.OCCUPATION:
            return this.occupationOptions.filter((option: any) => option.title.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.title : '';
   }

   updateJMBGOnGenderChange() {
      if (this.personalInformationStep.value.foreigner === true) {
         return;
      }
      if (this.personalInformationStep.value.jmbg !== null && this.personalInformationStep.value.jmbg.length === 13) {
         const jmbg = this.personalInformationStep.value.jmbg.substring(9, 12);
         if (this.personalInformationStep.get('gender').value !== null && this.personalInformationStep.get('gender').value !== undefined) {
            if (this.personalInformationStep.value.jmbg.length === 13 && jmbg >= '000' && jmbg <= '499') {
               if (this.personalInformationStep.value.gender.code === 'F') {
                  this.personalInformationStep.get('gender').setErrors({ incorrect: true });
               } else {
                  this.personalInformationStep.get('gender').setErrors(null);
               }
            } else if (jmbg >= '500' && jmbg <= '999') {
               if (this.personalInformationStep.value.gender.code === 'M') {
                  this.personalInformationStep.get('gender').setErrors({ incorrect: true });
               } else {
                  this.personalInformationStep.get('gender').setErrors(null);
               }
            }
         }
      }
   }

   updateJMBGOnDateOfBirthChange() {
      if (this.personalInformationStep.value.foreigner) {
         return;
      }
      if (this.personalInformationStep.value.dateOfBirth && this.personalInformationStep.value.jmbg) {
         const birthDate = new Date(this.personalInformationStep.value.dateOfBirth).getTime();
         const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
         const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
         if (this.personalInformationStep.value.jmbg !== null && this.personalInformationStep.value.jmbg.length === 13) {
            const jmbg = this.personalInformationStep.value.jmbg.substring(0, 7);
            if (newBirthDate !== jmbg) {
               this.personalInformationStep.get('dateOfBirth').setErrors({ incorrect: true });
            } else {
               this.personalInformationStep.get('dateOfBirth').setErrors(null);
            }
         }
      }
   }

   checkNationality() {
      if (this.personalInformationStep.value.nationality !== undefined && this.personalInformationStep.value.nationality !== null && this.personalInformationStep.value.nationality !== '') {
         const choosedValue = this.personalInformationStep.value.nationality;
         var result = this.nationalityOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            this.personalInformationStep.controls.nationality.setErrors({ notValid: true });
         } else {
            this.personalInformationStep.controls.nationality.setValue(result);
            this.personalInformationStep.controls.nationality.setErrors(null);
         }
      }
   }

   checkCitizenship() {
      if (this.personalInformationStep.value.citizenship !== undefined && this.personalInformationStep.value.citizenship !== null && this.personalInformationStep.value.citizenship !== '') {
         const choosedValue = this.personalInformationStep.value.citizenship;
         var result = this.citizenshipOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            this.personalInformationStep.controls.citizenship.setErrors({ notValid: true });
         } else {
            this.personalInformationStep.controls.citizenship.setValue(result);
            this.personalInformationStep.controls.citizenship.setErrors(null);
         }
      }
   }

   checkOccupation() {
      if (this.personalInformationStep.value.occupation !== undefined && this.personalInformationStep.value.occupation !== null && this.personalInformationStep.value.occupation !== '') {
         const choosedValue = this.personalInformationStep.value.occupation;
         var result = this.occupationOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            this.personalInformationStep.controls.occupation.setErrors({ notValid: true });
         } else {
            this.personalInformationStep.controls.occupation.setValue(result);
            this.personalInformationStep.controls.occupation.setErrors(null);
         }
      }
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.personalInformationStep.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);

      if (inputDate > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.personalInformationStep.get(controlName);
      if (!control) return;

      const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement;
      const inputValue = inputElement?.value || '';
      const dateValue = control.value;

      if (!inputValue) {
         control.setErrors({ required: true });
         return;
      }

      if (!dateValue) {
         control.setErrors({ invalidFormat: true });
         return;
      }

      if (dateValue > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      }
   }

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      } else if (fieldName === 'jmbg') {
         return new RegExp(this.regexStrJmbg).test(event.key);
      }
   }
}
