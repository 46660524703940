/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

export enum ModalSizeEnum {
    MINI = '480px',
    DEFAULT = '700px',
    EXTRA_LARGE = '2000px'
}

export type Maybe<T> = T | void;

/**
 * Subject
 * TODO: @gorandivovic @jelenaninkovic @danijelgrabez
 * Properly enumerate possible entries
 */


export interface Address {
    town: string;
    localComunity: string;
    ptt: string;
    populatedPlace: any;
    street: any;
    number: string;
    subnumber: string;
}

export interface Subject {
    historicalSubjectIdentity: {
        csrId: string;
        subjectId: number;
        rev: number;
    };
    registrationId: any;
    id: number;
    subjectIdentity: {
        csrId: any;
        subjectId: number;
    };
    firstName: string;
    lastName: string;
    gender: any;
    dateOfBirth: string;
    jmbg: number;
    street: string;
    city: string;
    status: any;
    placeOfBirth: string;
    yearOfBirth: string;
    nationality: any;
    citizenship: any;
    nativeLanguage: string;
    disabledPerson: any;
    familyType: any;
    disabilityType: string;
    ableToWork: any;
    parentName: string;
    occupation: any;
    employment: any;
    education: any;
    businessAbility: boolean | null;
    familyDossier: any;
    jmbp: string;
    residence: Address;
    permanentResidence: Address;
    phone: number;
    mobile: number;
    email: string;
    note: string;
    note1: string;
    note2: string;
    note3: string;
    dateOfCreation: string;
    dateOfModification: string;
    modifiedBy: string;
    permanentResidenceId: number;
    residenceId: number;
    maritalStatus: any;
    familyDossierId: any;
    dossierOwner: any;
    levelOfEducation: any;
    schoolAttending: any;
    category: any;
    foreigner: boolean | null;
    deprivationExtent: any;
}

export enum DossierStatus {
    CARRIER = 'CARRIER',
    MEMBER = 'MEMBER',
    NONE = 'NONE'
}
export enum ModalEventEnum {
    DELETE = 'delete',
    CANCEL = 'cancel',
    VIEW = 'view',
    EDIT = 'edit',
    NEW = 'new',
    SEND = 'send',
    DOWNLOAD = 'download',
    SUCCESS = 'success',
    PRINT = 'print',
    CONFIRM = 'confirm',
    VIEW_IN_NEW_TAB = 'viewInNewTab'
}

export enum CaseStatus {
    NOT_ASSIGNED = 'NOT_ASSIGNED',
    ASSIGNED = 'ASSIGNED',
    ACCEPTED = 'ACCEPTED',
    CLOSED = 'CLOSED',
    FINISHED_PROCESSING = 'FINISHED_PROCESSING',
    COMPLAIN = 'COMPLAIN',
    SECOND_DEGREE = 'SECOND_DEGREE'
}

