<container>
  <mat-card class="card">
    <card-header title="{{ 'STATISTICS.GENERAL_RECORD_STATS.TITLE' | translate }}">
    </card-header>
    <form [formGroup]="generalRecordStatsForm" class="form">
      <field-holder *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
        <mat-form-field appearance="outline" (click)="selectMulti()">
          <mat-label> {{'GLOBAL.CENTER' | translate}}</mat-label>
          <mat-select formControlName="center" multiple required #multiSelect>
            <input type="text" class="multi-input" autocomplete="off" matInput
              placeholder="{{'GLOBAL.SEARCH_CENTERS' | translate}}" #multiSearch (focus)="multiSelect.disabled = true"
              (focusout)="multiSelect.disabled = false" (input)="onInputChange($event.target.value)" />
            <mat-option #allSelectedCenters (click)="toggleAllSelectionCenter()" [value]="0">
              {{ 'GLOBAL.ALL_CENTERS' | translate }}
            </mat-option>
            <mat-option *ngFor="let value of filteredAllCenters" [value]="value?.id" (click)="tosslePerOneCenter()">
              {{ value.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="generalRecordStatsForm.get('center').hasError('required')">
            {{ "GLOBAL.CENTER_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{'STATISTICS.GENERAL_RECORD_STATS.CHOOSE_OPTION' | translate}}</mat-label>
          <mat-select formControlName="selectedRecordOption">
            <mat-option *ngFor="let record of recordOptions" [value]="record">
              {{ record.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <section formGroupName="1" *ngIf="groups[1]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="timeOption" [compareWith]="compareObjectsId"
              (selectionChange)="changeTimePeriod($event.value)">
              <mat-option *ngFor="let option of timeOptions" [value]="option">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline"
            *ngIf="(generalRecordStatsForm.get('1')['controls']).timeOption.value?.id == 1">
            <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
            <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
              onlyNumber />
            <mat-error *ngIf="(generalRecordStatsForm.get('1')['controls']).selectedYear.hasError('required')  ">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline"
            *ngIf="(generalRecordStatsForm.get('1')['controls']).timeOption.value?.id == 2">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="dateDay" formControlName="dateDay" autocomplete="off"
              [required]="(generalRecordStatsForm.get('1')['controls']).timeOption.value?.id == 2"
              placeholder="{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="dateDay"></mat-datepicker-toggle>
            <mat-datepicker #dateDay></mat-datepicker>
            <mat-error *ngIf="(generalRecordStatsForm.get('1')['controls']).dateDay.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.GENDER' | translate}}</mat-label>
            <mat-select formControlName="gender">
              <mat-option></mat-option>
              <mat-option *ngFor="let record of genderOptions" [value]="record">
                {{ record.gender }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>


      </section>
      <section formGroupName="2" *ngIf="groups[2]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
            <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
              onlyNumber />
            <mat-error *ngIf="(generalRecordStatsForm.get('2')['controls']).selectedYear.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.GENERAL_RECORD_STATS.SUBJECT_STATUS' | translate}}</mat-label>
            <mat-select formControlName="subjectStatus" multiple>
              <mat-option *ngFor="let record of subjectStatusOptions" [value]="record.id">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </field-holder>
      </section>
      <section formGroupName="3" *ngIf="groups[3]">
        <field-holder kind="4-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
            <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
              onlyNumber />
            <mat-error *ngIf="(generalRecordStatsForm.get('3')['controls']).selectedYear.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.GENDER' | translate}}</mat-label>
            <mat-select formControlName="gender">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let record of genderOptions" [value]="record">
                {{ record.gender }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.RESIDENCE' | translate }}</mat-label>
            <mat-select formControlName="residence">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let record of residenceOptions" [value]="record.id">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </field-holder>
      </section>
      <section formGroupName="4" *ngIf="groups[4]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="dateDay" formControlName="dateDay" autocomplete="off" required
              placeholder="{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="dateDay"></mat-datepicker-toggle>
            <mat-datepicker #dateDay></mat-datepicker>
            <mat-error *ngIf="(generalRecordStatsForm.get('4')['controls']).dateDay.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </field-holder>
      </section>
      <section formGroupName="5" *ngIf="groups[5]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
            <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
              onlyNumber />
            <mat-error *ngIf="(generalRecordStatsForm.get('5')['controls']).selectedYear.hasError('required')  ">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.GENDER' | translate}}</mat-label>
            <mat-select formControlName="gender">
              <mat-option></mat-option>
              <mat-option *ngFor="let record of genderOptions" [value]="record">
                {{ record.gender }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
      </section>
      <section formGroupName="6" *ngIf="groups[6]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="dateDay" formControlName="dateDay" autocomplete="off" required
              placeholder="{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="dateDay"></mat-datepicker-toggle>
            <mat-datepicker #dateDay></mat-datepicker>
            <mat-error *ngIf="(generalRecordStatsForm.get('6')['controls']).dateDay.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
      </section>
      <section formGroupName="7" *ngIf="groups[7]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="dateDay" formControlName="dateDay" autocomplete="off" required
              placeholder="{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="dateDay"></mat-datepicker-toggle>
            <mat-datepicker #dateDay></mat-datepicker>
            <mat-error *ngIf="(generalRecordStatsForm.get('7')['controls']).dateDay.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
      </section>
      <section formGroupName="8" *ngIf="groups[8]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="dateDay" formControlName="dateDay" autocomplete="off" required
              placeholder="{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="dateDay"></mat-datepicker-toggle>
            <mat-datepicker #dateDay></mat-datepicker>
            <mat-error *ngIf="(generalRecordStatsForm.get('8')['controls']).dateDay.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
      </section>
      <section formGroupName="9" *ngIf="groups[9]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
            <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
              onlyNumber />
            <mat-error *ngIf="(generalRecordStatsForm.get('9')['controls']).selectedYear.hasError('required')  ">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
      </section>
      <section formGroupName="10" *ngIf="groups[10]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="dateDay" formControlName="dateDay" autocomplete="off" required
              placeholder="{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="dateDay"></mat-datepicker-toggle>
            <mat-datepicker #dateDay></mat-datepicker>
            <mat-error *ngIf="(generalRecordStatsForm.get('10')['controls']).dateDay.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.GENERAL_RECORD_STATS.DURATION_IN_FACILITY' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
      </section>
      <section formGroupName="11" *ngIf="groups[11]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.SELECTED_YEAR' | translate }}</mat-label>
            <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
              onlyNumber />
            <mat-error *ngIf="(generalRecordStatsForm.get('11')['controls']).selectedYear.hasError('required')  ">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'STATISTICS.AGE_GROUP' | translate}}</mat-label>
            <mat-select formControlName="ageGroup" multiple required>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'NEW_SUBJECT_PROBLEM.PROBLEM_TYPE' | translate }}</mat-label>
            <input formControlName="problem" required type="text" matInput [matAutocomplete]="autoType"
              (focusout)="autoSelectType()" />
            <mat-autocomplete #autoType="matAutocomplete" class="custom-autocomplete" [displayWith]="displayCustomType">
              <mat-option *ngFor="let problemCodebook of problemsCodebookFiltered | async" [value]="problemCodebook">
                {{ problemCodebook.title }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="(generalRecordStatsForm.get('11')['controls']).problem.hasError('required')">
              {{ 'NEW_SUBJECT_PROBLEM.PROBLEM_TYPE_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
      </section>
      <field-holder kind="2-cols" *ngIf="shouldShowAgeGroupRange">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.FROM' | translate }}</mat-label>
          <input matInput type="text" formControlName="customAgeGroupFrom" autocomplete="off" maxlength="3"
            (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
            placeholder="{{ 'STATISTICS.FROM' | translate }}" />
          <mat-error *ngIf="generalRecordStatsForm.get('customAgeGroupFrom').hasError('outOfRange')">
            {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.TO' | translate }}</mat-label>
          <input matInput type="text" formControlName="customAgeGroupTo" autocomplete="off" maxlength="3"
            (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
            placeholder="{{ 'STATISTICS.TO' | translate }}" />
          <mat-error *ngIf="generalRecordStatsForm.get('customAgeGroupTo').hasError('incorect')">
            {{ "STATISTICS.NUMBER_OF_YEARS_INCORECT" | translate }}
          </mat-error>
          <mat-error *ngIf="generalRecordStatsForm.get('customAgeGroupTo').hasError('outOfRange')">
            {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols"
        *ngIf="(isMainCenterBelgrade || isUserDrugostepeni)&&generalRecordStatsForm.controls.center.value!=null&&generalRecordStatsForm.controls.center.value.length>1">
        <mat-checkbox color="primary" class="form__checkbox" formControlName="byCenter">
          {{ 'STATISTICS.GROUP_BY_CSR' | translate }}
        </mat-checkbox>
      </field-holder>
      <form-footer>
        <button [disabled]="!generalRecordStatsForm.valid || btnDisabled"
          class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
          (click)="sendRequest(generalRecordStatsForm)">
          {{ 'STATISTICS.SUBMIT' | translate }}
        </button>
        <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </form-footer>
    </form>
  </mat-card>
</container>