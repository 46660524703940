/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalEventEnum } from 'src/types';
import { DatePipe } from '@angular/common';

@Component({
   selector: 'new-dependent-court-judgement',
   templateUrl: './new-dependent-court-judgement.component.html',
   styleUrls: ['./new-dependent-court-judgement.component.scss'],
})
export class NewDependentCourtJudgement implements OnInit {
   newRecord: FormGroup;
   currentDate = new Date();
   maxDate: Date = new Date(2999, 11, 31);
   regexStrDate = '^[0-9.]+$';

   constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<any>) {}

   ngOnInit(): void {
      this.newRecord = this.formBuilder.group({
         courtName: ['', [Validators.required]],
         courtNumber: ['', [Validators.required]],
         courtDate: ['', [Validators.required]],
         courtValidityDate: ['', [Validators.required]],
         dateOfVerdictDelivery: ['', [Validators.required]],
         dateOfCommencment: ['', [Validators.required]],
      });
   }

   addNewRecord(trackingDocumentForm: FormGroup) {
      trackingDocumentForm.get('courtValidityDate').setValue(this.datePipe.transform(new Date(trackingDocumentForm.value.courtValidityDate).getTime(), 'yyyy-MM-dd'));
      trackingDocumentForm.get('courtDate').setValue(this.datePipe.transform(new Date(trackingDocumentForm.value.courtDate).getTime(), 'yyyy-MM-dd'));
      trackingDocumentForm.get('dateOfVerdictDelivery').setValue(this.datePipe.transform(new Date(trackingDocumentForm.value.dateOfVerdictDelivery).getTime(), 'yyyy-MM-dd'));
      trackingDocumentForm.get('dateOfCommencment').setValue(this.datePipe.transform(new Date(trackingDocumentForm.value.dateOfCommencment).getTime(), 'yyyy-MM-dd'));
      this.dialogRef.close({
         event: ModalEventEnum.SUCCESS,
         data: trackingDocumentForm.value,
      });
   }

   closeDialog() {
      this.dialogRef.close({ event: ModalEventEnum.CANCEL });
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.newRecord.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);
      const maxDate = fieldName === 'courtValidityDate' || fieldName === 'dateOfCommencment' ? this.maxDate : this.currentDate;

      if (inputDate > maxDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.newRecord.get(controlName);
      if (!control) return;

      const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement;
      const inputValue = inputElement?.value || '';
      const dateValue = control.value;

      if (!inputValue) {
         control.setErrors({ required: true });
      }

      if (!dateValue) {
         control.setErrors({ invalidFormat: true });
         return;
      }

      const maxDate = controlName === 'courtValidityDate' || controlName === 'dateOfCommencment' ? this.maxDate : this.currentDate;
      
      if (dateValue > maxDate) {
         control.setErrors({ maxDateExceeded: true });
      }
   }

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      }
   }
}
