import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { Page } from 'src/app/page';
import { environment } from 'src/environments/environment';
import { Subject } from 'src/models/subject.model';
import { CodebookService } from 'src/services/codebook.service';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalSizeEnum } from 'src/types';
import * as myLetters from './../../letters';
import { ChangeRegIdDialogComponent } from './change-reg-id-dialog/change-reg-id-dialog.component';
import { RightsHistoryDialogComponent } from 'src/app/rights-history-dialog/rights-history-dialog.component';

enum AutocompleteFilterEnum {
   SUBCENTER = 'subcenter',
}
export interface SearchItem {
   name: string;
}

@Component({
   selector: 'app-change-reg-id',
   templateUrl: './change-reg-id.component.html',
   styleUrls: ['./change-reg-id.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class ChangeRegIdComponent implements OnInit {
   appliedTextFilters: string[] = [];
   subcenters = [];
   form: FormGroup;
   public searchTerms: string[] = [];
   public globalFilter: any = '';
   page: Page<Subject> = new Page();
   public searchItems: SearchItem[] = [];
   subcenter = new FormControl();
   filteredSubcenters: Observable<any>;
   displayedColumns: string[] = ['registrationId', 'id', 'dateOfCreation', 'lastName', 'firstName', 'dateOfBirth', 'gender', 'jmbg', 'actions'];
   myInput = '';
   centerId: number;
   subjects: any = [];
   isLoading: boolean = false;
   constructor(
      private paginationService: CustomPaginationService,
      private codebookService: CodebookService,
      private formBuilder: FormBuilder,
      private subjectsService: SubjectsService,
      private localStorageService: LocalStorageService,
      public dialog: MatDialog,
      private translate: TranslateService,
      private toastr: ToastrImplService
   ) {
      this.codebookService.getCsrCodebook().subscribe(result => {
         this.subcenters = result;
         this.filteredSubcenters = this.subcenter.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.SUBCENTER) : this.subcenters.slice()))
         );
      });
   }

   ngOnInit(): void {
      this.form = this.formBuilder.group({
         jmbg: ['', [Validators.pattern(myLetters.numberValidator)]],
         regId: [''],
         subjectId: [''],
      });
   }
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.SUBCENTER:
            return this.subcenters.filter(option => option.name.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }
   public getNextPage(): void {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.getSubjects();
   }

   public getPreviousPage(): void {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.getSubjects();
   }
   public getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.getSubjects();
   }

   public getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.getSubjects();
   }

   public getPageInNewSize(pageSize: number): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.getSubjects();
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }

   chooseCenter(center: any) {
      this.centerId = center?.id;
      this.page.pageable.pageNumber = 0;
      this.getSubjects();
   }
   submit() {
      this.page.pageable.pageNumber = 0;
      this.getSubjects();
   }
   getSubjects() {
      this.isLoading = true;
      const subjectId = this.form.get('subjectId').value;
      const jmbg = this.form.get('jmbg').value;
      const regId = this.form.get('regId').value;
      this.subjects = [];
      const data = {
         csrId: this.centerId,
         subjectId: subjectId,
         jmbg: jmbg ? String(jmbg) : '',
         regId: regId,
      };
      this.subjectsService.getSubjects(data, this.page.pageable).subscribe(res => {
         this.isLoading = false;
         this.page = res;
         this.page.pageable.pagePerShow = res.number + 1;
         this.subjects = res.content;
         for (const element of this.subjects) {
            if (element.dateOfBirth !== null) {
               element.dateOfBirth = (element.dateOfBirth as any).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
            }
            element.dateOfCreation = (element.dateOfCreation as any).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
         }
      });
   }

   handleOpenDialog(rowSelected: any) {
      const csrId = rowSelected.subjectIdentity.csrId;
      const subjectId = rowSelected.subjectIdentity.subjectId;
      const regId = rowSelected.registrationId;
      const object = {
         regId: regId,
         csrId: csrId,
      };
      const dialogRef = this.dialog.open(ChangeRegIdDialogComponent, {
         disableClose: true,
         width: ModalSizeEnum.MINI,
         data: object,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(res => {
         if (res.event !== 'cancel') {
            this.subjectsService.changeRegId(String(csrId), String(subjectId), res.data.newRegId, regId ? String(regId) : null).subscribe(
               resp => {
                  this.toastr.success('GLOBAL_ADMIN.CHANGE_REG_ID.SUCCESS');
                  this.form.get('subjectId').setValue(null);
                  this.form.get('jmbg').setValue(null);
                  this.form.get('regId').setValue(null);
                  this.getSubjects();
               },
               err => {
                  this.toastr.error('GLOBAL_ADMIN.CHANGE_REG_ID.ERROR_SUBMIT');
               }
            );
         }
      });
   }

   checkDetails(item: any) {
      this.localStorageService.set('localStorageCenterId', item.subjectIdentity.csrId);
      window.open(environment.getFamilyMemberTab + item.subjectIdentity.subjectId, '_blank');
   }

   openRightsHistoryComponent(item: any) {
      const dataObject = {
         subjectId: item.subjectIdentity.subjectId,
         csrId: item.subjectIdentity.csrId,
         type: 'NSP'
      };
  
      this.dialog.open(RightsHistoryDialogComponent, {
         panelClass: 'overlay-panel',
         disableClose: false,
         width: ModalSizeEnum.EXTRA_LARGE,
         data: dataObject,
      });
   }
}
