/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class NotificationService {
   constructor(private httpClient: HttpClient, private app: AppService) {}

   getNotifications(notificationType) {
      return this.httpClient.get(this.app.getUrlNotifications() + environment.getNotifications + notificationType) as Observable<any>;
   }

   notificationsReceived(notificationsType) {
      return this.httpClient.put(this.app.getUrlNotifications() + environment.notificationReceived + notificationsType, {}) as Observable<any>;
   }

   getAllNotificationsPageable(page, size) {
      return this.httpClient.get(this.app.getUrlNotifications() + environment.getAllNotificationsPageable + page + '/' + size) as Observable<any>;
   }
}
