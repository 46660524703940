/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';
import { ProtegePersonService } from 'src/services/protege-person.service';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'new-protege-subject',
  templateUrl: './new-protege-subject.component.html',
  styleUrls: ['./new-protege-subject.component.scss'],
})
export class NewProtegeSubjectComponent implements OnInit {
  newProtegeSubject: FormGroup;

  guardianshipTypeOptions = [
  ];
  currentDate = new Date();
  regexStrDate = '^[0-9.]+$';
  regexStrJmbg = '^[0-9]+$';

  constructor(
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private protegePersonService: ProtegePersonService,
    private codebookService: CodebookService,
    public dialogRef: MatDialogRef<NewProtegeSubjectComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { step: string },
    private formBuilder: FormBuilder
  ) {
    this.newProtegeSubject = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      fatherFirstName: ['', []],
      subjectId: ['', []],
      foreigner: [false],
      dateOfBirth: ['', []],
      jmbg: ['', [Validators.pattern('^[0-9]*$')]],
      guardianshipType: ['', [Validators.required]],
      guardianshipId: ['', []],
      yearOfBirth: ['']
    });

    this.getGuardianshipTypeCodebook();
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  ngOnInit() { }

  closeRecord() {
    // Kreiranje
    this.newProtegeSubject.value.guardianshipType = this.newProtegeSubject.value.guardianshipType === '' ?
      null : this.newProtegeSubject.value.guardianshipType;
		if(this.newProtegeSubject.value.dateOfBirth != null){
    	this.newProtegeSubject.value.dateOfBirth =
      this.datePipe.transform(new Date(this.newProtegeSubject.value.dateOfBirth).getTime(), 'dd/MM/yyyy');
}
    this.protegePersonService.createProtegePerson(this.newProtegeSubject.value).subscribe((res) => {
      if (res != null) {
        this.dialogRef.close({ event: 'Success', data: this.newProtegeSubject.value });
      } else {
        this.dialogRef.close({ event: 'Unsuccessful', data: this.newProtegeSubject.value });
      }
    });

  }

  getGuardianshipTypeCodebook() {
    this.codebookService.getGuardianshipTypeCodebook().subscribe((res) => { this.guardianshipTypeOptions = res; }
    );
  }


  updateJMBGOnDateOfBirthChange(event: any) {
    if (this.newProtegeSubject.value.foreigner === true) {
        return;
    }
    if (this.newProtegeSubject.value.dateOfBirth !== null && this.newProtegeSubject.value.dateOfBirth !== '' &&
      this.newProtegeSubject.value.jmbg !== null && this.newProtegeSubject.value.jmbg.length >= 7) {
      const birthDate = new Date(this.newProtegeSubject.value.dateOfBirth).getTime();
      const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
      const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
      const jmbg = this.newProtegeSubject.value.jmbg.substring(0, 7);

      if (this.newProtegeSubject.value.jmbg.length === 13) {
        if (newBirthDate !== jmbg) {
          this.newProtegeSubject.get('dateOfBirth').setErrors({ incorrect: true });
        } else {
          this.newProtegeSubject.get('dateOfBirth').setErrors(null);
        }
      }
    }

  }


  importSubjectData() {
    const dialogRef = this.dialog.open(SubjectsComponent, {
      width: '1200px',
      panelClass:'overlay-panel',
      data: {
        origin: 'entrance',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.newProtegeSubject.patchValue({
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          fatherFirstName: result.data.parentName,
          foreigner: result.data.foreigner,
          jmbg: result.data.jmbg,
          subjectId: result.data.subjectIdentity.subjectId,
          dateOfBirth: result.data.dateOfBirth
            !== null ? new Date(result.data.dateOfBirth.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')) : null,
          gender: result.data.gender,
          yearOfBirth: result.data.yearOfBirth
        });
        this.selectGuardianshipTypeOptions();
        this.newProtegeSubject.controls.jmbg.markAsTouched();
        this.newProtegeSubject.controls.dateOfBirth.markAsTouched();
      }
    });
  }
  selectGuardianshipTypeOptions() {
    const currentYear = new Date().getFullYear();
    let userYearOfBirth = -1;
    let userDateOfBirth = null;

    if (this.newProtegeSubject.value.dateOfBirth !== undefined && this.newProtegeSubject.value.dateOfBirth !== null
      && this.newProtegeSubject.value.dateOfBirth !== '') {
      userDateOfBirth = (new Date(this.newProtegeSubject.value.dateOfBirth.toString()
        .replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')));
    } else if (this.newProtegeSubject.value.jmbg !== undefined && this.newProtegeSubject.value.jmbg !== null &&
      this.newProtegeSubject.value.jmbg !== '' && this.newProtegeSubject.value.jmbg.toString().length >= 7) {
      const jmbgDate = this.newProtegeSubject.value.jmbg.substring(4, 7);
      userYearOfBirth = Number(Number(jmbgDate) <= 200 ? '2' + jmbgDate : '1' + jmbgDate);
      userDateOfBirth = new Date(userYearOfBirth, Number(jmbgDate.substring(2, 4)) - 1, Number(jmbgDate.substring(0, 2)));
    } else if (this.newProtegeSubject.value.yearOfBirth !== undefined &&
      this.newProtegeSubject.value.yearOfBirth !== null && this.newProtegeSubject.value.yearOfBirth !== '') {
      userYearOfBirth = Number(this.newProtegeSubject.value.yearOfBirth);
    } else {
      return;
    }

    if (userDateOfBirth !== null) {
      const timeDiff = Math.abs(Date.now() - userDateOfBirth.getTime());
      const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      if (age >= 18) {
        this.newProtegeSubject.controls.guardianshipType.setValue(this.guardianshipTypeOptions[1]);
      } else {
        this.newProtegeSubject.controls.guardianshipType.setValue(this.guardianshipTypeOptions[0]);
      }
    } else if (currentYear - userYearOfBirth >= 18) {
      this.newProtegeSubject.controls.guardianshipType.setValue(this.guardianshipTypeOptions[1]);
    } else {
      this.newProtegeSubject.controls.guardianshipType.setValue(this.guardianshipTypeOptions[0]);
    }
  }

  checkInputDateFormat(fieldName: string, event: any): void {
    const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

    const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

    const control = this.newProtegeSubject.get(fieldName);
    const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
    const inputDate = new Date(year, month - 1, day);

    if (inputDate > this.currentDate) {
       control.setErrors({ maxDateExceeded: true });
    } else {
       control.setErrors(null);
    }
 }

 onBlur(controlName: string) {
    const control = this.newProtegeSubject.get(controlName);
    if (!control) return;
  
    const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement;
    const inputValue = inputElement?.value || '';
    const dateValue = control.value;
  
    if (inputValue && !dateValue) {
      control.setErrors({ invalidFormat: true });
      return;
    }
  
    if (dateValue && dateValue > this.currentDate) {
      control.setErrors({ maxDateExceeded: true });
    }
  }
  

 @HostListener('keypress', ['$event'])
 onKeyPress(event: any) {
    const fieldName = event.target.name;
    if (fieldName === 'dateFormat') {
       return new RegExp(this.regexStrDate).test(event.key);
    } else if (fieldName === 'jmbg') {
       return new RegExp(this.regexStrJmbg).test(event.key);
    }
 }
}
