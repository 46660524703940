<form [formGroup]="createUserForm" class="modal-new-worker">
  <field-holder>
    <mat-form-field appearance="outline">
      <input matInput type="text" formControlName="firstName" required />
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.FIRST_NAME' | translate }}</mat-label>
      <mat-error *ngIf="createUserForm.get('firstName').hasError('required')">
        {{'GLOBAL_ADMIN.CREATE_USER.FIRST_NAME_ERROR' | translate}}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <input matInput type="text" formControlName="lastName" required />
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.LAST_NAME' | translate }}</mat-label>
      <mat-error *ngIf="createUserForm.get('lastName').hasError('required')">
        {{'GLOBAL_ADMIN.CREATE_USER.LAST_NAME_ERROR' | translate}}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.USERNAME' | translate }}</mat-label>
      <input matInput type="text" (change)="checkUsername()" formControlName="username" required/>
      <mat-error *ngIf="createUserForm.get('username').hasError('required')">
        {{'GLOBAL_ADMIN.CREATE_USER.USERNAME_ERROR' | translate}}
      </mat-error>
      <mat-error *ngIf="createUserForm.get('username').hasError('taken')">
        {{'GLOBAL_ADMIN.CREATE_USER.USERNAME_EXIST_ERROR' | translate}}
      </mat-error>
      <mat-error *ngIf="createUserForm.get('username').hasError('pattern')">
        {{'GLOBAL_ADMIN.CREATE_USER.USERNAME_LETTER_ERROR' | translate}}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.JMBG' | translate }}</mat-label>
      <input matInput type='text' maxlength="13" formControlName="jmbg"  (input)="checkJMBGRule()"/>
      <mat-error *ngIf="createUserForm.get('jmbg').hasError('pattern')">
        {{'GLOBAL_ADMIN.CREATE_USER.JMBG_ERROR' | translate}}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline" *ngIf="userId === undefined">
      <input matInput [type]="fieldTextType ? 'text' : 'password'" formControlName="password" required />
      <mat-icon matSuffix (click)="toggleFieldTextType()">{{!fieldTextType ? 'visibility_off' : 'visibility'}}
      </mat-icon>
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.PASSWORD' | translate }}</mat-label>
      <mat-error *ngIf="createUserForm.get('password').hasError('required')">
        {{'GLOBAL_ADMIN.CREATE_USER.PASSWORD_ERROR' | translate}}
      </mat-error>
      <mat-error *ngIf="createUserForm.get('password').hasError('pattern')">
        {{'GLOBAL_ADMIN.CREATE_USER.PASSWORD_LETTER_ERRROR' | translate}}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline" *ngIf="!isCenterAdminLogged">
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.CENTER' | translate }}</mat-label>
      <mat-select formControlName="center" (selectionChange)="findOffices($event);"[required]="requiredCenter" [compareWith]="compareCenters">
        <mat-option *ngFor="let cent of centers" [value]="cent">
          {{ cent.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="createUserForm.get('center').hasError('required')">
        {{'GLOBAL_ADMIN.CREATE_USER.CENTER_ERROR' | translate}}
      </mat-error>
      <mat-error *ngIf="createUserForm.get('center').hasError('missingCenter')">
        {{'GLOBAL_ADMIN.CREATE_USER.OFFICE_ERROR' | translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="isCenterAdminLogged">
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.CENTER' | translate }}</mat-label>
       <input matInput formControlName="center" [readonly]="true" required>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-select formControlName="office" (selectionChange)="checkCenter($event)" [compareWith]="compareOffices">
        <mat-option></mat-option>
        <mat-option *ngFor="let office of offices" [value]="office">
          {{ office.name }}
        </mat-option>
      </mat-select>
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.OFFICE' | translate }}</mat-label>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-select formControlName="role" multiple required (selectionChange)="checkIsRoleChanged($event.value)" 
                  [compareWith]="compareRoles">
        <mat-option *ngFor="let role of roles; let i = index" [value]="role" [disabled]=disabledRoles[i] >
          {{ role.roleName }}
        </mat-option>
      </mat-select>
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.ROLE' | translate }}</mat-label>
      <mat-error *ngIf="createUserForm.get('role').hasError('required')">
        {{'GLOBAL_ADMIN.CREATE_USER.ROLE_ERROR' | translate}}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder *ngIf="isRoleDescriptionNeeded">
    <mat-form-field appearance="outline">
      <mat-select formControlName="roleDescription" [compareWith]="compareRoleDescription">
        <mat-option value=null> </mat-option>
        <mat-option *ngFor="let role of roleDescriptions" [value]="role">
          {{ role }}
        </mat-option>
      </mat-select>
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.ROLE_DESCRIPTION' | translate }}</mat-label>
      <mat-error *ngIf="createUserForm.get('role').hasError('required')">
        {{'GLOBAL_ADMIN.CREATE_USER.ROLE_ERROR' | translate}}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder *ngIf="isRoleDescriptionNeeded">
    <mat-form-field appearance="outline">
      <mat-select multiple formControlName="supervisors" [disabled]="this.createUserForm.value.center === null" [compareWith]="compareSubordinates">
        <mat-option *ngFor="let user of supervisors" [value]="user.username">
          {{ user.name }}
          <ng-container *ngIf="user.office !== ''">
            ( {{  user.office }} )
          </ng-container>
        </mat-option>
      </mat-select>
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.SUPERVISORS' | translate }}</mat-label>
    </mat-form-field>
  </field-holder>
  <field-holder *ngIf="isUserSupervisor">
    <mat-form-field appearance="outline">
      <mat-select multiple formControlName="subordinates" [disabled]="this.createUserForm.value.center === null" [compareWith]="compareSubordinates">
        <mat-option *ngFor="let user of subordinates" [value]="user.username">
          {{ user.name }}
          <ng-container *ngIf="user.office !== ''">
            ( {{  user.office }} )
          </ng-container>
        </mat-option>
      </mat-select>
      <mat-label>{{ 'GLOBAL_ADMIN.CREATE_USER.SUBORDINATES' | translate }}</mat-label>
    </mat-form-field>
  </field-holder>
</form>
<form-footer>
  <button class="form-footer__button form-footer__button--secondary" (click)="closeDialog()" mat-flat-button
    color="secondary">
    {{ 'GLOBAL_ADMIN.CREATE_USER.CANCEL' | translate }}
  </button>
  <button class="form-footer__button form-footer__button--secondary" mat-flat-button color="primary"
    (click)="submitNewUser()" type="submit" [disabled]="!createUserForm.valid">
    {{ 'GLOBAL_ADMIN.CREATE_USER.SUBMIT' | translate }}
  </button>
</form-footer>
