import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { SubjectDocumentsDialogComponent } from 'src/app/sidebar-widget/subject-documents/subject-documents-dialog/subject-documents-dialog.component';
import { fileCheck } from 'src/app/utils/fileInputUtil';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { environment } from 'src/environments/environment';
import { AlfrescoService } from 'src/services/alfresco.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { CaseService } from 'src/services/case.service';
import { ExpeditionService } from 'src/services/expedition.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalSizeEnum } from 'src/types';

@Component({
   selector: 'pmn',
   templateUrl: './pmn.component.html',
   styleUrls: ['./pmn.component.scss'],
})
export class PmnComponent implements OnInit {
   pmnForm: FormGroup;
   documentOptions = [];
   caseData: any;
   caseName: any;
   caseId: any;
   subjectId: any;
   fileToUpload: any = null;
   docType: any;
   isChecked = false;
   isAccepted = false;
   isLawyer: any = false;

   @ViewChild('submitButton') submitButton;
   @ViewChild('file') file: ElementRef<HTMLInputElement>;
   isSubmited: boolean = false;
   loggedIn: any;
   teamMember = false;

   constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private caseService: CaseService,
      private cdr: ChangeDetectorRef,
      private authentication: AuthenticationService,
      private alfrescoService: AlfrescoService,
      public dialog: MatDialog,
      private translate: TranslateService,
      private toastr: ToastrImplService,
      private localStorageService: LocalStorageService,
      private route: ActivatedRoute,
      private userService: UserService,
      private expedition: ExpeditionService
   ) {
      this.isLawyer = this.userService.isUserLawyer();

      this.route.params.subscribe(params => {
         this.subjectId = params.subjectId;
         this.caseId = params.id;
         const [_, caseType] = this.router.url.split('/cases/');
         if (caseType !== '') {
            this.caseName = caseType.split('/')[0];
         }
         this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
         this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
         this.caseService.getSocialCase(this.caseId).subscribe(result => {
            this.caseData = result;
            if (this.caseData.caseStatus === 'ACCEPTED') {
               this.isAccepted = true;
            }
            if (this.loggedIn?.id !== this.caseData.assignedUserId) {
               this.teamMember = true;
            } else {
               this.teamMember = false;
            }
         });
         this.alfrescoService.getDocumentTemplates(this.caseName).subscribe(result => {
            this.documentOptions = result;
         });
      });

      this.pmnForm = this.formBuilder.group({
         fileName: ['', []],
      });
   }

   updateStatus(newStatus: string) {
      this.caseData.caseService = newStatus;
      if (newStatus === 'ACCEPTED') {
         this.isAccepted = true;
      }
      this.cdr.detectChanges();
   }

   ngOnInit(): void {}

   handleNewDocument(doc) {
      //    window.location.href = 'ms-word:ofe|u|' + url;
      const body = {
         documentId: doc.documentId,
         fileName: doc.name,
         caseId: this.caseId === undefined ? null : this.caseId,
      };
      this.alfrescoService.downloadDocumentByUrl(body);
   }

   handleFileInput(files: FileList) {
      const modifiedFiles = fileCheck(files, this.dialog, this.translate);
      const dialogRef = this.dialog.open(SubjectDocumentsDialogComponent, {
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(({ event, ...rest }) => {
         if (event === 'new') {
            if (modifiedFiles.length > 0) {
               this.fileToUpload = modifiedFiles[0];
               this.pmnForm.get('fileName').setValue(this.fileToUpload.name);
               this.docType = rest.data.value;
            }
            this.file.nativeElement.value = null;
         }
      });
   }

   // provera da li korisnik ima aktivne usluge
   checkSubjectStatus() {
      this.caseService.checkIsActiveRight(this.subjectId).subscribe(result => {
         if (result === 'true') {
            this.toastr.warning('SNACKBAR.USER_HAVE_SERVICE');
         } else {
            this.toastr.success('SNACKBAR.USER_NOT_HAVE_SERVICE');
         }
         this.isChecked = true;
      });
   }

   sendRequest() {
      this.isSubmited = true;
      const formData: FormData = new FormData();
      formData.append('file', this.fileToUpload, this.fileToUpload.name);
      formData.append('docType', this.docType);

      // prenos
      if (this.caseData.socialCaseClassificationCodebook.caseKindViewCodebook.type === 1) {
         this.caseService.submitPmnRequest(this.caseId, formData).subscribe(
            result => {
               this.cdr.detectChanges();
               this.toastr.success('SNACKBAR.PMN_DOCUMENT_ADDED');
               this.submitButton.disabled = false;
               this.isSubmited = false;
               this.localStorageService.set('case', true);
               this.expedition.send({ documentId: result, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'pmn');
            },
            error => {
               manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
               this.submitButton.disabled = false;
               this.isSubmited = false;
            }
         );
         // prijem
      } else {
         this.caseService.submitPmnAcceptance(this.caseId, formData).subscribe(
            result => {
               this.cdr.detectChanges();
               this.toastr.success('SNACKBAR.PMN_ACCEPT_DOCUMENT_ADDED');
               this.submitButton.disabled = false;
               this.isSubmited = false;
               this.localStorageService.set('case', true);
               this.expedition.send({ documentId: result, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'pmn');
            },
            error => {
               manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
               this.submitButton.disabled = false;
               this.isSubmited = false;
            }
         );
      }
   }

   handleCloseCase({ status, res }) {
      const body = {
         closeCaseCodebook: res.closeCaseCodebook,
         caseId: this.caseId,
         explanation: res.explanation,
         comment: res.comment,
      };

      this.caseService.closePmn(body).subscribe(
         result => {
            this.cdr.detectChanges();
            this.toastr.success('SNACKBAR.SUBJECT_CLOSED');
            this.localStorageService.set('case', true);
            this.router.navigateByUrl('/');
         },
         error => {
            manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
         }
      );
   }

   /**
    * Notes:
    * - This component will be used for both by requester and recepient
    * - Requester can check subject status (hide it for recepient)
    * - When the document is uploaded by requester, it should automatically open the modal for document sending
    */
}
