/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { AbusedPersonService } from 'src/services/abused-person.service';
import { CodebookService } from 'src/services/codebook.service';
import { ToastrImplService } from 'src/services/toastr.service';

enum AutocompleteFilterEnum {
   NATIONALITY = 'nationality',
   CITIZENSHIP = 'citizenship',
   OCCUPATION = 'occupation',
}

@Component({
   selector: 'new-domestic-violence-victim',
   templateUrl: './new-domestic-violence-victim.component.html',
   styleUrls: ['./new-domestic-violence-victim.component.scss'],
})
export class NewDomesticViolenceVictimComponent implements OnInit {
   commitedVioleceTypeOptions = [];
   genderOptions = [];
   violenceFamilyMemberCommiterOptions = [];
   definedProtectionMeasureOptions = [];
   protectionFromViolenceRequesterOptions = [];
   commitedViolenceCountOptions = [];
   nationalityOptions = [];
   filteredNationalityOptions: Observable<any[]>;
   citizenshipOptions = [];
   filteredCitizenshipOptions: Observable<any[]>;
   occupationOptions = [];
   filteredOccupationOptions: Observable<any[]>;

   currentDate = new Date();
   newRecord: FormGroup;
   shouldShowCommitedViolenceMeta = false;
   shouldShowProtectionFromViolenceRequesterMeta = false;
   shouldShowViolenceFamilyMemberCommiterMeta = false;

   maxDate: Date = new Date(2999, 11, 31);
   regexStrDate = '^[0-9.]+$';
   regexStrJmbg = '^[0-9]+$';

   constructor(
      private router: Router,
      private abusedPersonService: AbusedPersonService,
      private dialog: MatDialog,
      private codebookService: CodebookService,
      private formBuilder: FormBuilder,
      private translate: TranslateService,
      private datePipe: DatePipe,
      private toastr: ToastrImplService
   ) {
      this.getGender();
      this.getViolence();
      this.getViolenceCommiter();
      this.getProtectionMeasure();
      this.getProtectionRequester();
      this.getCommitedViolenceCount();
      this.getNationality();
      this.getCitizenship();
      this.getOccupation();
   }

   ngOnInit(): void {
      this.newRecord = this.formBuilder.group({
         subjectId: [''],
         registrationId: [''],
         firstName: ['', [Validators.required]],
         lastName: ['', [Validators.required]],
         maidenName: ['', []],
         nickname: ['', []],
         parentFullName: ['', []],
         motherMaidenName: ['', []],
         gender: [''],
         foreigner: [false],
         dateOfBirth: [null],
         jmbg: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(13), Validators.maxLength(13)]],
         placeOfBirth: [''],
         countryOfBirth: ['', []],
         citizenship: [null],
         nationality: [null],
         occupation: [''],
         residenceAddress: [''],
         commitedViolenceCount: ['', [Validators.required]],
         commitedViolenceMeta: ['', []],
         protectionFromViolenceRequester: ['', [Validators.required]],
         protectionFromViolenceRequesterMeta: ['', [Validators.required]],
         courtName: ['', [Validators.required]],
         verdictNumber: ['', [Validators.required]],
         dateOfVerdict: ['', [Validators.required]],
         dateOfVerdictEntryIntoForce: ['', [Validators.required]],
         commitedVioleceType: ['', [Validators.required]],
         violenceFamilyMemberCommiter: ['', [Validators.required]],
         violenceFamilyMemberCommiterMeta: [''],
         definedProtectionMeasure: ['', [Validators.required]],
         from: ['', [Validators.required]],
         to: ['', [Validators.required]],
      });

      this.dynamicFormUpdate();
   }

   dynamicFormUpdate() {
      this.newRecord.get('commitedViolenceCount').valueChanges.subscribe(option => {
         if (option.id === 3) {
            this.newRecord.get('commitedViolenceMeta').enable();
            this.shouldShowCommitedViolenceMeta = true;
         } else {
            this.newRecord.get('commitedViolenceMeta').disable();
            this.shouldShowCommitedViolenceMeta = false;
         }
      });

      this.newRecord.get('protectionFromViolenceRequester').valueChanges.subscribe(option => {
         if (option.id === 3 || option.id === 4) {
            this.newRecord.get('protectionFromViolenceRequesterMeta').enable();
            this.shouldShowProtectionFromViolenceRequesterMeta = true;
         } else {
            this.newRecord.get('protectionFromViolenceRequesterMeta').disable();
            this.shouldShowProtectionFromViolenceRequesterMeta = false;
         }
      });

      this.newRecord.get('violenceFamilyMemberCommiter').valueChanges.subscribe(option => {
         if ([4, 5, 6, 7, 8, 9, 12].includes(option.id)) {
            this.newRecord.get('violenceFamilyMemberCommiterMeta').enable();
            this.shouldShowViolenceFamilyMemberCommiterMeta = true;
         } else {
            this.newRecord.get('violenceFamilyMemberCommiterMeta').disable();
            this.shouldShowViolenceFamilyMemberCommiterMeta = false;
         }
      });
   }

   createRecord(newRecord) {
      // Submit new record
      this.createNewDomesticViolence();
   }

   createNewDomesticViolence() {
      if (this.newRecord.value.jmbg !== '') {
         this.updateJMBGOnGenderChange('');
         this.updateJMBGOnDateOfBirthChange('');
      }
      if (!this.newRecord.valid) {
         return;
      }

      const body = {
         subjectId: this.newRecord.value.subjectId,
         registrationId: this.newRecord.value.registrationId,
         firstName: this.newRecord.value.firstName,
         lastName: this.newRecord.value.lastName,
         maidenName: this.newRecord.value.maidenName,
         nickName: this.newRecord.value.nickname,
         parentFirstAndLastName: this.newRecord.value.parentFullName,
         motherMaidenName: this.newRecord.value.motherMaidenName,
         gender: this.newRecord.value.gender === '' ? null : this.newRecord.value.gender,
         dateOfBirth: this.newRecord.value.dateOfBirth !== null ? this.datePipe.transform(new Date(this.newRecord.value.dateOfBirth).getTime(), 'dd/MM/yyyy') : null,
         placeOfBirth: this.newRecord.value.placeOfBirth,
         countryOfBirth: this.newRecord.value.countryOfBirth,
         jmbg: this.newRecord.value.jmbg,
         nationality: this.newRecord.value.nationality !== '' ? this.newRecord.value.nationality : null,
         citizenship: this.newRecord.value.citizenship !== '' ? this.newRecord.value.citizenship : null,
         permanentResidence: this.newRecord.value.residenceAddress,
         occupation: this.newRecord.value.occupation !== '' ? this.newRecord.value.occupation : null,
         commitedViolenceCount: this.newRecord.value.commitedViolenceCount,
         count: this.newRecord.value.commitedViolenceMeta,
         protectionRequesterCodebook: this.newRecord.value.protectionFromViolenceRequester,
         protectionRequesterNotes: this.newRecord.value.protectionFromViolenceRequesterMeta,
         judgmentNumber: this.newRecord.value.verdictNumber,
         judgmentDate: this.datePipe.transform(new Date(this.newRecord.value.dateOfVerdict).getTime(), 'dd/MM/yyyy'),
         entryForceDate: this.datePipe.transform(new Date(this.newRecord.value.dateOfVerdictEntryIntoForce).getTime(), 'dd/MM/yyyy'),
         violenceKindCodebook: this.newRecord.value.commitedVioleceType,
         familyMemberCommiter: [
            {
               key: this.newRecord.value.violenceFamilyMemberCommiter.id,
               value: this.newRecord.value.violenceFamilyMemberCommiterMeta !== undefined ? this.newRecord.value.violenceFamilyMemberCommiterMeta : '',
            },
         ],
         measureDurationFrom: this.datePipe.transform(new Date(this.newRecord.value.from).getTime(), 'dd/MM/yyyy'),
         measureDurationTo: this.datePipe.transform(new Date(this.newRecord.value.to).getTime(), 'dd/MM/yyyy'),
         protectionMeasureCodebook: this.newRecord.value.definedProtectionMeasure,
         courtName: this.newRecord.value.courtName,
      };

      this.abusedPersonService.createAbusedPerson(body).subscribe(res => {
         if (res != null) {
            this.toastr.success('SNACKBAR.ADDED_VIOLENCE_VICTIM');
            this.router.navigateByUrl('/domestic-violence-victim');
         } else {
            this.toastr.error('SNACKBAR.ADD_VIOLENCE_VICTIM_ERROR');
            this.router.navigateByUrl('/domestic-violence-victim');
         }
      });
   }

   getViolence() {
      this.codebookService.getViolenceCodebook().subscribe(res => {
         this.commitedVioleceTypeOptions = res;
      });
   }

   getGender() {
      this.codebookService.getGenderCodebook().subscribe(res => {
         this.genderOptions = res;
      });
   }

   getViolenceCommiter() {
      this.codebookService.getViolenceCommiterCodebook().subscribe(res => {
         this.violenceFamilyMemberCommiterOptions = res;
      });
   }

   getProtectionMeasure() {
      this.codebookService.getProtectionMeasureCodebook().subscribe(res => {
         this.definedProtectionMeasureOptions = res;
      });
   }

   getProtectionRequester() {
      this.codebookService.getProtectionRequesterCodebook().subscribe(res => {
         this.protectionFromViolenceRequesterOptions = res;
      });
   }

   getCommitedViolenceCount() {
      this.codebookService.getCommitedViolenceCountCodebook().subscribe(res => {
         this.commitedViolenceCountOptions = res;
      });
   }

   importSubjectData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const address =
               String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
               String(result.data.permanentResidence.number ? ' ' + result.data.permanentResidence.number : '') +
               String(result.data.permanentResidence.subnumber ? ' ' + result.data.permanentResidence.subnumber : '') +
               String(result.data.permanentResidence.town ? ', ' + result.data.permanentResidence.town : '') +
               String(result.data.permanentResidence.street && result.data.residence.street ? '/' : '') +
               String(result.data.residence.street ? result.data.residence.street : '') +
               String(result.data.residence.number ? ' ' + result.data.residence.number : '') +
               String(result.data.residence.subnumber ? ' ' + result.data.residence.subnumber : '') +
               String(result.data.residence.town ? ', ' + result.data.residence.town : '');
            this.newRecord.patchValue({
               firstName: result.data.firstName,
               lastName: result.data.lastName,
               gender: result.data.gender,
               foreigner: result.data.foreigner,
               dateOfBirth: result.data.dateOfBirth !== null ? new Date(result.data.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')) : null,
               placeOfBirth: result.data.placeOfBirth,
               jmbg: result.data.jmbg,
               citizenship: result.data.citizenship,
               residenceAddress: address,
               nationality: result.data.nationality,
               occupation: result.data.occupation,
               parentFullName: result.data.parentName,
               subjectId: result.data.subjectIdentity.subjectId,
               registrationId: result.data.registrationId,
            });

            this.newRecord.controls.gender.markAsTouched();
            this.newRecord.controls.dateOfBirth.markAsTouched();
            this.newRecord.controls.jmbg.markAsTouched();
         }
      });
   }

   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }

   getNationality() {
      this.codebookService.getNationalityCodebook().subscribe(res => {
         this.nationalityOptions = res;
         this.filteredNationalityOptions = this.newRecord.controls.nationality.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.NATIONALITY) : this.nationalityOptions.slice()))
         );
      });
   }

   getCitizenship() {
      this.codebookService.getCitizenshipCodebook().subscribe(res => {
         this.citizenshipOptions = res;
         this.filteredCitizenshipOptions = this.newRecord.controls.citizenship.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice()))
         );
      });
   }

   getOccupation() {
      this.codebookService.getOccupationCodebook().subscribe(res => {
         this.occupationOptions = res;
         this.filteredOccupationOptions = this.newRecord.controls.occupation.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION) : this.occupationOptions.slice()))
         );
      });
   }
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();

      switch (what) {
         case AutocompleteFilterEnum.NATIONALITY:
            return this.nationalityOptions.filter(option => option.title.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.CITIZENSHIP:
            return this.citizenshipOptions.filter(option => option.title.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.OCCUPATION:
            return this.occupationOptions.filter(option => option.title.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.title : '';
   }

   updateJMBGOnGenderChange(event: any) {
      if (this.newRecord.value.foreigner === true) {
         return;
      }
      if (this.newRecord.value.jmbg !== null && this.newRecord.value.jmbg.length === 13) {
         const jmbg = this.newRecord.value.jmbg.substring(9, 12);
         if (this.newRecord.get('gender').value !== null && this.newRecord.get('gender').value !== undefined) {
            if (this.newRecord.value.jmbg.length === 13 && jmbg >= '000' && jmbg <= '499') {
               if (this.newRecord.value.gender.code === 'F') {
                  this.newRecord.get('gender').setErrors({ incorrect: true });
               } else {
                  this.newRecord.get('gender').setErrors(null);
               }
            } else if (jmbg >= '500' && jmbg <= '999') {
               if (this.newRecord.value.gender.code === 'M') {
                  this.newRecord.get('gender').setErrors({ incorrect: true });
               } else {
                  this.newRecord.get('gender').setErrors(null);
               }
            }
         }
      }
   }

   updateJMBGOnDateOfBirthChange(event: any) {
      if (event != null && this.newRecord.value.foreigner !== true) {
         if (this.newRecord.value.dateOfBirth !== null) {
            const birthDate = new Date(this.newRecord.value.dateOfBirth).getTime();
            const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
            const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
            if (this.newRecord.value.jmbg !== null && this.newRecord.value.jmbg.length === 13) {
               const jmbg = this.newRecord.value.jmbg.substring(0, 7);
               if (newBirthDate !== jmbg) {
                  this.newRecord.get('dateOfBirth').setErrors({ incorrect: true });
               } else {
                  this.newRecord.get('dateOfBirth').setErrors(null);
               }
            }
         }
      }
   }

   checkNationality() {
      if (this.newRecord.value.nationality !== undefined && this.newRecord.value.nationality !== null && this.newRecord.value.nationality !== '') {
         const choosedValue = this.newRecord.value.nationality;

         var result = this.nationalityOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            this.newRecord.controls.nationality.setErrors({ notValid: true });
         } else {
            this.newRecord.controls.nationality.setValue(result);
            this.newRecord.controls.nationality.setErrors(null);
         }
      }
   }

   checkCitizenship() {
      if (this.newRecord.value.citizenship !== undefined && this.newRecord.value.citizenship !== null && this.newRecord.value.citizenship !== '') {
         const choosedValue = this.newRecord.value.citizenship;

         var result = this.citizenshipOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            this.newRecord.controls.citizenship.setErrors({ notValid: true });
         } else {
            this.newRecord.controls.citizenship.setValue(result);
            this.newRecord.controls.citizenship.setErrors(null);
         }
      }
   }

   checkOccupation() {
      if (this.newRecord.value.occupation !== undefined && this.newRecord.value.occupation !== null && this.newRecord.value.occupation !== '') {
         const choosedValue = this.newRecord.value.occupation;

         var result = this.occupationOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            this.newRecord.controls.occupation.setErrors({ notValid: true });
         } else {
            this.newRecord.controls.occupation.setValue(result);
            this.newRecord.controls.occupation.setErrors(null);
         }
      }
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.newRecord.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);
      const maxDate = fieldName === 'from' || fieldName === 'to' ? this.maxDate : this.currentDate;

      if (inputDate > maxDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.newRecord.get(controlName);
      if (!control) return;

      const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement;
      const inputValue = inputElement?.value || '';
      const dateValue = control.value;

      if (controlName !== 'dateOfBirth' && !inputValue) {
         control.setErrors({ required: true });
         return;
      }

      if (!dateValue && inputValue) {
         control.setErrors({ invalidFormat: true });
         return;
      }

      const maxDate = controlName === 'from' || controlName === 'to' ? this.maxDate : this.currentDate;

      if (dateValue && dateValue > maxDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      } else if (fieldName === 'jmbg') {
         return new RegExp(this.regexStrJmbg).test(event.key);
      }
   }
}
