/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
   selector: 'new-adopted-child-other-information-step',
   templateUrl: './new-adopted-child-other-information-step.component.html',
   styleUrls: ['./new-adopted-child-other-information-step.component.scss'],
})
export class NewAdoptedChildOtherInformationStepComponent implements OnInit {
   @Output() otherInformationStepEmitter = new EventEmitter();
   @Input() otherInformationStep: FormGroup;
   currentDate = new Date();
   regexStrDate = '^[0-9.]+$';
   constructor(private formBuilder: FormBuilder) {}

   ngOnInit(): void {
      this.otherInformationStep = this.formBuilder.group({
         typeOfRelationship: ['', [Validators.required]],
         decisionNumber: ['', [Validators.required]],
         decisionDate: ['', [Validators.required]],
         decisionNumberNewBirthRegistration: ['', [Validators.required]],
         decisionDateNewBirthRegistration: ['', [Validators.required]],
         annulmentOfAdoption: [''],
         holderOfMaintenanceObligation: [''],
         note: [''],
      });
   }

   /**
    * Send step information to parent component
    */
   updateOtherInformationStep() {
      this.otherInformationStepEmitter.emit(this.otherInformationStep);
   }

   checkInputDateFormat(fieldName: string, event: any): void {
      const input = event.target.value.trim().replace(/\s+/g, ''); // remove all spaces

      const validFormat = /^\d{1,2}\.\d{1,2}\.\d{4}\.?$/; // d.m.yyyy or dd.mm.yyyy with optional trailing period

      const control = this.otherInformationStep.get(fieldName);
      const [day, month, year] = input.replace(/\./g, '/').split('/').map(Number);
      const inputDate = new Date(year, month - 1, day);

      if (inputDate > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      } else {
         control.setErrors(null);
      }
   }

   onBlur(controlName: string) {
      const control = this.otherInformationStep.get(controlName);
      const inputElement = document.querySelector(`[formControlName="${controlName}"]`) as HTMLInputElement;

      if (!control || !inputElement) return;

      const inputValue = inputElement.value;
      const dateValue = control.value;

      if (!inputValue) {
         control.setErrors({ required: true });
      } else if (!dateValue) {
         control.setErrors({ invalidFormat: true });
      } else if (dateValue > this.currentDate) {
         control.setErrors({ maxDateExceeded: true });
      }
   }

   @HostListener('keypress', ['$event'])
   onKeyPress(event: any) {
      const fieldName = event.target.name;
      if (fieldName === 'dateFormat') {
         return new RegExp(this.regexStrDate).test(event.key);
      }
   }
}
