<h3 class="title">
  {{ 'VS.INITIAL_ASSESSMENT.FORMS23.TITLE' + formNumber | translate }}
</h3>

<mat-vertical-stepper #stepper [linear]="!firstStep.valid" class="stepper">
  <mat-step [stepControl]="firstStep">
    <form class="form" [formGroup]="firstStep">
      <ng-template matStepLabel>
        {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.TITLE' | translate }}
      </ng-template>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>lll
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.DATE' | translate }}
          </mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateOfSubmission" readonly required disabled
            autocomplete="off" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CSR' | translate }}
          </mat-label>
          <input matInput type="text" formControlName="center" required readonly autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.TYPE_OF_EVALUATION'| translate}}
          </mat-label>
          <mat-select formControlName="assessmentType" required [compareWith]="compareAssessmentTypeObjects">
            <mat-option *ngFor="let assessmentType of assessmentTypes" [value]="assessmentType">
              {{ assessmentType.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="firstStep.get('assessmentType').hasError('required')">
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.TYPE_OF_EVALUATION_ERROR'| translate}}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder *ngIf="formNumber === 3">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.EDUCATION_NOTES'| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="notesEducation" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider *ngIf="formNumber === 3"></form-subgroup-divider>
      <field-holder *ngIf="formNumber === 3">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.OCCUPATION_EMPLOYMENT_NOTES'| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="notesOcupationEmployment" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider *ngIf="formNumber === 3"></form-subgroup-divider>
      <field-holder *ngIf="formNumber === 3">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.MARITAL_STATUS_NOTES'| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="notesMaritalStatus" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols" *ngIf="formNumber === 2">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.SCHOOL_PRESCHOOL'| translate}}
          </mat-label>
          <input matInput maxlength="70" type="text" formControlName="schoolPreschool" autocomplete="off" />
          <mat-error *ngIf="firstStep.get('schoolPreschool').hasError('required')">
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.SCHOOL_PRESCHOOL_ERROR'| translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CLASS' | translate }}
          </mat-label>
          <input matInput type="text" maxlength="15" formControlName="class" autocomplete="off" />
          <mat-error *ngIf="firstStep.get('class').hasError('required')">
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CLASS_ERROR' | translate}}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder *ngIf="formNumber === 2">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.NOTES' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="notesSchoolPreschool" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CONTACT_PERSON.NAME'| translate}}
          </mat-label>
          <input matInput type="text" maxlength="54" formControlName="contactPerson1Name" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CONTACT_PERSON.KINSHIP'| translate}}
          </mat-label>
          <mat-select formControlName="contactPerson1Kinship" [compareWith]="compareRelationshipObjects">
            <mat-option *ngFor="let relationship of relationshipOptions" [value]="relationship">
              {{ relationship.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <field-holder kind="2-cols">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CONTACT_PERSON.ADDRESS'| translate}}
            </mat-label>
            <input matInput type="text" maxlength="67" formControlName="contactPerson1Address" autocomplete="off" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CONTACT_PERSON.PHONE'| translate}}
            </mat-label>
            <input matInput type="text" maxlength="31" formControlName="contactPerson1Phone" autocomplete="off" />
          </mat-form-field>
        </field-holder>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CONTACT_PERSON.NOTE'| translate}}
            </mat-label>
            <input matInput type="text" formControlName="contactPerson1Note" autocomplete="off" />
          </mat-form-field>
        </field-holder>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CONTACT_PERSON.NAME'| translate}}
          </mat-label>
          <input matInput type="text" maxlength="54" formControlName="contactPerson2Name" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CONTACT_PERSON.KINSHIP'| translate}}
          </mat-label>
          <mat-select formControlName="contactPerson2Kinship" [compareWith]="compareRelationshipObjects">
            <mat-option *ngFor="let relationship of relationshipOptions" [value]="relationship">
              {{ relationship.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <field-holder kind="2-cols">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CONTACT_PERSON.ADDRESS'| translate}}
            </mat-label>
            <input matInput type="text" maxlength="67" formControlName="contactPerson2Address" autocomplete="off" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CONTACT_PERSON.PHONE'| translate}}
            </mat-label>
            <input matInput type="text" maxlength="31" formControlName="contactPerson2Phone" autocomplete="off" />
          </mat-form-field>
        </field-holder>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_01.CONTACT_PERSON.NOTE'| translate}}
            </mat-label>
            <input matInput type="text" formControlName="contactPerson2Note" autocomplete="off" />
          </mat-form-field>
        </field-holder>
      </field-holder>
      <form-footer>
        <button matStepperNext mat-stroked-button color="primary" [disabled]="!firstStep.valid"
          class="form-footer__button">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.NEXT_STEP' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondStep">
    <form class="form" [formGroup]="secondStep">
      <ng-template matStepLabel>
        {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_02.TITLE' | translate }}
      </ng-template>
      <table mat-table [dataSource]="members" #mytable class="mb-16" *ngIf="members.length > 0"
        style="width: -webkit-fill-available">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'FAMILY_MEMBERS.FIRST_LAST_NAME' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="relationship">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'FAMILY_MEMBERS.RELATIONSHIP' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.relationship.title }}
          </td>
        </ng-container>
        <ng-container matColumnDef="yearOfBirth">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'FAMILY_MEMBERS.BIRTH_YEAR' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.yearOfBirth }}
          </td>
        </ng-container>
        <ng-container matColumnDef="qualificationEmployment">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'FAMILY_MEMBERS.QUALIFICATION_EMPLOYMENT' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.qualificationEmployment }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="members__row-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowSelected(row)"
          [ngClass]="{ hovered: row.hovered, highlighted: row.highlighted }" (mouseover)="row.hovered = true"
          (mouseout)="row.hovered = false"></tr>
      </table>
      <div class="button-holder">
        <button mat-flat-button color="primary" (click)="importFamilyMember()"
          class="form-footer__button form-footer__button--secondary">
          <mat-icon>person_add</mat-icon>
          {{ 'ENTRANCE.STEP_03.ADD_MEMBER' | translate }}
        </button>
        <button mat-flat-button color="warn" (click)="removeFamilyMember()" [disabled]="!isMemberSelected">
          <mat-icon>person_remove</mat-icon>
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_02.REMOVE_MEMBER' | translate}}
        </button>
      </div>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_02.OTHER_DATA' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="otherData" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
          class="form-footer__button form-footer__button--secondary">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.PREVIOUS_STEP' | translate }}
        </button>
        <button matStepperNext mat-stroked-button color="primary" class="form-footer__button">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.NEXT_STEP' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-step>
  <mat-step [stepControl]="thirdStep">
    <form class="form" [formGroup]="thirdStep">
      <ng-template matStepLabel>
        {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_03.TITLE' | translate }}
      </ng-template>
      <table mat-table [dataSource]="dataSource" #mytable class="mb-16" style="width: -webkit-fill-available">
        <ng-container matColumnDef="kind">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_03.KIND' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" [formGroup]="element">
            <mat-form-field appearance="outline" style="width: 10vw">
              <input matInput type="text" formControlName="kind" required readonly />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_03.NAME' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" [formGroup]="element">
            <mat-form-field appearance="outline" style="width: 8vw">
              <input matInput type="text" formControlName="name" maxlength="250" />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_03.ADDRESS' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" [formGroup]="element">
            <mat-form-field appearance="outline" style="width: 8vw">
              <input matInput type="text" maxlength="250" formControlName="address" />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_03.PHONE' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" [formGroup]="element">
            <mat-form-field appearance="outline" style="width: 8vw">
              <input matInput type="text" maxlength="15" formControlName="phone" />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="contactPerson">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_03.CONTACT_PERSON' | translate}}
          </th>
          <td mat-cell *matCellDef="let element" [formGroup]="element">
            <mat-form-field appearance="outline" style="width: 8vw">
              <input matInput type="text" maxlength="250" formControlName="contactPerson" />
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="participation">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_03.PARTICIPATION' | translate}}
          </th>
          <td mat-cell *matCellDef="let element" [formGroup]="element">
            <mat-form-field appearance="outline" style="width: 8vw">
              <input matInput type="text" formControlName="participation" maxlength="250" />
            </mat-form-field>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsInstitutions" class="members__row-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsInstitutions" (click)="onRowSelected(row)"></tr>
      </table>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_03.NOTE' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="note" autocomplete="off"> </textarea>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
          class="form-footer__button form-footer__button--secondary">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.PREVIOUS_STEP' | translate }}
        </button>
        <button matStepperNext mat-stroked-button color="primary" class="form-footer__button">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.NEXT_STEP' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-step>
  <mat-step [stepControl]="fourthStep">
    <form class="form" [formGroup]="fourthStep">
      <ng-template matStepLabel>
        {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_04.TITLE' | translate }}
      </ng-template>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_04.EVALUATION_REASON'| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="evaluationReason" autocomplete="off"
            matTooltip="{{              'VS.INITIAL_ASSESSMENT.FORMS23.STEP_04.EVALUATION_REASON_TOOLTIP'| translate}}"></textarea>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
          class="form-footer__button form-footer__button--secondary">
          {{ 'ENTRANCE.PREVIOUS_STEP' | translate }}
        </button>
        <button matStepperNext mat-stroked-button color="primary" class="form-footer__button">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.NEXT_STEP' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-step>
  <mat-step [stepControl]="fifthStep">
    <form class="form" [formGroup]="fifthStep">
      <ng-template matStepLabel>
        {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_05.TITLE' + formNumber | translate}}
      </ng-template>
      <field-holder *ngIf="formNumber === 2">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_05.CHILD_NEEDS' | translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="childNeeds" autocomplete="off"
            matTooltip="{{              'VS.INITIAL_ASSESSMENT.FORMS23.STEP_05.CHILD_NEEDS_TOOLTIP'| translate}}"></textarea>
        </mat-form-field>
      </field-holder>
      <field-holder *ngIf="formNumber === 2">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_05.CHILD_NEEDS_EVALUATION'| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="childNeedsEvaluation" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>

      <field-holder *ngIf="formNumber === 3">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_05.TITLE' + formNumber| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="stateNeeds" autocomplete="off"
            matTooltip="{{              'VS.INITIAL_ASSESSMENT.FORMS23.STEP_05.STATE_NEEDS_TOOLTIP'| translate}}"></textarea>
        </mat-form-field>
      </field-holder>
      <field-holder *ngIf="formNumber === 3">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_05.STATE_NEEDS_EVALUATION'| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="stateNeedsEvaluation" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>

      <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
          class="form-footer__button form-footer__button--secondary">
          {{ 'ENTRANCE.PREVIOUS_STEP' | translate }}
        </button>
        <button matStepperNext mat-stroked-button color="primary" class="form-footer__button">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.NEXT_STEP' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-step>
  <mat-step [stepControl]="sixthStep">
    <form class="form" [formGroup]="sixthStep">
      <ng-template matStepLabel>
        {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.TITLE' + formNumber | translate}}
      </ng-template>
      <ng-container *ngIf="formNumber === 2">
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.PARENT_ABILITY'| translate}}
            </mat-label>
            <textarea matInput type="text" formControlName="parentAbility" autocomplete="off"
              matTooltip="{{                'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.PARENT_ABILITY_TOOLTIP'| translate}}"></textarea>
          </mat-form-field>
        </field-holder>
        <form-subgroup-divider></form-subgroup-divider>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.INFLUENCE_FACTORS'| translate}}
            </mat-label>
            <mat-select formControlName="influenceFactors" multiple [compareWith]="compareRelationshipObjects"
              (selectionChange)="setInfluenceFactorsConditionalFields()">
              <mat-option *ngFor="let factor of form2_step06" [value]="factor">
                {{ factor.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
        <article *ngIf="selectedform2_step06.length > 0">
          <field-holder kind="3-cols" [formGroup]="influence" *ngFor="
              let influence of influenceFactorsGetter.controls;
              let i = index
            ">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.DIFICULTY_TYPE'| translate}}
              </mat-label>
              <input matInput type="text" maxlength="250" formControlName="factor" required readonly />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.DESCRIPTION'| translate}}
              </mat-label>
              <input matInput type="text" maxlength="250" formControlName="description" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.MANIFESTING_PERSON'| translate}}
              </mat-label>
              <input matInput type="text" maxlength="250" formControlName="manifestingPerson" />
            </mat-form-field>
          </field-holder>
        </article>
        <form-subgroup-divider></form-subgroup-divider>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.ASSESSMENT' | translate}}
            </mat-label>
            <textarea matInput type="text" formControlName="assessment" autocomplete="off"></textarea>
          </mat-form-field>
        </field-holder>
      </ng-container>
      <ng-container *ngIf="formNumber === 3">
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.DEVELOPMENT' | translate}}
            </mat-label>
            <mat-select formControlName="developments" multiple [compareWith]="compareRelationshipObjects">
              <mat-option *ngFor="let option of developmentOptions" [value]="option">
                {{ option.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
        <article *ngIf="sixthStep.get('developments').value !== null">
          <field-holder kind="2-cols" *ngFor="
              let development of sixthStep.get('developments').value;
              let i = index
            ">
            <ng-container *ngIf="development.id === 2">
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.DEVELOPMENT'| translate}}
                </mat-label>
                <input matInput type="text" readonly value="{{ development.title }}" />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input matInput type="text" formControlName="disability" maxlength="250" />
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="development.id === 4">
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.DEVELOPMENT'| translate}}
                </mat-label>
                <input matInput type="text" readonly value="{{ development.title }}" />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input matInput type="text" formControlName="talent" maxlength="250" />
              </mat-form-field>
            </ng-container>
          </field-holder>
        </article>
        <form-subgroup-divider></form-subgroup-divider>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.IMPORTANT_EVENTS'| translate}}
            </mat-label>
            <textarea matInput type="text" formControlName="importantEvents" autocomplete="off"
              matTooltip="{{                'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.IMPORTANT_EVENTS_TOOLTIP'| translate}}"></textarea>
          </mat-form-field>
        </field-holder>
        <form-subgroup-divider></form-subgroup-divider>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.USER_PROBLEMS'| translate}}
            </mat-label>
            <textarea matInput type="text" formControlName="userProblems" autocomplete="off"
              matTooltip="{{                'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.USER_PROBLEMS_TOOLTIP'| translate}}"></textarea>
          </mat-form-field>
        </field-holder>
        <form-subgroup-divider></form-subgroup-divider>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.PARENT_PROBLEMS'| translate}}
            </mat-label>
            <textarea matInput type="text" formControlName="parentProblems" autocomplete="off"
              matTooltip="{{                'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.PARENT_PROBLEMS_TOOLTIP'| translate}}"></textarea>
          </mat-form-field>
        </field-holder>
        <form-subgroup-divider></form-subgroup-divider>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.DEVELOPMENT_ASSESSMENT'| translate}}
            </mat-label>
            <textarea matInput type="text" formControlName="developmentAssessment" autocomplete="off"></textarea>
          </mat-form-field>
        </field-holder>
      </ng-container>
      <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
          class="form-footer__button form-footer__button--secondary">
          {{ 'ENTRANCE.PREVIOUS_STEP' | translate }}
        </button>
        <button matStepperNext mat-stroked-button color="primary" class="form-footer__button">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.NEXT_STEP' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-step>
  <mat-step [stepControl]="seventhStep">
    <form class="form" [formGroup]="seventhStep">
      <ng-template matStepLabel>
        {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_07.TITLE' | translate }}
      </ng-template>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_07.TITLE' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="familyInfluence" autocomplete="off"
            matTooltip="{{              'VS.INITIAL_ASSESSMENT.FORMS23.STEP_07.FAMILY_INFLUENCE_TOOLTIP'| translate}}"></textarea>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder *ngIf="formNumber === 3">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_07.PROBLEMS_HOUSEHOLD'| translate}}
          </mat-label>
          <mat-select formControlName="householdProblems" multiple [compareWith]="compareRelationshipObjects">
            <mat-option *ngFor="let problem of form3_step07" [value]="problem">
              {{ problem.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <article *ngIf="selectedform3_step07.length > 0">
        <field-holder kind="3-cols" [formGroup]="householdProblem" *ngFor="
            let householdProblem of householdProblemsGetter.controls;
            let i = index
          ">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_07.PROBLEM' | translate }}
            </mat-label>
            <input matInput type="text" formControlName="factor" required readonly />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_06.MANIFESTING_PERSON'| translate}}
            </mat-label>
            <input matInput type="text" maxlength="250" formControlName="manifestingPerson" />
          </mat-form-field>
        </field-holder>
      </article>
      <form-subgroup-divider *ngIf="formNumber === 3"></form-subgroup-divider>
      <field-holder *ngIf="formNumber === 3">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_07.SOCIAL_ASSISTANCE'| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="socialAssistance" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider *ngIf="formNumber === 3"></form-subgroup-divider>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_07.FAMILY_INFLUENCE_ASSESSMENT'| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="familyInfluenceAssessment" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
          class="form-footer__button form-footer__button--secondary">
          {{ 'ENTRANCE.PREVIOUS_STEP' | translate }}
        </button>
        <button matStepperNext mat-stroked-button color="primary" class="form-footer__button">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.NEXT_STEP' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-step>
  <mat-step [stepControl]="eighthStep">
    <form class="form" [formGroup]="eighthStep">
      <ng-template matStepLabel>
        {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.TITLE' | translate }}
      </ng-template>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label *ngIf="formNumber === 2">
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.PROBLEMS_CHILD' | translate}}
          </mat-label>
          <mat-label *ngIf="formNumber === 3">
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.PROBLEMS_USER' | translate}}
          </mat-label>
          <mat-select formControlName="problems" multiple [compareWith]="compareRelationshipObjects"
            (selectionChange)="isOtherProblemChecked($event)">
            <ng-container *ngIf="formNumber === 2">
              <mat-option *ngFor="let problem of form2_step08" [value]="problem">
                {{ problem.title }}
              </mat-option>
            </ng-container>
            <ng-container *ngIf="formNumber === 3">
              <mat-option *ngFor="let problem of form3_step08" [value]="problem">
                {{ problem.title }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <article *ngIf="selectedform23_step08.length > 0">
        <field-holder kind="3-cols" [formGroup]="problem"
          *ngFor="let problem of problemsGetter.controls; let i = index">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.PROBLEM' | translate }}
            </mat-label>
            <input matInput type="text" formControlName="difficulty" readonly />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.DURATION' | translate }}
            </mat-label>
            <mat-select formControlName="duration" [compareWith]="compareDurationObjects" required>
              <mat-option *ngFor="let duration of problemsDuration" [value]="duration" required>
                {{ duration.duration }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.INTENSITY' | translate}}
            </mat-label>
            <mat-select formControlName="intensity" [compareWith]="compareIntensityObjects" required>
              <mat-option *ngFor="let intensity of problemsIntensity" [value]="intensity" required>
                {{ intensity.intensity }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
        <ng-container *ngIf="isOtherProblem">
          <field-holder kind="1-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.OTHER_PROBLEM' | translate }}
              </mat-label>
              <input matInput type="text" formControlName="otherProblem" required="isOtherProblem" />
            </mat-form-field>
          </field-holder>
        </ng-container>
      </article>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.STRENGHTS' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="strenghts" autocomplete="off"
            [matTooltip]="formNumber === 2 ? strengthTooltipChild :  strengthTooltipAdult">
          </textarea>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.SUMMARY_EVALUATION'| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="summaryEvaluation" autocomplete="off"
            matTooltip="{{              'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.SUMMARY_EVALUATION_TOOLTIP'| translate}}"></textarea>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <div class="checkbox-holder">
        <mat-checkbox color="primary" (change)="handleChange()" [checked]="checkTargetedEvaluation"
          [disabled]="hasTeamRequest || teamRequestOnWait">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.TARGETED_EVALUATION'| translate}}
        </mat-checkbox>
      </div>
      <div *ngIf="!isTargetedEvaluationNeeded">
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.TARGETED_EVALUATION'| translate}}
            </mat-label>
            <textarea matInput type="text" formControlName="targetedAssessment" autocomplete="off"></textarea>
          </mat-form-field>
        </field-holder>
        <button mat-flat-button color="primary" [disabled]="hasTeamRequest || teamRequestOnWait"
          (click)="sendRequest()">
          {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.SEND_REQUEST' | translate }}
        </button>
      </div>

      <form-subgroup-divider></form-subgroup-divider>

      <h4>
        {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.DONE_SERVICES_PROCEDURES_INTERVENTIONS'| translate}}
      </h4>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.LEGAL_MEASURES_PROCEDURES'| translate}}
          </mat-label>
          <mat-select formControlName="legalProcedures" multiple [compareWith]="compareRelationshipObjects">
            <mat-option *ngFor="let option of legalMeasuresProceduresData" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.SERVICES' | translate }}
          </mat-label>
          <mat-select formControlName="services" multiple [compareWith]="compareRelationshipObjects">
            <mat-option *ngFor="let option of servicesData" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <form-subgroup-divider></form-subgroup-divider>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.RIGHTS' | translate }}
          </mat-label>
          <mat-select formControlName="rights" multiple [compareWith]="compareRelationshipObjects">
            <mat-option *ngFor="let option of rightsData" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.PROFESSIONAL_PROCEDURES'| translate}}
          </mat-label>
          <mat-select formControlName="professionalProcedures" multiple [compareWith]="compareRelationshipObjects">
            <mat-option *ngFor="let option of professionalProceduresData" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.SERVICES_PROCEDURES_INTERVENTIONS'| translate}}
          </mat-label>
          <textarea matInput type="text" formControlName="servicesProceduresInterventions"
            autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
          class="form-footer__button form-footer__button--secondary">
          {{ 'ENTRANCE.PREVIOUS_STEP' | translate }}
        </button>
        <button mat-flat-button mat-stroked-button (click)="preview()" [disabled]="
          (!firstStep.valid ||
          !secondStep.valid ||
          !thirdStep.valid ||
          !fourthStep.valid ||
          !fifthStep.valid ||
          !sixthStep.valid ||
          !seventhStep.valid ||
          !eighthStep.valid) ||
          !hasSupervisor" class="form-footer__button form-footer__button--secondary">
          {{ 'VS.PREVIEW' | translate }}
        </button>
        <div matTooltip="{{'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.NO_SUPERVISOR' | translate}}"
          [matTooltipDisabled]="hasSupervisor">
          <button mat-flat-button color="primary" (click)="handleSubmit(); submitButton1.disabled = true;submitButton2.disabled = true;" [disabled]="
            (!firstStep.valid ||
            !secondStep.valid ||
            !thirdStep.valid ||
            !fourthStep.valid ||
            !fifthStep.valid ||
            !sixthStep.valid ||
            !seventhStep.valid ||
            !eighthStep.valid) ||
            !hasSupervisor" type="button" class="form-footer__button form-footer__button--primary" #submitButton1>

            {{ 'VS.CLOSE_CASE.SEND_SAVE' | translate }}
          </button>
        </div>
      </form-footer>
    </form>
  </mat-step>
</mat-vertical-stepper>

<button mat-stroked-button color="primary" (click)="closeForm()" type="button"
  class="form-footer__button form-footer__button--secondary">
  {{ 'DIALOG.CANCEL' | translate }}
</button>
<button mat-flat-button color="primary" (click)="save(false, false); submitButton1.disabled = true;submitButton2.disabled = true;" [disabled]="!firstStep.valid ||
  !secondStep.valid ||
  !thirdStep.valid ||
  !fourthStep.valid ||
  !fifthStep.valid ||
  !sixthStep.valid ||
  !seventhStep.valid ||
  !eighthStep.valid" class="form-footer__button form-footer__button--secondary" #submitButton2>
  {{ 'VS.SAVE' | translate }}
</button>