<form class="form" [formGroup]="newRecord" (ngSubmit)="addNewRecord(newRecord)">
  <form-group title="{{ 'NEW_DEPENDENT.NEW_RECORD' | translate }}" isLast="true">
    <div class="button-add-holder">
      <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
        <mat-icon>person_add</mat-icon>
        {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
      </button>
    </div>
    <br>

    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.FIRST_NAME' | translate }}</mat-label>
        <input matInput type="text" formControlName="firstName" required autocomplete="off" maxlength="255" />
        <mat-error *ngIf="newRecord.get('firstName').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.LAST_NAME' | translate
        }}</mat-label>
        <input matInput type="text" formControlName="lastName" required autocomplete="off" maxlength="255" />
        <mat-error *ngIf="newRecord.get('lastName').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.DATE_OF_BIRTH' | translate
        }}</mat-label>
        <input matInput [matDatepicker]="dateOfBirth" [max]="currentDate" formControlName="dateOfBirth"
          autocomplete="off" (dateChange)="updateJMBGOnDateOfBirthChange($event)" maxlength="255"
          name="dateFormat" (input)="checkInputDateFormat('dateOfBirth', $event)" (blur)="onBlur('dateOfBirth')" />
        <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
        <mat-datepicker #dateOfBirth></mat-datepicker>
        <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('incorrect')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('maxDateExceeded')">
          {{ 'NEW_PROTEGE.FORM.DATE_OUT_OF_BOUNDS' | translate }}
        </mat-error>
        <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('invalidFormat')">
          {{ 'NEW_PROTEGE.FORM.INVALID_DATE_FORMAT' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.PLACE_OF_BIRTH' | translate }}</mat-label>
        <input matInput type="text" formControlName="placeOfBirth" autocomplete="off" maxlength="255" />
        <mat-error *ngIf="newRecord.get('placeOfBirth').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.JMBG' | translate }}</mat-label>
        <input matInput type="text" formControlName="jmbg" autocomplete="off"
          (input)="updateJMBGOnDateOfBirthChange($event)" maxlength="13" name="jmbg" />
        <mat-error *ngIf="newRecord.get('jmbg').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="newRecord.get('jmbg').hasError('minlength') ||
                  newRecord.get('jmbg').hasError('pattern')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.ADDRESS' | translate }}</mat-label>
        <input matInput type="text" formControlName="address" autocomplete="off" maxlength="255" />
        <mat-error *ngIf="newRecord.get('address').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.LEGAL_RELATION' | translate }}</mat-label>
        <input matInput type="text" formControlName="legalRelation" autocomplete="off" maxlength="255" />
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button mat-stroked-button color="primary" class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()" type="button">
      {{ 'NEW_DEPENDENT.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" class="modal-footer__button modal-footer__button--primary" type="submit"
      [disabled]="!newRecord.valid">
      {{ 'NEW_DEPENDENT.SAVE' | translate }}
    </button>
  </form-footer>
</form>